import { Component, OnInit } from "@angular/core";
import { ReportsService, StorageReport } from "src/app/services/google/reports.service";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { ActivatedRoute, Router } from "@angular/router";
import { EmailsService } from "src/app/services/storage-plus/emails.service";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "src/app/layout/dialogs/confirmation-dialog/confirmation-dialog.component";
import { EmailCustomizationComponent } from "./dialogs/email-customization/email-customization.component";
import { User as localUser } from "src/app/web/login/user";
import { AdminPlusService } from "src/app/services/admin-plus/admin-plus.service";

@Component({
	selector: "app-storage-report-emails",
	templateUrl: "./storage-report-emails.component.html",
})
export class StorageReportEmailsComponent implements OnInit {
	options = [];
	user: localUser = {};
	storageReport: StorageReport = {
		status: {
			name: "",
			id: 0,
		},
		totalUsers: 0,
		totalStorage: 0,
		totalDrive: 0,
		totalGmail: 0,
		totalPhotos: 0,
	};

	tabIndex = 0;
	customizedEmail;
	emailName = "";

	constructor(
		private emailsService: EmailsService,
		private navService: NavService,
		private notificationService: NotificationService,
		private dialog: MatDialog,
		private reportsService: ReportsService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private adminPlusService: AdminPlusService
	) {
		const user: any = JSON.parse(localStorage.getItem("user"));

		if (user) {
			this.user = user;
		}

		this.options.push({
			icon: "edit",
			name: "Customize Email",
			tooltip: "Allows admins to set branding and other custom content to emails",
			disabled: true,
			class: "primary",
		});
		this.options.push({
			icon: "delete",
			name: "Remove Customization",
			tooltip: "Removes all email customizations for the selected email",
			disabled: true,
			class: "delete",
		});
		this.options.push({
			icon: "send",
			name: "Send Test Email",
			tooltip: "Allows admins to set branding and other custom content to emails",
			disabled: true,
			class: "success",
		});

		if (this.adminPlusService.breadCrumbLinks.length < 2) {
			this.adminPlusService.breadCrumbLinks.push({
				link: null,
				text: "Emails",
				alt: "Emails",
			});
		}
	}

	ngOnInit(): void {
		this.activatedRoute.fragment.subscribe(async (fragment: string) => {
			this.options.forEach((option) => {
				option.disabled = true;
			});
			if (fragment) {
				this.tabIndex = Number(fragment);
				if (this.tabIndex > 0) {
					this.emailName = this.getEmailNameByTabIndex();
					this.customizedEmail = null;
					this.customizedEmail = await this.emailsService.getEmailCustomization(this.emailName);
				}
			}
			this.setOptions();
		});

		this.loadData();
	}

	setOptions() {
		this.options.forEach((option) => {
			option.disabled = true;
		});

		if (this.tabIndex) {
			this.options.forEach((option) => {
				option.disabled = false;
				if (option.name == "Remove Customization" && !this.customizedEmail) {
					option.disabled = true;
				}
			});
		}
	}

	optionSelected(option) {
		switch (option.name) {
			case "Customize Email":
				this.openEmailCustomizationDialog();
				break;
			case "Remove Customization":
				this.confirmEmailCustomizationDeletion();
				break;
			case "Send Test Email":
				this.sendTest();
				break;
			default:
				break;
		}
	}

	private async loadData() {
		this.navService.loading.next(true);
		this.storageReport = await this.reportsService.getStorageReport();
		if (!this.storageReport) {
			this.navService.loading.next(false);
			this.router.navigate(["/reports/storage"]);
		}
		this.navService.loading.next(false);
	}

	updateHash(index: number) {
		this.router.navigate(["."], {
			relativeTo: this.activatedRoute,
			fragment: index.toString(),
		});
	}

	openEmailCustomizationDialog() {
		const dialogRef = this.dialog.open(EmailCustomizationComponent, {
			width: "600px",
			disableClose: true,
			data: {
				emailName: this.emailName,
				customizedEmail: this.customizedEmail,
			},
		});

		dialogRef.afterClosed().subscribe(async (result) => {
			if (result) {
				this.customizedEmail = await this.emailsService.getEmailCustomization(this.emailName);
				this.emailsService.sendRefresh(this.emailName);
				this.setOptions();
			}
		});
	}

	confirmEmailCustomizationDeletion() {
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			data: {
				title: "Confirm Deletion of Email Customization",
				message: "Are you sure you would like to delete the email customization?",
				btnOkText: "Yes",
				btnCancelText: "Close",
			},
		});

		dialogRef.afterClosed().subscribe(async (result) => {
			if (result) {
				const customizationDeleted = await this.emailsService.deleteEmailCustomization(this.customizedEmail.id);
				if (customizationDeleted) {
					this.customizedEmail = null;
					this.emailsService.sendRefresh(this.emailName);
					this.notificationService.notify("The email customization was deleted");
					this.setOptions();
				} else {
					this.notificationService.notify("Unable to delete email customization");
				}
			}
		});
	}

	async sendTest() {
		this.navService.loading.next(true);
		const sent = await this.emailsService.sendTest(this.emailName);
		if (sent) {
			this.notificationService.notify("A test email has been sent to you");
		} else {
			this.notificationService.notify("Something went wrong, please check logs/console");
		}
		this.navService.loading.next(false);
	}

	private getEmailNameByTabIndex() {
		let emailName = "";
		switch (this.tabIndex) {
			case 1:
				emailName = "reportComplete.html";
				break;
			case 2:
				emailName = "actionRequired.html";
				break;
			case 3:
				emailName = "pendingDeletion.html";
				break;
			case 4:
				emailName = "filesDeleted.html";
				break;
			case 5:
				emailName = "filesTrashed.html";
				break;
			case 6:
				emailName = "actionRequiredRemoved.html";
				break;

			default:
				break;
		}

		return emailName;
	}
}
