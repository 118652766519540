import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AttachmentsService } from "src/app/services/admin-plus/attachments.service";
import { Attachment } from "src/app/models/admin-plus/attachment";
import { MatDialog } from "@angular/material/dialog";
import { AttachmentComponent } from "./attachment/attachment.component";
import { ConfirmationDialogComponent } from "src/app/layout/dialogs/confirmation-dialog/confirmation-dialog.component";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { NotificationService } from "src/app/services/utilities/notification.service";

@Component({
  selector: "attachments",
  templateUrl: "attachments.component.html",
  styleUrls: ["attachments.component.scss"],
})
export class AttachmentsComponent {
  files: FileList = null;
  loading = false;
  error = false;
  @Output() newAttachmentEvent = new EventEmitter<Attachment>();
  @Output() deleteAttachmentEvent = new EventEmitter<Attachment>();
  @Input() tooltip: string;
  @Input() acceptedMimeTypes: string;
  @Input() branding = null;
  @Input() customerSettings;
  attachedFile: Attachment = null;

  params = {
    brand: true,
  };

  constructor(
    private attachmentsService: AttachmentsService,
    private dialog: MatDialog,
    private navService: NavService,
    private notifyService: NotificationService
  ) {}

  async ngOnChanges() {
    this.navService.loading.next(true);
    if (this.customerSettings.attachmentId && this.customerSettings.brand?.trim()) {
      await this.getAttachment(this.customerSettings.attachmentId);
    }
    this.navService.loading.next(false);
  }

  // On file Select
  handleSelection(event) {
    this.navService.loading.next(true);
    this.loading = true;
    const file = event.target.files[0];
    // (25 * 1024) * 1024 = 26214400 bytes
    if (file?.size >= 26214400) {
      this.notifyService.notify("The maximum file image size allowed is 25 MB");
      this.loading = false;
    }
    this.handleUpload(file).then(async (response) => {
      if (response?.id) {
        const counter = 0;
        await this.getAttachment(response.id, counter);
        this.newAttachmentEvent.emit(this.attachedFile);
      }
      this.loading = false;
    });
    this.navService.loading.next(false);
  }

  async handleUpload(file) {
    return this.attachmentsService.upload(file);
  }

  async getAttachment(attachmentId: number, counter = 0) {
    this.navService.loading.next(true);
    this.loading = true;
    const response = await this.attachmentsService.getAttachment(attachmentId, this.params);
    if (response) {
      counter++;
      // Some files, like files with long names, take a few moments to return the image binary data
      // If the file is in xml format, try a few more times to get the image binary data
      if (response?.url?.includes("data:application/xml") && counter < 5) {
        await this.getAttachment(attachmentId, counter);
      } else {
        if (response) {
          this.attachedFile = response;
        } else {
          this.error = true;
        }
        this.navService.loading.next(false);
        this.loading = false;
      }
    }
    this.navService.loading.next(false);
    this.loading = false;
    return response;
  }

  delete(attachedFile) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: "Confirm Deletion of Attachment",
        message: "Are you sure you would like to delete this attachment?",
        btnOkText: "Yes",
        btnCancelText: "Cancel",
        buttonColor: "danger",
      },
    });
    dialogRef.afterClosed().subscribe(async (confirmation) => {
      if (confirmation) {
        this.navService.loading.next(true);
        const attachment = await this.attachmentsService.deleteAttachment(
          this.customerSettings.attachmentId ? this.customerSettings.attachmentId : attachedFile.id,
          this.params
        );
        if (attachment?.count > 0) {
          this.attachedFile = null;
          this.customerSettings.brand = null;
          this.customerSettings.attachmentId = null;
          this.notifyService.notify(`Successfully deleted the branding attachment`);
          this.deleteAttachmentEvent.emit();
        }
        this.navService.loading.next(false);
      }
    });
  }

  view(attachedFile) {
    this.dialog.open(AttachmentComponent, {
      disableClose: false,
      width: "80%",
      data: {
        attachment: attachedFile,
      },
    });
  }
}
