<h2 mat-dialog-title class="renew-dialog mb-3">{{ data.message }}</h2>
<div mat-dialog-content class="d-flex justify-content-center align-items-center">
  <a class="py-3" (click)="authService.signInWithGoogle()">
    <img
      class="text-center"
      src="https://ap-assets.trafera.app/images/btn_google_signin_light_normal_web@2x.png"
      width="260px"
    />
  </a>
</div>
