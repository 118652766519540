<div style="margin-bottom: 33px">
	<google-chart
		[options]="options"
		[type]="type"
		[dynamicResize]="true"
		[data]="data"
		[columns]="chartColumns"
		*ngIf="data.length > 0"
		style="width: 100%; cursor: pointer"
		(select)="onBarSelect($event)"
	></google-chart>
</div>
