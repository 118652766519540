import { Component, OnInit, Inject, Input } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { GroupsService } from "src/app/services/admin-plus/groups.service";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { NavService } from "src/app/services/admin-plus/nav.service";

@Component({
  selector: "app-group-dialog",
  templateUrl: "./group-dialog.component.html",
  styleUrls: ["./group-dialog.component.scss"],
})
export class GroupDialogComponent {
  public user: any;
  public error: string;
  public name: string;
  public description: string;
  public email: string;
  public domain: string;
  public wordChange: string;

  @Input() hideRequiredMarker: boolean;

  constructor(
    public router: Router,
    public matDialog: MatDialog,
    private groupsService: GroupsService,
    private notifyService: NotificationService,
    private dialogRef: MatDialogRef<GroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private navService: NavService
  ) {
    const user: any = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.user = user;
      this.domain = this.user.email.replace(/.*@/, "");
    }
  }

  async processForm() {
    this.navService.loading.next(true);
    if (this.name === undefined) {
      this.error = "Group Name";
      this.notifyService.notify("Group name is required");
    } else if (this.description === undefined) {
      this.error = "Group Description";
      this.notifyService.notify("Group description is required");
    } else if (this.email === undefined) {
      this.error = "Group Email";
      this.notifyService.notify("Group email is required");
    } else {
      const group: any = {
        displayName: this.name,
        description: this.description,
        groupKey: {
          id: this.email + "@" + this.domain,
        },
        labels: {
          "cloudidentity.googleapis.com/groups.discussion_forum": "",
        },
        parent: "customers/C" + this.user.customerID,
      };
      const response = await this.groupsService.addGroup(group);
      if (response) {
        this.dialogRef.close(response["response"]);
        this.notifyService.notify("Successfully created a new group");
      } else {
        this.navService.loading.next(false);
        this.notifyService.notify(response["error"]["message"]);
      }
    }
    this.navService.loading.next(false);
  }
}
