import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class TimesService {
  intervals = ["Daily", "Weekly", "Monthly", "Yearly"];
  frequency = {
    timeOfDay: "08:00",
    Weekly: [false, false, false, false, false, false, false],
    Monthly: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    Yearly: [false, false, false, false, false, false, false, false, false, false, false, false],
  };

  convertToUTC(convertTime: string, offset: string, dayOfWeek: number, initialOffset: string) {
    const date = {
      number: 0,
      dayOfWeek: 0,
    };
    const time = convertTime.split(":")[0];
    if (parseInt(initialOffset) < 0) {
      let number = 0;
      number = parseInt(time) + parseInt(offset);
      if (number > 23) {
        dayOfWeek = dayOfWeek + 1;
        if (dayOfWeek > 6) {
          dayOfWeek = 0;
        }
        if (dayOfWeek < 0) {
          dayOfWeek = 6;
        }
        number = number - 24;
      }
      date.number = number;
      date.dayOfWeek = dayOfWeek;
    } else if (parseInt(initialOffset) == 0) {
      // Greenwich, England time: do not need to convert to UTC
      date.number = parseInt(time);
      date.dayOfWeek = dayOfWeek;
    } else {
      let number = 0;
      number = parseInt(time) - parseInt(offset);
      if (number < 0) {
        dayOfWeek = dayOfWeek - 1;
        if (dayOfWeek > 6) {
          dayOfWeek = 0;
        }
        if (dayOfWeek < 0) {
          dayOfWeek = 6;
        }
        number = number + 24;
      }
      date.number = number;
      date.dayOfWeek = dayOfWeek;
    }
    return date;
  }

  convertToLocal(convertTime: string, offset: string, initialOffset: string) {
    const date = {
      number: 0,
    };
    const time = convertTime.split(":")[0];
    let number = 0;
    if (parseInt(initialOffset) < 0) {
      number = parseInt(time) - parseInt(offset);
      if (number < 0) {
        number = number + 24;
      }
      date.number = number;
    } else if (parseInt(initialOffset) == 0) {
      // Greenwich, England time: do not need to convert to UTC
      date.number = parseInt(time);
    } else {
      number = parseInt(time) + parseInt(offset);
      if (number > 23) {
        number = number - 24;
      }
      date.number = number;
    }
    return date;
  }

  convertDayToLocal(convertTime: string, offset: string, dayOfWeek: number, initialOffset: string) {
    const time = convertTime.split(":")[0];
    let number = 0;
    if (parseInt(initialOffset) < 0) {
      number = parseInt(time) - parseInt(offset);
      if (number < 0) {
        dayOfWeek = dayOfWeek - 1;
        if (dayOfWeek < 0) {
          dayOfWeek = 6;
        }
        if (dayOfWeek > 6) {
          dayOfWeek = 0;
        }
      }
    } else {
      number = parseInt(time) + parseInt(offset);
      if (number > 23) {
        dayOfWeek = dayOfWeek + 1;
        if (dayOfWeek < 0) {
          dayOfWeek = 6;
        }
        if (dayOfWeek > 6) {
          dayOfWeek = 0;
        }
      }
    }
    return dayOfWeek;
  }

  public DateToISO8601(date = new Date()): string {
    return date.toISOString().split("T")[0];
  }
}
