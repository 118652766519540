<h2 mat-dialog-title>{{ action }} {{ data.type }}</h2>
<form (ngSubmit)="processForm()" autocomplete="off">
  <div mat-dialog-content class="mt-4">
    <div class="row">
      <div class="col-12 mt-12" *ngIf="action == 'Delete'">
        <h4>
          Are you sure you want to delete
          <span *ngIf="priority.id">{{ priority.name }}</span>
          <span *ngIf="status.id">{{ status.name }}</span>
          <span *ngIf="channel.id">{{ channel.name }}</span>
          ?
        </h4>
      </div>
      <div class="col-12 mt-2" *ngIf="action != 'Delete'">
        <mat-form-field class="w-100">
          <mat-label>Name</mat-label>
          <input
            *ngIf="data.type === 'Priority'"
            type="text"
            matInput
            [(ngModel)]="priority.name"
            name="priority"
            maxlength="45"
            required
          />
          <input
            *ngIf="data.type === 'Status'"
            type="text"
            matInput
            [(ngModel)]="status.name"
            name="status"
            maxlength="45"
            required
          />
          <input
            *ngIf="data.type === 'Channel'"
            type="text"
            matInput
            [(ngModel)]="channel.name"
            name="channel"
            maxlength="45"
            required
          />
        </mat-form-field>
        <app-status
          *ngIf="data.type === 'Status'"
          (onSelectionChange)="statusChange($event)"
          [ticket]="{ status: { id: status.parent?.id } }"
          [ticketLoading]="loading"
          [title]="'Parent Status'"
          defaultStatuses="true"
        ></app-status>
      </div>

      <div class="col-12 mt-2" *ngIf="action != 'Delete' && data.type === 'Channel'">
        <mat-form-field class="w-100">
          <mat-label>Icon</mat-label>
          <input
            *ngIf="data.type === 'Channel'"
            type="text"
            matInput
            [(ngModel)]="channel.icon"
            name="icon"
            maxlength="45"
          />
          <mat-hint
            ><sup>*</sup>Icons can be any
            <a href="https://fonts.google.com/icons" alt="Google Fonts" target="_blank">Google Font icon</a>.</mat-hint
          >
        </mat-form-field>
      </div>
    </div>
    <div mat-dialog-actions>
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button
        type="submit"
        mat-stroked-button
        class="ml-2"
        [class]="action == 'Delete' ? 'danger' : 'success'"
        [disabled]="loading"
      >
        <span>{{ action }} {{ data.type }}</span>
      </button>
    </div>
  </div>
</form>
