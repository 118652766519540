<div class="pt-4">
	<ng-container *ngFor="let ou of ous; let i = index">
		<span class="mr-2 fs-6 rounded-circle mat-icon" [style.backgroundColor]="options.colors[i]"></span>
		<span class="mr-3">{{ ou.name }}</span
		><br />
	</ng-container>
</div>
<div class="d-flex justify-content-center">
	<google-chart
		[options]="options"
		[type]="type"
		[data]="data"
		[dynamicResize]="true"
		*ngIf="data.length > 0"
		(select)="onPieSelect($event)"
	></google-chart>
</div>
