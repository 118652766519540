import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Action } from "src/app/services/utilities/utility.service";

@Component({
  selector: "app-action-dialog",
  templateUrl: "./action-dialog.component.html",
  styleUrls: ["./action-dialog.component.scss"],
})
export class ActionDialogComponent {
  constructor(public dialogRef: MatDialogRef<ActionDialogComponent>, @Inject(MAT_DIALOG_DATA) public action: Action) {}

  public onConfirm(): void {
    this.dialogRef.close(this.action);
  }

  keyExists(object, key) {
    return key in object;
  }

  receiveEmail(event) {
    if (event.deleted) {
      this.action.params = {
        email: "",
      };
    } else {
      if (event.email === "Unassigned") {
        const action = {
          name: "Unassign User",
          description: "Unassign the user from the selected Chrome OS Devices.",
          btnLabel: "Unassign",
          params: {
            email: "",
          },
          object: "assigned-user",
        };
        this.action = action;
      } else {
        this.action.params = {
          email: event.email,
        };
      }
    }
  }

  invalidInput() {
    if (this.action.btnLabel === "Assign") {
      return !this.action.params["email"];
    }

    if (this.action.btnLabel === "Set") {
      if (this.action.params["volume"] < 0) {
        this.action.params["volume"] = 0;
      }

      if (this.action.params["volume"] > 100) {
        this.action.params["volume"] = 100;
      }
      //This will allow for 0 to be set as a volume and also check for empty or invalid values.
      return this.action.params["volume"] === 0 ? false : !this.action.params["volume"];
    }
  }

  orgUnitIdChanged(orgUnitId: string) {
    this.action.params["orgUnitId"] = orgUnitId;
  }
}
