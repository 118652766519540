import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'storageReportDrivesTableTruncate'
})
export class StorageReportDrivesTableTruncatePipe implements PipeTransform {

	transform(value: string): string {
		if (!value) return 'None';
		
		const names = value.split(', ');
		if (names.length > 1) {
			return names.length + ' Users';
		} else {
			return value;
		}
  }

}
