<mat-form-field class="w-100">
    <mat-label> {{ fieldLabel }} </mat-label>
    <mat-chip-grid #userChip>
        <mat-chip-row  *ngIf="selected_user?.name?.fullName || selected_user?.primaryEmail" selectable="true" removable="true" (removed)="remove()">
            {{ selected_user.name.fullName ? selected_user.name.fullName : selected_user.primaryEmail }}
            <mat-icon *ngIf="!disabled" matChipRemove>cancel</mat-icon>
        </mat-chip-row>

        <input 
            type="text" 
            #userInput
            matInput
            name="selected_user"
            [matAutocomplete]="auto"
            [matChipInputFor]="userChip"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [readonly]="disabled"
        />
    </mat-chip-grid>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let user of users" [value]="user">
            {{ user.name.fullName }}&nbsp;|&nbsp;<small class="text-muted">{{ user.primaryEmail }}</small>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>