<h2 mat-dialog-title>
    Create Group
</h2>
<form (ngSubmit)="processForm()" autocomplete="off">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-12 mt-2">
                <mat-form-field class="w-100">
                    <mat-label>
                        Name
                    </mat-label>
                    <input 
                        matInput
                        [(ngModel)]="name" 
                        name="name" 
                        id="name" 
                        type="text" 
                        placeholder="Name *" 
                        autocomplete="off" 
                        required />
                </mat-form-field>
            </div>
            <div class="col-12 mt-2">
                <mat-form-field class="w-100">
                    <mat-label>
                        Description
                    </mat-label>
                    <input 
                        matInput
                        [(ngModel)]="description" 
                        name="description" 
                        id="description" 
                        type="text" 
                        placeholder="Description *" 
                        autocomplete="off" 
                        required />
                </mat-form-field>
            </div>
            <div class="col-12 mt-2">
                <mat-form-field class="w-100">
                    <mat-label>
                        Email
                    </mat-label>
                        <input 
                            matInput 
                            [(ngModel)]="email" 
                            name="email" 
                            id="email" 
                            type="text" 
                            placeholder="Group email *" 
                            autocomplete="off" 
                            required />
                        <span matSuffix>{{ domain }}</span>
                </mat-form-field>
            </div>
        </div>
        <app-dialog-actions-container></app-dialog-actions-container>
    </div>
</form>