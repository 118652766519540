import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef, Input } from "@angular/core";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Router } from "@angular/router";
import { Sort } from "@angular/material/sort";
import { MatSort } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { UntypedFormControl } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { ReportsService, User } from "src/app/services/google/reports.service";
import { EmailList, EmailsService } from "src/app/services/storage-plus/emails.service";
import { SelectionModel } from "@angular/cdk/collections";
import {
  ConfirmDialogModel,
  ConfirmationDialogComponent,
} from "src/app/layout/dialogs/confirmation-dialog/confirmation-dialog.component";
import { merge, of as observableOf } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { FiltersService, Filter, FilterType } from "src/app/services/utilities/filters.service";

@Component({
  selector: "app-storage-report-users-table",
  templateUrl: "./storage-report-users-table.component.html",
  styleUrls: ["./storage-report-users-table.component.scss"],
})
export class StorageReportUsersTableComponent implements OnInit {
  @Input() ouId = 0;
  @Input() options = [];
  filterTypeValue: FilterType = FilterType.USER;
  displayedColumns: string[] = [
    "select",
    "fullName",
    "totalStorage",
    "driveTotal",
    "nonTrashedStorage",
    "trashedStorage",
    "gmailTotal",
    "photosTotal",
    "OU",
    "options",
  ];

  usageSizeList: string[] = [
    "1",
    "5",
    "10",
    "15",
    "20",
    "25",
    "30",
    "35",
    "40",
    "45",
    "50",
    "75",
    "100",
    "150",
    "250",
    "500",
    "1000",
    "1500",
    "2000",
  ];

  dataSource = new MatTableDataSource<User>();
  actions = new UntypedFormControl();
  flagged = new UntypedFormControl();
  actionsMaxResult = new UntypedFormControl();
  usageSize = new UntypedFormControl();
  columns = new UntypedFormControl();
  userCtrl = new UntypedFormControl();
  selection = new SelectionModel<User>(true, []);

  filteredOptions: Observable<Filter[]>;

  selected_users: string[] = [];
  columnsList: string[] = ["nonTrashedStorage", "trashedStorage", "previousTotal", "previousTrashed"];

  maxResults = 10;
  page = 1;
  offset = 0;
  private getCount = true;
  totalUserCount = 0;
  storageReportId = "";

  nextButton = false;
  nextButtonDis = false;
  loading = false;

  /* filtered search stuff */
  private usageSizeSearch = "0";
  private firstNameSearch = "";
  private lastNameSearch = "";
  private emailSearch = "";
  private ouSearch = "";
  private flaggedSearch = "all";
  private daysFlagged = "";
  private flaggedDateBefore = "";
  public activeOrderBy = "totalStorage";
  public direction = "desc";
  public orderBy = "givenName";
  public sortOrder = "SORT_ORDER_UNDEFINED";

  public users: User[] = [];
  public allUsers: User[] = [];
  public user: User;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("userInput") userInput: ElementRef;

  @Output() usersEvent = new EventEmitter<User[]>();

  @Input() removeAdditionalFilter = false;
  data = {
    usageSizeSearch: this.usageSizeSearch,
    firstNameSearch: this.firstNameSearch,
    lastNameSearch: this.lastNameSearch,
    emailSearch: this.emailSearch,
    flaggedSearch: this.flaggedSearch,
    ouSearch: this.ouSearch,
    page: this.page,
    offset: this.offset,
    daysFlagged: this.daysFlagged,
    flaggedDateBefore: this.flaggedDateBefore,
    filtersCount: 0,
  };

  constructor(
    private navService: NavService,
    private router: Router,
    private notifyService: NotificationService,
    private reportsService: ReportsService,
    private emailsService: EmailsService,
    private filtersService: FiltersService,
    private dialog: MatDialog
  ) {
    const user: User = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.user = user;
    }
  }

  async ngOnInit() {
    const hasNavFilters = await this.filtersService.checkNavFilters(this.filterTypeValue);
    if (!hasNavFilters) this.loadData();
    this.actions.valueChanges.subscribe((value) => {
      if (value == "flag") {
        const users: User[] = [];
        for (let i = 0; i < this.selection.selected.length; i++) {
          users.push(this.selection.selected[i]);
        }
        this.flagConfirmation(users);
      } else {
        return;
      }
    });
    this.flagged.valueChanges.subscribe((value) => {
      this.flaggedSearch = value;
      this.loadData();
    });
    this.columns.setValue(this.displayedColumns);
    this.usageSize.setValue("1");
    this.usageSize.valueChanges.subscribe((value) => {
      this.usageSizeSearch = value;
      this.loadData();
    });
  }

  receiveData($event) {
    this.data = $event;
    this.usageSizeSearch = this.data.usageSizeSearch;
    this.firstNameSearch = this.data.firstNameSearch;
    this.lastNameSearch = this.data.lastNameSearch;
    this.emailSearch = this.data.emailSearch;
    this.flaggedSearch = this.data.flaggedSearch;
    this.ouSearch = this.data.ouSearch;
    this.page = this.data.page;
    this.offset = this.data.offset;
    this.daysFlagged = this.data.daysFlagged;
    this.flaggedDateBefore = this.data.flaggedDateBefore;
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  validFlag() {
    let counter = 0;
    for (let i = 0; i < this.selection.selected.length; i++) {
      if (this.selection.selected[i]["flagId"] === null) {
        counter++;
      }
    }

    if (counter === 0) {
      return true;
    } else {
      return false;
    }
  }

  flagConfirmation(users: User[]) {
    let message = `Are you sure you want to flag the user` + (users.length > 1 ? "s" : "");
    for (let i = 0; i < users.length; i++) {
      if (this.selection.selected[i]["flagId"] === null) {
        if (i == users.length - 1) {
          message += ` ${users[i].firstName} ${users[i].lastName}`;
        } else {
          message += ` ${users[i].firstName} ${users[i].lastName}, `;
        }
      }
    }
    message += ` for excessive storage usage?`;
    const btnOkText = `Confirm`;
    const dialogData = new ConfirmDialogModel("Flag User", message, btnOkText);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.flagUsers(users);
      }
      this.navService.loading.next(false);
      this.selection.clear();
      this.actions.setValue("");
    });
  }

  async flagUsers(users: User[]) {
    this.updateLoader(true);
    const emailList = { emails: [] };
    for (const user of users) {
      if (user.flagId === null) {
        emailList.emails.push(user.email);
      }
    }

    const response = await this.emailsService.addFlag(emailList);

    if (response) {
      this.notifyService.notify(
        (response.length ? response.length : 0) +
          ` user` +
          (response.length !== 1 ? `s ` : ` `) +
          (response.length !== 1 ? `have` : `has`) +
          ` been notified for using excessive storage in their Google Drive`
      );
      const data = this.dataSource.data;
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < response.length; j++) {
          if (data[i].email === response[j].email) {
            data[i].flagId = 1; //Will replace with a boolean one day. JRB 2022-04-18
            data[i].previousTotal = response[j].usageStorage;
            data[i].previousTrashed = response[j].driveTrashed;
          }
        }
      }
      this.dataSource.data = data;
    }
    this.updateLoader(false);
    this.selection.clear();
    this.actions.setValue("");
  }

  async undoFlag(firstName: string, lastName: string, email: string) {
    this.updateLoader(true);
    const emailList: EmailList = {
      emails: [email],
    };
    const response = await this.emailsService.deleteFlag(emailList);
    if (response.length) {
      this.notifyService.notify(
        `The user ${firstName} ${lastName} has been removed from being flagged for excessive storage in their Google Drive`
      );
      const data = this.dataSource.data;
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < response.length; j++) {
          if (data[i].email == response[j].email) {
            data[i].flagId = null;
          }
        }
      }
      this.dataSource.data = data;
    }
    this.updateLoader(false);
  }

  changeSort(sort: Sort) {
    if (sort.direction === "") {
      this.direction = "desc";
    } else {
      this.activeOrderBy = sort.active;
      this.direction = sort.direction;
    }
    this.loadData();
  }

  setPager(data) {
    this.page = data.page;
    this.offset = data.offset;
    this.maxResults = data.maxResults ? data.maxResults : this.maxResults;
    this.loadData(data.arrow);
  }

  async loadData(arrow = "") {
    this.navService.loading.next(true);
    await this.getUsers(arrow);
    this.navService.loading.next(false);
  }

  private async getUsers(arrow = "") {
    this.updateLoader(true);
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.selection.clear();
          this.nextButton = true;
          this.getCount = !arrow;
          return this.reportsService.getStorageReportUsers(
            this.maxResults,
            this.activeOrderBy,
            this.direction,
            this.firstNameSearch,
            this.lastNameSearch,
            this.emailSearch,
            this.ouSearch,
            this.offset,
            this.flaggedSearch,
            this.usageSizeSearch,
            this.getCount,
            this.ouId,
            this.daysFlagged,
            this.flaggedDateBefore
          );
        }),
        map((users) => {
          if (users) {
            this.users = users["data"];
            this.dataSource = new MatTableDataSource(this.users);
            this.dataSource.sort = this.sort;
            this.totalUserCount = this.getCount ? (users["count"] ? users["count"] : 0) : this.totalUserCount;
            if (this.dataSource.filteredData.length < 10) {
              this.nextButtonDis = true;
            }
            if (this.dataSource.filteredData.length > 10) {
              this.nextButtonDis = false;
            }
            if (arrow === "next" && this.dataSource.filteredData.length + this.offset === this.totalUserCount) {
              this.nextButtonDis = true;
            }
            if (arrow === "next" && this.dataSource.filteredData.length + this.offset < this.totalUserCount) {
              this.nextButtonDis = false;
            }
            if (this.dataSource.filteredData.length + this.offset < this.totalUserCount) {
              this.nextButtonDis = false;
            }
            if (this.dataSource.filteredData.length + this.offset === this.totalUserCount) {
              this.nextButtonDis = true;
            }
            if (arrow === "prev" && this.totalUserCount > 0) {
              this.nextButtonDis = false;
            }
            if (this.totalUserCount === this.maxResults) {
              this.nextButtonDis = true;
            }
          }
          this.nextButton = false;
          this.updateLoader(false);
          return this.users;
        }),
        catchError(() => {
          this.updateLoader(false);
          return observableOf([]);
        })
      )
      .subscribe();
  }

  async sendUserEmitter() {
    const users = [];
    let response = {};
    let offset = 0;
    do {
      response = await this.reportsService.getStorageReportUsers(
        100,
        this.activeOrderBy,
        this.direction,
        this.firstNameSearch,
        this.lastNameSearch,
        this.emailSearch,
        this.ouSearch,
        offset,
        this.flaggedSearch,
        this.usageSizeSearch,
        this.getCount,
        this.ouId,
        this.daysFlagged,
        this.flaggedDateBefore
      );
      offset = offset + 100;
      users.push(...response["data"]);
    } while (response["data"].length > 0);
    const emitObject: any = {
      users: users,
      columns: this.displayedColumns,
    };
    this.usersEvent.emit(emitObject);
  }

  routeToUser(userId: string) {
    this.router.navigate(["/reports/storage/users/" + userId]);
  }

  getPageData() {
    return this.dataSource._pageData(this.dataSource._orderData(this.dataSource.filteredData));
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  masterToggle() {
    this.isEntirePageSelected()
      ? this.selection.deselect(...this.getPageData())
      : this.selection.select(...this.getPageData());
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.getPageData() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1}`;
  }

  tableColumnChange(value: string) {
    let displayedTableColumns = this.displayedColumns;
    const orderedDisplayedTableColumns = [];
    const orderedTableColumns = [
      "select",
      "fullName",
      "totalStorage",
      "driveTotal",
      "nonTrashedStorage",
      "trashedStorage",
      "previousTotal",
      "previousTrashed",
      "gmailTotal",
      "photosTotal",
      "OU",
      "options",
    ];

    if (displayedTableColumns.includes(value)) {
      displayedTableColumns = displayedTableColumns.filter((c) => c != value);
    } else {
      displayedTableColumns.push(value);
    }

    for (const orderedTableColumn of orderedTableColumns) {
      if (displayedTableColumns.includes(orderedTableColumn)) orderedDisplayedTableColumns.push(orderedTableColumn);
    }

    this.displayedColumns = orderedDisplayedTableColumns;
  }

  updateLoader(value: boolean) {
    for (const option of this.options) {
      if (option["name"] == "Export to Sheets" || option["name"] == "Export to PDF") {
        option["disabled"] = value;
      }
    }
    this.loading = value;
    this.navService.loading.next(value);
  }
}
