import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ModulesService } from "src/app/services/modules.service";

@Component({
  selector: "app-tos-prompt",
  templateUrl: "./tos-prompt.component.html",
})
export class TosPromptComponent implements OnInit {
  signature = "";
  showError = false;
  lockButtons = false;

  constructor(
    private modulesService: ModulesService,
    public dialogRef: MatDialogRef<TosPromptComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.lockButtons = true;
  }

  onChangeSignature(value: string) {
    if (value.length > 3) {
      this.lockButtons = false;
    }
  }

  async onConfirm() {
    this.lockButtons = true;
    const result: boolean = await this.modulesService.approveTos(this.signature);
    if (result) {
      this.dialogRef.close(true);
    } else {
      this.showError = true;
    }
  }

  onDismiss() {
    this.dialogRef.close(false);
  }
}
