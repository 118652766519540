<mat-form-field class="w-100">
  <mat-label> {{ fieldLabel }} </mat-label>
  <mat-chip-grid #itemsList>
    <mat-chip-row *ngFor="let item of selected_items" [removable]="true" (removed)="automationService.dirty(); remove()">
      {{ item.itemName ? item.itemName : item }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-row>
    <input
      placeholder="{{ placeholder }}"
      #itemsInput
      [formControl]="itemsCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="itemsList"
      (matChipInputTokenEnd)="add($event)"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      matInput
      required
      (blur)="removeExtraText()"
      (keyup)="search($event)"
      (focus)="search($event)"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option matTooltip="{{ item.itemName }}" *ngFor="let item of filteredOptions | async" [value]="item">
      <span class="smaller-size">{{ item.itemName }}</span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
