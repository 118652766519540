import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { LoginService } from "./login.service";
import { Users, User } from "../../users/users";
import { Groups } from "../../groups/groups";
import { AuthService } from "src/app/services/admin-plus/auth.service";
import { Payload, UtilityService } from "../utilities/utility.service";
import { Module } from "src/enums/module";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  public api_url = "https://www.googleapis.com/admin/directory/v1/users";
  private user;
  viewingUser: User;

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private authService: AuthService,
    public utilityService: UtilityService
  ) {}

  async getGoogleUsers(moduleId = Module.TICKETS, params = {}): Promise<Payload> {
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("GET", `/admin-plus/${moduleId}/users`, params, {}).subscribe((payload) => {
        if (payload) {
          resolve(payload);
        } else {
          resolve(null);
        }
      });
    });
  }

  async getUsers(
    maxResults = 500,
    pageToken = "",
    orderBy = "givenName",
    sortOrder = "SORT_ORDER_UNDEFINED",
    email = "",
    givenName = "",
    familyName = "",
    OUSearch = "",
    orgUnitPath = "/",
    nameAndEmail = ""
  ): Promise<Users> {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Users>((resolve) => {
      this.getUsersData(
        maxResults,
        pageToken,
        orderBy,
        sortOrder,
        givenName,
        familyName,
        email,
        OUSearch,
        orgUnitPath,
        nameAndEmail
      ).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getUsersData(
    maxResults = 500,
    pageToken = "",
    orderBy = "givenName",
    sortOrder = "SORT_ORDER_UNDEFINED",
    givenName = "",
    familyName = "",
    email = "",
    OUSearch = "",
    orgUnitPath = "/",
    nameAndEmail = ""
  ): Observable<Users> {
    const query = this.buildQuery(nameAndEmail, givenName, familyName, email, OUSearch, orgUnitPath);
    return this.http
      .get<Users>(this.api_url + `?query=${query}`, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
        params: new HttpParams()
          .set("customer", "my_customer")
          .set("maxResults", maxResults.toString())
          //.set("query", query)
          .set("pageToken", pageToken)
          .set("orderBy", orderBy)
          .set("sortOrder", sortOrder),
      })
      .pipe(catchError(this.authService.handleAPIError<Users>("getUsersData")));
  }

  async resetPassword(userKey: string, newPassword: resetPassword) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<resetPassword>((resolve) => {
      this.resetPasswordData(userKey, newPassword).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private resetPasswordData(userKey: string, newPassword: resetPassword): Observable<resetPassword> {
    return this.http
      .put<resetPassword>(this.api_url + "/" + userKey, newPassword, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<resetPassword>("resetPasswordData")));
  }

  async getUser(id: string): Promise<User> {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<User>((resolve) => {
      this.getUserData(id).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getUserData(id: string): Observable<User> {
    return this.http
      .get<User>(this.api_url + "/" + id, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
        params: new HttpParams().set("projection", "full"),
      })
      .pipe(catchError(this.authService.handleAPIError<User>("getUserData")));
  }

  async addUser(newUser): Promise<User> {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<User>((resolve) => {
      this.addUserData(newUser).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private addUserData(newUser): Observable<User> {
    return this.http
      .post<User>(this.api_url, JSON.stringify(newUser), {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<User>("addUserData")));
  }

  async editUser(id: string, editUser): Promise<User> {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<User>((resolve) => {
      this.editUserData(id, editUser).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private editUserData(id: string, editUser): Observable<User> {
    return this.http
      .put<User>(this.api_url + "/" + id, JSON.stringify(editUser), {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<User>("editUserData")));
  }

  async deleteUser(id: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise((resolve) => {
      this.deleteUserData(id).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private deleteUserData(id: string) {
    return this.http
      .delete(this.api_url + "/" + id, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError("deleteUserData")));
  }

  async getGroupMemberships(id: string, maxResults = 25, pageToken = ""): Promise<Groups> {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise((resolve) => {
      this.getGroupMembershipsData(id, maxResults, pageToken).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getGroupMembershipsData(id: string, maxResults = 25, pageToken = ""): Observable<Groups> {
    return this.http
      .get<Groups>("https://www.googleapis.com/admin/directory/v1/groups", {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
        params: new HttpParams().set("userKey", id).set("maxResults", maxResults.toString()).set("pageToken", pageToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Groups>("getGroupMembershipsData", true)));
  }

  async getGroups(nextToken = "") {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise((resolve) => {
      this.getGroupsData(nextToken).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getGroupsData(nextToken = "") {
    return this.http
      .get("https://www.googleapis.com/admin/directory/v1/groups", {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
        params: new HttpParams().set("customer", "my_customer").set("pageToken", nextToken),
      })
      .pipe(catchError(this.authService.handleAPIError("getGroupsData")));
  }

  async addGroupMembership(groupID: string, newMember) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise((resolve) => {
      this.addGroupMembershipData(groupID, newMember).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private addGroupMembershipData(groupID: string, newMember) {
    return this.http
      .post("https://www.googleapis.com/admin/directory/v1/groups/" + groupID + "/members", JSON.stringify(newMember), {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError("addGroupMembershipData")));
  }

  async deleteGroupMembership(groupID: string, email: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise((resolve) => {
      this.deleteGroupMembershipData(groupID, email).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private deleteGroupMembershipData(groupID: string, email: string) {
    return this.http
      .delete("https://www.googleapis.com/admin/directory/v1/groups/" + groupID + "/members/" + email, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError("deleteGroupMembershipData")));
  }

  private buildQuery(nameAndEmail = "", givenName = "", familyName = "", email = "", OUSearch = "", orgUnitPath = "/") {
    let query = "";

    if (OUSearch !== "") {
      if (OUSearch.indexOf("'") !== -1) {
        OUSearch = OUSearch.replace(/'/g, "\\'");
      }
      query += "orgUnitPath='" + encodeURIComponent(OUSearch) + "'";
    } else if (orgUnitPath !== "") {
      if (orgUnitPath.indexOf("'") !== -1) {
        orgUnitPath = orgUnitPath.replace(/'/g, "\\'");
      }
      query += "orgUnitPath='" + encodeURIComponent(orgUnitPath) + "'";
    }

    if (givenName !== "" || familyName !== "") {
      query += " givenName:" + givenName + "* familyName:" + familyName + "*";
    }
    if (email !== "") {
      query += " email:*" + email.replace(/\s/g, "") + "*";
    }

    if (nameAndEmail !== "") {
      query += " " + nameAndEmail;
    }

    return query;
  }
}

export interface resetPassword {
  changePasswordAtNextLogin: boolean;
  password: string;
}
