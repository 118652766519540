<div class="row dashboard__links">
  <ng-container *ngFor="let link of leftNavService.leftNavLinks">
    <div
      *ngIf="moduleService.isValidModule(link.module.name) && link.name !== 'Dashboard' && link.location == 'top'"
      class="col-12 col-md-6 col-lg-4 col-xl-3 mt-2"
    >
      <a
        [routerLink]="
          link.error ? '/settings/product/service-account' : link.loading || link.disabled ? '#' : link.routerLink
        "
        [fragment]="link.error ? 'serviceAccounts' : ''"
        class="box"
        [class.disabled]="link.loading || link.disabled"
      >
        <mat-card class="dashboard__card">
          <div class="px-0 text-center m-auto">
            <div
              class="text-center"
              [ngClass]="link.loading || link.disabled ? 'text-muted' : link.error ? 'text-danger' : link.color"
            >
              <span
                style="font-size: 40px"
                class="material-icons"
                matBadgePosition="after"
                matBadgeSize="large"
                [matBadgeHidden]="!link.loading"
                matBadge="filter_tilt_shift"
                >{{ link.error ? "error" : link.icon }}</span
              >
            </div>
            <h2 class="mt-2">
              {{ link.name }}
            </h2>
            <p class="mb-0">
              {{ !link.disabled ? link.description : leftNavService.serviceAccountRequiredMessage }}
            </p>
          </div>
        </mat-card>
      </a>
    </div>
  </ng-container>
</div>
