import { Customer } from "../../services/admin-plus/customer.service";
import { UserAction } from "./base";
import { Building } from "./building";

export interface IRoom {
	id: number;
	customer: Customer;
	createdBy: UserAction;
	updatedBy: UserAction;
	building: Building;
	name: string;
	imported: boolean;
}

export class Room implements IRoom {
	id: number;
	customer: Customer;
	createdBy: UserAction;
	updatedBy: UserAction;
	building: Building;
	name: string;
	imported = false;

	constructor(properties) {
		Object.assign(this, properties);
	}
}
