import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output, SimpleChanges } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { map, startWith } from "rxjs/operators";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { FiltersService, Filter, FilterType } from "src/app/services/utilities/filters.service";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { FilterDialogComponent } from "src/app/layout/dialogs/filter-dialog/filter-dialog.component";
import { ConfirmationDialogComponent } from "src/app/layout/dialogs/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: "app-filters",
  templateUrl: "./app-filters.component.html",
  styleUrls: ["./app-filters.component.scss"],
})
export class AppFiltersComponent implements OnInit {
  @Input() filterType: FilterType;
  @Input() inputSelectedFilters: Filter[];
  @Input() selectedOu: string;
  @Input() driveId: number;
  @Input() userId: number;
  @Input() md5ChecksumValue = "";
  @Input() role: string;
  @Input() auditDataType: string;
  @Output() dataEvent = new EventEmitter();

  page = 1;
  offset = 0;

  // Filter code
  filterControl = new UntypedFormControl();
  filteredFilters: Observable<Filter[]>;
  sortedFilters: Observable<Filter[]>;
  selectedFilters: Filter[] = [];
  selectable = true;
  removable = true;
  filterFromUser = false;

  /* filtered search stuff */
  usageSizeSearch = "0";
  firstNameSearch = "";
  fileNameSearch = "";
  lastNameSearch = "";
  emailSearch = "";
  sharedDriveSearch = "";
  organizerSearch = "";
  readerSearch = "";
  commenterSearch = "";
  writerSearch = "";
  fileOrganizerSearch = "";
  trashType = false;
  fileType = "";
  ouSearch = "";
  lastViewedBefore = "";
  lastViewedAfter = "";
  lastModifiedBy = "";
  flaggedSearch = "all";
  totalUsers = "";
  externalUsers = "";
  recentUsers = "";
  model = "";
  statusSearch = "";
  serialNumber = "";
  createdAfterSearch = "";
  createdBeforeSearch = "";
  typeSearch = "";
  organizerEmailSearch = "";
  participantNameSearch = "";
  conferenceIdSearch = "";
  deviceTypeSearch = "";
  externalParticipantSearch = false;
  durationSearch = "0";
  titleFilter = "";
  buildingName = "";
  workflowName = "";
  workflowType = "";
  workflowActivity = "";
  workflowEvent = "";
  folderName = "";
  folderId = "";
  createdByUser = "";
  updatedByUser = "";
  updatedAfter = "";
  updatedBefore = "";
  lastRunBefore = "";
  lastRunAfter = "";
  enabled = false;
  disabled = false;
  deleted = false;
  errored = false;
  executed = false;
  logName = "";
  logType = "";
  logDate = "";
  type = 0;
  actor = "";
  nameSearch = "";
  description = "";
  daysFlagged = "";
  flaggedDateBefore = "";
  messageTitleSearch = "";
  recipientEmailSearch = "";
  status = null;
  unassigned = false;
  subject = "";
  priority = "";
  category = "";
  channel = "";
  ticketCreatedBy = "";
  assignedAgent = "";
  ticketCreatedAfter = "";
  ticketCreatedBefore = "";
  ticketUpdatedAfter = "";
  ticketUpdatedBefore = "";
  tag = "";
  assigned = false;
  requestedBy = "";
  object = "";
  method = "";
  auditType = "";
  createdBy = "";
  assignedUser = "";
  manufacturer = 0;
  room = 0;
  objectId = "";
  logData = "";
  reportLogType = ""; // will need to be removed one day because of new Audit Logs area
  ownerSuspendedType = false;
  autoUpdateExpiration = "";
  annotatedLocation = "";
  auditLogDataSearch = "";
  active = 0;
  selectedModule = "";

  pageData = {
    page: this.page,
    offset: this.offset,
  };

  data = {
    usageSizeSearch: this.usageSizeSearch,
    firstNameSearch: this.firstNameSearch,
    fileNameSearch: this.fileNameSearch,
    lastNameSearch: this.lastNameSearch,
    emailSearch: this.emailSearch,
    sharedDriveSearch: this.sharedDriveSearch,
    organizerSearch: this.organizerSearch,
    readerSearch: this.readerSearch,
    commenterSearch: this.commenterSearch,
    writerSearch: this.writerSearch,
    fileOrganizerSearch: this.fileOrganizerSearch,
    trashType: this.trashType,
    fileType: this.fileType,
    ouSearch: this.ouSearch,
    lastViewedBefore: this.lastViewedBefore,
    lastViewedAfter: this.lastViewedAfter,
    flaggedSearch: this.flaggedSearch,
    totalUsers: this.totalUsers,
    externalUsers: this.externalUsers,
    createdAfterSearch: this.createdAfterSearch,
    createdBeforeSearch: this.createdBeforeSearch,
    typeSearch: this.typeSearch,
    recentUsers: this.recentUsers,
    model: this.model,
    statusSearch: this.statusSearch,
    serialNumber: this.serialNumber,
    organizerEmailSearch: this.organizerEmailSearch,
    participantNameSearch: this.participantNameSearch,
    conferenceIdSearch: this.conferenceIdSearch,
    deviceTypeSearch: this.deviceTypeSearch,
    externalParticipantSearch: this.externalParticipantSearch,
    durationSearch: this.durationSearch,
    titleFilter: this.titleFilter,
    userId: 0,
    md5ChecksumValue: this.md5ChecksumValue,
    lastModifiedBy: this.lastModifiedBy,
    buildingName: this.buildingName,
    workflowName: this.workflowName,
    workflowType: this.workflowType,
    workflowActivity: this.workflowActivity,
    workflowEvent: this.workflowEvent,
    folderName: this.folderName,
    folderId: this.folderId,
    createdByUser: this.createdByUser,
    updatedByUser: this.updatedByUser,
    updatedAfter: this.updatedAfter,
    updatedBefore: this.updatedBefore,
    lastRunBefore: this.lastRunBefore,
    lastRunAfter: this.lastRunAfter,
    enabled: this.enabled,
    disabled: this.disabled,
    deleted: this.deleted,
    executed: this.executed,
    errored: this.errored,
    logName: this.logName,
    logType: this.logType,
    logDate: this.logDate,
    actor: this.actor,
    type: this.type,
    page: this.page,
    offset: this.offset,
    nameSearch: this.nameSearch,
    description: this.description,
    daysFlagged: this.daysFlagged,
    flaggedDateBefore: this.flaggedDateBefore,
    messageTitleSearch: this.messageTitleSearch,
    recipientEmailSearch: this.recipientEmailSearch,
    filtersCount: this.selectedFilters.length,
    status: this.status,
    unassigned: this.unassigned,
    subject: this.subject,
    tag: this.tag,
    priority: this.priority,
    category: this.category,
    channel: this.channel,
    ticketCreatedBy: this.ticketCreatedBy,
    assignedAgent: this.assignedAgent,
    ticketCreatedAfter: this.ticketCreatedAfter,
    ticketCreatedBefore: this.ticketCreatedBefore,
    ticketUpdatedAfter: this.ticketUpdatedAfter,
    ticketUpdatedBefore: this.ticketUpdatedBefore,
    assigned: this.assigned,
    requestedBy: this.requestedBy,
    createdBy: this.createdBy,
    assignedUser: this.assignedUser,
    manufacturer: this.manufacturer,
    room: this.room,
    object: this.object,
    method: this.method,
    auditType: this.auditType,
    objectId: this.objectId,
    logData: this.logData,
    reportLogType: this.reportLogType,
    ownerSuspendedType: this.ownerSuspendedType,
    autoUpdateExpiration: this.autoUpdateExpiration,
    annotatedLocation: this.annotatedLocation,
    auditLogDataSearch: this.auditLogDataSearch,
    active: this.active,
    selectedModule: this.selectedModule,
  };

  @ViewChild(MatAutocompleteTrigger, { static: true })
  trigger: MatAutocompleteTrigger;
  @ViewChild("filtersInput", { static: false }) public filtersRef: ElementRef;

  constructor(
    private filtersService: FiltersService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit() {
    if (this.inputSelectedFilters) {
      for (let i = 0; i < this.inputSelectedFilters.length; i++) {
        this.addFilter(this.inputSelectedFilters[i]);
      }
    }
    this.filteredFilters = this.filterControl.valueChanges.pipe(
      startWith(""),
      map((value: string) => this._filter(value))
    );
    this.readNav();
  }

  async ngOnChanges(change: SimpleChanges) {
    if (change?.auditDataType) this.auditDataType = change.auditDataType.currentValue;
  }

  onFilterFocus() {
    this.trigger._onChange("");
    this.trigger.openPanel();
  }

  refreshFilters() {
    this.filteredFilters = this.filterControl.valueChanges.pipe(
      startWith(""),
      map((value: string) => this._filter(value))
    );
  }

  async showFilterDialog(filter: Filter) {
    if (filter.inputType !== "checkbox") {
      const dialogRef = this.dialog.open(FilterDialogComponent, {
        width: "250px",
        autoFocus: true,
        disableClose: true,
        closeOnNavigation: true,
        data: {
          filterType: this.filterType,
          firstName: this.firstNameSearch,
          lastName: this.lastNameSearch,
          email: this.emailSearch,
          flagged: this.flaggedSearch,
          usageSize: this.usageSizeSearch,
          lastViewedBefore: this.lastViewedBefore,
          lastViewedAfter: this.lastViewedAfter,
          fileName: this.fileNameSearch,
          fileType: this.fileType,
          trashType: this.trashType,
          sharedDrive: this.sharedDriveSearch,
          organizer: this.organizerSearch,
          reader: this.readerSearch,
          commenter: this.commenterSearch,
          writer: this.writerSearch,
          fileOrganizer: this.fileOrganizerSearch,
          recentUsers: this.recentUsers,
          model: this.model,
          serialNumber: this.serialNumber,
          driveId: this.driveId,
          ouSearch: this.ouSearch ? this.ouSearch : this.selectedOu,
          md5ChecksumSearch: this.md5ChecksumValue,
          lastModifiedBy: this.lastModifiedBy,
          buildingName: this.buildingName,
          workflowName: this.workflowName,
          workflowType: this.workflowType,
          workflowActivity: this.workflowActivity,
          workflowEvent: this.workflowEvent,
          folderName: this.folderName,
          folderId: this.folderId,
          createdByUser: this.createdByUser,
          updatedByUser: this.updatedByUser,
          updatedAfter: this.updatedAfter,
          updatedBefore: this.updatedBefore,
          lastRunBefore: this.lastRunBefore,
          lastRunAfter: this.lastRunAfter,
          enabled: this.enabled,
          disabled: this.disabled,
          deleted: this.deleted,
          executed: this.executed,
          errored: this.errored,
          logName: this.logName,
          logType: this.logType,
          logDate: this.logDate,
          actor: this.actor,
          type: this.type,
          filter: filter,
          positionRelativeToElement: this.filtersRef,
          nameSearch: this.nameSearch,
          description: this.description,
          daysFlagged: this.daysFlagged,
          flaggedDateBefore: this.flaggedDateBefore,
          filtersCount: this.selectedFilters.length,
          status: this.status,
          unassigned: this.unassigned,
          subject: this.subject,
          tag: this.tag,
          priority: this.priority,
          category: this.category,
          channel: this.channel,
          ticketCreatedBy: this.ticketCreatedBy,
          assignedAgent: this.assignedAgent,
          ticketCreatedAfter: this.ticketCreatedAfter,
          ticketCreatedBefore: this.ticketCreatedBefore,
          ticketUpdatedAfter: this.ticketUpdatedAfter,
          ticketUpdatedBefore: this.ticketUpdatedBefore,
          assigned: this.assigned,
          requestedBy: this.requestedBy,
          createdBy: this.createdBy,
          assignedUser: this.assignedUser,
          manufacturer: this.manufacturer,
          room: this.room,
          object: this.object,
          method: this.method,
          auditType: this.auditType,
          objectId: this.objectId,
          logData: this.logData,
          reportLogType: this.reportLogType,
          ownerSuspendedType: this.ownerSuspendedType,
          autoUpdateExpiration: this.autoUpdateExpiration,
          annotatedLocation: this.annotatedLocation,
          auditLogDataSearch: this.auditLogDataSearch,
          active: this.active,
          selectedModule: this.auditDataType,
        },
      });
      dialogRef.afterClosed().subscribe((filter) => {
        if (filter !== undefined) {
          if (typeof filter.search !== "undefined") {
            let filteredValue = "";
            if (typeof filter.search === "string") {
              filteredValue = filter.search.trim();
            } else {
              filteredValue = filter.search;
            }
            const filteredOptions = filter.options ? filter.options[0]["selected"] : false;
            if (filteredValue || filteredOptions || (!filteredValue && filter.inputType == "select")) {
              this.addFilter(filter);
            }
          }
        }
      });
    } else {
      filter.options[0]["selected"] = true;
      this.addFilter(filter);
    }
    this.filtersRef.nativeElement.value = "";
  }

  async editChip(filter: Filter) {
    const originalFilter = filter;
    if (filter.inputType == "checkbox") {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: true,
        data: {
          title: "Remove Filter",
          message: "Are you sure you want to remove filter?",
          btnOkText: "Yes",
          btnCancelText: "Cancel",
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.removeFilter(originalFilter);
        }
      });
    } else {
      const dialogRef = this.dialog.open(FilterDialogComponent, {
        width: "250px",
        autoFocus: true,
        disableClose: true,
        closeOnNavigation: true,
        data: {
          filterType: this.filterType,
          firstName: this.firstNameSearch,
          lastName: this.lastNameSearch,
          email: this.emailSearch,
          flagged: this.flaggedSearch,
          usageSize: this.usageSizeSearch,
          lastViewedBefore: this.lastViewedBefore,
          lastViewedAfter: this.lastViewedAfter,
          fileName: this.fileNameSearch,
          fileType: this.fileType,
          trashType: this.trashType,
          sharedDrive: this.sharedDriveSearch,
          organizer: this.organizerSearch,
          reader: this.readerSearch,
          commenter: this.commenterSearch,
          writer: this.writerSearch,
          fileOrganizer: this.fileOrganizerSearch,
          recentUsers: this.recentUsers,
          model: this.model,
          serialNumber: this.serialNumber,
          driveId: this.driveId,
          ouSearch: this.ouSearch,
          md5ChecksumSearch: this.md5ChecksumValue,
          lastModifiedBy: this.lastModifiedBy,
          buildingName: this.buildingName,
          workflowName: this.workflowName,
          workflowType: this.workflowType,
          workflowActivity: this.workflowActivity,
          workflowEvent: this.workflowEvent,
          folderName: this.folderName,
          createdByUser: this.createdByUser,
          updatedByUser: this.updatedByUser,
          updatedAfter: this.updatedAfter,
          updatedBefore: this.updatedBefore,
          lastRunBefore: this.lastRunBefore,
          lastRunAfter: this.lastRunAfter,
          enabled: this.enabled,
          disabled: this.disabled,
          deleted: this.deleted,
          executed: this.executed,
          errored: this.errored,
          logName: this.logName,
          logType: this.logType,
          logDate: this.logDate,
          actor: this.actor,
          type: this.type,
          filter: filter,
          nameSearch: this.nameSearch,
          description: this.description,
          positionRelativeToElement: this.filtersRef,
          daysFlagged: this.daysFlagged,
          flaggedDateBefore: this.flaggedDateBefore,
          messageTitle: this.messageTitleSearch,
          recipientEmail: this.recipientEmailSearch,
          filtersCount: this.selectedFilters.length,
          status: this.status,
          unassigned: this.unassigned,
          subject: this.subject,
          tag: this.tag,
          priority: this.priority,
          category: this.category,
          channel: this.channel,
          ticketCreatedBy: this.ticketCreatedBy,
          assignedAgent: this.assignedAgent,
          ticketCreatedAfter: this.ticketCreatedAfter,
          ticketCreatedBefore: this.ticketCreatedBefore,
          ticketUpdatedAfter: this.ticketUpdatedAfter,
          ticketUpdatedBefore: this.ticketUpdatedBefore,
          assigned: this.assigned,
          requestedBy: this.requestedBy,
          createdBy: this.createdBy,
          assignedUser: this.assignedUser,
          manufacturer: this.manufacturer,
          room: this.room,
          object: this.object,
          method: this.method,
          auditType: this.auditType,
          objectId: this.objectId,
          logData: this.logData,
          reportLogType: this.reportLogType,
          ownerSuspendedType: this.ownerSuspendedType,
          autoUpdateExpiration: this.autoUpdateExpiration,
          annotatedLocation: this.annotatedLocation,
          auditLogDataSearch: this.auditLogDataSearch,
          active: this.active,
          selectedModule: this.auditDataType,
        },
      });
      dialogRef.afterClosed().subscribe((modifiedFilter) => {
        if (modifiedFilter) {
          filter = modifiedFilter;
          this.addFilter(filter, "edit");
        }
      });
    }
  }

  sendData() {
    this.data = {
      usageSizeSearch: this.usageSizeSearch,
      firstNameSearch: this.firstNameSearch,
      fileNameSearch: this.fileNameSearch,
      lastNameSearch: this.lastNameSearch,
      emailSearch: this.emailSearch,
      sharedDriveSearch: this.sharedDriveSearch,
      organizerSearch: this.organizerSearch,
      readerSearch: this.readerSearch,
      commenterSearch: this.commenterSearch,
      writerSearch: this.writerSearch,
      fileOrganizerSearch: this.fileOrganizerSearch,
      trashType: this.trashType,
      fileType: this.fileType,
      ouSearch: this.ouSearch,
      lastViewedBefore: this.lastViewedBefore,
      lastViewedAfter: this.lastViewedAfter,
      flaggedSearch: this.flaggedSearch,
      totalUsers: this.totalUsers,
      externalUsers: this.externalUsers,
      createdAfterSearch: this.createdAfterSearch,
      createdBeforeSearch: this.createdBeforeSearch,
      typeSearch: this.typeSearch,
      recentUsers: this.recentUsers,
      model: this.model,
      statusSearch: this.statusSearch,
      serialNumber: this.serialNumber,
      organizerEmailSearch: this.organizerEmailSearch,
      participantNameSearch: this.participantNameSearch,
      conferenceIdSearch: this.conferenceIdSearch,
      deviceTypeSearch: this.deviceTypeSearch,
      externalParticipantSearch: this.externalParticipantSearch,
      durationSearch: this.durationSearch,
      titleFilter: this.titleFilter,
      userId: this.userId,
      md5ChecksumValue: this.md5ChecksumValue,
      lastModifiedBy: this.lastModifiedBy,
      buildingName: this.buildingName,
      workflowName: this.workflowName,
      workflowType: this.workflowType,
      workflowActivity: this.workflowActivity,
      workflowEvent: this.workflowEvent,
      folderName: this.folderName,
      folderId: this.folderId,
      createdByUser: this.createdByUser,
      updatedByUser: this.updatedByUser,
      updatedAfter: this.updatedAfter,
      updatedBefore: this.updatedBefore,
      lastRunBefore: this.lastRunBefore,
      lastRunAfter: this.lastRunAfter,
      enabled: this.enabled,
      disabled: this.disabled,
      deleted: this.deleted,
      executed: this.executed,
      errored: this.errored,
      logName: this.logName,
      logType: this.logType,
      logDate: this.logDate,
      actor: this.actor,
      type: this.type,
      page: this.page,
      offset: this.offset,
      nameSearch: this.nameSearch,
      description: this.description,
      daysFlagged: this.daysFlagged,
      flaggedDateBefore: this.flaggedDateBefore,
      recipientEmailSearch: this.recipientEmailSearch,
      messageTitleSearch: this.messageTitleSearch,
      filtersCount: this.selectedFilters.length,
      status: this.status,
      unassigned: this.unassigned,
      subject: this.subject,
      tag: this.tag,
      priority: this.priority,
      category: this.category,
      channel: this.channel,
      ticketCreatedBy: this.ticketCreatedBy,
      assignedAgent: this.assignedAgent,
      ticketCreatedAfter: this.ticketCreatedAfter,
      ticketCreatedBefore: this.ticketCreatedBefore,
      ticketUpdatedAfter: this.ticketUpdatedAfter,
      ticketUpdatedBefore: this.ticketUpdatedBefore,
      assigned: this.assigned,
      requestedBy: this.requestedBy,
      createdBy: this.createdBy,
      assignedUser: this.assignedUser,
      manufacturer: this.manufacturer,
      room: this.room,
      object: this.object,
      method: this.method,
      auditType: this.auditType,
      objectId: this.objectId,
      logData: this.logData,
      reportLogType: this.reportLogType,
      ownerSuspendedType: this.ownerSuspendedType,
      autoUpdateExpiration: this.autoUpdateExpiration,
      annotatedLocation: this.annotatedLocation,
      auditLogDataSearch: this.auditLogDataSearch,
      active: this.active,
      selectedModule: this.auditDataType,
    };
    this.dataEvent.emit(this.data);
  }

  resetToFirstPage() {
    this.page = 1;
    this.offset = 0;
    this.sendData();
  }

  addFilter(filter: Filter, action = "add") {
    this.setFilterValue(filter);
    this.filtersService.updateNavFilters(filter, this.filterType, "add");
    if (this.filterFromUser && filter.name != "First Name" && filter.name != "Last Name") this.filterFromUser = false;
    if (action == "add") {
      this.selectedFilters.push(filter);
    }
    this.resetToFirstPage();
    this.refreshFilters();
  }

  removeFilter(filter: Filter) {
    if (filter.name === "Workflows in Folder") {
      this.router.navigate(["/automation/workflows"]);
    }
    filter.search = null;
    filter.id = null;
    if (filter.inputType == "checkbox") filter.options[0].selected = false;
    for (let i = this.selectedFilters.length - 1; i >= 0; i--) {
      if (filter.name == this.selectedFilters[i].name) {
        this.selectedFilters.splice(i, 1);
        break;
      }
    }
    this.setFilterValue(filter);
    this.filtersService.updateNavFilters(filter, this.filterType);
    this.resetToFirstPage();
    this.refreshFilters();
  }

  setFilterValue(filter: Filter) {
    switch (filter.name) {
      case "Usage Amount":
        this.usageSizeSearch = filter.search ?? "0";
        break;
      case "First Name":
        this.firstNameSearch = filter.search ?? "";
        break;
      case "Last Name":
        this.lastNameSearch = filter.search ?? "";
        break;
      case "Created By User":
        this.createdByUser = filter.search ?? "";
        break;
      case "Updated By User":
        this.updatedByUser = filter.search ?? "";
        break;
      case "Email":
        this.emailSearch = filter.search ?? "";
        this.userId = filter.search ? this.userId : 0;
        break;
      case "File Name":
        this.fileNameSearch = filter.search ?? "";
        break;
      case "Hide Trashed":
        this.trashType = filter.options[0].selected === true;
        filter.search = filter.options[0].selected.toString();
        break;
      case "Enabled":
        this.enabled = filter.options[0].selected === true;
        filter.search = filter.options[0].selected.toString();
        break;
      case "Disabled":
        this.disabled = filter.options[0].selected === true;
        filter.search = filter.options[0].selected.toString();
        break;
      case "Executed":
        this.executed = filter.options[0].selected === true;
        filter.search = filter.options[0].selected.toString();
        break;
      case "Errored":
        this.errored = filter.options[0].selected === true;
        filter.search = filter.options[0].selected.toString();
        break;
      case "Deleted":
        this.deleted = filter.options[0].selected === true;
        filter.search = filter.options[0].selected.toString();
        break;
      case "MD5 Checksum":
        this.md5ChecksumValue = filter.search ?? "";
        break;
      case "Last Modified By":
        this.lastModifiedBy = filter.search ?? "";
        break;
      case "Building Name":
        this.buildingName = filter.search ?? "";
        break;
      case "Workflow Name":
        this.workflowName = filter.search ?? "";
        break;
      case "Workflow Type":
        this.workflowType = filter.search ?? "";
        break;
      case "Workflow Activity":
        this.workflowActivity = filter.search ?? "";
        break;
      case "Workflow Event":
        this.workflowEvent = filter.search ?? "";
        break;
      case "Folder Name":
        this.folderName = filter.search ?? "";
        break;
      case "Workflows in Folder":
        this.folderId = filter.search ?? "";
        break;
      case "File Type":
        this.fileType = filter.search ?? "";
        break;
      case "Shared Drive":
        this.sharedDriveSearch = filter.search ?? "";
        this.driveId = filter.search ? this.driveId : 0;
        break;
      case "Organizational Unit":
        this.ouSearch = filter.search ?? "";
        break;
      case "Last Viewed by Owner Before":
        this.lastViewedBefore = filter.search ?? "";
        break;
      case "Last Viewed by Owner After":
        this.lastViewedAfter = filter.search ?? "";
        break;
      case "Last Run Before":
        this.lastRunBefore = filter.search ?? "";
        break;
      case "Last Run After":
        this.lastRunAfter = filter.search ?? "";
        break;
      case "Flagged for Excessive Usage":
        this.flaggedSearch = filter.options[0].selected ? filter.options[0].selected.toString() : "all";
        filter.search = filter.options[0].selected.toString();
        break;
      case "Shared Drive Name":
        this.sharedDriveSearch = filter.search ?? "";
        this.driveId = filter.search ? this.driveId : 0;
        break;
      case "value Manager":
        this.fileOrganizerSearch = filter.search ?? "";
        break;
      case "Viewer":
        this.readerSearch = filter.search ?? "";
        break;
      case "Commenter":
        this.commenterSearch = filter.search ?? "";
        break;
      case "Contributor":
        this.writerSearch = filter.search ?? "";
        break;
      case "Manager":
        this.organizerSearch = filter.search ?? "";
        break;
      case "Number of Users":
        this.totalUsers = filter.search ?? "";
        break;
      case "Contains External Users":
        this.externalUsers = filter.options[0].selected ? filter.options[0].selected.toString() : "";
        filter.search = filter.options[0].selected.toString();
        break;
      case "Recent Users":
        this.recentUsers = filter.search ?? "";
        break;
      case "Model":
        this.model = filter.search ?? "";
        break;
      case "Serial Number":
        this.serialNumber = filter.search ?? "";
        break;
      case "Created Before":
        this.createdBeforeSearch = filter.search ?? "";
        break;
      case "Created After":
        this.createdAfterSearch = filter.search ?? "";
        break;
      case "Updated Before":
        this.updatedBefore = filter.search ?? "";
        break;
      case "Updated After":
        this.updatedAfter = filter.search ?? "";
        break;
      case "Job Status":
        this.statusSearch = filter.search ?? "";
        break;
      case "Job Type":
        this.typeSearch = filter.search ?? "";
        break;
      case "Organizer Email":
        this.organizerEmailSearch = filter.search ?? "";
        break;
      case "Participant Name":
        this.participantNameSearch = filter.search ?? "";
        break;
      case "Conference ID":
        this.conferenceIdSearch = filter.search ?? "";
        break;
      case "Device Type":
        this.deviceTypeSearch = filter.search && filter.search !== "" ? filter.search.replace(" ", "_").toLowerCase() : "";
        break;
      case "External Participant":
        this.externalParticipantSearch = filter.options[0].selected === true;
        filter.search = filter.options[0].selected.toString();
        break;
      case "Duration":
        this.durationSearch = filter.search ?? "0";
        filter.search = this.filtersService.formatDurationFilterDisplay(parseFloat(this.durationSearch));
        break;
      case "Title":
        this.titleFilter = filter.search ?? "";
        break;
      case "Log Name":
        this.logName = filter.search ?? "";
        break;
      case "Log Type":
        this.logType = filter.search ?? "";
        break;
      case "Log Date":
        this.logDate = filter.search ?? "";
        break;
      case "Actor":
        this.actor = filter.search ?? "";
        break;
      case "Days Flagged":
        this.daysFlagged = filter.search ?? "";
        break;
      case "Flagged Date Before":
        this.flaggedDateBefore = filter.search ?? "";
        break;
      case "Type":
        this.type = filter.id ?? 0;
        break;
      case "Name":
        this.nameSearch = filter.search ?? "";
        break;
      case "Description":
        this.description = filter.search ?? "";
        break;
      case "Message Title":
        this.messageTitleSearch = filter.search ?? "";
        break;
      case "Recipient Email":
        this.recipientEmailSearch = filter.search ?? "";
        break;
      case "Status":
        this.status = filter.search ?? "";
        break;
      case "Unassigned":
        this.unassigned = filter.options[0].selected === true;
        filter.search = filter.options[0].selected.toString();
        break;
      case "Subject":
        this.subject = filter.search ?? "";
        break;
      case "Tag": {
        this.tag = filter.search ?? "";
        break;
      }
      case "Priority":
        this.priority = filter.search ?? "";
        break;
      case "Category":
        this.category = filter.search ?? "";
        break;
      case "Channel":
        this.channel = filter.search ?? "";
        break;
      case "Ticket Created By":
        this.ticketCreatedBy = filter.search ?? "";
        break;
      case "Assigned Agent":
        this.assignedAgent = filter.search ?? "";
        break;
      case "Ticket Created After":
        this.ticketCreatedAfter = filter.search ?? "";
        break;
      case "Ticket Created Before":
        this.ticketCreatedBefore = filter.search ?? "";
        break;
      case "Ticket Updated After":
        this.ticketUpdatedAfter = filter.search ?? "";
        break;
      case "Ticket Updated Before":
        this.ticketUpdatedBefore = filter.search ?? "";
        break;
      case "Assigned":
        this.assigned = filter.options[0].selected === true;
        filter.search = filter.options[0].selected.toString();
        break;
      case "Requested By":
        this.requestedBy = filter.search ?? "";
        break;
      case "Object Type":
        this.object = filter.search ?? "";
        break;
      case "Action":
        this.method = filter.search ?? "";
        break;
      case "Tool":
        this.auditType = filter.search ?? "";
        break;
      case "Created By":
        this.createdBy = filter.search ?? "";
        break;
      case "Assigned User":
        this.assignedUser = filter.search ?? "";
        break;
      case "Manufacturer":
        this.manufacturer = filter.id ?? 0;
        break;
      case "Room":
        this.room = filter.id ?? 0;
        break;
      case "Object ID":
        this.objectId = filter.search ?? "";
        break;
      case "Log Data":
        this.logData = filter.search ?? "";
        break;
      // will need to be removed one day because of new Audit Logs area
      case "Report Log Type":
        this.reportLogType = filter.search ?? "";
        break;
      case "Owner Is Suspended":
        this.ownerSuspendedType = filter.options[0].selected === true;
        filter.search = filter.options[0].selected.toString();
        break;
      case "Auto Update Expiration After Date":
        this.autoUpdateExpiration = filter.search ?? "";
        break;
      case "Custom Location":
        this.annotatedLocation = filter.search ?? "";
        break;
      case "Data":
        this.auditLogDataSearch = filter.search ?? "";
        break;
      case "Active":
        this.active = filter.options[0].selected ? 1 : 0;
        filter.search = filter.options[0].selected.toString();
        break;
      default:
        break;
    }
  }

  readNav() {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      let newFilter = false;
      const tableQueryParams = Object.entries(queryParams).filter((p) => parseInt(p[0].split("_")[1]) == this.filterType);
      if (tableQueryParams.length > 0) {
        for (const [encodedName, encodedSearchValue] of tableQueryParams) {
          const name = decodeURI(encodedName).split("_")[0];
          const searchValue = decodeURI(encodedSearchValue);
          const selectedFilterNames = this.selectedFilters.map((s) => s.name);
          if (!selectedFilterNames.includes(name)) {
            newFilter = true;
            const filter = Object.assign({}, this.filtersService.filters.filter((f) => f.name == name)[0]);
            if (filter.inputType !== "checkbox") {
              if (filter.inputType !== "duration") {
                filter.search = searchValue;
              } else {
                filter.search = `${this.filtersService.formatDurationFilterValue(searchValue)}`;
              }
            } else {
              filter.options[0].selected = searchValue == "true";
            }
            filter.caller = this.filterType;
            this.selectedFilters.push(filter);
            this.setFilterValue(filter);
          }
        }
        if (newFilter) {
          this.refreshFilters();
          this.sendData();
        }
      }
    });
  }

  private _filter(value: string): Filter[] {
    if (typeof value === "string") {
      const filterValue = value.toLowerCase();

      let typedFilters = this.filtersService.filters.filter(
        (option) =>
          option.name.toLowerCase().indexOf(filterValue) === 0 &&
          option.types.includes(this.filterType) &&
          (this.role ? option.roles?.includes(this.role) : true) &&
          this.selectedFilters.filter((e) => e.name === option.name).length === 0
      );
      typedFilters.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
      );
      typedFilters = typedFilters.map((f) => {
        return { ...f, caller: this.filterType };
      });
      for (let i = 0; i < typedFilters.length; i++) {
        if (typedFilters[i].exclude) {
          typedFilters.splice(i, 1);
        }
      }
      return typedFilters;
    } else {
      return [];
    }
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  isString(val): boolean {
    return typeof val === "string";
  }
}
