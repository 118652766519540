<app-page-header [options]="options" (optionSelected)="optionSelected($event)"></app-page-header>
<div class="row">
  <div class="col-12">
    <mat-tab-group mat-align-tabs="start" [(selectedIndex)]="tabIndex" (selectedTabChange)="updateHash($event.index)">
      <mat-tab label="History">
        <app-email-history-table></app-email-history-table>
      </mat-tab>
      <mat-tab label="Preview Report Complete">
        <div class="container">
          <app-email-report-complete></app-email-report-complete>
        </div>
      </mat-tab>
      <mat-tab label="Preview Action Required">
        <div class="container">
          <app-email-action-required></app-email-action-required>
        </div>
      </mat-tab>
      <mat-tab label="Preview Files Pending Trash">
        <div class="container">
          <app-email-files-pending-deletion></app-email-files-pending-deletion>
        </div>
      </mat-tab>
      <mat-tab label="Preview Files Deleted">
        <div class="container">
          <app-email-deleted-files></app-email-deleted-files>
        </div>
      </mat-tab>
      <mat-tab label="Preview Files Moved to Trash">
        <div class="container">
          <app-email-files-trashed></app-email-files-trashed>
        </div>
      </mat-tab>
      <mat-tab label="Preview Action Required Removed">
        <div class="container">
          <app-email-action-required-removed></app-email-action-required-removed>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
