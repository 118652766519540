import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./web/login/login.component";
import { HomeComponent } from "./web/home/home.component";
import { LogoutComponent } from "./web/logout/logout.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { UsersComponent } from "./users/users.component";
import { StorageComponent } from "./storage-plus/storage/storage.component";
import { StorageReportUsersComponent } from "./storage-plus/storage/storage-report-users/storage-report-users.component";
import { StorageReportUserComponent } from "./storage-plus/storage/storage-report-user/storage-report-user.component";
import { StorageReportFilesComponent } from "./storage-plus/storage/storage-report-files/storage-report-files.component";
import { StorageReportEmailsComponent } from "./storage-plus/storage/storage-report-emails/storage-report-emails.component";
import { StorageReportDrivesComponent } from "./storage-plus/storage/storage-report-drives/storage-report-drives.component";
import { StorageReportSharedDriveComponent } from "./storage-plus/storage/storage-report-shared-drive/storage-report-shared-drive.component";
import { StorageReportSettingsComponent } from "./settings/product/storage-report/storage-report-settings/storage-report-settings.component";
import { UserComponent } from "./users/user/user.component";
import { GroupsComponent } from "./groups/groups.component";
import { GroupComponent } from "./groups/group/group.component";
import { AccountComponent } from "./settings/product/account/account.component";
import { EuaComponent } from "./web/eua/eua.component";
import { TosComponent } from "./web/tos/tos.component";
import { AuthGuard } from "./services/admin-plus/auth-guard.service";
import { FreeTrialComponent } from "./web/free-trial/free-trial.component";
import { JobsComponent } from "src/app/storage-plus/jobs/jobs.component";
import { CreateBucketInstructionComponent } from "./settings/product/storage-report/storage-report-settings/dialogs/create-bucket-instruction/create-bucket-instruction.component";
import { StorageReportDuplicateFilesComponent } from "./storage-plus/storage/storage-report-duplicate-files/storage-report-duplicate-files.component";
import { ProductUpdatesComponent } from "./product-updates/product-updates.component";
import { StorageOrganizationalUnitsDashboardComponent } from "./storage-plus/storage/storage-organizational-units-dashboard/storage-organizational-units-dashboard.component";
import { StorageReportOrganizationalUnitsTableComponent } from "src/app/storage-plus/storage/storage-organizational-units-dashboard/storage-report-organizational-units-table/storage-report-organizational-units-table.component";
import { StorageReportOrganizationalUnitUsersComponent } from "src/app/storage-plus/storage/storage-organizational-units-dashboard/storage-report-organizational-units-table/storage-report-organizational-unit-users/storage-report-organizational-unit-users.component";
import { AuditLogsTableComponent } from "./audit-logs/audit-logs-table.component";
import { SignUpComponent } from "./web/free-trial/sign-up/sign-up.component";

const routes: Routes = [
	{
		path: "",
		component: HomeComponent,
	},
	{
		path: "eua",
		component: EuaComponent,
	},
	{
		path: "tos",
		component: TosComponent,
	},
	{
		path: "login",
		component: LoginComponent,
	},
	{
		path: "logout",
		component: LogoutComponent,
	},
	{
		path: "free-trial",
		component: FreeTrialComponent,
	},
	{
		path: "free-trial/sign-up",
		component: SignUpComponent,
	},
	{
		path: "dashboard",
		component: DashboardComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "account",
		component: AccountComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "users",
		component: UsersComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "users/:id",
		component: UserComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "groups",
		component: GroupsComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "groups/:id",
		component: GroupComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "users",
		component: UsersComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "users/:id",
		component: UserComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "groups",
		component: GroupsComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "groups/:id",
		component: GroupComponent,
		canActivate: [AuthGuard],
	},

	/* Reports Module */
	{
		path: "reports/storage",
		component: StorageComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "reports/storage/jobs",
		component: JobsComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "reports/storage/bucket-instruction",
		component: CreateBucketInstructionComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "reports/storage/emails",
		component: StorageReportEmailsComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "reports/storage/users",
		component: StorageReportUsersComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "reports/storage/users/:userId",
		component: StorageReportUserComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "reports/storage/drives",
		component: StorageReportDrivesComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "reports/storage/drives/:driveId",
		component: StorageReportSharedDriveComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "reports/storage/files",
		component: StorageReportFilesComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "reports/storage/duplicate-files",
		component: StorageReportDuplicateFilesComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "reports/storage/config",
		component: StorageReportSettingsComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "reports/storage/organizational-units",
		component: StorageOrganizationalUnitsDashboardComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "reports/storage/organizational-units/ous",
		component: StorageReportOrganizationalUnitsTableComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "reports/storage/organizational-units/:ouId/users",
		component: StorageReportOrganizationalUnitUsersComponent,
		canActivate: [AuthGuard],
	},
	/* End Reports Module */
	{
		path: "automation",
		loadChildren: () => import("./workflow-plus/automation.module").then((m) => m.AutomationModule),
		canActivate: [AuthGuard],
	},
	{
		path: "assets",
		loadChildren: () => import("./asset-plus/chromebook-plus.module").then((m) => m.ChromebookPlusModule),
		canActivate: [AuthGuard],
	},
	{
		path: "ticketing",
		loadChildren: () => import("./ticket-plus/ticket-plus.module").then((m) => m.TicketPlusModule),
		canActivate: [AuthGuard],
	},
	{
		path: "settings",
		loadChildren: () => import("./settings/settings.module").then((m) => m.SettingsModule),
		canActivate: [AuthGuard],
	},
	{
		path: "product-updates",
		component: ProductUpdatesComponent,
		canActivate: [AuthGuard],
	},
	{
		path: "audit-logs",
		component: AuditLogsTableComponent,
		canActivate: [AuthGuard],
	},
	/* Wild Card - Go home */
	{
		path: "**",
		redirectTo: "/",
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			onSameUrlNavigation: "ignore",
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
