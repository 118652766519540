import { Component } from "@angular/core";
import { ModuleService } from "../services/admin-plus/module.service";
import { LeftNavService } from "../services/admin-plus/leftnav.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent {
  constructor(public leftNavService: LeftNavService, public moduleService: ModuleService) {}

  notChips = [
    {
      label: "Chip 1",
      color: "primary",
      chipAvatar: "https://material.angular.io/assets/img/examples/shiba1.jpg",
      isDisabled: true,
      removable: true,
      tooltip: "hello I am da tooltip",
    },
    {
      label: "Chip 2",
      color: "accent",
      chipAvatar: "https://material.angular.io/assets/img/examples/shiba2.jpg",
      hasTooltip: false,
      isDisabled: true,
    },
    {
      label: "Chip 3",
      color: "warn",
      chipAvatar: "https://material.angular.io/assets/img/examples/shiba2.jpg",
      isDisabled: false,
    },
  ];
}
