<div class="container mt-5 mb-5">
  <h1 class="text-center">END-USER AGREEMENT FOR Admin+</h1>
  <p>
    <strong>IMPORTANT READ CAREFULLY:</strong>
    This End-User Agreement ("Agreement") is a legal contract between you and Trafera, LLC (“Trafera”) 
		for your use of the Admin+ software platform ("Admin+"), which includes computer software and related 
		files and settings imaged for you by Trafera (the "Custom Image").
  </p>
  <p>
    BY CLICKING THE "I ACCEPT" ICON BELOW, OR BY USING Admin+, YOU AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT, 
		INCLUDING THE WARRANTY DISCLAIMERS, LIMITATIONS OF LIABILITY, AND TERMINATION PROVISIONS BELOW. IF YOU DO NOT 
		AGREE TO THE TERMS OF THIS AGREEMENT, DO NOT USE Admin+ AND EXIT NOW.
  </p>
  <p>Your use of Admin+ is subject to the Terms of Service in place between your school or organization and Trafera.</p>
  <br />

  <h3 class="text-center">USE LIMITATIONS AND REQUIREMENTS</h3>
  <p>
    You will not rent, lease, lend or provide others with access to Admin+. You may only use Admin+ and any content or data 
		within Admin+ on behalf of your school or organization. You will not (and will not permit others to) modify or interfere 
		with the Admin+ or reverse engineer, decompile, or attempt to discover the source code of Admin+. You will keep 
		confidential Admin+ and all information you receive while working on Admin+. You will follow all policies and procedures 
		of your school or organization.
  </p>
  <br />

  <h3 class="text-center">GOVERNING LAW -ARBITRATION</h3>
  <p>
    This Agreement shall be governed by the laws of the State of Delaware. By using Admin+, you agree that any and all disputes 
		you may have with, or claims you may have against Trafera, arising out of or connected in any way with this Agreement, will 
		be resolved individually, without resort to any form of class or representative action, and exclusively by final and binding 
		arbitration in Minneapolis, Minnesota pursuant to the commercial arbitration rules established by the American Arbitration 
		Association ("AAA"). You acknowledge and agree that neither you nor Trafera will have the right to litigate claims in court, 
		to have a jury trial, or to engage in pre-arbitration discovery except as provided for in the AAA rules. Other rights and 
		remedies that you would otherwise have if you went to court also may not be available in arbitration.
  </p>
  <br />

  <h3 class="text-center">DISCLAIMER OF WARRANTY</h3>
  <p>
    Admin+ AND THE ACCOMPANYING FILES AND DATA ARE PROVIDED "AS IS –WHERE IS." Admin+ AND ITS SUPPLIERS DO NOT AND CANNOT WARRANT 
		THE PERFORMANCE OR RESULTS YOU MAY OBTAIN BY USING Admin+ OR THE ACCURACY OR COMPLETENESS OF ANY DATA OR OUTPUT. TRAFERA AND 
		ITS SUPPLIERS MAKE NO WARRANTIES, EXPRESS OR IMPLIED, AS TO TITLE OR INFRINGEMENT OF THIRD-PARTY RIGHTS, MERCHANTABILITY, 
		ACCURACY, OR COMPLETENESS OF DATA OR FITNESS FOR ANY PARTICULAR PURPOSE.
  </p>
  <p>
    THE USER ASSUMES THE ENTIRE RISK OF USING Admin+. ANY LIABILITY OF TRAFERA RELATING TO Admin+ IS GOVERNED BY THE TERMS OF 
		SERVICE BETWEEN TRAFERA AND YOUR SCHOOL OR ORGANIZATION. IN NO EVENT WILL TRAFERA OR ITS SUPPLIERS BE LIABLE TO YOU FOR ANY 
		DAMAGES IN EXCESS OF THE FEES PAID FOR YOUR Admin+ LICENSE INSTANCE OR FOR ANY CONSEQUENTIAL, INCIDENTAL, OR SPECIAL DAMAGES, 
		INCLUDING ANY LOST PROFITS OR LOST SAVINGS, EVEN IF A TRAFERA REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH 
		DAMAGES, OR FOR ANY CLAIM BY ANY THIRD PARTY.
  </p>
  <p>
    SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR 
		EXCLUSION MAY NOT APPLY TO YOU. THIS WARRANTY GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY 
		FROM STATE TO STATE.
  </p>
	<p>
		Trafera reserves the right to update this Agreement in the future. Any updates will be posted on the Admin+ platform.
	</p>
	<p>
		Updated: June 7, 2024
	</p>
</div>
