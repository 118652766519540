<h2 mat-dialog-title>Open Ticket on Behalf of</h2>
<form autocomplete="off" (ngSubmit)="processForm()">
  <div mat-dialog-content>
    <app-name-email
      (selectedUser)="requestedBy($event)"
      placeholder="Full name or user@domain.com"
      [multiple]="false"
      fieldLabel="Requested By"
      [user]="current_user"
      [requestedBy]="ticket.requestedBy"
      [disabled]="ticket.deleted === 1"
    >
    </app-name-email>
    <div mat-dialog-actions>
      <button class="" mat-stroked-button mat-dialog-close>Cancel</button>
      <button create type="submit" [disabled]="!ticket?.requestedBy?.email && !requstedByUser" mat-stroked-button>
        Save
      </button>
    </div>
  </div>
</form>
