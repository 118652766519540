import { ChannelService } from "src/app/services/ticket-plus/channel.service";
import { Customer } from "src/app/services/admin-plus/customer.service";

export interface IChannel {
  id: number;
  customer?: Customer;
  name?: string;
  icon?: string;
  edit?: boolean;
  parentId?: number;
}

export class Channel implements IChannel {
  id: number;
  customer?: Customer;
  name?: string;
  icon?: string;
  edit?: boolean;
  parentId?: number;
  channelService: ChannelService;

  constructor(properties) {
    Object.assign(this, properties);
  }
}
