import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { GroupsService } from "../../services/admin-plus/groups.service";
import { ActivatedRoute } from "@angular/router";
import { NotificationService } from "../../services/utilities/notification.service";
import {
  ConfirmDialogModel,
  ConfirmationDialogComponent,
} from "src/app/layout/dialogs/confirmation-dialog/confirmation-dialog.component";
import { MatAccordion } from "@angular/material/expansion";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { AdminPlusService } from "src/app/services/admin-plus/admin-plus.service";

@Component({
  selector: "app-group",
  templateUrl: "./group.component.html",
})
export class GroupComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  options = [];
  public user: any;
  public selected_group: any;
  public membership: any;
  public domain: string;
  private id: string;
  public name: string;
  public email: string;
  public enterprise = false;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public groupsService: GroupsService,
    private activatedRoute: ActivatedRoute,
    private notifyService: NotificationService,
    private navService: NavService,
    private adminPlusService: AdminPlusService
  ) {
    const user: any = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.user = user;
      this.domain = this.user.email.replace(/.*@/, "");
    }

    if (user.isAdmin == true) {
      this.options.push({
        icon: "delete",
        name: "Delete Group",
        tooltip: "Delete this group from your organization",
        class: "danger",
      });
    }
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get("id");
    this.getGroup(this.id);
  }

  optionSelected(option) {
    switch (option.name) {
      case "Delete Group":
        this.openConfirmationDialog();
        break;
      default:
        break;
    }
  }

  async getGroup(id: string) {
    this.navService.loading.next(true);
    const response = await this.groupsService.getGroup(id);
    if (response) {
      this.selected_group = response;
      this.groupsService.selected_group = this.selected_group;
      this.name = this.selected_group["name"];
      this.email = this.selected_group.groupKey.id.split("@")[0];
      this.membership = {
        role: "",
      };
      const memResponse = await this.groupsService.getMember(response["name"].replace("groups/", ""));
      if (memResponse) {
        this.membership = memResponse["roles"][0]["name"];
      }
      const res = await this.groupsService.searchTransitiveMemberships(id);
      if (res) {
        this.enterprise = true;
      }

      this.adminPlusService.breadCrumbLinks.push({
        link: null,
        text: this.selected_group.displayName,
        alt: this.selected_group.displayName,
      });
    }
    this.navService.loading.next(false);
  }

  public async editGroup() {
    this.navService.loading.next(true);
    const response = await this.groupsService.editGroup(this.selected_group);
    if (response) {
      this.notifyService.notify(this.selected_group.displayName + " has been updated");
    }
    this.navService.loading.next(false);
  }

  public openConfirmationDialog(): void {
    const message = `Are you sure you want to delete the ${this.selected_group.displayName} group?`;
    const btnOkText = `Delete`;
    const btnCancelText = `Cancel`;
    const dialogData = new ConfirmDialogModel("Delete Group", message, btnOkText, btnCancelText);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      width: "500px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.removeGroup(dialogResult);
      }
    });
  }

  public async removeGroup(confirmed: boolean) {
    if (confirmed) {
      this.navService.loading.next(true);
      const response = await this.groupsService.deleteGroup(this.id);
      if (response) {
        this.navService.loading.next(false);
        this.router.navigateByUrl("/groups");
        this.notifyService.notify("Successfully removed " + this.selected_group.groupKey.id);
      }
    }
  }
}
