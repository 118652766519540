import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/admin-plus/auth.service";

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
})
export class SignUpComponent implements OnInit {
  public url: string;
  constructor(private authService: AuthService) {
    history.replaceState(null, "", window.location.href.replace(window.location.hash, ""));
    this.url = this.authService.createFreeTrialURL();
  }

  ngOnInit(): void {
    window.location.href = this.url;
  }
}
