<ul *ngIf="payload?.data?.length" class="list-group">
  <li *ngFor="let action of payload.data" class="list-group-item">
    <span class="list__title"
      >{{ action.actionName }}<br /><small class="text-muted">{{
        action.timedOut ? "Request timed out" : action.results
      }}</small></span
    >
    <div class="list__subtitle d-flex align-items-center justify-content-end">
      {{ action.created | date : "short" }}
      <mat-icon
        class="ml-2"
        style="transform: scale(0.8)"
        matTooltip="{{ action.timedOut || action.errored ? 'Error' : action.processing ? 'Processing' : 'Success' }}"
        [ngClass]="action.timedOut || action.errored ? 'text-danger' : action.processing ? 'faa-spin' : 'text-success'"
      >
        {{
          action.timedOut || action.errored ? "cancel" : action.processing ? "filter_tilt_shift" : "check_circle"
        }}</mat-icon
      >
    </div>
  </li>
</ul>
