<app-page-header [options]="options" (optionSelected)="optionSelected($event)"></app-page-header>
<div class="row">
  <div *ngIf="user && user.filesSyncing && !user.filesSynced" class="col-12 mt-2">
    <div class="alert alert-info">
      <div class="d-flex align-items-center justify-content-center mb-3">
        <strong>All of the files for this user are currently being re-synced and pulled into the storage report. </strong>
      </div>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
  <div *ngIf="finishedLoading && user.id && user.fileTransfer !== 0" class="col-12 mt-2">
    <div class="alert alert-info text-center d-flex align-items-center justify-content-center">
      <span class="material-icons mat-icon faa-spin mr-3"> filter_tilt_shift </span>
      User's drive data transfer in progress.
    </div>
  </div>
</div>
<div *ngIf="finishedLoading && user.id" id="entireScreen" class="row">
  <div class="col-12 col-md-6 mt-2">
    <mat-card class="p-4">
      <h2 class="">Highlights</h2>
      <table class="table table-striped">
        <tr>
          <td>Total</td>
          <td class="text-right">
            <strong> {{ user.totalStorage / 1024 | number : "1.1-2" }} GB </strong>
          </td>
        </tr>
        <tr>
          <td>Drive</td>
          <td class="text-right">
            <strong> {{ user.driveTotal! / 1024 | number : "1.1-2" }} GB </strong>
          </td>
        </tr>
        <tr>
          <td>Gmail</td>
          <td class="text-right">
            <strong> {{ user.gmailTotal! / 1024 | number : "1.1-2" }} GB </strong>
          </td>
        </tr>
        <tr>
          <td>Photos</td>
          <td class="text-right">
            <strong> {{ user.photosTotal! / 1024 | number : "1.1-2" }} GB </strong>
          </td>
        </tr>
      </table>
      <div *ngIf="user.flagId" class="alert alert-danger text-center">
        <div class="d-flex align-items-center justify-content-center">
          <mat-icon class="mr-2"> feedback </mat-icon>
          Account flagged for excessive usage.
        </div>
        Total storage usage was
        {{ user.previousTotal! / 1024 | number : "1.1-2" }} GB on {{ user.flagDate | date : "short" }}.
      </div>
      <div class="card-footer">
        Usage cached: <strong>{{ user.created | date : "short" }}</strong>
      </div>
    </mat-card>
  </div>

  <div class="col-12 col-md-6 mt-2">
    <mat-card class="p-4">
      <div class="d-flex justify-content-between">
        <h2 class="">Files</h2>

        <div class="btn-group" role="group">
          <button (click)="viewFiles()" mat-stroked-button class="primary">
            <span class="material-icons mat-icon mr-2"> visibility </span>
            View All
          </button>
        </div>
      </div>

      <div *ngIf="user.partialFiles == 1 && user.filesSynced !== null" class="alert alert-info mx-3 px-3">
        <div class="d-flex justify-content-between">
          <p class="pt-3">Only the top 100 files have been synced.</p>
          <button
            class="primary"
            mat-stroked-button
            [disabled]="clickedSyncFiles"
            (click)="resyncFilesConfirmed(); clickedSyncFiles = true"
          >
            Sync Files
          </button>
        </div>
      </div>

      <div *ngIf="!user.filesSynced" class="alert alert-info text-center mt-2">User files are syncing...</div>

      <table class="table table-striped">
        <thead>
          <th>Name</th>
          <th>Type</th>
          <th class="text-right">Used <small class="text-muted"> (GB)</small></th>
        </thead>
        <tr *ngFor="let file of files">
          <td [ngClass]="{ grayout: file.trashed === 1 }">
            <span
              matTooltip="This file is in the user's trash."
              *ngIf="file.trashed === 1"
              style="font-size: 19px"
              class="mr-2 mat-icon material-icons text-danger"
            >
              delete
            </span>
            <span class="text-nowrap">{{ file.name }}</span>
          </td>
          <td [ngClass]="{ grayout: file.trashed === 1 }">
            {{ file.mimeType }}
          </td>
          <td [ngClass]="{ grayout: file.trashed === 1 }" class="text-right">
            <strong>
              {{ file.storageMb / 1024 | number : "1.1-2" }}
            </strong>
          </td>
        </tr>
      </table>
      <div *ngIf="files.length === 0 && user.filesSynced" class="alert alert-info text-center mt-2">
        No files for this user
      </div>

      <div class="card-footer">
        <span *ngIf="user.filesSynced">
          Files cached:
          <strong>{{ user.filesSynced | date : "short" }}</strong>
        </span>
        <span *ngIf="user.filesSyncing && !user.filesSynced">
          Files caching, started:
          <strong>{{ user.filesSyncing | date : "short" }}</strong>
        </span>
      </div>
    </mat-card>
  </div>

  <div class="col-12 col-md-6 mt-2">
    <mat-card class="card p-4">
      <div class="d-flex justify-content-between">
        <h2>Storage by Type</h2>
      </div>
      <div class="">
        <p class="text-center">Total Storage Used</p>
        <mat-icon
          class="icon-display float-right"
          (click)="openMoreInfoDialog()"
          matTooltip="More information on totals"
          aria-label="More information on totals"
          >help</mat-icon
        >
        <p class="text-center pt-2">
          <span style="font-size: 30px">
            {{ user.totalStorage! / 1024 | number : "1.1-2" }}
            <small class="text-muted">GB</small>
          </span>
        </p>
        <div class="pt-4" *ngIf="!user.totalStorage">
          <p class="text-center">Insufficient data</p>
        </div>
        <div class="pt-4 graph__icons" *ngIf="!!user.totalStorage">
          <span class="circleDrive" matTooltip="{{ user.driveTotal! / 1024 | number : '1.1-2' }} GB">Drive</span>
          <span class="circleGmail" matTooltip="{{ user.gmailTotal! / 1024 | number : '1.1-2' }} GB">Gmail</span>
          <span class="circlePhotos" matTooltip="{{ user.photosTotal! / 1024 | number : '1.1-2' }} GB">Photos</span>
        </div>
        <app-storage-report-pie-chart *ngIf="storageByType && storageByType.length" [storageByType]="storageByType">
        </app-storage-report-pie-chart>
        <div *ngIf="!storageByType || !storageByType.length" class="alert alert-danger text-center">
          Unable to retrieve storage by type data.
        </div>
      </div>
      <div class="card-footer">
        Data Cached: <strong>{{ user.created | date : "short" }}</strong>
      </div>
    </mat-card>
  </div>

  <div class="col-12 col-md-6 mt-2">
    <mat-card class="p-4">
      <div class="d-flex justify-content-between">
        <h2>Historical Totals</h2>
      </div>
      <div class="">
        <div class="pt-4">
          <span class="mr-2 circleTotal mat-icon"></span><span class="mr-3">Total</span>
          <span class="mr-2 circleDrive mat-icon"></span><span class="mr-3">Drive</span>
          <span class="mr-2 circleGmail mat-icon"></span><span class="mr-3">Gmail</span>
          <span class="mr-2 circlePhotos mat-icon"></span><span class="mr-3">Photos</span>
        </div>
        <app-storage-report-historical-line-chart *ngIf="historical.length && historicalLoaded" [data]="historical">
        </app-storage-report-historical-line-chart>
        <div *ngIf="!historical.length && historicalLoaded" class="alert alert-danger text-center mt-2">
          Unable to load historical data
        </div>
        <div *ngIf="!historicalLoaded" class="alert alert-info mt-2 d-flex align-items-center justify-content-center">
          <span class="material-icons mat-icon faa-spin mr-3"> filter_tilt_shift </span>
          Loading Historical Data...
        </div>
      </div>
      <div class="card-footer">
        Data Cached:
        <strong>{{ storageReport.lastRun | date : "short" }}</strong>
      </div>
    </mat-card>
  </div>
</div>

<div *ngIf="!finishedLoading" class="row">
  <div class="col-12 mt-2">
    <div class="alert alert-info text-center d-flex align-items-center justify-content-center">
      <span class="material-icons mat-icon faa-spin mr-3"> filter_tilt_shift </span>
      Loading User Data...
    </div>
  </div>
</div>

<div *ngIf="finishedLoading && !user.id" class="row">
  <div class="col-12 mt-2">
    <div class="alert alert-warning text-center">
      <div class="d-flex align-items-center justify-content-center">
        <span class="material-icons mat-icon mr-3"> warning </span>
        Failed to load user data.
      </div>

      <small class="text-muted">
        Please try <a routerLink="../" title="All users">searching</a> for the intended user again.
      </small>
    </div>
  </div>
</div>
