<div class="icon-badge-list" [ngClass]="badgeClass ? badgeClass : ''">
  <mat-icon
    [ngClass]="'!payload?.count => 0' ? 'icon-badge-list__icon--inactive' : ''"
    class="icon-badge-list__icon"
    matBadgePosition="after"
    [matBadge]="matBadge()"
    matBadgeSize="small"
    matBadgeColor="warn"
    matTooltipPosition="above"
    [attr.aria-label]="ariaLabelDisplay"
    [matTooltip]="matTooltipDisplay"
    >{{ iconName }}
  </mat-icon>

  <div *ngIf="payload?.count" class="icon-badge-list__popup" [ngClass]="popupClass ? popupClass : ''">
    <h4 class="icon-badge-list__header fw-semibold p-3 bg-primary text-white mb-0">{{ matSubheaderDisplay }}</h4>
    <ul class="icon-badge-list__list pl-0">
      <li
        class="icon-badge-list__list-item py-2"
        [ngClass]="item.id == ticket.id ? 'cursor-default' : 'cursor-pointer'"
        (click)="openTicket(item.id)"
        *ngFor="let item of payload.data"
      >
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center px-3">
            <mat-icon [ngClass]="item.id == ticket.id ? 'text-muted' : ''" class="icon-badge-list__icon">{{
              iconName
            }}</mat-icon>
            <div class="ml-3">
              <h5 class="icon-badge-list__title" [class.text-muted]="item.id == ticket.id">
                {{ item.subject }}
              </h5>

              <h6 class="icon-badge-list__subtitle" [class.text-muted]="item.id == ticket.id">
                {{ item.createdBy.date | date : "short" }}
                <span *ngIf="item.id == ticket.id">(current ticket)</span>
              </h6>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <a
      *ngIf="displayButton"
      target="_blank"
      href="/ticketing/tickets?Requested%2520By_15={{ ticket.requestedBy.email }}"
      class="footer-button text-center w-100 py-2 mat-shadow mat-shadow-hover"
      mat-button
      >{{ matButtonDisplay }}</a
    >
  </div>
</div>
