import { Injectable } from "@angular/core";
import { Room } from "src/app/models/admin-plus/room";
import { Payload } from "../utilities/utility.service";
import { AdminPlusService } from "./admin-plus.service";

@Injectable({
  providedIn: "root",
})
export class RoomsService extends AdminPlusService {
  public async getRooms(buildingId, params = {}): Promise<Payload> {
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("GET", `/admin-plus/buildings/${buildingId}/rooms`, params).subscribe((payload) => {
        if (payload?.data?.length) {
          resolve(payload);
        } else {
          resolve(null);
        }
      });
    });
  }

  public async addRoom(buildingId, room: Room): Promise<Payload> {
    return new Promise<Payload>((resolve) => {
      this.utilityService
        .sendRequest("POST", `/admin-plus/buildings/${buildingId}/rooms`, null, room)
        .subscribe((payload) => {
          if (payload?.data?.length) {
            resolve(payload);
          } else {
            resolve(null);
          }
        });
    });
  }

  public async deleteRoom(buildingId, roomId): Promise<Payload> {
    return new Promise<Payload>((resolve) => {
      this.utilityService
        .sendRequest("DELETE", `/admin-plus/buildings/${buildingId}/rooms/${roomId}`, null)
        .subscribe((payload) => {
          if (payload?.data?.length) {
            resolve(payload);
          } else {
            resolve(null);
          }
        });
    });
  }
}
