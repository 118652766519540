<div class="row">
  <div class="col-12 col-lg-4 col-xl-3 mt-2">
    <mat-form-field class="w-100">
      <mat-label>Actions</mat-label>
      <mat-select [formControl]="actions">
        <mat-option value="" selected></mat-option>
        <mat-option value="wipeSharedDrive" [disabled]="selection.selected.length == 0 || checkDrive() || checkSynced()">
          Delete Drive and Contents
        </mat-option>
        <mat-option value="pullAllFiles" [disabled]="selection.selected.length == 0 || checkDrive() || checkSynced()">
          Pull All Files
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-12 col-lg-4 col-xl-6 mt-2">
    <app-filters [filterType]="filterTypeValue" (dataEvent)="receiveData($event)"></app-filters>
  </div>

  <div class="col-12 col-lg-4 col-xl-3 mt-2">
    <mat-form-field class="w-100">
      <mat-label>Columns</mat-label>
      <mat-select [formControl]="extraColumns" multiple>
        <mat-option *ngFor="let column of columnsList" [value]="column" (click)="tableColumnChange(column)">
          {{ column.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <small
      class="text-muted d-flex align-items-center mb-1"
      *ngIf="storageReport.status.id == 4 && !storageReport.drivesSynced"
      style="gap: 4px"
    >
      <mat-icon class="faa-spin" style="transform: scale(0.75)"> filter_tilt_shift </mat-icon>
      Shared drives are still syncing...
    </small>
    <div id="pdfTable" #pdfTable class="table-responsive has-active">
      <table mat-table [dataSource]="dataSource" matSort matSortActive="totalUsage" matSortDirection="desc">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              color="primary"
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() ? true : false && getPageData()"
              [indeterminate]="selection.hasValue() && !getPageData()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              color="primary"
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
          <td (click)="goToDrive(row.id)" mat-cell *matCellDef="let row">
            {{ row.name }}
          </td>
          <td mat-footer-cell *matFooterCellDef>Total</td>
        </ng-container>
        <ng-container matColumnDef="totalUsage">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Total Usage (GB)</th>
          <td (click)="goToDrive(row.id)" mat-cell *matCellDef="let row">
            {{ row.totalUsage / 1024 | number : "1.1-2" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="totalUsers">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Total Users</th>
          <td (click)="goToDrive(row.id)" mat-cell *matCellDef="let row">
            {{ row.totalUsers }}
          </td>
        </ng-container>
        <ng-container matColumnDef="totalFiles">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Total Files</th>
          <td (click)="goToDrive(row.id)" mat-cell *matCellDef="let row">
            <div class="d-flex align-items-center">
              <span *ngIf="row.totalFiles === 100">> </span>{{ row.totalFiles }}
              <span
                *ngIf="!row.filesSynced"
                class="ml-2 material-icons mat-icon faa-spin"
                matTooltip="Files are currently syncing"
                >filter_tilt_shift</span
              >
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="managers">
          <th mat-header-cell *matHeaderCellDef>Managers</th>
          <td (click)="goToDrive(row.id)" mat-cell *matCellDef="let row">
            <span [matTooltip]="row.organizers">{{ row.managers }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="contentManagers">
          <th mat-header-cell *matHeaderCellDef>Content Managers</th>
          <td (click)="goToDrive(row.id)" mat-cell *matCellDef="let row">
            <span [matTooltip]="row.fileOrganizers">{{ row.contentManagers }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="viewers">
          <th mat-header-cell *matHeaderCellDef>Viewers</th>
          <td (click)="goToDrive(row.id)" mat-cell *matCellDef="let row">
            <span class="text-nowrap" [matTooltip]="row.readers">{{ row.viewers }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="contributors">
          <th mat-header-cell *matHeaderCellDef>Contributors</th>
          <td (click)="goToDrive(row.id)" mat-cell *matCellDef="let row">
            <span class="text-nowrap" [matTooltip]="row.writers">{{ row.contributors }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="commenters">
          <th mat-header-cell *matHeaderCellDef>Commenters</th>
          <td (click)="goToDrive(row.id)" mat-cell *matCellDef="let row">
            <span class="text-nowrap" [matTooltip]="row.commenters">{{ row.commenters }}</span>
          </td>
        </ng-container>
        <tr class="mat-row" app-empty-dataset-alert [loading]="loading" [filterCount]="data.filtersCount" *matNoDataRow></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row class="clickable-row" *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <app-table-footer
      [dataSource]="dataSource"
      [maxResults]="maxResults"
      [nextButton]="nextButton"
      [nextButtonDis]="nextButtonDis"
      [offset]="offset"
      [page]="page"
      [total]="totalDriveCount"
      (pager)="setPager($event)"
    ></app-table-footer>
  </div>
</div>
