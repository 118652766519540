<form autocomplete="off">
  <div class="row mt-3">
    <div class="col-12 my-auto">
      <mat-form-field class="w-100">
        <mat-label> Name </mat-label>
        <input
          matInput
          [(ngModel)]="serviceAccount.name"
          type="text"
          name="name"
          id="Name"
          placeholder="Name *"
          autocomplete="off"
          maxlength="255"
          required
          [ngModelOptions]="{ standalone: true }"
        />
      </mat-form-field>
    </div>
    <div class="col-12 my-auto">
      <mat-form-field class="w-100">
        <mat-label> Description </mat-label>
        <input
          matInput
          [(ngModel)]="serviceAccount.description"
          type="text"
          name="description"
          id="description"
          placeholder="Description"
          autocomplete="off"
          maxlength="512"
          #descriptionText
          [ngModelOptions]="{ standalone: true }"
        />
        <mat-hint>{{ descriptionText.value?.length }} of 512 characters.</mat-hint>
      </mat-form-field>
    </div>
    <div class="col-12 my-auto">
      <mat-form-field class="w-100">
        <mat-label> Service Client ID </mat-label>
        <input
          matInput
          [(ngModel)]="serviceAccount.clientId"
          name="clientId"
          id="clientId"
          placeholder="Service Client ID"
          autocomplete="off"
          [ngModelOptions]="{ standalone: true }"
        />
      </mat-form-field>
    </div>
    <div class="col-12 my-auto">
      <mat-form-field class="w-100">
        <mat-label> Project ID </mat-label>
        <input
          matInput
          [(ngModel)]="serviceAccount.projectId"
          name="projectId"
          id="projectId"
          placeholder="Project ID"
          autocomplete="off"
          maxlength="124"
          required
          [ngModelOptions]="{ standalone: true }"
        />
      </mat-form-field>
    </div>
    <div class="col-12 my-auto">
      <mat-form-field class="w-100">
        <mat-label> Service Email </mat-label>
        <input
          matInput
          [(ngModel)]="serviceAccount.serviceEmail"
          type="email"
          name="serviceEmail"
          id="serviceEmail"
          placeholder="Service Email *"
          autocomplete="off"
          required
          email
          [ngModelOptions]="{ standalone: true }"
        />
      </mat-form-field>
    </div>
    <div class="col-12 my-auto">
      <app-email
        (selectedEmail)="receiveDelegateEmail($event)"
        placeholder="Search user email / name ..."
        fieldLabel="Admin+ Default Email"
        [email]="serviceAccount.delegateEmail"
        [multiple]="false"
        [custom]="false"
        [type]="type"
        [error]="error"
      >
      </app-email>
    </div>
    <div class="col-12 my-auto">
      <app-email
        (selectedEmail)="receiveMailboxEmail($event)"
        placeholder="Search user email / name ..."
        fieldLabel="Ticket Mailbox Email"
        [email]="serviceAccount.mailbox?.email"
        [multiple]="false"
        [custom]="false"
        [type]="type"
        [error]="error"
      >
      </app-email>
    </div>
    <div class="col-12 my-auto">
      <div class="text-danger" *ngIf="data.action == 'edit'">
        For security, the stored private key cannot be displayed, but can be updated (leave blank if not changing)
      </div>
      <mat-form-field class="w-100">
        <mat-label> Private Key</mat-label>
        <input
          matInput
          [(ngModel)]="serviceAccount.accessKey"
          type="text"
          name="privateKey"
          id="privateKey"
          placeholder="Private Key *"
          autocomplete="off"
          [required]="data.action == 'add'"
          [ngModelOptions]="{ standalone: true }"
        />
        <mat-hint>Paste entirety of private key</mat-hint>
      </mat-form-field>
    </div>
  </div>
  <div class="w-100 px-2 my-3 d-flex justify-content-between">
    <a
      class="pt-4 align-self-center text-decoration-none"
      target="_blank"
      href="https://www.trafera.com/knowledgebase/workflow-create-a-service-account"
    >
      <b>Instructions</b>
    </a>
    <div mat-dialog-actions>
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-stroked-button create (click)="processForm()" [disabled]="saving">Setup Service Account</button>
    </div>
  </div>
</form>
