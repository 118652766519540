<div *ngIf="memberPermission">
  <div *ngIf="user.isAdmin == true || membership == 'MEMBER' || membership == 'MANAGER' || membership == 'OWNER'">
    <div class="row pt-2">
      <div class="d-flex flex-wrap justify-content-between mt-2">
        <h2 class="my-auto">Members</h2>

        <div class="d-flex justify-content-end">
          <button
            *ngIf="dataSource.data.length > 0"
            mat-stroked-button
            class="success mr-2"
            (click)="groupMemebershipsSpreadsheet()"
            matTooltip="Create Members Google Sheet"
          >
            Export to Google Sheets
          </button>
          <button
            class="ml-2"
            *ngIf="user.isAdmin == true || membership == 'OWNER' || membership == 'MANAGER'"
            mat-stroked-button
            class="create"
            (click)="openDialog()"
          >
            Add Users
          </button>
          <button
            class="ml-2 primary"
            *ngIf="user.isAdmin == true || membership == 'OWNER' || membership == 'MANAGER'"
            mat-stroked-button
            (click)="openSubGroupDialog()"
          >
            Add Groups
          </button>
        </div>
      </div>

      <div class="col-12 col-md-4 mt-2">
        <mat-form-field class="w-100">
          <mat-label> Actions </mat-label>
          <mat-select [formControl]="actions">
            <mat-option value="" selected></mat-option>
            <mat-option value="delete" [disabled]="selection.selected.length == 0"> Delete </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      
      <div class="col-12 col-md-8 mt-2">
        <mat-form-field class="w-100">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. email@domain.com" #input />
        </mat-form-field>
      </div>

      <div class="col-12">
        <div class="table-responsive has-active">
          <table mat-table [dataSource]="dataSource" matSort>
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  color="primary"
                  *ngIf="user.isAdmin || membership === 'MANAGER' || membership === 'OWNER'"
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && getPageData()"
                  [indeterminate]="selection.hasValue() && !getPageData()"
                  [aria-label]="checkboxLabel()"
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox
                  color="primary"
                  *ngIf="user.isAdmin || membership === 'MANAGER' || membership === 'OWNER'"
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)"
                  [aria-label]="checkboxLabel(row)"
                >
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="preferredMemberKey.id">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>Email</th>
              <td mat-cell *matCellDef="let row">
                {{ row.preferredMemberKey.id }}
              </td>
            </ng-container>
            <ng-container matColumnDef="role">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>Role</th>
              <td mat-cell *matCellDef="let row">
                <a
                  (click)="onClickEditMemberships(row)"
                  *ngIf="user.isAdmin || (membership !== 'MEMBER' && (row.role !== 'OWNER' || membership == 'OWNER'))"
                >
                  {{ row.role }}
                </a>
                <span *ngIf="!user.isAdmin && (membership == 'MEMBER' || (row.role == 'OWNER' && membership !== 'OWNER'))">
                  {{ row.role }}
                </span>
              </td>
            </ng-container>
            <tr class="mat-row" app-empty-dataset-alert [loading]="loading" [filterCount]="dataSource.filter.length" *matNoDataRow></tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
        <app-table-footer
          [dataSource]="dataSource"
          [maxResults]="maxResults"
          [nextButton]="nextButton"
          [nextPageToken]="nextPageToken"
          [prevPageToken]="prevPageToken"
          [offset]="offset"
          [page]="page"
          (pager)="setPager($event)"
          [disabled]="isFetchingData"
        ></app-table-footer>
      </div>
    </div>
  </div>
</div>

<div *ngIf="memberPermissionSet && !memberPermission" class="alert alert-danger text-center">
  You do not have permission to view this group's members.
</div>
