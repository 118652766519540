import { Injectable } from "@angular/core";
import { Payload } from "src/app/services/utilities/utility.service";
import { Agent } from "src/app/models/ticket-plus/agent";
import { TicketPlusService } from "./ticket-plus.service";

@Injectable({
  providedIn: "root",
})
export class AgentService extends TicketPlusService {
  private url = `/ticket-plus/agents`;

  async getAgents(params = {}): Promise<Payload> {
    return this.cacheService.getPayloadAndCache(this.url, params, this.utilityService);
  }

  async createAgent(agent: Agent): Promise<Agent> {
    this.cacheService.clearCache(this.url);
    return new Promise<Agent>((resolve) => {
      const method = "POST";
      const path = this.url + (agent.id ? agent.id : "");
      const body = agent;

      this.utilityService.sendRequest(method, path, {}, body).subscribe((payload) => {
        if (payload?.data?.length) {
          resolve(payload.data[0]);
        } else {
          resolve(null);
        }
      });
    });
  }

  async deleteAgent(agent: Agent): Promise<Payload> {
    this.cacheService.clearCache(this.url);
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("DELETE", `${this.url}/${agent.id}`).subscribe((payload) => {
        if (payload) {
          resolve(payload);
        } else {
          resolve(null);
        }
      });
    });
  }
}
