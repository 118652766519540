<!-- my-component.component.html -->
<div class="page-header__icons" [ngSwitch]="true">
  <ng-container *ngSwitchCase="options.length <= 3">
    <button
      *ngFor="let option of options"
      class="{{ option.class }} page-header__icon"
      [ngClass]="{ 'text-muted': option.disabled }"
      (click)="optionSelected.emit(option)"
      [disabled]="option.disabled"
      mat-stroked-button
      matTooltip="{{ option.tooltip }}"
    >
      <mat-icon>{{ option.icon }}</mat-icon>
      <span>{{ option.name }}</span>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="options.length > 3">
    <button
      *ngFor="let option of options"
      class="{{ option.class }} page-header__icon"
      [ngClass]="{ 'text-muted': option.disabled }"
      (click)="optionSelected.emit(option)"
      [disabled]="option.disabled"
      mat-stroked-button
      matTooltip="{{ option.tooltip }}"
    >
      <mat-icon class="mx-0">{{ option.icon }}</mat-icon>
      <span class="page-header__labels ml-2">{{ option.name }}</span>
    </button>
  </ng-container>

    <button
      *ngIf="options?.length"
      class="page-header__icon-more"
      [matMenuTriggerFor]="menu"
      mat-icon-button
      aria-label="Options"
      >
      <mat-icon> more_vert </mat-icon>
  </button>

      <!-- Menu options -->
  <mat-menu class="page-header__menu" #menu="matMenu">
    <button
      *ngFor="let option of options"
      mat-menu-item
      (click)="optionSelected.emit(option)"
      [disabled]="option.disabled"
      matTooltip="{{ option.tooltip }}"
    >

      <mat-icon class="pr-4">{{ option.icon }}</mat-icon>
      <span>{{ option.name }}</span>
    </button>
  </mat-menu>

