<h2 mat-dialog-title>Testing Service Account Scopes</h2>
<div mat-dialog-content>
  <div *ngIf="loading" class="row ml-0 pl-0">
    <div class="col-12">
      <h3 class="text-center mt-3"><mat-icon class="faa-spin mx-1"> filter_tilt_shift </mat-icon>Checking Scopes...</h3>
    </div>
  </div>
  <div *ngIf="!loading" class="row">
    <div class="col-12">
      <h3 class="text-center mt-3">Scope Check Complete</h3>
      <div *ngIf="failedScopeStatus" class="d-flex">
        <div class="my-auto mr-2">
          <mat-icon class="text-danger mr-2">error</mat-icon>
        </div>
        <div>
          <span class="d-block">One or more scopes failed the scope check.</span>
          <span class="d-block">Follow the additional prompts to manage your Google Workspace API controls.</span>
        </div>
      </div>
      <div *ngIf="!failedScopeStatus">
        <mat-icon class="text-success mr-2">check_circle</mat-icon>
        <span>Your Service Account scopes (permissions) are valid.</span>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button *ngIf="!loading && failedScopeStatus" mat-button class="btn-success" (click)="retry()">Retry</button>
    <button class="blue-border" mat-button (click)="close()">
      {{ failedScopeStatus ? "Continue" : "Close" }}
    </button>
  </div>
</div>
