<form *ngIf="settings" (ngSubmit)="saveSettings()" autocomplete="off">
  <div class="row px-4 pt-0 pb-4">
      <div class="col-12 mt-2">
        <p>
          <strong>Note: </strong>External members can't view group members or
          search group content
        </p>

        <div class="table-responsive has-active">
          <table class="table table-striped">
            <thead>
              <th scope="col" class="text-left">
                <div class="d-flex align-items-center flex-wrap">
                  <mat-icon class="mr-1">settings</mat-icon>
                  <span>Access Settings</span>
                </div>
              </th>
              <th scope="col">
                <div class="d-flex align-items-center flex-wrap">
                  <mat-icon class="mr-1">person</mat-icon>
                  Group Managers
                </div>
              </th>
              <th scope="col">
                <div class="d-flex align-items-center flex-wrap">
                  <mat-icon class="mr-1">group</mat-icon>
                  Group Members
                </div>
              </th>
              <th scope="col">
                <div class="d-flex align-items-center flex-wrap">
                  <mat-icon class="mr-1">business</mat-icon>
                  Organization
                </div>
              </th>
              <th scope="col">
                <div class="d-flex align-items-center flex-wrap">
                  <mat-icon class="mr-1">public</mat-icon>
                  External
                </div>
              </th>
            </thead>
            <tbody>
              <tr>
                <th class="text-left">
                  <span>Contact Owners</span>
                </th>
                <td>
                  <mat-checkbox
                    color="primary"
                    [checked]="
                      settings.whoCanContactOwner == 'ALL_MANAGERS_CAN_CONTACT' ||
                      settings.whoCanContactOwner == 'ALL_MEMBERS_CAN_CONTACT' ||
                      settings.whoCanContactOwner ==
                        'ALL_IN_DOMAIN_CAN_CONTACT' ||
                      settings.whoCanContactOwner == 'ANYONE_CAN_CONTACT'
                    "
                    [disabled]="true"
                  ></mat-checkbox>
                </td>
                <td>
                  <mat-checkbox
                    color="primary"
                    [checked]="
                      settings.whoCanContactOwner == 'ALL_MEMBERS_CAN_CONTACT' ||
                      settings.whoCanContactOwner ==
                        'ALL_IN_DOMAIN_CAN_CONTACT' ||
                      settings.whoCanContactOwner == 'ANYONE_CAN_CONTACT'
                    "
                    (change)="
                      contactOwnerOnChange($event, 'ALL_MEMBERS_CAN_CONTACT')
                    "
                  ></mat-checkbox>
                </td>
                <td>
                  <mat-checkbox
                    color="primary"
                    [checked]="
                      settings.whoCanContactOwner ==
                        'ALL_IN_DOMAIN_CAN_CONTACT' ||
                      settings.whoCanContactOwner == 'ANYONE_CAN_CONTACT'
                    "
                    (change)="
                      contactOwnerOnChange($event, 'ALL_IN_DOMAIN_CAN_CONTACT')
                    "
                  ></mat-checkbox>
                </td>
                <td>
                  <mat-checkbox
                    color="primary"
                    [checked]="
                      settings.whoCanContactOwner == 'ANYONE_CAN_CONTACT'
                    "
                    (change)="contactOwnerOnChange($event, 'ANYONE_CAN_CONTACT')"
                  ></mat-checkbox>
                </td>
              </tr>
              <tr>
                <th class="text-left">
                  <span>Discover Group</span>
                </th>
                <td></td>
                <td>
                  <mat-checkbox
                    color="primary"
                    [checked]="
                      settings.whoCanDiscoverGroup ==
                        'ALL_MEMBERS_CAN_DISCOVER' ||
                      settings.whoCanDiscoverGroup ==
                        'ALL_IN_DOMAIN_CAN_DISCOVER' ||
                      settings.whoCanDiscoverGroup == 'ANYONE_CAN_DISCOVER'
                    "
                    [disabled]="true"
                  ></mat-checkbox>
                </td>
                <td>
                  <mat-checkbox
                    color="primary"
                    [checked]="
                      settings.whoCanDiscoverGroup ==
                        'ALL_IN_DOMAIN_CAN_DISCOVER' ||
                      settings.whoCanDiscoverGroup == 'ANYONE_CAN_DISCOVER'
                    "
                    (change)="
                      discoverGroupOnChange($event, 'ALL_IN_DOMAIN_CAN_DISCOVER')
                    "
                  ></mat-checkbox>
                </td>
                <td>
                  <mat-checkbox
                    color="primary"
                    [checked]="
                      settings.whoCanDiscoverGroup == 'ANYONE_CAN_DISCOVER'
                    "
                    (change)="
                      discoverGroupOnChange($event, 'ANYONE_CAN_DISCOVER')
                    "
                  ></mat-checkbox>
                </td>
              </tr>
              <tr>
                <th class="text-left">
                  <span>View Members</span>
                </th>
                <td>
                  <mat-checkbox
                    color="primary"
                    [checked]="
                      settings.whoCanViewMembership == 'ALL_MANAGERS_CAN_VIEW' ||
                      settings.whoCanViewMembership == 'ALL_MEMBERS_CAN_VIEW' ||
                      settings.whoCanViewMembership == 'ALL_IN_DOMAIN_CAN_VIEW'
                    "
                    [disabled]="true"
                  ></mat-checkbox>
                </td>
                <td>
                  <mat-checkbox
                    color="primary"
                    [checked]="
                      settings.whoCanViewMembership == 'ALL_MEMBERS_CAN_VIEW' ||
                      settings.whoCanViewMembership == 'ALL_IN_DOMAIN_CAN_VIEW'
                    "
                    (change)="viewMembersOnChange($event, 'ALL_MEMBERS_CAN_VIEW')"
                  ></mat-checkbox>
                </td>
                <td>
                  <mat-checkbox
                    color="primary"
                    [checked]="
                      settings.whoCanViewMembership == 'ALL_IN_DOMAIN_CAN_VIEW'
                    "
                    (change)="
                      viewMembersOnChange($event, 'ALL_IN_DOMAIN_CAN_VIEW')
                    "
                  ></mat-checkbox>
                </td>
                <td></td>
              </tr>
              <tr>
                <th class="text-left">
                  <span>View Conversations</span>
                </th>
                <td>
                  <mat-checkbox
                    color="primary"
                    [checked]="
                      settings.whoCanViewGroup == 'ALL_MANAGERS_CAN_VIEW' ||
                      settings.whoCanViewGroup == 'ALL_MEMBERS_CAN_VIEW' ||
                      settings.whoCanViewGroup == 'ALL_IN_DOMAIN_CAN_VIEW' ||
                      settings.whoCanViewGroup == 'ANYONE_CAN_VIEW'
                    "
                    [disabled]="true"
                  ></mat-checkbox>
                </td>
                <td>
                  <mat-checkbox
                    color="primary"
                    [checked]="
                      settings.whoCanViewGroup == 'ALL_MEMBERS_CAN_VIEW' ||
                      settings.whoCanViewGroup == 'ALL_IN_DOMAIN_CAN_VIEW' ||
                      settings.whoCanViewGroup == 'ANYONE_CAN_VIEW'
                    "
                    (change)="
                      viewConversationsOnChange($event, 'ALL_MEMBERS_CAN_VIEW')
                    "
                  ></mat-checkbox>
                </td>
                <td>
                  <mat-checkbox
                    color="primary"
                    [checked]="
                      settings.whoCanViewGroup == 'ALL_IN_DOMAIN_CAN_VIEW' ||
                      settings.whoCanViewGroup == 'ANYONE_CAN_VIEW'
                    "
                    (change)="
                      viewConversationsOnChange($event, 'ALL_IN_DOMAIN_CAN_VIEW')
                    "
                  ></mat-checkbox>
                </td>
                <!--td>
                                  <mat-checkbox
                                      color="primary"
                                      [checked]="settings.whoCanViewGroup == 'ANYONE_CAN_VIEW'"
                                      (change)="viewConversationsOnChange($event, 'ANYONE_CAN_VIEW')"></mat-checkbox>
                              </td-->
              </tr>
              <tr>
                <th class="text-left">
                  <span>Publish Posts</span>
                </th>
                <td>
                  <mat-checkbox
                    color="primary"
                    [checked]="
                      settings.whoCanPostMessage == 'ALL_MANAGERS_CAN_POST' ||
                      settings.whoCanPostMessage == 'ALL_MEMBERS_CAN_POST' ||
                      settings.whoCanPostMessage == 'ALL_IN_DOMAIN_CAN_POST' ||
                      settings.whoCanPostMessage == 'ANYONE_CAN_POST'
                    "
                    [disabled]="true"
                  ></mat-checkbox>
                </td>
                <td>
                  <mat-checkbox
                    color="primary"
                    [checked]="
                      settings.whoCanPostMessage == 'ALL_MEMBERS_CAN_POST' ||
                      settings.whoCanPostMessage == 'ALL_IN_DOMAIN_CAN_POST' ||
                      settings.whoCanPostMessage == 'ANYONE_CAN_POST'
                    "
                    (change)="whoCanPostOnChange($event, 'ALL_MEMBERS_CAN_POST')"
                  ></mat-checkbox>
                </td>
                <td>
                  <mat-checkbox
                    color="primary"
                    [checked]="
                      settings.whoCanPostMessage == 'ALL_IN_DOMAIN_CAN_POST' ||
                      settings.whoCanPostMessage == 'ANYONE_CAN_POST'
                    "
                    (change)="
                      whoCanPostOnChange($event, 'ALL_IN_DOMAIN_CAN_POST')
                    "
                  ></mat-checkbox>
                </td>
                <td>
                  <mat-checkbox
                    color="primary"
                    [checked]="settings.whoCanPostMessage == 'ANYONE_CAN_POST'"
                    (change)="whoCanPostOnChange($event, 'ANYONE_CAN_POST')"
                  ></mat-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row no-gutters align-items-center mt-2 pt-3">
        <div class="col-12 col-lg-6">
          <mat-form-field class="w-100">
            <mat-label>
              <strong> Who can join group </strong>
            </mat-label>
            <mat-select [(ngModel)]="settings.whoCanJoin" name="whoCanJoin">
              <mat-option
                value="INVITED_CAN_JOIN"
                [selected]="settings.whoCanJoin == INVITED_CAN_JOIN"
              >
                Only invited users
              </mat-option>
              <mat-option
                value="ALL_IN_DOMAIN_CAN_JOIN"
                [selected]="settings.whoCanJoin == ALL_IN_DOMAIN_CAN_JOIN"
              >
                Anyone in the organization can join
              </mat-option>
              <mat-option
                value="CAN_REQUEST_TO_JOIN"
                [selected]="settings.whoCanJoin == CAN_REQUEST_TO_JOIN"
              >
                Anyone in the organization can ask
              </mat-option>
              <mat-option
                value="ANYONE_CAN_JOIN"
                [selected]="settings.whoCanJoin == ANYONE_CAN_JOIN"
              >
                Anyone can join
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <div class="d-flex align-items-center justify-content-end">
            <span class="mr-3">Allow members outside your organization</span>
            <mat-slide-toggle
              [(ngModel)]="settings.allowExternalMembers"
              name="allowExternalMembers"
            ></mat-slide-toggle>
          </div>
        </div>
      </div>
      <div class="col-12">
        <button mat-stroked-button class="success mt-3">
          <i class="material-icons mr-2 mb-1">save</i>
          Save Settings
        </button>
      </div>
  </div>
</form>

