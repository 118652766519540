<div class="row">
	<div class="col-12 mt-2">
		<app-filters
			[inputSelectedFilters]="selectedFilters"
			[filterType]="filterTypeValue"
			[driveId]="driveId"
			[userId]="userId"
			(dataEvent)="receiveData($event)"
		>
		</app-filters>
	</div>
</div>
<div class="row">
	<div class="col-12">
		<div id="pdfTable" #pdfTable>
			<div class="table-responsive has-active">
				<table mat-table [dataSource]="dataSource" matSort matSortActive="totalStorageMb" matSortDirection="desc">
					<!-- Checkbox Column -->
					<ng-container matColumnDef="select">
						<th class="px-3" mat-header-cell *matHeaderCellDef >
							<mat-checkbox
							color="primary"
								
								(change)="$event ? masterToggle() : null"
								[checked]="selection.hasValue() ? true : false && getPageData()"
								[indeterminate]="selection.hasValue() && !getPageData()"
								[aria-label]="checkboxLabel()"
							>
							</mat-checkbox>
						</th>
						<td class="p-3" mat-cell *matCellDef="let row" >
							<mat-checkbox
								matTooltip="You can only fix one duplicate group at a time."
								color="primary"
								
								(click)="$event.stopPropagation()"
								(change)="$event ? selection.toggle(row) : null"
								[checked]="selection.isSelected(row)"
								[aria-label]="checkboxLabel(row)"
								[disabled]="row.busy !== 0"
							>
							</mat-checkbox>
						</td>
					</ng-container>
					<ng-container matColumnDef="totalFiles">
						<th class="px-3 text-nowrap" mat-header-cell mat-sort-header *matHeaderCellDef>Total Files</th>
						<td [ngClass]="{ grayout: row.busy !== 0 }" class="py-3 text-nowrap px-3" mat-cell *matCellDef="let row">
							{{ row.totalFiles }}
						</td>
					</ng-container>
					<ng-container matColumnDef="totalStorageMb">
						<th class="px-3 text-nowrap" mat-header-cell mat-sort-header *matHeaderCellDef>Total Storage (GB)</th>
						<td [ngClass]="{ grayout: row.busy !== 0 }" class="py-3 text-nowrap px-3" mat-cell *matCellDef="let row">
							{{ row.totalStorageMb / 1024 | number : "1.1-2" }}
						</td>
					</ng-container>
					<ng-container matColumnDef="md5Checksum">
						<th class="px-3 text-nowrap" mat-header-cell mat-sort-header *matHeaderCellDef>md5Checksum</th>
						<td [ngClass]="{ grayout: row.busy !== 0 }" class="p-3" mat-cell *matCellDef="let row">
							<span class="text-nowrap">{{ row.md5Checksum }}</span>
						</td>
					</ng-container>
					<ng-container *ngIf="storageReport.status.id == 4 && !storageReport.filesSynced">
						<ng-container matColumnDef="syncing">
							<th mat-header-cell *matHeaderCellDef [attr.colspan]="99"  class="alert alert-info">
								<span class="material-icons mat-icon faa-spin mr-3"> filter_tilt_shift </span>
								Duplicate files are still syncing...
							</th>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="fixDuplicateBanner !== 0">
						<ng-container matColumnDef="banner">
							<th mat-header-cell *matHeaderCellDef [attr.colspan]="99"  class="alert alert-info">
								<p class="mt-2 text-center">
									<strong>Some duplicate files are being fixed </strong>
									<a
										matTooltip="Refresh"
										class="bg-warning text-white"
										aria-label="Refresh"
										mat-stroked-button 
										class="ml-2"
										(click)="reloadComponent()"
									>
										<span class="cursor-pointer mat-icon material-icons">refresh</span>
									</a>
								</p>
							</th>
						</ng-container>
					</ng-container>
					<tr class="mat-row" app-empty-dataset-alert [loading]="loading" [filterCount]="data.filtersCount" *matNoDataRow></tr>
					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<ng-container *ngIf="storageReport.status.id == 4 && !storageReport.filesSynced">
						<tr mat-header-row *matHeaderRowDef="['syncing']"></tr>
					</ng-container>
					<ng-container *ngIf="fixDuplicateBanner !== 0">
						<tr mat-header-row *matHeaderRowDef="['banner']"></tr>
					</ng-container>
					<tr (click)="routeToFilesTable(row.md5Checksum)" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
				</table>
			</div>
			<app-table-footer
			[dataSource]="dataSource"
			[maxResults]="maxResults"
			[nextButton]="nextButton"
			[nextButtonDis]="nextButtonDis"
			[offset]="offset"
			[page]="page"
			[total]="totalFileCount"
			(pager)="setPager($event)"
		></app-table-footer>
		</div>
	</div>
</div>
