<div class="free-trial overflow-hidden">
  <div class="front-page-bg" style="background-image: url('/assets/images/elementor-mesh.png')"></div>
  <div class="front-page">
    <div [@freeTrialLeftAnimation] class="ft__header">
      <app-header title="Admin+ Free Trial"></app-header>
    </div>

    <div class="ft__mockup">
      <img [@freeTrialRightAnimation] src="/assets/images/elementor-image.png" alt="mockup" class="img-fluid" />
    </div>
  </div>
</div>
