import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";
import { ExportsheetsService } from "src/app/services/google/sheets.service";
import { UntypedFormControl } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { GroupDialogComponent } from "./new-group-dialog/group-dialog.component";
import { Group, GroupsService } from "../services/admin-plus/groups.service";
import { User } from "src/app/web/login/user";
import { DeleteGroupsDialogComponent } from "./delete-groups-dialog/delete-groups-dialog.component";
import { SelectGroupsComponent } from "src/app/layout/dialogs/select-groups/select-groups.component";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { merge, of as observableOf } from "rxjs";
import { startWith, switchMap, map, catchError } from "rxjs/operators";
import { MatPaginator } from "@angular/material/paginator";
import { NotificationService } from "../services/utilities/notification.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-groups",
  templateUrl: "./groups.component.html",
})
export class GroupsComponent implements OnInit {
  options = [];
  user: User;
  groups: Group[];
  members: [];
  actions = new UntypedFormControl();
  columns = new UntypedFormControl();
  displayedColumns: string[] = ["select", "name", "email", "directMembersCount"];
  selection = new SelectionModel(true, []);
  dataSource = new MatTableDataSource<Group>();
  maxResults = 10;
  page = 1;
  offset = 0;
  totalGroupCount = 0;
  nextPageToken = "";
  prevPageToken = "";
  pageTokens = [""];
  nextButton = false;
  nextButtonDis = false;
  loading = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isFetchingData: boolean;

  constructor(
    public groupsService: GroupsService,
    public dialog: MatDialog,
    private navService: NavService,
    private exportSheetsService: ExportsheetsService,
    private notificationService: NotificationService,
    private router: Router
  ) {
    const user: User = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.user = user;
    }
    this.isFetchingData = false;

    this.options.push({
      icon: "description",
      name: "Export to Sheets",
      tooltip: "Exports the current data set to Google Sheets",
      class: "success",
    });
    this.options.push({
      icon: "add_outline",
      name: "New Group",
      tooltip: "Creates a new group within your organization",
      class: "create",
    });
  }

  ngOnInit(): void {
    this.columns.setValue(this.displayedColumns);
    this.loadGroupsTable();
    this.actions.valueChanges.subscribe((value) => {
      if (value == "delete") {
        this.openDeleteDialog();
      } else if (value == "addsubgroup") {
        this.openAddSubGroups();
      } else {
        return;
      }
    });
  }

  optionSelected(option) {
    switch (option.name) {
      case "Export to Sheets":
        this.exportToSheets();
        break;
      case "New Group":
        this.openDialog();
        break;
      default:
        break;
    }
  }

  getProperty = (obj, path) => path.split(".").reduce((o, p) => o && o[p], obj);

  async loadGroupsTable(arrow = "") {
    this.navService.loading.next(true);
    let arrowToken = "";
    if (arrow === "prev") {
      this.selection.clear(); // QUICK FIX
      this.setTokens(this.page);
      arrowToken = this.prevPageToken;
    } else if (arrow === "next") {
      this.selection.clear(); // QUICK FIX
      arrowToken = this.nextPageToken;
      this.setTokens(this.page);
    }
    this.getGroups(arrowToken);
    this.navService.loading.next(false);
  }

  async getGroups(arrowToken = "") {
    this.isFetchingData = true;
    this.updateLoader(true);
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isFetchingData = true;
          return this.groupsService.getGroups(this.maxResults, arrowToken);
        }),
        map(async (groups) => {
          if (groups) {
            this.groups = groups["groups"];
            this.nextPageToken = groups.nextPageToken;
            this.dataSource = new MatTableDataSource(this.groups);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
          this.updateLoader(false);
          this.isFetchingData = false;
          if (groups.nextPageToken) {
            if (this.page == this.pageTokens.length) {
              this.pageTokens.push(groups.nextPageToken);
              this.nextPageToken = groups.nextPageToken;
            }
          } else {
            this.nextPageToken = "";
          }
          return groups;
        }),
        catchError(() => {
          this.isFetchingData = false;
          this.updateLoader(false);
          return observableOf([]);
        })
      )
      .subscribe((groups) => {
        if (groups) {
          this.updateLoader(false);
          this.isFetchingData = false;
          //This has to be here to load groups.
        }
      });
  }

  async addGroupData(spreadsheetID: string) {
    const newGroupObject = await this.exportSheetsService.createSpreadsheetArray(
      this.displayedColumns,
      this.dataSource["filteredData"]
    );
    if (newGroupObject) {
      await this.exportSheetsService.addExportData(
        newGroupObject,
        spreadsheetID,
        "Sheet1!A1:G" + newGroupObject["values"].length
      );
    }
  }

  async exportToSheets() {
    this.navService.loading.next(true);
    const spreadsheetID = await this.exportSheetsService.createSpreadsheet("Groups");
    await this.addGroupData(spreadsheetID);
    window.open("https://docs.google.com/spreadsheets/d/" + spreadsheetID);
    this.navService.loading.next(false);
  }

  openDeleteDialog() {
    const dialogRef = this.dialog.open(DeleteGroupsDialogComponent, {
      disableClose: true,
      width: "500px",
      data: {
        groups: this.selection.selected,
      },
    });

    dialogRef.afterClosed().subscribe((groups: Group[]) => {
      if (groups) {
        this.notificationService.notify(`${groups.length} groups have been removed`);
        const data = this.dataSource.data;
        for (let i = data.length - 1; i >= 0; i--) {
          for (let j = 0; j < groups.length; j++) {
            if (data[i]) {
              if (data[i].id == groups[j].id) {
                data.splice(i, 1);
              }
            }
          }
        }
        this.dataSource.data = data;
        this.selection.clear();
        this.actions.setValue("");
      } else {
        this.selection.clear();
        this.actions.setValue("");
      }
    });
  }

  openAddSubGroups() {
    const dialogRef = this.dialog.open(SelectGroupsComponent, {
      disableClose: true,
      width: "500px",
      data: {
        groups: this.selection.selected,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selection.clear();
        this.actions.setValue("");
      } else {
        this.selection.clear();
        this.actions.setValue("");
      }
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(GroupDialogComponent, {
      disableClose: true,
      width: "500px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const newGroup = {
          id: result.name.replace("groups/", ""),
          email: result.groupKey.id,
          name: result.displayName,
          directMembersCount: 0,
        };

        const data = this.dataSource.data;
        data.unshift(newGroup);
        this.dataSource.data = data;
      }
      this.navService.loading.next(false);
    });
  }

  getPageData() {
    return this.dataSource._pageData(this.dataSource._orderData(this.dataSource.filteredData));
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  masterToggle() {
    this.isEntirePageSelected()
      ? this.selection.deselect(...this.getPageData())
      : this.selection.select(...this.getPageData());
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row): string {
    if (!row) {
      return `${this.getPageData() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1}`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  tableColumnChange(value) {
    let add = true;
    for (let i = 0; i < this.displayedColumns.length; i++) {
      if (this.displayedColumns[i] == value) {
        if (i !== -1) {
          this.displayedColumns.splice(i, 1);
          add = false;
        }
      }
    }

    if (add) {
      this.displayedColumns.push(value);
    }
  }

  setTokens(page: number) {
    this.nextPageToken = this.pageTokens[page];
    this.prevPageToken = this.pageTokens[page - 1];
  }

  setPager(data) {
    this.page = data.page;
    this.offset = data.offset;
    this.maxResults = data.maxResults ? data.maxResults : this.maxResults;
    this.loadGroupsTable(data.arrow);
  }

  routeToGroup(groupId: number) {
    this.router.navigate(["/groups/" + groupId]);
  }

  updateLoader(value: boolean) {
    for (const option of this.options) {
      if (option["name"] == "Export to Sheets") {
        option["disabled"] = value;
      }
    }
    this.loading = value;
    this.navService.loading.next(value);
  }
}
