import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { User } from "src/app/web/login/user";
import { OUService, OrgUnit } from "src/app/services/google/ou.service";
import { LoginService } from "src/app/services/admin-plus/login.service";

@Component({
  selector: "app-ou-selection",
  templateUrl: "./ou-selection.component.html",
  styleUrls: ["./ou-selection.component.scss"],
})
export class OuSelectionComponent implements OnInit {
  user: User;
  selected_ou;
  isDomainClicked = true;
  selectedOU: OrgUnit;
  isOpen = true;
  domain: string;
  list: OrgUnit[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public ouService: OUService,
    private dialogRef: MatDialogRef<OuSelectionComponent>,
    public loginService: LoginService
  ) {}

  async ngOnInit() {
    this.domain = this.data.domain;
    this.selectedOU = this.data.selectedOU
      ? { orgUnitPath: this.data.selectedOU }
      : this.ouService.selected_ou?.orgUnitPath
      ? this.ouService.selected_ou
      : ({} as OrgUnit);
    this.user = this.data.user;
    await this.loadData();
  }

  async loadData() {
    this.list = await this.ouService.userOUsDialog;
    if (this.list) {
      await this.setClosed(this.list);
      const ouPath = this.selectedOU ? this.selectedOU.orgUnitPath : this.ouService.selected_ou.orgUnitPath;
      const pathParts = ouPath.split("/").filter(Boolean);
      await this.setOpened(this.list, pathParts);
    }
  }

  private async setOpened(list, pathParts, path = "") {
    let searchPath = path ? path : `/${pathParts[0]}`;
    const ou = list.find((o) => o.orgUnitPath == searchPath);
    if (ou) {
      ou.isOpen = true;
      const searchPathArr = searchPath.split("/").filter(Boolean);
      searchPath += `/${pathParts[pathParts.findIndex((p) => p == searchPathArr[searchPathArr.length - 1]) + 1]}`;
      await this.setOpened(ou.children, pathParts, searchPath);
    } else {
      return;
    }
  }

  private async setClosed(list) {
    for (const ou of list) {
      ou.isOpen = false;
      await this.setClosed(ou.children);
    }
  }

  public rotateLeftNavIcon() {
    this.isDomainClicked = !this.isDomainClicked;
  }

  public toggle(ou) {
    ou.isOpen = !ou.isOpen;
  }

  public selectOU(ou: OrgUnit = null) {
    if (ou === null) {
      this.selected_ou = {
        orgUnitPath: "/",
        orgUnitId: this.list[0].parentOrgUnitId,
      };
      this.dialogRef.close(this.selected_ou);
    } else if (ou.orgUnitPath !== this.selected_ou) {
      this.selected_ou = ou;
      if (ou.orgUnitPath === "/") {
        this.selected_ou.orgUnitId = this.list[0].orgUnitId;
      }
      this.dialogRef.close(this.selected_ou);
    }
    this.selectedOU = this.selected_ou;
  }
}
