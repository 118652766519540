<h2 mat-dialog-title>Find & Fix Duplicate Files - {{ data.file.md5Checksum }}</h2>
<form (ngSubmit)="onConfirm()" autocomplete="off">
  <div mat-dialog-content>
    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Files
            <span *ngIf="loadingFiles" class="ml-2 material-icons faa-spin"> filter_tilt_shift </span>
          </mat-panel-title>
          <mat-panel-description> {{ files.length }} files </mat-panel-description>
        </mat-expansion-panel-header>
        <table mat-table [dataSource]="dataSourceFiles" matSort>
          <ng-container matColumnDef="name">
            <th class="px-3 text-nowrap" mat-header-cell *matHeaderCellDef>Name</th>
            <td class="py-3 text-nowrap px-3" mat-cell *matCellDef="let row">
              {{ row.name }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Owners
            <span *ngIf="loadingOwners" class="ml-2 material-icons faa-spin"> filter_tilt_shift </span>
          </mat-panel-title>
          <mat-panel-description> {{ owners.length }} owners </mat-panel-description>
        </mat-expansion-panel-header>
        <div *ngIf="dataSourceOwners.data.length == 0 && !loadingOwners" class="row">
          <div class="col-12">
            <div class="alert alert-info my-auto">
              <h3>There are no owners for these duplicate files.</h3>
            </div>
          </div>
        </div>
        <table *ngIf="dataSourceOwners.data.length != 0" mat-table [dataSource]="dataSourceOwners" matSort>
          <ng-container matColumnDef="name">
            <th class="px-3 text-nowrap" mat-header-cell *matHeaderCellDef>Name</th>
            <td class="text-nowrap p-3" mat-cell *matCellDef="let row">{{ row.firstName }} {{ row.lastName }}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-form-field class="mt-5 w-100">
      <mat-label>Options</mat-label>
      <mat-select [(ngModel)]="targetAction" name="action" required>
        <mat-option *ngFor="let action of actions" [value]="action">
          {{ action.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="primaryOwner" class="mt-3 w-100">
      <mat-chip-grid #userList>
        <mat-chip-row *ngFor="let selected of selected_user" [removable]="removable" (removed)="remove(selected, 'user')">
          {{ selected.primaryEmail }}
          <mat-icon [selectable]="selectable" matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip-row>
        <input
          placeholder="Search user email / name ..."
          #userInput
          [formControl]="userCtrl"
          [matAutocomplete]="auto"
          [matChipInputFor]="userList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event, 'user')"
          matInput
          required
          (keyup)="search($event)"
        />
      </mat-chip-grid>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let user of filteredOptions | async" [value]="user">
          {{ user.name.fullName }}&nbsp;|&nbsp;<small class="text-muted">{{ user.primaryEmail }}</small>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field *ngIf="sharedDrive" class="mt-3 w-100">
      <mat-chip-grid #driveList>
        <mat-chip-row removable="true" (removed)="remove(drive, 'drive')">
          {{ drive.name }}
          <mat-icon [selectable]="selectable" matChipRemove>cancel</mat-icon>
        </mat-chip-row>
        <input
          placeholder="Search drive"
          #driveInput
          [formControl]="driveCtrl"
          [matAutocomplete]="autoDrive"
          [matChipInputFor]="driveList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event, 'drive')"
          matInput
          required
          (keyup)="searchDrive($event)"
        />
      </mat-chip-grid>
      <mat-autocomplete #autoDrive="matAutocomplete" (optionSelected)="selectedDrive($event)">
        <mat-option *ngFor="let drive of filteredOptionsDrive | async" [value]="drive">
          {{ drive.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field *ngIf="sharedDrive || primaryOwner" class="mt-3 w-100">
      <input placeholder="New file name" matInput [(ngModel)]="options.fileName" name="newFile" type="text" />
    </mat-form-field>
    <div mat-dialog-actions class="px-0">
      <button class="" mat-stroked-button mat-dialog-close>Cancel</button>
      <button create mat-stroked-button>Confirm</button>
    </div>
  </div>
</form>
