import { Component, Input } from "@angular/core";

@Component({
	selector: "[app-empty-dataset-alert]",
	templateUrl: "./empty-dataset-alert.component.html",
	styleUrls: ["./empty-dataset-alert.component.scss"],
})
export class EmptyDatasetAlertComponent {
	@Input() loading = false;
	@Input() filterCount = 0;
	@Input() column = 0;
	@Input() customMessage = "";
}
