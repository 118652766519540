import { Customer } from "src/app/services/admin-plus/customer.service";
import { UserAction } from "../admin-plus/base";

export interface IManufacturer {
  id: number;
  customer: Customer;
  createdBy: UserAction;
  name: string;
  updateManufacturer(): Promise<Manufacturer>;
  deleteManufacturer(): Promise<Manufacturer>;
}

export class Manufacturer implements IManufacturer {
  id: number;
  customer: Customer;
  createdBy: UserAction;
  name: string;

  constructor(properties) {
    Object.assign(this, properties);
  }

  async updateManufacturer() {
    return this;
  }

  async deleteManufacturer() {
    return this;
  }
}
