import { Customer } from "src/app/services/admin-plus/customer.service";
import { UserAction } from "../admin-plus/base";

export interface IDelegate {
  id: number;
  customer: Customer;
  createdBy: UserAction;
  updatedBy: UserAction;
  name: string;
  ou: string;
  groupEmail: string;
}

export class Delegate implements IDelegate {
  id: number;
  customer: Customer;
  createdBy: UserAction;
  updatedBy: UserAction;
  name: string;
  ou: string;
  groupEmail: string;

  constructor(properties) {
    Object.assign(this, properties);
  }
}
