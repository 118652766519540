import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Type } from "src/app/models/asset-plus/type";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { UtilityService } from "src/app/services/utilities/utility.service";

@Component({
  selector: "app-type-dialog",
  templateUrl: "./type-dialog.component.html",
  styleUrls: ["./type-dialog.component.css"],
})
export class TypeDialogComponent implements OnInit {
  action = "Add";
  loading = false;
  originalTypeName = "";

  @Input() type = new Type({});

  constructor(
    private dialogRef: MatDialogRef<TypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private notifyService: NotificationService,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.action = this.utilityService.capitalizeFirstLetter(this.data.action);
    if (this.data.type) {
      this.type = Object.assign({}, this.data.type);
      this.originalTypeName = this.type.name;
    }
  }

  async processForm() {
    this.loading = true;
    if (this.originalTypeName == this.type.name && this.action !== "Delete") {
      this.notifyService.notify("Type has not been changed");
      this.loading = false;
      return;
    }
    if (this.type.name?.trim()) {
      let duplicate = false;
      if (this.action !== "Delete") {
        for (const type of this.data.types) {
          if (this.type.name === type.name) {
            duplicate = true;
          }
        }
      }
      if (!duplicate || this.action == "Delete") {
        this.dialogRef.close(this.type);
      } else {
        this.notifyService.notify("The type already exists");
      }
    } else {
      this.notifyService.notify("Type is required");
    }
    this.loading = false;
  }
}
