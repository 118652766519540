import { Component, Input, ElementRef, Output, EventEmitter, ViewChild, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipListbox } from "@angular/material/chips";
import { UsersService } from "src/app/services/admin-plus/users.service";
import { User } from "src/app/users/users";
import { fromEvent } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@Component({
  selector: "app-name-email",
  templateUrl: "./name-email.component.html",
  styleUrls: ["./name-email.component.css"],
})
export class NameEmailComponent implements OnInit {
  @Input() user: User;
  @Input() fieldLabel = "";
  @Input() multiple: boolean;
  @Input() disabled = false;
  @Input() placeholder = "";
  @Input() requestedBy = null;
  @Input() parentLoading: boolean;
  @Output() selectedUser = new EventEmitter();
  @ViewChild("userInput") userInput: ElementRef;
  @ViewChild("userList") userList: MatChipListbox;

  users: User[];
  selected_user: User;
  filteredOptions: Observable<User[]>;

  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  orderBy = "givenName";
  sortOrder = "SORT_ORDER_UNDEFINED";
  searchUser = "";
  params = {
    maxResults: 100,
    orderBy: "givenName",
    sort: "DESC",
    email: null,
  };

  constructor(private usersService: UsersService) {}

  async ngOnInit() {
    if (this.requestedBy.name || this.requestedBy.email) {
      this.selected_user = {
        id: null,
        primaryEmail: null,
        name: {
          givenName: null,
          familyName: null,
          fullName: null,
        },
        customerId: null,
      };
      this.selected_user.name.fullName = this.requestedBy.name;
      this.selected_user.primaryEmail = this.requestedBy.email;
    } else {
      this.selected_user = null;
    }
    if (!this.disabled) {
      this.users = await this.getUsers();
      fromEvent(this.userInput.nativeElement, "input")
        .pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map((event: any) => event.target.value),
          switchMap((value: string) => this.getUsers(value))
        )
        .subscribe((res) => {
          this.users = res;
        });
    }
  }

  async getUsers(search?: string) {
    const searchedUsers = [];
    this.params.email = search;
    if (!this.selected_user) {
      let users = [];
      const responseUsers = await this.usersService.getGoogleUsers(13, this.params);
      users = users.concat(responseUsers);
      if (users) {
        searchedUsers.push(...users);
      }
    }
    return searchedUsers;
  }

  async remove() {
    const removeObject = { deleted: true };
    this.selectedUser.emit(removeObject);
    this.selected_user = null;
    this.users = await this.getUsers();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selected_user = event.option.value;
    this.selectedUser.emit(event);
    this.userInput.nativeElement.value = "";
  }
}
