import { Injectable } from "@angular/core";
import { Module } from "src/app/models/admin-plus/base";

@Injectable({
  providedIn: "root",
})
export class ModuleService {
  private modules: Module[];
  private defaultModules = ["product-updates"];

  isValidModule(moduleName: string) {
    if (!this.modules?.length) this.modules = JSON.parse(localStorage.getItem("modules")) || [];

    if (this.defaultModules.includes(moduleName)) return true;

    return this.modules.find((module) => module.name == moduleName);
  }
}
