<ul class="list-group pl-0">
  <li *ngIf="device?.chromeOsDeviceId" class="list-group-item">
    <span class="list__title">Chrome Os Device ID</span>
    <span class="asset__subtitle">{{ device.chromeOsDeviceId }}</span>
  </li>
  <li *ngIf="device?.assignedUser" class="list-group-item">
    <span class="list__title">Assigned User</span>
    <span class="asset__subtitle">{{ device.assignedUser }}</span>
  </li>
  <li *ngIf="device?.assignedUser && device?.assignedDate" class="list-group-item">
    <span class="list__title">Assigned Date</span>
    <span class="asset__subtitle">{{ device.assignedDate | date : "short" }}</span>
  </li>
  <li *ngIf="device?.createdBy" class="list-group-item">
    <span class="list__title">Created By</span>
    <span class="asset__subtitle">{{ device.createdBy }}</span>
  </li>
  <li *ngIf="device?.model" class="list-group-item">
    <span class="list__title">Model</span>
    <span class="asset__subtitle">{{ device.model }}</span>
  </li>
  <li *ngIf="device?.serialNumber" class="list-group-item">
    <span class="list__title">Serial Number</span>
    <span class="asset__subtitle">{{ device.serialNumber }}</span>
  </li>
  <li *ngIf="device?.ou" class="list-group-item">
    <span class="list__title">Organizational Unit</span>
    <span class="asset__subtitle">{{ device.ou.orgUnitPath }}</span>
  </li>
  <li *ngIf="device?.manufacturer" class="list-group-item">
    <span class="list__title">Manufacturer</span>
    <span class="asset__subtitle">{{ device.manufacturer.name }}</span>
  </li>
  <li *ngIf="device?.building" class="list-group-item">
    <span class="list__title">Building</span>
    <span class="asset__subtitle">{{ device.building.name }}</span>
  </li>
  <li *ngIf="device?.room" class="list-group-item">
    <span class="list__title">Room</span>
    <span class="asset__subtitle">{{ device.room.name }}</span>
  </li>
  <li *ngIf="device?.annotatedLocation" class="list-group-item">
    <span class="list__title">Custom Location</span>
    <span class="asset__subtitle">{{ device.annotatedLocation }}</span>
  </li>
  <li *ngIf="device?.type" class="list-group-item">
    <span class="list__title">Type</span>
    <span class="asset__subtitle">{{ device.type.name }}</span>
  </li>
  <li *ngIf="device?.assetTag" class="list-group-item">
    <span class="list__title">Asset Tag</span>
    <span class="asset__subtitle">{{ device.assetTag }}</span>
  </li>
  <li *ngIf="device?.osVersion" class="list-group-item">
    <span class="list__title">OS Version</span>
    <span class="asset__subtitle">{{ device.osVersion }}</span>
  </li>
  <li *ngIf="device?.lastSync" class="list-group-item">
    <span class="list__title">Last Sync</span>
    <span class="asset__subtitle">{{ device.lastSync | date : "short" }}</span>
  </li>
  <li *ngIf="device?.status" class="list-group-item">
    <span class="list__title">Status</span>
    <span class="asset__subtitle">{{ device.status.name }}</span>
  </li>
  <li *ngIf="device?.macAddress" class="list-group-item">
    <span class="list__title">MAC Address</span>
    <span class="asset__subtitle">{{ device.macAddress }}</span>
  </li>
  <li *ngIf="device?.lastIpAddress" class="list-group-item">
    <span class="list__title">Last IP Address</span>
    <span class="asset__subtitle">{{ device.lastIpAddress }}</span>
  </li>
  <li *ngIf="device?.lastWanIpAddress" class="list-group-item">
    <span class="list__title">Last WAN IP Address</span>
    <span class="asset__subtitle">{{ device.lastWanIpAddress }}</span>
  </li>
  <li *ngIf="device?.autoUpdateExpiration" class="list-group-item">
    <span class="list__title">Auto Update Expiration</span>
    <span class="asset__subtitle">{{ device.autoUpdateExpiration | date : "short" }}</span>
  </li>
</ul>
