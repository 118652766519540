import { Customer } from "src/app/services/admin-plus/customer.service";
import { UserAction } from "../admin-plus/base";

export interface IType {
  id: number;
  customer: Customer;
  createdBy: UserAction;
  name: string;
  updateType(): Promise<Type>;
  deleteType(): Promise<Type>;
}

export class Type implements IType {
  id: number;
  customer: Customer;
  createdBy: UserAction;
  name: string;

  constructor(properties) {
    Object.assign(this, properties);
  }

  async updateType() {
    return this;
  }

  async deleteType() {
    return this;
  }
}
