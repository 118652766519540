<div class="row align-items-center pt-2">
  <div class="col-12 col-md-6 mt-2">
    <div class="d-flex flex-wrap">
      <h2 class="mb-0">
        <i style="font-size: 28px" class="material-icons mr-3">person</i>
      </h2>
      <span class="h2 mb-0">Memberships</span>
    </div>
  </div>

  <div class="col-12 col-md-6">
    <div class="d-flex justify-content-end align-items-center">
      <a
        *ngIf="dataSource.data.length > 0"
        class="success"
        mat-stroked-button
        (click)="userMembershipsSpreadsheet()"
        matTooltip="Create Group Memberships Google Sheet"
      >
        Export to Google Sheets
      </a>
      <button mat-stroked-button (click)="openDialog()" class="create ml-2">Add Memberships</button>
    </div>
  </div>

  <div class="col-12 col-lg-4 col-xl-3 mt-2">
    <mat-form-field class="w-100">
      <mat-label>Actions</mat-label>
      <mat-select [formControl]="actions">
        <mat-option value="" selected></mat-option>
        <mat-option value="delete" [disabled]="selection.selected.length == 0"> Delete </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-12 col-lg-4 col-xl-6 mt-2">
    <mat-form-field class="w-100">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. email@domain.com" #input />
    </mat-form-field>
  </div>

  <div class="col-12 col-lg-4 col-xl-3 mt-2">
    <mat-form-field class="w-100">
      <mat-label>Columns</mat-label>
      <mat-select [formControl]="columns" multiple>
        <mat-option *ngFor="let column of columnsList" [value]="column" (click)="tableColumnChange(column)">{{
          column == "directMembersCount" ? "Members" : (column | titlecase)
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-12">
    <div class="table-responsive has-active">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              color="primary"
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && getPageData()"
              [indeterminate]="selection.hasValue() && !getPageData()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              color="primary"
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td (click)="routeToGroup(row.id)" mat-cell *matCellDef="let row">
            {{ row.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
          <td (click)="routeToGroup(row.id)" mat-cell *matCellDef="let row">
            {{ row.email }}
          </td>
        </ng-container>
        <ng-container matColumnDef="directMembersCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Members</th>
          <td (click)="routeToGroup(row.id)" mat-cell *matCellDef="let row">
            {{ row.directMembersCount }}
          </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
          <td (click)="routeToGroup(row.id)" mat-cell *matCellDef="let row">{{ row.description }}</td>
        </ng-container>
        <tr class="mat-row" app-empty-dataset-alert [loading]="loading" [filterCount]="dataSource.filter.length" *matNoDataRow></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <app-table-footer
      [dataSource]="dataSource"
      [maxResults]="maxResults"
      [nextButton]="nextButton"
      [nextButtonDis]="nextButtonDis"
      [nextPageToken]="nextPageToken"
      [prevPageToken]="prevPageToken"
      [offset]="offset"
      [page]="page"
      (pager)="setPager($event)"
      [disabled]="isFetchingData"
    ></app-table-footer>
  </div>
</div>
