import { Injectable } from "@angular/core";
import { Status } from "src/app/models/ticket-plus/status";
import { Payload } from "../utilities/utility.service";
import { TicketPlusService } from "./ticket-plus.service";

@Injectable({
  providedIn: "root",
})
export class StatusService extends TicketPlusService {
  private url = `/ticket-plus/statuses`;

  async getStatuses(params = {}): Promise<Payload> {
    return this.cacheService.getPayloadAndCache(this.url, params, this.utilityService);
  }

  async addStatus(data: Status): Promise<Status> {
    this.cacheService.clearCache(this.url);
    return new Promise<Status>((resolve) => {
      this.utilityService.sendRequest("POST", this.url, {}, data).subscribe((payload) => {
        if (payload?.data?.length) {
          resolve(payload.data[0]);
        } else {
          resolve(null);
        }
      });
    });
  }

  async deleteStatus(status: Status): Promise<Payload> {
    this.cacheService.clearCache(this.url);
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("DELETE", `${this.url}/${status.id}`).subscribe((payload) => {
        resolve(payload);
      });
    });
  }

  async getGroupedStatuses() {
    const statusData = await this.getStatuses();

    if (!statusData?.data?.length) return [];

    const parentStatuses = statusData.data.filter((status) => {
      return status.parentId == null;
    });

    for (const parentStatus of parentStatuses) {
      parentStatus.children = [];
      const childStatuses = statusData.data.filter((status) => {
        return status.parentId == parentStatus.id;
      });

      parentStatus.children = childStatuses;
    }
    return parentStatuses;
  }
}
