import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/admin-plus/auth.service";
import { trigger, style, animate, transition } from "@angular/animations";
import { LoginService } from "src/app/services/admin-plus/login.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  animations: [
    trigger("loginAnimation", [
      transition(":enter", [
        style({ opacity: 0, transform: "translateY(50px) scale(0.8)" }),
        animate("1s ease-in-out", style({ opacity: 1, transform: "translateY(0) scale(1)" })),
      ]),
    ]),
  ],
})
export class HomeComponent {
  constructor(private router: Router, public authService: AuthService, private loginService: LoginService) {
    if (this.loginService.user?.jwt?.expires > new Date().getTime()) {
      this.router.navigateByUrl("dashboard");
    } else {
      sessionStorage.clear();
      localStorage.clear();
    }
  }
}
