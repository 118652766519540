import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { LoginService } from "../admin-plus/login.service";
import { AuthService } from "../admin-plus/auth.service";
import cronstrue from "cronstrue";
import { NavService } from "../admin-plus/nav.service";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

@Injectable({
  providedIn: "root",
})
export class UtilityService {
  private user;
  private apiUrl: string = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private loginService: LoginService,
    private navService: NavService
  ) {}

  sendRequest(method = "GET", path = ``, params = {}, body = {}, mute = false): Observable<Payload> {
    this.user = this.loginService.getUser();
    if (!this.user?.idToken || !this.user?.authToken) {
      return new Observable<Payload>((observer) => {
        observer.next(null);
        observer.error({ message: "Invalid credentials" });
      });
    }

    //TODO: if !this.user.authToken || !this.user.idToken

    const apiUrl = this.apiUrl + path;
    if (method == "GET") {
      return this.http
        .get<Payload>(apiUrl, {
          params: this.buildParams(params),
          headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        })
        .pipe(catchError(this.authService.handleAPIError<Payload>("sendRequest", mute)));
    } else if (method == "POST") {
      return this.http
        .post<Payload>(apiUrl, body, {
          params: this.buildParams(params),
          headers: this.buildHeaders(),
        })
        .pipe(catchError(this.authService.handleAPIError<Payload>("sendRequest", mute)));
    } else if (method == "DELETE") {
      return this.http
        .delete<Payload>(apiUrl, {
          params: this.buildParams(params),
          headers: this.buildHeaders(),
        })
        .pipe(catchError(this.authService.handleAPIError<Payload>("sendRequest", mute)));
    } else if (method == "PUT") {
      return this.http
        .put<Payload>(apiUrl, body, {
          params: this.buildParams(params),
          headers: this.buildHeaders(),
        })
        .pipe(catchError(this.authService.handleAPIError<Payload>("sendRequest", mute)));
    }
  }

  buildParams(params) {
    let httpParams = new HttpParams();

    if (!params) return;

    Object.keys(params).forEach((k) => {
      if (params[k] != null) httpParams = httpParams.set(k, params[k]);
    });
    return httpParams;
  }

  buildHeaders() {
    return new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken);
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  public crontrue(cron: string) {
    return cronstrue.toString(cron, { verbose: true });
  }

  compareObjects(o1: any, o2: any) {
    return o1?.id == o2?.id;
  }

  public async downloadAsPDF() {
    this.navService.loading.next(true);
    const canvas = await this.canvas();
    if (canvas) {
      this.navService.loading.next(false);
    }
  }

  public async canvas() {
    return new Promise((resolve) => {
      html2canvas(document.getElementById("entireScreen")).then(function (canvas) {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 210;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        const doc = new jsPDF("p", "mm");
        let position = 0;
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save("storage-report-user.pdf");
        resolve(true);
      });
    });
  }
}

export interface Payload {
  count: number;
  //The data here has to be any because this interface is used across multiple services
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any;
}

export interface Action {
  name: string;
  description: string;
  btnLabel: string;
  url?: string;
  params?: unknown;
  disabled?: boolean;
  object?: string;
  alwaysEnabled?: boolean;
  unassigned?: boolean;
}

export interface ActionBody {
  action: Action;
  ids?: number[];
  allSelected: boolean;
}
