import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
	selector: "app-notification-dialog",
	templateUrl: "./notification-dialog.component.html",
	styleUrls: ["./notification-dialog.component.scss"],
})
export class NotificationDialogComponent {
	public title: string;
	public message: string;
	public btnCancelText = "Cancel";

	constructor(
		public dialogRef: MatDialogRef<NotificationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: NotificationDialogModel
	) {
		this.title = data.title;
		this.message = data.message;
		this.btnCancelText = data.btnCancelText;
	}

	public onConfirm(): void {
		this.dialogRef.close(true);
	}
}

export class NotificationDialogModel {
	constructor(public title: string, public message: string, public btnCancelText = "Cancel") {}
}
