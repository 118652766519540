import { Injectable } from "@angular/core";
import { Payload } from "src/app/services/utilities/utility.service";

@Injectable({
  providedIn: "root",
})
export class CacheService {
  cache: Payload[] = [];

  clearCache(url: string) {
    this.cache[url] = [];
  }

  async getPayloadAndCache(url: string, params = {}, utilityService) {
    return new Promise<Payload>((resolve) => {
      if (!this.cache[url]) this.cache[url] = [];
      const cachedDataIndex = this.cache[url]?.findIndex((obj) => obj.params == JSON.stringify(params));
      utilityService.sendRequest("GET", url, params).subscribe((payload) => {
        payload.params = JSON.stringify(params);
        if (cachedDataIndex != -1) {
          this.cache[url][cachedDataIndex] = payload;
        } else {
          this.cache[url] = [];
          this.cache[url].push(payload);
        }
        resolve(payload);
      });

      if (cachedDataIndex != -1) resolve(this.cache[url][cachedDataIndex ? cachedDataIndex : 0]);
    });
  }
}
