import { Injectable } from "@angular/core";
import { Building } from "src/app/models/admin-plus/building";
import { Payload } from "../utilities/utility.service";
import { AdminPlusService } from "./admin-plus.service";

@Injectable({
  providedIn: "root",
})
export class BuildingsService extends AdminPlusService {
  private cache: Payload[] = [];

  async getBuildings(params = {}): Promise<Payload> {
    return new Promise<Payload>((resolve) => {
      const cachedDataIndex = this.cache.findIndex((obj) => obj.params == JSON.stringify(params));
      this.utilityService.sendRequest("GET", `/admin-plus/buildings`, params).subscribe((payload) => {
        payload.params = JSON.stringify(params);
        if (cachedDataIndex != -1) {
          this.cache[cachedDataIndex] = payload;
        } else {
          this.cache.push(payload);
        }
        resolve(payload);
      });

      if (cachedDataIndex != -1) resolve(this.cache[cachedDataIndex]);
    });
  }

  async addBuilding(building: Building): Promise<Payload> {
    this.clearCache();
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("POST", `/admin-plus/buildings`, null, building).subscribe((payload) => {
        if (payload?.data?.length) {
          resolve(payload);
        } else {
          resolve(null);
        }
      });
    });
  }

  async deleteBuilding(building: Building): Promise<Payload> {
    this.clearCache();
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("DELETE", `/admin-plus/buildings/${building.id}`, null).subscribe((payload) => {
        if (payload?.data?.length) {
          resolve(payload);
        } else {
          resolve(null);
        }
      });
    });
  }

  clearCache() {
    this.cache = [];
  }
}
