import { Component, Input, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Ticket } from "../../../models/ticket-plus/ticket";
import { FilterType } from "src/app/services/utilities/filters.service";
import { Payload } from "src/app/services/utilities/utility.service";
import { TicketService } from "src/app/services/ticket-plus/ticket/ticket.service";
import { User } from "src/app/users/users";
import { NavService } from "src/app/services/admin-plus/nav.service";

@Component({
  selector: "app-ticket-list",
  templateUrl: "./ticket-list.component.html",
  styleUrls: ["./ticket-list.component.scss"],
})
export class TicketListComponent implements OnInit {
  ImagePath: string;
  payload: Payload;
  tickets: Ticket[];
  loading = true;
  @Input() filters: string;
  @Input() title: string;
  @Input() hideSeeAll = false;
  filterTypeValue: FilterType = FilterType.TICKET;
  user: User;

  constructor(
    private router: Router,
    private ticketService: TicketService,
    private activatedRoute: ActivatedRoute,
    private navService: NavService
  ) {
    const user: User = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.user = user;
    }
    this.ImagePath = "/assets/images/adminplus.svg";
  }

  ngOnInit() {
    this.getTickets();
  }

  // get tickets
  private async getTickets() {
    this.navService.loading.next(true);
    this.loading = true;
    this.payload = await this.ticketService.getTickets(Object.assign(this.filters, this.payload));
    this.loading = false;
    this.navService.loading.next(false);

    if (!this.payload) return;

    this.tickets = this.payload.data;
  }

  seeAll() {
    const tableId = `_${FilterType.TICKET.toString()}`;
    let paramKey = "";
    let paramValue = "";
    let sort = false;
    let queryParams = {};
    switch (this.title) {
      case "Unassigned Tickets":
        paramKey = encodeURI("Unassigned") + tableId;
        paramValue = "true";
        break;
      case "Assigned To Me":
        paramKey = encodeURI("Assigned Agent") + tableId;
        paramValue = this.user.firstName + " " + this.user.lastName;
        break;
      case "Oldest Open Tickets":
        paramKey = encodeURI("Status") + tableId;
        paramValue = "Open";
        queryParams = {
          [paramKey]: paramValue,
          orderBy: "created",
          sort: "ASC",
        };
        sort = true;
        break;
      case "Requested By Me":
        paramKey = encodeURI("Requested By") + tableId;
        paramValue = this.user.email;
        break;
    }
    if (!sort) {
      queryParams = {
        [paramKey]: paramValue,
      };
    }
    this.router.navigate(["/ticketing/tickets"], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: "merge",
    });
  }

  routeToTicket(id: string) {
    this.router.navigate(["/ticketing/tickets/" + id]);
  }
}
