<div class="row">
 <div class="col-12 col-md-4 mt-2">
  <mat-form-field class="w-100 h-100">
	<mat-label>Data Source</mat-label>			
	<mat-select [(value)]="selectedModule">
   <mat-option *ngFor="let type of dataType" [value]="type.value" (click)="changeSelectedModule(type.value)">
              {{ type.name }}
            </mat-option>		
					</mat-select>
	</mat-form-field>
	</div>
  <div class="col-12 col-md-8 mt-2">
    <app-filters [filterType]="filterTypeValue" [auditDataType]="params.selectedModule" (dataEvent)="receiveData($event)"></app-filters>
  </div>

  <div class="col-12">
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="changeSort($event)">
        <ng-container matColumnDef="created">
          <th class="px-3 text-wrap" mat-header-cell mat-sort-header *matHeaderCellDef>Created</th>
          <td class="py-3 px-3" mat-cell *matCellDef="let row">
            {{ row.created ? (row.created | date : "short") : "--" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="auditType">
          <th class="px-3 text-wrap" mat-header-cell mat-sort-header *matHeaderCellDef>Tool</th>
          <td class="py-3 px-3" mat-cell *matCellDef="let row">
            {{ row.auditType | emptyValueDisplay }}
          </td>
        </ng-container>
        <ng-container matColumnDef="object">
          <th class="px-3 text-wrap" mat-header-cell mat-sort-header *matHeaderCellDef>Object Type</th>
          <td class="py-3 px-3" mat-cell *matCellDef="let row">
            {{ row.object | emptyValueDisplay }}
          </td>
        </ng-container>
        <ng-container matColumnDef="methodExact">
          <th class="px-3 text-wrap" mat-header-cell mat-sort-header *matHeaderCellDef>Action</th>
          <td class="py-3 px-3" mat-cell *matCellDef="let row">
            <span *ngIf="row.method == 'POST'">Created</span>
            <span *ngIf="row.method == 'PUT'">Updated</span>
            <span *ngIf="row.method == 'DELETE'">Deleted</span>
            <span *ngIf="!['POST', 'PUT', 'DELETE'].includes(row.methodExact)">{{ row.methodExact }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="createdBy">
          <th class="px-3 text-wrap" mat-header-cell mat-sort-header *matHeaderCellDef>Created By</th>
          <td class="py-3 px-3" mat-cell *matCellDef="let row">
            {{ row.createdBy | emptyValueDisplay }}
          </td>
        </ng-container>
        <ng-container matColumnDef="data">
          <th class="px-3 text-wrap" mat-header-cell *matHeaderCellDef></th>
          <td class="py-3 px-3 text-wrap" mat-cell *matCellDef="let audit">
            <span *ngIf="audit.data">
              <span>
                <button mat-icon-button [matMenuTriggerFor]="menu">
                  <span class="material-icons mat-icon"> settings </span>
                </button>
              </span>
              <mat-menu #menu="matMenu" xPosition="before" yPosition="above">
                <button mat-menu-item (click)="viewDetails(audit.data)" matTooltip="View details">View Details</button>
              </mat-menu>
            </span>
          </td>
        </ng-container>
        <tr class="mat-row" app-empty-dataset-alert [loading]="loading" [filterCount]="filtersCount" *matNoDataRow></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <app-table-footer
    [dataSource]="dataSource"
    [maxResults]="params.limit"
    [nextButton]="nextButton"
    [nextButtonDis]="nextButtonDis"
    [offset]="params.offset"
    [page]="page"
    [total]="totalCount"
    (pager)="setPager($event)"
  ></app-table-footer>
  </div>
</div>
