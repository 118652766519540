<div class="row" *ngIf="!loading">
  <div class="col-12 col-xl-6 mt-2">
    <mat-card class="p-4 h-100 mat-shadow-hover">
      <h2 class="text-center">Top Organizational Units by Usage Amount</h2>
      <app-storage-report-ou-pie-chart [ous]="topOus"></app-storage-report-ou-pie-chart>
    </mat-card>
  </div>
  <div class="col-12 col-xl-6 mt-2">
    <mat-card class="p-4 h-100 mat-shadow-hover">
      <h2 class="text-center mb-5">Top User in Each of the Top Organizational Units</h2>
      <app-storage-report-ou-bar-chart [users]="topUsers"></app-storage-report-ou-bar-chart>
    </mat-card>
  </div>
  <div class="col-12 mt-2">
      <h2 class="pt-4">Organizational Units</h2>
      <app-storage-report-organizational-units-table></app-storage-report-organizational-units-table>
  </div>
</div>
