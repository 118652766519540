import { Customer } from "src/app/services/admin-plus/customer.service";
import { UserAction } from "../admin-plus/base";

export interface IStatus {
  id: number;
  customer: Customer;
  createdBy: UserAction;
  name: string;
  updateType(): Promise<Status>;
  deleteType(): Promise<Status>;
}

export class Status implements IStatus {
  id: number;
  customer: Customer;
  createdBy: UserAction;
  name: string;

  constructor(properties) {
    Object.assign(this, properties);
  }

  async updateType() {
    return this;
  }

  async deleteType() {
    return this;
  }
}
