import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { HelpDialogServiceComponent } from "src/app/layout/dialogs/help-dialog-service/help-dialog-service.component";

@Injectable({
	providedIn: "root",
})
export class HelpDialogService {
	constructor(private dialog: MatDialog) {}

	openHelpDialogService(title: string, message: string, list = false) {
		this.dialog.open(HelpDialogServiceComponent, {
			width: "500px",
			data: {
				title: title,
				message: message,
				list: list,
			},
		});
	}
}
