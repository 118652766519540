import { Injectable } from "@angular/core";
import { Payload } from "src/app/services/utilities/utility.service";
import { AdminPlusService } from "../admin-plus/admin-plus.service";
import { Attachment } from "src/app/models/admin-plus/attachment";

@Injectable({
  providedIn: "root",
})
export class AttachmentsService extends AdminPlusService {
  toFormData(file) {
    const formData = new FormData();
    formData.append("attachment", file);
    return formData;
  }

  async upload(file: File, params?): Promise<Attachment> {
    return await new Promise<Attachment>((resolve) => {
      this.utilityService
        .sendRequest("POST", "/admin-plus/attachments/upload", params, this.toFormData(file))
        .subscribe((payload) => {
          if (payload?.data?.length) {
            resolve(payload.data[0]);
          } else {
            resolve(null);
          }
        });
    });
  }

  async getAttachment(id: number, params?): Promise<Attachment> {
    return new Promise<Attachment>((resolve) => {
      this.utilityService.sendRequest("GET", `/admin-plus/attachments/${id}`, params).subscribe((payload) => {
        if (payload?.data?.length) {
          resolve(payload.data[0]);
        } else {
          resolve(null);
        }
      });
    });
  }

  async deleteAttachment(id: number, params?): Promise<Payload> {
    return await new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("DELETE", `/admin-plus/attachments/${id}`, params, {}).subscribe((payload) => {
        resolve(payload);
      });
    });
  }
}
