import { Component, Input } from "@angular/core";
import { Payload } from "src/app/services/utilities/utility.service";

@Component({
  selector: "app-recent-users",
  templateUrl: "./recent-users.component.html",
  styleUrls: ["./recent-users.component.scss"],
})
export class RecentUsersComponent {
  @Input() payload: Payload;
  @Input() loading = true;
}
