<h2 mat-dialog-title>Enter Bucket Name - Enable Google Cloud Storage</h2>
<form (ngSubmit)="processForm()" autocomplete="off">
  <div mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <p>
          This action will require that billing is enabled for your project and storing data in Google Cloud will incur
          additional charges. 
        </p>
        
      </div>
    </div>
    <div class="row">
      <div class="col-12 my-3">
        <mat-form-field class="w-100">
          <mat-label> Bucket Name </mat-label>
          <input
            maxlength="255"
            matInput
            [(ngModel)]="bucketName"
            type="text"
            name="bucketName"
            id="bucketName"
            placeholder="Bucket Name"
            autocomplete="off"
            required
          />
          <mat-hint> <sup>*</sup>Any users sending files to this bucket will need write access </mat-hint>        
        </mat-form-field>
      </div>
    </div>
    <app-dialog-actions-container></app-dialog-actions-container>
  </div>
</form>
