<h2 mat-dialog-title>{{ action }} Category</h2>
<form (ngSubmit)="processForm()" autocomplete="off">
  <div mat-dialog-content>
    <app-loader *ngIf="loading"></app-loader>
    <div class="row">
      <div class="col-6 mt-2" *ngIf="action != 'Delete'">
        <mat-form-field class="w-100">
          <mat-label>Parent</mat-label>
          <mat-select name="parent" [(ngModel)]="category.parentId">
            <mat-option
              *ngFor="let parent of data.categories"
              mat-tooltip="{{ parent.name }}"
              [value]="parent.id"
              [disabled]="parent.id == category.id"
              >{{ parent.name }} <small>{{ parent.id == category.id ? "(Current)" : "" }}</small>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6 mt-2" *ngIf="action != 'Delete'">
        <mat-form-field class="w-100">
          <mat-label>Label</mat-label>
          <input matInput type="text" [(ngModel)]="category.name" name="category" required />
        </mat-form-field>
      </div>
      <div class="col-12 mt-2" *ngIf="action == 'Delete'">
        <h4>Are you sure you want to delete {{ category.name }}?</h4>
        <strong *ngIf="category.children?.length > 0">This will delete ALL child categories</strong>
      </div>
    </div>
    <div mat-dialog-actions class='px-0'>
      <button mat-stroked-button class="" mat-dialog-close>Cancel</button>
      <button
        type="submit"
        mat-stroked-button 
        class="ml-2"
        [class]="action == 'Delete' ? 'danger' : 'success'"
        [disabled]="loading"
      >
        <span>{{ action }} Category</span>
      </button>
    </div>
  </div>
</form>
