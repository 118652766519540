import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { environment } from "src/environments/environment";
import { AuthService } from "src/app/services/admin-plus/auth.service";

@Injectable({
  providedIn: "root",
})
export class FreeTrialService {
  private user: any;
  private apiUrl: string = environment.apiUrl + "/free-trial";

  constructor(
    private loginService: LoginService,
    private httpClient: HttpClient,
    private authService: AuthService,
    private http: HttpClient
  ) {}

  public async getNumberOfUsers(isoDate: string, accessToken: string) {
    return new Promise<UsageReportResult>((resolve) => {
      this.getNumberOfUsersRequest(isoDate, accessToken).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getNumberOfUsersRequest(isoDate: string, accessToken: string) {
    return this.http
      .get<UsageReportResult>(`https://admin.googleapis.com/admin/reports/v1/usage/dates/${isoDate}`, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + accessToken),
        params: new HttpParams().set("parameters", "accounts:num_users"),
      })
      .pipe(catchError(this.authService.handleAPIError<UsageReportResult>("getNumberOfUsersRequest", true)));
  }

  public async getGoogleCustomer(accessToken: string) {
    return new Promise<unknown>((resolve) => {
      this.getGoogleCustomerRequest(accessToken).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getGoogleCustomerRequest(accessToken: string) {
    return this.http
      .get<unknown>(`https://admin.googleapis.com/admin/directory/v1/customers/my_customer`, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + accessToken),
      })
      .pipe(catchError(this.authService.handleAPIError<unknown>("getNumberOfUsersRequest")));
  }

  public async createFreeTrial(freeTrial: FreeTrial) {
    this.user = this.loginService.getUser();
    if (this.user) {
      return;
    }
    return new Promise<FreeTrial>((resolve) => {
      this.createFreeTrialData(freeTrial).subscribe((freeTrial) => {
        if (freeTrial) {
          resolve(freeTrial);
        }
      });
    });
  }

  private createFreeTrialData(freeTrial: FreeTrial) {
    return this.httpClient
      .post<FreeTrial>(this.apiUrl + "/add", freeTrial)
      .pipe(catchError(this.authService.handleAPIError<FreeTrial>("createFreeTrialData")));
  }
}

export interface FreeTrial {
  organization: string;
  firstName: string;
  lastName: string;
  email: string;
  domain: string;
  customerId: string;
  numUsers: number;
}

export interface UsageReportResult {
  usageReports: UsageReport[];
}

export interface UsageReport {
  parameters: Parameter[];
}

export interface Parameter {
  name: string;
  intValue: string;
}
