import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { GooglePickerService } from "src/app/services/utilities/google-picker.service";
import { AutomationService } from "src/app/services/workflow-plus/automation.service";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { User } from "src/app/web/login/user";
import { FileSelectorMimeType } from "src/enums/fileSelectorMimeType";

declare const google;

@Component({
  selector: "app-file-selector",
  templateUrl: "./file-selector.component.html",
  styleUrls: ["./file-selector.component.scss"],
})
export class FileSelectorComponent implements OnInit {
  private user: User;
  // allows multiple or single file selection
  @Input() multiSelect: boolean;
  @Input() parameter: null;
  // emits selected file(s) ids
  @Output() onSelectionChange = new EventEmitter<string[]>();
  // allowed mimeType(s)
  @Input() mimeType: FileSelectorMimeType;
  @Input() value: string;
  @Input() file = "";
  @Input() parameterProperty = null;
  @Input() loadAlertInfoMessage = false;
  @Output() selectedFile = new EventEmitter();
  // mat-chip
  selected_items = [];
  itemCtrl = new UntypedFormControl();
  public fileId = "";
  public spinner = false;
  public checkReadAccess = null;
  public checkAccess = {};
  public readonly separatorKeysCodes = [ENTER, COMMA] as const;
  @ViewChild("itemInput") itemInput: ElementRef;

  constructor(
    private loginService: LoginService,
    private chRef: ChangeDetectorRef,
    private automationService: AutomationService
  ) {
    // default is multiple
    this.multiSelect = true;
    // default is AllFiles
    this.mimeType = FileSelectorMimeType.AllFiles;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) this.convertToChip({ target: { value: changes.value.currentValue } });
  }

  ngOnInit(): void {
    if (this.value) {
      this.selected_items = [this.value];
    }
  }

  async openFilePickerDialog() {
    this.user = this.loginService.getUser();
    const picker = new GooglePickerService(this.user, this.mimeType, this.multiSelect);

    picker.open(async (data) => {
      this.spinner = true;
      this.checkReadAccess = null;
      this.file = "";
      this.selected_items = [];
      this.selectedFile.emit(this.file);
      if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
        if (data && data.docs && data.docs.length > 0) {
          if (!this.multiSelect) {
            this.fileId = data.docs.map((doc) => doc.id)[0];
            this.selected_items = [this.fileId];
            this.onSelectionChange.emit([this.fileId]);
            this.chRef.detectChanges();
          } else {
            const fileIds = data.docs.map((doc) => doc.id);
            fileIds.forEach((fileId) => {
              this.selected_items.push(fileId);
            });
            this.onSelectionChange.emit(fileIds);
            this.chRef.detectChanges();
          }
        }
      }
      if (data.action === "picked" || data.action === "cancelled") {
        picker.dispose();
        this.checkReadAccessForFile();
      }
      this.spinner = false;
    });
  }

  generateTitle() {
    return "Enter File Id";
  }

  async convertToChip(event) {
    const value = (event.target.value || "").trim();
    if (value) {
      // file ids are either 33 or 44 characters long
      if (this.itemInput?.nativeElement?.value.length < 33) {
        this.itemInput.nativeElement.value = "";
      } else {
        if (this.itemInput?.nativeElement?.value && this.itemInput?.nativeElement?.value !== "") {
          this.spinner = true;
          this.fileId = this.itemInput?.nativeElement?.value;
          await this.checkReadAccessForFile();
          this.spinner = false;
        }
        if (this.multiSelect) this.selected_items.push(value);
        else this.selected_items = [value];
        if (this.itemInput && this.itemInput.nativeElement) this.itemInput.nativeElement.value = "";
        this.itemCtrl.setValue(null);
        this.onSelectionChange.emit(this.selected_items);
      }
    }
  }

  public async checkReadAccessForFile() {
    if (!this.parameterProperty || (this.parameterProperty && !this.parameterProperty.id)) {
      this.checkAccess = await this.automationService.checkFileReadAccess(this.fileId);
      if (this.checkAccess["success"] === true) {
        this.checkReadAccess = "true";
      } else {
        this.checkReadAccess = "false";
      }
    }
  }

  valueList(): string[] {
    return !this.file || this.file.split(",")[0] === "" ? [] : this.file.split(",");
  }

  remove(item: string): void {
    this.selected_items = this.selected_items.filter((selected_item) => selected_item !== item);
    if (this.parameterProperty && this.parameterProperty.id) {
      this.selectedFile.emit(this.file);
    } else {
      this.onSelectionChange.emit(this.selected_items);
    }
    this.checkReadAccess = null;
  }
}
