import { UserAction } from "../admin-plus/base";
import { Ticket } from "./ticket";

export interface ITag {
  id: number;
  name: string;
  createdBy: UserAction;
  updatedBy: UserAction;
  ticketCount: number;
  createTag(): Promise<Tag>;
  deleteTag(): Promise<Tag>;
}

export interface ITicketTag {
  ticket: Ticket;
  tag: Tag;
  createdBy: UserAction;
  createTicketTag(): Promise<TicketTag>;
  deleteTicketTag(): Promise<TicketTag>;
}

export class Tag implements ITag {
  id: number;
  name: string;
  ticketCount: number;
  createdBy: UserAction;
  updatedBy: UserAction;

  constructor(properties) {
    Object.assign(this, properties);
  }

  async createTag() {
    return this;
  }
  async deleteTag() {
    return this;
  }
}

export class TicketTag implements ITicketTag {
  ticket: Ticket;
  tag: Tag;
  createdBy: UserAction;

  constructor(properties) {
    Object.assign(this, properties);
  }

  async createTicketTag() {
    return this;
  }
  async deleteTicketTag() {
    return this;
  }
}
