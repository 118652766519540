<h2 mat-dialog-title>
	<i style="font-size: 20px" class="faa-shake material-icons material-icon-align mr-2">warning</i>
	<span>{{ action.name }}</span>
</h2>
<div mat-dialog-content>
	<div class="row">
		<div class="col">
			{{ action.description }}
		</div>
	</div>
	<div *ngIf="action.btnLabel === 'Assign'" class="row">
		<div class="col mt-2">
			<app-email
				defaultOption="Unassigned"
				(selectedEmail)="receiveEmail($event)"
				[email]="action.params.email"
				[placeholder]="action.name"
				[fieldLabel]="action.name"
				[multiple]="false"
				[custom]="false"
				[type]="1"
			>
			</app-email>
		</div>
	</div>
	<div *ngIf="action?.params && keyExists(action.params, 'volume')" class="row">
		<div class="col mt-2">
			<span>Volume</span>
			<mat-form-field class="w-100">
				<input type="number" min="0" max="100" matInput required [(ngModel)]="action.params['volume']" />
			</mat-form-field>
		</div>
	</div>
	<div *ngIf="action?.params && keyExists(action.params, 'orgUnitPath')" class="row">
		<div class="col mt-2">
			<span>Organizational Unit</span>
			<app-organizational-unit id="org_unit" name="org_unit" [(ngModel)]="action.params['orgUnitPath']" (onIdChanged)="orgUnitIdChanged($event)" required></app-organizational-unit>	
		</div>
	</div>
	<div class="row">
		<div class="col-12">
			<div mat-dialog-actions class='px-0'>
				<button mat-stroked-button mat-dialog-close>Cancel</button>
				<button mat-stroked-button class="success ml-2" [disabled]="invalidInput()" (click)="onConfirm()">
					{{ action.btnLabel }}
				</button>
			</div>
		</div>
	</div>
</div>
