import { NgModule } from "@angular/core";
import { NgxEditorModule } from "ngx-editor";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatIconModule } from "@angular/material/icon";
import { MatBadgeModule } from "@angular/material/badge";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatRadioModule } from "@angular/material/radio";
import { MatSortModule } from "@angular/material/sort";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatExpansionModule } from "@angular/material/expansion"; //matDatepicker
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTreeModule } from "@angular/material/tree";
import { MatCardModule } from "@angular/material/card";
import { MatStepperModule } from "@angular/material/stepper";
import { MatSliderModule } from "@angular/material/slider";
import { GoogleChartsModule } from "angular-google-charts";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppFiltersComponent } from "./layout/app-filters/app-filters.component";
import { TableFooterComponent } from "./layout/tables/table-footer/table-footer.component";
import { EmailComponent } from "./layout/inputs/email/email.component";
import { OrganizationalUnitComponent } from "./layout/inputs/organizational-unit/organizational-unit.component";
import { SingleSelectionComponent } from "./layout/inputs/single-selection/single-selection.component";
import { TreeSelectorComponent } from "./layout/dialogs/tree-selector/tree-selector.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { FileSelectorComponent } from "./layout/inputs/file-selector/file-selector.component";
import { PageHeaderComponent } from "./layout/page-header/page-header.component";
import { PageInfoComponent } from "./layout/page-info/page-info.component";
import { EmptyDatasetAlertComponent } from "./layout/tables/empty-dataset-alert/empty-dataset-alert.component";
import { DashboardCardComponent } from "./layout/dashboard-card/dashboard-card.component";
import { DeviceInfoComponent } from "./asset-plus/layout/asset-info/device-info.component";
import { RecentUsersComponent } from "./asset-plus/layout/recent-users/recent-users.component";
import { ChromeOsDeviceLogsComponent } from "./asset-plus/layout/chrome-os-device-logs/chrome-os-device-logs.component";
import { TicketListComponent } from "./ticket-plus/layout/ticket-list/ticket-list.component";
import { AgentListComponent } from "./ticket-plus/layout/agent-list/agent-list.component";
import { DashboardCardHeaderComponent } from "./layout/dashboard-card-header/dashboard-card-header.component";
import { SubActionComponent } from "./workflow-plus/workflows/workflow/actions/sub-action/sub-action.component";
import { DialogWorkflowAdditionalInformationComponent } from "./workflow-plus/layout/dialogs/workflow-additional-information/dialog-workflow-additional-information.component";
import { WarningsComponent } from "./layout/warnings/warnings.component";
import { AttachmentsComponent } from "./layout/attachments/attachments.component";
import { AttachmentComponent } from "./layout/attachments/attachment/attachment.component";
import { AnalyticsComponent } from "./layout/analytics/analytics.component";
import {
  GetProfilePicturePipe,
  SafeHtmlPipe,
  EmptyValueDisplay,
  HumanizePipe,
  NamePropertyPipe,
} from "./services/utilities/custom-pipe.service";
import { StatusComponent } from "./ticket-plus/layout/inputs/status/status.component";
import { TableVirtualScrollModule } from "ng-table-virtual-scroll";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { NameEmailComponent } from "./layout/inputs/name-email/name-email.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { BreadcrumbsComponent } from "./layout/breadcrumbs/breadcrumbs.component";
import { RouterModule } from "@angular/router";
import { IconBadgeListComponent } from "./layout/icon-badge-list/icon-badge-list.component";
import { BuildingsInputComponent } from "src/app/layout/inputs/buildings/buildings.component";
import { RoomsInputComponent } from "src/app/layout/inputs/rooms/rooms.component";
import { NotesComponent } from "./asset-plus/assets/asset/notes/notes.component";
import { EmailEditorTagsComponent } from "./workflow-plus/workflows/workflow/actions/email-action/email-editor-tags/email-editor-tags.component";
import { EmailEditorTagDialogComponent } from "./workflow-plus/workflows/workflow/actions/email-action/email-editor-tag-dialog/email-editor-tag-dialog.component";
import { CustomMenuComponent } from "./layout/custom-menu/custom-menu.component";

@NgModule({
  declarations: [
    AppFiltersComponent,
    TableFooterComponent,
    EmailComponent,
    OrganizationalUnitComponent,
    SingleSelectionComponent,
    TreeSelectorComponent,
    FileSelectorComponent,
    PageHeaderComponent,
    PageInfoComponent,
    EmptyDatasetAlertComponent,
    DashboardCardComponent,
    DeviceInfoComponent,
    RecentUsersComponent,
    ChromeOsDeviceLogsComponent,
    TicketListComponent,
    AgentListComponent,
    DashboardCardHeaderComponent,
    SubActionComponent,
    DialogWorkflowAdditionalInformationComponent,
    WarningsComponent,
    AttachmentsComponent,
    AttachmentComponent,
    GetProfilePicturePipe,
    SafeHtmlPipe,
    EmptyValueDisplay,
    NameEmailComponent,
    StatusComponent,
    BreadcrumbsComponent,
    BuildingsInputComponent,
    RoomsInputComponent,
    IconBadgeListComponent,
    HumanizePipe,
    NamePropertyPipe,
    AnalyticsComponent,
    NotesComponent,
    EmailEditorTagsComponent,
    EmailEditorTagDialogComponent,
    CustomMenuComponent,
  ],
  imports: [
    MatSnackBarModule,
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSidenavModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatProgressBarModule,
    MatChipsModule,
    MatListModule,
    CommonModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule,
    MatMenuModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatBadgeModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatCardModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTreeModule,
    MatSliderModule,
    GoogleChartsModule.forRoot({ version: "49" }),
    TableVirtualScrollModule,
    ScrollingModule,
    NgxEditorModule,
    OverlayModule,
    MatProgressSpinnerModule,
    RouterModule,
  ],
  exports: [
    MatSnackBarModule,
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSidenavModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatProgressBarModule,
    MatChipsModule,
    MatListModule,
    CommonModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule,
    MatMenuModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatBadgeModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatCardModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatTabsModule,
    GoogleChartsModule,
    AppFiltersComponent,
    EmailComponent,
    SingleSelectionComponent,
    OrganizationalUnitComponent,
    TableFooterComponent,
    FileSelectorComponent,
    MatTreeModule,
    PageHeaderComponent,
    PageInfoComponent,
    EmptyDatasetAlertComponent,
    DashboardCardComponent,
    DeviceInfoComponent,
    RecentUsersComponent,
    ChromeOsDeviceLogsComponent,
    MatSliderModule,
    TicketListComponent,
    AgentListComponent,
    SubActionComponent,
    DialogWorkflowAdditionalInformationComponent,
    WarningsComponent,
    AttachmentsComponent,
    AttachmentComponent,
    GetProfilePicturePipe,
    SafeHtmlPipe,
    EmptyValueDisplay,
    TableVirtualScrollModule,
    ScrollingModule,
    NgxEditorModule,
    NameEmailComponent,
    StatusComponent,
    OverlayModule,
    MatProgressSpinnerModule,
    BreadcrumbsComponent,
    BuildingsInputComponent,
    RoomsInputComponent,
    RouterModule,
    IconBadgeListComponent,
    HumanizePipe,
    NamePropertyPipe,
    AnalyticsComponent,
    NotesComponent,
    EmailEditorTagsComponent,
    EmailEditorTagDialogComponent,
    CustomMenuComponent,
  ],
})
export default class SharedModule {}
