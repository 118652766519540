import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { UsersService } from "../../services/admin-plus/users.service";
import { ActivatedRoute } from "@angular/router";
import { NotificationService } from "../../services/utilities/notification.service";
import {
  ConfirmDialogModel,
  ConfirmationDialogComponent,
} from "src/app/layout/dialogs/confirmation-dialog/confirmation-dialog.component";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { ResetPasswordComponent } from "src/app/users/dialogs/reset-password/reset-password.component";
import { User } from "src/app/web/login/user";
import { AdminPlusService } from "src/app/services/admin-plus/admin-plus.service";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
})
export class UserComponent implements OnInit {
  options = [];
  public user: User;
  public selected_user;
  public emailName: string;
  public domain: string;
  public error: string;
  private id: string;
  public firstname: string;
  public lastname: string;
  public primaryemail: string;
  public groupPermissionSet = false;
  public groupPermission = false;
  public memberships = [];

  constructor(
    private usersService: UsersService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private notifyService: NotificationService,
    private dialog: MatDialog,
    private navService: NavService,
    private adminPlusService: AdminPlusService
  ) {
    const user: User = JSON.parse(localStorage.getItem("user"));

    if (user) {
      this.user = user;
    }

    this.options.push({
      icon: "password",
      name: "Reset Password",
      tooltip: "Resets the selected user's password",
      class: "primary",
    });
    this.options.push({
      icon: "delete",
      name: "Delete User",
      tooltip: "Delete the selected user",
      class: "danger",
    });
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get("id");
    this.getUser(this.id);
    this.getGroups();
  }

  optionSelected(option) {
    switch (option.name) {
      case "Reset Password":
        this.resetPassword();
        break;
      case "Delete User":
        this.confirmDialog();
        break;
      default:
        break;
    }
  }

  async getUser(id: string) {
    this.navService.loading.next(true);
    const response = await this.usersService.getUser(id);
    if (response) {
      this.usersService.viewingUser = response;
      this.selected_user = response;
      this.primaryemail = this.selected_user.primaryEmail.substring(0, this.selected_user.primaryEmail.lastIndexOf("@"));
      this.domain = this.selected_user.primaryEmail.replace(/.*@/, "");
      const fullName = this.selected_user.name.givenName + " " + this.selected_user.name.familyName;

      this.adminPlusService.breadCrumbLinks.push({
        link: null,
        text: fullName,
        alt: fullName,
      });
    }
    this.navService.loading.next(false);
  }

  async getGroups(maxResults = 500, pageToken = "") {
    this.navService.loading.next(true);
    const response = await this.usersService.getGroupMemberships(this.id, maxResults, pageToken);
    this.groupPermissionSet = true;
    if (response) {
      this.groupPermission = true;
      if (response.groups) {
        this.memberships.push(...response.groups);
      }

      //Check if there's more data
      if (response.nextPageToken) {
        this.getGroups(maxResults, response.nextPageToken);
      }
    }
    this.navService.loading.next(false);
  }

  async editUser() {
    this.navService.loading.next(true);

    //Build our user object that we're submitting
    const editUser = {
      name: {
        givenName: this.selected_user.name.givenName,
        familyName: this.selected_user.name.familyName,
      },
      primaryEmail: this.primaryemail + "@" + this.domain,
      orgUnitPath: this.selected_user.orgUnitPath,
    };
    const response = await this.usersService.editUser(this.id, editUser);
    if (response) {
      this.notifyService.notify("Successfully updated " + this.primaryemail + "@" + this.domain);
    }
    this.navService.loading.next(false);
  }

  async resetPassword() {
    this.dialog.open(ResetPasswordComponent, {
      disableClose: true,
      width: "500px",
      data: {
        userKey: this.primaryemail + "@" + this.domain,
      },
    });
  }

  confirmDialog(): void {
    const message = `Are you sure you want to delete the user ${this.selected_user.name.givenName} ${this.selected_user.name.familyName}?`;
    const btnOkText = `Delete`;
    const dialogData = new ConfirmDialogModel("Delete User", message, btnOkText);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      width: "500px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.removeUser(dialogResult);
      }
    });
  }

  public async removeUser(confirmed: boolean) {
    if (confirmed) {
      this.navService.loading.next(true);
      const response = await this.usersService.deleteUser(this.id);
      if (response === null) {
        this.router.navigateByUrl("/users");
        this.notifyService.notify("Successfully removed " + this.primaryemail + "@" + this.domain);
        this.navService.loading.next(false);
      }
    }
  }
}
