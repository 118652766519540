import { Component } from "@angular/core";
import { AuthService } from "src/app/services/admin-plus/auth.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  public currentYear = 0;

  constructor(public authService: AuthService) {
    const d = new Date();
    this.currentYear = d.getFullYear();
  }
}
