<h2 mat-dialog-title>Add Users to this Group</h2>
<form *ngIf="users" (ngSubmit)="processForm()" (keyup.enter)="processForm()" autocomplete="off">
  <div mat-dialog-content>
    <mat-form-field class="w-100">
      <mat-chip-grid #userList>
        <mat-chip-row *ngFor="let selected_user of selected_users" [removable]="removable" (removed)="remove(selected_user)">
          {{ selected_user.primaryEmail }}
          <button matChipRemove [attr.aria-label]="'remove ' + selected_user.primaryEmail">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
        <input
          placeholder="New user..."
          #userInput
          [formControl]="userCtrl"
          [matAutocomplete]="auto"
          [matChipInputFor]="userList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)"
          matInput
          (keyup)="search($event)"
        />
      </mat-chip-grid>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let user of filteredOptions | async" [value]="user">
          {{ user.name.fullName }}&nbsp;|&nbsp;<small class="text-muted">{{ user.primaryEmail }}</small>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <app-dialog-actions-container></app-dialog-actions-container>
  </div>
</form>
