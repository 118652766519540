<div mat-dialog-title>
	<span>
		Edit Membership Roles
		<span *ngIf="selected_member && selected_member.preferredMemberKey"> for {{ selected_member.preferredMemberKey.id }}</span>
	</span>
</div>
<div mat-dialog-content>
	<form *ngIf="selected_member" (ngSubmit)="processForm()" autocomplete="off">
		<table mat-table [dataSource]="dataSource">
			<ng-container matColumnDef="selected">
				<th mat-header-cell *matHeaderCellDef>Selected</th>
				<td class="p-3" mat-cell *matCellDef="let element" >
					<mat-checkbox
						color="primary"
						class="example-margin"
						name="_selected"
						[checked]="element['selected']"
						(change)="toggleMembership(element)"
						[disabled]="element.name == 'MEMBER' || isChecked"
					></mat-checkbox>
				</td>
			</ng-container>
			<!-- Name Column -->
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef>Name</th>
				<td mat-cell *matCellDef="let element">{{ element.name }}</td>
			</ng-container>
			<!-- Symbol Column -->
			<ng-container matColumnDef="expires">
				<th mat-header-cell *matHeaderCellDef>
					Expires<br />
					<small class="text-muted">mm/dd/yy hh:mm</small>
				</th>
				<td mat-cell class="py-3" *matCellDef="let element">
					<mat-form-field class=" w-100 mt-3" *ngIf="memberExpiration === true && element.name == 'MEMBER'">
						<mat-label>Set Expiration Date</mat-label>
						<input
							class=""
							[min]="todayDate"
							name="expirationdate"
							matInput
							[formControl]="expirationdate"
							[matDatepicker]="picker"
						/>
						<mat-datepicker-toggle class="" matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-datepicker class="" #picker></mat-datepicker>
					</mat-form-field>
					<div class="w-100 ml-5" *ngIf="memberExpiration === true && element.name == 'MEMBER'">
						<ngb-timepicker
							name="time"
							(ngModelChange)="onTimeChange($event)"
							[(ngModel)]="time"
							[meridian]="meridian"
						></ngb-timepicker>
					</div>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>
		<div mat-dialog-actions class="d-flex justify-content-between px-0">
			<mat-slide-toggle
				*ngIf="enterprise"
				class="mr-2"
				[(ngModel)]="isChecked"
				[disabled]="disabled"
				name="expirationToggle"
				type="button"
				(change)="setExpiration()"
			>
				Set Expiration
			</mat-slide-toggle>
			<mat-slide-toggle
				*ngIf="!enterprise"
				class="mr-2"
				[disabled]="true"
				matTooltip="This feature is available for Google Workspace for Education Plus"
				aria-label="This feature is available for Google Workspace for Education Plus"
			>
				Set Expiration
			</mat-slide-toggle>
			<div class="d-flex px-0">
				<button class='' mat-stroked-button mat-dialog-close>Cancel</button>
				<button class="success" mat-stroked-button>Save</button>
			</div>
		</div>
	</form>
</div>
