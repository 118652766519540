<div class="homepage">
  <div class="homepage__left px-4">
    <div class="homepage__animation" style="background-image: url('../../../../assets/images/hero.png')"></div>
    <div
      class="homepage__left-content d-flex flex-column justify-content-center p-2 pl-md-0 p-md-4 col-11 col-xl-6"
      [@loginAnimation]
    >
      <img class="img-fluid" src="../../../../assets/images/trafera-logo-all-white-vertically-centered.png" alt="" />
      <div class="text-light mt-3">
        <p>
          Trafera's Google Workspace management software simplifies operations and gives admins the tools to manage their
          domain easily. Admin+ is built in Google Cloud and leverages the Google Workspace APIs. Google OAuth permissions,
          required upon sign in, allow Admin+ to fully integrate with user's Google Workspace directory, export reports to
          Google Sheets, and configure service accounts required for automation.
        </p>
        <p>
          Admin+ provides the visibility, control, and automation to manage your Google Workspace domain. Key capabilities
          include:
        </p>
        <ul style="font-size: 14.4px !important; list-style-type: disc">
          <li>Granular Storage Management</li>
          <li>Google Workspace Automation</li>
          <li>Asset Management</li>
          <li>Ticketing - w/ Asset Integration</li>
        </ul>
      </div>
      <div class="homepage__buttons d-md-flex mt-3 mt-md-5">
        <a
          target="_blank"
          href="https://admin-plus.trafera.app"
          mat-stroked-button
          class="white white--hover-green mt-sm-0 my-1 bg-gradient"
          type="button"
          >Google Workspace</a
        >
        <a
          mat-stroked-button
          routerLink="free-trial"
          class="white white--hover-primary mt-sm-0 my-1 ml-2 bg-gradient"
          type="button"
          >Start Free Trial
        </a>
      </div>
    </div>
  </div>

  <div class="homepage__right w-100">
    <div class="homepage__right d-flex flex-column w-100 p-4 mt-md-5" [@loginAnimation]>
      <img class="homepage__right-logo img-fluid" src="../../../../assets/images/admin-plus-logo.svg" alt="" />
      <h5 class="ml-3 mt-2 ls-1">Welcome Back.</h5>

      <div class="text-center">
        <a (click)="authService.signInWithGoogle()">
          <img
            class="mt-3 mt-md-5"
            src="https://ap-assets.trafera.app/images/btn_google_signin_light_normal_web@2x.png"
            width="240px"
          />
        </a>
        <a
          class="d-block mt-1 text-primary"
          href="https://www.trafera.com/trafera-privacy-policy"
          target="_blank"
          alt="Privacy Policy"
          >Privacy Policy</a
        >
      </div>
    </div>
  </div>
</div>
