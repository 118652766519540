import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Sort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";
import { UntypedFormControl } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { DatePipe } from "@angular/common";
import { ExportsheetsService } from "src/app/services/google/sheets.service";
import { OUService, OrgUnit } from "src/app/services/google/ou.service";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { UsersService } from "../services/admin-plus/users.service";
import { UsersNewUserComponent } from "./dialogs/users-new-user/users-new-user.component";
import { UsersDeleteUsersComponent } from "./dialogs/users-delete-users/users-delete-users.component";
import { User } from "./users";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { merge, of as observableOf } from "rxjs";
import { FiltersService, FilterType } from "../services/utilities/filters.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
})
export class UsersComponent implements OnInit {
  options = [];
  user;
  users: User[] = [];
  new_user: User;
  columnsList: string[] = ["lastLoginTime", "orgUnitPath"];
  displayedColumns: string[] = ["select", "profilePicture", "givenName", "familyName", "email", "lastLoginTime"];
  selection = new SelectionModel(true, []);

  dataSource = new MatTableDataSource();
  columns = new UntypedFormControl();
  actions = new UntypedFormControl();
  userCtrl = new UntypedFormControl();

  nextPageToken = "";
  prevPageToken = "";
  pageToken = "";
  orderBy = "givenName";
  sortOrder = "SORT_ORDER_UNDEFINED";
  selectedOU = "";

  maxResults = 10;

  page = 1;
  offset = 0;
  pageTokens = [""];

  /* filtered search stuff */
  private firstNameSearch = "";
  private lastNameSearch = "";
  private emailSearch = "";
  private ouSearch = "";

  // Filter code
  filterFromUser = false;

  nextButton = false;
  nextButtonDis = false;
  loading = false;

  filterTypeValue: FilterType = FilterType.ADMIN_USER;

  data = {
    firstNameSearch: this.firstNameSearch,
    lastNameSearch: this.lastNameSearch,
    emailSearch: this.emailSearch,
    ouSearch: this.ouSearch,
    page: this.page,
    offset: this.offset,
    filtersCount: 0,
  };

  totalCount: number = null;
  isFetchingData: boolean;

  constructor(
    private usersService: UsersService,
    private exportSheetsService: ExportsheetsService,
    public dialog: MatDialog,
    public ouService: OUService,
    private datePipe: DatePipe,
    private navService: NavService,
    private filtersService: FiltersService,
    private router: Router
  ) {
    this.ouService.ou_change.subscribe((change) => {
      let path = this.ouService.parentOU.orgUnitPath;
      if (change) {
        path = change.orgUnitPath;
      }
      this.selectedOU = path.trim();
      this.pageTokens = [""];
      this.resetToFirstPage();
    });

    const user: User = JSON.parse(localStorage.getItem("user"));

    if (user) {
      this.user = user;
    }

    this.options.push({
      icon: "description",
      name: "Export to Sheets",
      tooltip: "Exports the current data set to Google Sheets",
      class: "success",
    });
    this.options.push({
      icon: "add_outline",
      name: "New User",
      tooltip: "Creates a new user within your organization",
      class: "create",
    });
  }

  async ngOnInit() {
    this.isFetchingData = false;

    this.columns.setValue(this.displayedColumns);
    this.actions.valueChanges.subscribe((value) => {
      if (value == "delete") {
        this.openDeleteUsersDialog();
      } else {
        return;
      }
    });
    let OU = [];
    if (!this.user.isAdmin) {
      OU = await this.ouService.getUserOUs();
    }
    this.selectedOU = this.user.isAdmin ? "/" : OU[0]["orgUnitPath"];
    const hasNavFilters = await this.filtersService.checkNavFilters(this.filterTypeValue);

    if (!hasNavFilters) this.loadData();
  }

  optionSelected(option) {
    switch (option.name) {
      case "Export to Sheets":
        this.exportToSheets();
        break;
      case "New User":
        this.openNewUserDialog();
        break;
      default:
        break;
    }
  }

  receiveData($event) {
    const orgUnit: OrgUnit = {
      orgUnitPath: "/",
    };
    this.data = $event;
    this.firstNameSearch = this.data.firstNameSearch;
    this.lastNameSearch = this.data.lastNameSearch;
    this.emailSearch = this.data.emailSearch;
    this.ouSearch = this.data.ouSearch ? this.data.ouSearch : "/";
    orgUnit.orgUnitPath = this.ouSearch;
    this.ouService.selected_ou = orgUnit;
    this.page = this.data.page;
    this.offset = this.data.offset;
    this.resetToFirstPage();
  }

  private async loadData(arrow = "") {
    let arrowToken = "";
    if (arrow === "prev") {
      this.selection.clear(); // QUICK FIX
      this.setTokens(this.page);
      arrowToken = this.prevPageToken;
    } else if (arrow === "next") {
      this.selection.clear(); // QUICK FIX
      arrowToken = this.nextPageToken;
      this.setTokens(this.page);
    }
    this.getUsers(arrowToken);
  }

  getUsers(arrowToken = ""): void {
    this.isFetchingData = true;
    this.updateLoader(true);
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.usersService.getUsers(
            this.maxResults,
            arrowToken,
            this.orderBy,
            this.sortOrder,
            this.emailSearch,
            this.firstNameSearch,
            this.lastNameSearch,
            this.ouSearch,
            this.selectedOU
          );
        }),
        map((response) => {
          this.isFetchingData = true;
          if (Object.keys(response).length === 0) {
            setTimeout(function () {
              location.reload();
            }, 1500);
          }
          if (response.users) {
            this.dataSource = new MatTableDataSource(response.users);
            this.isFetchingData = false;
            if (response.nextPageToken) {
              if (this.page == this.pageTokens.length) {
                this.pageTokens.push(response.nextPageToken);
                this.nextPageToken = response.nextPageToken;
              }
            } else {
              this.nextPageToken = "";
            }
          }
          this.updateLoader(false);
          this.isFetchingData = false;
          return this.users;
        }),
        catchError(() => {
          this.updateLoader(false);
          this.isFetchingData = false;
          return observableOf([]);
        })
      )
      .subscribe((response) => {
        if (response) {
          return this.users;
        }
        this.updateLoader(false);
        this.isFetchingData = false;
      });
  }

  sortData(sort: Sort) {
    this.orderBy = sort.active;
    if (sort.direction === "asc") {
      this.sortOrder = "ASCENDING";
    } else if (sort.direction === "desc") {
      this.sortOrder = "DESCENDING";
    } else if (sort.direction === "") {
      this.sortOrder = "SORT_ORDER_UNDEFINED";
    }

    this.pageTokens = [""];
    this.resetToFirstPage();
  }

  async usersExport() {
    const users = [];
    let pageToken = "";
    let response = {};
    do {
      response = await this.usersService.getUsers(
        500,
        pageToken,
        this.orderBy,
        this.sortOrder,
        this.emailSearch,
        this.firstNameSearch,
        this.lastNameSearch,
        this.ouSearch,
        this.selectedOU
      );
      if (response["nextPageToken"]) {
        pageToken = response["nextPageToken"];
      } else {
        pageToken = "";
      }
      users.push(...response["users"]);
    } while (pageToken !== "");
    return users;
  }

  async exportToSheets() {
    this.navService.loading.next(true);
    const spreadsheetID = await this.exportSheetsService.createSpreadsheet("Users");
    await this.addUserData(spreadsheetID);
    window.open("https://docs.google.com/spreadsheets/d/" + spreadsheetID);
    this.navService.loading.next(false);
  }

  async addUserData(spreadsheetID: string) {
    const users = await this.usersExport();
    const userSheetsData = [];
    for (let i = 0; i < users.length; i++) {
      const lastLoginTime = this.datePipe.transform(users[i]["lastLoginTime"], "MMM d, y, h:mm:ss a");
      const givenName = users[i]["name"]["givenName"];
      const familyName = users[i]["name"]["familyName"];
      const primaryEmail = users[i]["primaryEmail"];
      const orgUnitPath = users[i]["orgUnitPath"];
      const user = {
        givenName: givenName,
        familyName: familyName,
        primaryEmail: primaryEmail,
      };
      if (this.displayedColumns.includes("lastLoginTime")) {
        user["lastLoginTime"] = lastLoginTime;
      }
      if (this.displayedColumns.includes("orgUnitPath")) {
        user["orgUnitPath"] = orgUnitPath;
      }
      userSheetsData.push(user);
    }
    const exportSheetColumns = this.displayedColumns.map((col) => {
      return col == "email" ? "primaryEmail" : col;
    });
    const newUserObject = await this.exportSheetsService.createSpreadsheetArray(exportSheetColumns, userSheetsData);
    if (newUserObject) {
      await this.exportSheetsService.addExportData(
        newUserObject,
        spreadsheetID,
        "Sheet1!A1:G" + newUserObject["values"].length
      );
    }
  }

  openNewUserDialog() {
    const dialogRef = this.dialog.open(UsersNewUserComponent, {
      disableClose: true,
      width: "500px",
      data: {
        user: this.new_user,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const data = this.dataSource.data;
        data.unshift(result);
        this.dataSource.data = data;
      }
    });
  }

  openDeleteUsersDialog() {
    const dialogRef = this.dialog.open(UsersDeleteUsersComponent, {
      disableClose: true,
      width: "600px",
      data: {
        users: this.selection.selected,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const data = this.dataSource.data;
        for (let i = data.length - 1; i >= 0; i--) {
          for (let j = 0; j < result.length; j++) {
            if (data[i]) {
              if (data[i]["id"] == result[j]["id"]) {
                data.splice(i, 1);
              }
            }
          }
        }
        this.dataSource.data = data;
      }
      this.selection.clear();
      this.actions.setValue("");
    });
  }

  getPageData() {
    return this.dataSource._pageData(this.dataSource._orderData(this.dataSource.filteredData));
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  masterToggle() {
    this.isEntirePageSelected()
      ? this.selection.deselect(...this.getPageData())
      : this.selection.select(...this.getPageData());
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.getPageData() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1}`;
  }

  resetToFirstPage() {
    this.pageTokens = [""];
    this.page = 1;
    this.setTokens(this.page);
    this.getUsers();
  }

  setTokens(page: number) {
    this.nextPageToken = this.pageTokens[page];
    this.prevPageToken = this.pageTokens[page - 1];
  }

  setPager(data) {
    this.page = data.page;
    this.offset = data.offset;
    this.maxResults = data.maxResults ? data.maxResults : this.maxResults;
    this.loadData(data.arrow);
  }

  tableColumnChange(value) {
    let add = true;
    for (let i = 0; i < this.displayedColumns.length; i++) {
      if (this.displayedColumns[i] == value) {
        if (i !== -1) {
          this.displayedColumns.splice(i, 1);
          add = false;
        }
      }
    }

    if (add) {
      this.displayedColumns.push(value);
    }
  }

  routeToUser(userId: number) {
    this.router.navigate(["/users/" + userId]);
  }

  updateLoader(value: boolean) {
    for (const option of this.options) {
      if (option["name"] == "Export to Sheets") {
        option["disabled"] = value;
      }
    }
    this.loading = value;
    this.navService.loading.next(value);
  }
}
