<h2 mat-dialog-title>Unable to build Storage report</h2>
<div mat-dialog-content>
  <div class="row">
    <div class="col-12 mt-2">
      <p>
        <strong> Your service account appears to be missing the following scopes. </strong>
      </p>
      <ul>
        <li *ngFor="let scope of missingScopes">
          <small>{{ scope.scope }}</small>
        </li>
      </ul>
      <p class="mt-4">
        The
        <a href="/settings/product/service-account">Service Account Management</a> area can provide more details. Each
        service account includes specific instructions for setting up workspace as well as a tester to confirm changes.
      </p>
    </div>
  </div>
  <div mat-dialog-actions class="px-0">
    <button class="" mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-stroked-button class="success ml-2" (click)="tryAgain()">Try again</button>
  </div>
</div>
