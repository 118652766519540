<h2 mat-dialog-title>
    Add Service Account Automatically <span *ngIf="existing && !new">- Existing Project</span><span *ngIf="new && !existing">- New Project</span>
</h2>
<form (ngSubmit)="processForm()" autocomplete="off">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-6 mt-2 text-center">
                <button type="button" mat-stroked-button class="" (click)="newProject()">New Project</button>
            </div>
            <div class="col-6 mt-2 text-center">
                <button type="button" mat-stroked-button (click)="existingProject()">Existing Project</button>
            </div>
        </div>
        <div class="row mt-3">
            <div *ngIf="existing" class="col-12 mt-2">
                <mat-form-field class="w-100">
                    <mat-label>Select Project</mat-label>
                    <mat-select [formControl]="selectProjectControl" (ngModelChange)="chooseProject($event)">
                      <mat-option *ngFor="let project of projects" [value]="project">{{ project.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12 mt-2">
                <mat-form-field class="w-100">
                    <mat-label> Delegate Email </mat-label>
                    <input
                        matInput
                        [(ngModel)]="delegateEmail"
                        type="text"
                        maxLength="128"
                        pattern="^\S+@\S+\.\S+"
                        name="delegateEmail"
                        id="delegateEmail"
                        placeholder="Example: sales@trafera.com *"
                        autocomplete="off"
                        required
                    />
                </mat-form-field>
            </div>
        </div>
        <app-dialog-actions-container></app-dialog-actions-container>
    </div>
</form>
  