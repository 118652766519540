import { Component, Input } from "@angular/core";
import { LeftNavLink } from "src/app/models/admin-plus/base";
import { LeftnavComponent } from "../leftnav.component";

@Component({
  selector: "app-link",
  templateUrl: "./link.component.html",
  styleUrls: ["./link.component.scss"],
})
export class LinkComponent extends LeftnavComponent {
  @Input() link: LeftNavLink;

  addQueryParams(queryParams: boolean, routerLink: string, filterName: string) {
    if (queryParams) {
      const tableId = `_${this.filterTypeValue.toString()}`;
      const paramKey = encodeURI(filterName) + tableId;
      this.router.navigate([routerLink], {
        relativeTo: this.activatedRoute,
        queryParams: { [paramKey]: this.current_user.firstName + " " + this.current_user.lastName },
      });
    }
  }

  checkAgentRequirement(child): boolean {
    switch (child.name) {
      case "My Tickets": {
        if (this.current_user.isAgent) {
          return true;
        } else if (!this.current_user.isAgent) {
          return false;
        }
        break;
      }
      case "Agents": {
        if (this.current_user.isAdmin) {
          return true;
        } else {
          return false;
        }
      }
      case "Teams": {
        if (this.current_user.isAdmin) {
          return true;
        } else {
          return false;
        }
      }
      default: {
        return true;
      }
    }
  }
}
