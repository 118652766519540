<div class="row">
  <div class="col-12 col-lg-6 mt-2">
    <mat-card class="">
      <form (submit)="saveStorageReport()">
        <div class="row">
          <div class="col-12 col-md-6 mt-2">
            <h2>Report Options</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 mt-2">
            <mat-form-field class="w-100">
              <mat-label> Title </mat-label>
              <input
                matInput
                [(ngModel)]="storageReport.name"
                placeholder="Name/Title"
                type="text"
                name="name"
                id="name"
                required
              />
              <mat-hint> Appears at the top of report pages </mat-hint>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6 mt-2">
            <mat-form-field class="w-100">
              <mat-label> Service Account </mat-label>
              <!-- <mat-select [(ngModel)]="storageReport.serviceAccount.id" name="serviceAccountId">
                  <mat-option *ngFor="let serviceAccount of serviceAccounts" [value]="serviceAccount.id">
                    {{ serviceAccount.delegateEmail }}
                  </mat-option>
                </mat-select> -->
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <mat-checkbox color="primary" [checked]="storageReport.allowDelete" [(ngModel)]="storageReport.allowDelete">
              Allow permanent deletion of files
            </mat-checkbox>
          </div>
          <div class="col-12 col-md-6 mt-2">
            <mat-form-field class="w-100">
              <mat-label> User Usage Limit </mat-label>
              <input
                matInput
                [(ngModel)]="storageReport.usageLimit"
                placeholder="Limit in GB"
                type="number"
                oninput="validity.valid||(value='');"
                name="usageLimit"
                id="usageLimit"
                required
              />
              <mat-hint> Limits users in your report to users with storage usage greater than the above limit GB </mat-hint>
            </mat-form-field>
          </div>
          <div class="col-12 text-right my-3">
            <button mat-stroked-button class="btn success py-1">Save Report</button>
          </div>
        </div>
      </form>
    </mat-card>
  </div>
</div>
