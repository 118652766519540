<div class="row">
  <div class="col-12">
    <form class="pt-2 pb-4 w-100">
      <div class="w-100 d-flex align-items-center mt-n1 mb-3">
        <h3 class="mb-0 mr-4">Report Options</h3>
        <a
          *ngIf="storageReport?.id"
          mat-stroked-button
          (click)="confirmBuildReport()"
          [disabled]="loading || storageReport?.status?.id == 2"
          [matTooltip]="loading || storageReport?.status?.id == 2 ? 'Report is building' : 'Build storage report data'"
        >
          <mat-icon> restart_alt </mat-icon>
          <span class="ml-1"> Build Report... </span>
        </a>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6">
          <mat-form-field class="w-100">
            <mat-label> Title </mat-label>
            <input
              matInput
              [(ngModel)]="storageReport.name"
              placeholder="Name/Title"
              type="text"
              name="name"
              id="name"
              required
            />
            <mat-hint> Appears at the top of report pages </mat-hint>
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-6">
          <div class="row">
            <div class="col-12 col-xl-8">
              <mat-form-field class="w-100">
                <mat-label> User Usage Limit </mat-label>
                <input
                  matInput
                  [(ngModel)]="storageReport.usageLimit"
                  placeholder="Limit in GB"
                  type="number"
                  name="usageLimit"
                  id="usageLimit"
                  min="0"
                  required
                  oninput="validity.valid||(value='');"
                />
                <mat-hint>
                  Limits users in your report to users with storage usage greater than the above limit GB
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex mt-4 align-items-center flex-wrap">
        <mat-checkbox
          color="primary"
          class="mat-checkbox mr-4"
          [(ngModel)]="storageReport.allowDelete"
          name="allowDelete"
          id="allowDelete"
          (change)="allowDeletionConfirmation($event)"
        >
          Allow permanent deletion of files
        </mat-checkbox>
      </div>
      <div class="d-flex mt-2 align-items-center flex-wrap">
        <mat-checkbox
          color="primary"
          [(ngModel)]="customerService.customer.gcsEnabled"
          name="enableCloudStorage"
          id="enableCloudStorage"
          (change)="enableCloudStorage($event)"
        >
          Enable Google Cloud Storage
        </mat-checkbox>
      </div>
      <div class="d-flex mt-2 align-items-center flex-wrap">
        <div class="ml-lg-4">
          <p class="mt-1" *ngIf="customerService.customer.gcsEnabled">
            <b>Bucket Name:</b> {{ customerService.customer.gcsBucket }}
          </p>
        </div>
      </div>

      <div class="d-flex flex-wrap align-items-center mt-4">
        <a mat-stroked-button class="mr-3" [routerLink]="'/reports/storage/bucket-instruction'">
          <mat-icon class="mr-2 mb-0">settings_system_daydream</mat-icon>
          Creating your bucket</a
        >

        <button (click)="saveStorageReport()" mat-stroked-button class="success mt-2 mt-md-0">Save Report</button>
      </div>
    </form>

    <hr class="mb-0 mt-2" />

    <div *ngIf="storageReport.enableScheduled">
      <app-storage-report-scheduled [storageReport]="storageReport"></app-storage-report-scheduled>
    </div>
  </div>
</div>
