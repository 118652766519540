<h2 mat-dialog-title>Workflow Information</h2>
<div class="mt-3" mat-dialog-content>
	<div *ngIf="loading; else details">
		<span class="material-icons faa-spin"> filter_tilt_shift </span>
	</div>
	<ng-template #details>
		<h3 *ngIf="workflow">
			{{ workflow.actions && workflow.actions.length > 0 ? "Workflow Actions" : "Workflow has no actions" }}
		</h3>
		<ul *ngIf="workflow">
			<li *ngFor="let action of workflow.actions">
				{{ action?.action?.name }}
			</li>
		</ul>
	</ng-template>
	<div mat-dialog-actions class='px-0'>
		<button mat-stroked-button mat-dialog-close class="" >Cancel</button>
		<button mat-stroked-button class="success ml-2" (click)="copy()" matTooltip="Copy this workflow">
			<mat-icon> content_copy </mat-icon> Copy this workflow
		</button>
	</div>
</div>
