import { Injectable } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { LeftNavLink } from "src/app/models/admin-plus/base";
import { Module } from "src/enums/module";

@Injectable({
  providedIn: "root",
})
export class LeftNavService {
  sidenav: MatSidenav;
  hideSignInWithGoogle = false;
  opened: boolean;
  modules: string[] = [];
  serviceAccountRequiredMessage = "Service Account required for access";

  leftNavLinks: LeftNavLink[] = [
    {
      name: "Dashboard",
      routerLink: "/dashboard",
      icon: "dashboard",
      module: {
        name: "dashboard",
      },
      location: "top",
    },
    {
      name: "Users",
      routerLink: "/users",
      icon: "person",
      module: {
        name: "users",
      },
      location: "top",
      color: "text-info",
      description: "Add or manage users",
    },
    {
      name: "Groups",
      routerLink: "/groups",
      icon: "group",
      module: {
        name: "groups",
      },
      location: "top",
      color: "text-success",
      description: "Manage groups and mailing lists",
    },
    {
      name: "Buildings",
      routerLink: "/buildings",
      icon: "corporate_fare",
      module: {
        name: "buildings",
      },
      location: "top",
      color: "text-secondary",
      description: "Manage organization buildings",
    },
    {
      name: "Storage",
      module: {
        id: Module.REPORTS,
        name: "reports",
      },
      routerLink: "/reports/storage",
      icon: "perm_media",
      location: "top",
      color: "text-primary",
      description: "Manage your organization's storage usage",
      showChildren: false,
      disabled: true,
      loading: true,
      children: [
        {
          name: "Organizational Units",
          routerLink: "/reports/storage/organizational-units",
          location: "top",
          module: {
            name: "reports",
          },
        },
        {
          name: "Users",
          routerLink: "/reports/storage/users",
          location: "top",
          module: {
            name: "reports",
          },
        },
        {
          name: "Files",
          routerLink: "/reports/storage/files",
          location: "top",
          module: {
            name: "reports",
          },
        },
        {
          name: "Shared Drives",
          routerLink: "/reports/storage/drives",
          location: "top",
          module: {
            name: "reports",
          },
        },
        {
          name: "Duplicate Files",
          routerLink: "/reports/storage/duplicate-files",
          location: "top",
          module: {
            name: "reports",
          },
        },
        {
          name: "Emails",
          routerLink: "/reports/storage/emails",
          location: "top",
          module: {
            name: "reports",
          },
        },
      ],
    },
    {
      name: "Workflows",
      module: {
        id: Module.AUTOMATION,
        name: "automation",
      },
      routerLink: "/automation/dashboard",
      icon: "account_tree",
      location: "top",
      color: "text-primary",
      description: "Automation Workflows",
      showChildren: false,
      disabled: true,
      loading: true,
      children: [
        {
          name: "Dashboard",
          routerLink: "/automation/dashboard",
          location: "top",
          module: {
            name: "automation",
          },
        },
        {
          name: "All Workflows",
          routerLink: "/automation/workflows",
          location: "top",
          module: {
            name: "automation",
          },
        },
        {
          name: "Templates",
          routerLink: "/automation/templates",
          location: "top",
          module: {
            name: "automation",
          },
        },
        {
          name: "Logs",
          routerLink: "/automation/logs",
          location: "top",
          module: {
            name: "automation",
          },
        },
      ],
    },
    {
      name: "Assets",
      routerLink: "/assets",
      icon: "laptop_chromebook",
      module: {
        id: Module.ASSETS,
        name: "assets",
      },
      location: "top",
      color: "text-primary",
      description: "Manage your organization's assets",
      showChildren: false,
      disabled: true,
      loading: true,
      children: [
        {
          name: "All Assets",
          routerLink: "/assets",
          location: "top",
          module: {
            name: "assets",
          },
        },
      ],
    },
    {
      name: "Tickets",
      routerLink: "/ticketing/summary",
      icon: "local_activity",
      module: {
        id: Module.TICKETS,
        name: "ticketing",
      },
      location: "top",
      color: "text-primary",
      description: "Manage your helpdesk tickets",
      showChildren: false,
      disabled: true,
      loading: true,
      children: [
        {
          name: "Dashboard",
          routerLink: "/ticketing/summary",
          location: "top",
          module: {
            name: "ticketing",
          },
        },
        {
          name: "My Tickets",
          routerLink: "/ticketing/my-tickets",
          location: "top",
          module: {
            name: "ticketing",
          },
        },
        {
          name: "All Tickets",
          routerLink: "/ticketing/tickets",
          location: "top",
          module: {
            name: "ticketing",
          },
          routerLinkActiveOption: true,
        },
      ],
    },
    {
      name: "Settings",
      routerLink: "/settings",
      icon: "settings",
      module: {
        name: "settings",
      },
      location: "bottom",
      color: "text-primary",
      description: "Manage your application settings",
    },
    {
      name: "Audit Logs",
      routerLink: "/audit-logs",
      icon: "folder",
      module: {
        name: "audit-logs",
      },
      location: "bottom",
      description: "View audit logs for Storage, Workflows, Tickets, and Assets",
      adminOnly: true,
    },
    {
      name: "Product Updates",
      routerLink: "/product-updates",
      icon: "update",
      module: {
        name: "product-updates",
      },
      location: "bottom",
      description: "View Product Updates and Release Notes",
      adminOnly: false,
    },
  ];

  setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }
}
