<app-dashboard-card-header [title]="title" [payload]="payload"></app-dashboard-card-header>
<mat-card [ngClass]="{'card--fixed-height': !loading}">
  <div class="h-100">
    <div
      *ngFor="let ticket of tickets"
      (click)="routeToTicket(ticket.id)"
      class="card__body d-flex border-bottom position-relative"
    >
      <mat-icon matTooltip="Source: {{ ticket.channel.name }}" class="mr-3 align-baseline">
        <ng-container *ngIf="ticket.channel.icon != 'admin_plus'">
          <mat-icon> {{ ticket.channel.icon }} </mat-icon>
        </ng-container>
        <ng-container *ngIf="ticket.channel.icon == 'admin_plus'">
          <div class="admin_plus-icon__wrapper">
            <img [src]="ImagePath" class="admin_plus-icon img-fluid align-baseline" alt="admin+ logo" />
          </div>
        </ng-container>
      </mat-icon>
      <div class="w-100 d-flex flex-column">
        <a class="h5 mb-0 text-break stretched-link"> #{{ ticket.id }} {{ ticket.subject }} </a>
        <!-- Would be cool if this date was "WHEN IT WAS MOVED TO THE STATUS" -->
        <small class="text-muted" matTooltip="Created: {{ ticket.createdBy.date | date : 'short' }}"
          >{{ ticket.status.name || "new"
          }}{{ !ticket.status.name || ticket.status.name == "new" ? " | " + (ticket.createdBy.date | date : "short") : "" }}
        </small>
      </div>
      <span *ngIf="ticket.assignedTo?.agent?.id" class="badge my-auto ml-3">
        <img
          referrerpolicy="no-referrer"
          matTooltip="Assigned to {{ ticket.assignedTo.agent.name }}"
          class="rounded-circle"
          height="32"
          width="32"
          matChipAvatar
          src="{{
            !(ticket.assignedTo.agent.email | getProfilePicturePipe | async)
              ? 'https://lh3.googleusercontent.com/a/default-user=s64'
              : (ticket.assignedTo.agent.email | getProfilePicturePipe | async)
          }}"
          alt="{{ ticket.assignedTo.agent?.name ? ticket.assignedTo.agent.name : 'Unassigned' }}"
        />
      </span>
      <mat-icon
        class="my-auto"
        matTooltip="Assigned to {{ ticket.assignedTo.team.name }}"
        *ngIf="ticket.assignedTo?.team?.id"
        >groups</mat-icon
      >
    </div>
    <div *ngIf="!loading && !tickets?.length" class="p-4">
      <h5>No {{ title | lowercase }} found. <a class="link" (click)="routeToTicket('new')">Create a ticket</a></h5>
    </div>
  </div>


  <mat-card-footer *ngIf="tickets?.length && !hideSeeAll" class="card-footer h-100 mt-auto">
      <button mat-stroked-button class="card__footer-button w-100 py-4 rounded-0" (click)="seeAll()">See All</button>
  </mat-card-footer>
</mat-card>


