import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { ReportsService, StorageReport, User, File, Historical } from "src/app/services/google/reports.service";
import {
  ConfirmDialogModel,
  ConfirmationDialogComponent,
} from "src/app/layout/dialogs/confirmation-dialog/confirmation-dialog.component";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { User as localUser } from "src/app/web/login/user";
import { DialogStorageReportMissingScopesComponent } from "./dialogs/dialog-storage-report-missing-scopes/dialog-storage-report-missing-scopes.component";
import { StorageReportMoreInfoComponent } from "./dialogs/storage-report-more-info/storage-report-more-info.component";

@Component({
  selector: "app-storage",
  templateUrl: "./storage.component.html",
  styleUrls: ["./storage.component.scss"],
})
export class StorageComponent implements OnInit {
  options = [];
  user: localUser = {};
  storageReport: StorageReport = {
    id: "0",
    name: "",
    status: {
      name: "",
      id: 0,
    },
    totalUsers: 0,
    totalStorage: 0,
    totalDrive: 0,
    totalGmail: 0,
    totalPhotos: 0,
  };

  users: User[] = [];
  files: File[] = [];

  charts: any[] = [];
  storageByType: any[] = [];
  storageByOU: any[] = null;
  historical: Historical[] = [];
  historicalLoading = true;
  loading = true;
  unit = "MB";
  divide = 1;
  totalStorage = 0;
  showNavigationArrows = true;

  constructor(
    private reportsService: ReportsService,
    private router: Router,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private navService: NavService,
    public loginService: LoginService
  ) {
    this.configureOptions();
  }

  ngOnInit(): void {
    this.loadData();
  }

  private async loadData() {
    this.updateLoadingStatus(true);
    const storageReport = await this.reportsService.getStorageReport();
    if (storageReport) {
      this.totalStorage = storageReport.totalDrive + storageReport.totalGmail + storageReport.totalPhotos;
      this.storageReport = storageReport;
    }
    if (this.storageReport.status.id === 2) {
      const interval = setInterval(async () => {
        if (this.storageReport.status.id === 4) {
          clearInterval(interval);
        } else {
          await this.loadReportData(true);
        }
      }, 30000);
    }
    if (this.storageReport.status.id == 4) {
      await this.loadReportData();
    }
    this.updateLoadingStatus(false);
  }

  updateLoadingStatus(isLoading: boolean) {
    this.loading = isLoading;
    this.navService.loading.next(isLoading);
    this.configureOptions();
  }

  configureOptions() {
    this.options = [
      {
        icon: "picture_as_pdf",
        class: "export",
        name: "Export to PDF",
        tooltip: "Exports the current page to a PDF document for sharing",
        disabled: this.loading || this.storageReport.status.id != 4,
      },
      {
        icon: "restart_alt",
        class: "success",
        name: "Re-run Report",
        tooltip: "Rebuilds all cached report data - this could take a while...",
        disabled: this.loading || this.storageReport.status.id === 2,
      },
    ];
  }

  optionSelected(option) {
    switch (option.name) {
      case "Export to PDF":
        this.downloadAsPDF();
        break;
      case "Re-run Report":
        this.confirmBuildReport();
        break;
      default:
        break;
    }
  }

  async loadReportData(interval = false) {
    if (interval) {
      this.storageReport = await this.reportsService.getStorageReport();
    }
    if (this.storageReport.status.id == 4) {
      this.calculateUnit();

      //Only get the rest of the data if the report is complete(4)
      if (this.storageReport.id && this.storageReport.status.id == 4) {
        if (this.storageReport) {
          this.storageByType = [
            ["User Drives", this.storageReport.totalDrive! / 1024],
            ["Gmail", this.storageReport.totalGmail! / 1024],
            ["Photos", this.storageReport.totalPhotos! / 1024],
          ];
          if (this.storageReport.totalSharedDrive) {
            this.storageByType.push(["Shared Drives", this.storageReport.totalSharedDrive! / 1024]);
          }
          this.getHistorical();
          const ouData: any[] = [];
          const ouStorageData = await this.reportsService.getOUStorage();
          if (ouStorageData) {
            for (let i = 0; i < ouStorageData.length; i++) {
              ouData.push([
                ouStorageData[i].name.length > 24 ? "..." + ouStorageData[i].name.slice(-24) : ouStorageData[i].name,
                ouStorageData[i].totalStorage / 1024,
                `&nbsp;${ouStorageData[i].name}<br />&nbsp;<nobr>Total Storage: ${
                  ouStorageData[i].totalStorage / 1024
                }</nobr>`,
              ]);
            }
          }
          this.storageByOU = ouData;
        }
      }
    }
  }

  async getHistorical() {
    //Load historical data
    if (this.reportsService.reportHistoricalData) {
      this.historical = this.reportsService.reportHistoricalData;
    } else {
      const reportData: any[] = [];
      const data = await this.reportsService.getHistorical(this.storageReport.id);
      if (data) {
        for (let i = 0; i < data.length; i++) {
          reportData.push([
            data[i].reportDate,
            data[i].totalStorage / 1024,
            data[i].totalDrive / 1024,
            data[i].totalGmail / 1024,
            data[i].totalPhotos / 1024,
          ]);
        }
      }
      this.historical = reportData;
      this.reportsService.reportHistoricalData = reportData;
    }
    this.historicalLoading = false;
  }

  viewUserFiles() {
    this.router.navigate(["files"], { relativeTo: this.activatedRoute });
  }

  viewUsers() {
    this.router.navigate(["users"], { relativeTo: this.activatedRoute });
  }

  viewDrives() {
    this.router.navigate(["drives"], { relativeTo: this.activatedRoute });
  }

  viewEmails() {
    this.router.navigate(["emails"], { relativeTo: this.activatedRoute });
  }

  viewTrashedFiles() {
    this.router.navigate(["files-trashed"], {
      relativeTo: this.activatedRoute,
    });
  }

  viewConfig() {
    this.router.navigateByUrl("/settings/product/storage-report");
  }

  calculateUnit() {
    if (Math.floor(this.storageReport.totalStorage / 1024) > 0) {
      this.unit = "GB";
      this.divide = 1024;
      if (Math.floor(this.storageReport.totalStorage / 1048576) > 0) {
        this.unit = "TB";
        this.divide = 1048576;
        if (Math.floor(this.storageReport.totalStorage / 1073741824) > 0) {
          this.unit = "PB";
          this.divide = 1073741824;
        }
      }
    }
  }

  async confirmBuildReport() {
    const message =
      "Are you sure you want to build this this report? It may take some time to complete this task and the system will email you when it's finished.";
    const btnOkText = `Confirm`;
    const btnCancelText = `Cancel`;
    const dialogData = new ConfirmDialogModel("Build Report", message, btnOkText, btnCancelText);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.buildReport();
      }
    });
  }

  async buildReport() {
    this.updateLoadingStatus(true);
    this.storageReport.totalUsers = 0;
    const response = await this.reportsService.buildReport(this.storageReport.id);
    this.updateLoadingStatus(false);

    if (response) {
      if (response.missingScopes) {
        const dialogRef = this.dialog.open(DialogStorageReportMissingScopesComponent, {
          width: "500px",
          data: response.missingScopes,
        });

        dialogRef.afterClosed().subscribe((dialogResult) => {
          if (dialogResult) {
            this.buildReport();
          }
        });
      } else {
        this.storageReport.status.id = 2;
        if (this.storageReport.status.id === 2) {
          this.refresh();
        }
      }
    }
  }

  async refresh() {
    this.loadData();
  }

  public async downloadAsPDF() {
    this.updateLoadingStatus(true);
    const canvas = await this.canvas();
    if (canvas) {
      this.updateLoadingStatus(false);
    }
  }

  public async canvas() {
    return new Promise((resolve) => {
      html2canvas(document.getElementById("entireScreen")!).then(function (canvas) {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 210;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        const doc = new jsPDF("p", "mm");
        let position = 0;
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save("storage-report.pdf");
        resolve(true);
      });
    });
  }

  openMoreInfoDialog() {
    this.dialog.open(StorageReportMoreInfoComponent);
  }
}
