import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { Priority } from "src/app/models/ticket-plus/priority";
import { Status } from "src/app/models/ticket-plus/status";
import { Channel } from "src/app/models/ticket-plus/channel";
import { UtilityService } from "src/app/services/utilities/utility.service";

@Component({
  selector: "app-custom-ticket-settings-dialog",
  templateUrl: "./custom-ticket-settings-dialog.component.html",
  styleUrls: ["./custom-ticket-settings-dialog.component.css"],
})
export class CustomTicketSettingsDialogComponent implements OnInit {
  priority = new Priority({});
  status = new Status({});
  channel = new Channel({});
  action = "Add";
  loading = false;
  originalPriority = "";
  originalStatus: Status;

  constructor(
    private dialogRef: MatDialogRef<CustomTicketSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private notifyService: NotificationService,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.action = this.utilityService.capitalizeFirstLetter(this.data.action);
    if (this.data.priority) {
      this.priority = Object.assign({}, this.data.priority);
      this.originalPriority = this.priority.name;
    }
    if (this.data.status) {
      this.status = Object.assign({}, this.data.status);
      this.originalStatus = JSON.parse(JSON.stringify(this.status));
    }
    if (this.data.channel) {
      this.channel = Object.assign({}, this.data.channel);
    }
  }

  statusChange(event) {
    this.status.parent = {
      id: event.value.id,
      name: event.value.name,
    };
  }

  async processForm() {
    this.loading = true;
    switch (this.data.type) {
      case "Priority": {
        if (this.priority.name?.trim()) {
          if (this.originalPriority == this.priority.name && this.action !== "Delete") {
            this.notifyService.notify("Priority has not been changed");
            this.loading = false;
            return;
          }
          let duplicate = false;
          if (this.action !== "Delete") {
            for (const priority of this.data.priorities) {
              if (this.priority.name === priority.name) {
                duplicate = true;
              }
            }
          }
          if (!duplicate || this.action == "Delete") {
            this.dialogRef.close(this.priority);
          } else {
            this.notifyService.notify("The priority already exists");
          }
        } else {
          this.notifyService.notify("Priority name is required");
        }
        break;
      }
      case "Status": {
        if (this.status.name?.trim()) {
          if (
            this.originalStatus?.name == this.status?.name &&
            this.originalStatus?.parent.id == this.status?.parent.id &&
            this.action !== "Delete"
          ) {
            this.notifyService.notify("Status has not been changed");
            this.loading = false;
            return;
          }
          let duplicate = false;
          if (this.action !== "Delete") {
            for (const status of this.data.statuses) {
              if (this.status.name === status.name && this.status.id != status.id) {
                duplicate = true;
              }
            }
          }
          if (!duplicate || this.action == "Delete") {
            this.dialogRef.close(this.status);
          } else {
            this.notifyService.notify("The status already exists");
          }
        } else {
          this.notifyService.notify("Status name is required");
        }
        break;
      }
      case "Channel": {
        if (this.channel.name?.trim()) {
          this.dialogRef.close(this.channel);
        } else {
          this.notifyService.notify("Channel name is required");
        }
        break;
      }
    }
    this.loading = false;
  }
}
