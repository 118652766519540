import { Injectable } from "@angular/core";
import { Category } from "src/app/models/ticket-plus/category";
import { Payload } from "src/app/services/utilities/utility.service";
import { TicketPlusService } from "./ticket-plus.service";

@Injectable({
  providedIn: "root",
})
export class CategoryService extends TicketPlusService {
  private url = `/ticket-plus/categories`;

  async getCategories(params = {}): Promise<Payload> {
    return this.cacheService.getPayloadAndCache(this.url, params, this.utilityService);
  }

  async addCategory(category: Category): Promise<Category> {
    this.cacheService.clearCache(this.url);
    return new Promise<Category>((resolve) => {
      this.utilityService.sendRequest("POST", this.url, {}, category).subscribe((payload) => {
        if (payload?.data?.length) {
          resolve(payload.data[0]);
        } else {
          resolve(null);
        }
      });
    });
  }

  async deleteCategory(category: Category): Promise<Payload> {
    this.cacheService.clearCache(this.url);
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("DELETE", `${this.url}/${category.id}`).subscribe((payload) => {
        resolve(payload);
      });
    });
  }

  async getGroupedCategories() {
    const categoryData = await this.getCategories();

    if (!categoryData?.data?.length) return [];

    const parentCategories = categoryData.data.filter((category) => {
      return category.parentId == null;
    });

    for (const parentCategory of parentCategories) {
      parentCategory.children = [];
      const childCategories = categoryData.data.filter((category) => {
        return category.parentId == parentCategory.id;
      });

      parentCategory.children = childCategories;
    }
    return parentCategories;
  }
}
