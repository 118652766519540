import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class FiltersService {
  filters: Filter[] = [
    {
      name: "Created After",
      additionalText: "",
      search: "",
      types: [FilterType.JOB, FilterType.WORKFLOW, FilterType.LOGS, FilterType.MESSAGE],
      inputType: "date",
      exclude: false,
    },
    {
      name: "Created Before",
      additionalText: "",
      search: "",
      types: [FilterType.JOB, FilterType.WORKFLOW, FilterType.LOGS, FilterType.MESSAGE],
      inputType: "date",
      exclude: false,
    },
    {
      name: "Last Run Before",
      additionalText: "",
      search: "",
      types: [FilterType.WORKFLOW],
      inputType: "date",
      exclude: false,
    },
    {
      name: "Last Run After",
      additionalText: "",
      search: "",
      types: [FilterType.WORKFLOW],
      inputType: "date",
      exclude: false,
    },
    {
      name: "Email",
      additionalText: "Starts with",
      search: "",
      step: "",
      types: [FilterType.USER, FilterType.FILE, FilterType.ADMIN_USER, FilterType.JOB, FilterType.SHARED_DRIVE_USER],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Folder Name",
      additionalText: "Starts with",
      search: "",
      step: "",
      types: [FilterType.WORKFLOW],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Workflows in Folder",
      additionalText: "",
      search: "",
      step: "",
      types: [FilterType.WORKFLOW],
      inputType: "text",
      exclude: true,
    },
    {
      name: "First Name",
      additionalText: "Starts with",
      search: "",
      step: "",
      types: [FilterType.USER, FilterType.FILE, FilterType.ADMIN_USER, FilterType.JOB, FilterType.SHARED_DRIVE_USER],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Last Name",
      additionalText: "Starts with",
      search: "",
      step: "",
      types: [FilterType.USER, FilterType.FILE, FilterType.ADMIN_USER, FilterType.JOB, FilterType.SHARED_DRIVE_USER],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Last Viewed by Owner Before",
      additionalText: "",
      search: "",
      types: [FilterType.FILE],
      inputType: "date",
      exclude: false,
    },
    {
      name: "Last Viewed by Owner After",
      additionalText: "",
      search: "",
      types: [FilterType.FILE],
      inputType: "date",
      exclude: false,
    },
    {
      name: "Recent Users",
      additionalText: "Contains",
      search: "",
      step: "",
      types: [FilterType.DEVICE],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Model",
      additionalText: "Contains",
      search: "",
      step: "",
      types: [FilterType.DEVICE],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Job Status",
      additionalText: "",
      search: "",
      types: [FilterType.JOB],
      inputType: "select",
      exclude: false,
    },
    {
      name: "Job Type",
      additionalText: "",
      search: "",
      types: [FilterType.JOB],
      inputType: "select",
      exclude: false,
    },
    {
      name: "Serial Number",
      additionalText: "Equals",
      search: "",
      step: "",
      types: [FilterType.DEVICE],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Organizational Unit",
      additionalText: "",
      search: "",
      types: [FilterType.USER, FilterType.FILE, FilterType.DEVICE, FilterType.ADMIN_USER, FilterType.ORGANIZATIONAL_UNIT],
      inputType: "select",
      exclude: false,
    },
    {
      name: "Flagged for Excessive Usage",
      additionalText: "",
      options: [
        {
          name: "Flagged Only",
          selected: false,
        },
      ],
      types: [FilterType.USER],
      inputType: "checkbox",
      exclude: false,
    },
    {
      name: "Contains External Users",
      additionalText: "",
      options: [
        {
          name: "Contains External Users",
          selected: false,
        },
      ],
      types: [FilterType.DRIVE],
      inputType: "checkbox",
      exclude: false,
    },
    {
      name: "MD5 Checksum",
      additionalText: "Value",
      search: "",
      types: [FilterType.FILE, FilterType.DUPLICATE_FILE],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Owner Is Suspended",
      additionalText: "",
      options: [
        {
          name: "Owner Is Suspended",
          selected: false,
        },
      ],
      types: [FilterType.FILE],
      inputType: "checkbox",
      exclude: false,
    },
    {
      name: "Hide Trashed",
      additionalText: "",
      options: [
        {
          name: "Hide Trashed",
          selected: false,
        },
      ],
      types: [FilterType.FILE],
      inputType: "checkbox",
      exclude: false,
    },
    {
      name: "Usage Amount",
      additionalText: "Greater than",
      unit: "(GB)",
      postfix: " GB",
      step: "0.01",
      search: "",
      types: [FilterType.USER, FilterType.FILE, FilterType.DRIVE, FilterType.DUPLICATE_FILE, FilterType.ORGANIZATIONAL_UNIT],
      inputType: "number",
      exclude: false,
    },
    {
      name: "File Type",
      additionalText: "",
      search: "",
      types: [FilterType.FILE],
      inputType: "select",
      exclude: false,
      parentChild: true,
    },
    {
      name: "Shared Drive",
      additionalText: "Starts with",
      search: "",
      types: [FilterType.FILE],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Shared Drive Name",
      additionalText: "Starts with",
      search: "",
      step: "",
      types: [FilterType.DRIVE],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Content Manager",
      additionalText: "Includes",
      search: "",
      step: "",
      types: [FilterType.DRIVE],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Viewer",
      additionalText: "Includes",
      search: "",
      step: "",
      types: [FilterType.DRIVE],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Commenter",
      additionalText: "Includes",
      search: "",
      step: "",
      types: [FilterType.DRIVE],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Contributor",
      additionalText: "Includes",
      search: "",
      step: "",
      types: [FilterType.DRIVE],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Manager",
      additionalText: "Includes",
      search: "",
      step: "",
      types: [FilterType.DRIVE],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Number of Users",
      additionalText: "# of users",
      search: "",
      step: "0",
      types: [FilterType.DRIVE],
      inputType: "number",
      exclude: false,
    },
    {
      name: "Building Name",
      additionalText: "Starts with",
      search: "",
      step: "",
      types: [FilterType.BUILDING],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Deleted",
      additionalText: "",
      options: [
        {
          name: "Deleted",
          selected: true,
        },
      ],
      types: [FilterType.WORKFLOW, FilterType.TICKET, FilterType.DEVICE, FilterType.SIGNATURE_TEMPLATE],
      inputType: "checkbox",
      exclude: false,
      roles: ["agent", "admin"],
    },
    {
      name: "Errored",
      additionalText: "",
      options: [
        {
          name: "Errored",
          selected: true,
        },
      ],
      types: [FilterType.WORKFLOW],
      inputType: "checkbox",
      exclude: false,
    },
    {
      name: "Executed",
      additionalText: "",
      options: [
        {
          name: "Executed",
          selected: true,
        },
      ],
      types: [FilterType.WORKFLOW],
      inputType: "checkbox",
      exclude: false,
    },
    {
      name: "Workflow Name",
      additionalText: "Starts with",
      search: "",
      step: "",
      types: [FilterType.WORKFLOW],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Workflow Type",
      additionalText: "",
      search: "",
      types: [FilterType.WORKFLOW],
      inputType: "select",
      exclude: false,
    },
    {
      name: "Workflow Activity",
      additionalText: "",
      search: "",
      types: [FilterType.WORKFLOW],
      inputType: "select",
      exclude: false,
    },
    {
      name: "Workflow Event",
      additionalText: "",
      search: "",
      types: [FilterType.WORKFLOW],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Enabled",
      additionalText: "",
      options: [
        {
          name: "Enabled",
          selected: true,
        },
      ],
      types: [FilterType.WORKFLOW],
      inputType: "checkbox",
      exclude: false,
    },
    {
      name: "Disabled",
      additionalText: "",
      options: [
        {
          name: "Disabled",
          selected: true,
        },
      ],
      types: [FilterType.WORKFLOW],
      inputType: "checkbox",
      exclude: false,
    },
    {
      name: "Created By User",
      additionalText: "Starts with",
      search: "",
      step: "",
      types: [FilterType.WORKFLOW],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Updated By User",
      additionalText: "Starts with",
      search: "",
      step: "",
      types: [FilterType.WORKFLOW],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Updated After",
      additionalText: "",
      search: "",
      types: [FilterType.WORKFLOW],
      inputType: "date",
      exclude: false,
    },
    {
      name: "Updated Before",
      additionalText: "",
      search: "",
      types: [FilterType.WORKFLOW],
      inputType: "date",
      exclude: false,
    },
    {
      name: "Name",
      additionalText: "Contains",
      search: "",
      types: [FilterType.TEAM, FilterType.SIGNATURE_TEMPLATE],
      inputType: "text",
    },
    {
      name: "Flagged Date Before",
      additionalText: "",
      search: "",
      types: [FilterType.FILE, FilterType.USER],
      inputType: "date",
      exclude: false,
    },
    {
      name: "Days Flagged",
      additionalText: "",
      search: "",
      types: [FilterType.FILE, FilterType.USER],
      inputType: "number",
      exclude: false,
    },
    {
      name: "Recipient Email",
      additionalText: "Starts with",
      search: "",
      types: [FilterType.MESSAGE],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Message Title",
      additionalText: "Includes",
      search: "",
      types: [FilterType.MESSAGE],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Status",
      key: "status",
      additionalText: "",
      search: "",
      types: [FilterType.TICKET],
      inputType: "select",
      exclude: false,
      customParams: true,
      roles: ["user", "agent", "admin"],
    },
    {
      name: "Unassigned",
      additionalText: "",
      options: [
        {
          name: "Unassigned",
          selected: false,
        },
      ],
      types: [FilterType.TICKET],
      inputType: "checkbox",
      exclude: false,
      roles: ["agent", "admin"],
    },
    {
      name: "Subject",
      additionalText: "Contains",
      search: "",
      types: [FilterType.TICKET],
      inputType: "text",
      exclude: false,
      roles: ["agent", "admin"],
    },
    {
      name: "Tag",
      additionalText: "Contains",
      search: "",
      types: [FilterType.TICKET, FilterType.TAG],
      inputType: "text",
      exclude: false,
      roles: ["agent", "admin"],
    },
    {
      name: "Priority",
      additionalText: "",
      search: "",
      types: [FilterType.TICKET],
      inputType: "select",
      exclude: false,
      roles: ["agent", "admin"],
    },
    {
      name: "Category",
      additionalText: "",
      search: "",
      types: [FilterType.TICKET],
      inputType: "select",
      exclude: false,
      roles: ["agent", "admin"],
      parentChild: true,
    },
    {
      name: "Channel",
      additionalText: "",
      search: "",
      types: [FilterType.TICKET],
      inputType: "select",
      exclude: false,
      roles: ["agent", "admin"],
    },
    {
      name: "Ticket Created By",
      additionalText: "Contains",
      search: "",
      types: [FilterType.TICKET],
      inputType: "text",
      exclude: false,
      roles: ["agent", "admin"],
    },
    {
      name: "Assigned Agent",
      additionalText: "",
      search: "",
      types: [FilterType.TICKET],
      inputType: "select",
      exclude: false,
      roles: ["agent", "admin"],
    },
    {
      name: "Ticket Created After",
      additionalText: "",
      search: "",
      types: [FilterType.TICKET],
      inputType: "date",
      exclude: false,
      roles: ["agent", "admin"],
    },
    {
      name: "Ticket Created Before",
      additionalText: "",
      search: "",
      types: [FilterType.TICKET],
      inputType: "date",
      exclude: false,
      roles: ["agent", "admin"],
    },
    {
      name: "Ticket Updated After",
      additionalText: "",
      search: "",
      types: [FilterType.TICKET],
      inputType: "date",
      exclude: false,
      roles: ["agent", "admin"],
    },
    {
      name: "Ticket Updated Before",
      additionalText: "",
      search: "",
      types: [FilterType.TICKET],
      inputType: "date",
      exclude: false,
      roles: ["agent", "admin"],
    },
    {
      name: "Assigned",
      additionalText: "",
      options: [
        {
          name: "Assigned",
          selected: false,
        },
      ],
      types: [FilterType.TICKET],
      inputType: "checkbox",
      exclude: false,
      roles: ["agent", "admin"],
    },
    {
      name: "Requested By",
      additionalText: "Contains",
      search: "",
      types: [FilterType.TICKET],
      inputType: "text",
      exclude: false,
      roles: ["agent", "admin"],
    },
    {
      name: "Object Type",
      additionalText: "",
      search: "",
      types: [FilterType.LOGS],
      inputType: "select",
      exclude: false,
    },
    {
      name: "Action",
      additionalText: "",
      search: "",
      types: [FilterType.LOGS],
      inputType: "select",
      exclude: false,
      parentChild: true,
    },
    {
      name: "Object ID",
      additionalText: "Equals",
      search: "",
      types: [FilterType.LOGS],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Assigned User",
      additionalText: "Contains",
      search: "",
      types: [FilterType.DEVICE],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Manufacturer",
      additionalText: "",
      search: "",
      types: [FilterType.DEVICE],
      inputType: "select",
      exclude: false,
    },
    {
      id: 0,
      name: "Location",
      additionalText: "",
      search: "",
      types: [FilterType.DEVICE],
      inputType: "select",
      exclude: false,
    },
    {
      name: "Type",
      additionalText: "",
      search: "",
      types: [FilterType.DEVICE],
      inputType: "select",
      exclude: false,
    },
    // will need to be removed one day because of new Audit Logs area
    {
      name: "Log Name",
      additionalText: "Starts with",
      search: "",
      types: [FilterType.WORKFLOW_LOG],
      inputType: "text",
      exclude: false,
    },
    // will need to be removed one day because of new Audit Logs area
    {
      name: "Log Type",
      additionalText: "Starts with",
      search: "",
      types: [FilterType.WORKFLOW_LOG],
      inputType: "select",
      exclude: false,
    },
    // will need to be removed one day because of new Audit Logs area
    {
      name: "Log Date",
      additionalText: "Starts with",
      search: "",
      types: [FilterType.WORKFLOW_LOG],
      inputType: "date",
      exclude: false,
    },
    // will need to be removed one day because of new Audit Logs area
    {
      name: "Actor",
      additionalText: "Starts with",
      search: "",
      types: [FilterType.WORKFLOW_LOG],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Auto Update Expiration After Date",
      additionalText: "Contains",
      search: "",
      types: [FilterType.DEVICE],
      inputType: "date",
      exclude: false,
    },
    {
      name: "Custom Location",
      additionalText: "Contains",
      search: "",
      types: [FilterType.DEVICE],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Log Data",
      additionalText: "Contains",
      search: "",
      types: [FilterType.LOGS],
      inputType: "logData",
      exclude: false,
    },
    {
      name: "File Name",
      additionalText: "Starts with",
      search: "",
      step: "",
      types: [FilterType.FILE],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Last Modified By",
      additionalText: "Starts with",
      search: "",
      types: [FilterType.FILE],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Description",
      additionalText: "Contains",
      search: "",
      types: [FilterType.SIGNATURE_TEMPLATE],
      inputType: "text",
      exclude: false,
    },
    {
      name: "Active",
      additionalText: "",
      options: [
        {
          name: "Active",
          selected: true,
        },
      ],
      types: [FilterType.SIGNATURE_TEMPLATE],
      inputType: "checkbox",
    },
  ];
  classRoomCourseStates = [
    {
      name: "ACTIVE",
    },
    {
      name: "ARCHIVED",
    },
    {
      name: "PROVISIONED",
    },
    {
      name: "DECLINED",
    },
    {
      name: "SUSPENDED",
    },
  ];

  classRoomCourseWorkStates = [
    {
      name: "PUBLISHED",
    },
    {
      name: "DRAFT",
    },
    {
      name: "DELETED",
    },
  ];

  meetingDeviceTypes = [
    {
      name: "ANDROID",
    },
    {
      name: "CHROMEBASE",
    },
    {
      name: "CHROMEBOX",
    },
    {
      name: "INTEROP",
    },
    {
      name: "IOS",
    },
    {
      name: "JAMBOARD",
    },
    {
      name: "OTHER CLIENT",
    },
    {
      name: "PSTN IN",
    },
    {
      name: "PSTN OUT",
    },
    {
      name: "SMART DISPLAY",
    },
    {
      name: "WEB",
    },
  ];

  deviceStatuses = [
    {
      name: "ACTIVE",
    },
    {
      name: "DELINQUENT",
    },
    {
      name: "PRE PROVISIONED",
    },
    {
      name: "DEPROVISIONED",
    },
    {
      name: "DISABLED",
    },
    {
      name: "INACTIVE",
    },
    {
      name: "RETURN ARRIVED",
    },
    {
      name: "RETURN REQUESTED",
    },
    {
      name: "UNKNOWN",
    },
  ];

  jobTypes = [
    {
      name: "TRANSFER TO GCP",
    },
    {
      name: "DELETE FILES",
    },
  ];

  jobStatuses = [
    {
      name: "CREATED",
    },
    {
      name: "QUEUED",
    },
    {
      name: "RUNNING",
    },
    {
      name: "FAILED",
    },
    {
      name: "COMPLETED",
    },
    {
      name: "ERRORED",
    },
  ];
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  addChipFromLink(params, selectedFilters) {
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const filter = this.filters.filter((f) => f.name == decodeURI(key).split("_")[0])[0];
        if (!filter) continue;
        filter.search = params[key];
        selectedFilters.push(filter);
      }
    }
    return selectedFilters;
  }

  async checkNavFilters(filterType: FilterType): Promise<boolean> {
    let hasNavFilters = false;
    const queryParams = this.activatedRoute.snapshot.queryParams;
    hasNavFilters = Object.entries(queryParams).some((p) => parseInt(p[0].split("_")[1]) == filterType);
    return hasNavFilters;
  }

  async updateNavFilters(filter: Filter, filterType: FilterType, type = "") {
    let filterSearch;
    if (filter.search && filter.name === "Status") {
      try {
        filterSearch = JSON.parse(filter.search);
        return;
      } catch (e) {
        filterSearch = filter.search;
      }
      if (typeof filterSearch == "object") return;
    } else {
      filterSearch = filter.search;
    }
    const tableId = `_${filterType.toString()}`;
    const paramKey = encodeURI(filter.name) + tableId;
    const paramValue = type == "add" ? encodeURI(filterSearch) : null;
    const queryParams = filter?.customParams
      ? { [paramKey]: paramValue, orderBy: null, sort: null }
      : { [paramKey]: paramValue };
    this.router.navigate([], {
      queryParams: queryParams,
      queryParamsHandling: "merge",
    });
  }

  formatDurationFilterDisplay(seconds: number) {
    const secondsPositive = Math.abs(seconds);
    let secondsValue = 0;
    let minutesValue = 0;
    let hoursValue = 0;
    hoursValue = Math.floor(secondsPositive / 3600);
    minutesValue = Math.floor((secondsPositive - hoursValue * 3600) / 60);
    secondsValue = secondsPositive - minutesValue * 60 - hoursValue * 3600;
    const durationFormatted =
      hoursValue.toString().padStart(2, "0") +
      ":" +
      minutesValue.toString().padStart(2, "0") +
      ":" +
      secondsValue.toString().padStart(2, "0");
    return durationFormatted;
  }

  formatDurationFilterValue(display: string) {
    let seconds = 0;
    let secondsPlace = 0,
      minutesPlace = 0,
      hoursPlace = 0;
    secondsPlace = parseInt(display.split(":")[2]);
    minutesPlace = parseInt(display.split(":")[1]) * 60;
    hoursPlace = parseInt(display.split(":")[0]) * 3600;
    seconds = hoursPlace + minutesPlace + secondsPlace;
    return seconds;
  }
}

export enum FilterType {
  // KEEP IN SYNC WITH /var/www/api.kincaidit.com/app/helpers/enums.js
  ADMIN_USER = 1,
  USER = 2,
  FILE = 3,
  DUPLICATE_FILE = 4,
  DRIVE = 5,
  JOB = 6,
  BUILDING = 7,
  DEVICE = 9,
  ANALYTICS_MEETING = 10,
  WORKFLOW = 11,
  SHARED_DRIVE_USER = 12,
  ORGANIZATIONAL_UNIT = 13,
  MESSAGE = 14,
  TICKET = 15,
  TICKET_DEVICE = 16,
  TEAM = 17,
  LOGS = 18,
  WORKFLOW_LOG = 19,
  TAG = 20,
  DELEGATE = 21,
  SIGNATURE_TEMPLATE = 22,
}

export interface Filter {
  id?: number;
  name: string;
  caller?: FilterType;
  additionalText?: string;
  unit?: string;
  options?: Option[];
  postfix?: string;
  search?: string;
  step?: string;
  types?: FilterType[];
  inputType?: string;
  hint?: string;
  exclude?: boolean;
  key?: string;
  customParams?: boolean;
  roles?: string[];
  parentChild?: boolean;
}

export interface Option {
  name: string;
  selected: boolean;
}
