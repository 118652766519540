import { Component, OnInit, ViewChild } from "@angular/core";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { StorageReport, ReportsService, User } from "src/app/services/google/reports.service";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Router } from "@angular/router";
import { ExportsheetsService } from "src/app/services/google/sheets.service";
import { StorageReportUsersTableComponent } from "./storage-report-users-table/storage-report-users-table.component";
import { AdminPlusService } from "src/app/services/admin-plus/admin-plus.service";

@Component({
  selector: "app-storage-report-users",
  templateUrl: "./storage-report-users.component.html",
  styleUrls: ["./storage-report-users.component.scss"],
})
export class StorageReportUsersComponent implements OnInit {
  options = [];
  @ViewChild(StorageReportUsersTableComponent)
  usersTableComponent: StorageReportUsersTableComponent;
  storageReport: StorageReport = {
    status: {
      name: "",
      id: 4,
    },
    totalUsers: 0,
    totalStorage: 0,
    totalDrive: 0,
    totalGmail: 0,
    totalPhotos: 0,
  };

  users: User[] = [];
  columns: string[] = [];

  constructor(
    private navService: NavService,
    private exportSheetsService: ExportsheetsService,
    private router: Router,
    private reportsService: ReportsService,
    private adminPlusService: AdminPlusService
  ) {
    this.options.push({
      icon: "picture_as_pdf",
      name: "Export to PDF",
      disabled: true,
      tooltip: "Exports the current page to a PDF document for sharing",
      class: "export",
    });
    this.options.push({
      icon: "description",
      name: "Export to Sheets",
      disabled: true,
      tooltip: "Exports the current data set to Google Sheets",
      class: "success",
    });

    this.adminPlusService.breadCrumbLinks.push({
      link: null,
      text: "Users",
      alt: "Users",
    });
  }

  async ngOnInit() {
    this.navService.loading.next(true);
    this.storageReport = await this.reportsService.getStorageReport();
    this.navService.loading.next(false);
    if (!this.storageReport || this.storageReport.status.id != 4) {
      //complete
      this.router.navigate(["/reports/storage"]);
    }
  }

  optionSelected(option) {
    switch (option.name) {
      case "Export to PDF":
        this.downloadAsPDF();
        break;
      case "Export to Sheets":
        this.exportToSheets();
        break;
      default:
        break;
    }
  }

  async receiveUsers($event) {
    this.users = $event["users"];
    this.columns = $event["columns"];
  }

  async addUserData(spreadsheetID: string) {
    const userSheetsData = [];
    for (let i = 0; i < this.users.length; i++) {
      const driveTotalNotRound = this.users[i]["driveTotal"];
      const trashedStorageNotRound = this.users[i]["trashedStorage"];
      const nonTrashedStorage = driveTotalNotRound - trashedStorageNotRound;
      const userSheet: User = {
        fullName: this.users[i]["fullName"],
        driveTotal: this.users[i]["driveTotal"],
        gmailTotal: this.users[i]["gmailTotal"],
        photosTotal: this.users[i]["photosTotal"],
        totalStorage: this.users[i]["totalStorage"],
        OU: this.users[i]["OU"],
      };
      if (this.columns.includes("previousTotal")) {
        userSheet.previousTotal = this.users[i]["previousTotal"];
      }
      if (this.columns.includes("previousTrashed")) {
        userSheet.previousTrashed = this.users[i]["previousTrashed"];
      }
      if (this.columns.includes("nonTrashedStorage")) {
        userSheet.nonTrashedStorage = nonTrashedStorage.toString();
      }
      if (this.columns.includes("trashedStorage")) {
        userSheet.trashedStorage = this.users[i]["trashedStorage"];
      }
      userSheetsData.push(userSheet);
    }
    const newUserObject = await this.exportSheetsService.createSpreadsheetArray(this.columns, userSheetsData);
    if (newUserObject) {
      await this.exportSheetsService.addExportData(
        newUserObject,
        spreadsheetID,
        "Sheet1!A1:G" + newUserObject["values"].length
      );
    }
  }

  async exportToSheets() {
    this.navService.loading.next(true);
    const spreadsheetID = await this.exportSheetsService.createSpreadsheet("Users");
    await this.usersTableComponent.sendUserEmitter();
    await this.addUserData(spreadsheetID);
    window.open("https://docs.google.com/spreadsheets/d/" + spreadsheetID);
    this.navService.loading.next(false);
  }

  async downloadAsPDF() {
    this.navService.loading.next(true);
    const canvas = await this.canvas();
    if (canvas) {
      this.navService.loading.next(false);
    }
  }

  async canvas() {
    return new Promise((resolve) => {
      html2canvas(document.getElementById("entireScreen")).then(function (canvas) {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 210;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        const doc = new jsPDF("p", "mm");
        let position = 0;
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save("storage-report-users.pdf");
        resolve(true);
      });
    });
  }
}
