<div class="list-group-item m-0 p-0">
  <div class="col-12 my-3" *ngFor="let subAction of workflowAction.action.subActions">
    <mat-slide-toggle
      
      (change)="automationService.dirty(); toggleSubAction(subAction)"
      [checked]="selected(subAction)"
      [disabled]="!selectedProperties.includes(subAction.objectProperty.id)"
      [matTooltip]="
        !selectedProperties.includes(subAction.objectProperty.id)
          ? 'Requires ' + subAction.objectProperty.name + ' Report Property'
          : subAction.description
      "
    >
      {{ subAction.name }}
    </mat-slide-toggle>
  </div>
</div>
