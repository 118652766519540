import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-no-service-account-dialog",
  templateUrl: "./no-service-account-dialog.component.html",
})
export class NoServiceAccountDialogComponent {
  constructor(private dialogRef: MatDialogRef<NoServiceAccountDialogComponent>) {}
  setUpServiceAccount() {
    this.dialogRef.close(true);
  }
}
