import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { environment } from "src/environments/environment";
import { AuthService } from "src/app/services/admin-plus/auth.service";
import { Customer } from "src/app/services/admin-plus/customer.service";
import { ServiceAccount } from "../admin-plus/service-account.service";
import { UtilityService } from "../utilities/utility.service";
import { AdminPlusService } from "../admin-plus/admin-plus.service";

@Injectable({
  providedIn: "root",
})
export class AutomationService {
  private user;
  private apiUrl: string = environment.apiUrl + "/customer/automation";
  private dirtySubject = new Subject<boolean>();
  private missingScopesSubject = new Subject<boolean>();
  public customGoogleObjects: CustomGoogleObjectData[] = [];
  public types: Type[] = [
    {
      id: 2,
      name: "Schedule",
      description: "Setup a recurring workflow that repeats actions at a specific date an time.",
    },
    {
      id: 3,
      name: "Specific Date and Time",
      description: "Run workflow on a specific date",
    },
    {
      id: 1,
      name: "Workspace Event",
      description: "Execute workflow when specific events within your environment occur.",
    },
  ];

  reportActions = [ACTIONS.CHROME_OS_DEVICE_REPORT, ACTIONS.STORAGE_REPORT_USER_REPORT];
  scopeCheck: ScopeCheck = {
    serviceAccount: null,
    missingScopes: [],
    missingAPIs: [],
  };

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private authService: AuthService,
    private utilityService: UtilityService,
    private adminPlusService: AdminPlusService
  ) {}

  setBreadCrumb(workflow: Workflow) {
    if (!workflow) return;
    if (this.adminPlusService.breadCrumbLinks.length < 2) {
      this.adminPlusService.breadCrumbLinks.push({
        link: null,
        text: workflow.name ? workflow.name : "New Workflow",
        alt: workflow.name ? workflow.name : "New Workflow",
      });
    } else {
      this.adminPlusService.breadCrumbLinks[1].text = workflow.name ? workflow.name : "New Workflow";
      this.adminPlusService.breadCrumbLinks[1].alt = workflow.name ? workflow.name : "New Workflow";
    }
  }

  public async getTagsByEmailId(emailId: number, search: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Tag[]>((resolve) => {
      this.getTagsByEmailIdRequest(emailId, search).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getTagsByEmailIdRequest(emailId: number, search: string): Observable<Tag[]> {
    return this.http
      .get<Tag[]>(this.apiUrl + `/emails/${emailId}`, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams().set("search", search),
      })
      .pipe(catchError(this.authService.handleAPIError<Tag[]>("getTagsByEmailIdRequest")));
  }

  public async getWorkflows(
    limit: number,
    offset: number,
    name: string,
    type: string,
    object: string,
    event: string,
    enabled: boolean,
    disabled: boolean,
    createdByUser: string,
    updatedByUser: string,
    createdAfter: string,
    createdBefore: string,
    updatedBefore: string,
    updatedAfter: string,
    lastRunBefore: string,
    lastRunAfter: string,
    deleted: boolean,
    executed: boolean,
    errored: boolean,
    folderName: string,
    folderId: number,
    orderBy: string,
    top5: boolean,
    isRunning = false
  ) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<WorkflowData>((resolve) => {
      this.getWorkflowsRequest(
        limit,
        offset,
        name,
        type,
        object,
        event,
        enabled,
        disabled,
        createdByUser,
        updatedByUser,
        createdAfter,
        createdBefore,
        updatedBefore,
        updatedAfter,
        lastRunBefore,
        lastRunAfter,
        deleted,
        executed,
        errored,
        folderName,
        folderId,
        orderBy,
        top5,
        isRunning
      ).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getWorkflowsRequest(
    limit: number,
    offset: number,
    name: string,
    type: string,
    object: string,
    event: string,
    enabled: boolean,
    disabled: boolean,
    createdByUser: string,
    updatedByUser: string,
    createdAfter: string,
    createdBefore: string,
    updatedBefore: string,
    updatedAfter: string,
    lastRunBefore: string,
    lastRunAfter: string,
    deleted: boolean,
    executed: boolean,
    errored: boolean,
    folderName: string,
    folderId: number,
    orderBy: string,
    top5: boolean,
    isRunning = false
  ): Observable<WorkflowData> {
    return this.http
      .get<WorkflowData>(this.apiUrl + `/workflows`, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams()
          .set("limit", limit.toString())
          .set("offset", offset.toString())
          .set("getCount", "true")
          .set("name", name)
          .set("type", type)
          .set("object", object)
          .set("event", event)
          .set("enabled", enabled.toString())
          .set("disabled", disabled.toString())
          .set("createdByUser", createdByUser)
          .set("updatedByUser", updatedByUser)
          .set("createdAfter", createdAfter)
          .set("createdBefore", createdBefore)
          .set("updatedBefore", updatedBefore)
          .set("updatedAfter", updatedAfter)
          .set("lastRunBefore", lastRunBefore)
          .set("lastRunAfter", lastRunAfter)
          .set("deleted", deleted.toString())
          .set("executed", executed.toString())
          .set("errored", errored.toString())
          .set("folderName", folderName)
          .set("folderId", folderId ? folderId.toString() : "0")
          .set("orderBy", orderBy)
          .set("top5", top5.toString())
          .set("isRunning", isRunning),
      })
      .pipe(catchError(this.authService.handleAPIError<WorkflowData>("getWorkflowsRequest")));
  }

  public async getDailyWorkflowExecutions() {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<WorkflowExecution[]>((resolve) => {
      this.getDailyWorkflowExecutionsData().subscribe((workflowExecutions) => {
        if (workflowExecutions) {
          resolve(workflowExecutions);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getDailyWorkflowExecutionsData(): Observable<WorkflowExecution[]> {
    return this.http
      .get<WorkflowExecution[]>(this.apiUrl + `/workflows/workflow-executions`, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<WorkflowExecution[]>("getDailyWorkflowExecutionsData")));
  }

  async getWorkflowTemplates() {
    return new Promise<WorkflowData>((resolve) => {
      this.utilityService.sendRequest("GET", `/customer/automation/workflow/templates`).subscribe((payload) => {
        if (payload?.data?.length) {
          resolve(payload);
        } else {
          resolve(null);
        }
      });
    });
  }

  async getFolder(folderId: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Folder>((resolve) => {
      this.getFolderRequest(folderId).subscribe((folder) => {
        if (folder) {
          resolve(folder);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getFolderRequest(folderId: string): Observable<Folder> {
    return this.http
      .get<Folder>(this.apiUrl + `/workflows/folders/${folderId}`, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Folder>("getFolderRequest")));
  }

  async getFolders(name = "") {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<FolderData>((resolve) => {
      this.getFoldersRequest(name).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getFoldersRequest(name = ""): Observable<FolderData> {
    return this.http
      .get<FolderData>(this.apiUrl + `/workflows/folders`, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams().set("name", name),
      })
      .pipe(catchError(this.authService.handleAPIError<FolderData>("getFoldersRequest")));
  }

  async getWorkflow(workflowId: number) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Workflow>((resolve) => {
      this.getWorkflowRequest(workflowId).subscribe((results) => {
        if (results) {
          results = this.prepareTagsToDisplay(results);
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getWorkflowRequest(workflowId: number): Observable<Workflow> {
    return this.http
      .get<Workflow>(this.apiUrl + `/workflows/` + workflowId, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Workflow>("getWorkflowRequest")));
  }

  private prepareTagsToDisplay(workflow) {
    for (const action of workflow.actions) {
      const tagParam = action.action.parameters.find(
        (param) => param.id === ACTION_PARAMETERS.EMAIL_TAGS || param.id === ACTION_PARAMETERS.TAGS
      );

      if (tagParam && typeof tagParam.parameterValue?.value === "string" && tagParam.parameterValue?.value != "") {
        const tags = JSON.parse(tagParam.parameterValue.value);
        for (const tag of tags) {
          if (tag.property?.id == WORKFLOW_PROPERTIES.CUSTOM_ATTRIBUTE && tag.objectKey) {
            tag.subProperty = tag.objectKey.split(/\.(.*)/s)[1];
            tag.objectKey = tag.objectKey.split(/\.(.*)/s)[0] + ".";
          }
        }
        tagParam.parameterValue.value = tags;
      }
    }
    return workflow;
  }

  public async getWorkflowTemplate(workflowId: number) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Workflow>((resolve) => {
      this.getWorkflowTemplateRequest(workflowId).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getWorkflowTemplateRequest(workflowId: number): Observable<Workflow> {
    return this.http
      .get<Workflow>(this.apiUrl + `/workflows/templates/` + workflowId, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Workflow>("getWorkflowTemplateRequest")));
  }

  public async createWorkflow(workflow: Workflow) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Workflow>((resolve) => {
      this.createWorkflowRequest(workflow).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private createWorkflowRequest(workflow: Workflow): Observable<Workflow> {
    return this.http
      .post<Workflow>(this.apiUrl + `/workflows`, workflow, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Workflow>("createWorkflowRequest")));
  }

  public async createFolder(workflowId: number, folder: Folder) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Folder[]>((resolve) => {
      this.createFolderRequest(workflowId, folder).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private createFolderRequest(workflowId: number, folder: Folder): Observable<Folder[]> {
    return this.http
      .post<Folder[]>(this.apiUrl + `/workflows/${workflowId}/folder`, folder, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Folder[]>("createFolderRequest")));
  }

  public async updateFolderId(workflowId: number, folderId: number) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Folder[]>((resolve) => {
      this.updateFolderIdRequest(workflowId, folderId).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private updateFolderIdRequest(workflowId: number, folderId: number): Observable<Folder[]> {
    return this.http
      .post<Folder[]>(
        this.apiUrl + `/workflows/${workflowId}/folder/${folderId}`,
        {},
        {
          headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        }
      )
      .pipe(catchError(this.authService.handleAPIError<Folder[]>("updateFolderIdRequest")));
  }

  public async updateFolder(folder: Folder) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Folder>((resolve) => {
      this.updateFolderRequest(folder).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private updateFolderRequest(folder: Folder): Observable<Folder> {
    return this.http
      .patch<Folder>(this.apiUrl + `/workflows/folders/${folder.id}`, folder, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Folder>("updateFolderRequest")));
  }

  public async copyWorkflow(workflowId: number, isTemplate = false) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Workflow>((resolve) => {
      this.copyWorkflowRequest(workflowId, isTemplate).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private copyWorkflowRequest(workflowId: number, isTemplate = false): Observable<Workflow> {
    return this.http
      .post<Workflow>(
        this.apiUrl + `/workflows/` + workflowId + "/copy",
        {
          workflowId: workflowId,
          isTemplate: isTemplate,
        },
        {
          headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        }
      )
      .pipe(catchError(this.authService.handleAPIError<Workflow>("copyWorkflowRequest")));
  }

  public async updateWorkflow(workflow: Workflow) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Workflow>((resolve) => {
      this.updateWorkflowRequest(workflow).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private updateWorkflowRequest(workflow: Workflow): Observable<Workflow> {
    return this.http
      .put<Workflow>(this.apiUrl + `/workflows/` + workflow.id, workflow, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Workflow>("updateWorkflowRequest")));
  }

  public async updateWorkflowDetails(workflow: Workflow, details = "details") {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    workflow = this.prepareTagsToSave(workflow);

    return new Promise<Workflow>((resolve) => {
      this.updateWorkflowDetailsRequest(workflow, details).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private prepareTagsToSave(workflow: Workflow) {
    for (const action of workflow.actions) {
      const tagParam = action.action.parameters.find(
        (param) => param.id === ACTION_PARAMETERS.EMAIL_TAGS || param.id === ACTION_PARAMETERS.TAGS
      );

      if (tagParam && typeof tagParam.parameterValue?.value === "string") {
        const tags = JSON.parse(tagParam.parameterValue.value);
        for (const tag of tags) {
          if (tag.property.id == WORKFLOW_PROPERTIES.CUSTOM_ATTRIBUTE) {
            if (tag.subProperty) {
              tag.objectKey += tag.subProperty;
              delete tag.subProperty;
            }
          }
        }
        tagParam.parameterValue.value = JSON.stringify(tags);
      }

      if (tagParam && typeof tagParam.parameterValue?.value === "object") {
        tagParam.parameterValue.value = JSON.stringify(tagParam.parameterValue.value);
      }
    }
    return workflow;
  }

  private updateWorkflowDetailsRequest(workflow: Workflow, details = "details"): Observable<Workflow> {
    return this.http
      .put<Workflow>(this.apiUrl + `/workflows/` + workflow.id + `/` + details, workflow, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Workflow>("updateWorkflowDetailsRequest")));
  }

  public async deleteFolder(folder: Folder, workflows: Workflow[]) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Folder>((resolve) => {
      this.deleteFolderRequest(folder, workflows).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private deleteFolderRequest(folder: Folder, workflows: Workflow[]): Observable<Folder> {
    return this.http
      .post<Folder>(this.apiUrl + `/workflows/folders/${folder.id}`, workflows, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Folder>("deleteFolderRequest")));
  }

  public async removeFromFolder(workflow: Workflow) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Workflow>((resolve) => {
      this.removeFromFolderRequest(workflow).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private removeFromFolderRequest(workflow: Workflow): Observable<Workflow> {
    return this.http
      .post<Workflow>(this.apiUrl + `/workflows/` + workflow.id + "/remove-from-folder", workflow, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Workflow>("removeFromFolderRequest")));
  }

  public async restoreWorkflow(workflow: Workflow) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Workflow>((resolve) => {
      this.restoreWorkflowRequest(workflow).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private restoreWorkflowRequest(workflow: Workflow): Observable<Workflow> {
    return this.http
      .post<Workflow>(this.apiUrl + `/workflows/` + workflow.id + "/restore", workflow, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Workflow>("restoreWorkflowRequest")));
  }

  public async permanentlyDeleteWorkflow(workflow: Workflow) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Workflow>((resolve) => {
      this.permanentlyDeleteWorkflowRequest(workflow).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private permanentlyDeleteWorkflowRequest(workflow: Workflow): Observable<Workflow> {
    return this.http
      .delete<Workflow>(this.apiUrl + `/workflows/` + workflow.id + "/permanently-delete", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Workflow>("permanentlyDeleteWorkflowRequest")));
  }

  public async deleteWorkflow(workflow: Workflow) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Workflow>((resolve) => {
      this.deleteWorkflowRequest(workflow).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private deleteWorkflowRequest(workflow: Workflow): Observable<Workflow> {
    return this.http
      .delete<Workflow>(this.apiUrl + `/workflows/` + workflow.id, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Workflow>("deleteWorkflowRequest")));
  }

  public async getActivities() {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Activity[]>((resolve) => {
      this.getActivitiesRequest().subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getActivitiesRequest(): Observable<Activity[]> {
    return this.http
      .get<Activity[]>(this.apiUrl + `/activities`, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Activity[]>("getActivitiesRequest")));
  }

  async getActions() {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<ActionGroup[]>((resolve) => {
      this.getActionsRequest().subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getActionsRequest(): Observable<ActionGroup[]> {
    return this.http
      .get<ActionGroup[]>(this.apiUrl + `/actions`, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<ActionGroup[]>("getActionsRequest")));
  }

  public async checkFileReadAccess(fileId: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<boolean>((resolve) => {
      this.checkFileReadAccessRequest(fileId).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private checkFileReadAccessRequest(fileId: string): Observable<boolean> {
    return this.http
      .get<boolean>(this.apiUrl + `/actions/file`, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams().set("fileId", fileId),
      })
      .pipe(catchError(this.authService.handleAPIError<boolean>("checkFileReadAccessRequest")));
  }

  public async getCustomGoogleObject(objectName: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    const customObject = this.customGoogleObjects.find((cgo) => cgo.key == objectName);
    if (customObject != undefined) {
      return new Promise<CustomGoogleObjectData>((resolve) => {
        resolve(customObject);
      });
    }

    return new Promise<CustomGoogleObjectData>((resolve) => {
      this.getCustomGoogleObjectRequest(objectName).subscribe((results) => {
        if (results) {
          this.customGoogleObjects.push(results);
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getCustomGoogleObjectRequest(objectName: string): Observable<CustomGoogleObjectData> {
    return this.http
      .get<CustomGoogleObjectData>(this.apiUrl + `/customGoogleObject/` + objectName, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<CustomGoogleObjectData>("getCustomGoogleObjectRequest")));
  }

  public async getMissingScopes(workflowId: number, actionIds: number[]) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<ScopeCheck>((resolve) => {
      this.getMissingScopesRequest(workflowId, actionIds).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getMissingScopesRequest(workflowId: number, actionIds: number[]): Observable<ScopeCheck> {
    return this.http
      .get<ScopeCheck>(this.apiUrl + `/workflows/` + workflowId + `/missing-scopes`, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams().set("actionIds", JSON.stringify(actionIds)),
      })
      .pipe(catchError(this.authService.handleAPIError<ScopeCheck>("getMissingScopesRequest")));
  }

  public async getActionAPI(actionId: number) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<API>((resolve) => {
      this.getActionAPIRequest(actionId).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getActionAPIRequest(actionId: number): Observable<API> {
    return this.http
      .get<API>(this.apiUrl + `/action-api`, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams().set("actionId", actionId.toString()),
      })
      .pipe(catchError(this.authService.handleAPIError<API>("getActionAPIRequest")));
  }

  public async toggleWorkflow(workflowId: number, toggle = "enable") {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Workflow>((resolve) => {
      this.toggleWorkflowRequest(workflowId, toggle).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private toggleWorkflowRequest(workflowId: number, toggle = "enable"): Observable<Workflow> {
    return this.http
      .post<Workflow>(
        this.apiUrl + `/workflows/` + workflowId + `/` + toggle,
        {},
        {
          headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        }
      )
      .pipe(catchError(this.authService.handleAPIError<Workflow>("toggleWorkflowRequest")));
  }

  public async getActivityProperties(workflow: Workflow): Promise<Parameter[]> {
    if (workflow.type.id != 1) {
      if (workflow.gResource == "users") {
        return [this.adminPlusService.userParam];
      } else {
        return [];
      }
    }

    const activityId = workflow.activity?.id;
    const eventId = workflow.event?.id;

    if (!activityId || !eventId) return [];

    return await new Promise((resolve) => {
      this.utilityService
        .sendRequest("GET", `/customer/automation/activities/${activityId}/events/${eventId}/parameters/object/properties`)
        .subscribe((payload: any) => {
          if (payload) {
            resolve(payload);
          } else {
            resolve([]);
          }
        });
    });
  }

  public async getReportSample(workflowAction: WorkflowAction) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<ReportSample>((resolve) => {
      this.getReportSampleRequest(workflowAction).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getReportSampleRequest(workflowAction: WorkflowAction): Observable<ReportSample> {
    return this.http
      .post<ReportSample>(
        this.apiUrl + `/workflows/-/actions/-/report/sample`, //Pulls what it needs from the other request data...
        workflowAction,
        {
          headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        }
      )
      .pipe(catchError(this.authService.handleAPIError<ReportSample>("getReportSampleRequest")));
  }

  public async runWorkflow(workflowId: number) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise((resolve) => {
      this.runWorkflowRequest(workflowId).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private runWorkflowRequest(workflowId: number) {
    return this.http
      .post(
        this.apiUrl + `/workflows/${workflowId}/run`,
        {},
        {
          headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        }
      )
      .pipe(catchError(this.authService.handleAPIError("runWorkflowRequest")));
  }

  public async getAuditLogs(
    limit: number,
    offset: number,
    workflowId: number,
    logName: string,
    logType: string,
    logDate: string,
    actor: string,
    folderId: number
  ) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<AuditLogData>((resolve) => {
      this.getAuditLogsRequest(limit, offset, workflowId, logName, logType, logDate, actor, folderId).subscribe(
        (results) => {
          if (results) {
            resolve(results);
          } else {
            resolve(null);
          }
        }
      );
    });
  }

  private getAuditLogsRequest(
    limit: number,
    offset: number,
    workflowId: number,
    logName: string,
    logType: string,
    logDate: string,
    actor: string,
    folderId: number
  ): Observable<AuditLogData> {
    return this.http
      .get<AuditLogData>(this.apiUrl + `/audits`, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams()
          .set("limit", limit.toString())
          .set("offset", offset.toString())
          .set("getCount", "true")
          .set("workflowId", workflowId.toString())
          .set("logName", logName)
          .set("logType", logType)
          .set("logDate", logDate)
          .set("actor", actor)
          .set("folderId", folderId.toString()),
      })
      .pipe(catchError(this.authService.handleAPIError<AuditLogData>("getAuditLogsRequest")));
  }

  public async getAuditLogTypes() {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<AuditLogType[]>((resolve) => {
      this.getAuditLogTypesRequest().subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getAuditLogTypesRequest(): Observable<AuditLogType[]> {
    return this.http
      .get<AuditLogType[]>(this.apiUrl + `/audits/types`, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<AuditLogType[]>("getAuditLogTypesRequest")));
  }

  public boundCrontab(workflow, frequency) {
    const selectedDates = [];
    const firstValue = 0;
    const lastValue = 0;
    const boundCrontab = {
      interval: "",
      weekly: frequency.Weekly,
      monthly: frequency.Monthly,
      yearly: frequency.Yearly,
      timeOfDay: frequency.timeOfDay,
    };
    const cronTabPositions = workflow.scheduleCrontab.split(" ");
    const min = parseInt(cronTabPositions[0]); // min
    const hour = parseInt(cronTabPositions[1]); // hour
    const dayOfMonth = cronTabPositions[2]; // day of the month
    const month = cronTabPositions[3]; // month
    const dayOfWeek = cronTabPositions[4]; // day of the week
    if (dayOfMonth === "*" && month === "*" && dayOfWeek === "*") {
      boundCrontab.interval = "Daily";
    } else if (month === "*" && dayOfMonth === "*") {
      boundCrontab.interval = "Weekly";
      boundCrontab.weekly = this.weekly(boundCrontab, dayOfWeek, selectedDates, firstValue, lastValue);
    } else if (dayOfWeek === "*" && month === "*") {
      boundCrontab.interval = "Monthly";
      boundCrontab.monthly = this.monthly(boundCrontab, dayOfMonth, selectedDates, firstValue, lastValue);
    } else if (dayOfMonth === "1" && dayOfWeek === "*") {
      boundCrontab.interval = "Yearly";
      boundCrontab.yearly = this.yearly(boundCrontab, month, selectedDates, firstValue, lastValue);
    }
    boundCrontab.timeOfDay = `${hour < 10 ? "0" + hour : hour}:${min < 10 ? "0" + min : min}`;
    return boundCrontab;
  }

  private weekly(boundCrontab, cronTabPosition, selectedDates, firstValue, lastValue) {
    selectedDates = this.buildSelectDates(selectedDates, cronTabPosition, firstValue, lastValue);
    boundCrontab.weekly.forEach(function (value, index) {
      if (index.toString() === cronTabPosition || selectedDates.includes(index.toString())) {
        boundCrontab.weekly[index] = true;
      }
    });
    return boundCrontab.weekly;
  }

  private monthly(boundCrontab, cronTabPosition, selectedDates, firstValue, lastValue) {
    selectedDates = this.buildSelectDates(selectedDates, cronTabPosition, firstValue, lastValue);
    boundCrontab.monthly.forEach(function (value, index) {
      if ((index + 1).toString() === cronTabPosition || selectedDates.includes((index + 1).toString())) {
        boundCrontab.monthly[index] = true;
      }
    });
    return boundCrontab.monthly;
  }

  private yearly(boundCrontab, cronTabPosition, selectedDates, firstValue, lastValue) {
    selectedDates = this.buildSelectDates(selectedDates, cronTabPosition, firstValue, lastValue);
    boundCrontab.yearly.forEach(function (value, index) {
      if (index + 1 === parseInt(cronTabPosition) || selectedDates.includes((index + 1).toString())) {
        boundCrontab.yearly[index] = true;
      }
    });
    return boundCrontab.yearly;
  }

  private buildSelectDates(selectedDates, cronTabPosition, firstValue, lastValue) {
    if (cronTabPosition.indexOf("-") > -1 || cronTabPosition.indexOf(",") > -1) {
      let dashList = [];
      let commaList = [];
      if (cronTabPosition.indexOf("-") < cronTabPosition.indexOf(",") || cronTabPosition.indexOf(",") === -1) {
        dashList = cronTabPosition.split(",")[0].split("-");
        commaList = cronTabPosition.indexOf(",") !== -1 ? cronTabPosition.split(",")[1].split(",") : "";
      }
      if (cronTabPosition.indexOf("-") > cronTabPosition.indexOf(",") || cronTabPosition.indexOf("-") === -1) {
        commaList = cronTabPosition.split("-")[0].split(",");
        commaList.pop();
        dashList = cronTabPosition.substring(cronTabPosition.lastIndexOf(",") + 1, cronTabPosition.length).split("-");
      }
      selectedDates.push(...dashList);
      firstValue = dashList[0];
      lastValue = dashList[1];
      selectedDates.push(...commaList);
      for (let i = firstValue; i < lastValue; i++) {
        if (!selectedDates.includes(i)) selectedDates.push(i.toString());
      }
    }
    return selectedDates;
  }

  public dirty(isDirty = true) {
    this.dirtySubject.next(isDirty);
  }

  public forceSave(): Observable<boolean> {
    return this.dirtySubject.asObservable();
  }

  public missingScopes(isMissingScopes: boolean) {
    this.missingScopesSubject.next(isMissingScopes);
  }

  public missingScopesUpdate(): Observable<boolean> {
    return this.missingScopesSubject.asObservable();
  }

  public async getWorkflowScopes(workflowId: number) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<WorkflowScopes>((resolve) => {
      this.getWorkflowScopesRequest(workflowId).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getWorkflowScopesRequest(workflowId: number): Observable<WorkflowScopes> {
    return this.http
      .get<WorkflowScopes>(this.apiUrl + `/workflows/` + workflowId + `/workflow-scopes`, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<WorkflowScopes>("getWorkflowScopesRequest")));
  }

  public cleanActions(workflowActions: WorkflowAction[]) {
    for (let i = workflowActions.length - 1; i >= 0; i--) {
      if (workflowActions[i].isDeleted) {
        workflowActions.splice(i, 1);
      } else if (workflowActions[i] && workflowActions[i].conditions) {
        this.cleanConditions(workflowActions[i].conditions);
      } else if (workflowActions[i] && workflowActions[i].action?.parameters?.length) {
        for (const param of workflowActions[i].action.parameters) {
          if (param.object?.property?.type === "html") {
            param.parameterValue.value = param.parameterValue?.value
              .replace(/[\u2018\u2019]/g, "'")
              .replace(/[\u201C\u201D]/g, '"');
          }
        }
      }
    }
  }

  private cleanConditions(conditions: Condition[]) {
    for (let i = conditions.length - 1; i >= 0; i--) {
      if (conditions[i].isDeleted) {
        conditions.splice(i, 1);
        break;
      }

      if (conditions[i].conditions.length) {
        this.cleanConditions(conditions[i].conditions);
      }
    }
  }

  public async checkScopes(workflowId: number, workflowActions: WorkflowAction[]) {
    this.scopeCheck = await this.getMissingScopes(
      workflowId,
      workflowActions.map((action) => action.action.id)
    );
  }

  async addAction(workflowActions: WorkflowAction[], action: Action) {
    action = JSON.parse(JSON.stringify(action)); //removes the binding

    const workflowAction: WorkflowAction = {
      id: null,
      isOpen: true,
      isDeleted: false,
      action: action,
      conditions: [],
      email: null,
      report: null,
      subActions: [],
    };

    if (action.id == ACTIONS.CONDITIONAL) {
      const condition: Condition = {
        operator: {
          id: OPERATORS.AND,
          name: "AND",
          symbol: "AND",
        },
        isGroup: true,
        isDeleted: false,
        isExpandable: true,
        conditions: [],
      };
      workflowAction.conditions = [condition];
    } else if (this.reportActions.includes(action.id)) {
      //Report conditions
      workflowAction.report = action.report;
      const condition: Condition = {
        operator: {
          id: OPERATORS.AND,
          name: "AND",
          symbol: "AND",
        },
        isGroup: true,
        isDeleted: false,
        isExpandable: true,
        conditions: [],
      };
      workflowAction.conditions = [condition];
      const tags: Tag[] = [];
      const email: Email = {
        id: null,
        created: null,
        updated: null,
        toEmail: "",
        fromEmail: "",
        subject: "",
        body: "",
        tags: tags,
        isExpandable: false,
      };
      workflowAction.email = email;
    }

    workflowActions.push(workflowAction);
    this.dirty();
  }

  getDynamicParameters(parameters: Parameter[], validProperties?: ValidProperty[]): Parameter[] {
    if (!validProperties) return [];
    const dynamicParameters: Parameter[] = [];
    for (const validProperty of validProperties) {
      for (const parameter of parameters) {
        if (parameter.object.properties?.some((property) => property.id === validProperty.property.id)) {
          parameter.property = validProperty.property;
          dynamicParameters.push(parameter);
        }
      }
    }

    return dynamicParameters;
  }
}

export interface ReportSample {
  properties: string[];
  data: string[];
}

export interface WorkflowData {
  data: Workflow[];
}

export interface WorkflowExtension {
  nextRun?: string;
}

export interface WorkflowExecution {
  executionDate: string;
  amountOfExecution: number;
}

export interface Workflow extends WorkflowExtension {
  id: number;
  created: Date;
  updated: Date;
  customer: Customer;
  createdUser: WorkflowUser;
  updatedUser: WorkflowUser;
  name: string;
  folderName: string;
  description: string;
  activity: Activity;
  type: Type;
  enabled: boolean;
  event: Event;
  conditions: Condition[];
  actions: WorkflowAction[];
  scopes: Scope[];
  specificDateTime: string;
  scheduleCrontab: string;
  deleted: number;
  errored?: number;
  lastRun: string;
  processing: boolean;
  folder: number;
  timezone: string;
  selected?: boolean;
  folderId?: number;
  gResource?: string;
}

export interface CustomGoogleObjectData {
  key: string;
  subProperties: object[];
}

export interface Type {
  id: number;
  name: string;
  description: string;
}

export interface Activity {
  id: number;
  name: string;
  events: Event[];
  isDeleted: boolean;
  isExpanded: boolean;
}

export interface WorkflowUser {
  id: number;
  name: string;
  email: string;
}

export interface Event {
  id: number;
  name: string;
}

export interface Condition {
  property?: Property;
  subProperty?: string;
  subPropertyDisplay?: string;
  operator?: Operator;
  value?: string;
  isGroup: boolean;
  isDeleted: boolean;
  isExpandable: boolean;
  conditions: Condition[];
  parentId?: number;
  parameter?: Parameter;
  error?: Error;
}

export interface Property {
  id: number;
  activity: Activity;
  name: string;
  requiredForAction: boolean;
  operators: Operator[];
  type: string;
  selected: boolean;
  enums?: string[];
  selector: string;
  objectKey: string;
}

export interface Parameter {
  id: number;
  name: string;
  object;
  parameter: string;
  parameterValue: ParameterValue;
  suggestions: string[]; //used for front-end auto-complete
  property: Property; //used for front-end dynamic parameters
  validProperties: ValidProperty[];
  invalidHtml?: boolean;
  requiredForAction: boolean;
}

export interface ParameterValue {
  value: string;
  parameter: Parameter;
  property: Property;
  action?: Action;
  rawHTML?: boolean;
}

export interface Object {
  id: number;
  name: string;
  properties: Property[];
  property: Property;
}

export interface Operator {
  id: number;
  name: string;
  symbol: string;
}

export interface LogicalOpertator {
  id: number;
  name: string;
  symbol: string;
}

export interface FolderData {
  data: Folder[];
}

export interface Folder {
  id?: number;
  created?: string;
  updated?: string;
  name: string;
  folderName?: string;
  createdUserId: string;
  visible?: boolean;
}

export interface ActionGroup {
  id: number;
  name: string;
  icon: string;
  isDeleted: boolean;
  isOpen: boolean;
  actions: Action[];
}

export interface WorkflowAction {
  id: number;
  action: Action;
  isDeleted: boolean;
  isOpen: boolean;
  conditions: Condition[];
  email: Email;
  report: Report;
  delay?: Delay;
  subActions: WorkflowSubAction[];
  error?: Error;
}

export interface Report {
  id: number;
  name: string;
  object;
  exportTo: string;
  selectedProperties: Property[];
}

export interface Action {
  id: number;
  name: string;
  icon: string;
  report?: Report;
  description: string;
  checkApiEnabled?: boolean;
  properties: Property[];
  parameters: Parameter[];
  parameter?: Parameter;
  subActions?: SubAction[];
}

export interface Error {
  message: string;
  actionParameterId?: number;
}

export interface SubAction {
  id: number;
  actionId: number;
  subActionId: number;
  objectProperty;
  name: string;
  description: string;
}

export interface WorkflowSubAction {
  id: number;
  actionId: number;
  subActionId: number;
  value: boolean;
  action: Action;
}

export interface Scope {
  id?: number;
  scope: string;
}

export interface Email {
  id: number;
  created: Date;
  updated: Date;
  toEmail: string;
  fromEmail: string;
  ccEmail?: string;
  bccEmail?: string;
  subject: string;
  body: string;
  tags?: Tag[];
  attachReports?: boolean;
  isExpandable: boolean;
}

export interface Tag extends TagExtension {
  id?: number;
  created?: Date;
  tag: string;
  value?: string;
  parameter?: Parameter;
  property?: Property;
  visible?: boolean;
  copy?: boolean;
  subProperty?: string;
  isEditing?: boolean;
}

export interface ValidProperty {
  parameter: Parameter;
  property: Property;
}

export interface ScopeCheck {
  serviceAccount: ServiceAccount;
  missingScopes?: Scope[];
  missingAPIs?: API[];
}

export interface API {
  actionId: number;
  actionName: string;
  name: string;
  url: string;
  scope: string;
}

export interface WorkflowScopes {
  data: Scope[];
}

export interface AuditLogData {
  data: AuditLog[];
}

export interface AuditLog {
  id: number;
  created: Date;
  name: string;
  type: string;
  note?: string;
}

export interface AuditLogType {
  id: number;
  name: string;
}

export interface Delay {
  id?: number;
  seconds: number;
}

export enum ACTION_GROUPS {
  GMAIL = 1,
  WORKSPACE = 2,
  DRIVE = 3,
  CALENDAR = 4,
  LOGIC = 6,
  REPORTS = 7,
  STORAGE_PLUS = 8,
}

export enum ACTIONS {
  // KEEP IN SYNC WITH Automation_Actions
  SEND_EMAIL = 1,
  MANAGE_USERS = 2,
  MANAGE_GROUPS = 3,
  MANAGE_LICENSES = 4,
  PERMISSION_DELETE = 7,
  CONDITIONAL = 11,
  ADD_PERMISSIONS = 12,
  UPDATE_PERMISSIONS = 13,
  CHROME_OS_DEVICE_REPORT = 14,
  TRASH_FILE = 15,
  CHANGE_USERS_OU = 16,
  RESET_PASSWORD_NEXT_LOGIN = 17,
  DISABLE_ACCOUNT = 18,
  STORAGE_REPORT_USER_REPORT = 19,
  FLAG_USER = 20,
  CHANGE_DEVICE_OU = 23,
  TRANSFER_FILES_TO_SHARED_DRIVE = 24,
  COPY_FILES_TO_USER_DRIVE = 25,
  COPY_FOLDERS_TO_USER_DRIVE = 26,
  GIVE_CALENDAR_ACCESS = 27,
  CREATE_SHORTCUTS = 29,
  DISABLE_DEVICE = 30,
  REENABLE_DEVICE = 31,
  REMOVE_MEMBERS = 32,
  REMOVE_ALL_LICENSES = 33,
}

export enum OPERATORS {
  // KEEP IN SYNC WITH Automation_Operators
  IS = 1,
  IS_NOT = 2,
  CONTAINS = 3,
  DOES_NOT_CONTAIN = 4,
  BEFORE = 5,
  AFTER = 6,
  AND = 7,
  OR = 8,
}

export enum WORKFLOW_TYPES {
  // KEEP IN SYNC WITH Automation_Operators
  SUBSCRIPTION = 1,
  SCHEDULE = 2,
  SPECIFIC_DATE = 3,
}

export enum CONDITION_PARAMETERS {
  // KEEP IN SYNC WITH Automation_Activity_Event_Parameters --- these are special
  CHROME_OS_DEVICE = 1249,
  STORAGE_REPORT_USER = 1317,
}

export enum AUDIT_TYPE {
  // KEEP IN SYNC WITH Automation_Audit_Types
  CREATED = 1,
  EDITED = 2,
  DELETED = 3,
  EXECUTED = 4,
  COMPLETED = 5,
  PENDING = 6,
  ENABLED = 7,
  DISABLED = 8,
  ERRORED = 9,
  RESTORED = 10,
  COPIED = 11,
  REMOVED = 12,
  ACTIONS_UPDATED = 13,
}

export interface TagExtension {
  //** Required to create and delete tag in email action from report Report Type Action */
  propertyId?: number;
}

export enum ACTION_PARAMETERS {
  TAGS = 40,
  SET_DEFAULT_SIGNATURE = 41,
  TO = 47,
  FROM = 48,
  SUBJECT = 49,
  ATTACH_REPORTS = 50,
  BODY = 52,
  EMAIL_TAGS = 51,
  LICENSES = 54,
  CC = 57,
  BCC = 58,
}

export enum WORKFLOW_PROPERTIES {
  CUSTOM_ATTRIBUTE = 170,
}

export enum AUTOMATION_OBJECT_PROPERTIES {
  EMAIL = 1,
  FIRST_NAME = 2,
  GROUP_KEY = 19,
  GROUP_NAME = 160,
}

export interface Frequency {
  timeOfDay: string;
  Weekly: boolean[];
  Monthly: boolean[];
  Yearly: boolean[];
}
