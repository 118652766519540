<h2 mat-dialog-title>
	<span *ngIf="action == 'add'">Add Tenant Database</span>
	<span *ngIf="action == 'edit'">Edit Tenant Database</span>
</h2>
<form (ngSubmit)="processForm()" autocomplete="off">
	<div mat-dialog-content>
		<div class="row">
			<div class="col-12 col-lg-6 mt-2">
				<mat-form-field class="w-100">
					<mat-label> Host </mat-label>
					<input
						matInput
						[(ngModel)]="tenantDatabase.host"
						type="text"
						name="host"
						id="host"
						placeholder="Host *"
						autocomplete="off"
						required
					/>
				</mat-form-field>
			</div>
			<div class="col-12 col-lg-6 mt-2">
				<mat-form-field class="w-100">
					<mat-label> Port </mat-label>
					<input
						matInput
						[(ngModel)]="tenantDatabase.port"
						type="text"
						name="port"
						id="port"
						placeholder="Port *"
						autocomplete="off"
						required
					/>
				</mat-form-field>
			</div>
			<div class="col-12 col-lg-6 mt-2">
				<mat-form-field class="w-100">
					<mat-label> Database </mat-label>
					<input
						matInput
						[(ngModel)]="tenantDatabase.database"
						type="text"
						name="database"
						id="database"
						placeholder="Database *"
						autocomplete="off"
						required
					/>
				</mat-form-field>
			</div>
			<div class="col-12 col-lg-6 mt-2">
				<mat-form-field class="w-100">
					<mat-label> User </mat-label>
					<input
						matInput
						[(ngModel)]="tenantDatabase.user"
						type="text"
						name="user"
						id="User"
						placeholder="User *"
						autocomplete="off"
						required
					/>
				</mat-form-field>
			</div>
			<div class="col-12 col-lg-6 mt-2">
				<mat-form-field class="w-100">
					<mat-label> Password </mat-label>
					<input
						matInput
						[(ngModel)]="tenantDatabase.password"
						type="password"
						name="password"
						id="password"
						placeholder="Password *"
						autocomplete="off"
						[required]="this.action == 'add'"
					/>
				</mat-form-field>
			</div>
			<div class="col-12 mt-2 radio-wrapper">
				<mat-label>Connect with: </mat-label>
				<mat-radio-group [(ngModel)]="connectionType" name="connectionType" aria-label="Select an option" required>
					<mat-radio-button value="socketPath">Socket Path</mat-radio-button>
					<mat-radio-button value="ssl">SSL</mat-radio-button>
				</mat-radio-group>
			</div>
			<div class="col-12 mt-2" *ngIf="connectionType === 'socketPath'">
				<mat-form-field class="w-100">
					<mat-label> Socket Path </mat-label>
					<input
						matInput
						[(ngModel)]="tenantDatabase.socketPath"
						type="text"
						name="socketPath"
						id="socketPath"
						placeholder="Socket Path"
						autocomplete="off"
						required
					/>
				</mat-form-field>
			</div>
			<div class="col-12 mt-2" *ngIf="connectionType === 'ssl'">
				<div class="text-danger" *ngIf="this.action == 'edit'">
					For security, the SSL Key cannot be displayed, but can be updated (leave blank if not changing)
				</div>
				<mat-form-field class="w-100">
					<mat-label> SSL Key </mat-label>
					<textarea
						matInput
						[(ngModel)]="tenantDatabase.sslKey"
						type="text"
						name="sslKey"
						id="sslKey"
						placeholder="SSL Key *"
						autocomplete="off"
						[required]="this.action == 'add' && connectionType === 'ssl'"
					></textarea>
					<mat-hint align="start">Paste entirety of the SSL Key</mat-hint>
				</mat-form-field>
			</div>
			<div class="col-12 mt-2" *ngIf="connectionType === 'ssl'">
				<div class="text-danger" *ngIf="this.action == 'edit'">
					For security, the SSL Certificate cannot be displayed, but can be updated (leave blank if not changing)
				</div>
				<mat-form-field class="w-100">
					<mat-label> SSL Certificate </mat-label>
					<textarea
						matInput
						[(ngModel)]="tenantDatabase.sslCertificate"
						type="text"
						name="sslCertificate"
						id="sslCertificate"
						placeholder="SSL Certificate *"
						autocomplete="off"
						[required]="this.action == 'add' && connectionType === 'ssl'"
					></textarea>
					<mat-hint align="start">Paste entirety of the SSL Certificate</mat-hint>
				</mat-form-field>
			</div>
			<div class="col-12 mt-2" *ngIf="connectionType === 'ssl'">
				<div class="text-danger" *ngIf="this.action == 'edit'">
					For security, the SSL Certificate Authority cannot be displayed, but can be updated (leave blank if not changing)
				</div>
				<mat-form-field class="w-100">
					<mat-label> SSL Certificate Authority </mat-label>
					<textarea
						matInput
						[(ngModel)]="tenantDatabase.sslCertificateAuthority"
						type="text"
						name="sslCertificateAuthority"
						id="sslCertificateAuthority"
						placeholder="SSL Certificate Authority *"
						autocomplete="off"
						[required]="this.action == 'add' && connectionType === 'ssl'"
					></textarea>
					<mat-hint align="start">Paste entirety of the SSL Certificate Authority</mat-hint>
				</mat-form-field>
			</div>
		</div>
		<app-dialog-actions-container></app-dialog-actions-container>
	</div>
</form>
