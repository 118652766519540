import { Pipe, PipeTransform } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AuthService } from "src/app/services/admin-plus/auth.service";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { catchError } from "rxjs/operators";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: "safeHtml" })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Pipe({ name: "emptyValueDisplay" })
export class EmptyValueDisplay implements PipeTransform {
  transform(value, emptyDisplay = "--") {
    return !value || value.toString() == "" ? emptyDisplay : value;
  }
}

@Pipe({ name: "humanize" })
export class HumanizePipe implements PipeTransform {
  transform(value: string): string {
    if (typeof value !== "string") {
      return value;
    }
    value = value.split(/(?=[A-Z])/).join(" ");
    value = value[0].toUpperCase() + value.slice(1);
    return value;
  }
}

@Pipe({ name: "nameProperty" })
export class NamePropertyPipe implements PipeTransform {
  transform(value: string | object): string {
    if (typeof value !== "string" && typeof value !== "undefined") {
      return value["name"];
    }
    return value;
  }
}

class preallocatedObjectPool {
  poolArray: Record<string, string>[];
  index = 0;

  constructor() {
    this.poolArray = new Array(100).fill({ email: "", photoUrl: "" });
  }

  addElement(email: string, photoUrl: string) {
    if (this.index < this.poolArray.length - this.poolArray.length / 10) {
      this.poolArray[this.index] = { email: email, photoUrl: photoUrl };
      this.index++;
    } else {
      const tempVar: Record<string, string>[] = new Array(this.poolArray.length * 2).fill({ email: "", photoUrl: "" });
      for (let i = 0; i <= this.index; i++) {
        tempVar[i] = this.poolArray[i];
      }
      this.poolArray = tempVar;

      this.poolArray[this.index] = { email: email, photoUrl: photoUrl };
      this.index++;
    }
  }
  fetchElementUrl(email: string) {
    const idx = this.poolArray.findIndex((x) => x.email == email);
    if (idx != -1) return this.poolArray[idx].photoUrl;
    return -1;
  }
  resetFunction() {
    this.poolArray = new Array(100).fill({ email: "", photoUrl: "" });
  }
}

const UrlObjectPool: preallocatedObjectPool = new preallocatedObjectPool();
let photosAuthorized = true;

@Pipe({ name: "getProfilePicturePipe" })
export class GetProfilePicturePipe implements PipeTransform {
  constructor(private http: HttpClient, private loginService: LoginService, private authService: AuthService) {}

  async transform(email: string) {
    const re = /^\s*[\w\-+_]+(\.[\w\-+_]+)*@[\w\-+_]+\.[\w\-+_]+(\.[\w\-+_]+)*\s*$/;
    if (!photosAuthorized || email == null || !email.match(re)) {
      return "https://lh3.googleusercontent.com/a/default-user=s64";
    }
    const poolVal = UrlObjectPool.fetchElementUrl(email);
    if (poolVal != -1) return poolVal;

    const user = this.loginService.getUser();
    const userProfile: UserProfile = await new Promise((resolve) => {
      this.getProfile(email, user.authToken).subscribe((results: UserProfile) => {
        if (results) {
          resolve(results);
        } else {
          resolve({ id: "https://lh3.googleusercontent.com/a/default-user=s64" });
        }
      });
    });
    if (userProfile.error?.code == 403) photosAuthorized = false;
    if (userProfile.id == "https://lh3.googleusercontent.com/a/default-user=s64") {
      UrlObjectPool.addElement(email, userProfile.id);
      return userProfile.id;
    }

    const photo: profilePhoto = await new Promise((resolve) => {
      this.getPhotoFromId(userProfile.id, user.authToken).subscribe((results: profilePhoto) => {
        if (results) {
          if (!results.photos) {
            results.photos = [{ url: "https://lh3.googleusercontent.com/a/default-user=s64" }];
          }
          resolve(results);
        } else {
          resolve({ photos: [{ url: "https://lh3.googleusercontent.com/a/default-user=s64" }] });
        }
      });
    });
    if (photo.error?.code == 403) photosAuthorized = false;
    UrlObjectPool.addElement(email, photo.photos[0].url);

    return photo.photos[0].url;
  }

  getProfile(email: string, authToken: string) {
    const url = "https://admin.googleapis.com/admin/directory/v1/users/" + email;

    return this.http
      .get(url, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + authToken),
      })
      .pipe(catchError(this.authService.handleAPIError("getPhoto", true)));
  }

  getPhotoFromId(userId: string, authToken: string) {
    const url = `https://people.googleapis.com/v1/people/${userId}?fields=photos`;
    return this.http
      .get(url, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + authToken),
        params: new HttpParams().set("personFields", "photos"),
      })
      .pipe(catchError(this.authService.handleAPIError("GetPhotoFromId", true)));
  }
}

interface profilePhoto {
  etag?: string;
  photos: photoDetail[];
  resourceName?: string;
  error?: any;
}
interface photoDetail {
  default?: boolean;
  metadata?: unknown;
  url: string;
}
interface UserProfile {
  id: string;
  primaryEmail?: string;
  lastLogin?: string;
  orUnitPath?: string;
  error?: any;
}
