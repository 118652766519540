import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { AuthService } from "src/app/services/admin-plus/auth.service";

@Component({
  selector: "app-content-right",
  templateUrl: "./content-right.component.html",
})
export class ContentRightComponent {
  constructor(public loginService: LoginService, public authService: AuthService, private router: Router) {}

  toggleHelper() {
    window["Beacon"]("toggle");
  }

  goToJobs() {
    this.router.navigate(["reports/storage/jobs"]);
  }
}
