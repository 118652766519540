import { SelectionModel } from "@angular/cdk/collections";
import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { FilterType } from "src/app/services/utilities/filters.service";
import { OrganizationalUnit, OrganizationalUnitData, ReportsService } from "src/app/services/google/reports.service";
import { merge, of as observableOf } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: "app-storage-report-organizational-units-table",
  templateUrl: "./storage-report-organizational-units-table.component.html",
  styleUrls: ["./storage-report-organizational-units-table.component.scss"],
})
export class StorageReportOrganizationalUnitsTableComponent implements OnInit {
  @Input() limit = false;
  public filterTypeValue: FilterType = FilterType.ORGANIZATIONAL_UNIT;
  public displayedColumns: string[] = ["name", "totalStorage"];
  public dataSource = new MatTableDataSource<OrganizationalUnit>();
  public ous: OrganizationalUnit[];
  public maxResults = 10;
  public page = 1;
  public offset = 0;
  private getCount = true;
  public totalCount = 0;
  public nextButton = false;
  public nextButtonDis = false;
  public loading = false;

  // filtered search stuff
  private usageSizeSearch = "0";
  private nameSearch = "";
  public activeOrderBy = "totalStorage";
  public direction = "desc";
  public orderBy = "givenName";
  public sortOrder = "SORT_ORDER_UNDEFINED";

  public separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild(MatSort) sort: MatSort;
  public data = {
    usageSizeSearch: this.usageSizeSearch,
    ouSearch: this.nameSearch,
    page: this.page,
    offset: this.offset,
    filtersCount: 0,
  };

  public selection = new SelectionModel<OrganizationalUnit>(true, []);

  constructor(private navService: NavService, private reportService: ReportsService, private router: Router) {}

  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  public async loadData(arrow = "") {
    this.navService.loading.next(true);
    await this.getOus(arrow);
    this.navService.loading.next(false);
  }

  public async getOus(arrow = "") {
    this.loading = true;
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.navService.loading.next(true);
          this.selection.clear();
          this.nextButton = true;
          this.getCount = !arrow;
          return this.reportService.getFilteredOus(
            this.maxResults,
            this.activeOrderBy,
            this.direction,
            this.nameSearch,
            this.usageSizeSearch,
            this.offset,
            this.getCount,
            this.limit
          );
        }),
        map((response: OrganizationalUnitData) => {
          this.loading = false;
          if (response) {
            this.ous = response.data;
            this.dataSource = new MatTableDataSource(this.ous);
            this.dataSource.sort = this.sort;
            this.totalCount = this.getCount ? (response["count"] ? response["count"] : 0) : this.totalCount;
            if (this.dataSource["filteredData"].length < 10) {
              this.nextButtonDis = true;
            }
            if (this.dataSource["filteredData"].length > 10) {
              this.nextButtonDis = false;
            }
            if (arrow === "next" && this.dataSource["filteredData"].length + this.offset === this.totalCount) {
              this.nextButtonDis = true;
            }
            if (arrow === "next" && this.dataSource["filteredData"].length + this.offset < this.totalCount) {
              this.nextButtonDis = false;
            }
            if (this.dataSource["filteredData"].length + this.offset < this.totalCount) {
              this.nextButtonDis = false;
            }
            if (this.dataSource["filteredData"].length + this.offset === this.totalCount) {
              this.nextButtonDis = true;
            }
            if (arrow === "prev" && this.totalCount > 0) {
              this.nextButtonDis = false;
            }
            if (this.totalCount === this.maxResults) {
              this.nextButtonDis = true;
            }
          }
          this.dataSource.sortingDataAccessor = (data: unknown, sortHeaderId: string) => {
            if (typeof data[sortHeaderId] === "string") {
              return data[sortHeaderId].toLowerCase();
            }
            return data[sortHeaderId];
          };
          this.nextButton = false;
          this.navService.loading.next(false);
          return this.ous;
        }),
        catchError(() => {
          this.loading = false;
          return observableOf([]);
        })
      )
      .subscribe();
  }

  receiveData($event) {
    this.data = $event;
    this.usageSizeSearch = this.data.usageSizeSearch;
    this.nameSearch = this.data.ouSearch;
    this.page = this.data.page;
    this.offset = this.data.offset;
    this.loadData();
  }

  public changeSort(sort: Sort) {
    if (sort.direction === "") {
      this.direction = "desc";
    } else {
      this.activeOrderBy = sort.active;
      this.direction = sort.direction;
    }
    this.loadData();
  }

  public setPager(data) {
    this.page = data.page;
    this.offset = data.offset;
    this.maxResults = data.maxResults ? data.maxResults : this.maxResults;
    this.loadData(data.arrow);
  }

  routeToOUUsers(ouId: number) {
    this.router.navigate(["reports/storage/organizational-units/" + ouId + "/users"]);
  }
}
