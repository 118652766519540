import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-page-header",
  templateUrl: "./page-header.component.html",
  styleUrls: ["./page-header.component.scss"],
})
export class PageHeaderComponent {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() class: string;
  @Input() options = [];
  @Output() optionSelected = new EventEmitter();
}
