import { Customer } from "../../services/admin-plus/customer.service";
import { UserAction } from "../admin-plus/base";
import { Room } from "./room";
export interface IBuilding {
	id: number;
	customer: Customer;
	createdBy: UserAction;
	updatedBy: UserAction;
	name: string;
	rooms: Room[];
	room?: Room;
	imported: boolean;
}

export class Building implements IBuilding {
	id: number;
	customer: Customer;
	createdBy: UserAction;
	updatedBy: UserAction;
	name: string;
	rooms: Room[];
	room?: Room;
	imported = false;

	constructor(properties) {
		Object.assign(this, properties);
	}
}
