import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { Delegate } from "src/app/models/ticket-plus/delegate";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { DelegateService } from "src/app/services/ticket-plus/delegate.service";
import { Payload, UtilityService } from "src/app/services/utilities/utility.service";

@Component({
  selector: "app-delegate-dialog",
  templateUrl: "./delegate-dialog.component.html",
  styleUrls: ["./delegate-dialog.component.css"],
})
export class DelegateDialogComponent implements OnInit {
  action = "Add";
  delegate = new Delegate({ ou: "/" });
  loading = false;

  constructor(
    private dialogRef: MatDialogRef<DelegateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private notifyService: NotificationService,
    private utilityService: UtilityService,
    private navService: NavService,
    private delegateService: DelegateService
  ) {
    this.action = data.action;
  }

  ngOnInit(): void {
    this.action = this.utilityService.capitalizeFirstLetter(this.data.action);
    if (this.data.delegate) {
      this.delegate = Object.assign({}, this.data.delegate);
    }
  }

  onKey(ou) {
    this.delegate.ou = ou;
  }

  receiveEmail(event) {
    if (event.deleted) {
      this.delegate.groupEmail = null;
    } else {
      this.delegate.groupEmail = event.email;
    }
  }

  processForm() {
    if (this.delegate.name?.trim()) {
      this.updateDelegate(this.delegate);
    } else {
      this.notifyService.notify("Delegate name is required");
    }
  }

  async updateDelegate(delegate) {
    this.updateLoadingStatus(true);
    let closeModal = true;

    if (delegate) {
      if (this.action.toLowerCase() == "delete") {
        const payload: Payload = await this.delegateService.deleteDelegate(delegate);
        if (payload.count) {
          this.notifyService.notify("Delegate deleted");
        }
      } else {
        // Check for dupes
        const delegateIsDupe = await this.checkIfDelegateIsDupe(delegate);

        if (delegateIsDupe) {
          this.notifyService.notify("This delegate record already exists");
          closeModal = false;
        } else {
          const results: Delegate = await this.delegateService.createUpdateDelegate(delegate);
          if (results) {
            this.notifyService.notify(`Delegate ${delegate.id ? "updated" : "created"}`);
            delegate = results;
          }
        }
      }

      if (closeModal) this.dialogRef.close(true);
    }

    this.updateLoadingStatus(false);
  }

  async checkIfDelegateIsDupe(delegate) {
    let dupeExists = false;

    const potentialDupes = await this.delegateService.getDelegates({
      groupEmail: delegate.groupEmail,
      ou: delegate.ou,
    });

    // Ensure emails match exactly (query search filter uses 'LIKE') and that it isn't the delegate that's being updated
    potentialDupes?.data?.forEach((potentialDupe) => {
      const matchingEmail = potentialDupe.groupEmail?.toLowerCase() === delegate?.groupEmail?.toLowerCase();
      const matchingOu = potentialDupe.ou?.toLowerCase() === delegate?.ou?.toLowerCase();
      const isSameDelegate = potentialDupe.id === delegate.id;
      if (matchingEmail && matchingOu && !isSameDelegate) {
        dupeExists = true;
      }
    });

    return dupeExists;
  }

  updateLoadingStatus(val: boolean) {
    this.navService.loading.next(val);
    this.loading = val;
  }
}
