<h2 mat-dialog-title>{{ action }} Delegate</h2>
<form (ngSubmit)="processForm()" autocomplete="off">
  <div mat-dialog-content>
    <div class="row">
      <div class="col-12 mt-12" *ngIf="action == 'Delete'">
        <h4>Are you sure you want to delete {{ delegate.name }}?</h4>
      </div>
      <div class="col-12 mt-2" *ngIf="action != 'Delete'">
        <mat-form-field class="w-100">
          <mat-label>Name</mat-label>
          <input type="text" matInput [(ngModel)]="delegate.name" name="delegate" maxlength="45" required />
        </mat-form-field>
        <app-organizational-unit
          [path]="delegate.ou"
          (onPathChanged)="onKey($event)"
        ></app-organizational-unit>
        <app-email
          (selectedEmail)="receiveEmail($event)"
          fieldLabel="Group Email"
          placeholder="Search for group email..."
          [email]="delegate.groupEmail"
          [type]="2"
          [multiple]="false"
          [custom]="false"
        ></app-email>
      </div>
    </div>
    <div mat-dialog-actions>  
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button
        type="submit"
        mat-stroked-button
        class="ml-2"
        [class]="action == 'Delete' ? 'danger' : 'success'"
        [disabled]="loading"> {{ action }} Delegate</button>
    </div>
  </div>
</form>
