<div class="row my-auto text-break">
  <div class="col-xs-12 col-sm-10 mt-2">
    <div class="text-break">
      <mat-vertical-stepper>
        <mat-step [stepControl]="complete">
          <ng-template matStepLabel>
            <h3 class="mt-3">New Bucket</h3>
          </ng-template>
          <p>1. Log into Google Cloud Platform</p>
          <p>2. Go to Cloud Storage Browser Under Storage</p>
          <p>3. Create a New Bucket</p>
          <span>
            <a href="https://ap-assets.trafera.app/kit-central/images/bucket-instruction/goToBuckets.gif" target="_blank">
              <img
                src="https://ap-assets.trafera.app/kit-central/images/bucket-instruction/goToBuckets.gif"
                style="padding-right: 2mm; margin-top: 5mm; margin-bottom: 3mm"
                width="75%"
              />
            </a>
          </span>
          <div class="row">
            <button mat-stroked-button matStepperNext>Next</button>
          </div>
        </mat-step>
        <mat-step [stepControl]="complete">
          <ng-template matStepLabel>
            <h3 class="mt-3">Creating Bucket</h3>
          </ng-template>
          <div class="row pl-1">
            <span>
              <p>4. Go through each sections and choose the setting that best fit your organization</p>
              <p>5. Hit the create button to save choices</p>
              <div class="ml-2" style="margin-bottom: 3mm">
                <a
                  href="https://ap-assets.trafera.app/kit-central/images/bucket-instruction/createBucket.gif"
                  target="_blank"
                >
                  <img
                    src="https://ap-assets.trafera.app/kit-central/images/bucket-instruction/createBucket.gif"
                    width="75%"
                  />
                </a>
              </div>
            </span>
          </div>
          <button mat-stroked-button matStepperPrevious>Back</button>
          <button class="ml-2" mat-stroked-button matStepperNext>Next</button>
        </mat-step>
        <mat-step [stepControl]="complete">
          <ng-template matStepLabel>
            <h3 class="mt-3">Setting Permissions</h3>
          </ng-template>
          <p>6. Return back to the browser page</p>
          <p>7. Click on the three dots on the far right of the screen</p>
          <div class="ml-3">
            <p>a. Select edit bucket permissions</p>
            <p>b. Select add member</p>
            <div class="ml-3">
              <p>i. <b>Storage Object Viewer</b> - allows member to read objects stored in a bucket</p>
              <p>
                ii.<b>Storage Object Admin</b> - allows member needs full control of objects in the bucket (but not control
                of the bucket itself)
              </p>
              <p>iii.<b>All users sending files will need to have write access</b></p>
            </div>
          </div>
          <a href="https://ap-assets.trafera.app/kit-central/images/bucket-instruction/assignPermissions.gif" target="_blank">
            <img
              src="https://ap-assets.trafera.app/kit-central/images/bucket-instruction/assignPermissions.gif"
              width="50%"
              height="50%"
              style="margin-bottom: 5mm"
            />
          </a>
          <div class="row pt-1">
            <button mat-stroked-button matStepperPrevious>Back</button>
          </div>
        </mat-step>
      </mat-vertical-stepper>
    </div>
  </div>
</div>
