import { Component, OnInit } from "@angular/core";
import { AuditLogsService } from "src/app/services/admin-plus/audit-logs.service";
import { AuditLog } from "src/app/models/admin-plus/audit-logs";
import { MatTableDataSource } from "@angular/material/table";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { Payload } from "src/app/services/utilities/utility.service";
import { merge, of as observableOf } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { Sort } from "@angular/material/sort";
import { User } from "src/app/users/users";
import { DialogDetailsComponent } from "../layout/dialogs/dialog-details/dialog-details.component";
import { MatDialog } from "@angular/material/dialog";
import { FilterType, FiltersService } from "src/app/services/utilities/filters.service";
import { AdminPlusService } from "../services/admin-plus/admin-plus.service";

@Component({
	selector: "app-audit-logs-table",
	templateUrl: "./audit-logs-table.component.html",
})
export class AuditLogsTableComponent implements OnInit {
	current_user: User;
	dataSource = new MatTableDataSource<AuditLog>();
	displayedColumns: string[] = ["created", "auditType", "object", "methodExact", "createdBy", "data"];
	public dataType = [
		{ name: "Assets", value: "asset" },
		{ name: "Automation", value: "workflow" },
		{ name: "Storage", value: "storage" },
		{ name: "Ticket", value: "ticket" },
	];
	page = 1;
	totalCount = 0;
	public selectedModule;
	params = {
		limit: 10,
		offset: 0,
		orderBy: "created",
		sort: "DESC",
		createdAfter: null,
		createdBefore: null,
		createdBy: null,
		objectExact: null,
		methodExact: null,
		auditTypeExact: null,
		objectId: null,
		data: null,
		selectedModule: null,
	};

	nextButton = false;
	nextButtonDis = false;
	loading = false;
	filtersCount = 0;

	filterTypeValue: FilterType = FilterType.LOGS;

	constructor(
		private auditLogsService: AuditLogsService,
		private navService: NavService,
		private dialog: MatDialog,
		private filtersService: FiltersService,
		private adminPlusService: AdminPlusService
	) {
		this.adminPlusService.breadCrumbLinks.push({
			link: null,
			text: "Audit Logs",
			alt: "Audit Logs",
		});
		this.selectedModule = this.auditLogsService.selectedDataType;
		this.params.selectedModule = this.selectedModule;
	}

	async ngOnInit() {
		const hasNavFilters = await this.filtersService.checkNavFilters(this.filterTypeValue);
		if (!hasNavFilters) this.loadData();
	}

	async loadData(arrow = "") {
		this.navService.loading.next(true);
		await this.getAuditLogs(arrow);
		this.navService.loading.next(false);
	}

	async getAuditLogs(arrow = "") {
		this.loading = true;
		merge()
			.pipe(
				startWith({}),
				switchMap(() => {
					this.navService.loading.next(true);
					this.nextButton = true;
					return this.auditLogsService.getAuditLogs(this.params);
				}),
				map((payload) => {
					this.loading = false;
					if (payload) {
						this.dataSource = new MatTableDataSource(payload.data);
						this.pagination(arrow, payload);
					}
					this.navService.loading.next(false);
					return payload;
				}),
				catchError(() => {
					this.loading = false;
					this.navService.loading.next(false);
					return observableOf([]);
				})
			)
			.subscribe((auditLogs) => {
				if (auditLogs) {
					this.navService.loading.next(false);
					return auditLogs;
				}
			});
	}

	pagination(arrow: string, payload: Payload) {
		this.totalCount = payload.count;
		if (this.dataSource.filteredData.length < 10) {
			this.nextButtonDis = true;
		}
		if (this.dataSource.filteredData.length > 10) {
			this.nextButtonDis = false;
		}
		if (arrow === "next" && this.dataSource.filteredData.length + this.params.offset === this.totalCount) {
			this.nextButtonDis = true;
		}
		if (arrow === "next" && this.dataSource.filteredData.length + this.params.offset < this.totalCount) {
			this.nextButtonDis = false;
		}
		if (this.dataSource.filteredData.length + this.params.offset < this.totalCount) {
			this.nextButtonDis = false;
		}
		if (this.dataSource.filteredData.length + this.params.offset === this.totalCount) {
			this.nextButtonDis = true;
		}
		if (arrow === "prev" && this.totalCount > 0) {
			this.nextButtonDis = false;
		}
		if (this.totalCount === this.params.limit) {
			this.nextButtonDis = true;
		}
		this.nextButton = false;
	}

	receiveData($event) {
		const logTypes = [
			"ticket",
			"attachments",
			"comments",
			"agents",
			"rules",
			"statuses",
			"categories",
			"teams",
			"priorities",
			"delegates",
			"channels",
			"mentions",
		];
		this.page = $event.page;
		this.params.offset = $event.offset;
		const data = $event.logData ? $event.logData : null;
		if (data && logTypes.includes(Object.keys(JSON.parse(data))[0].split(".")[0])) {
			this.params.data = data.replace(Object.keys(JSON.parse(data))[0].split(".")[0] + ".", "");
			this.params.objectExact = Object.keys(JSON.parse(data))[0].split(".")[0];
		} else {
			this.params.data = data;
			this.params.objectExact = $event.object ? $event.object : null;
		}
		this.params.createdAfter = $event?.createdAfterSearch ? $event?.createdAfterSearch : null;
		this.params.createdBefore = $event?.createdBeforeSearch ? $event?.createdBeforeSearch : null;
		this.params.createdBy = $event.createdBy ? $event.createdBy : null;
		this.params.auditTypeExact = $event.auditType ? $event.auditType : null;
		this.params.objectId = $event.objectId ? $event.objectId : null;
		this.filtersCount = $event.filtersCount;
		this.params.selectedModule = $event.selectedModule;
		switch ($event.method) {
			case "Created":
				this.params.methodExact = "POST";
				break;
			case "Updated":
				this.params.methodExact = "PUT";
				break;
			case "Deleted":
				this.params.methodExact = "DELETE";
				break;
			default:
				this.params.methodExact = $event.method;
		}
		this.loadData();
	}

	setPager(data) {
		this.page = data.page;
		this.params.offset = data.offset;
		this.params.limit = data.maxResults ? data.maxResults : this.params.limit;
		this.loadData(data.arrow);
	}

	changeSort(sort: Sort) {
		if (sort.direction === "") {
			this.params.sort = "DESC";
			this.params.orderBy = "created";
		} else {
			this.params.sort = sort.direction.toUpperCase();
			this.params.orderBy = sort.active;
		}
		this.loadData();
	}

	viewDetails(data: string) {
		this.dialog.open(DialogDetailsComponent, {
			width: "800px",
			data: data,
		});
	}

	changeSelectedModule(module: string) {
		this.params.selectedModule = module;
		this.auditLogsService.selectedDataType = module;
		this.loadData();
	}
}
