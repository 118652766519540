import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
})
export class ConfirmationDialogComponent {
  public title: string;
  public message: string;
  public btnOkText: string;
  public btnCancelText = "Cancel";
  public buttonColor = "";

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
  ) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.btnOkText = data.btnOkText;
    this.btnCancelText = data.btnCancelText;
    this.buttonColor = data.buttonColor;
  }

  public onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }
}

export class ConfirmDialogModel {
  constructor(
    public title: string,
    public message: string,
    public btnOkText: string,
    public btnCancelText = "Cancel",
    public buttonColor = ""
  ) {}
}
