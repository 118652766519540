import { Component } from "@angular/core";

@Component({
  selector: "app-create-bucket-instruction",
  templateUrl: "./create-bucket-instruction.component.html",
})
export class CreateBucketInstructionComponent {
  complete() {
    //
  }
}
