<mat-form-field class="w-100 h-100">
  <mat-chip-grid class="h-100" #chipList aria-label="Filter Selection">
    <mat-chip-row
      *ngFor="let filter of selectedFilters"
      [removable]="removable"
      (removed)="removeFilter(filter)"
      (click)="editChip(filter)"
    >
      {{ 
        filter.name + ": " + filter.additionalText + " " + 
        (filter.search.name ? filter.search.name : filter.search) + (filter.postfix ? filter.postfix : "") 
      }}
      <mat-icon [selectable]="selectable" matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip-row>
    <input
      type="text"
      #filtersInput
      placeholder="Add filter"
      aria-label="Filters"
      matInput
      [formControl]="filterControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      (focus)="onFilterFocus()"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="showFilterDialog($event.option.value)">
    <mat-option *ngFor="let filter of filteredFilters | async" [value]="filter">
      {{ filter.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
