import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { CustomerService } from "src/app/services/admin-plus/customer.service";
import { NotificationService } from "src/app/services/utilities/notification.service";
import {
  ConfirmDialogModel,
  ConfirmationDialogComponent,
} from "src/app/layout/dialogs/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-enable-google-cloud-storage",
  templateUrl: "./enable-google-cloud-storage.component.html",
  styleUrls: ["./enable-google-cloud-storage.component.scss"],
})
export class EnableGoogleCloudStorageComponent {
  public bucketName = "";
  public account;

  constructor(
    private navService: NavService,
    private customerService: CustomerService,
    private notifyService: NotificationService,
    private dialogRef: MatDialogRef<EnableGoogleCloudStorageComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  async processForm() {
    this.navService.loading.next(true);
    if (this.bucketName === "") {
      this.notifyService.notify("Bucket name is required");
    } else {
      const response = await this.customerService.enableGoogleCloudStorage(this.bucketName, true);
      if (!response["missingScopes"]) {
        await this.updateLocalStorageCustomerBucket(response);
        this.notifyService.notify("You successfully enabled Google Cloud Storage for Storage");
        this.dialogRef.close(response);
      } else {
        this.dialogRef.close(response);
        this.missingScopes(response["missingScopes"]);
      }
    }
    this.navService.loading.next(false);
  }

  async missingScopes(missingScopes) {
    let message = `You are missing scopes that are required for enabling storage buckets. The missing scopes are: `;
    for (let i = 0; i < missingScopes.length; i++) {
      if (i == missingScopes.length - 1) {
        message += `${missingScopes[i]}`;
      } else {
        message += `${missingScopes[i]}, `;
      }
    }
    const btnCloseText = `Close`;
    const dialogData = new ConfirmDialogModel("Missing Scopes", message, btnCloseText, "");

    this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      width: "500px",
      data: dialogData,
    });
  }

  async updateLocalStorageCustomerBucket(response) {
    this.customerService.customer["gcsBucket"] = response["gcsBucket"];
    this.customerService.customer["gcsEnabled"] = response["gcsEnabled"];
    localStorage.setItem("customer", JSON.stringify(this.customerService.customer));
  }
}
