<div class="row">
  <div class="col-12 col-lg-4 col-xl-3 mt-2" *ngIf="!removeAdditionalFilter">
    <mat-form-field class="w-100">
      <mat-label>Actions</mat-label>
      <mat-select [formControl]="actions">
        <mat-option value="" selected></mat-option>
        <mat-option value="flag" [disabled]="selection.selected.length == 0 || validFlag()"
          >Flag for Excessive Storage ...</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-12 col-lg-4 col-xl-6 mt-2">
    <app-filters [filterType]="filterTypeValue" (dataEvent)="receiveData($event)"></app-filters>
  </div>

  <div class="col-12 col-lg-4 col-xl-3 mt-2" *ngIf="!removeAdditionalFilter">
    <mat-form-field class="w-100">
      <mat-label>Columns</mat-label>
      <mat-select [formControl]="columns" multiple>
        <mat-option *ngFor="let column of columnsList" [value]="column" (click)="tableColumnChange(column)">
          {{
            column == "nonTrashedStorage"
              ? "Drive"
              : column == "trashedStorage"
              ? "Drive Trashed"
              : column == "previousTrashed"
              ? "Flagged Drive Trashed"
              : "Flagged Total Storage"
          }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" matSort matSortActive="totalStorage" matSortDirection="desc">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              color="primary"
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && getPageData()"
              [indeterminate]="selection.hasValue() && !getPageData()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              color="primary"
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="fullName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>User</th>
          <td
            [ngClass]="row.deleted || row.suspended ? 'text-muted' : ''"
            (click)="routeToUser(row.id)"
            mat-cell
            *matCellDef="let row"
          >
            <div class="d-flex align-items-center">
              <img
                referrerpolicy="no-referrer"
                class="img-fluid rounded-circle"
                width="30"
                height="30"
                src="{{
                  !(row.email | getProfilePicturePipe | async)
                    ? 'https://lh3.googleusercontent.com/a/default-user=s64'
                    : (row.email | getProfilePicturePipe | async)
                }}"
                alt="Avatar"
              />
              <div class="ml-2">
                <div class="d-flex">
                  {{ row.firstName + " " + row.lastName }}
                  <mat-icon
                    *ngIf="row.flagId"
                    style="transform: scale(1)"
                    matTooltip="This user has been flagged for excessive storage usage. Total Storage: ({{
                      row.previousTotal / 1024 | number : '1.1-2'
                    }}), Trashed Storage: ({{ row.previousTrashed / 1024 | number : '1.1-2' }})."
                    inline
                    class="ml-1 text-danger"
                  >
                    feedback
                  </mat-icon>
                  <span class="pl-1" *ngIf="row.suspended">(Suspended)</span>
                  <span class="pl-1" *ngIf="row.deleted">(Deleted)</span>
                </div>

                <small class="text-muted">
                  {{ row.email }}
                </small>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="totalStorage">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Total (GB)</th>
          <td
            (click)="routeToUser(row.id)"
            [ngClass]="row.deleted || row.suspended ? 'text-wrap text-muted' : 'text-wrap'"
            mat-cell
            *matCellDef="let row"
          >
            <strong>{{ row.totalStorage / 1024 | number : "1.1-2" }}</strong>
          </td>
        </ng-container>
        <ng-container matColumnDef="driveTotal">
          <th mat-header-cell class="text-wrap bg-light" mat-sort-header *matHeaderCellDef>Drive Total (GB)</th>
          <td
            (click)="routeToUser(row.id)"
            [ngClass]="row.deleted || row.suspended ? 'text-wrap text-muted' : 'text-wrap bg-light'"
            mat-cell
            *matCellDef="let row"
          >
            {{ row.driveTotal / 1024 | number : "1.1-2" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="nonTrashedStorage">
          <th mat-header-cell class="text-wrap bg-light" mat-sort-header *matHeaderCellDef>Drive (GB)</th>
          <td
            (click)="routeToUser(row.id)"
            [ngClass]="row.deleted || row.suspended ? 'text-wrap text-muted' : 'text-wrap bg-light'"
            mat-cell
            *matCellDef="let row"
          >
            {{ (row.driveTotal - row.trashedStorage) / 1024 | number : "1.1-2" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="trashedStorage">
          <th class="text-wrap bg-light" mat-header-cell mat-sort-header *matHeaderCellDef>Drive Trashed (GB)</th>
          <td
            (click)="routeToUser(row.id)"
            [ngClass]="row.deleted || row.suspended ? 'text-wrap text-muted' : 'text-wrap bg-light'"
            mat-cell
            *matCellDef="let row"
          >
            {{ row.trashedStorage / 1024 | number : "1.1-2" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="gmailTotal">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Gmail (GB)</th>
          <td
            (click)="routeToUser(row.id)"
            [ngClass]="row.deleted || row.suspended ? 'text-wrap text-muted' : 'text-wrap'"
            mat-cell
            *matCellDef="let row"
          >
            <span class="text-wrap">{{ row.gmailTotal / 1024 | number : "1.1-2" }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="photosTotal">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Photos (GB)</th>
          <td
            (click)="routeToUser(row.id)"
            [ngClass]="row.deleted || row.suspended ? 'text-wrap text-muted' : 'text-wrap'"
            mat-cell
            *matCellDef="let row"
          >
            {{ row.photosTotal / 1024 | number : "1.1-2" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="previousTotal">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Flagged Total Storage (GB)</th>
          <td
            (click)="routeToUser(row.id)"
            [ngClass]="row.deleted || row.suspended ? 'text-wrap text-muted' : 'text-wrap'"
            mat-cell
            *matCellDef="let row"
          >
            {{ row.previousTotal / 1024 | number : "1.1-2" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="previousTrashed">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Flagged Drive Trashed (GB)</th>
          <td
            (click)="routeToUser(row.id)"
            [ngClass]="row.deleted || row.suspended ? 'text-wrap text-muted' : 'text-wrap'"
            mat-cell
            *matCellDef="let row"
          >
            {{ row.previousTrashed / 1024 | number : "1.1-2" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="OU">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Organizational Unit</th>
          <td
            (click)="routeToUser(row.id)"
            [ngClass]="row.deleted || row.suspended ? 'text-wrap text-muted' : 'text-wrap'"
            mat-cell
            *matCellDef="let row"
          >
            <span *ngIf="row.OU !== 'undefined'">{{ row.OU }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="options">
          <th class="text-right" mat-header-cell *matHeaderCellDef></th>
          <td [ngClass]="row.deleted || row.suspended ? 'text-wrap text-muted' : ''" *matCellDef="let row">
            <button mat-icon-button *ngIf="row.flagId !== null" mat-icon-button [matMenuTriggerFor]="menu">
              <span class="material-icons mat-icon"> settings </span>
            </button>

            <mat-menu #menu="matMenu">
              <button
                class="d-flex align-items-center"
                mat-menu-item
                (click)="undoFlag(row.firstName, row.lastName, row.email)"
              >
                <mat-icon class="mr-2"> undo </mat-icon>
                Undo Flag
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr class="mat-row" app-empty-dataset-alert [loading]="loading" [filterCount]="data.filtersCount" *matNoDataRow></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row class="clickable-row" *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <app-table-footer
      [dataSource]="dataSource"
      [maxResults]="maxResults"
      [nextButton]="nextButton"
      [nextButtonDis]="nextButtonDis"
      [offset]="offset"
      [page]="page"
      [total]="totalUserCount"
      (pager)="setPager($event)"
    ></app-table-footer>
  </div>
</div>
