<h2 mat-dialog-title>
  <i style="font-size: 20px" class="faa-shake material-icons material-icon-align mr-2">warning</i>
  Admin+ by Trafera IT End-User Agreement
</h2>
<div mat-dialog-content class="text-center">
  <p class="pb-3">
    By checking <strong>I Agree</strong> below, you are accepting the <strong>Admin+ End-User Agreement</strong>.
  </p>
  <app-eua></app-eua>

  <div class="col-12 mt-2 text-center">
    <mat-checkbox color="primary" [(ngModel)]="agree" name="agree" id="agree"> I Agree </mat-checkbox>
  </div>
  <div *ngIf="showError" class="alert alert-danger">
    Unable to Confirm End-User Agreement - please contact google.support&#64;trafera.com for further assistance.
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close [disabled]="lockButtons" (click)="onDismiss()">Cancel</button>
    <button mat-stroked-button class="success ml-2" (click)="onConfirm()" [disabled]="!agree || lockButtons">
      <span>Confirm</span>
    </button>
  </div>
</div>
