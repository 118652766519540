import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { AuthService } from "src/app/services/admin-plus/auth.service";
import { Payload, UtilityService } from "../utilities/utility.service";
import { Module } from "src/enums/module";

@Injectable({
  providedIn: "root",
})
export class OUService {
  api_url = "https://admin.googleapis.com/admin/directory/v1/customer/my_customer/orgunits";
  ou_api_url = "https://admin.googleapis.com/admin/directory/v1/customer/";
  selected_ou: OrgUnit = null;
  on_user_page = false;
  active_route = "/";
  ous = [];
  userOUsDialog: OrgUnit[] = [];
  ou_change: Subject<OU> = new Subject<OU>();
  private user;
  parentOU: OrgUnit = null;

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private authService: AuthService,
    private utilityService: UtilityService
  ) {}

  async getGoogleOUs() {
    const finalArray = [];
    let list = [];
    const response = await this.getGoogleOUsData();
    list = list.concat(response);
    for (let i = 0; i < list.length; i++) {
      list[i] = this.appendChildren(list[i], list);
    }
    const googleOUs: OrgUnit[] = this.groupParentOUs(list, finalArray);
    return googleOUs;
  }

  async getGoogleOUsData(moduleId = Module.TICKETS, params = {}): Promise<Payload> {
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("GET", `/admin-plus/${moduleId}/orgunits`, params, {}).subscribe((payload) => {
        if (payload) {
          resolve(payload);
        } else {
          resolve(null);
        }
      });
    });
  }

  async createOU(OrgUnit, customerId: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<OrgUnit>((resolve) => {
      this.createOUData(OrgUnit, customerId).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private createOUData(OrgUnit: OrgUnit, customerId: string): Observable<OrgUnit> {
    return this.http
      .post<OrgUnit>(this.ou_api_url + customerId + "/orgunits", OrgUnit, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<OrgUnit>("createOUData")));
  }

  async editOU(OrgUnitPath: string, customerId: string, OrgUnit) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<OrgUnit>((resolve) => {
      this.editOUData(OrgUnitPath, customerId, OrgUnit).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private editOUData(OrgUnitPath: string, customerId: string, OrgUnit): Observable<OrgUnit> {
    return this.http
      .put<OrgUnit>(this.ou_api_url + customerId + "/orgunits/" + OrgUnitPath, OrgUnit, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<OrgUnit>("editOUData")));
  }

  async deleteOU(OrgUnitPath: string, customerId: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<OrgUnit>((resolve) => {
      this.deleteOUData(OrgUnitPath, customerId).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private deleteOUData(OrgUnitPath: string, customerId: string): Observable<OrgUnit> {
    return this.http
      .delete<OrgUnit>(this.ou_api_url + customerId + "/orgunits" + OrgUnitPath, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<OrgUnit>("deleteOUData")));
  }

  async getOUs(path = "/", children = "all"): Promise<OrgUnit[]> {
    this.user = this.loginService.getUser();
    if (!this.user.permissions) {
      return [];
    }

    return new Promise<OrgUnit[]>((resolve) => {
      this.getOUData(path, children).subscribe((result) => {
        if (result) {
          if (result.organizationUnits) {
            this.ous = result.organizationUnits.sort((a, b) => (a.orgUnitPath > b.orgUnitPath ? 1 : -1));
            resolve(result.organizationUnits);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      });
    });
  }

  getOUData(path = "/", children = "all"): Observable<OrganizationUnits> {
    return this.http
      .get<OrganizationUnits>(this.api_url, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
        params: new HttpParams().set("orgUnitPath", encodeURI(path)).set("type", children),
      })
      .pipe(catchError(this.authService.handleAPIError<OrganizationUnits>("getOUData", true)));
  }

  async getUserOUs() {
    const finalArray = [];
    let list = [];
    list = await this.getOUs();
    for (let i = 0; i < list.length; i++) {
      list[i] = this.appendChildren(list[i], list);
    }
    const userOUs: OrgUnit[] = this.groupParentOUs(list, finalArray);
    return userOUs;
  }

  groupParentOUs(list, finalArray) {
    let userOUs: OrgUnit[] = [];
    let parentOUPath: string;
    let slashes: number;
    for (let i = 0; i < list.length; i++) {
      if (!slashes || list[i]["parentOrgUnitPath"].split("/").length < slashes || list[i]["parentOrgUnitPath"] == "/") {
        slashes = list[i]["parentOrgUnitPath"].split("/").length;
        parentOUPath = list[i]["parentOrgUnitPath"];
      }
    }

    this.parentOU = {
      orgUnitPath: parentOUPath,
    };

    //clean up the array
    for (let i = 0; i < list.length; i++) {
      if (list[i]["parentOrgUnitPath"] === parentOUPath) {
        finalArray.push(list[i]);
      }
    }
    if (!this.selected_ou) {
      this.selected_ou =
        (this.loginService.user && this.loginService.user["isAdmin"] && this.parentOU.orgUnitPath) ||
        list.filter((o) => o.parentOrgUnitPath == "/").length > 1
          ? this.parentOU
          : finalArray.length
          ? finalArray[0]
          : { orgUnitPath: "/" };
    }

    userOUs = finalArray.sort((a, b) => (a.name > b.name ? 1 : -1));
    this.userOUsDialog = userOUs.map((x) => Object.assign({}, x));
    return userOUs;
  }

  appendChildren(OU, batch) {
    OU.children = [];
    OU["isOpen"] = false;

    for (let i = 0; i < batch.length; i++) {
      if (OU.orgUnitPath == batch[i].parentOrgUnitPath) {
        OU.children.push(batch[i]);
      }
    }

    if (OU.children.length > 0) {
      //sort children ous alphabetically
      OU.children.sort();
      for (let i = 0; i < OU.children.length; i++) {
        OU.children[i] = this.appendChildren(OU.children[i], batch);
      }
    }

    return OU;
  }
}

export interface OrganizationUnits {
  kind: string;
  etag: string;
  organizationUnits: OrgUnit[];
}

export interface OrgUnit {
  kind?: string;
  name?: string;
  description?: string;
  etag?: string;
  blockInheritance?: boolean;
  orgUnitId?: string;
  orgUnitPath: string;
  parentOrgUnitId?: string;
  parentOrgUnitPath?: string;
  children?: OrgUnit[];
  isOpen?: boolean;
}

export interface OU {
  orgUnitId: string;
  orgUnitPath: string;
}
