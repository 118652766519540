<mat-form-field class="w-100">
  <mat-label>Room</mat-label>
  <mat-select
    [compareWith]="compare"
    [(ngModel)]="selectedRoom"
    name="room"
    (ngModelChange)="onRoomChange()"
    [disabled]="parentLoading || loadingRooms || !selectedBuilding?.id || rooms?.length === 0"
  >
    <mat-option value=""></mat-option>
    <mat-option *ngFor="let room of rooms" [value]="room">
      {{ room.name }}
    </mat-option>
  </mat-select>
  <mat-hint *ngIf="loadingRooms">
    <mat-icon class="faa-spin mx-1"> filter_tilt_shift </mat-icon>
    Gathering Rooms ...
  </mat-hint>
  <mat-hint *ngIf="rooms?.length === 0 && !loadingRooms"> No rooms available for selected building </mat-hint>
</mat-form-field>
