import { Component, OnInit, Inject }     from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-help-dialog-service',
  templateUrl: './help-dialog-service.component.html',
  styleUrls: ['./help-dialog-service.component.scss']
})
export class HelpDialogServiceComponent implements OnInit {

  public message: string;
  public title: string;
  public list: boolean = false;
  public listArray: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<HelpDialogServiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.listArray = this.data.message.split("$");
  }

  close() {
    this.dialogRef.close(true);
  }
}
