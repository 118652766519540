import { Component, Inject } from "@angular/core";
import {
  AutomationService,
  CustomGoogleObjectData,
  Parameter,
  Tag,
  WorkflowAction,
} from "src/app/services/workflow-plus/automation.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TreeSelectorComponent } from "src/app/layout/dialogs/tree-selector/tree-selector.component";
import { CustomerService } from "src/app/services/admin-plus/customer.service";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { HelpDialogService } from "src/app/services/utilities/help-dialog.service";

@Component({
  selector: "app-email-editor-tag-dialog",
  templateUrl: "./email-editor-tag-dialog.component.html",
  styleUrls: ["./email-editor-tag-dialog.component.scss"],
})
export class EmailEditorTagDialogComponent {
  tag: Tag;
  originalTagId: number;
  originalTag: string;
  parameters: Parameter[];
  workflowAction: WorkflowAction;
  tags: Tag[];
  nested = false;
  error = false;
  loading = false;
  myTextBox: HTMLInputElement;
  defaultParameter: any = { id: null };
  hasSubProperties = false;
  private customAttributes = [];
  useTreeSelector: boolean;
  useDropDownSelector: boolean;
  customGoogleObject: CustomGoogleObjectData;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EmailEditorTagDialogComponent>,
    public automationService: AutomationService,
    private customerService: CustomerService,
    private navService: NavService,
    private dialog: MatDialog,
    public helpDialogService: HelpDialogService
  ) {
    this.parameters = data.parameters;
    this.workflowAction = data.workflowAction;
    this.tags = data.tags;
    this.nested = data.nested;
    this.tag = data.tag;
    this.originalTagId = data.tag.id;
    this.originalTag = data.tag.tag;
    if (this.tag.tag === "") {
      this.tag.tag = "<<>>";
    }
    if (this.tag.tag != "<<>>" && !this.tag.parameter) {
      this.tag.parameter = this.defaultParameter;
    }
  }

  async ngOnInit() {
    this.myTextBox = document.getElementById("tagField") as HTMLInputElement;
    this.hasSubProperties = !!this.tag.subProperty;
    this.useTreeSelector = this.tag.property?.selector == "tree";
    this.useDropDownSelector = this.tag.property?.selector == "dropdown";
    if (this.useDropDownSelector) {
      this.customGoogleObject = await this.automationService.getCustomGoogleObject(
        this.tag.property?.objectKey.slice(0, -1)
      );
    }
  }

  updatePosition() {
    if (this.tag.id) {
      setTimeout(() => {
        this.myTextBox.focus();
        this.myTextBox.setSelectionRange(this.tag.tag.length - 2, this.tag.tag.length - 2);
      }, 0);
    } else {
      setTimeout(() => {
        this.myTextBox.focus();
        this.myTextBox.setSelectionRange(2, 2);
      }, 0);
    }
  }

  async processForm() {
    if (this.tag && this.tag.parameter && this.tag.parameter.toString() == "--- Static Value ---") {
      delete this.tag.parameter;
    }
    this.dialogRef.close(this.tag);
  }

  public validTag(tag): boolean {
    const filter = /^<<[0-9A-Z \-'_]+>>$/;
    return String(tag.trim()).search(filter) != -1;
  }

  tagFormat() {
    this.loading = true;
    const tag = this.tag.tag;
    const prefix = "<<";
    const suffix = ">>";
    setTimeout(() => {
      if (tag == this.tag.tag) {
        if (this.tag.tag) {
          if (this.tag.tag.match(new RegExp("<", "g")) && this.tag.tag.match(new RegExp("<", "g")).length > 2) {
            this.tag.tag = this.tag.tag.replace(/</g, "");
            this.tag.tag = prefix + this.tag.tag;
          }
          if (this.tag.tag.match(new RegExp(">", "g")) && this.tag.tag.match(new RegExp(">", "g")).length > 2) {
            this.tag.tag = this.tag.tag.replace(/>/g, "");
            this.tag.tag = this.tag.tag + suffix;
          }
          if (this.tag.tag.substring(0, 2) !== "<<") {
            this.tag.tag = prefix + this.tag.tag;
          }
          if (this.tag.tag.substring(this.tag.tag.length - 2, this.tag.tag.length) !== ">>") {
            if (this.tag.tag.substring(0, 2) !== "<<") {
              this.tag.tag = prefix + this.tag.tag + suffix;
            } else {
              this.tag.tag = this.tag.tag + suffix;
            }
          }
          this.tag.tag = this.tag.tag.toUpperCase();
          const result = this.validTag(this.tag.tag);
          this.error = !result;
        }
      }
    }, 800);
    this.loading = false;
  }

  isDuplicate(): boolean {
    if (!this.tags?.length) return;

    for (const tag of this.tags) {
      if (tag.isEditing && this.tag.isEditing) continue;
      if (tag.tag == this.tag.tag) return true;
    }

    return false;
  }

  compareObjects(o1: any, o2: any) {
    return o1 && o2 ? o1.id == o2.id : false;
  }

  compareCustomObjects(o1, o2) {
    return o1 && o2 ? `${o1.parentKey}.${o1.propertyKey}` == o2 : false;
  }

  async checkProperty(value = null) {
    if (value?.objectKey) {
      this.hasSubProperties = value.objectKey.endsWith(".");
    } else {
      this.hasSubProperties = false;
      this.tag.subProperty = undefined;
      return;
    }

    this.useTreeSelector = value.selector == "tree";
    this.useDropDownSelector = value.selector == "dropdown";

    if (this.useDropDownSelector) {
      this.loading = true;
      this.customGoogleObject = await this.automationService.getCustomGoogleObject(value.objectKey.slice(0, -1));
      this.loading = false;
    }
  }

  async openTreeSelector() {
    if (this.hasSubProperties) {
      if (!this.customAttributes.length) {
        // this.loaded = false;
        this.navService.loading.next(true);
        const schemas = await this.customerService.getCustomerSchemas();
        this.navService.loading.next(false);
        // this.loaded = true;
        let schemaCount = 0;
        for (const schema of schemas) {
          const parent = {
            id: schemaCount++,
            name: schema.schemaName,
            children: [],
          };
          for (const field of schema.fields) {
            const attribute = {
              id: field.fieldId,
              parent: schema.schemaName,
              name: field.fieldName,
            };
            parent.children.push(attribute);
          }
          this.customAttributes.push(parent);
        }
      }
      const dialogRef = this.dialog.open(TreeSelectorComponent, {
        width: "500px",
        data: {
          source: this.customAttributes,
          title: this.tag.property.name,
        },
      });

      dialogRef.afterClosed().subscribe((results) => {
        if (results) {
          this.tag.subProperty = `${results.parent}.${results.name}`;
        } else if (!this.tag.subProperty) {
          this.tag.property = null;
          this.hasSubProperties = false;
        }
      });
    }
  }

  setSubProperty(prop = null) {
    if (prop) {
      this.tag.subProperty = `${prop.parentKey}.${prop.propertyKey}`;
    } else if (!this.tag.subProperty) {
      this.tag.property = null;
      this.hasSubProperties = false;
    }
  }
}
