<button
  [class.spinner]="customerService.running"
  color="info"
  mat-mini-fab
  matTooltip="Recent Jobs"
  aria-label="Recent Jobs"
  class="nav-link"
  [matMenuTriggerFor]="jobsMenu"
  (menuOpened)="loadData()"
>
  <mat-icon class="text-secondary" color="info">notifications</mat-icon>
</button>
<mat-menu #jobsMenu="matMenu" xPosition="before">
  <div class="menu-container">
    <div class="row border-bottom pt-2">
      <div class="col-12 text-center">
        <h3>Jobs</h3>
      </div>
    </div>
    <app-loader *ngIf="loading"></app-loader>
    <div *ngIf="jobs.length === 0 && !loading" class="row pt-2 pb-3">
      <div class="col-12 pt-2 text-center">
        <span class="noActivities">No Activity Today</span>
      </div>
    </div>
    <div *ngIf="jobs.length > 0">
      <div *ngFor="let job of jobs" class="row py-2 border-bottom">
        <div class="col-2">
          <span *ngIf="job.status.id !== 3" class="text-{{ job.status.color }} text-secondary material-icons mat-icon mr-2">
            {{ job.status.icon }}
          </span>
        </div>
        <div class="col-10">
          <span class="text-nowrap">
            <span> {{ job.type.name }} </span><br />
            <small class="text-muted">
              {{ job.status.name }}:
              <span *ngIf="job.status.id == 1 || job.status.id == 2">{{ job.created | date : "short" }}</span>
              <span *ngIf="job.status.id == 3 || job.status.id == 4 || job.status.id == 6">{{
                job.start | date : "short"
              }}</span>
              <span *ngIf="job.status.id == 5">{{ job.end | date : "short" }}</span>
            </small>
            <app-loader *ngIf="job.status.id === 3"></app-loader>
          </span>
        </div>
      </div>
    </div>
    <div class="row border-top">
      <div class="col-12 text-center pt-4">
        <button  mat-stroked-button (click)="goToJobs()">See All Activities</button>
      </div>
    </div>
  </div>
</mat-menu>
