<div class="d-flex align-items-center">
  <h3 class="mb-0">Email Header Image</h3>
  <a mat-icon-button class="ml-2 search-icon__button" (click)="fileUpload.click()" [disabled]="loading || attachedFile">
    <mat-icon>attach_file</mat-icon>
  </a>
</div>
<div class="mb-3">
  <small>{{ tooltip }}</small>
  <br />
  <mat-error *ngIf="!loading && error"> Unable to retrieve branding attachment </mat-error>
</div>
<div class="image-grid">
  <div
    *ngIf="attachedFile || (customerSettings?.brand && customerSettings?.attachmentId)"
    class="image-grid__item mat-elevation-z1"
  >
    <img (click)="view(attachedFile)" [src]="customerSettings.brand ? customerSettings.brand : attachedFile?.imageUrl" />
    <button type="button" class="image-grid__btn" mat-icon-button (click)="delete(attachedFile)">
      <mat-icon>cancel</mat-icon>
    </button>
    <div class="image-grid__file-name mt-auto p-2">
      <small class="text-muted text-truncate d-inline-block">
        {{ attachedFile?.name }}
      </small>
    </div>
  </div>
  <input
    [disabled]="loading || attachedFile"
    type="file"
    class="file-input d-none"
    (change)="handleSelection($event)"
    accept=".png,.jpeg"
    #fileUpload
  />
</div>
