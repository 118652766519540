import { Component, Inject, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormControl } from "@angular/forms";
import { ReportsService } from "src/app/services/google/reports.service";
import { AutomationService } from "src/app/services/workflow-plus/automation.service";
import { OrgUnit } from "src/app/services/google/ou.service";
import { FiltersService, FilterType } from "src/app/services/utilities/filters.service";
import { DatePipe } from "@angular/common";
import { TooltipPosition } from "@angular/material/tooltip";
import { CategoryService } from "src/app/services/ticket-plus/category.service";
import { PriorityService } from "src/app/services/ticket-plus/priority.service";
import { ChannelService } from "src/app/services/ticket-plus/channel.service";
import { AgentService } from "src/app/services/ticket-plus/agent.service";
import { StatusService } from "src/app/services/ticket-plus/status.service";
import { AuditLogsService } from "src/app/services/admin-plus/audit-logs.service";
import { ManufacturerService } from "src/app/services/asset-plus/manufacturer.service";
import { RoomService } from "src/app/services/asset-plus/room.service";
import { TypeService } from "src/app/services/asset-plus/type.service";
import { DeviceService } from "src/app/services/asset-plus/device.service";

export interface Value {
	id: string;
	name: string;
}

@Component({
	selector: "app-filter-dialog",
	templateUrl: "./filter-dialog.component.html",
	styleUrls: ["./filter-dialog.component.scss"],
})
export class FilterDialogComponent implements OnInit {
	loading = false;
	selectValues = [];
	selectFilteredValues = [];
	filteredOus: OrgUnit[] = [];
	selectedDate: Date | null;
	selection;
	search;
	durationSearch = "";
	formattedDurationDisplay = "";
	positionOptions: TooltipPosition[] = ["right"];
	position = new UntypedFormControl(this.positionOptions[0]);
	@ViewChild("ouInput") ouInput: ElementRef;
	idKey = "";
	Object = Object; //This is so we can use Object in the html
	auditType = "";
	auditTypeSearch = "";
	dataKey = "";
	ticketDataKey = "";
	dataKeys = {
		storage: ["note", "fileId", "fileName", "userEmail"],
		tickets: {
			ticket: [
				"id",
				"subject",
				"tags",
				"agent",
				"status",
				"channel",
				"priority",
				"category",
				"comments",
				"createdBy",
				"updatedBy",
				"description",
				"requestedBy",
			],
			tag: ["id", "name", "createdBy", "updatedBy"],
			attachments: ["ticket", "createdBy", "updatedBy"],
			comments: ["body", "createdBy", "updatedBy"],
			agents: ["name", "email", "createdBy", "updatedBy"],
			rules: ["label", "notify", "weight", "agentId", "categoryId"],
			statuses: ["name", "createdBy", "updatedBy"],
			categories: ["name", "createdBy", "updatedBy"],
			teams: ["name", "createdBy", "updatedBy", "description"],
			device: ["id", "type", "model", "assetTag", "createdBy", "updatedBy", "manufacturer", "serialNumber"],
			priorities: ["name", "createdBy", "updatedBy"],
			delegates: ["name", "createdBy", "updatedBy"],
			channels: ["name", "createdBy", "updatedBy"],
			mentions: ["agent", "ticket", "comment", "createdBy", "updatedBy"],
		},
		workflows: ["name", "type", "event", "created", "deleted", "enabled", "lastRun"],
	};

	constructor(
		public dialogRef: MatDialogRef<FilterDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		private reportsService: ReportsService,
		private automationService: AutomationService,
		private filtersService: FiltersService,
		private datePipe: DatePipe,
		private categoryService: CategoryService,
		private priorityService: PriorityService,
		private channelService: ChannelService,
		private agentsService: AgentService,
		private statusService: StatusService,
		private auditLogsService: AuditLogsService,
		private manufacturerService: ManufacturerService,
		private roomService: RoomService,
		private typeService: TypeService,
		private deviceService: DeviceService
	) {
		this.search = "";
		if (data.filter.search) {
			if (data.filter.inputType === "date") {
				this.selectedDate = new Date(data.filter.search);
			}
			if (data.filter.inputType === "duration") {
				const seconds = this.filtersService.formatDurationFilterValue(data.filter.search);
				if (seconds) {
					this.search = seconds;
					this.formattedDurationDisplay = data.filter.search;
				}
			} else {
				this.search = data.filter.search;
			}
		}
	}

	async ngOnInit() {
		if (this.data.filter.name === "Log Data" && this.data.filter.search) {
			this.setLogDataFields();
		}
		this.loadData();
	}

	setLogDataFields() {
		for (const logDataType of this.dataKeys.storage) {
			if (Object.keys(JSON.parse(this.data.filter.search))[0] === logDataType) {
				this.auditType = "storage";
			}
		}
		for (const logDataType of this.dataKeys.workflows) {
			if (Object.keys(JSON.parse(this.data.filter.search))[0] === logDataType) {
				this.auditType = "workflows";
			}
		}
		this.dataKey = Object.keys(JSON.parse(this.data.filter.search))[0];
		this.auditTypeSearch = Object.values(JSON.parse(this.data.filter.search))[0].toString();
		const ticketDataType = Object.keys(JSON.parse(this.data.filter.search))[0].split(".")[0];
		if (this.dataKeys.tickets[`${ticketDataType}`]) {
			this.auditType = "tickets";
			this.ticketDataKey = Object.keys(JSON.parse(this.data.filter.search))[0];
		}
	}

	get FilterType() {
		return FilterType;
	}

	async loadData() {
		if (this.data.filter.inputType == "select") {
			this.loading = true;
			await this.buildSelectList();
			this.selectFilteredValues = this.selectValues;
			this.loading = false;
		}
	}

	async buildSelectList() {
		const key = "name";
		let sortByName = true;
		let data = {};
		let selections = [];
		switch (this.data.filter.name) {
			case "Log Type":
				selections = await this.automationService.getAuditLogTypes();
				break;
			case "File Type":
				this.selectValues = await this.reportsService.getMimeTypes(
					this.data.firstName,
					this.data.lastName,
					this.data.email,
					this.data.usageSize,
					this.data.lastViewedBefore,
					this.data.lastViewedAfter,
					this.data.fileName,
					this.data.fileType,
					this.data.trashType,
					this.data.sharedDrive,
					this.data.driveId,
					this.data.ouSearch,
					this.data.md5ChecksumSearch,
					this.data.lastModifiedBy
				);
				break;
			case "Workflow Type":
				selections = this.automationService.types;
				break;
			case "Workflow Activity":
				selections = await this.automationService.getActivities();
				break;
			case "Device Type":
				selections = this.filtersService.meetingDeviceTypes;
				break;
			case "Job Type":
				selections = this.filtersService.jobTypes;
				break;
			case "Job Status":
				selections = this.filtersService.jobStatuses;
				break;
			case "Status":
				this.selectValues = await this.statusService.getGroupedStatuses();
				return;
			case "Assigned Agent":
				data = await this.agentsService.getAgents();
				selections = data["data"];
				break;
			case "Priority":
				sortByName = false;
				data = await this.priorityService.getPriorities();
				selections = data["data"];
				break;
			case "Category":
				this.selectValues = await this.categoryService.getGroupedCategories();
				return;
			case "Channel":
				sortByName = false;
				data = await this.channelService.getChannels();
				selections = data["data"];
				break;
			case "Action":
				sortByName = false;
				this.selectValues = [
					{
						name: "Tickets",
						children: [{ name: "Created" }, { name: "Updated" }, { name: "Deleted" }],
					},
					{
						name: "Storage",
						children: [
							{ name: "Enable Delete" },
							{ name: "File Deleted" },
							{ name: "File Restored" },
							{ name: "File Trashed" },
							{ name: "User Flagged" },
							{ name: "File Scheduled for Trash" },
							{ name: "File Scheduled for Trash Removed" },
							{ name: "Disable Delete" },
							{ name: "User Flag Removed" },
							{ name: "Run Report" },
							{ name: "Service Account Tested" },
							{ name: "Service Account Updated" },
							{ name: "Service Account Removed" },
							{ name: "Service Account Created" },
							{ name: "Delete Shared Drive Contents" },
							{ name: "Delete Shared Drive" },
							{ name: "Transfer Drive Ownership" },
							{ name: "Transfer to GCP" },
							{ name: "Storage Report Created" },
						],
					},
				];
				break;
			case "Object Type": {
				await this.auditLogsService.getFilters({ selectedModule: this.data.selectedModule });
				const objects = this.auditLogsService.objects;
				data = objects;
				for (const object of objects) {
					if (object.object) {
						const filterObject = {
							name: object.object,
						};
						selections.push(filterObject);
					}
				}
				break;
			}
			case "Manufacturer":
				this.idKey = "id";
				data = await this.manufacturerService.getManufacturers();
				selections = data["data"];
				break;
			case "Type":
				this.idKey = "id";
				data = await this.typeService.getTypes();
				selections = data["data"];
				break;
			case "Room":
				this.idKey = "id";
				data = await this.roomService.getRooms();
				selections = data["data"];
				break;
			case "Assigned User":
				data = await this.deviceService.getFilters();
				selections = data["data"];
				break;
			case "Object Property": {
				data = await this.auditLogsService.getFilters({ selectedModule: this.data.selectedModule });
				break;
			}
		}
		for (let i = 0; i < selections.length; i++) {
			const value = {
				id: this.idKey ? selections[i][this.idKey] : selections[i][key],
				name: selections[i][key],
			};
			this.selectValues.push(value);
		}
		if (sortByName) {
			this.selectValues.sort((a, b) => (a.name > b.name ? 1 : -1));
		}
	}

	onKey(value: string) {
		this.selectFilteredValues = this.selectFilter(value);
	}

	updateDurationFormatDisplay(value: number) {
		this.formattedDurationDisplay = this.filtersService.formatDurationFilterDisplay(value);
	}

	selectFilter(value: string) {
		const filterValue = value.toLowerCase();
		const filtered = this.selectValues.filter((m) => m.name.toLowerCase().includes(filterValue));
		return filtered;
	}

	applyFilter() {
		if (this.idKey) {
			for (const selected of this.selectValues) {
				if (selected.id === this.search) {
					this.data.filter.id = selected.id;
					this.data.filter.search = selected.name;
				}
			}
		} else {
			this.data.filter.search = this.search;
		}
		this.dialogRef.close(this.data.filter);
	}

	onKeyOU(value: string) {
		this.selectFilter(value);
	}

	onSelectDate(event) {
		this.selectedDate = event;
		this.search = this.datePipe.transform(event, "MMM d, y zzzz");
	}

	buildDataSearch(event) {
		this.search = `{"${this.auditType === "tickets" ? this.ticketDataKey : this.dataKey}" : "${event.trim()}"}`;
	}

	onDismiss(): void {
		// Close the dialog, return false
		this.search = "";
		this.dialogRef.close(false);
	}

	capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
}
