import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { User, Permission } from "../../web/login/user";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  user: User;
  private refreshAccessTokenSubject = new Subject<any>();
  refreshing: boolean;
  showLeftNavBreadcrumb = false;

  constructor(private notificationService: NotificationService, private router: Router) {
    const localUser = localStorage.getItem("user") || null;
    if (localUser) this.user = JSON.parse(localUser);
  }

  logout(target = ""): void {
    this.user = null;
    this.showLeftNavBreadcrumb = false;
    localStorage.removeItem("user");
    localStorage.removeItem("modules");
    localStorage.removeItem("elevatedAccess");

    if (target != "" && !target.startsWith("/login")) {
      localStorage.setItem("target", target);
    } else {
      localStorage.removeItem("target");
    }

    sessionStorage.clear();
    this.notificationService.notify("You have been successfully logged out");
    this.router.navigateByUrl("");
  }

  isLoggedIn(): boolean {
    try {
      const user: User = JSON.parse(localStorage.getItem("user"));
      //const modules: string = JSON.parse(localStorage.getItem("modules"));

      if (user) {
        this.user = user;
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  // MAYBE NEED THE FLEXIBILITY TO USE ELSEWHERE
  private sendRefreshAccessToken(id_token: string, jwt_token: string) {
    this.refreshAccessTokenSubject.next({ id_token: id_token, jwt_token: jwt_token });
  }

  refreshAccessToken(): Observable<any> {
    return this.refreshAccessTokenSubject.asObservable();
  }

  getUser(): User {
    const user: User = JSON.parse(localStorage.getItem("user"));
    //Will return null, that's ok the rest of the app should understand 'null user' means logged out.
    if (!user) {
      return;
    }

    const googlePermission = this.getGooglePermission(user);

    if (googlePermission) {
      if (googlePermission.expires < new Date().getTime() && !this.refreshing) {
        this.refreshing = true;
        this.sendRefreshAccessToken(user?.idToken, user?.jwt?.token);
      }
      //Dumb but it's how it needs to be for now.
      //Hopefully this will get cleaned up but it's not part of this current sprint 11/16/2021
      user.authToken = googlePermission.accessToken;
      if (googlePermission.idToken) user.idToken = googlePermission.idToken;
    }

    return user;
  }

  getGooglePermission(user): Permission | null {
    if (user.permissions) {
      for (const permission of user.permissions) {
        if (permission.name == "google") {
          return permission;
        }
      }
    }

    return null;
  }

  parseParams(str: string) {
    const pieces = str.split("&");
    const data = {};
    let i;
    let parts;
    // process each query pair
    for (i = 0; i < pieces.length; i++) {
      parts = pieces[i].split("=");
      if (parts.length < 2) {
        parts.push("");
      }
      data[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
    }
    return data;
  }
}
