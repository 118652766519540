export interface IPriority {
	id: number;
	name: string;
	edit?: boolean;
	parentId?: number;
}

export class Priority implements IPriority {
	id: number;
	name: string;
	edit?: boolean;
	parentId?: number;

	constructor(properties) {
		Object.assign(this, properties);
	}
}
