import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UsersService } from "src/app/services/admin-plus/users.service";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { NavService } from "src/app/services/admin-plus/nav.service";

@Component({
  selector: "app-users-delete-users",
  templateUrl: "./users-delete-users.component.html",
  styleUrls: ["./users-delete-users.component.scss"],
})
export class UsersDeleteUsersComponent implements OnInit {
  public users: [];
  private users_to_delete = 0;
  private users_attempted_to_delete = 0;
  private users_deleted = [];

  constructor(
    private dialogRef: MatDialogRef<UsersDeleteUsersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private usersService: UsersService,
    private notificationService: NotificationService,
    private navService: NavService
  ) {}

  ngOnInit(): void {
    this.users = this.data.users;
    this.users_to_delete = this.data.users.length;
  }

  async delete() {
    this.navService.loading.next(true);
    for (var i = 0; i < this.users.length; i++) {
      await this.deleteuser(this.users[i]);
    }
  }

  private async deleteuser(user: any) {
    var response = await this.usersService.deleteUser(user.id);
    return new Promise((resolve) => {
      if (response === null) {
        this.users_attempted_to_delete++;
        this.users_deleted.push(user);
        this.notificationService.notify(`The user ${user.name.fullName} was deleted`);
        if (this.users_to_delete == this.users_attempted_to_delete) {
          this.navService.loading.next(false);
          this.notificationService.notify("All selected users have been removed");
          this.dialogRef.close(this.users_deleted);
        }
        resolve(user);
      } else {
        this.notificationService.notify(`The user ${user.name.fullName} could not be deleted`);
        resolve(null);
      }
    });
  }
}
