import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ReportsService, StorageReport, Files } from "src/app/services/google/reports.service";
import { MatTableDataSource } from "@angular/material/table";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { DrivesService, Drive, DriveFile } from "src/app/services/storage-plus/drives.service";
import { SelectionModel } from "@angular/cdk/collections";
import { MatSort } from "@angular/material/sort";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { ExportsheetsService } from "src/app/services/google/sheets.service";
import { UntypedFormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import {
  ConfirmDialogModel,
  ConfirmationDialogComponent,
} from "src/app/layout/dialogs/confirmation-dialog/confirmation-dialog.component";
import { User as localUser } from "src/app/web/login/user";
import { StorageReportFilesTableComponent } from "src/app/storage-plus/storage/storage-report-files/storage-report-files-table/storage-report-files-table.component";
import { DialogTransferComponent } from "src/app/layout/dialogs/dialog-transfer/dialog-transfer.component";
import { AdminPlusService } from "src/app/services/admin-plus/admin-plus.service";
import { UtilityService } from "src/app/services/utilities/utility.service";

@Component({
  selector: "app-storage-report-shared-drive",
  templateUrl: "./storage-report-shared-drive.component.html",
  styleUrls: ["./storage-report-shared-drive.component.scss"],
})
export class StorageReportSharedDriveComponent implements OnInit {
  private pullAllFilesInterval;
  options = [];
  @ViewChild(StorageReportFilesTableComponent)
  driveFilesTableComponent: StorageReportFilesTableComponent;
  user: localUser = {};
  storageReport: StorageReport = {
    status: {
      name: "",
      id: 4,
    },
    totalUsers: 0,
    totalStorage: 0,
    totalDrive: 0,
    totalGmail: 0,
    totalPhotos: 0,
    driveFilesSynced: new Date().toString(),
  };

  viewOnly = false;
  destroy = false;

  dataSource = new MatTableDataSource<DriveFile>();
  maxResultsActions = new UntypedFormControl();
  actions = new UntypedFormControl();
  selection = new SelectionModel<DriveFile>(true, []);

  displayedColumns: string[] = ["name", "mimeType", "storageMb"];
  search = "";
  searchUser = "";
  direction = "desc";
  activeOrderBy = "storageMb";
  driveId = "";

  maxResults = 10;
  totalDriveFileCount = 0;
  page = 1;
  offset = 0;

  driveFiles: DriveFile[] = [];

  nextButtonDis = false;
  nextButton = false;

  @ViewChild(MatSort) sort!: MatSort;

  drive: Drive;

  files: Files[] = [];
  columns: string[] = [];

  constructor(
    private navService: NavService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notifyService: NotificationService,
    private exportSheetsService: ExportsheetsService,
    private drivesService: DrivesService,
    private reportsService: ReportsService,
    private dialog: MatDialog,
    private adminPlusService: AdminPlusService,
    private utilityService: UtilityService
  ) {
    this.options.push({
      icon: "picture_as_pdf",
      name: "Export to PDF",
      tooltip: "Exports the current page to a PDF document for sharing",
      class: "export",
      disabled: true,
    });
    this.options.push({
      icon: "delete",
      name: "Delete Drive and Contents",
      tooltip: "Deletes shared drive and all files in the shared drive",
      disabled: this.viewOnly,
    });
    this.options.push({
      icon: "cloud_upload",
      name: "Transfer to Google Cloud Storage",
      tooltip: "Transfers shared drive and its contents to Google Cloud Storage",
      disabled: this.viewOnly,
    });
    this.options.push({
      icon: "download",
      name: "Pull All Drive Files",
      tooltip: "This process can take some time",
      disabled: this.viewOnly,
    });

    this.adminPlusService.breadCrumbLinks.push({
      link: "/reports/storage/drives",
      text: "Shared Drives",
      alt: "Shared Drives",
    });
  }

  async ngOnInit() {
    this.loadData();
  }

  optionSelected(option) {
    switch (option.name) {
      case "Export to PDF":
        this.utilityService.downloadAsPDF();
        break;
      case "Delete Drive and Contents":
        this.openConfirmationWipeDrive();
        break;
      case "Transfer to Google Cloud Storage":
        this.openTransferDialog();
        break;
      case "Pull All Drive Files":
        this.resyncDriveFilesConfirmation();
        break;
      default:
        break;
    }
  }

  receiveFilesEvent($event) {
    this.files = $event["files"];
    this.columns = $event["columns"];
  }

  async filesSpreadsheet() {
    this.navService.loading.next(true);
    const spreadsheetID = await this.exportSheetsService.createSpreadsheet("Files");
    await this.driveFilesTableComponent.sendFilesEvent();
    await this.addFileData(spreadsheetID);
    window.open("https://docs.google.com/spreadsheets/d/" + spreadsheetID);
    this.navService.loading.next(false);
  }

  async addFileData(spreadsheetID: string) {
    const newFileObject = await this.exportSheetsService.createSpreadsheetArray(this.columns, this.files);
    if (newFileObject) {
      await this.exportSheetsService.addExportData(
        newFileObject,
        spreadsheetID,
        "Sheet1!A1:G" + newFileObject["values"].length
      );
    }
  }

  private async loadData() {
    this.navService.loading.next(true);
    this.options.forEach((option) => {
      option.disabled = true;
    });
    this.storageReport = await this.reportsService.getStorageReport();
    if (!this.storageReport || this.storageReport.status.id != 4) {
      //complete
      this.router.navigate(["/reports/storage"]);
    }
    this.driveId = this.activatedRoute.snapshot.paramMap.get("driveId") || "";
    const drive = await this.drivesService.getDrive(this.driveId);
    if (!drive["error"] && !this.destroy) {
      this.adminPlusService.breadCrumbLinks.push({
        link: null,
        text: drive.name,
        alt: drive.name,
      });
      this.drive = drive;
      if (this.drive.isDeleting === 1) {
        this.viewOnly = true;
        this.toggleButtons();
      }
      if (this.drive.filesSynced === null) {
        this.toggleButtons();
        if (!this.destroy) this.checkPullAllFiles();
        this.viewOnly = true;
      }
      this.options.forEach((option) => {
        option.disabled = false;
        if (
          option.name == "Delete Drive and Contents" ||
          option.name == "Transfer to Google Cloud Storage" ||
          option.name == "Pull All Drive Files"
        ) {
          option.disabled = this.viewOnly;
        }
      });
    } else {
      this.driveId = "0";
    }
    this.navService.loading.next(false);
  }

  openConfirmationWipeDrive() {
    const message = `
      Are you sure you want to wipe all drive contents and delete the shared drive ${this.drive.name}? 
      This could take some time if there are lots of files in the shared drive.
    `;
    const btnOkText = `Confirm`;
    const dialogData = new ConfirmDialogModel("Delete Shared Drive and Contents", message, btnOkText);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      width: "500px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.wipeShareDrive();
        this.router.navigate(["/reports/storage/drives"]);
      }
      this.selection.clear();
      this.navService.loading.next(false);
      this.actions.setValue("");
    });
  }

  async wipeShareDrive() {
    this.notifyService.notify("Drive and contents are currently being deleted");
    await this.drivesService.wipeSharedDrive(this.driveId);
  }

  resyncDriveFilesConfirmation() {
    const message = `Are you sure you want to pull all drive files for this shared drive?`;
    const btnOkText = `Pull Drive Files`;
    const dialogData = new ConfirmDialogModel("Pull All Drive Files", message, btnOkText);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      width: "500px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.resyncDriveFiles();
      }
    });
  }

  async resyncDriveFiles() {
    this.navService.loading.next(true);
    const response = await this.drivesService.resyncDrivesFiles(this.driveId);
    if (response) {
      this.toggleButtons(false);
      this.notifyService.notify("The drive files are being re-synced");
      this.drive.filesSynced = null;
      this.viewOnly = true;
      if (!this.destroy) this.checkPullAllFiles();
    }
    this.navService.loading.next(false);
  }

  getPageData() {
    return this.dataSource._pageData(this.dataSource._orderData(this.dataSource.filteredData));
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  masterToggle() {
    this.isEntirePageSelected()
      ? this.selection.deselect(...this.getPageData())
      : this.selection.select(...this.getPageData());
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.getPageData() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1}`;
  }

  openTransferDialog() {
    const dialogRef = this.dialog.open(DialogTransferComponent, {
      disableClose: true,
      width: "500px",
      data: {
        files: this.files,
        isDrive: true,
        isZip: true,
        zipFile: this.drive.name,
        sendEmail: false,
        drive: {
          googleId: this.drive.driveId,
          type: "drive",
          name: this.drive.name,
        },
        isMove: false,
        folderPath: "",
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        //Add flag or something?
      }
    });
  }

  ngOnDestroy() {
    clearInterval(this.pullAllFilesInterval);
    this.destroy = true;
  }

  checkPullAllFiles() {
    this.pullAllFilesInterval = setInterval(async () => {
      const drive = await this.drivesService.getDrive(this.driveId);
      if (drive.filesSynced) {
        this.drive.filesSynced = drive.filesSynced;
        clearInterval(this.pullAllFilesInterval);
        this.toggleButtons(false);
        location.reload();
      }
    }, 10000);
  }

  private toggleButtons(disabled = true) {
    for (let i = 0; i < this.options.length; i++) {
      if (i != 0) this.options[i].disabled = disabled;
    }
  }
}
