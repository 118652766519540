import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Payload } from "src/app/services/utilities/utility.service";
import { Agent } from "src/app/models/ticket-plus/agent";
import { AgentService } from "src/app/services/ticket-plus/agent.service";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { TicketService } from "src/app/services/ticket-plus/ticket/ticket.service";

@Component({
  selector: "app-agent-list",
  templateUrl: "./agent-list.component.html",
  styleUrls: ["./agent-list.component.scss"],
})
export class AgentListComponent {
  payload: Payload;
  params = { limit: 5, offset: 0 };
  agents: Agent[];
  @Input() title: string;
  loading = true;
  constructor(
    private router: Router,
    private agentsService: AgentService,
    private navService: NavService,
    private ticketService: TicketService
  ) {}

  async ngOnInit() {
    this.loadData();
  }

  private async loadData() {
    this.loading = true;
    this.payload = await this.agentsService.getAgents(this.params);
    if (this.payload?.data) {
      this.agents = this.payload.data;
      this.agents.forEach(async (agent) => {
        this.navService.loading.next(true);
        const ticketCount = await this.ticketService.getTickets({ agentEmail: agent.email, deleted: 0, statusId: 1 });
        if (ticketCount) {
          agent.openTickets = ticketCount.count;
        }
        this.navService.loading.next(false);
      });
    }
    this.loading = false;
  }

  seeAll() {
    this.router.navigateByUrl("/settings/custom/tickets/agents");
  }

  routeToTicketTable(agent: Agent) {
    this.router.navigate(["/ticketing/tickets"], {
      queryParams: {
        ["Assigned Agent_15"]: agent.name,
      },
    });
  }

  routeToAgent() {
    this.router.navigate(["/settings/custom/tickets/agents"]);
  }
}
