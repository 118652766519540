import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { environment } from "src/environments/environment";
import { AuthService } from "src/app/services/admin-plus/auth.service";

@Injectable({
  providedIn: "root",
})
export class TenantDatabaseService {
  private baseURL: string = environment.apiUrl + "/customer/tenant-databases";
  private user: any;

  constructor(private http: HttpClient, private loginService: LoginService, private authService: AuthService) {}

  public async getTenantDatabases() {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<TenantDatabase[]>((resolve) => {
      this.getTenantDatabasesData().subscribe((serviceAccounts) => {
        if (serviceAccounts) {
          resolve(serviceAccounts);
        } else {
          resolve([]);
        }
      });
    });
  }

  private getTenantDatabasesData(): Observable<TenantDatabase[]> {
    return this.http
      .get<TenantDatabase[]>(this.baseURL, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<TenantDatabase[]>("getTenantDatabasesData")));
  }

  public async createTenantDatabase(tenantDatabase: TenantDatabase) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<TenantDatabase>((resolve) => {
      this.createTenantDatabaseData(tenantDatabase).subscribe((tenantDatabase) => {
        if (tenantDatabase) {
          resolve(tenantDatabase);
        } else {
          resolve(null);
        }
      });
    });
  }

  private createTenantDatabaseData(tenantDatabase: TenantDatabase): Observable<TenantDatabase> {
    return this.http
      .post<TenantDatabase>(this.baseURL, tenantDatabase, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<TenantDatabase>("createTenantDatabaseData")));
  }

  public async updateTenantDatabase(tenantDatabase: TenantDatabase) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<TenantDatabase>((resolve) => {
      this.updateTenantDatabaseData(tenantDatabase).subscribe((tenantDatabase) => {
        if (tenantDatabase) {
          resolve(tenantDatabase);
        } else {
          resolve(null);
        }
      });
    });
  }

  private updateTenantDatabaseData(tenantDatabase: TenantDatabase): Observable<TenantDatabase> {
    return this.http
      .put<TenantDatabase>(this.baseURL + "/" + tenantDatabase.id, tenantDatabase, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<TenantDatabase>("updateTenantDatabaseData")));
  }

  public async deleteTenantDatabase(tenantDatabaseId: number) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<boolean>((resolve) => {
      this.deleteTenantDatabaseData(tenantDatabaseId).subscribe((tenantDatabase) => {
        if (tenantDatabase) {
          resolve(tenantDatabase);
        } else {
          resolve(null);
        }
      });
    });
  }

  private deleteTenantDatabaseData(tenantDatabaseId: number): Observable<boolean> {
    return this.http
      .delete<boolean>(this.baseURL + "/" + tenantDatabaseId, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<boolean>("deleteTenantDatabaseData")));
  }
}

export interface TenantDatabase {
  id?: number;
  database?: string;
  host?: string;
  port?: number;
  user?: string;
  password?: string;
  socketPath?: string;
  sslKey?: string;
  sslCertificate?: string;
  sslCertificateAuthority?: string;
}
