import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Ticket } from "src/app/models/ticket-plus/ticket";
import { User } from "src/app/users/users";

@Component({
  selector: "app-form-ticket-dialog",
  templateUrl: "./form-ticket-dialog.component.html",
  styleUrls: ["./form-ticket-dialog.component.scss"],
})
export class FormTicketDialogComponent implements OnInit {
	public ticket: Ticket;
	current_user: User;
	requstedByUser: User;

	constructor(private dialogRef: MatDialogRef<FormTicketDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

	ngOnInit(): void {
		this.ticket = this.data.ticket;
		const current_user: User = JSON.parse(localStorage.getItem("user"));
		if (current_user) {
			this.current_user = current_user;
		}
	}

	async requestedBy(event) {
		this.requstedByUser = event.option?.value;
	}

	async processForm() {
		this.ticket.requestedBy.name = this.requstedByUser?.name?.fullName || this.ticket.requestedBy.name;
		this.ticket.requestedBy.email = this.requstedByUser?.primaryEmail || this.ticket.requestedBy.email;
		this.dialogRef.close(this.requstedByUser);
	}
}
