<app-dashboard-card-header [title]="title" [payload]="payload"></app-dashboard-card-header>
<mat-card class="card" [ngClass]="{'card--fixed-height': !loading}">
  <div *ngIf="agents?.length">
    <div *ngFor="let agent of agents" class="card__body d-flex border-bottom position-relative">
      <a
        (click)="routeToTicketTable(agent)"
        class="list-group-item list-group-item-action d-flex justify-content-between align-items-start"
      >
        <div class="badge mr-3 position-relative">
          <img
            class="rounded-circle"
            height="32"
            width="32"
            matChipAvatar
            referrerpolicy="no-referrer"
            src="{{
              !(agent.email | getProfilePicturePipe | async)
                ? 'https://lh3.googleusercontent.com/a/default-user=s64'
                : (agent.email | getProfilePicturePipe | async)
            }}"
            alt="Agent Avatar"
          />

          <div class="profile__image-badge" matTooltip="Open Tickets" matTooltipPosition="above">
            <span>
              {{ (agent?.openTickets || agent?.openTickets == 0) ? agent?.openTickets : '-' }}
            </span>
          </div>
        </div>

        <div class="w-100 text-break">
          <h5 class="mb-0">{{ agent.name }}</h5>
          <small class="text-muted">{{ agent.email }}</small>
        </div>
      </a>
    </div>

  </div>
	
    <div *ngIf="!loading && !agents?.length" class="p-4">
      <h5 class="h5">No {{ title | lowercase }} found.  <a class="link" (click)="routeToAgent()">Create an agent</a></h5>
    </div>

  <div class="card__footer d-flex justify-content-between mt-auto" *ngIf="agents?.length">
    <button mat-stroked-button class="card__footer-button w-100 py-4 rounded-0" (click)="seeAll()">See All</button>
  </div>
</mat-card>
