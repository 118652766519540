import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { AuthService } from "src/app/services/admin-plus/auth.service";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent {
  options = [
    {
      text: "Get Help",
      icon: "help",
      isAuthenticated: true,
    },
    {
      text: "Settings",
      icon: "settings",
      isAuthenticated: true,
    },
    {
      text: "Logout",
      icon: "logout",
      isAuthenticated: true,
    },
    {
      text: "Login",
      icon: "login",
      isAuthenticated: false,
    },
  ];
  constructor(public loginService: LoginService, public authService: AuthService, private router: Router) {}

  menuOption(text: string) {
    switch (text) {
      case "Get Help":
        window["Beacon"]("toggle");
        break;
      case "Login":
        this.authService.signInWithGoogle();
        break;
      case "Logout":
        this.router.navigate(["/logout"]);
        break;
      case "Settings":
        this.router.navigate(["/settings"]);
        break;
      default:
        break;
    }
  }
}
