<div class="bg-white position-relative">
  <div class="" *ngIf="!payload && loading">
    <div class="alert alert-info d-flex align-items-center m-3" *ngIf="loading">
      <span class="material-icons mat-icon faa-spin mr-3"> filter_tilt_shift </span>
      <p class="mb-0">    Gathering {{ title }}...</p>
    </div>
    </div>
  <h4 class="p-3" *ngIf="!payload && !loading">Unable to retrieve {{ title }}</h4>
  <h4 class="p-3" *ngIf="payload?.data?.length == 0 && !loading && title != 'Notes'">No data found for {{ title }}</h4>
</div>
