import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { UsersService } from "src/app/services/admin-plus/users.service";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent, MatChipListbox } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { GroupsService } from "src/app/services/admin-plus/groups.service";
import { debounceTime, distinctUntilChanged, map, startWith } from "rxjs/operators";
import { Observable, fromEvent } from "rxjs";
import { AutomationService, Parameter, WorkflowAction } from "src/app/services/workflow-plus/automation.service";
import { Module } from "src/enums/module";

@Component({
  selector: "app-email",
  templateUrl: "./email.component.html",
  styleUrls: ["./email.component.scss"],
})
export class EmailComponent implements OnInit {
  @Output() selectedEmail = new EventEmitter();
  emailsCtrl = new UntypedFormControl(null, Validators.required);
  @Input() email = "";
  @Input() workflowAction: WorkflowAction;
  @Input() parameterId: number;
  @Input() fieldLabel = "";
  @Input() placeholder = "";
  @Input() type: InputType;
  @Input() multiple: boolean;
  @Input() custom: boolean;
  @Input() error = false;
  @Input() parameter: Parameter;
  @Input() disabled = false;
  @Input() hideRemove = false;
  @Input() defaultOption = "";
  @ViewChild("emailsInput") emailsInput: ElementRef;
  @ViewChild("itemsList") itemsList: MatChipListbox;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  filteredOptions: Observable<string[]>;
  orderBy = "givenName";
  sortOrder = "SORT_ORDER_UNDEFINED";
  removable = true;
  emails = [];
  loading = false;
  searchType = "emails";
  @Input() parentLoading: boolean;
  params = {
    maxResults: 100,
    orderBy: "givenName",
    sort: "DESC",
    email: null,
  };

  constructor(
    private usersService: UsersService,
    private groupsService: GroupsService,
    public automationService: AutomationService
  ) {}

  ngOnInit(): void {
    if (!this.placeholder.includes("Enter") && !this.placeholder.includes("Ex")) {
      this.placeholder = "Enter " + this.placeholder;
    }
  }

  ngAfterViewInit() {
    if (this.parentLoading) this.emailsCtrl.disable();
    fromEvent(this.emailsInput.nativeElement, "input")
      .pipe(map((event: Event) => (event.target as HTMLInputElement).value))
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .subscribe(async (value) => {
        this.loading = true;
        this.emails = null;
        const search = value.trim();
        if (search && (this.multiple || (!this.email && !this.multiple))) {
          switch (this.type) {
            case InputType.USERS:
              this.emails = await this.getUsers(search);
              break;
            case InputType.GROUPS:
              this.emails = await this.getGroups(search);
              break;
            case InputType.BOTH:
              this.emails = await (await this.getUsers(search)).concat(await this.getGroups(search));
              break;
          }
        } else {
          this.loading = false;
        }
      });
  }

  ngOnChanges() {
    if (this.parameter) {
      this.filteredOptions = this.emailsCtrl.valueChanges.pipe(
        startWith(""),
        map(() => [])
      );
    }

    if (!this.parentLoading) {
      if (this.emailsCtrl.disabled) this.emailsCtrl.enable();
    }
  }

  add(event: MatChipInputEvent): void {
    if (this.custom) {
      const input = event.input;
      const value = event.value.trim();
      let check = false;
      if ((value || "").trim()) {
        check = this.validEmail(value);
        if (check) {
          this.createChip(value);
        }
        // Reset the input value
        if (input) {
          input.value = "";
        }
      }
    } else {
      this.emails = null;
      this.emailsInput.nativeElement.value = "";
      this.emailsCtrl.setValue(null);
    }
  }

  convertToChip(event): void {
    // onBlur method
    if (this.custom) {
      let check = false;
      check = this.validEmail(event.target.value.trim());
      if (check) {
        this.createChip(event.target.value);
      }
    } else {
      // clear displayed emails if there is no text in the email field
      if (!event.target.value) {
        this.emails = null;
      }
    }
    this.emailsInput.nativeElement.value = "";
    this.emailsCtrl.setValue(null);
  }

  createChip(value) {
    if (this.multiple || (this.email === "" && !this.multiple)) {
      const selectedObject = {
        email: value,
        parameterId: this.parameterId ? this.parameterId : null,
      };
      this.selectedEmail.emit(selectedObject);
      this.emails = null;
    }
  }

  valueList(): string[] {
    return !this.email || this.email.split(",")[0] === "" ? [] : this.email.split(",");
  }

  async remove(item: string): Promise<void> {
    const removeObject = {
      email: item,
      deleted: true,
    };
    this.selectedEmail.emit(removeObject);
    this.emails = null;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    // autocomplete optionSelected method
    const selectedObject = {
      email: event.option.value,
      parameterId: this.parameterId ? this.parameterId : null,
    };
    if (!this.multiple) {
      this.email = "";
    }
    this.selectedEmail.emit(selectedObject);
    this.emails = null;
    this.emailsInput.nativeElement.value = "";
    this.emailsCtrl.setValue(null);
  }

  validEmail(e) {
    const primaryEmail = e.primaryEmail ? e.primaryEmail : e;
    const filter = /^\s*[\w\-+_]+(\.[\w\-+_]+)*@[\w\-+_]+\.[\w\-+_]+(\.[\w\-+_]+)*\s*$/;
    return String(primaryEmail.trim()).search(filter) != -1;
  }

  async getUsers(search?) {
    const localEmails = [];
    this.loading = true;

    if (!this.error) {
      this.searchType = "emails";
      this.params.email = search;
      let users = [];
      const response = await this.usersService.getGoogleUsers(Module.TICKETS, this.params);
      if (response) {
        users = users.concat(response);
        if (users) {
          for (const user of users) {
            localEmails.push({
              name: user.name.fullName,
              email: user.primaryEmail,
            });
          }
        }
      } else {
        this.error = true;
      }
    }

    this.loading = false;
    return localEmails;
  }

  async getGroups(search?, maxResults = 25, pageToken = "") {
    const localEmails = [];
    this.loading = true;

    if (!this.error) {
      this.searchType = "emails";
      const response = await this.groupsService.getGroups(maxResults, pageToken, search);
      if (response) {
        if (response.groups) {
          this.emails = [];
          if (typeof response.groups !== "undefined") {
            for (const group of response.groups) {
              localEmails.push({
                name: group["name"],
                email: group["email"],
              });
            }
          }
        }
      } else {
        this.error = true;
      }
    }

    this.loading = false;
    return localEmails;
  }

  clearError() {
    this.error = false;
  }
}

export enum InputType {
  USERS = 1,
  GROUPS = 2,
  BOTH = 3,
}
