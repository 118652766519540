<div @fadeInOut class="app-settings-links d-flex align-items-center">
  <div class="d-flex justify-content-between w-100 px-3">
    <div class="d-flex flex-wrap align-items-center">
      <div *ngFor="let appSettingLink of adminPlusService.breadCrumbLinks; let last = last" class="breadcrumb my-auto">
        <a
          *ngIf="!last"
          [matTooltip]="appSettingLink.text"
          [routerLink]="appSettingLink.link"
          [queryParams]="appSettingLink.queryParams"
          >{{ appSettingLink.text }}</a
        >
        <span *ngIf="last" [matTooltip]="appSettingLink.text">{{ appSettingLink.text }}</span>
      </div>
    </div>
  </div>
</div>
