import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CategoryService } from "src/app/services/ticket-plus/category.service";
import { Category } from "src/app/models/ticket-plus/category";
import { NotificationService } from "src/app/services/utilities/notification.service";

@Component({
  selector: "app-category-dialog",
  templateUrl: "./category-dialog.component.html",
  styleUrls: ["./category-dialog.component.scss"],
})
export class AddCategoryDialogComponent implements OnInit {
  action = "Add";
  loading = false;

  @Input() category = new Category({});

  constructor(
    private dialogRef: MatDialogRef<AddCategoryDialogComponent>,
    private categoryService: CategoryService,
    @Inject(MAT_DIALOG_DATA) public data,
    private notifyService: NotificationService
  ) {}

  ngOnInit(): void {
    this.action = this.capitalizeFirstLetter(this.data.action);
    if (this.data.category) {
      this.category = Object.assign({}, this.data.category);
    }
  }

  async processForm() {
    this.loading = true;
    if (this.category.name?.trim()) {
      if (this.action !== "Delete" && this.categoryExists()) {
        this.loading = false;
        this.notifyService.notify("Category already exists.");
        return;
      }
      if (this.action == "Add" || this.action == "Edit") {
        await this.categoryService
          .addCategory(this.category)
          .catch((error) => {
            console.error(error);
          })
          .then((data) => {
            this.dialogRef.close(data);
          });
      } else if (this.action == "Delete") {
        if (this.category.children?.length > 0) {
          for (const childCategory of this.category.children) {
            await this.categoryService.deleteCategory(childCategory).catch((error) => {
              console.error(error);
            });
          }
        }
        await this.categoryService
          .deleteCategory(this.category)
          .catch((error) => {
            console.error(error);
          })
          .then((data) => {
            this.loading = false;
            this.dialogRef.close(data);
          });
      }
    } else {
      this.loading = false;
      this.notifyService.notify("Label is required.");
    }
  }

  categoryExists() {
    if (this.category.parentId) {
      const siblings = this.data.categories.find((parent) => parent.id == this.category.parentId).children;
      const existingCategory = siblings.find((category) => category.name == this.category.name);
      return existingCategory ? true : false;
    } else {
      const existingCategory = this.data.categories.find((category) => category.name == this.category.name);
      return existingCategory ? true : false;
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
