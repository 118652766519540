import { Component, Input } from "@angular/core";
import { Payload } from "src/app/services/utilities/utility.service";

@Component({
  selector: "app-chrome-os-device-logs",
  templateUrl: "./chrome-os-device-logs.component.html",
})
export class ChromeOsDeviceLogsComponent {
  @Input() payload: Payload;
  @Input() loading = true;
}
