import { Component, OnInit, ElementRef, ViewChild, Inject } from "@angular/core";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ReportsService, File } from "src/app/services/google/reports.service";
import { UsersService } from "src/app/services/admin-plus/users.service";
import { FilesService, Options } from "src/app/services/storage-plus/files.service";
import { map, startWith } from "rxjs/operators";
import { OUService } from "src/app/services/google/ou.service";
import { DrivesService, Drive } from "src/app/services/storage-plus/drives.service";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { UntypedFormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Observable } from "rxjs";
import { User } from "src/app/services/storage-plus/user.service";
import { User as gUser } from "src/app/users/users";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-fix-duplicates-dialog",
  templateUrl: "./fix-duplicates-dialog.component.html",
  styleUrls: ["./fix-duplicates-dialog.component.scss"],
})
export class FixDuplicatesDialogComponent implements OnInit {
  loadingFiles = false;
  loadingOwners = false;
  newFile = "";
  files: File[] = [];
  owners: any[] = [];
  direction = "desc";
  activeOrderBy = "storageMb";
  panelOpenState = false;
  orderBy = "givenName";
  sortOrder = "SORT_ORDER_UNDEFINED";
  directionDrive = "desc";
  activeOrderByDrive = "totalUsage";
  users: gUser[] = [];
  drives: Drive[] = [];
  userCtrl = new UntypedFormControl();
  driveCtrl = new UntypedFormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredOptions: Observable<any[]>;
  filteredOptionsDrive: Observable<any[]>;
  selected_user: string[] = [];
  primaryOwner = false;
  sharedDrive = false;
  offset = 0;
  getCount = true;
  private usageSizeSearch = "0";
  private totalUsers = "";
  private externalUsers = "";
  dataSourceFiles = new MatTableDataSource<File>();
  dataSourceOwners = new MatTableDataSource<any>();
  user: gUser;
  displayedColumns: string[] = ["name"];
  actions = [
    {
      value: "user",
      description: "Set a user as the primary owner and share with other users",
    },
    {
      value: "drive",
      description: "Move file to a shared drive and share with users",
    },
    {
      value: "trash",
      description: "Trash all duplicate files",
    },
  ];

  options = {
    fileName: null,
    googleId: null,
  };
  targetAction = null;
  targetDrive: Drive = null;
  targetUser: User = null;

  @ViewChild("userInput") userInput: ElementRef;
  @ViewChild("driveInput") driveInput: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<FixDuplicatesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private reportsService: ReportsService,
    private filesService: FilesService,
    private usersService: UsersService,
    private navService: NavService,
    private ouService: OUService,
    private drivesService: DrivesService,
    private notifyService: NotificationService
  ) {
    const tUser: string = localStorage.getItem("user") || "";
    this.user = tUser && tUser != "" ? JSON.parse(tUser) : null;
  }

  ngOnInit(): void {
    this.selected_user = [];
    this.loadData();
  }

  private async loadData() {
    await this.getFiles();
  }

  public add(event: MatChipInputEvent, type: string): void {
    const input = event.input;
    const value = event.value;

    const user: any = {
      primaryEmail: "",
    };

    const drive: any = {
      name: "",
    };

    if ((value || "").trim()) {
      if (type === "user") {
        user["primaryEmail"] = value;
      }
      if (type === "drive") {
        drive["name"] = value;
      }
      this.selected_user.push(user);
      this.targetDrive.driveId = value;
      // Reset the input value
      if (input) {
        input.value = "";
      }

      this.userCtrl.setValue(null);
      this.driveCtrl.setValue(null);
    }
  }

  remove(emailOrName: string, type: string): void {
    if (type === "user") {
      const index = this.selected_user.indexOf(emailOrName);
      if (index >= 0) {
        this.selected_user.splice(index, 1);
        this.users = [];
      }
    }
    if (type === "drive") {
      //
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.users = [];
    if (this.selected_user.length < 1) {
      this.selected_user.push(event.option.value);
    }
    this.userInput.nativeElement.value = "";
    this.userCtrl.setValue(null);
  }

  selectedDrive(event: MatAutocompleteSelectedEvent): void {
    const drive = event.option.value;
    console.log(drive);
  }

  public async getFiles() {
    this.loadingFiles = true;
    this.loadingOwners = true;
    const response = await this.reportsService.getStorageReportFiles(
      0,
      this.activeOrderBy,
      this.direction,
      "",
      "",
      "",
      "",
      "",
      0,
      "",
      0,
      "",
      0,
      "",
      "",
      "",
      true,
      "",
      "",
      this.data.file.md5Checksum
    );
    if (response) {
      // eslint-disable-next-line prefer-spread
      this.files.push.apply(this.files, response["data"]);
      this.dataSourceFiles = new MatTableDataSource(this.files);
      this.loadingFiles = false;
      const owners = await this.filesService.getMD5ChecksumOwners(this.data.file.md5Checksum);
      if (owners) {
        for (let i = 0; i < owners.length; i++) {
          if (owners[i]["userId"]) {
            this.owners.push(owners[i]);
          }
        }
      }
      this.dataSourceOwners = new MatTableDataSource(this.owners);
      this.loadingOwners = false;
    }
  }

  public async onConfirm() {
    this.navService.loading.next(true);
    const options: Options = {
      fileName: this.newFile,
      googleId: this.targetDrive.driveId ? this.targetDrive.driveId : this.targetUser.googleId,
    };
    const response = await this.filesService.fixDuplicateFiles(this.data.file.md5Checksum, this.targetAction.value, options);
    if (response) {
      this.notifyService.notify("The duplicate files are being fixed");
      this.dialogRef.close(response);
    }
    this.navService.loading.next(false);
  }

  async getDrives(searchName = "") {
    if (!this.targetDrive) {
      this.navService.loading.next(true);
      const drives = await this.drivesService.getDrives(
        this.usageSizeSearch,
        searchName,
        10,
        this.activeOrderByDrive,
        this.directionDrive,
        this.offset,
        this.getCount,
        this.totalUsers,
        this.externalUsers
      );
      if (drives) {
        this.drives = drives["data"];
      }
      this.filteredOptionsDrive = this.driveCtrl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value, "drive"))
      );
      this.navService.loading.next(false);
    }
  }

  async getUsers(searchEmail = "") {
    if (this.selected_user.length < 1) {
      this.navService.loading.next(true);
      let OU = {};
      if (!this.user.isAdmin) {
        OU = await this.ouService.getUserOUs();
      }
      const response = await this.usersService.getUsers(
        25,
        "",
        this.orderBy,
        this.sortOrder,
        searchEmail,
        "",
        "",
        "",
        this.user.isAdmin ? "/" : OU[0]["orgUnitPath"]
      );
      if (response) {
        if (response.users) {
          this.users = [];
          if (typeof response.users !== "undefined") {
            this.users = response.users;
          }
        }
      }

      this.filteredOptions = this.userCtrl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value, "user"))
      );
      this.navService.loading.next(false);
    }
  }

  private _filter(value: any, type: string): any[] {
    if (typeof value !== "object" && value !== null) {
      let filterValue = "";
      if (type === "user") {
        if (value.primaryEmail) {
          filterValue = value.primaryEmail.toLowerCase();
        }

        return this.users.filter((user) => user.primaryEmail.toLowerCase().indexOf(filterValue) === 0);
      }
      if (type === "drive") {
        if (value.name) {
          filterValue = value.name.toLowerCase();
        }

        return this.drives.filter((drive) => drive.name.toLowerCase().indexOf(filterValue) === 0);
      }
    }
  }

  search(event: any) {
    const search = event.target.value;
    if (search.length > 2) {
      this.getUsers(search);
    }
  }

  searchDrive(event: any) {
    const search = event.target.value;
    if (search.length > 2) {
      this.getDrives(search);
    }
  }
}
