import { Component, Inject, OnInit } from "@angular/core";
import { FilesService, Bucket, TransferFile, BucketFolder, Transfer } from "src/app/services/storage-plus/files.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { UntypedFormControl } from "@angular/forms";
import { CustomerService } from "src/app/services/admin-plus/customer.service";
import { ReportsService } from "src/app/services/google/reports.service";

@Component({
  selector: "app-dialog-transfer",
  templateUrl: "./dialog-transfer.component.html",
  styleUrls: ["./dialog-transfer.component.scss"],
})
export class DialogTransferComponent implements OnInit {
  actions = new UntypedFormControl();
  allowDelete = false;
  bucket = "";
  buckets: Bucket[] = [];
  bucketFolders: BucketFolder[] = [];
  disabled = false;
  files: TransferFile[] = [];
  fileCount = 0;
  loadedBucketFolders = false;
  newFolder = "";
  newFolderInput = false;
  totalJobs = 0;
  totalSize = 0;
  transferFiles: TransferFile[] = [];
  topText = "";
  transfer: Transfer = null;

  constructor(
    private dialogRef: MatDialogRef<DialogTransferComponent>,
    private filesService: FilesService,
    private notifyService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customerService: CustomerService,
    private reportsService: ReportsService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  async loadData() {
    this.transfer = this.data;
    this.loadedBucketFolders = false;
    this.disabled = true;
    this.transfer.bucketName = this.customerService.customer["gcsBucket"];

    if (this.transfer.files) {
      this.files = this.transfer.files;
      this.fileCount = this.files.length;
    }

    this.totalJobs = Math.ceil(this.fileCount / 25);

    for (const file of this.files) {
      this.totalSize += file["storageMb"];
    }

    this.topText = "Are you sure you want to transfer ";
    this.topText += !this.transfer.isDrive
      ? "<b>" + this.files.length + "</b> files at " + (this.totalSize / 1024).toFixed(2) + " GB</b>"
      : this.transfer.isDrive && this.transfer.drive.type == "drive"
      ? "the <i>ENTIRE</i> contents of <b>" + this.transfer.drive.name + "</b>"
      : this.transfer.isDrive && this.transfer.drive.type == "user"
      ? "the <i>ENTIRE</i> contents of <b>" + this.transfer.drive.name + "'s</b> drive"
      : "";
    this.topText += " to <b>";
    this.topText += this.transfer.bucketName;
    this.topText += "</b>?";

    this.bucketFolders = await this.filesService.getBucketFolders(this.transfer.bucketName);
    this.actions.setValue("");
    this.disabled = false;
    this.loadedBucketFolders = true;
  }

  folderSelect(value: string) {
    this.newFolderInput = value == "[]";
    this.newFolder = "";
    this.disabled = value.length > 765;
  }

  async onConfirm() {
    this.files.map((file) => (file["busy"] = 1));
    this.disabled = true;
    let bucketFolder: string = this.actions.value == "[]" ? this.newFolder.trim() : this.actions.value;
    bucketFolder += bucketFolder == "" ? "" : bucketFolder.endsWith("/") ? "" : "/";

    this.transfer.folderPath = bucketFolder;

    if (!this.transfer.isDrive) {
      let filesProcessed = 0;
      let jobCount = 1;
      for (const file of this.files) {
        const user = await this.reportsService.getStorageReportUser(file["userId"]);
        const transferFile: TransferFile = {
          id: file["id"],
          fileId: file["googleId"],
          name: file["name"],
          googleId: user.googleId,
        };
        this.transferFiles.push(transferFile);
        filesProcessed++;
        if (filesProcessed % 25 == 0 || filesProcessed == this.fileCount) {
          this.transfer.files = this.transferFiles;
          const response = await this.filesService.transferFilesToGcp(this.transfer);
          if (response) {
            this.notifyService.notify(`Job ${jobCount} of ${this.totalJobs} created - Transfer to GCP`);
            this.customerService.running = true;
          } else {
            this.notifyService.notify(`Job ${jobCount} of ${this.totalJobs} not created - An error occurred`);
          }
          this.transferFiles = [];
          jobCount++;
          if (filesProcessed == this.fileCount) this.dialogRef.close(response);
        }
      }
    } else {
      const response = await this.filesService.transferFilesToGcp(this.transfer);
      if (response) {
        this.notifyService.notify(`Job created - Transfer to GCP`);
        this.customerService.running = true;
      } else {
        this.notifyService.notify(`Job not created - An error occurred`);
      }
      this.dialogRef.close(response);
    }
  }
}
