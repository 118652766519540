import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Group, GroupsService } from "src/app/services/admin-plus/groups.service";
import { NavService } from "src/app/services/admin-plus/nav.service";

@Component({
  selector: "app-delete-group-dialog",
  templateUrl: "./delete-groups-dialog.component.html",
  styleUrls: ["./delete-groups-dialog.component.scss"],
})
export class DeleteGroupsDialogComponent implements OnInit {
  public groups: Group[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private groupsService: GroupsService,
    private dialogRef: MatDialogRef<DeleteGroupsDialogComponent>,
    private navService: NavService
  ) {}

  ngOnInit(): void {
    this.groups = this.data.groups;
  }

  async delete() {
    this.navService.loading.next(true);
    const deletedGroups: Group[] = [];
    for (let i = 0; i < this.groups.length; i++) {
      const response = await this.groupsService.deleteGroup(this.groups[i].id);
      if (response) deletedGroups.push(this.groups[i]);
    }
    this.navService.loading.next(false);
    this.dialogRef.close(deletedGroups);
  }
}
