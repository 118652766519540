import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Building } from "src/app/models/admin-plus/building";
import { BuildingsService } from "src/app/services/admin-plus/buildings.service";

@Component({
	selector: "app-buildings",
	templateUrl: "./buildings.component.html",
})
export class BuildingsInputComponent implements OnInit {
	@Input() selectedBuilding: Building;
	@Input() parentLoading = false;
	@Output() buildingChange = new EventEmitter();

	loading = false;
	loadingRooms = false;
	buildings: Building[];

	constructor(private buildingsService: BuildingsService) {}

	async ngOnInit(): Promise<void> {
		this.loading = true;
		this.buildings = await (await this.buildingsService.getBuildings())?.data;
		this.loading = false;
	}

	async onBuildingChange() {
		this.buildingChange.emit(this.selectedBuilding);
	}

	compare(o1, o2) {
		return o1 && o2 ? o1.id == o2.id : false;
	}
}
