import { Component, OnInit, Inject, ElementRef, ViewChild } from "@angular/core";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { GroupsService } from "../../../services/admin-plus/groups.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NotificationService } from "../../../services/utilities/notification.service";
import { UntypedFormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { Group } from "../../../groups/group";
import { NavService } from "src/app/services/admin-plus/nav.service";

@Component({
  selector: "app-select-groups",
  templateUrl: "./select-groups.component.html",
  styleUrls: ["./select-groups.component.scss"],
})
export class SelectGroupsComponent implements OnInit {
  public groups: Group[] = [];
  public members: [];
  public selected_groups: string[];
  public urlID: string;
  public parentGroupID: string;
  public display: boolean;
  public email: string;
  public removable = true;
  public filteredOptions: Observable<any[]>;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public myControl = new UntypedFormControl();
  public groups_attempted_to_add = 0;
  public groups_failed_to_add = 0;
  public groups_added = [];

  @ViewChild("userInput") userInput: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private groupsService: GroupsService,
    private notifyService: NotificationService,
    private navService: NavService,
    public dialogRef: MatDialogRef<SelectGroupsComponent>
  ) {}

  public add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || "").trim()) {
      this.selected_groups.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }

    this.myControl.setValue(null);
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    this.selected_groups.push(event.option.value);
    this.userInput.nativeElement.value = "";
    this.myControl.setValue(null);
  }

  public remove(id: string): void {
    const index = this.selected_groups.indexOf(id);
    if (index >= 0) {
      this.selected_groups.splice(index, 1);
    }
  }

  async getGroups(maxResults = 500, pageToken = "") {
    const response = await this.groupsService.getGroups(maxResults, pageToken);
    if (response) {
      if (response["groups"]) {
        this.groups.push.apply(this.groups, response["groups"]);
      }

      if (!response["nextPageToken"]) {
        //
      } else {
        this.getGroups(maxResults, response["nextPageToken"]);
      }

      for (let i = this.groups.length - 1; i >= 0; i--) {
        this.groups[i]["visible"] = true;
        for (let j = 0; j < this.data.groups.length; j++) {
          if (this.data.groups[j]["name"] === this.groups[i]["name"]) {
            this.groups[i]["visible"] = false;
          }
        }
      }

      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value))
      );
    } else {
      this.notifyService.notify(response["error"]["message"]);
    }
  }

  public processForm() {
    for (let i = 0; i < this.data.groups.length; i++) {
      for (let j = 0; j < this.selected_groups.length; j++) {
        this.saveSubGroups(this.data.groups[i].id, this.selected_groups[j]);
      }
    }
  }

  public async saveSubGroups(id: string, member: any) {
    this.navService.loading.next(true);
    const newMember: any = {
      roles: [
        {
          name: "MEMBER",
        },
      ],
      preferredMemberKey: {
        id: member.email,
      },
    };
    const response = await this.groupsService.addMember(id, newMember);
    if (response) {
      this.groups_attempted_to_add++;
      if (response !== undefined) {
        if (!response["error"]) {
          this.groups_added.push(response);
        }
      } else {
        this.groups_failed_to_add++;
      }

      if (this.groups_attempted_to_add == this.selected_groups.length) {
        if (this.groups_added.length == this.selected_groups.length) {
          this.notifyService.notify(
            "You successfully added " + this.groups_added.length + " member" + (this.groups_added.length > 1 ? "s" : "")
          );
        } else {
          this.notifyService.notify(
            "Failed to add " + this.groups_failed_to_add + " member" + (this.groups_failed_to_add > 1 ? "s" : "")
          );
        }
        this.dialogRef.close(this.groups_added);
      }
      this.navService.loading.next(false);
    }
  }

  private _filter(value: any): any[] {
    if (typeof value !== "object" && value !== null) {
      const filterValue = value.toLowerCase();

      return this.groups.filter((group) => group.id.toLowerCase().indexOf(filterValue) === 0 && group.visible);
    }
  }

  ngOnInit(): void {
    this.selected_groups = [];
    this.getGroups();
  }
}
