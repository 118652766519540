import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ChartSelectionChangedEvent, ChartType } from "angular-google-charts";
import { StorageReport, ReportsService } from "src/app/services/google/reports.service";

@Component({
  selector: "app-storage-report-ou-pie-chart",
  templateUrl: "./storage-report-ou-pie-chart.component.html",
  styleUrls: ["./storage-report-ou-pie-chart.component.scss"],
})
export class StorageReportOuPieChartComponent {
  @Input() ous: StorageReport[] = [];
  type: ChartType = ChartType.PieChart;
  public unit = "";
  data: unknown[];
  options = {
    chartArea: { width: "90%", height: "90%" },
    legend: "none",
    colors: ["#114c71", "#FFAA01", "#008000", "#FF0000", "#F11100", "#099000", "#3333FF", "#003344", "#AA0022"],
    sliceVisibilityThreshold: 0,
    title: `Usage ${this.unit}`,
  };

  constructor(private reportsService: ReportsService, private router: Router) {
    this.data = [];
  }

  onPieSelect(event: ChartSelectionChangedEvent) {
    for (let i = 0; i < this.ous.length; i++) {
      if (event.selection[0].row === i) {
        if (this.ous[i]?.id != "") {
          this.router.navigate([`/reports/storage/organizational-units/${this.ous[i].id}/users`]);
        }
      }
    }
  }

  ngOnChanges() {
    if (this.ous && this.ous.length) {
      this.unit = this.reportsService.calculateUnit(this.ous[0].totalStorage);
      this.options.title = `Usage ${this.unit}`;
    }
    this.data = this.ous.map((ou) => [ou.name, this.reportsService.calculateValue(ou.totalStorage, this.unit)]);
  }
}
