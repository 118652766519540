<div class="card-header d-flex justify-content-between">
  <h2 class="mb-0">Drive Users</h2>
  <button
    *ngIf="storageReport.status.id == 4"
    class="success"
    mat-stroked-button
    (click)="driveUsersSpreadsheet()"
    matTooltip="Export to Google Sheets"
  >
    <mat-icon> description </mat-icon>
    <span>Export to Sheets</span>
  </button>
</div>

<div class="">
  <div class="row">
    <div class="col-12 mt-2">
      <app-filters [filterType]="filterTypeValue" (dataEvent)="receiveData($event)"></app-filters>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div id="pdfTable" #pdfTable>
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
          <ng-container matColumnDef="emailAddress">
            <th class="px-3 text-nowrap" mat-header-cell mat-sort-header *matHeaderCellDef>Email Address</th>
            <td class="py-3 text-nowrap px-3" mat-cell *matCellDef="let row">
              {{ row.emailAddress }}
            </td>
          </ng-container>
          <ng-container matColumnDef="displayName">
            <th class="px-3 text-nowrap" mat-header-cell *matHeaderCellDef>Name</th>
            <td class="p-3" mat-cell *matCellDef="let row">
              {{ row.displayName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th class="px-3 text-nowrap" mat-header-cell mat-sort-header *matHeaderCellDef>Type</th>
            <td class="p-3" mat-cell *matCellDef="let row">
              {{ row.type }}
            </td>
          </ng-container>
          <ng-container matColumnDef="role">
            <th class="px-3 text-nowrap" mat-header-cell mat-sort-header *matHeaderCellDef>Role</th>
            <td class="p-3" mat-cell *matCellDef="let row">
              <span *ngIf="row.role === 'fileOrganizer'">Content Manager</span>
              <span *ngIf="row.role === 'reader'">Viewer</span>
              <span *ngIf="row.role === 'commenter'">Commenter</span>
              <span *ngIf="row.role === 'writer'">Contributor</span>
              <span *ngIf="row.role === 'organizer'">Manager</span>
              <span
                *ngIf="
                  row.role !== 'fileOrganizer' &&
                  row.role !== 'reader' &&
                  row.role !== 'organizer' &&
                  row.role !== 'commenter' &&
                  row.role !== 'writer'
                "
              >
                {{ row.role }}
              </span>
            </td>
          </ng-container>
          <ng-container *ngIf="storageReport.status.id == 4 && !storageReport.driveUsersSynced">
            <ng-container matColumnDef="syncing">
              <th mat-header-cell *matHeaderCellDef [attr.colspan]="99"  class="alert alert-info">
                <span class="material-icons mat-icon faa-spin mr-3"> filter_tilt_shift </span>
                Shared drive users are still syncing...
              </th>
            </ng-container>
          </ng-container>
          <tr class="mat-row" app-empty-dataset-alert [loading]="loading" [filterCount]="data.filtersCount" *matNoDataRow></tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <ng-container *ngIf="storageReport.status.id == 4 && !storageReport.driveUsersSynced">
            <tr mat-header-row *matHeaderRowDef="['syncing']"></tr>
          </ng-container>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <app-table-footer
      [dataSource]="dataSource"
      [maxResults]="maxResults"
      [nextButton]="nextButton"
      [nextButtonDis]="nextButtonDis"
      [offset]="offset"
      [page]="page"
      [total]="totalDriveUserCount"
      (pager)="setPager($event)"
    ></app-table-footer>
    </div>
  </div>
</div>
