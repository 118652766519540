import { CategoryService } from "src/app/services/ticket-plus/category.service";
import { Rule } from "./rule";

export interface ICategory {
	id: number;
	parentId: number;
	name: string;
	children: Category[];
	rules?: Rule[];
	edit?: boolean;
	createCategory(): Promise<Category>;
	deleteCategory(): Promise<Category>;
}

export class Category implements ICategory {
	id: number;
	parentId: number;
	name: string;
	children: Category[];
	rules?: Rule[];
	edit?: boolean;
	categoryService: CategoryService;

	constructor(properties) {
		Object.assign(this, properties);
	}

	async createCategory() {
		await this.categoryService.addCategory(this).catch((error) => {
			console.error(error);
		});
		return this;
	}

	async deleteCategory() {
		await this.categoryService.deleteCategory(this).catch((error) => {
			console.error(error);
		});
		return this;
	}
}
