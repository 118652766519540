import { Injectable } from "@angular/core";
import { UtilityService } from "../utilities/utility.service";
import { BreadCrumb } from "src/app/models/admin-plus/bread-crumbs";

@Injectable({
  providedIn: "root",
})
export class AdminPlusService {
  disabledHomeLinks = ["/dashboard", "/login", "/logout"];
  breadCrumbLinks = [];
  breadCrumbs: BreadCrumb[] = [
    {
      url: "/login",
      links: [],
    },
    {
      url: "/logout",
      links: [],
    },
    {
      url: "/dashboard",
      links: [
        {
          link: "/dashboard",
          text: "Dashboard",
          alt: "Dashboard",
        },
      ],
    },
    {
      url: "/users",
      links: [
        {
          link: "/users",
          text: "Users",
          alt: "Users",
        },
      ],
    },
    {
      url: "/groups",
      links: [
        {
          link: "/groups",
          text: "Groups",
          alt: "Groups",
        },
      ],
    },
    {
      url: "/reports",
      links: [
        {
          link: "/reports/storage",
          text: "Storage",
          alt: "Storage",
        },
      ],
    },
    {
      url: "/automation",
      links: [
        {
          link: "/automation/dashboard",
          text: "Workflows",
          alt: "Workflows",
        },
      ],
    },
    {
      url: "/assets",
      links: [
        {
          link: "/assets",
          text: "Assets",
          alt: "Assets",
        },
      ],
    },
    {
      url: "/ticketing",
      links: [
        {
          link: "/ticketing/summary",
          text: "Tickets",
          alt: "Tickets",
        },
      ],
    },
    {
      url: "/settings",
      links: [
        {
          link: "/settings",
          text: "Settings",
          alt: "Settings",
        },
      ],
    },
    {
      url: "/product-updates",
      links: [
        {
          link: "/product-updates",
          text: "Product Updates",
          alt: "Product Updates",
        },
      ],
    },
  ];
  userParam: any = {
    id: 2,
    parameter: "USER_EMAIL",
    name: "User",
    object: {
      id: 1,
      properties: [
        {
          id: 8,
          object: {
            id: 1,
            parentId: null,
          },
          name: "Change password at login",
          objectKey: "changePasswordAtNextLogin",
          operators: [
            {
              property: {
                id: 8,
              },
              operator: {
                id: 1,
                name: "Is",
                symbol: "=",
              },
              object: {
                id: 1,
              },
            },
            {
              property: {
                id: 8,
              },
              operator: {
                id: 2,
                name: "Is not",
                symbol: "!=",
              },
              object: {
                id: 1,
              },
            },
          ],
          type: "text",
          enums: ["true", "false"],
          methods: [
            {
              id: 1,
              method: "GET",
              scope: "https://www.googleapis.com/auth/admin.directory.user.readonly",
              objectId: 1,
              requestData: {
                api: "admin",
                apiVersion: "directory_v1",
                resource: "users",
                method: "get",
                params: '{"userKey": "${apiValue}", "projection": "full"}',
              },
            },
          ],
          selector: "enum",
        },
        {
          id: 170,
          object: {
            id: 1,
            parentId: null,
          },
          name: "Custom Attribute",
          objectKey: "customSchemas.",
          operators: [
            {
              property: {
                id: 170,
              },
              operator: {
                id: 1,
                name: "Is",
                symbol: "=",
              },
              object: {
                id: 1,
              },
            },
            {
              property: {
                id: 170,
              },
              operator: {
                id: 2,
                name: "Is not",
                symbol: "!=",
              },
              object: {
                id: 1,
              },
            },
          ],
          type: "text",
          enums: [],
          methods: [
            {
              id: 1,
              method: "GET",
              scope: "https://www.googleapis.com/auth/admin.directory.user.readonly",
              objectId: 1,
              requestData: {
                api: "admin",
                apiVersion: "directory_v1",
                resource: "users",
                method: "get",
                params: '{"userKey": "${apiValue}", "projection": "full"}',
              },
            },
          ],
          selector: "tree",
        },
        {
          id: 1,
          object: {
            id: 1,
            parentId: null,
          },
          name: "Email",
          objectKey: "primaryEmail",
          operators: [
            {
              property: {
                id: 1,
              },
              operator: {
                id: 1,
                name: "Is",
                symbol: "=",
              },
              object: {
                id: 1,
              },
            },
            {
              property: {
                id: 1,
              },
              operator: {
                id: 2,
                name: "Is not",
                symbol: "!=",
              },
              object: {
                id: 1,
              },
            },
            {
              property: {
                id: 1,
              },
              operator: {
                id: 3,
                name: "Contains",
                symbol: "LIKE",
              },
              object: {
                id: 1,
              },
            },
            {
              property: {
                id: 1,
              },
              operator: {
                id: 4,
                name: "Does not contain",
                symbol: "NOT LIKE",
              },
              object: {
                id: 1,
              },
            },
          ],
          type: "text",
          enums: [],
          methods: [
            {
              id: 1,
              method: "GET",
              scope: "https://www.googleapis.com/auth/admin.directory.user.readonly",
              objectId: 1,
              requestData: {
                api: "admin",
                apiVersion: "directory_v1",
                resource: "users",
                method: "get",
                params: '{"userKey": "${apiValue}", "projection": "full"}',
              },
            },
          ],
          selector: "email",
        },
        {
          id: 177,
          object: {
            id: 1,
            parentId: null,
          },
          name: "Employee Information",
          objectKey: "employeeInformation.",
          operators: [
            {
              property: {
                id: 177,
              },
              operator: {
                id: 1,
                name: "Is",
                symbol: "=",
              },
              object: {
                id: 1,
              },
            },
            {
              property: {
                id: 177,
              },
              operator: {
                id: 2,
                name: "Is not",
                symbol: "!=",
              },
              object: {
                id: 1,
              },
            },
            {
              property: {
                id: 177,
              },
              operator: {
                id: 3,
                name: "Contains",
                symbol: "LIKE",
              },
              object: {
                id: 1,
              },
            },
            {
              property: {
                id: 177,
              },
              operator: {
                id: 4,
                name: "Does not contain",
                symbol: "NOT LIKE",
              },
              object: {
                id: 1,
              },
            },
          ],
          type: "text",
          enums: [],
          methods: [
            {
              id: 1,
              method: "GET",
              scope: "https://www.googleapis.com/auth/admin.directory.user.readonly",
              objectId: 1,
              requestData: {
                api: "admin",
                apiVersion: "directory_v1",
                resource: "users",
                method: "get",
                params: '{"userKey": "${apiValue}", "projection": "full"}',
              },
            },
          ],
          selector: "dropdown",
        },
        {
          id: 2,
          object: {
            id: 1,
            parentId: null,
          },
          name: "First name",
          objectKey: "name.givenName",
          operators: [
            {
              property: {
                id: 2,
              },
              operator: {
                id: 1,
                name: "Is",
                symbol: "=",
              },
              object: {
                id: 1,
              },
            },
            {
              property: {
                id: 2,
              },
              operator: {
                id: 2,
                name: "Is not",
                symbol: "!=",
              },
              object: {
                id: 1,
              },
            },
            {
              property: {
                id: 2,
              },
              operator: {
                id: 3,
                name: "Contains",
                symbol: "LIKE",
              },
              object: {
                id: 1,
              },
            },
            {
              property: {
                id: 2,
              },
              operator: {
                id: 4,
                name: "Does not contain",
                symbol: "NOT LIKE",
              },
              object: {
                id: 1,
              },
            },
          ],
          type: "text",
          enums: [],
          methods: [
            {
              id: 1,
              method: "GET",
              scope: "https://www.googleapis.com/auth/admin.directory.user.readonly",
              objectId: 1,
              requestData: {
                api: "admin",
                apiVersion: "directory_v1",
                resource: "users",
                method: "get",
                params: '{"userKey": "${apiValue}", "projection": "full"}',
              },
            },
          ],
          selector: null,
        },
        {
          id: 160,
          object: {
            id: 6,
            parentId: 1,
          },
          name: "Group Name",
          objectKey: "name",
          operators: [
            {
              property: {
                id: 160,
              },
              operator: {
                id: 1,
                name: "Is",
                symbol: "=",
              },
              object: {
                id: 6,
              },
            },
            {
              property: {
                id: 160,
              },
              operator: {
                id: 2,
                name: "Is not",
                symbol: "!=",
              },
              object: {
                id: 6,
              },
            },
          ],
          type: "text",
          enums: [],
          methods: [
            {
              id: 5,
              method: "GET",
              scope: "https://www.googleapis.com/auth/admin.directory.group.readonly",
              objectId: 6,
              requestData: {
                api: "admin",
                apiVersion: "directory_v1",
                resource: "groups",
                method: "list",
                params:
                  '{"domain": "\'${apiValue}\'.split(\'@\')[1]", "fields": "groups.${field}", "userKey": "${apiValue}"}',
              },
            },
          ],
          selector: null,
        },
        {
          id: 4,
          object: {
            id: 1,
            parentId: null,
          },
          name: "Last Login",
          objectKey: "lastLoginTime",
          operators: [
            {
              property: {
                id: 4,
              },
              operator: {
                id: 5,
                name: "Before",
                symbol: "<",
              },
              object: {
                id: 1,
              },
            },
            {
              property: {
                id: 4,
              },
              operator: {
                id: 6,
                name: "After",
                symbol: ">",
              },
              object: {
                id: 1,
              },
            },
          ],
          type: "date",
          enums: [],
          methods: [
            {
              id: 1,
              method: "GET",
              scope: "https://www.googleapis.com/auth/admin.directory.user.readonly",
              objectId: 1,
              requestData: {
                api: "admin",
                apiVersion: "directory_v1",
                resource: "users",
                method: "get",
                params: '{"userKey": "${apiValue}", "projection": "full"}',
              },
            },
          ],
          selector: null,
        },
        {
          id: 3,
          object: {
            id: 1,
            parentId: null,
          },
          name: "Last name",
          objectKey: "name.familyName",
          operators: [
            {
              property: {
                id: 3,
              },
              operator: {
                id: 1,
                name: "Is",
                symbol: "=",
              },
              object: {
                id: 1,
              },
            },
            {
              property: {
                id: 3,
              },
              operator: {
                id: 2,
                name: "Is not",
                symbol: "!=",
              },
              object: {
                id: 1,
              },
            },
            {
              property: {
                id: 3,
              },
              operator: {
                id: 3,
                name: "Contains",
                symbol: "LIKE",
              },
              object: {
                id: 1,
              },
            },
            {
              property: {
                id: 3,
              },
              operator: {
                id: 4,
                name: "Does not contain",
                symbol: "NOT LIKE",
              },
              object: {
                id: 1,
              },
            },
          ],
          type: "text",
          enums: [],
          methods: [
            {
              id: 1,
              method: "GET",
              scope: "https://www.googleapis.com/auth/admin.directory.user.readonly",
              objectId: 1,
              requestData: {
                api: "admin",
                apiVersion: "directory_v1",
                resource: "users",
                method: "get",
                params: '{"userKey": "${apiValue}", "projection": "full"}',
              },
            },
          ],
          selector: null,
        },
        {
          id: 6,
          object: {
            id: 1,
            parentId: null,
          },
          name: "Mailbox setup",
          objectKey: "isMailboxSetup",
          operators: [
            {
              property: {
                id: 6,
              },
              operator: {
                id: 1,
                name: "Is",
                symbol: "=",
              },
              object: {
                id: 1,
              },
            },
            {
              property: {
                id: 6,
              },
              operator: {
                id: 2,
                name: "Is not",
                symbol: "!=",
              },
              object: {
                id: 1,
              },
            },
          ],
          type: "text",
          enums: ["true", "false"],
          methods: [
            {
              id: 1,
              method: "GET",
              scope: "https://www.googleapis.com/auth/admin.directory.user.readonly",
              objectId: 1,
              requestData: {
                api: "admin",
                apiVersion: "directory_v1",
                resource: "users",
                method: "get",
                params: '{"userKey": "${apiValue}", "projection": "full"}',
              },
            },
          ],
          selector: "enum",
        },
        {
          id: 5,
          object: {
            id: 1,
            parentId: null,
          },
          name: "Organizational Unit",
          objectKey: "orgUnitPath",
          operators: [
            {
              property: {
                id: 5,
              },
              operator: {
                id: 1,
                name: "Is",
                symbol: "=",
              },
              object: {
                id: 1,
              },
            },
            {
              property: {
                id: 5,
              },
              operator: {
                id: 2,
                name: "Is not",
                symbol: "!=",
              },
              object: {
                id: 1,
              },
            },
            {
              property: {
                id: 5,
              },
              operator: {
                id: 3,
                name: "Contains",
                symbol: "LIKE",
              },
              object: {
                id: 1,
              },
            },
            {
              property: {
                id: 5,
              },
              operator: {
                id: 4,
                name: "Does not contain",
                symbol: "NOT LIKE",
              },
              object: {
                id: 1,
              },
            },
          ],
          type: "ou",
          enums: [],
          methods: [
            {
              id: 1,
              method: "GET",
              scope: "https://www.googleapis.com/auth/admin.directory.user.readonly",
              objectId: 1,
              requestData: {
                api: "admin",
                apiVersion: "directory_v1",
                resource: "users",
                method: "get",
                params: '{"userKey": "${apiValue}", "projection": "full"}',
              },
            },
          ],
          selector: "ou",
        },
        {
          id: 7,
          object: {
            id: 1,
            parentId: null,
          },
          name: "Super administrator",
          objectKey: "isAdmin",
          operators: [
            {
              property: {
                id: 7,
              },
              operator: {
                id: 1,
                name: "Is",
                symbol: "=",
              },
              object: {
                id: 1,
              },
            },
            {
              property: {
                id: 7,
              },
              operator: {
                id: 2,
                name: "Is not",
                symbol: "!=",
              },
              object: {
                id: 1,
              },
            },
          ],
          type: "text",
          enums: ["true", "false"],
          methods: [
            {
              id: 1,
              method: "GET",
              scope: "https://www.googleapis.com/auth/admin.directory.user.readonly",
              objectId: 1,
              requestData: {
                api: "admin",
                apiVersion: "directory_v1",
                resource: "users",
                method: "get",
                params: '{"userKey": "${apiValue}", "projection": "full"}',
              },
            },
          ],
          selector: "enum",
        },
      ],
    },
  };
  constructor(public utilityService: UtilityService) {}
}
