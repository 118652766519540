import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";
import { UntypedFormControl } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { GroupsService } from "src/app/services/admin-plus/groups.service";
import { MembersDialogComponent } from "src/app/groups/group/members-dialog/members-dialog.component";
import { DeleteDialogComponent } from "./dialogs/delete-dialog/delete-dialog.component";
import { EditMemberRolesComponent } from "./dialogs/edit-member-roles/edit-member-roles.component";
import { SelectGroupsMembersComponent } from "src/app/layout/dialogs/select-groups-members/select-groups-members.component";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { ExportsheetsService } from "src/app/services/google/sheets.service";
import { Router } from "@angular/router";
import { User } from "src/app/web/login/user";
import { merge, of as observableOf } from "rxjs";
import { startWith, switchMap, map, catchError } from "rxjs/operators";
import { Membership } from "src/app/groups/groups";

@Component({
  selector: "app-members",
  templateUrl: "./members.component.html",
  styleUrls: ["./members.component.scss"],
})
export class MembersComponent implements OnInit {
  private id: string;
  public nextPageToken = "";
  public pageToken = "";
  public user: User;
  public selected_group;
  public membership;
  public members: [] = [];
  public displayedColumns: string[] = ["select", "preferredMemberKey.id", "role"];
  public selection = new SelectionModel(true, []);
  public dataSource = new MatTableDataSource();
  public columns = new UntypedFormControl();
  public roles = ["MEMBER", "MANAGER"];
  public actions = new UntypedFormControl();
  public maxResults = 10;
  private paginator: MatPaginator;
  @Input() enterprise = false;
  memberPermissionSet = false;
  memberPermission = false;
  public page = 1;
  public offset = 0;
  public totalGroupCount = 0;
  public prevPageToken = "";
  public pageTokens = [""];
  public isFetchingData: boolean;
  public loading = false;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.dataSource.paginator = this.paginator;
  }
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private groupsService: GroupsService,
    private navService: NavService,
    private exportSheetsService: ExportsheetsService,
    private router: Router
  ) {
    this.isFetchingData = false;
    const user: User = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.user = user;
    }
    this.isFetchingData = false;
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get("id");
    this.columns.setValue(this.displayedColumns);
    this.getMembers();
    this.getGroup(this.id);
    this.actions.valueChanges.subscribe((value) => {
      if (value == "delete") {
        this.openDeleteDialog();
      } else {
        return;
      }
    });
  }

  async loadMembersTable(arrow = "") {
    this.navService.loading.next(true);
    this.isFetchingData = true;
    let arrowToken = "";
    if (arrow === "prev") {
      this.page--;
      this.selection.clear(); // QUICK FIX
      this.setTokens(this.page);
      arrowToken = this.prevPageToken;
    } else if (arrow === "next") {
      this.page++;
      this.selection.clear(); // QUICK FIX
      arrowToken = this.nextPageToken;
      this.setTokens(this.page);
    }
    this.getMembers(arrowToken);
    this.navService.loading.next(false);
  }

  async getMembers(arrowToken = "") {
    this.isFetchingData = true;
    this.navService.loading.next(true);
    this.loading = true;
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.groupsService.getMembers(this.id, this.maxResults, arrowToken);
        }),
        map(async (members) => {
          this.loading = false;
          if (members) {
            this.memberPermission = true;
            if (members["memberships"]) {
              const memberships = this.calcRoles(members["memberships"]);
              this.members = memberships;
            }
            this.nextPageToken = members.nextPageToken;
            this.dataSource = new MatTableDataSource(this.members);
            this.dataSource.filterPredicate = (data: Membership, filter: string) => {
              delete data.createTime;
              delete data.name;
              delete data.roles;
              delete data.type;
              delete data.updateTime;
              const accumulator = (membership, key) => {
                return this.nestedFilterCheck(membership, data, key);
              };
              const dataStr = Object.keys(data).reduce(accumulator, "").toLowerCase();
              // Transform the filter by converting it to lowercase and removing whitespace.
              const transformedFilter = filter.trim().toLowerCase();
              return dataStr.indexOf(transformedFilter) !== -1;
            };
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
          this.dataSource.sortingDataAccessor = (data, sortHeaderId: string) => {
            if (typeof data[sortHeaderId] === "string") {
              return data[sortHeaderId].toLowerCase();
            }
            return data[sortHeaderId];
          };
          this.navService.loading.next(false);
          this.isFetchingData = false;
          if (members.nextPageToken) {
            if (this.page == this.pageTokens.length) {
              this.pageTokens.push(members.nextPageToken);
              this.nextPageToken = members.nextPageToken;
            }
          } else {
            this.nextPageToken = "";
          }
          this.navService.loading.next(false);
          this.isFetchingData = false;
          return members;
        }),
        catchError(() => {
          this.navService.loading.next(false);
          this.loading = false;
          this.isFetchingData = false;
          return observableOf([]);
        })
      )
      .subscribe((members) => {
        if (members) {
          //This has to be here to load members.
        }
        this.navService.loading.next(false);
        this.isFetchingData = false;
      });
  }

  nestedFilterCheck(search, data, key) {
    if (typeof data[key] === "object") {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }

  getProperty = (obj, path) => path.split(".").reduce((o, p) => o && o[p], obj);

  private calcRoles(members): [] {
    for (let i = 0; i < members.length; i++) {
      members[i]["role"] = this.calcRole(members[i]["roles"]);
    }
    return members;
  }

  private calcRole(roles): string {
    let role = "";
    const roleHierarchy = ["", "MEMBER", "MANAGER", "OWNER"];

    for (let i = 0; i < roles.length; i++) {
      if (roleHierarchy.indexOf(role) < roleHierarchy.indexOf(roles[i]["name"])) {
        role = roles[i]["name"];
      }
    }
    return role;
  }

  async addMembersData(spreadsheetID: string) {
    const newGroupMembershipsObject = await this.exportSheetsService.createSpreadsheetArray(
      this.displayedColumns,
      this.dataSource["filteredData"]
    );
    if (newGroupMembershipsObject) {
      await this.exportSheetsService.addExportData(
        newGroupMembershipsObject,
        spreadsheetID,
        "Sheet1!A1:G" + newGroupMembershipsObject["values"].length
      );
    }
  }

  async groupMemebershipsSpreadsheet() {
    this.navService.loading.next(true);
    const spreadsheetID = await this.exportSheetsService.createSpreadsheet("Members");
    await this.addMembersData(spreadsheetID);
    window.open("https://docs.google.com/spreadsheets/d/" + spreadsheetID);
    this.navService.loading.next(false);
  }

  async getGroup(id: string) {
    this.navService.loading.next(true);
    const response = await this.groupsService.getGroup(id);
    if (response) {
      this.selected_group = response;
      this.groupsService.selected_group = this.selected_group;
      this.membership = {
        role: "",
      };
      const memResponse = await this.groupsService.getMember(response["name"].replace("groups/", ""));
      if (memResponse) {
        if (memResponse["roles"][0]["name"] !== undefined || memResponse["roles"][0]["name"] !== "") {
          this.membership = memResponse["roles"][0]["name"];
          this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      }
      this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
    this.navService.loading.next(false);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /* Dialogs */
  openDialog() {
    const dialogRef = this.dialog.open(MembersDialogComponent, {
      disableClose: true,
      width: "500px",
      data: {
        members: this.dataSource.data,
        id: this.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const data = this.dataSource.data;
        for (let i = 0; i < result.length; i++) {
          result[i].role = this.calcRole(result[i]["roles"]);
          data.unshift(result[i]);
        }

        this.dataSource.data = data;
      }
    });
  }

  openSubGroupDialog() {
    const dialogRef = this.dialog.open(SelectGroupsMembersComponent, {
      disableClose: true,
      width: "500px",
      data: {
        groups: this.dataSource.data,
        id: this.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const data = this.dataSource.data;
        for (let i = 0; i < result.length; i++) {
          result[i].role = this.calcRole(result[i]["roles"]);
          data.unshift(result[i]);
        }
        this.dataSource.data = data;
      }
    });
  }

  openDeleteDialog() {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      disableClose: true,
      width: "600px",
      data: {
        members: this.selection.selected,
        id: this.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const data = this.dataSource.data;
        for (let i = data.length - 1; i >= 0; i--) {
          for (let j = 0; j < result.length; j++) {
            if (data[i]) {
              if (data[i]["name"] == result[j]) {
                data.splice(i, 1);
              }
            }
          }
        }
        this.dataSource.data = data;
        this.selection.clear();
        this.actions.setValue("");
      } else {
        this.selection.clear();
        this.actions.setValue("");
      }
    });
  }

  openEditRolesDialog(member) {
    const dialogRef = this.dialog.open(EditMemberRolesComponent, {
      disableClose: true,
      width: "700px",
      data: {
        member: member,
        id: this.id,
        memberID: member.id,
        enterprise: this.enterprise,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //loop over members and recreate the new (edited) one
        const data = this.dataSource.data;
        for (let i = 0; i < data.length; i++) {
          if (data[i]["preferredMemberKey"]["id"] === result["email"]) {
            data[i]["role"] = result["newRole"];
          }
        }
        this.dataSource.data = data;
      }
    });
  }

  onClickEditMemberships(member) {
    this.openEditRolesDialog(member);
  }

  getPageData() {
    return this.dataSource._pageData(this.dataSource._orderData(this.dataSource.filteredData));
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  masterToggle() {
    this.isEntirePageSelected()
      ? this.selection.deselect(...this.getPageData())
      : this.selection.select(...this.getPageData());
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row): string {
    if (!row) {
      return `${this.getPageData() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1}`;
  }

  tableColumnChange(value) {
    let add = true;
    for (let i = 0; i < this.displayedColumns.length; i++) {
      if (this.displayedColumns[i] == value) {
        if (i !== -1) {
          this.displayedColumns.splice(i, 1);
          add = false;
        }
      }
    }

    if (add) {
      this.displayedColumns.push(value);
    }
  }

  setTokens(page: number) {
    this.nextPageToken = this.pageTokens[page];
    this.prevPageToken = this.pageTokens[page - 1];
  }

  public setPager(data) {
    this.page = data.page;
    this.offset = data.offset;
    this.maxResults = data.maxResults ? data.maxResults : this.maxResults;
    this.loadMembersTable(data.arrow);
  }
}
