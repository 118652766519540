import { Component, OnInit, ViewChild } from "@angular/core";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { StorageReport, ReportsService, Files } from "src/app/services/google/reports.service";
import { jsPDF } from "jspdf";
import { Router } from "@angular/router";
import html2canvas from "html2canvas";
import { ExportsheetsService } from "src/app/services/google/sheets.service";
import { FilesService } from "src/app/services/storage-plus/files.service";
import { StorageReportDuplicateFilesTableComponent } from "src/app/storage-plus/storage/storage-report-duplicate-files/storage-report-duplicate-files-table/storage-report-duplicate-files-table.component";
import { AdminPlusService } from "src/app/services/admin-plus/admin-plus.service";

@Component({
  selector: "app-storage-report-duplicate-files",
  templateUrl: "./storage-report-duplicate-files.component.html",
  styleUrls: ["./storage-report-duplicate-files.component.scss"],
})
export class StorageReportDuplicateFilesComponent implements OnInit {
  options = [];
  @ViewChild(StorageReportDuplicateFilesTableComponent)
  duplicateFilesTableComponent: StorageReportDuplicateFilesTableComponent;
  storageReport: StorageReport = {
    status: {
      name: "",
      id: 4,
    },
    totalUsers: 0,
    totalStorage: 0,
    totalDrive: 0,
    totalGmail: 0,
    totalPhotos: 0,
    allowDelete: false,
    filesSynced: new Date().toString(),
  };

  fixDuplicateBanner = 0;
  files: Files[] = [];
  columns: string[] = [];

  constructor(
    private navService: NavService,
    private exportSheetsService: ExportsheetsService,
    private router: Router,
    private reportsService: ReportsService,
    private filesService: FilesService,
    private adminPlusService: AdminPlusService
  ) {
    this.options.push({
      icon: "picture_as_pdf",
      name: "Export to PDF",
      disabled: true,
      tooltip: "Exports the current page to a PDF document for sharing",
      class: "export",
    });
    this.options.push({
      icon: "description",
      name: "Export to Sheets",
      disabled: true,
      tooltip: "Exports the current data set to Google Sheets",
      class: "success",
    });

    this.adminPlusService.breadCrumbLinks.push({
      link: null,
      text: "Duplicate Files",
      alt: "Duplicate Files",
    });
  }

  async ngOnInit() {
    this.navService.loading.next(true);
    this.storageReport = await this.reportsService.getStorageReport();
    if (!this.storageReport || this.storageReport.status.id != 4) {
      //complete
      this.router.navigate(["/reports/storage"]);
    }
    const banner = await this.filesService.getBusyDuplicateFiles();
    this.fixDuplicateBanner = banner.length;
    this.navService.loading.next(false);
  }

  optionSelected(option) {
    switch (option.name) {
      case "Export to PDF":
        this.downloadAsPDF();
        break;
      case "Export to Sheets":
        this.exportToSheets();
        break;
      default:
        break;
    }
  }

  receiveFilesEvent($event) {
    this.files = $event["files"];
    this.columns = $event["columns"];
  }

  async addFileData(spreadsheetID: string) {
    const newFileObject = await this.exportSheetsService.createSpreadsheetArray(this.columns, this.files);
    if (newFileObject) {
      await this.exportSheetsService.addExportData(
        newFileObject,
        spreadsheetID,
        "Sheet1!A1:G" + newFileObject["values"].length
      );
    }
  }

  async exportToSheets() {
    this.navService.loading.next(true);
    const spreadsheetID = await this.exportSheetsService.createSpreadsheet("Duplicate Files");
    await this.duplicateFilesTableComponent.sendDuplicateFilesEvent();
    await this.addFileData(spreadsheetID);
    window.open("https://docs.google.com/spreadsheets/d/" + spreadsheetID);
    this.navService.loading.next(false);
  }

  async downloadAsPDF() {
    this.navService.loading.next(true);
    const canvas = await this.canvas();
    if (canvas) {
      this.navService.loading.next(false);
    }
  }

  async canvas() {
    return new Promise((resolve) => {
      html2canvas(document.getElementById("entireScreen")).then(function (canvas) {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 210;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        const doc = new jsPDF("p", "mm");
        let position = 0;
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save("storage-report-duplicate-files.pdf");
        resolve(true);
      });
    });
  }
}
