<app-page-header [options]="options" (optionSelected)="optionSelected($event)"></app-page-header>
<div class="row">
  <div *ngIf="user.isAdmin" class="col-12 col-md-4 mt-2">
    <mat-form-field class="w-100">
      <mat-label> Actions </mat-label>
      <mat-select [formControl]="actions">
        <mat-option value="" selected></mat-option>
        <mat-option value="delete" [disabled]="selection.selected.length == 0"> Delete </mat-option>
        <mat-option value="addsubgroup" [disabled]="selection.selected.length == 0"> Add Sub Groups </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-12 col-md-8 mt-2">
    <mat-form-field class="w-100">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. email@domain.com" #input />
    </mat-form-field>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="table-responsive has-active">
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              color="primary"
              #checkboxesColumn
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && getPageData()"
              [indeterminate]="selection.hasValue() && !getPageData()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              color="primary"
              #checkboxes
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell (click)="routeToGroup(row.id)" *matCellDef="let row">
            {{ row.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
          <td mat-cell (click)="routeToGroup(row.id)" *matCellDef="let row">
            {{ row.email }}
          </td>
        </ng-container>
        <ng-container matColumnDef="directMembersCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Members</th>
          <td mat-cell (click)="routeToGroup(row.id)" *matCellDef="let row">
            {{ row.directMembersCount }}
          </td>
        </ng-container>
        <tr class="mat-row" app-empty-dataset-alert [loading]="loading" [filterCount]="dataSource.filter.length" *matNoDataRow></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <app-table-footer
      [dataSource]="dataSource"
      [maxResults]="maxResults"
      [nextButton]="nextButton"
      [nextPageToken]="nextPageToken"
      [prevPageToken]="prevPageToken"
      [offset]="offset"
      [page]="page"
      (pager)="setPager($event)"
      [disabled]="isFetchingData"
    ></app-table-footer>
  </div>
</div>
