import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { ServiceAccountService, ServiceAccount } from "src/app/services/admin-plus/service-account.service";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { CustomerService, CustomerModule } from "src/app/services/admin-plus/customer.service";
import { EmailsService } from "src/app/services/storage-plus/emails.service";
import { NavService } from "src/app/services/admin-plus/nav.service";

@Component({
  selector: "app-manual-add-edit-sa",
  templateUrl: "./manual-add-edit-sa.component.html",
  styleUrls: ["./manual-add-edit-sa.component.css"],
})
export class ManualAddEditSaComponent implements OnInit {
  @Input() data = null;
  @Input() dialogRef = null; //this isn't right... JRB 2021-11-17
  modules: CustomerModule[] = [];
  selectedModules: CustomerModule[] = [];
  accessKeyPlaceholder: string;
  errorInv = "Invalid field";
  errorReq = "Required field";
  serviceAccount: ServiceAccount = {
    id: null,
    name: null,
    description: null,
    clientId: null,
    projectId: null,
    serviceEmail: null,
    delegateEmail: null,
    accessKey: null,
    mailbox: {
      email: null,
      created: null,
      autoConfigure: false,
      lastCheck: null,
    },
  };
  saving = false;
  type = 1;
  error = false;
  orderBy = "givenName";
  sortOrder = "SORT_ORDER_UNDEFINED";

  constructor(
    private notifyService: NotificationService,
    private customerService: CustomerService,
    private serviceAccountService: ServiceAccountService,
    public loginService: LoginService,
    private emailService: EmailsService,
    private navService: NavService
  ) {}

  ngOnInit(): void {
    this.navService.loading.next(true);
    this.modules = this.data.modules.filter((m: CustomerModule) => m.requiresServiceAccount && !m.serviceAccountId);
    if (this.data.serviceAccount) {
      this.serviceAccount = JSON.parse(JSON.stringify(this.data.serviceAccount));
    }
    this.navService.loading.next(false);
  }

  receiveDelegateEmail(event) {
    if (event.deleted) {
      this.serviceAccount.delegateEmail = "";
    } else {
      this.serviceAccount.delegateEmail = event.email ? event.email : event;
    }
  }

  receiveMailboxEmail(event) {
    if (event.deleted) {
      this.serviceAccount.mailbox.email = null;
    } else {
      if (!this.serviceAccount.mailbox)
        this.serviceAccount.mailbox = { email: null, created: null, lastCheck: null, autoConfigure: false };

      this.serviceAccount.mailbox.email = event.email ? event.email : event;
    }
  }

  moduleSelected(module: CustomerModule) {
    if (!this.selectedModules.includes(module)) {
      this.selectedModules.push(module);
    } else {
      this.selectedModules = this.selectedModules.filter((m) => m != module);
    }
  }

  //TODO: Clean this up
  async processForm() {
    if (!this.serviceAccount.delegateEmail) {
      this.error = true;
    } else {
      this.error = false;
    }
    if (!this.serviceAccount.name || !this.serviceAccount.name.trim()) {
      this.notifyService.notify("Name is required");
      return;
    }
    if (!this.serviceAccount.serviceEmail || !this.serviceAccount.serviceEmail.trim()) {
      this.notifyService.notify("Service Email is required");
      return;
    }
    if (!this.serviceAccount.delegateEmail || !this.serviceAccount.delegateEmail.trim()) {
      this.notifyService.notify("Delegate Email is required and delegate email must be selected from auto-suggest");
      return;
    }
    if (!this.emailService.isValidEmail(this.serviceAccount.delegateEmail)) {
      this.notifyService.notify("Delegate Email is invalid");
      return;
    }
    if (this.data.action === "add" && (!this.serviceAccount.accessKey || !this.serviceAccount.accessKey.trim())) {
      this.notifyService.notify("Access Key is required");
      return;
    }
    if (!this.emailService.isValidEmail(this.serviceAccount.serviceEmail)) {
      this.notifyService.notify("Service Email is invalid");
      return;
    }
    if (!this.serviceAccount.projectId) {
      this.notifyService.notify("Project ID is required");
      return;
    }

    if (
      this.data.action == "edit" &&
      this.serviceAccount.name == this.data.serviceAccount.name &&
      this.serviceAccount.description == this.data.serviceAccount.description &&
      this.serviceAccount.projectId == this.data.serviceAccount.projectId &&
      this.serviceAccount.serviceEmail == this.data.serviceAccount.serviceEmail &&
      this.serviceAccount.delegateEmail == this.data.serviceAccount.delegateEmail &&
      this.serviceAccount.mailbox?.email == this.data.serviceAccount.mailbox?.email &&
      this.serviceAccount.clientId == this.data.serviceAccount.clientId &&
      this.serviceAccount.accessKey === undefined
    ) {
      this.notifyService.notify("No updates to apply, cancel to exit");
      return;
    }
    this.saving = true;
    const accessKey =
      this.serviceAccount.accessKey !== undefined
        ? `${!this.serviceAccount.accessKey.startsWith('"') ? '"' : ""}${this.serviceAccount.accessKey}${
            !this.serviceAccount.accessKey.endsWith('"') ? '"' : "" // eslint-disable-next-line no-mixed-spaces-and-tabs
          }`
        : this.serviceAccount.accessKey;
    this.serviceAccount.accessKey = accessKey;
    if (this.serviceAccount.mailbox?.email && this.serviceAccount.mailbox?.created == null) {
      this.serviceAccount.mailbox.created = new Date();
    }
    const response = await this.serviceAccountService.createUpdateServiceAccount(this.serviceAccount, this.data.action);
    if (response) {
      this.serviceAccount.id = parseInt(response["insertId"]);
      if (this.selectedModules.length > 0) {
        for (const selectedModule of this.selectedModules) {
          await this.customerService.linkCustomerModule("link", selectedModule.subscriptionModuleId, this.serviceAccount.id);
        }
      }
      this.dialogRef.close(response);
      this.notifyService.notify(`Service account was ${this.data.action === "add" ? "added" : "updated"}`);
    }
    this.saving = false;
  }
}
