import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AccountService } from "src/app/services/admin-plus/account.service";
import { Customer } from "src/app/services/admin-plus/customer.service";

@Component({
  selector: "app-edit-dialog",
  templateUrl: "./edit-dialog.component.html",
})
export class EditDialogComponent implements OnInit {
  loading: boolean;
  defaultTimeZones = ["America/New_York", "America/Chicago", "America/Denver", "America/Los_Angeles"];
  timeZones: string[];
  error = {
    name: false,
    timezone: false,
  };

  constructor(
    private dialogRef: MatDialogRef<EditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public customer: Customer,
    private accountService: AccountService
  ) {}

  async ngOnInit() {
    this.loading = true;
    this.timeZones = await this.accountService.getTimezones();
    this.loading = false;
  }

  processForm() {
    if (!this.customer.name?.trim()) return (this.error.name = true);
    if (!this.customer.timezone?.trim()) return (this.error.timezone = true);

    this.dialogRef.close(this.customer);
  }
}
