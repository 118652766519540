import { Component, OnInit } from "@angular/core";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { GroupsService } from "src/app/services/admin-plus/groups.service";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { SettingsService } from "./settings.service";
import { Settings } from "./settings";

@Component({
  selector: "app-group-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class GroupSettingsComponent implements OnInit {
  public settings: Settings;

  constructor(
    private settingsService: SettingsService,
    private notificationService: NotificationService,
    private groupsService: GroupsService,
    private navService: NavService
  ) {}

  async getSettings(groupEmail: string) {
    this.navService.loading.next(true);
    const response = await this.settingsService.getSettings(groupEmail);
    if (response) {
      this.settings = this.tidyData(response);
    }
    this.navService.loading.next(false);
  }

  tidyData(data) {
    data.allowExternalMembers = data.allowExternalMembers == "true" ? true : false;
    return data;
  }

  async saveSettings() {
    this.navService.loading.next(true);
    const response = await this.settingsService.saveSettings(this.groupsService.selected_group.groupKey.id, this.settings);
    if (response) {
      this.notificationService.notify("Settings have been saved");
    }
    this.navService.loading.next(false);
  }

  discoverGroupOnChange(event: any, value: any) {
    const values = ["ALL_MEMBERS_CAN_DISCOVER", "ALL_IN_DOMAIN_CAN_DISCOVER", "ANYONE_CAN_DISCOVER"];
    const checked = event.checked;

    if (!checked) {
      let to_be_selected = "";
      for (let i = 0; i < values.length; i++) {
        if (value == values[i]) {
          this.settings.whoCanDiscoverGroup = to_be_selected;
        }

        to_be_selected = values[i];
      }
    } else {
      this.settings.whoCanDiscoverGroup = value;
    }
  }

  contactOwnerOnChange(event: any, value: any) {
    const values = [
      "ALL_MANAGERS_CAN_CONTACT",
      "ALL_MEMBERS_CAN_CONTACT",
      "ALL_IN_DOMAIN_CAN_CONTACT",
      "ANYONE_CAN_CONTACT",
    ];

    const checked = event.checked;

    if (!checked) {
      let to_be_selected = "";
      for (let i = 0; i < values.length; i++) {
        if (value == values[i]) {
          this.settings.whoCanContactOwner = to_be_selected;
        }

        to_be_selected = values[i];
      }
    } else {
      this.settings.whoCanContactOwner = value;
    }
  }

  viewMembersOnChange(event: any, value: any) {
    const values = ["ALL_MANAGERS_CAN_VIEW", "ALL_MEMBERS_CAN_VIEW", "ALL_IN_DOMAIN_CAN_VIEW"];

    const checked = event.checked;

    if (!checked) {
      let to_be_selected = "";
      for (let i = 0; i < values.length; i++) {
        if (value == values[i]) {
          this.settings.whoCanViewMembership = to_be_selected;
        }

        to_be_selected = values[i];
      }
    } else {
      this.settings.whoCanViewMembership = value;
    }
  }

  viewConversationsOnChange(event: any, value: any) {
    const values = ["ALL_MANAGERS_CAN_VIEW", "ALL_MEMBERS_CAN_VIEW", "ALL_IN_DOMAIN_CAN_VIEW", "ANYONE_CAN_VIEW"];

    const checked = event.checked;

    if (!checked) {
      let to_be_selected = "";
      for (let i = 0; i < values.length; i++) {
        if (value == values[i]) {
          this.settings.whoCanViewGroup = to_be_selected;
        }

        to_be_selected = values[i];
      }
    } else {
      this.settings.whoCanViewGroup = value;
    }
  }

  whoCanPostOnChange(event: any, value: any) {
    const values = ["ALL_MANAGERS_CAN_POST", "ALL_MEMBERS_CAN_POST", "ALL_IN_DOMAIN_CAN_POST", "ANYONE_CAN_POST"];

    const checked = event.checked;

    if (!checked) {
      let to_be_selected = "";
      for (let i = 0; i < values.length; i++) {
        if (value == values[i]) {
          this.settings.whoCanPostMessage = to_be_selected;
        }

        to_be_selected = values[i];
      }
    } else {
      this.settings.whoCanPostMessage = value;
    }
  }

  ngOnInit(): void {
    this.getSettings(this.groupsService.selected_group.groupKey.id);
  }
}
