<h2 mat-dialog-title>
	<i style="font-size: 20px" class="faa-shake material-icons material-icon-align mr-2">warning</i>
	{{ title }}
</h2>
<div mat-dialog-content>
	<span *ngIf="bold === undefined">{{ message }}{{ questionMark }}</span>
	<span *ngIf="bold !== undefined"
		>{{ message }}
		<mat-form-field class="w-25">
			<mat-select class="center" [(value)]="selectedDays" required>
				<mat-option value="1">1</mat-option>
				<mat-option value="7">7</mat-option>
				<mat-option value="14">14</mat-option>
				<mat-option value="30">30</mat-option>
			</mat-select>
		</mat-form-field>
		<b> {{ bold }}</b>
		{{ questionMark }}
	</span>
	<ul>
		<li *ngFor="let data of dialogData">{{ data.name ? data.name : data.subject ? data.subject : data }}</li>
	</ul>
	<div class="row" *ngIf="hideReason">
		<div class="col-12 mt-2">
			<mat-form-field class="w-100">
				<mat-label>
					Reason for <span *ngIf="deleteFile">Deleting File</span><span *ngIf="!deleteFile">Moving to Trash</span>
				</mat-label>
				<mat-select [formControl]="actions" (ngModelChange)="reason($event)">
					<mat-option value="Violation of policy" selected>Violation of policy</mat-option>
					<mat-option value="Duplicate file">Duplicate file</mat-option>
					<mat-option value="Personal file">Personal file</mat-option>
					<mat-option value="Other">Other</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div *ngIf="textArea" class="col-12 mt-2">
			<mat-form-field class="w-100">
				<mat-label>Other Reason</mat-label>
				<textarea
					matInput
					(input)="reasonText = $event.target.value"
					maxlength="1022"
					cdkTextareaAutosize
					#autosize
					cdkAutosizeMinRows="1"
					rows="2"
					cdkAutosizeMaxRows="2"
				></textarea>
			</mat-form-field>
		</div>
	</div>
	<div mat-dialog-actions class='px-0'>
		<button mat-stroked-button mat-dialog-close class="" (click)="onDismiss()">Cancel</button>
		<button mat-stroked-button class="success ml-2" (click)="onConfirm()">
			{{ btnOkText }}
		</button>
	</div>
</div>
