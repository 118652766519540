<div *ngIf="!hasCloudAccess()" class="row">
  <div class="col text-center">
    <div class="alert alert-info text-center py-2 mt-2">
      <div class="d-flex align-items-center" style="gap: 8px">
        <mat-icon style="overflow: visible"> info </mat-icon>
        <h3 class="mb-0">
          {{ authService.appName }} requires authorization of additional permissions to automatically configure your service
          account(s).
        </h3>
      </div>
    </div>
    <br />
    <a (click)="elevate()" class="my-3 py-3">
      <img src="https://ap-assets.trafera.app/images/btn_google_signin_light_normal_web@2x.png" width="260px" />
    </a>
  </div>
</div>
<div *ngIf="hasCloudAccess()" class="row">
  <div class="col-12 my-3">
    <div *ngIf="!advanced" class="alert alert-info my-auto">
      <div class="d-flex align-items-center">
        <mat-icon class="mr-3"> info </mat-icon>
        <h3 class="mb-0">{{ authService.appName }} will now setup the following...</h3>
      </div>
      <hr />
      <ul class="mt-2 mb-0">
        <li>
          <b>New Project:</b><br />
          <span class="ml-2">
            <i>{{ autoSA.projectName }}</i>
          </span>
        </li>
        <li>
          <b>Enable Project APIs:</b>
          <span class="ml-2">
            <ul>
              <li>Admin SDK</li>
              <li>Google Drive</li>
              <li>Gmail</li>
              <li>Licensing</li>
              <li>Calendar</li>
            </ul>
          </span>
        </li>
        <li>
          <b>New Service Account:</b><br />
          <span class="ml-2">
            <i>{{ autoSA.name }}</i>
          </span>
        </li>
        <li>
          <b>Assign Admin+ Default Email:</b><br />
          <span class="ml-2">{{ autoSA.delegateEmail }} (you)</span>
        </li>
        <li>
          <b>Create/Assign Ticket Mailbox Email:</b><br />
          <span class="ml-2">{{ autoSA.mailbox.email }}</span>
        </li>
      </ul>
    </div>
    <div *ngIf="advanced">
      <div class="col-12 mt-3">
        <table *ngIf="!projectsList" class="table table-borderless text-left">
          <tr>
            <td>
              <span> Retrieving GCP Projects... </span>
            </td>
            <td>
              <span class="material-icons faa-spin"> filter_tilt_shift </span>
            </td>
          </tr>
        </table>
        <mat-form-field *ngIf="projectsList" class="w-100">
          <mat-label>Select a project</mat-label>
          <mat-select [formControl]="projects" (ngModelChange)="projectSelect($event)">
            <mat-option value="[]">---New project---</mat-option>
            <mat-option #project *ngFor="let project of projectsList" [value]="project.projectId">{{
              project.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="newProjectInput" class="col-12 mt-2">
        <mat-form-field class="w-100">
          <mat-label> New project name: </mat-label>
          <input
            matInput
            [(ngModel)]="newProject"
            type="string"
            name="newProject"
            id="newProject"
            autocomplete="off"
            maxlength="100"
          />
          <mat-hint> Project names are global and must be unique to your organization. </mat-hint>
        </mat-form-field>
      </div>
      <div *ngIf="projects.value" class="col-12 mt-3 pt-2">
        <table *ngIf="!serviceAccountsList && projects.value !== '[]'" class="table table-borderless text-left">
          <tr>
            <td>
              <span> Retrieving GCP Service Accounts... </span>
            </td>
            <td>
              <span class="material-icons faa-spin"> filter_tilt_shift </span>
            </td>
          </tr>
        </table>
        <mat-form-field *ngIf="serviceAccountsList" class="w-100">
          <mat-label>Select a service account</mat-label>
          <mat-select [formControl]="serviceAccounts" (ngModelChange)="serviceAccountSelect($event)">
            <mat-option value="[]">---New service account---</mat-option>
            <mat-option #serviceAccount *ngFor="let serviceAccount of serviceAccountsList" [value]="serviceAccount.uniqueId">
              {{ serviceAccount.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="newServiceAccountInput" class="col-12 mt-2">
        <mat-form-field class="w-100">
          <mat-label> New service account name: </mat-label>
          <input
            matInput
            [(ngModel)]="newServiceAccount"
            type="string"
            name="newServiceAccount"
            id="newServiceAccount"
            autocomplete="off"
            maxlength="100"
          />
          <mat-hint> Service Account names are global and must be unique to your organization. </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-12 mt-3 pt-2">
        <mat-form-field class="w-100">
          <mat-label> Default Email </mat-label>
          <input
            matInput
            [(ngModel)]="autoSA.delegateEmail"
            type="email"
            name="delegateEmail"
            id="delegateEmail"
            placeholder="Default Email *"
            autocomplete="off"
            required
            email
          />
        </mat-form-field>
      </div>
      <div class="col-12 mt-3 pt-2">
        <mat-form-field class="w-100">
          <mat-label> Mailbox Email </mat-label>
          <input
            matInput
            [(ngModel)]="autoSA.mailbox.email"
            type="email"
            name="mailboxEmail"
            id="mailboxEmail"
            placeholder="Mailbox Email *"
            autocomplete="off"
            required
            email
          />
        </mat-form-field>
      </div>
      <!-- <div class="col-12 mt-3">
        <mat-label>Customize for the following {{ authService.appName }} module(s):</mat-label>
        <mat-form-field class="w-100">
          <mat-label>Select modules</mat-label>
          <mat-select [formControl]="modules" multiple>
            <mat-select-trigger>
              {{ (modules.value.length ? modules.value[0].name : "") | titlecase }}
              <span *ngIf="modules.value?.length > 1" class="example-additional-selection">
                (+{{ modules.value.length - 1 }} more)
              </span>
            </mat-select-trigger>
            <mat-option *ngFor="let module of modulesList" [value]="module">{{ module.name | titlecase }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
      <div class="col-12 mt-2">
        <mat-label>
          {{ modules.length > 0 ? (modules.length == 1 ? "Module" : "Modules") : "No modules" }}
          available to link
        </mat-label>
        <div class="w-100">
          <ng-container class="modules-container" *ngFor="let module of modules">
            <mat-checkbox
              color="primary"
              class="pr-3"
              [checked]="module.selected"
              (change)="moduleSelected(module, $event)"
              >{{ module.name | titlecase }}</mat-checkbox
            >
          </ng-container>
        </div>
      </div>
    </div>
    <div class="w-100 mt-3 px-2 d-flex justify-content-between">
      <a class="pt-4 align-self-center text-decoration-none" (click)="toggleAdvanced()">
        <b>
          {{ !advanced ? "Advanced" : "Basic" }}
        </b>
      </a>
      <div mat-dialog-actions>
        <button mat-stroked-button mat-dialog-close>Cancel</button>
        <button mat-stroked-button class="success" (click)="setupService()" [disabled]="saving">
          Setup Service Account
        </button>
      </div>
    </div>
  </div>
</div>
