import { Injectable } from "@angular/core";
import { Payload } from "src/app/services/utilities/utility.service";
import { Priority } from "src/app/models/ticket-plus/priority";
import { TicketPlusService } from "./ticket-plus.service";

@Injectable({
  providedIn: "root",
})
export class PriorityService extends TicketPlusService {
  private url = `/ticket-plus/priorities`;

  async getPriorities(params = {}): Promise<Payload> {
    return this.cacheService.getPayloadAndCache(this.url, params, this.utilityService);
  }

  async createUpdatePriority(data: Priority): Promise<Priority> {
    this.cacheService.clearCache(this.url);
    return new Promise<Priority>((resolve) => {
      this.utilityService.sendRequest("POST", this.url, {}, data).subscribe((payload) => {
        if (payload?.data?.length) {
          resolve(payload.data[0]);
        } else {
          resolve(null);
        }
      });
    });
  }

  async deletePriority(priority: Priority): Promise<Payload> {
    this.cacheService.clearCache(this.url);
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("DELETE", `${this.url}/${priority.id}`).subscribe((payload) => {
        resolve(payload);
      });
    });
  }
}
