<mat-form-field class="w-100">
  <mat-label>Building</mat-label>
  <mat-select
    [compareWith]="compare"
    [(ngModel)]="selectedBuilding"
    name="building"
    (ngModelChange)="onBuildingChange()"
    [disabled]="parentLoading || loading"
  >
    <mat-option value=""></mat-option>
    <mat-option *ngFor="let building of buildings" [value]="building">
      {{ building.name }}
    </mat-option>
  </mat-select>
  <mat-hint *ngIf="loading">
    <mat-icon class="faa-spin mx-1"> filter_tilt_shift </mat-icon>
    Gathering Buildings ...
  </mat-hint>
</mat-form-field>
