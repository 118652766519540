<h2 mat-dialog-title>Add Sub Groups</h2>
<form (ngSubmit)="processForm()" autocomplete="off">
    <div mat-dialog-content>
        <mat-form-field class="w-100">
            <mat-chip-grid #groupList>
                <mat-chip-row
                    *ngFor="let selected_group of selected_groups"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="remove(selected_group)">
                    {{ selected_group }}
                    <mat-icon [selectable]="selectable" matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip-row>
                <input 
                    placeholder="New group..."
                    #userInput
                    type="text"
                    name="email"
                    [formControl]="myControl"
                    [matAutocomplete]="auto" 
                    [matChipInputFor]="groupList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="add($event)"
                    matInput
                    />
            </mat-chip-grid>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
              <mat-option *ngFor="let group of filteredOptions | async" [value]="group.email">
                {{ group.name }}&nbsp;|&nbsp;<small class="text-muted">{{ group.email }}</small>
              </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <app-dialog-actions-container></app-dialog-actions-container>
    </div>
</form>
