<div class="h-100 d-flex align-items-center">
  <button mat-icon-button *ngIf="loginService.user" (click)="toggleLeftNav()">
    <mat-icon>menu</mat-icon>
  </button>
  <a id="kitLogoLink" class="ml-2" matTooltip="Home" [routerLink]="'/'">
    <img
      id="kitLogo"
      class="navbar-brand d-none d-md-block"
      src="https://ap-assets.trafera.app/images/Admin_plus_by_kincaidit.svg"
      alt="Admin Plus Logo"
    />
    <img
      id="kitLogo"
      class="navbar-brand d-md-none"
      src="https://storage.googleapis.com/ap-assets.trafera.app/images/admin_blue.svg"
      alt="Admin Plus Logo"
    />
  </a>
</div>
