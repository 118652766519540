import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";
import { UntypedFormControl } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { ExportsheetsService } from "src/app/services/google/sheets.service";
import { GroupMembershipsDialogComponent } from "../group-memberships-dialog/group-memberships-dialog.component";
import { DeleteGroupMembershipsDialogComponent } from "./delete-group-memberships-dialog/delete-group-memberships-dialog.component";
import { Group } from "src/app/groups/groups";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-group-memberships",
  templateUrl: "./group-memberships.component.html",
  styleUrls: ["./group-memberships.component.scss"],
})
export class GroupMembershipsComponent implements OnInit {
  private id: string;
  @Input() groups: Group[] = [];
  nextPageToken = "";
  prevPageToken = "";
  pageToken = "";
  columnsList: string[] = ["email", "directMembersCount", "description"];
  displayedColumns: string[] = ["select", "name", "email", "directMembersCount"];
  selection = new SelectionModel(true, []);
  dataSource = new MatTableDataSource();
  resultsLength = 0;
  maxResults = 10;
  offset = 0;
  page = 1;
  actions = new UntypedFormControl();
  columns = new UntypedFormControl();

  nextButton = false;
  nextButtonDis = false;
  loading = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isFetchingData: boolean;

  constructor(
    private exportSheetsService: ExportsheetsService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private navService: NavService,
    private router: Router
  ) {
    this.isFetchingData = false;
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get("id");
    this.columns.setValue(this.displayedColumns);
    this.actions.valueChanges.subscribe((value) => {
      if (value == "delete") {
        this.openDeleteDialog();
      } else {
        return;
      }
    });
  }

  loadData() {
    this.isFetchingData = true;
    this.loading = true;

    const start = (this.page - 1) * this.maxResults;
    const end = start + this.maxResults;
    const pageGroups = this.groups.slice(start, end);

    if (this.page === 1) {
      this.prevPageToken = "";
    } else {
      this.prevPageToken = "true";
    }

    if (this.groups.length > end) {
      this.nextPageToken = "true";
    } else {
      this.nextPageToken = "";
    }

    this.dataSource = new MatTableDataSource(pageGroups);
    this.isFetchingData = false;
    this.loading = false;
  }

  ngAfterViewInit() {
    this.loadData();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === "") {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "Name":
          return compare(a["Name"], b["Name"], isAsc);
        case "email":
          return compare(a["email"], b["email"], isAsc);
        case "Members":
          return compare(a["Members"], b["Members"], isAsc);
        case "Description":
          return compare(a["Description"], b["Description"], isAsc);
        default:
          return 0;
      }
    });
  }

  async addGroupMembersData(spreadsheetID: string) {
    const newGroupMemberObject = await this.exportSheetsService.createSpreadsheetArray(
      this.displayedColumns,
      this.dataSource["filteredData"]
    );
    if (newGroupMemberObject) {
      await this.exportSheetsService.addExportData(
        newGroupMemberObject,
        spreadsheetID,
        "Sheet1!A1:G" + newGroupMemberObject["values"].length
      );
    }
  }

  async userMembershipsSpreadsheet() {
    this.navService.loading.next(true);
    const spreadsheetID = await this.exportSheetsService.createSpreadsheet("Group Memberships");
    await this.addGroupMembersData(spreadsheetID);
    window.open("https://docs.google.com/spreadsheets/d/" + spreadsheetID);
    this.navService.loading.next(false);
  }

  openDialog() {
    const dialogRef = this.dialog.open(GroupMembershipsDialogComponent, {
      disableClose: true,
      width: "500px",
      data: {
        groups: this.dataSource.data,
        userID: this.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const data = this.dataSource.data;
        for (let i = 0; i < result.length; i++) {
          data.unshift(result[i]);
        }
        this.dataSource.data = data;
      }
    });
  }

  openDeleteDialog() {
    const dialogRef = this.dialog.open(DeleteGroupMembershipsDialogComponent, {
      disableClose: true,
      width: "500px",
      data: {
        groups: this.selection.selected,
        id: this.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        for (let j = 0; j < result.length; j++) {
          this.removeMemebership(result[j].id);
        }
        this.selection.clear();
        this.actions.setValue("");
      } else {
        this.selection.clear();
        this.actions.setValue("");
      }
    });
  }

  private removeMemebership(id) {
    const data = this.dataSource.data;
    for (let i = data.length - 1; i >= 0; i--) {
      if (data[i]) {
        if (data[i]["id"] == id) {
          data.splice(i, 1);
        }
      }
    }
    this.dataSource.data = data;
  }

  getPageData() {
    return this.dataSource._pageData(this.dataSource._orderData(this.dataSource.filteredData));
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  masterToggle() {
    this.isEntirePageSelected()
      ? this.selection.deselect(...this.getPageData())
      : this.selection.select(...this.getPageData());
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row): string {
    if (!row) {
      return `${this.getPageData() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1}`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  tableColumnChange(value) {
    let add = true;
    for (let i = 0; i < this.displayedColumns.length; i++) {
      if (this.displayedColumns[i] == value) {
        if (i !== -1) {
          this.displayedColumns.splice(i, 1);
          add = false;
        }
      }
    }

    if (add) {
      this.displayedColumns.push(value);
    }
  }

  setPager(data) {
    this.isFetchingData = true;
    this.page = data.page;
    this.offset = data.offset;
    this.maxResults = data.maxResults ? data.maxResults : this.maxResults;
    this.loadData();
    this.isFetchingData = false;
  }

  routeToGroup(groupId: number) {
    this.router.navigate(["/groups/" + groupId]);
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
