import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from "@angular/material/snack-bar";
import {
  NotificationDialogComponent,
  NotificationDialogModel,
} from "../../layout/notification-dialog/notification-dialog.component";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  private defaultConfig: MatSnackBarConfig;
  constructor(private _snackBar: MatSnackBar, private dialog: MatDialog) {}

  notify(message, error = false, config: MatSnackBarConfig = null) {
    if (error) {
      this.defaultConfig = {
        duration: 10000,
        panelClass: ["snackbar-error"],
      };
    } else {
      this.defaultConfig = {
        duration: 10000,
      };
    }
    let snackBarRef: MatSnackBarRef<unknown>;
    if (config) {
      if (message.length > 256) {
        snackBarRef = this._snackBar.open(`${this.getTrimmedMessage(message)}`, "Show More", {
          ...this.defaultConfig,
          ...config,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
          const data = new NotificationDialogModel("Notification", message, "Close");
          this.dialog.open(NotificationDialogComponent, {
            disableClose: true,
            width: "700px",
            data: data,
          });
        });
      } else {
        snackBarRef = this._snackBar.open(`${message}`, "Close", { ...this.defaultConfig, ...config });
        snackBarRef.onAction().subscribe(() => snackBarRef.dismiss());
      }
    } else {
      if (message.length > 256) {
        snackBarRef = this._snackBar.open(`${this.getTrimmedMessage(message)}`, "Show More", this.defaultConfig);
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
          const data = new NotificationDialogModel("Notification", message, "Close");
          this.dialog.open(NotificationDialogComponent, {
            disableClose: true,
            width: "700px",
            data: data,
          });
        });
      } else {
        snackBarRef = this._snackBar.open(`${message}`, "Close", this.defaultConfig);
        snackBarRef.onAction().subscribe(() => snackBarRef.dismiss());
      }
    }
  }

  getTrimmedMessage(message: string): string {
    let trimmedMessage = message.substring(0, 256);
    trimmedMessage = trimmedMessage.substring(0, Math.min(trimmedMessage.length, trimmedMessage.lastIndexOf(" ")));
    return trimmedMessage + "...";
  }
}
