import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate } from "@angular/router";
import { LoginService } from "./login.service";
import { CustomerService } from "src/app/services/admin-plus/customer.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private loginService: LoginService, private router: Router, private customerService: CustomerService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    localStorage.setItem("target", state.url);
    return await this.checkLoggedIn(state.url);
  }

  async checkLoggedIn(url: string): Promise<boolean> {
    const user = this.loginService.getUser();
    if (user && this.customerService.validModuleRoute(url)) {
      return true;
    } else if (user) {
      this.router.navigate(["/dashboard"]);
      return false;
    }
    this.router.navigate(["/"]);
    return false;
  }
}

export class RedirectGuard implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot): boolean {
    window.location.href = route.data["externalUrl"];
    return true;
  }
}
