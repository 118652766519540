<h2 mat-dialog-title>Edit Account</h2>
<form autocomplete="off" (ngSubmit)="processForm()">
  <div mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Organization Name</mat-label>
          <input matInput type="text" [(ngModel)]="customer.name" name="name" maxlength="45" required />
          <mat-error *ngIf="error.name">Name is required</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Default Time Zone</mat-label>
          <mat-select [(value)]="customer.timezone" placeholder="America/Chicago" name="timezone" required>
            <mat-option *ngFor="let timeZone of defaultTimeZones" [value]="timeZone">
              {{ timeZone }}
            </mat-option>
            <mat-divider></mat-divider>
            <mat-option *ngIf="loading" [disabled]="loading">Gathering Additional Time Zones...</mat-option>
            <mat-option *ngFor="let timeZone of timeZones" [value]="timeZone">
              {{ timeZone }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="error.timezone">Time zone is required</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close>Cancel</button>
      <button type="submit" mat-button class="ml-2" class="btn-success" [disabled]="loading">
        <span>Save</span>
      </button>
    </div>
  </div>
</form>
