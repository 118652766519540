import { Component, OnInit, Input, DoCheck, Output, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AutomationService, Parameter, Tag, WorkflowAction } from "src/app/services/workflow-plus/automation.service";
import { Clipboard } from "@angular/cdk/clipboard";
import { EmailEditorTagDialogComponent } from "../email-editor-tag-dialog/email-editor-tag-dialog.component";
import { NotificationService } from "src/app/services/utilities/notification.service";

@Component({
  selector: "app-email-editor-tags",
  templateUrl: "./email-editor-tags.component.html",
  styleUrls: ["./email-editor-tags.component.scss"],
})
export class EmailEditorTagsComponent implements DoCheck, OnInit {
  @Input() nested = false;
  @Input() parameters: Parameter[] = [];
  @Input() workflowAction: WorkflowAction;
  @Input() tags: Tag[];
  @Output() setTagsEvent = new EventEmitter<Tag[]>();
  @Input() loaded: boolean;
  private filteredParameters: Parameter[] = [];

  constructor(
    private dialog: MatDialog,
    private clipboard: Clipboard,
    private notifyService: NotificationService,
    public automationService: AutomationService
  ) {}

  ngOnInit(): void {
    if (this.nested) this.filteredParameters = JSON.parse(JSON.stringify(this.parameters));
    if (!this.tags) {
      this.tags = [];
      this.setTagsEvent.emit(this.tags);
    } else if (typeof this.tags === "string") {
      this.tags = JSON.parse(this.tags);
    }
  }

  ngDoCheck() {
    if (
      this.nested &&
      this.filteredParameters.length &&
      this.parameters.length &&
      this.filteredParameters[0].object.properties.length != this.parameters[0].object.properties.length
    ) {
      this.filteredParameters = JSON.parse(JSON.stringify(this.parameters));
      if (this.tags.length) {
        for (const tag of this.tags) {
          if (!this.filteredParameters[0].object.properties.find((p) => p.objectKey == tag.property["objectKey"])) {
            this.delete(tag);
          }
        }
      }
    }

    if (typeof this.tags === "string") {
      this.tags = JSON.parse(this.tags);
    }
  }

  addTag() {
    const tag: Tag = {
      id: null,
      created: new Date(),
      tag: "",
    };
    const dialogRef = this.dialog.open(EmailEditorTagDialogComponent, {
      width: "600px",
      data: {
        tag: tag,
        parameters: this.parameters,
        workflowAction: this.workflowAction,
        nested: this.nested,
        tags: this.tags,
      },
    });
    dialogRef.afterClosed().subscribe(async (tag: Tag) => {
      if (tag) {
        if (!this.tags) this.tags = [];
        this.tags.push(tag);
        this.setTagsEvent.emit(this.tags);
      }
    });
  }

  edit(tag: Tag) {
    if (!tag.parameter?.object) {
      const thisParameter = this.parameters.find((param) => param.id === tag.parameter?.id);
      if (thisParameter) {
        tag.parameter = thisParameter;
      }
    }

    if (!tag.property?.name) {
      const selectedProperty = tag.parameter?.object?.properties.find((prop) => prop.id === tag.property.id);
      if (selectedProperty) {
        tag.property = selectedProperty;
      }
    }
    const originalTag = Object.assign({}, tag);

    tag.isEditing = true;
    const dialogRef = this.dialog.open(EmailEditorTagDialogComponent, {
      width: "600px",
      data: {
        tag: tag,
        parameters: this.parameters,
        workflowAction: this.workflowAction,
        tags: this.tags,
      },
    });
    dialogRef.afterClosed().subscribe(async (resultTag: Tag) => {
      if (!resultTag) {
        tag = originalTag;
      }
      tag.isEditing = false;
      this.setTagsEvent.emit(this.tags);
    });
  }

  copy(tag: Tag) {
    this.clipboard.copy(tag.tag);
    this.notifyService.notify(`The tag ${tag.tag} was copied to your clipboard`);
    tag.copy = true;
    this.setTagsEvent.emit(this.tags);
  }

  delete(tag: Tag) {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
      this.setTagsEvent.emit(this.tags);
    }
  }
}
