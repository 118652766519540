import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { UntypedFormControl } from "@angular/forms";
import {
  ServiceAccountService,
  ServiceAccount,
  Projects,
  Project,
} from "src/app/services/admin-plus/service-account.service";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { EmailsService } from "src/app/services/storage-plus/emails.service";

@Component({
  selector: "app-add-service-account-automatically",
  templateUrl: "./add-service-account-automatically.component.html",
  styleUrls: ["./add-service-account-automatically.component.scss"],
})
export class AddServiceAccountAutomaticallyComponent implements OnInit {
  public serviceAccount: ServiceAccount;
  public projectId: string;
  public projectName: string;
  public delegateEmail: string;
  public projects: Projects[] = [];
  public new: boolean = false;
  public existing: boolean = false;
  public selectProjectControl = new UntypedFormControl();

  constructor(
    private serviceAccountService: ServiceAccountService,
    private navService: NavService,
    private dialogRef: MatDialogRef<AddServiceAccountAutomaticallyComponent>,
    private notifyService: NotificationService,
    private emailService: EmailsService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {}

  async existingProject() {
    this.navService.loading.next(true);
    this.existing = !this.existing;
    this.new = false;
    if (this.existing) {
      let projects = await this.serviceAccountService.listProjects();
      if (projects) {
        if (projects["projects"]) {
          this.projects = projects["projects"];
        }
      }
    }
    this.navService.loading.next(false);
  }

  async newProject() {
    this.new = !this.new;
    this.existing = false;
  }

  close() {
    localStorage.setItem("automaticServiceAccount", "false");
    this.dialogRef.close();
  }

  chooseProject(value: Project) {
    this.projectName = value["name"];
    this.projectId = value["projectId"];
  }

  async processForm() {
    this.navService.loading.next(true);
    if (this.emailService.isValidEmail(this.delegateEmail.trim())) {
      let body = {
        projectId: this.projectId,
        projectName: this.projectName,
        delegateEmail: this.delegateEmail,
      };
      if (this.new) {
        let response = await this.serviceAccountService.addServiceAccountToProject(body);
        if (response) {
          this.notifyService.notify(`The service account was successfully added to the new project ${response}`);
          localStorage.setItem("automaticServiceAccount", "false");
          this.dialogRef.close();
        }
      }
      if (this.existing) {
        let response = await this.serviceAccountService.addServiceAccountToExistingProject(body);
        if (response) {
          this.notifyService.notify(
            `The service account was successfully added to the existing project ${this.projectName}`
          );
          localStorage.setItem("automaticServiceAccount", "false");
          this.dialogRef.close();
        }
      }
    } else {
      this.notifyService.notify(`The delegate email address needs to be a valid email`);
    }
    this.navService.loading.next(false);
  }
}
