<a
  *ngIf="
    (!link.module?.name || moduleService.isValidModule(link.module.name)) &&
    ((link.adminOnly && current_user.isAdmin) || !link.adminOnly)
  "
  matTooltip="{{ link.errored ? leftNavService.serviceAccountRequiredMessage : link.description }}"
  class="menu-link faa-parent"
  [ngClass]="link.loading || link.disabled ? 'text-muted' : link.errored ? 'text-danger' : 'animated-hover'"
  [routerLink]="link.routerLink"
  [routerLinkActiveOptions]="{ exact: false }"
  matTooltipPosition="right"
  routerLinkActive="active-link"
  [class.disabled]="link.disabled"
>
  <div class="list-link">
    <i
      class="list-link__icon faa-shake material-icons material-icon-align"
      matBadgePosition="after"
      matBadgeSize="medium"
      [matBadgeHidden]="!link.loading"
      matBadge="filter_tilt_shift"
      >{{ link.errored ? "error" : link.icon }}</i
    >
    <span class="list-link__name">{{ link.name }}</span>
  </div>
</a>

<div class="submenu-links" *ngIf="!link.disabled && link.children?.length" [ngClass]="link.showChildren ? '' : 'd-none'">
  <ng-container *ngFor="let child of link.children">
    <a
      class="submenu-link"
      *ngIf="checkAgentRequirement(child)"
      [routerLink]="child.routerLink"
      (click)="addQueryParams(child.queryParams, child.routerLink)"
      [routerLinkActiveOptions]="{ exact: false }"
      routerLinkActive="active-link"
    >
      {{ child.name }}
    </a>
  </ng-container>
</div>
