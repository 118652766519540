import { Component, OnInit, EventEmitter, Output, ViewChild, Input } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { UntypedFormControl } from "@angular/forms";
import { ReportsService, StorageReport, File } from "src/app/services/google/reports.service";
import { MatSort } from "@angular/material/sort";
import { merge, of as observableOf } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { Filter, FiltersService, FilterType } from "src/app/services/utilities/filters.service";
import { SelectionModel } from "@angular/cdk/collections";

@Component({
  selector: "app-storage-report-duplicate-files-table",
  templateUrl: "./storage-report-duplicate-files-table.component.html",
  styleUrls: ["./storage-report-duplicate-files-table.component.scss"],
})
export class StorageReportDuplicateFilesTableComponent implements OnInit {
  @Input() options = [];
  @Input() storageReport: StorageReport = {
    status: {
      name: "",
      id: 4,
    },
    totalUsers: 0,
    totalStorage: 0,
    totalDrive: 0,
    totalGmail: 0,
    totalPhotos: 0,
    allowDelete: false,
  };

  @Input() fixDuplicateBanner = 0;

  displayedColumns: string[] = [/*"select", */ "totalFiles", "totalStorageMb", "md5Checksum"];

  dataSource = new MatTableDataSource<File>();
  mimeTypeActions = new UntypedFormControl();
  maxResultsActions = new UntypedFormControl();
  usageSize = new UntypedFormControl();
  selection = new SelectionModel<File>(true, []);

  maxResults = 10;
  private getCount = true;
  totalFileCount = 0;
  page = 1;
  offset = 0;
  userId = null;
  driveId = 0;

  nextButtonDis = false;
  nextButton = false;
  nextPageDisabled = false;
  loading = false;

  direction = "desc";
  activeOrderBy = "totalStorageMb";
  name = "";

  files: File[] = [];

  /* filtered search stuff */
  private usageSizeSearch = "0";
  private md5ChecksumValue = "";

  // Filter code
  selectedFilters: Filter[] = [];
  filterFromUser = false;

  filterTypeValue: FilterType = FilterType.DUPLICATE_FILE;

  @ViewChild(MatSort) sort!: MatSort;

  @Output() filesEvent = new EventEmitter<File[]>();

  data = {
    usageSizeSearch: this.usageSizeSearch,
    md5ChecksumValue: this.md5ChecksumValue,
    userId: this.userId,
    page: this.page,
    offset: this.offset,
    filtersCount: 0,
  };

  constructor(
    private navService: NavService,
    private reportsService: ReportsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private filtersService: FiltersService
  ) {}

  async ngOnInit() {
    const hasNavFilters = await this.filtersService.checkNavFilters(this.filterTypeValue);
    if (!hasNavFilters) this.loadData();
    this.storageReport = await this.reportsService.getStorageReport();
    if (!this.storageReport || this.storageReport.status.id != 4) {
      //complete
      this.router.navigate(["/reports/storage"]);
    }
  }

  private async loadData(arrow = "") {
    this.navService.loading.next(true);
    await this.getDuplicateFiles(arrow);
    this.navService.loading.next(false);
  }

  receiveData($event) {
    this.data = $event;
    this.md5ChecksumValue = this.data.md5ChecksumValue;
    this.usageSizeSearch = this.data.usageSizeSearch;
    this.userId = this.data.userId;
    this.page = this.data.page;
    this.offset = this.data.offset;
    this.loadData();
  }

  private async getDuplicateFiles(arrow = "") {
    this.updateLoader(true);
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.nextButton = true;
          this.getCount = !arrow;
          return this.reportsService.getDuplicateFiles(
            this.maxResults,
            this.activeOrderBy,
            this.direction,
            this.offset,
            this.md5ChecksumValue,
            this.usageSizeSearch,
            this.getCount
          );
        }),
        map((files) => {
          if (files) {
            this.files = files["data"];
            this.dataSource = new MatTableDataSource(this.files);
            this.dataSource.sort = this.sort;
            this.nextPageDisabled = this.dataSource.data.length < this.maxResults ? true : false;
            //pagination
            this.totalFileCount = this.getCount ? (files["count"] ? files["count"] : 0) : this.totalFileCount;
            if (this.dataSource.filteredData.length < 10) {
              this.nextButtonDis = true;
            }
            if (this.dataSource.filteredData.length > 10) {
              this.nextButtonDis = false;
            }
            if (arrow === "next" && this.dataSource.filteredData.length + this.offset === this.totalFileCount) {
              this.nextButtonDis = true;
            }
            if (arrow === "next" && this.dataSource.filteredData.length + this.offset < this.totalFileCount) {
              this.nextButtonDis = false;
            }
            if (this.dataSource.filteredData.length + this.offset < this.totalFileCount) {
              this.nextButtonDis = false;
            }
            if (this.dataSource.filteredData.length + this.offset === this.totalFileCount) {
              this.nextButtonDis = true;
            }
            if (arrow === "prev" && this.totalFileCount > 0) {
              this.nextButtonDis = false;
            }
            if (this.totalFileCount === this.maxResults) {
              this.nextButtonDis = true;
            }
          }
          this.nextButton = false;
          this.updateLoader(false);
          return this.files;
        }),
        catchError(() => {
          this.updateLoader(false);
          return observableOf([]);
        })
      )
      .subscribe((files) => {
        if (files) {
          this.updateLoader(false);
          return files;
        }
      });
  }

  async sendDuplicateFilesEvent() {
    const files = [];
    let response = {};
    let offset = 0;
    do {
      response = await this.reportsService.getDuplicateFiles(
        100,
        this.activeOrderBy,
        this.direction,
        offset,
        this.md5ChecksumValue,
        this.usageSizeSearch,
        this.getCount
      );
      offset = offset + 100;
      files.push(...response["data"]);
    } while (response["data"].length > 0);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const emitObject: any = {
      files: files,
      columns: this.displayedColumns,
    };
    this.filesEvent.emit(emitObject);
  }

  maxResultChange(value: number) {
    this.page = 1;
    this.offset = 0;
    this.maxResults = parseInt(value + "");
    this.loadData("max");
  }

  resetToFirstPage() {
    this.page = 1;
    this.offset = 0;
    this.loadData("");
  }

  onSubmit() {
    this.loadData("");
  }

  setPager(data) {
    this.page = data.page;
    this.offset = data.offset;
    this.maxResults = data.maxResults ? data.maxResults : this.maxResults;
    this.loadData(data.arrow);
  }

  routeToFilesTable(md5Checksum: string) {
    const tableId = `_${FilterType.FILE.toString()}`;
    const paramKey = encodeURI("MD5 Checksum") + tableId;
    this.router.navigate(["/reports/storage/files"], {
      relativeTo: this.activatedRoute,
      queryParams: { [paramKey]: md5Checksum },
      state: {
        addChip: true,
      },
    });
  }

  getPageData() {
    return this.dataSource._pageData(this.dataSource._orderData(this.dataSource.filteredData));
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  masterToggle() {
    this.isEntirePageSelected()
      ? this.selection.deselect(...this.getPageData())
      : this.selection.select(...this.getPageData());
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.getPageData() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1}`;
  }

  updateLoader(value: boolean) {
    for (const option of this.options) {
      if (option["name"] == "Export to Sheets" || option["name"] == "Export to PDF") {
        option["disabled"] = value;
      }
    }
    this.loading = value;
    this.navService.loading.next(value);
  }
}
