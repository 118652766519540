import { Component, Input } from "@angular/core";
import { ChartType } from "angular-google-charts";

@Component({
  selector: "app-storage-report-stacked-horizontal-chart",
  templateUrl: "./storage-report-stacked-horizontal-chart.component.html",
  styleUrls: ["./storage-report-stacked-horizontal-chart.component.scss"],
})
export class StorageReportStackedHorizontalChartComponent {
  @Input() data: any[] = [];
  public chartColumns = [
    "Organizational Unit Name",
    "Total Storage",
    { role: "tooltip", type: "string", p: { html: true } },
  ];
  type: ChartType = ChartType.BarChart;
  options = {
    hAxis: {
      title: "GB",
    },
    chartArea: { right: 0, top: 25, width: "80%" },
    legend: "none",
    colors: ["#114c71", "#FFAA01", "#008000"],
    tooltip: { isHtml: true },
  };

  ngOnChanges() {
    this.data = this.data.slice(0, 5);
  }
}
