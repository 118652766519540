import { Component, OnInit } from "@angular/core";
import { AccountService } from "src/app/services/admin-plus/account.service";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { CustomerService, Customer } from "src/app/services/admin-plus/customer.service";
import { TenantDatabaseService, TenantDatabase } from "src/app/services/asset-plus/tenant-database.service";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { MatDialog } from "@angular/material/dialog";
import { EditDialogComponent } from "./dialogs/edit-dialog/edit-dialog.component";
import { SubscriptionExpiredDialogComponent } from "./dialogs/subscription-expired-dialog/subscription-expired-dialog.component";
import { AdminPlusService } from "src/app/services/admin-plus/admin-plus.service";
import { ServiceAccountService } from "src/app/services/admin-plus/service-account.service";
import { Router } from "@angular/router";
import { NoServiceAccountDialogComponent } from "../admin-plus-service-account/dialogs/no-service-account-dialog/no-service-account-dialog.component";

@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["../admin-plus-service-account/service-account-management.component.scss"],
})
export class AccountComponent implements OnInit {
  options = [];
  loading: boolean;
  account;
  tenantDatabases: TenantDatabase[] = [];
  edit: boolean;
  customer: Customer = {
    tenantDatabase: {},
    subscription: {},
    serviceAccount: {},
  };
  stripeLink: string;
  renewLink: string;

  constructor(
    private accountService: AccountService,
    private navService: NavService,
    private customerService: CustomerService,
    private tenantDatabaseService: TenantDatabaseService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private adminPlusService: AdminPlusService,
    private serviceAccountService: ServiceAccountService,
    private router: Router
  ) {
    this.options.push({
      icon: "edit",
      name: "Edit Account",
      tooltip: "Update customer name and timezone",
      disabled: true,
    });
    this.adminPlusService.breadCrumbLinks.push({
      link: null,
      text: "Account",
      alt: "Account",
    });
  }

  async getAccount() {
    this.loading = true;
    this.navService.loading.next(true);
    await this.customerService.updateStripeSubscriptionExpiration();

    const accountPayload = await this.accountService.getAccount();
    if (accountPayload) this.account = accountPayload;

    const customerPayload = await this.customerService.getCustomer();
    if (customerPayload) {
      this.customer = customerPayload;
      localStorage.setItem("customer", JSON.stringify(this.customer));
    }

    if (this.customer?.id && !this.customer?.name?.length) {
      this.openDialog();
    }
    if (this.customer?.tenantEnabled) {
      this.tenantDatabases = await this.tenantDatabaseService.getTenantDatabases();
    }

    this.options[0].disabled = false;

    if (this.customer.subscription?.billing == "CREDIT_CARD") {
      const eligibleForFreeTrial = !this.account.customer.subscription?.expires;
      this.stripeLink = await this.customerService.getStripeLink(eligibleForFreeTrial);

      if (eligibleForFreeTrial && this.stripeLink) window.location.href = this.stripeLink;

      if (!eligibleForFreeTrial && this.account.customer.subscription.expired) {
        this.renewLink = await this.customerService.getStripeLink(true);
      }
    }

    this.loading = false;
    this.navService.loading.next(false);

    if (this.account?.customer?.subscription?.expired && this.account?.customer?.subscription?.expires) {
      this.openExpiredDialog();
      return;
    } else {
      this.customerService.loadCustomerModules();
    }

    if (!this.serviceAccountService.serviceAccount) await this.serviceAccountService.getServiceAccounts();

    if (!this.serviceAccountService.serviceAccount) {
      this.openNoServiceAccountDialog();
    }
  }

  openNoServiceAccountDialog() {
    const dialogRef = this.dialog.open(NoServiceAccountDialogComponent, {
      width: "600px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.router.navigateByUrl("/settings/product/service-account");
    });
  }

  ngOnInit(): void {
    this.getAccount();
  }

  optionSelected(option) {
    switch (option.name) {
      case "Edit Account":
        this.openDialog();
        break;
      default:
        break;
    }
  }

  openDialog() {
    const originalCustomer = JSON.parse(JSON.stringify(this.customer));
    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: "500px",
      data: this.customer,
    });

    dialogRef.afterClosed().subscribe(async (customer) => {
      if (!customer) return (this.customer = originalCustomer);

      this.navService.loading.next(true);
      this.options[0].disabled = true;
      const updatedCustomerRecord = await this.customerService.updateCustomer(customer);
      if (updatedCustomerRecord) {
        this.notificationService.notify("Account information updated");
        localStorage.setItem(customer, JSON.stringify(this.customer));
      } else {
        this.customer = originalCustomer;
        this.notificationService.notify("Failed to update account information");
      }
      this.options[0].disabled = false;
      this.navService.loading.next(false);
    });
  }

  goToStripe(stripeLink: string) {
    window.open(stripeLink);
  }

  openExpiredDialog() {
    this.dialog.open(SubscriptionExpiredDialogComponent, {
      width: "600px",
      data: {
        customer: this.customer,
        stripeLink: this.stripeLink,
        renewLink: this.renewLink,
      },
    });
  }
}
