import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { environment } from "src/environments/environment";
import { AuthService } from "src/app/services/admin-plus/auth.service";
import { User } from "src/app/web/login/user";
import { UtilityService } from "../utilities/utility.service";

@Injectable({
  providedIn: "root",
})
export class ServiceAccountService {
  private customerApi: string = environment.apiUrl + "/customer";
  private user: User;
  hasFailedScopes = false;
  serviceAccount: ServiceAccount;

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private authService: AuthService,
    private utilityService: UtilityService
  ) {}

  async getServiceAccounts() {
    this.user = await this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<ServiceAccount[]>((resolve) => {
      this.getServiceAccountsData().subscribe((serviceAccounts) => {
        if (serviceAccounts) {
          if (serviceAccounts.length) this.serviceAccount = serviceAccounts[0];
          resolve(serviceAccounts);
        } else {
          resolve([]);
        }
      });
    });
  }

  private getServiceAccountsData(): Observable<ServiceAccount[]> {
    return this.http
      .get<ServiceAccount[]>(this.customerApi + "/service-accounts", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<ServiceAccount[]>("getServiceAccountsData")));
  }

  public async addServiceAccountToExistingProject(body) {
    this.user = await this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<ServiceAccount>((resolve) => {
      this.addServiceAccountToExistingProjectData(body).subscribe((serviceAccount) => {
        if (serviceAccount) {
          resolve(serviceAccount);
        } else {
          resolve(null);
        }
      });
    });
  }

  private addServiceAccountToExistingProjectData(body): Observable<ServiceAccount> {
    return this.http
      .post<ServiceAccount>(
        this.customerApi + "/service-accounts/add-service-account-existing-project",
        { body },
        {
          headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        }
      )
      .pipe(catchError(this.authService.handleAPIError<ServiceAccount>("addServiceAccountToExistingProjectData")));
  }

  public async addServiceAccountToProject(body) {
    this.user = await this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<ServiceAccount>((resolve) => {
      this.addServiceAccountToProjectData(body).subscribe((serviceAccount) => {
        if (serviceAccount) {
          resolve(serviceAccount);
        } else {
          resolve(null);
        }
      });
    });
  }

  private addServiceAccountToProjectData(body): Observable<ServiceAccount> {
    return this.http
      .post<ServiceAccount>(
        this.customerApi + "/service-accounts/add-service-account-new-project",
        { body },
        {
          headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        }
      )
      .pipe(catchError(this.authService.handleAPIError<ServiceAccount>("addServiceAccountToProjectData")));
  }

  public async createUpdateServiceAccount(serviceAccount, action) {
    this.user = await this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<ServiceAccount>((resolve) => {
      this.createUpdateServiceAccountData(serviceAccount, action).subscribe((serviceAccount) => {
        if (serviceAccount) {
          resolve(serviceAccount);
        } else {
          resolve(null);
        }
      });
    });
  }

  private createUpdateServiceAccountData(serviceAccount: ServiceAccount, action: string): Observable<ServiceAccount> {
    if (action === "add") {
      return this.http
        .post<ServiceAccount>(this.customerApi + "/service-accounts", serviceAccount, {
          headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        })
        .pipe(catchError(this.authService.handleAPIError<ServiceAccount>("createUpdateServiceAccountData")));
    } else if (action === "edit") {
      return this.http
        .patch<ServiceAccount>(this.customerApi + "/service-accounts", serviceAccount, {
          headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        })
        .pipe(catchError(this.authService.handleAPIError<ServiceAccount>("createUpdateServiceAccountData")));
    }
  }

  public async checkScopes(serviceAccountId: number) {
    this.user = await this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<boolean>((resolve) => {
      this.checkScopesData(serviceAccountId).subscribe((check) => {
        if (check) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  private checkScopesData(serviceAccountId: number): Observable<boolean> {
    return this.http
      .post<boolean>(
        this.customerApi + "/service-accounts/" + serviceAccountId + "/checkScopes?alwaysRun=1",
        {},
        {
          headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        }
      )
      .pipe(catchError(this.authService.handleAPIError<boolean>("checkScopesData")));
  }

  public async failedScopeStatus(serviceAccountId: number) {
    const url = "/customer/service-accounts/" + serviceAccountId + "/failedScopesStatus";
    return new Promise<boolean>((resolve) => {
      this.utilityService.sendRequest("GET", url).subscribe((results) => {
        this.hasFailedScopes = !!results;
        resolve(!!results);
      });
    });
  }

  public async listProjects() {
    this.user = await this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Project[]>((resolve) => {
      this.listProjectsData().subscribe((projects) => {
        if (projects) {
          resolve(projects.projects);
        } else {
          resolve(null);
        }
      });
    });
  }

  private listProjectsData(): Observable<Projects> {
    return this.http
      .get<Projects>("https://cloudresourcemanager.googleapis.com/v1/projects", {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
        params: new HttpParams().set("filter", "lifecycleState:ACTIVE"),
      })
      .pipe(catchError(this.authService.handleAPIError<Projects>("listProjectsData")));
  }

  public async listServiceAccounts(projectId: string) {
    this.user = await this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Account[]>((resolve) => {
      this.listServiceAccountsData(projectId).subscribe((accounts) => {
        if (accounts.accounts) {
          resolve(accounts.accounts);
        } else {
          resolve([]);
        }
      });
    });
  }

  private listServiceAccountsData(projectId: string): Observable<Accounts> {
    return this.http
      .get<Accounts>("https://iam.googleapis.com/v1/projects/" + projectId + "/serviceAccounts", {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Accounts>("listServiceAccountsData")));
  }

  public async autoServiceAccount(autoSA: ServiceAccount) {
    this.user = await this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<ServiceAccount>((resolve) => {
      this.autoServiceAccountData(autoSA).subscribe(
        (autoSA) => {
          if (autoSA) {
            resolve(autoSA);
          }
        },
        (error) => {
          resolve(error);
        }
      );
    });
  }

  private autoServiceAccountData(autoSA: ServiceAccount): Observable<ServiceAccount> {
    return this.http.post<ServiceAccount>(this.customerApi + "/service-accounts/auto", autoSA, {
      headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
    });
  }
}

export interface ServiceAccount {
  id?: number;
  created?: string;
  customerId?: string;
  name?: string;
  description?: string;
  accessKey?: string;
  serviceEmail?: string;
  delegateEmail?: string;
  type?: ServiceAccountType;
  testedDate?: string;
  clientId?: string;
  projectId?: string;
  projectName?: string;
  scopes?: string;
  mailbox?: Mailbox;
}

export interface Mailbox {
  email: string;
  created: Date;
  autoConfigure: boolean;
  lastCheck: Date;
}

export interface ServiceAccountType {
  id?: string;
  name: string;
}

export interface Projects {
  projects: Project[];
}

export interface Project {
  projectNumber?: string;
  projectId?: string;
  lifeCycleState?: string;
  name?: string;
}

export interface Accounts {
  accounts: Account[];
}

export interface Account {
  name: string;
  projectId: string;
  uniqueId: string;
  email: string;
  displayName: string;
}
