import { Injectable } from "@angular/core";
import { Device } from "src/app/models/asset-plus/device";
import { ActionBody, UtilityService, Payload } from "../utilities/utility.service";

@Injectable({
  providedIn: "root",
})
export class DeviceService {
  constructor(public utilityService: UtilityService) {}

  async createUpdateDevice(device: Device) {
    return new Promise<Payload>((resolve) => {
      const method = "POST";
      const path = `/devices/device/` + (device.id ? device.id : "");
      const body = device;

      this.utilityService.sendRequest(method, path, {}, body).subscribe((results) => {
        resolve(results);
      });
    });
  }

  async getDevice(deviceId, params) {
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("GET", `/devices/device/${deviceId}`, params, null).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  async getDevices(params) {
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("GET", `/devices`, params, null).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  async getFilters(): Promise<Payload> {
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("GET", `/devices/filters`, {}).subscribe((payload) => {
        if (payload) {
          resolve(payload);
        } else {
          resolve(null);
        }
      });
    });
  }

  async setAction(body: ActionBody, params = {}) {
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("POST", `/devices/batch/${body.action.object}`, params, body).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  async deleteDevices(devices: Device[]) {
    return new Promise<Payload>((resolve) => {
      const method = devices.length > 1 ? "POST" : "DELETE";
      const path = `/devices/device/` + (devices.length > 1 ? "batch" : devices[0].id);
      const body = devices.length > 1 ? devices.map((device) => ({ id: device.id, action: "delete" })) : {};

      this.utilityService.sendRequest(method, path, {}, body).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }
}
