import { Component, Input, OnInit } from "@angular/core";
import { TicketService } from "src/app/services/ticket-plus/ticket/ticket.service";
import { NavService } from "src/app/services/admin-plus/nav.service";

@Component({
  selector: "app-analytics",
  templateUrl: "./analytics.component.html",
  styleUrls: ["./analytics.component.scss"],
})
export class AnalyticsComponent implements OnInit {
  @Input() title: string;
  @Input() type: string;
  loading: boolean;
  panelOpenState = true;
  charts = [];
  options = {
    chartArea: { width: "100%", height: "90%" },
    legend: { position: "none" },
    colors: ["#114c71", "#FFAA01", "#008000", "#FF0000", "#F11100", "#099000", "#3333FF", "#003344", "#AA0022"],
    sliceVisibilityThreshold: 0,
    title: "",
    bars: "",
  };

  constructor(private ticketService: TicketService, private navService: NavService) {}

  async ngOnInit(): Promise<void> {
    this.navService.loading.next(true);
    this.loading = true;
    await this.populatePieChartData();
    await this.populateTicketAnalytics();
    this.loading = false;
    this.navService.loading.next(false);
  }

  async populatePieChartData() {
    const pieChartOptions = Object.assign({}, this.options);
    pieChartOptions["pieHole"] = 0.4;
    const pieChartData = {
      title: "Ticket Statuses",
      type: "PieChart",
      options: pieChartOptions,
      data: [],
    };
    let ticketCount;
    await this.ticketService.getTickets().then((response) => {
      ticketCount = response.count;
    });

    await this.ticketService
      .getTickets({ firstResponse: "NULL", search: JSON.stringify({ statusId: 1, statusParentId: 1 }) })
      .then((response) => {
        ticketCount -= response.count;
        pieChartData.data.push(["Awaiting Response", response.count]);
      });

    await this.ticketService.getTickets({ search: JSON.stringify({ statusId: 3, statusParentId: 3 }) }).then((response) => {
      ticketCount -= response.count;
      pieChartData.data.push(["Closed", response.count]);
    });

    pieChartData.data.push(["In Progress", ticketCount]);

    this.charts.push(pieChartData);
  }

  async populateTicketAnalytics() {
    await this.ticketService.getAnalytics({ limit: 5 }).then((response) => {
      const repsonseTimeOptions = Object.assign({}, this.options);
      repsonseTimeOptions.bars = "horizontal";
      const repsonseTimeData = {
        title: "Response Time (Days)",
        type: "Bar",
        options: repsonseTimeOptions,
        data: [],
      };
      for (const repsonseTime of response.responseTimes.data) {
        repsonseTimeData.data.push([repsonseTime.name, repsonseTime.responseDays]);
      }
      this.charts.push(repsonseTimeData);

      const categoryOptions = Object.assign({}, this.options);
      categoryOptions.bars = "horizontal";
      const categoryData = {
        title: "Top Categories",
        type: "Bar",
        options: categoryOptions,
        data: [],
      };
      for (const category of response.topCategories.data) {
        categoryData.data.push([category.name, category.ticketCount]);
      }
      this.charts.push(categoryData);
    });
  }
}
