<h2 mat-dialog-title>
  <span>Customize Email</span>
</h2>
<form (ngSubmit)="processForm()" autocomplete="off">
  <div mat-dialog-content>
    <div class="row pb-3">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label> Header Image URL </mat-label>
          <input
            matInput
            [(ngModel)]="headerUrl"
            type="string"
            name="headerUrl"
            id="headerUrl"
            placeholder="Header Image URL"
            autocomplete="off"
            maxlength="2048"
          />
          <mat-hint>Enter the URL of the image to be displayed at the top of the email.</mat-hint>
        </mat-form-field>
      </div>
      <div class="col-12 mt-4">
        <mat-form-field class="w-100">
          <mat-label> Body Text </mat-label>
          <textarea
            matInput
            [(ngModel)]="paragraph"
            type="string"
            name="paragraph"
            id="paragraph"
            placeholder="Body Text"
            autocomplete="off"
            maxlength="1000"
            #bodyText
          ></textarea>
          <mat-hint
            >Separating lines with a new line will display as a new paragraph. Text entered will display as unformatted text,
            does not support HTML tags. {{ bodyText.value?.length }} of 1000 characters.</mat-hint
          >
        </mat-form-field>
      </div>
      <div class="col-12 mt-5">
        <mat-form-field class="w-100">
          <mat-label> Footer Text </mat-label>
          <textarea
            matInput
            [(ngModel)]="footerLine"
            type="string"
            name="footerLine"
            id="footerLine"
            placeholder="Footer Text"
            autocomplete="off"
            maxlength="1000"
            #footerText
          ></textarea>
          <mat-hint
            >Separating lines with a new line will display as a new line. Text entered will display as unformatted text, does
            not support HTML tags. {{ footerText.value?.length }} of 1000 characters.</mat-hint
          >
        </mat-form-field>
      </div>
    </div>
    <app-dialog-actions-container></app-dialog-actions-container>
  </div>
</form>
