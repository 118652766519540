import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
	selector: "app-dialog-storage-report-missing-scopes",
	templateUrl: "./dialog-storage-report-missing-scopes.component.html",
	styleUrls: ["./dialog-storage-report-missing-scopes.component.scss"],
})
export class DialogStorageReportMissingScopesComponent {
	missingScopes: string[] = [];
	constructor(
		private dialogRef: MatDialogRef<DialogStorageReportMissingScopesComponent>,
		@Inject(MAT_DIALOG_DATA) public data: string[]
	) {
		this.missingScopes = this.data;
	}

	tryAgain() {
		this.dialogRef.close(true);
	}
}
