import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { TenantDatabaseService, TenantDatabase } from "src/app/services/asset-plus/tenant-database.service";
import { NavService } from "src/app/services/admin-plus/nav.service";

@Component({
  selector: "app-edit-tenant-database",
  templateUrl: "./edit-tenant-database.component.html",
  styleUrls: ["./edit-tenant-database.component.scss"],
})
export class EditTenantDatabaseComponent implements OnInit {
  public action: string;
  public errorInv = "Invalid field";
  public errorReq = "Required field";
  public tenantDatabase: TenantDatabase = {};
  public host: string;
  public port: number;
  public database: string;
  public user: string;
  public connectionType: string;

  constructor(
    private dialogRef: MatDialogRef<EditTenantDatabaseComponent>,
    private notifyService: NotificationService,
    private tenantDatabaseService: TenantDatabaseService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private navService: NavService
  ) {}

  ngOnInit(): void {
    this.action = this.data.action;
    if (this.action == "edit") {
      this.tenantDatabase = this.data.tenantDatabase;
      this.host = this.data.tenantDatabase.host;
      this.port = this.data.tenantDatabase.port;
      this.database = this.data.tenantDatabase.database;
      this.user = this.data.tenantDatabase.user;
      this.connectionType = this.data.socketPath ? "socketPath" : "ssl";
    }
  }

  async processForm() {
    this.navService.loading.next(true);
    let tenantDatabase = null;
    if (!this.tenantDatabase.host || !this.tenantDatabase.host.trim()) {
      this.notifyService.notify("Host is required");
    } else if (!this.tenantDatabase.port) {
      this.notifyService.notify("Port is required");
    } else if (!this.tenantDatabase.database || !this.tenantDatabase.database.trim()) {
      this.notifyService.notify("Database is required");
    } else if (!this.tenantDatabase.user || !this.tenantDatabase.user.trim()) {
      this.notifyService.notify("User is required");
    } else if (this.action == "add" && (!this.tenantDatabase.password || !this.tenantDatabase.password.trim())) {
      this.notifyService.notify("Password is required");
    } else if (!this.connectionType) {
      this.notifyService.notify("Connection Type is required");
    } else if (!this.tenantDatabase.socketPath && this.connectionType === "socketPath") {
      this.notifyService.notify("Socket Path is required");
    } else if (
      this.action == "add" &&
      (!this.tenantDatabase.sslKey || !this.tenantDatabase.sslKey.trim()) &&
      this.connectionType === "ssl"
    ) {
      this.notifyService.notify("SSL Key is required");
    } else if (
      this.action == "add" &&
      (!this.tenantDatabase.sslCertificate || !this.tenantDatabase.sslCertificate.trim()) &&
      this.connectionType === "ssl"
    ) {
      this.notifyService.notify("SSL Certificate is required");
    } else if (
      this.action == "add" &&
      (!this.tenantDatabase.sslCertificateAuthority || !this.tenantDatabase.sslCertificateAuthority.trim()) &&
      this.connectionType === "ssl"
    ) {
      this.notifyService.notify("SSL Certificate Authority is required");
    } else if (
      this.action == "edit" &&
      this.tenantDatabase.host == this.host &&
      this.tenantDatabase.port == this.port &&
      this.tenantDatabase.database == this.database &&
      this.tenantDatabase.user == this.user &&
      this.tenantDatabase.password == undefined &&
      this.tenantDatabase.sslKey == undefined &&
      this.tenantDatabase.sslCertificate == undefined &&
      this.tenantDatabase.sslCertificateAuthority == undefined
    ) {
      this.notifyService.notify("No updates to apply, cancel to exit");
    } else {
      let status = "updated";
      if (this.tenantDatabase.id) {
        tenantDatabase = await this.tenantDatabaseService.updateTenantDatabase(this.tenantDatabase);
      } else {
        tenantDatabase = await this.tenantDatabaseService.createTenantDatabase(this.tenantDatabase);
        if (tenantDatabase) {
          status = "created";
        }
      }
    }

    if (tenantDatabase) {
      //if there's a response it was a success - otherwise the service SHOULD throw an error
      this.notifyService.notify("Database " + status);
      this.tenantDatabase = tenantDatabase;
      this.dialogRef.close(true);
    }
    this.navService.loading.next(false);
  }
}
