import { Component, Input } from "@angular/core";
import { ChartType } from "angular-google-charts";

@Component({
  selector: "app-storage-report-historical-line-chart",
  templateUrl: "./storage-report-historical-line-chart.component.html",
  styleUrls: ["./storage-report-historical-line-chart.component.scss"],
})
export class StorageReportHistoricalLineChartComponent {
  @Input() data: any[] = [];
  public chartColumns = ["Date", "Total", "Drive", "Gmail", "Photos"];
  title = "Historical Storage Usage";
  type: ChartType = ChartType.LineChart;
  options = {
    vAxis: {
      title: "GB",
    },
    hAxis: { slantedText: true, slantedTextAngle: 45, type: "string" },
    chartArea: { right: 0, top: 25, width: "85%" },
    /*curveType: 'function',*/
    legend: "none",
    colors: ["#FF0000", "#114c71", "#FFAA01", "#008000"],
  };
}
