import { Component, OnInit, Input } from "@angular/core";
import { SPACE, ENTER } from "@angular/cdk/keycodes";
import { UntypedFormControl } from "@angular/forms";
import { MatChipInputEvent } from "@angular/material/chips";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { TimesService } from "src/app/services/utilities/times.service";
import { MatDialog } from "@angular/material/dialog";
import { ReportsService, StorageReport, Week, Email, User, ScheduledReport } from "src/app/services/google/reports.service";

@Component({
  selector: "app-storage-report-scheduled",
  templateUrl: "./storage-report-scheduled.component.html",
  styleUrls: ["./storage-report-scheduled.component.scss"],
})
export class StorageReportScheduledComponent implements OnInit {
  public week: Week = {
    days: [
      {
        name: "Sunday",
        dayOfWeek: 0,
        offsetDayOfWeek: 0,
        time: "0",
        offsetTime: "0",
        checked: false,
        changeDayOfWeek: false,
      },
      {
        name: "Monday",
        dayOfWeek: 1,
        offsetDayOfWeek: 0,
        time: "0",
        offsetTime: "0",
        checked: false,
        changeDayOfWeek: false,
      },
      {
        name: "Tuesday",
        dayOfWeek: 2,
        offsetDayOfWeek: 0,
        time: "0",
        offsetTime: "0",
        checked: false,
        changeDayOfWeek: false,
      },
      {
        name: "Wednesday",
        dayOfWeek: 3,
        offsetDayOfWeek: 0,
        time: "0",
        offsetTime: "0",
        checked: false,
        changeDayOfWeek: false,
      },
      {
        name: "Thursday",
        dayOfWeek: 4,
        offsetDayOfWeek: 0,
        time: "0",
        offsetTime: "0",
        checked: false,
        changeDayOfWeek: false,
      },
      {
        name: "Friday",
        dayOfWeek: 5,
        offsetDayOfWeek: 0,
        time: "0",
        offsetTime: "0",
        checked: false,
        changeDayOfWeek: false,
      },
      {
        name: "Saturday",
        dayOfWeek: 6,
        offsetDayOfWeek: 0,
        time: "0",
        offsetTime: "0",
        checked: false,
        changeDayOfWeek: false,
      },
    ],
  };

  @Input() public storageReport: StorageReport = {
    status: {
      name: "",
      id: 0,
    },
    totalUsers: 0,
    totalStorage: 0,
    totalDrive: 0,
    totalGmail: 0,
    totalPhotos: 0,
    allowDelete: false,
    usageLimit: 0,
  };

  public scheduledReport: ScheduledReport[] = [];

  readonly separatorKeyCodes = [SPACE, ENTER] as const;

  public emailCtrl = new UntypedFormControl();

  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;

  public emailAddresses: Email[] = [];

  public offset: string;
  public domain: string;

  public user: User;

  constructor(
    private navService: NavService,
    private reportsService: ReportsService,
    private notifyService: NotificationService,
    private timesService: TimesService,
    private dialog: MatDialog
  ) {
    const user: User = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.user = user;
      this.domain = this.user.email.replace(/.*@/, "");
    }
  }

  public add(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();
    const valueDomain = value.split("@")[1];

    // Add our email
    if (value) {
      let counter = 0;
      for (let j = 0; j < this.emailAddresses.length; j++) {
        if (this.emailAddresses[j]["name"].trim() === value.trim()) {
          counter++;
        }
        if (this.emailAddresses[j]["name"].split("@")[1].trim() !== valueDomain) {
          counter++;
        }
      }
      if (counter === 0) {
        this.emailAddresses.push({ name: value });
      }
    }

    // Clear the input value
    this.emailCtrl.setValue(null);
  }

  public remove(email: Email): void {
    const index = this.emailAddresses.indexOf(email);

    if (index >= 0) {
      this.emailAddresses.splice(index, 1);
    }
  }

  async processForm() {
    this.navService.loading.next(true);
    // eslint-disable-next-line no-useless-escape
    this.offset = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    const initialOffset = this.offset;
    this.offset = this.offset.substring(1, 3);
    let error = false;
    for (let i = 0; i < this.week.days.length; i++) {
      if (this.week.days[i]["checked"]) {
        if (this.week.days[i]["time"] !== "0" || this.week.days[i]["time"] !== "") {
          const number = this.timesService.convertToUTC(
            this.week.days[i]["time"],
            this.offset,
            this.week.days[i]["dayOfWeek"],
            initialOffset
          );
          const offsetTime = number["number"].toString() + ":" + this.week.days[i]["time"].substring(3, 5);
          if (offsetTime.split(":")[1] !== "") {
            const offsetDay = number["dayOfWeek"];
            this.week.days[i]["offsetTime"] = offsetTime;
            this.week.days[i]["offsetDayOfWeek"] = offsetDay;
          } else {
            error = true;
            this.notifyService.notify("You must give a meridian (AM or PM)");
          }
        } else {
          error = true;
          this.notifyService.notify(
            "You must give a time when checking a day. Make sure to assign an hour, minute, and a meridian"
          );
        }
      }
    }
    if (!error) {
      const response = await this.reportsService.scheduleRunReport(this.week, this.emailAddresses);
      if (response) {
        this.notifyService.notify("The scheduled reports have been saved");
      }
    }
    this.navService.loading.next(false);
  }

  checkTime(event: Event, day: Week) {
    if (event["checked"] === true) {
      day["checked"] = true;
    } else {
      day["checked"] = false;
    }
  }

  async getScheduledReports() {
    const response = await this.reportsService.getScheduledReports();
    if (response) {
      this.scheduledReport = response;
      // eslint-disable-next-line no-useless-escape
      this.offset = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
      const initialOffset = this.offset;
      this.offset = this.offset.substring(1, 3);
      for (let i = 0; i < this.scheduledReport.length; i++) {
        const localDayOfWeek = this.timesService.convertDayToLocal(
          this.scheduledReport[i]["time"],
          this.offset,
          this.scheduledReport[i]["dayOfWeek"],
          initialOffset
        );
        const number = this.timesService.convertToLocal(this.scheduledReport[i]["time"], this.offset, initialOffset);
        const minute = this.scheduledReport[i]["time"].substring(3, 5);
        const zero = "0";
        if (number["number"] < 10) {
          this.scheduledReport[i]["time"] = zero + number["number"].toString() + ":" + minute;
        } else {
          this.scheduledReport[i]["time"] = number["number"].toString() + ":" + minute;
        }
        this.scheduledReport[i]["dayOfWeek"] = localDayOfWeek;
      }
      for (let i = 0; i < this.week.days.length; i++) {
        for (let j = 0; j < this.scheduledReport.length; j++) {
          if (this.week.days[i].dayOfWeek === this.scheduledReport[j].dayOfWeek) {
            this.week.days[i].checked = true;
            this.week.days[i].dayOfWeek = this.scheduledReport[j].dayOfWeek;
            this.week.days[i].time = this.scheduledReport[j].time;
          }
        }
      }
    }
  }

  async ngOnInit() {
    this.navService.loading.next(true);
    const reportEmails = this.storageReport["emailAddresses"].split(",");
    for (let i = 0; i < reportEmails.length; i++) {
      if (reportEmails[i]) {
        const email: Email = {
          name: reportEmails[i],
        };

        this.emailAddresses.push(email);
      }
    }
    this.getScheduledReports();
    this.navService.loading.next(false);
  }
}
