import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Manufacturer } from "src/app/models/asset-plus/manufacturer";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { UtilityService } from "src/app/services/utilities/utility.service";

@Component({
  selector: "app-manufacturer-dialog",
  templateUrl: "./manufacturer-dialog.component.html",
  styleUrls: ["./manufacturer-dialog.component.css"],
})
export class ManufacturerDialogComponent implements OnInit {
  action = "Add";
  loading = false;
  originalManufacturerName = "";

  @Input() manufacturer = new Manufacturer({});

  constructor(
    private dialogRef: MatDialogRef<ManufacturerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private notifyService: NotificationService,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.action = this.utilityService.capitalizeFirstLetter(this.data.action);
    if (this.data.manufacturer) {
      this.manufacturer = Object.assign({}, this.data.manufacturer);
      this.originalManufacturerName = this.manufacturer.name;
    }
  }

  async processForm() {
    this.loading = true;
    if (this.manufacturer.name?.trim()) {
      if (this.originalManufacturerName == this.manufacturer.name && this.action !== "Delete") {
        this.notifyService.notify("Manufacturer has not been changed");
        this.loading = false;
        return;
      }
      if (this.manufacturer.name?.trim()) {
        let duplicate = false;
        if (this.action !== "Delete") {
          for (const manufacturer of this.data.manufacturers) {
            if (this.manufacturer.name === manufacturer.name) {
              duplicate = true;
            }
          }
        }
        if (!duplicate || this.action == "Delete") {
          this.dialogRef.close(this.manufacturer);
        } else {
          this.notifyService.notify("The manufacturer already exists");
        }
      } else {
        this.notifyService.notify("Manufacturer is required");
      }
    } else {
      this.notifyService.notify("Manufacturer is required");
    }
    this.loading = false;
  }
}
