import { Component, Input } from "@angular/core";
import { Payload } from "src/app/services/utilities/utility.service";

@Component({
  selector: "app-dashboard-card-header",
  templateUrl: "./dashboard-card-header.component.html",
  styleUrls: ["./dashboard-card-header.component.scss"],
})
export class DashboardCardHeaderComponent {
  @Input() payload: Payload;
  @Input() title: string;
  @Input() loading = true;
}
