<h2 mat-dialog-title>Testing Mailbox Connectivity</h2>
<div mat-dialog-content>
  <!--app-warnings
		*ngIf="!!failed"
		message="Domain wide delegation permissions usually apply within minutes of creation. However, depending on the size of your organization, this process can take up to 24 hours."
	></app-warnings-->
  <table class="table table-borderless text-left">
    <tr *ngFor="let test of tests">
      <td>
        <span *ngIf="test.state == 'PENDING'">
          <span class="material-icons text-muted mb-0"> pending </span>
        </span>
        <span *ngIf="test.state == 'VERIFIED'">
          <span class="material-icons text-success mb-0"> done_outline </span>
        </span>
        <span *ngIf="test.state == 'ERRORED'">
          <span class="material-icons text-danger mb-0"> error_outline </span>
        </span>
        <span *ngIf="test.state == 'CHECKING'">
          <span class="material-icons faa-spin"> filter_tilt_shift </span>
        </span>
      </td>
      <td>
        <span>
          {{ test.name }}
        </span>
      </td>
    </tr>
  </table>
  <div mat-dialog-actions>
    <button class="blue-border" mat-stroked-button mat-dialog-close>
      <span *ngIf="loading">Cancel</span><span *ngIf="!loading">Close</span>
    </button>
    <button *ngIf="failed" mat-stroked-button class="primary" (click)="retry()">Retry</button>
  </div>
</div>
