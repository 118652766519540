import { Component, Input } from "@angular/core";
import { toggleMark } from "prosemirror-commands";
import { EditorState, Transaction } from "prosemirror-state";
import { Editor } from "ngx-editor";
import { SignatureService, Font } from "src/app/services/admin-plus/signature.service";

@Component({
  selector: "app-custom-menu",
  templateUrl: "./custom-menu.component.html",
  styleUrls: ["./custom-menu.component.scss"],
})
export class CustomMenuComponent {
  @Input() editor: Editor;
  selectedFont: Font;

  constructor(public signatureService: SignatureService) {
    this.selectedFont = this.signatureService.fonts[0];
  }

  onChange(font: Font): void {
    this.selectedFont = font;
    const { state, dispatch } = this.editor.view;
    this.execute(state, dispatch);
  }

  execute(state: EditorState, dispatch?: (tr: Transaction) => void): boolean {
    const { schema } = state;
    const result = toggleMark(schema.marks.font_family, { "font-family": this.selectedFont.css })(state, dispatch);
    return result;
  }
}
