import { Component, OnInit, Input } from "@angular/core";
import { ChartType } from "angular-google-charts";

@Component({
  selector: "app-storage-report-pie-chart",
  templateUrl: "./storage-report-pie-chart.component.html",
  styleUrls: ["./storage-report-pie-chart.component.scss"],
})
export class StorageReportPieChartComponent implements OnInit {
  @Input() storageByType: any[] = [];
  type: ChartType = ChartType.PieChart;
  options = {
    chartArea: { width: "90%", height: "90%" },
    legend: "none",
    colors: ["#114c71", "#FFAA01", "#008000", "#FF0000"],
  };
  data = [
    ["User Drives", 0],
    ["Gmail", 0],
    ["Photos", 0],
    ["Shared Drives", 0],
  ];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.data = this.storageByType;
  }
}
