import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { Memberships, Membership, Groups } from "../../groups/groups";
import { AuthService } from "src/app/services/admin-plus/auth.service";
import { User } from "src/app/web/login/user";

@Injectable({
  providedIn: "root",
})
export class GroupsService {
  private api_url = "https://cloudidentity.googleapis.com/v1/groups";
  private user: User;
  public selected_group;
  public groups: Group[] = [];

  constructor(private http: HttpClient, private loginService: LoginService, private authService: AuthService) {}

  async buildGroups() {
    let nextPageToken = "";
    while (nextPageToken || nextPageToken == "") {
      const groups = await this.getGroups(100, nextPageToken);
      nextPageToken = groups.nextPageToken;
      this.groups = this.groups.concat(groups.groups);
    }
  }

  async getGroups(maxResults = 25, pageToken = "", query = ""): Promise<Groups> {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Groups>((resolve) => {
      this.getGroupsData(maxResults, pageToken, query).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getGroupsData(maxResults = 25, pageToken = "", query = ""): Observable<Groups> {
    const search = `name:${query.replace(/'/g, "\\'").replace(/\s/g, "*")}*`;
    return this.http
      .get<Groups>("https://admin.googleapis.com/admin/directory/v1/groups", {
        headers: new HttpHeaders()
          .set("Authorization", "Bearer " + this.user.authToken)
          .set("Content-Type", "application/json"),
        params: new HttpParams()
          .set("customer", this.user.customerID)
          .set("maxResults", maxResults.toString())
          .set("pageToken", pageToken)
          .set(query.length ? "query" : "", query.length ? search : "*"),
      })
      .pipe(catchError(this.authService.handleAPIError<Groups>("getGroupsData")));
  }

  async searchGroups(maxResults = 25, pageToken = "", query = ""): Promise<Groups> {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Groups>((resolve) => {
      this.searchGroupsData(maxResults, pageToken, query).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private searchGroupsData(maxResults = 200, pageToken = "", query = ""): Observable<Groups> {
    let params = {};
    if (query.length) {
      const search = `email:${query}*`;
      params = new HttpParams()
        .set("customer", this.user.customerID)
        .set("maxResults", maxResults.toString())
        .set("pageToken", pageToken)
        .set("query", search);
    } else {
      params = new HttpParams()
        .set("customer", this.user.customerID)
        .set("maxResults", maxResults.toString())
        .set("pageToken", pageToken);
    }
    return this.http
      .get<Groups>("https://admin.googleapis.com/admin/directory/v1/groups", {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
        params: params,
      })
      .pipe(catchError(this.authService.handleAPIError<Groups>("searchGroupsData")));
  }

  async searchTransitiveMemberships(groupId): Promise<Memberships> {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Memberships>((resolve) => {
      this.searchTransitiveMembershipsData(groupId).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private searchTransitiveMembershipsData(groupId): Observable<Memberships> {
    return this.http
      .get<Memberships>(this.api_url + "/" + groupId + "/memberships:searchTransitiveMemberships", {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Memberships>("searchTransitiveMembershipsData", true)));
  }

  async getGroup(id: string): Promise<Group> {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Group>((resolve) => {
      this.getGroupData(id).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getGroupData(id: string): Observable<Group> {
    return this.http
      .get<Group>(this.api_url + "/" + id, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Group>("getGroupData")));
  }

  async addGroup(newGroup: Group): Promise<Group> {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Group>((resolve) => {
      this.addGroupData(newGroup).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private addGroupData(newGroup: Group): Observable<Group> {
    return this.http
      .post<Group>(this.api_url + "", JSON.stringify(newGroup), {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
        params: new HttpParams().set("initialGroupConfig", "WITH_INITIAL_OWNER"),
      })
      .pipe(catchError(this.authService.handleAPIError<Group>("addGroupData")));
  }

  async editGroup(editGroup: Group): Promise<Group> {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Group>((resolve) => {
      this.editGroupData(editGroup).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private editGroupData(editGroup: Group): Observable<Group> {
    return this.http
      .patch<Group>("https://cloudidentity.googleapis.com/v1/" + editGroup.name, JSON.stringify(editGroup), {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
        params: new HttpParams().set("updateMask", "displayName,description"),
      })
      .pipe(catchError(this.authService.handleAPIError<Group>("editGroupData")));
  }

  async deleteGroup(id: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise((resolve) => {
      this.deleteGroupData(id).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private deleteGroupData(id: string) {
    return this.http
      .delete(this.api_url + "/" + id, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError("deleteGroupData")));
  }

  async getMembers(id: string, maxResults: number, pageToken: string): Promise<Memberships> {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Memberships>((resolve) => {
      this.getMembersData(id, maxResults, pageToken).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getMembersData(id: string, maxResults: number, pageToken: string): Observable<Memberships> {
    return this.http
      .get<Memberships>(this.api_url + "/" + id + "/memberships", {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
        params: new HttpParams().set("view", "FULL").set("pageSize", maxResults.toString()).set("pageToken", pageToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Memberships>("getMembersData", true)));
  }

  async getMember(groupID: string, userID: string = this.user.googleID): Promise<Membership> {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Membership>((resolve) => {
      this.getMemberData(groupID, userID).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getMemberData(groupID: string, userID: string = this.user.googleID): Observable<Membership> {
    return this.http
      .get<Membership>(this.api_url + "/" + groupID + "/memberships/" + userID, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Membership>("getMemberData", true)));
  }

  async addMember(groupID: string, newMember): Promise<Membership> {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Membership>((resolve) => {
      this.addMemberData(groupID, newMember).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private addMemberData(groupID: string, newMember): Observable<Membership> {
    return this.http
      .post<Membership>(this.api_url + "/" + groupID + "/memberships", JSON.stringify(newMember), {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Membership>("addMemberData")));
  }

  async deleteMember(member) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise((resolve) => {
      this.deleteMemberData(member).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private deleteMemberData(member) {
    return this.http
      .delete("https://cloudidentity.googleapis.com/v1/" + member.name, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError("deleteMemberData")));
  }
}

export interface Group {
  id: string;
  email: string;
  name: string;
  displayName?: string;
  description?: string;
  adminCreated?: boolean;
  directMembersCount: number;
  kind?: string;
  etag?: string;
  aliases?: [string];
  nonEditableAliases?: [string];
}
