<app-page-header [options]="options" (optionSelected)="optionSelected($event)"></app-page-header>

<div class="row">
  <div class="col-12 col-lg-4 col-xl-3 mt-2">
    <mat-form-field class="w-100">
      <mat-label>Actions</mat-label>
      <mat-select style="min-height: 32px" [formControl]="actions">
        <mat-option value="" selected></mat-option>
        <mat-option value="delete" [disabled]="selection.selected.length == 0"> Delete </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-12 col-lg-4 col-xl-6 mt-2">
    <app-filters [filterType]="filterTypeValue" [selectedOu]="selectedOU" (dataEvent)="receiveData($event)"></app-filters>
  </div>
  <div class="col-12 col-lg-4 col-xl-3 mt-2">
    <mat-form-field class="w-100">
      <mat-label>Columns</mat-label>
      <mat-select style="min-height: 32px" [formControl]="columns" multiple>
        <mat-option *ngFor="let column of columnsList" [value]="column" (click)="tableColumnChange(column)">
          {{ column == "orgUnitPath" ? "Organizational Unit" : column == "lastLoginTime" ? "Last Login" : column }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              color="primary"
              class="mat-checkbox"
              #checkboxesColumn
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && getPageData()"
              [indeterminate]="selection.hasValue() && !getPageData()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              color="primary"
              class="mat-checkbox"
              #checkboxes
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="profilePicture">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell (click)="routeToUser(row.id)" *matCellDef="let row">
            <img
              referrerpolicy="no-referrer"
              matChipAvatar
              width="25"
              height="25"
              src="{{
                !(row.primaryEmail | getProfilePicturePipe | async)
                  ? 'https://lh3.googleusercontent.com/a/default-user=s64'
                  : (row.primaryEmail | getProfilePicturePipe | async)
              }}"
              alt="Avatar"
            />
          </td>
        </ng-container>
        <ng-container matColumnDef="givenName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Given Name</th>
          <td mat-cell (click)="routeToUser(row.id)" *matCellDef="let row">
            {{ row.name.givenName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="familyName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Family Name</th>
          <td mat-cell (click)="routeToUser(row.id)" *matCellDef="let row">
            {{ row.name.familyName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Email</th>
          <td mat-cell (click)="routeToUser(row.id)" *matCellDef="let row">
            {{ row.primaryEmail }}
          </td>
        </ng-container>
        <ng-container matColumnDef="lastLoginTime">
          <th mat-header-cell *matHeaderCellDef>Last Login</th>
          <td mat-cell (click)="routeToUser(row.id)" *matCellDef="let row">
            <span *ngIf="row.lastLoginTime == '1970-01-01T00:00:00.000Z'"> Never </span>
            <span *ngIf="row.lastLoginTime !== '1970-01-01T00:00:00.000Z'">
              {{ row.lastLoginTime | date : "short" }}
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="orgUnitPath">
          <th mat-header-cell *matHeaderCellDef>Organizational Unit</th>
          <td mat-cell (click)="routeToUser(row.id)" *matCellDef="let row">{{ row.orgUnitPath }}</td>
        </ng-container>
        <tr class="mat-row" app-empty-dataset-alert [loading]="loading" [filterCount]="data.filtersCount" *matNoDataRow></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row class="clickable-row" *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <app-table-footer
      [dataSource]="dataSource"
      [maxResults]="maxResults"
      [nextButton]="nextButton"
      [nextPageToken]="nextPageToken"
      [prevPageToken]="prevPageToken"
      [offset]="offset"
      [page]="page"
      (pager)="setPager($event)"
      [disabled]="isFetchingData"
    ></app-table-footer>
  </div>
</div>
