import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { AuthService } from "src/app/services/admin-plus/auth.service";
import { User } from "src/app/web/login/user";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class LicensesService {
  private user: User;
  private apiUrl: string = environment.apiUrl + "/licenses";
  public licenses: Product[] = [];
  constructor(private http: HttpClient, private loginService: LoginService, private authService: AuthService) {
    this.getLicenses("");
  }

  public async getProduct(productId: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Product>((resolve) => {
      this.getProductRequest(productId).subscribe((product) => {
        if (product) {
          resolve(product);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getProductRequest(productId: string): Observable<Product> {
    return this.http
      .get<Product>(this.apiUrl + "/product", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams().set("productId", productId),
      })
      .pipe(catchError(this.authService.handleAPIError<Product>("getProductRequest")));
  }

  public async getLicenses(search: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Product[]>((resolve) => {
      this.getLicensesRequest(search).subscribe((licenses) => {
        if (licenses) {
          this.licenses = [];
          this.licenses.push(...licenses);
          resolve(licenses);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getLicensesRequest(search: string): Observable<Product[]> {
    return this.http
      .get<Product[]>(this.apiUrl + "/licenses", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams().set("search", search),
      })
      .pipe(catchError(this.authService.handleAPIError<Product[]>("getProductsRequest")));
  }

  public async getSKU(skuId: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<SKU>((resolve) => {
      this.getSKURequest(skuId).subscribe((sku) => {
        if (sku) {
          resolve(sku);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getSKURequest(skuId: string): Observable<SKU> {
    return this.http
      .get<SKU>(this.apiUrl + "/sku", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams().set("skuId", skuId),
      })
      .pipe(catchError(this.authService.handleAPIError<SKU>("getSKURequest")));
  }
}

export interface Product {
  id: number;
  created: string;
  productId: string;
  productName: string;
  SKUs: SKU[];
}

export interface SKU {
  id: number;
  created: string;
  productId: string;
  skuId: string;
  skuName: string;
  visible?: boolean;
}
