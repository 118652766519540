<mat-form-field class="w-100">
  <mat-label> {{ fieldLabel }} </mat-label>
  <mat-chip-grid #itemsList>
    <mat-chip-row
      *ngFor="let email of valueList()"
      [removable]="removable"
      (removed)="automationService.dirty(); remove(email)"
    >
      <img
        referrerpolicy="no-referrer"
        matChipAvatar
        src="{{
          !(email | getProfilePicturePipe | async)
            ? 'https://lh3.googleusercontent.com/a/default-user=s64'
            : (email | getProfilePicturePipe | async)
        }}"
        alt="Avatar"
      />
      {{ email }}
      <mat-icon [selectable]="selectable" matChipRemove *ngIf="!disabled && !hideRemove">cancel</mat-icon>
    </mat-chip-row>

    <input
      #emailsInput
      name="email"
      [formControl]="emailsCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="itemsList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
      matInput
      required
      email
      [readonly]="disabled"
      (blur)="convertToChip($event)"
    />
  </mat-chip-grid>

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngIf="defaultOption" [value]="defaultOption">{{ defaultOption }}</mat-option>
    <mat-option *ngIf="defaultOption && loading" [value]="defaultOption">Searching for {{ searchType }}...</mat-option>
    <mat-option *ngFor="let item of emails" [value]="item.email">
      {{ item.name }}
      <small class="text-muted ml-2">{{ item.email }}</small>
    </mat-option>
  </mat-autocomplete>
  <mat-hint *ngIf="loading" class="d-flex align-items-center">
    <mat-icon class="faa-spin mx-1"> filter_tilt_shift </mat-icon>
    Searching for {{ searchType }}...
  </mat-hint>
  <mat-hint class="text-danger" *ngIf="error && !loading">
    <div class="overflow-mat-error">
      Unable to auto-suggest emails. Possible configuration issue with your
      <a (click)="clearError()" target="_blank" href="/settings/product/service-account">service account</a>
    </div>
  </mat-hint>
</mat-form-field>
