<mat-accordion>
  <mat-expansion-panel
    [expanded]="panelOpenState"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    [ngClass]="{ 'h-100': panelOpenState }"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h4>{{ title }}</h4>
      </mat-panel-title>
      <mat-panel-description *ngIf="payload?.count > 1"> Total: {{ payload.count }} </mat-panel-description>
    </mat-expansion-panel-header>
    <app-dashboard-card-header [payload]="payload" [title]="title" [loading]="loading"></app-dashboard-card-header>
    <app-device-info *ngIf="payload && type == 'device'" [loading]="loading" [device]="payload"></app-device-info>
    <app-recent-users *ngIf="payload && type == 'recentUsers'" [loading]="loading" [payload]="payload"></app-recent-users>
    <app-chrome-os-device-logs
      *ngIf="payload && type == 'chromeOsDeviceLogs'"
      [loading]="loading"
      [payload]="payload"
    ></app-chrome-os-device-logs>
    <app-ticket-list
      *ngIf="type == 'tickets'"
      [filters]="payload"
      [title]="title"
      [hideSeeAll]="hideSeeAll"
    ></app-ticket-list>
    <app-agent-list *ngIf="type == 'agents'" [filters]="payload" [title]="title"></app-agent-list>
    <app-notes *ngIf="type == 'html' && payload" [payload]="payload"></app-notes>
  </mat-expansion-panel>
</mat-accordion>
