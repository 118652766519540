<div [class.mobile]="mobileQuery.matches" [ngClass]="isSideMenuOpen ? 'closed' : 'open'" class="app-container">
  <app-nav (sideNavToggle)="toggleSideMenu()" class="app-nav"></app-nav>
    <div
      [ngClass]="isSideMenuOpen ? 'closed' : 'open'"
      [class.mobile]="mobileQuery.matches" 
      @fadeInOut 
      *ngIf="loginService.isLoggedIn() && loginService.showLeftNavBreadcrumb"
      class="side-nav"
      (toggle)="toggleSideMenu()"
      [class.closed]="isSideMenuOpen">
      <mat-nav-list
        *ngIf="loginService.isLoggedIn() && loginService.showLeftNavBreadcrumb"
        [ngClass]="isSideMenuOpen ? 'closed' : ''" 
        class="side-nav__links h-100 pt-2">
        <app-leftnav></app-leftnav>
      </mat-nav-list>
    </div>

    <div class="main-content">
      <div class="fixed-space">
        <app-breadcrumbs *ngIf="loginService.isLoggedIn() && loginService.showLeftNavBreadcrumb"></app-breadcrumbs>
        <router-outlet></router-outlet>
      </div>
    </div>
</div>