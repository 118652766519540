import { Component } from "@angular/core";
import { trigger, style, animate, transition } from "@angular/animations";

@Component({
  selector: "app-free-trial",
  templateUrl: "./free-trial.component.html",
  styleUrls: ["./free-trial.component.scss"],
  animations: [
    trigger("freeTrialLeftAnimation", [
      transition(":enter", [
        style({ transform: "scale(0.8) translateY(-20%)", opacity: 0 }),
        animate("700ms ease-in", style({ transform: "scale(1) translateY(0%)", opacity: 1 })),
      ]),
    ]),

    trigger("freeTrialRightAnimation", [
      transition(":enter", [
        style({ transform: "scale(0.8) translate(0%, -70%)", opacity: 0 }),
        animate("700ms ease-in", style({ transform: "scale(1) translate(0%, -50%)", opacity: 1 })),
      ]),
    ]),
  ],
})
export class FreeTrialComponent {}
