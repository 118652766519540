<h2 mat-dialog-title class="d-flex align-items-center" style="gap: 8px">
  <mat-icon>manage_accounts</mat-icon>
  Service Account Required
</h2>
<div mat-dialog-content>
  <div class="d-flex align-items-center mt-2">
    <span class="px-3">
      A service account is required to enable the full functionality of Admin+. Modules with warnings in the left navigation
      will be disabled until a Service Account is setup.
    </span>
  </div>

  <div mat-dialog-actions class="px-0">
    <button mat-stroked-button class="ml-2" mat-dialog-close>Not Now</button>
    <button mat-stroked-button class="success" (click)="setUpServiceAccount()">Setup Service Account</button>
  </div>
</div>
