import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Observable } from "rxjs";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { AuthService } from "src/app/services/admin-plus/auth.service";
import { User } from "src/app/web/login/user";

@Injectable({
  providedIn: "root",
})
export class DrivesService {
  private user: User;
  private apiUrl = environment.apiUrl + "/drives";

  constructor(private http: HttpClient, private loginService: LoginService, private authService: AuthService) {}

  public async transferOwnership(originalUser, newUser) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<string>((resolve) => {
      this.transferOwnershipData(originalUser, newUser).subscribe((transferOwnership) => {
        if (transferOwnership) {
          resolve(transferOwnership);
        }
      });
    });
  }

  private transferOwnershipData(originalUser, newUser): Observable<string> {
    const body = {
      originalUser: originalUser,
      newUser: newUser,
    };
    return this.http
      .post<string>(this.apiUrl + "/transfer-ownership/transferData", body, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<string>("transferOwnershipData")));
  }

  public async wipeSharedDrive(driveId: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<DriveFile>((resolve) => {
      this.wipeSharedDriveContents(driveId).subscribe((driveFiles) => {
        if (driveFiles) {
          resolve(driveFiles);
        }
      });
    });
  }

  private wipeSharedDriveContents(driveId: string): Observable<DriveFile> {
    return this.http
      .delete<DriveFile>(this.apiUrl + "/wipe-shared-drive/" + driveId, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<DriveFile>("wipeSharedDriveContents")));
  }

  public async resyncDrivesFiles(driveId: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<DriveFile>((resolve) => {
      this.resyncDrivesFilesData(driveId).subscribe((driveFiles) => {
        if (driveFiles) {
          resolve(driveFiles);
        }
      });
    });
  }

  private resyncDrivesFilesData(driveId: string): Observable<DriveFile> {
    return this.http
      .get<DriveFile>(this.apiUrl + "/resync-drives-files/" + driveId, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<DriveFile>("resyncDrivesFilesData")));
  }

  public async resyncDrives(reportId: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Drives>((resolve) => {
      this.resyncDrivesData(reportId).subscribe((drives) => {
        if (drives) {
          resolve(drives);
        }
      });
    });
  }

  private resyncDrivesData(reportId: string): Observable<Drives> {
    return this.http
      .get<Drives>(this.apiUrl + "/resyncDrives/report/" + reportId, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Drives>("resyncDrivesData")));
  }

  public async getDrives(
    usageSize = "",
    sharedDriveSearch = "",
    limit = 10,
    orderBy: string,
    order: string,
    offset = 0,
    getCount = true,
    totalUsers = "",
    externalUsers = "",
    managerSearch = "",
    viewerSearch = "",
    commenterSearch = "",
    contributorSearch = "",
    contentManagerSearch = ""
  ) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<DriveData>((resolve) => {
      this.getDrivesData(
        usageSize,
        sharedDriveSearch,
        limit,
        orderBy,
        order,
        offset,
        getCount,
        totalUsers,
        externalUsers,
        managerSearch,
        viewerSearch,
        commenterSearch,
        contributorSearch,
        contentManagerSearch
      ).subscribe((drives) => {
        if (drives) {
          resolve(drives);
        }
      });
    });
  }

  private getDrivesData(
    usageSize = "",
    sharedDriveSearch = "",
    limit = 10,
    orderBy: string,
    order: string,
    offset = 0,
    getCount = true,
    totalUsers = "",
    externalUsers = "",
    managerSearch = "",
    viewerSearch = "",
    commenterSearch = "",
    contributorSearch = "",
    contentManagerSearch = ""
  ): Observable<DriveData> {
    return this.http
      .get<DriveData>(this.apiUrl + "/report", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams()
          .set("usage", usageSize)
          .set("sharedDrive", sharedDriveSearch)
          .set("limit", limit.toString())
          .set("orderBy", orderBy)
          .set("order", order)
          .set("offset", offset.toString())
          .set("getCount", getCount.toString())
          .set("totalUsers", totalUsers)
          .set("externalUsers", externalUsers)
          .set("managers", managerSearch)
          .set("viewers", viewerSearch)
          .set("commenters", commenterSearch)
          .set("contributors", contributorSearch)
          .set("contentManagers", contentManagerSearch),
      })
      .pipe(catchError(this.authService.handleAPIError<DriveData>("getDrivesData")));
  }

  public async getDrive(driveId: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Drive>((resolve) => {
      this.getDriveData(driveId).subscribe((drive) => {
        if (drive) {
          resolve(drive);
        }
      });
    });
  }

  private getDriveData(driveId: string): Observable<Drive> {
    return this.http
      .get<Drive>(this.apiUrl + "/drive/" + driveId, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Drive>("getDriveData")));
  }

  public async getDriveUsers(
    driveId: string,
    orderBy: string,
    order: string,
    limit = 10,
    offset = 0,
    firstName: string,
    lastName = "",
    email = "",
    ouSearch = "",
    flagged = "all",
    usage = "",
    getCount = true,
    externalEmail = ""
  ) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<DriveUserData>((resolve) => {
      this.getDriveUsersData(
        driveId,
        orderBy,
        order,
        limit,
        offset,
        firstName,
        lastName,
        email,
        ouSearch,
        flagged,
        usage,
        getCount,
        externalEmail
      ).subscribe((driveUsers) => {
        if (driveUsers) {
          resolve(driveUsers);
        }
      });
    });
  }

  private getDriveUsersData(
    driveId: string,
    orderBy: string,
    order: string,
    limit = 10,
    offset = 0,
    firstName: string,
    lastName = "",
    email = "",
    ouSearch = "",
    flagged = "all",
    usage = "",
    getCount = true,
    externalEmail = ""
  ): Observable<DriveUserData> {
    return this.http
      .get<DriveUserData>(this.apiUrl + "/drive-users/" + driveId, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams()
          .set("orderBy", orderBy)
          .set("order", order)
          .set("limit", limit.toString())
          .set("offset", offset.toString())
          .set("firstName", firstName)
          .set("lastName", lastName)
          .set("email", email)
          .set("ouSearch", ouSearch)
          .set("flaggedSearch", flagged)
          .set("usage", usage)
          .set("getCount", getCount.toString())
          .set("externalEmail", externalEmail),
      })
      .pipe(catchError(this.authService.handleAPIError<DriveUserData>("getDriveUsersData")));
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DriveData {}

export interface Drives {
  drive: Drive[];
  driveCount?: number;
}

export interface Drive {
  name: string;
  driveId?: string;
  firstName: string;
  lastName: string;
  totalUsage: number;
  totalUsers: number;
  totalFiles: number;
  isDeleting?: number;
  filesSyncing?: string;
  filesSynced?: string;
  managers: string;
  contentManagers: string;
  viewers: string;
  contributors: string;
  commenters: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DriveUserData {}

export interface DriveUser {
  emailAddress: string;
  type: string;
  role: string;
  displayName: string;
  driveId: string;
  reportId: string;
  driveUserCount?: number;
}

export interface DriveFile {
  name: string;
  storageMb: number;
  driveFileCount?: number;
}

export interface TransferOwnership {
  id: number;
  userId: string;
  reportId: number;
  filesMoving: string;
  filesMoved: string;
}
