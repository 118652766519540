<h2 mat-dialog-title>
	<i style="font-size: 20px" class="faa-shake material-icons material-icon-align mr-2 mb-1">info</i>
	{{ title }}
</h2>
<div mat-dialog-content class="text-justify">
	{{ message }}
	<div mat-dialog-actions class='px-0'>
		<button class="" mat-stroked-button mat-dialog-close>
			{{ btnCancelText }}
		</button>
	</div>
</div>
