import { Component, Input, OnInit, DoCheck } from "@angular/core";
import { AutomationService, WorkflowAction, WorkflowSubAction } from "src/app/services/workflow-plus/automation.service";

@Component({
  selector: "app-sub-action",
  templateUrl: "./sub-action.component.html",
  styleUrls: ["./sub-action.component.scss"],
})
export class SubActionComponent implements DoCheck, OnInit {
  @Input() workflowAction: WorkflowAction;
  private selectedProperties: number[] = [];

  constructor(public automationService: AutomationService) {}
  ngOnInit(): void {
    this.setSelectedProperties();
  }

  ngDoCheck() {
    this.setSelectedProperties();
  }

  selected(subAction) {
    return this.workflowAction.subActions.findIndex((s) => s.subActionId == subAction.id) >= 0;
  }

  setSelectedProperties() {
    this.selectedProperties = this.workflowAction.report.selectedProperties.map((s) => s.id);
    for (const subAction of this.workflowAction.subActions) {
      const subActionIndex = this.workflowAction.subActions.findIndex((s) => s.subActionId == subAction.id);
      if (subActionIndex >= 0) {
        this.workflowAction.subActions.splice(subActionIndex, 1);
      }
    }
  }

  toggleSubAction(subAction) {
    const subActionIndex = this.workflowAction.subActions.findIndex((s) => s.subActionId == subAction.id);
    if (subActionIndex >= 0) {
      this.workflowAction.subActions.splice(subActionIndex, 1);
    } else {
      const sub: WorkflowSubAction = {
        id: null,
        actionId: this.workflowAction.id,
        subActionId: subAction.id,
        value: true,
        action: {
          id: null,
          name: subAction.name,
          icon: subAction.icon,
          description: subAction.description,
          properties: null,
          parameters: null,
        },
      };
      this.workflowAction.subActions.push(sub);
    }
  }
}
