<h2 mat-dialog-title>
	<i
		style="font-size: 20px"
		class="faa-shake material-icons material-icon-align mr-2"
		>warning</i
	>
	Are you sure you want to remove the following users?
</h2>
<div mat-dialog-content>
	<ul>
		<li *ngFor="let user of users">
			{{ user.primaryEmail }}
		</li>
	</ul>
	<div mat-dialog-actions class='px-0'>
		<button mat-dialog-close mat-stroked-button>Cancel</button>
		<button class="danger" mat-stroked-button (click)="delete()">Confirm</button>
	</div>
</div>
