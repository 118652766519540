<mat-form-field class="w-100">
  <mat-label>File</mat-label>
  <mat-chip-grid #itemList>
    <mat-chip-row *ngFor="let file of valueList()" [selectable]="true" [removable]="true" (removed)="remove(file)">
      {{ file }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-row>
    <input
      placeholder="Enter File Id ..."
      #itemInput
      maxlength="5000"
      [formControl]="itemCtrl"
      [matChipInputFor]="itemList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      matInput
      required
      (blur)="convertToChip($event)"
      (keyup.enter)="convertToChip($event)"
    />
    <mat-hint *ngIf="spinner">
      <span class="icon-spinner mr-1 mat-icon material-icons mb-1"> filter_tilt_shift </span>
      <span class="mr-3">Checking read access for the selected file</span>
    </mat-hint>
  </mat-chip-grid>
  <span class="pr-3" matSuffix>
    <span style="font-size: 13px; white-space: nowrap; cursor: pointer" (click)="openFilePickerDialog()">
      <i style="font-size: 16px" class="material-icons">create</i>
    </span>
  </span>
</mat-form-field>
<div
  *ngIf="checkReadAccess === 'false' && (!this.parameterProperty || (this.parameterProperty && !this.parameterProperty.id))"
  style="font-size: 12px"
  class="alert alert-danger"
>
  In order to perform this action, your service account delegate user {{ checkAccess.delegateUser }} needs read access to
  this file.
</div>
<div
  *ngIf="loadAlertInfoMessage && parameterProperty && parameterProperty.id"
  style="font-size: 12px"
  class="alert alert-info"
>
  In order to perform this action, your service account delegate user {{ checkAccess.delegateUser }} needs read access to
  this file.
</div>
