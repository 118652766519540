export enum Module {
  // KEEP IN SYNC WITH /var/www/api.kincaidit.com/app/helpers/enums.js
  DASHBOARD = 1,
  USERS = 2,
  GROUPS = 3,
  ACCOUNT = 4,
  CLASSROOM = 5,
  ANALYTICS = 6,
  REPORTS = 7,
  ORGANIZATIONAL_UNITS = 8,
  BUILDINGS = 9,
  AUTOMATION = 10,
  ASSETS = 11,
  TICKETS = 13,
  SETTINGS = 14,
}
