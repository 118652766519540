import { Component, Input } from "@angular/core";
import { Payload } from "src/app/services/utilities/utility.service";

@Component({
  selector: "app-dashboard-card",
  templateUrl: "./dashboard-card.component.html",
  styleUrls: ["./dashboard-card.component.scss"],
})
export class DashboardCardComponent {
  @Input() title: string;
  @Input() type: string;
  @Input() payload: Payload;
  @Input() loading = true;
  @Input() hideSeeAll = false;
  panelOpenState = true;
}
