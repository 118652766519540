import { Injectable } from "@angular/core";
import { UtilityService, Payload } from "../utilities/utility.service";

@Injectable({
  providedIn: "root",
})
export class RoomService {
  constructor(public utilityService: UtilityService) {}

  async getRooms(params?) {
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("GET", `/devices/rooms`, params, null).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }
}
