<div class="title my-auto my-md-0 position-relative px-4">
  <div class="d-inline-flex align-items-center">
    <img class="logo" src="/assets/images/elementor-logo-top.webp" />
  </div>

  <div class="mt-2 positon-relative">
    <div class="d-inline-block">
      <h6 class="text-white ft-subtitle">The most comprehensive toolset to manage your Google Workspace environment.</h6>
    </div>

    <div class="d-flex align-items-center icon-wrapper mt-md-2">
      <img class="icon-logo" src="/assets/images/elementor-logo-a.webp" />
      <h5 class="text-white ft-subtitle ft-subtitle--sm ml-3 pt-1">
        Elevate your Google Workspace environment with automation.
      </h5>
    </div>

    <div class="d-flex flex-column mt-md-4 pt-4">
      <h6 class="sign-up-w-google text-white">
        Get started with a no-cost trial by signing into your Google account with a Super Admin account
      </h6>
      <a class="d-inline-block mt-2" routerLink="/free-trial/sign-up">
        <img class="" src="https://ap-assets.trafera.app/images/btn_google_signin_light_normal_web@2x.png" width="200px" />
      </a>
    </div>
  </div>
</div>
