<nav role="navigation" id="navigation" class="leftnav bs-white w-100">
  <div class="list__top-links">
    <app-link [class.leftnav__link] class="leftnav__link" *ngFor="let link of topLinks" [link]="link"></app-link>
  </div>

  <mat-divider class="m-3"></mat-divider>

  <div class="list__bottom-links">
    <app-link *ngFor="let link of bottomLinks" [link]="link"></app-link>
  </div>

  <div class="left-nav-footer">
    <mat-divider class="m-3"></mat-divider>
    <small class="my-1 text-center">&copy;&nbsp;Copyright&nbsp;{{ currentYear }}&nbsp; Trafera</small>
    <div class="d-block text-center">
      <a class="small" href="https://admin-plus.trafera.app/tos" target="_blank">Terms of Use</a>
      <span class="small mx-1">|</span>
      <a class="small" href="https://www.trafera.com/trafera-privacy-policy" target="_blank">Privacy Policy</a>
    </div>

    <div class="d-block text-center">
      <a class="small my-1" [routerLink]="'/eua'">End-User Agreement </a> <span class="small mx-1">|</span>
      <a class="small my-1" href="https://trafera.statuspage.io/" target="_blank">Status</a>
    </div>
  </div>
</nav>
