import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DriveUser } from "src/app/services/storage-plus/drives.service";
import { MatTableDataSource } from "@angular/material/table";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { SelectionModel } from "@angular/cdk/collections";
import { DrivesService } from "src/app/services/storage-plus/drives.service";
import { StorageReport } from "src/app/services/google/reports.service";
import { MatSort } from "@angular/material/sort";
import { UntypedFormControl } from "@angular/forms";
import { merge, of as observableOf } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { Sort } from "@angular/material/sort";
import { ExportsheetsService } from "src/app/services/google/sheets.service";
import { Filter, FiltersService, FilterType } from "src/app/services/utilities/filters.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-storage-report-drive-users",
  templateUrl: "./storage-report-drive-users.component.html",
  styleUrls: ["./storage-report-drive-users.component.scss"],
})
export class StorageReportDriveUsersComponent implements OnInit {
  @Input() public storageReport: StorageReport = {
    status: {
      name: "",
      id: 4,
    },
    totalUsers: 0,
    totalStorage: 0,
    totalDrive: 0,
    totalGmail: 0,
    totalPhotos: 0,
    allowDelete: false,
  };

  public dataSource = new MatTableDataSource<DriveUser>();
  public maxResultsActions = new UntypedFormControl();
  public actions = new UntypedFormControl();
  public userCtrl = new UntypedFormControl();
  public selection = new SelectionModel(true, []);

  public filteredOptions: Observable<Filter[]>;

  public displayedColumns: string[] = ["emailAddress", "displayName", "type", "role"];
  public search = "";
  public searchUser = "";
  public direction = "desc";
  public activeOrderBy = "emailAddress";
  public driveId = "";

  public maxResults = 10;
  private getCount = true;
  public totalDriveUserCount = 0;
  public page = 1;
  public offset = 0;

  public driveUsers: DriveUser[] = [];

  public nextButtonDis = false;
  public nextButton = false;
  public loading = false;

  /* filtered search stuff */
  private usageSizeSearch = "0";
  private firstNameSearch = "";
  private lastNameSearch = "";
  private emailSearch = "";
  private ouSearch = "";
  private flaggedSearch = "all";

  public data = {
    usageSizeSearch: this.usageSizeSearch,
    firstNameSearch: this.firstNameSearch,
    lastNameSearch: this.lastNameSearch,
    emailSearch: this.emailSearch,
    flaggedSearch: this.flaggedSearch,
    ouSearch: this.ouSearch,
    page: this.page,
    offset: this.offset,
    filtersCount: 0,
  };

  // Filter code
  public selectedFilters: Filter[] = [];

  public filterTypeValue: FilterType = FilterType.SHARED_DRIVE_USER;

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private navService: NavService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private drivesService: DrivesService,
    private filtersService: FiltersService,
    private exportSheetsService: ExportsheetsService
  ) {}

  async ngOnInit() {
    const hasNavFilters = await this.filtersService.checkNavFilters(this.filterTypeValue);
    if (!hasNavFilters) this.loadData();
  }

  async driveUsersSpreadsheet() {
    this.navService.loading.next(true);
    const spreadsheetID = await this.exportSheetsService.createSpreadsheet("Drive Users");
    const driveUsers = await this.sendDriveUsersEvent();
    await this.addDriveUserData(spreadsheetID, driveUsers);
    window.open("https://docs.google.com/spreadsheets/d/" + spreadsheetID);
    this.navService.loading.next(false);
  }

  public async sendDriveUsersEvent() {
    const driveUsers = [];
    let response = {};
    let offset = 0;
    do {
      response = await this.drivesService.getDriveUsers(
        this.driveId,
        this.activeOrderBy,
        this.direction,
        100,
        offset,
        this.firstNameSearch,
        this.lastNameSearch,
        this.emailSearch,
        this.ouSearch,
        this.flaggedSearch,
        this.usageSizeSearch,
        this.getCount
      );
      offset = offset + 100;
      driveUsers.push(...response["data"]);
    } while (response["data"].length > 0);
    return driveUsers;
  }

  async addDriveUserData(spreadsheetID: string, driveUsers) {
    for (let i = 0; i < driveUsers.length; i++) {
      driveUsers[i]["role"] = driveUsers[i]["role"].charAt(0).toUpperCase() + driveUsers[i]["role"].slice(1);
    }
    const driveUsersObject = await this.exportSheetsService.createSpreadsheetArray(this.displayedColumns, driveUsers);
    if (driveUsersObject) {
      await this.exportSheetsService.addExportData(
        driveUsersObject,
        spreadsheetID,
        "Sheet1!A1:G" + driveUsersObject["values"].length
      );
    }
  }

  receiveData($event) {
    this.data = $event;
    this.firstNameSearch = this.data.firstNameSearch;
    this.lastNameSearch = this.data.lastNameSearch;
    this.emailSearch = this.data.emailSearch;
    this.ouSearch = this.data.ouSearch;
    this.usageSizeSearch = this.data.usageSizeSearch;
    this.flaggedSearch = this.data.flaggedSearch;
    this.page = this.data.page;
    this.offset = this.data.offset;
    this.loadData();
  }

  async loadData() {
    this.navService.loading.next(true);
    this.driveId = this.activatedRoute.snapshot.paramMap.get("driveId") || "";
    if (this.driveId) {
      await this.getDriveUsers();
    }
    this.navService.loading.next(false);
  }

  private async getDriveUsers(arrow = "") {
    this.loading = true;
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.navService.loading.next(true);
          this.nextButton = true;
          this.getCount = !arrow;
          return this.drivesService.getDriveUsers(
            this.driveId,
            this.activeOrderBy,
            this.direction,
            this.maxResults,
            this.offset,
            this.firstNameSearch,
            this.lastNameSearch,
            this.emailSearch,
            this.ouSearch,
            this.flaggedSearch,
            this.usageSizeSearch,
            this.getCount
          );
        }),
        map((driveUsers) => {
          this.loading = false;
          if (driveUsers) {
            this.driveUsers = driveUsers["data"];
            this.dataSource = new MatTableDataSource(this.driveUsers);
            //pagination
            this.totalDriveUserCount = this.getCount
              ? driveUsers["count"]
                ? driveUsers["count"]
                : 0
              : this.totalDriveUserCount;
            if (this.dataSource["filteredData"].length < 10) {
              this.nextButtonDis = true;
            }
            if (this.dataSource["filteredData"].length > 10) {
              this.nextButtonDis = false;
            }
            if (arrow === "next" && this.dataSource["filteredData"].length + this.offset === this.totalDriveUserCount) {
              this.nextButtonDis = true;
            }
            if (arrow === "next" && this.dataSource["filteredData"].length + this.offset < this.totalDriveUserCount) {
              this.nextButtonDis = false;
            }
            if (this.dataSource["filteredData"].length + this.offset < this.totalDriveUserCount) {
              this.nextButtonDis = false;
            }
            if (this.dataSource["filteredData"].length + this.offset === this.totalDriveUserCount) {
              this.nextButtonDis = true;
            }
            if (arrow === "prev" && this.totalDriveUserCount > 0) {
              this.nextButtonDis = false;
            }
            if (this.totalDriveUserCount === this.maxResults) {
              this.nextButtonDis = true;
            }
          }
          this.nextButton = false;
          this.navService.loading.next(false);
          return this.driveUsers;
        }),
        catchError(() => {
          this.loading = false;
          return observableOf([]);
        })
      )
      .subscribe((drives) => {
        if (drives) {
          return drives;
        }
      });
  }

  maxResultChange(value: number) {
    this.page = 1;
    this.offset = 0;
    this.maxResults = parseInt(value + "");
    this.getDriveUsers("max");
  }

  resetToFirstPage() {
    this.page = 1;
    this.offset = 0;
    this.getDriveUsers();
  }

  public setPager(data) {
    this.page = data.page;
    this.offset = data.offset;
    this.maxResults = data.maxResults ? data.maxResults : this.maxResults;
    this.getDriveUsers(data.arrow);
  }

  goToUser(userId: string) {
    if (parseInt(userId) !== 0) {
      this.router.navigate(["reports/storage/users/" + userId]);
    }
  }

  getPageData() {
    return this.dataSource._pageData(this.dataSource._orderData(this.dataSource.filteredData));
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  sortData(sort: Sort) {
    this.activeOrderBy = sort.active;
    if (sort.direction === "") {
      this.direction = "asc";
    } else {
      this.direction = sort.direction;
    }
    this.getDriveUsers();
    this.resetToFirstPage();
  }

  masterToggle() {
    this.isEntirePageSelected()
      ? this.selection.deselect(...this.getPageData())
      : this.selection.select(...this.getPageData());
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row): string {
    if (!row) {
      return `${this.getPageData() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1}`;
  }
}
