import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { GroupsService } from "src/app/services/admin-plus/groups.service";
import { NavService } from "src/app/services/admin-plus/nav.service";

@Component({
  selector: "app-delete-dialog",
  templateUrl: "./delete-dialog.component.html",
  styleUrls: ["./delete-dialog.component.scss"],
})
export class DeleteDialogComponent implements OnInit {
  public members: [];
  public members_that_are_deleted: string[] = [];
  private members_to_delete = 0;
  private members_attempted_to_delete = 0;
  private members_deleted = [];
  private members_failed_to_delete: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: NotificationService,
    private dialogRef: MatDialogRef<DeleteDialogComponent>,
    private groupsService: GroupsService,
    private navService: NavService
  ) {}

  ngOnInit(): void {
    this.members = this.data.members;
    this.members_to_delete = this.data.members.length;
  }

  delete(): void {
    this.navService.loading.next(true);
    for (var i = 0; i < this.members.length; i++) {
      this.deleteMember(this.members[i]);
    }
  }

  private async deleteMember(member: any) {
    var response = await this.groupsService.deleteMember(member);
    if (response) {
      this.members_that_are_deleted.push(member.name);
      this.members_attempted_to_delete++;
      if (response) {
        this.members_deleted.push(response);
      } else {
        this.members_failed_to_delete.push(response["displayName"]);
      }

      if (this.members_to_delete == this.members_attempted_to_delete) {
        //Finished
        if (this.members_to_delete == this.members_deleted.length) {
          this.notificationService.notify(this.members_deleted.length + " group members removed");
        } else {
          this.notificationService.notify(response["error"]["message"]);
        }
        this.navService.loading.next(false);
        this.dialogRef.close(this.members_that_are_deleted);
      }
    }
  }
}
