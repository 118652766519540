<mat-form-field class="w-100">
	<mat-label *ngIf="!hideLabel"> {{ label }} </mat-label>
	<input
		matInput
		[ngModel]="path"
		(ngModelChange)="onChange($event)"
		type="text"
		[placeholder]="placeholder"
		autocomplete="off"
		[disabled]="isDisabled"
		[required]="isRequired"
	/>
	<span matSuffix *ngIf="!isDisabled">
		<button mat-icon-button [disabled]="loading" type="button" class="primary" (click)="openChangeOUDialog()">
			<i *ngIf="!hideDialogControl" style="font-size: 16px" class="material-icons">create</i>
		</button>
	</span>
	<mat-hint
		*ngIf="showHint"
		class="icon-button"
		(click)="helpDialogService.openHelpDialogService(hintTitle, hintDescription, false)"
	>
		<mat-icon matSuffix>help_outline</mat-icon>
	</mat-hint>
</mat-form-field>
