<form class="py-4" (ngSubmit)="processForm()" autocomplete="off">
  <div class="d-inline-flex">
    <h3 class="mb-2">Automation</h3>
  </div>

  <div class="row">
    <div class="col">
      <h4 class="py-2 bg-light mt-4">Days Scheduled</h4>
    </div>
      <div class="col-xl-12 d-flex flex-wrap">
        <div
          class="d-flex flex-column mr-2 py-2 px-3"
          *ngFor="let day of week.days; let i = index"
        >
          <mat-checkbox
          color="primary"
            [checked]="day.checked"
            (change)="checkTime($event, day)"
            class="mr-2"
            >{{ day.name }}</mat-checkbox
          >
          <input
            class=""
            *ngIf="day.checked"
            class="form-control"
            type="time"
            [(ngModel)]="day.time"
            name="time{{ i }}"
          />
        </div>
      </div>

      <div class="col-xl-6">
        <mat-form-field class="w-100 pt-4 pb-3">
          <mat-label
            >Notify the following users when report is complete</mat-label
          >
          <mat-chip-grid #emailList>
            <mat-chip-row
              *ngFor="let email of emailAddresses"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(email)"
            >
              {{ email.name }}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip-row>
            <input
              matInput
              maxlength="1024"
              [formControl]="emailCtrl"
              placeholder="Add email ..."
              [matChipInputFor]="emailList"
              [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="add($event)"
              type="text"
            />
          </mat-chip-grid>
          <mat-hint>The emails must belong to the user’s domain.</mat-hint>
        </mat-form-field>
      </div>
    <div class="col-12">
      <button mat-stroked-button class="mt-3 success">
        Save Schedule
        </button>
    </div>
  </div>
</form>
