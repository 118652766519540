<h2 mat-dialog-title>{{ action }} Status</h2>
<form (ngSubmit)="processForm()" autocomplete="off">
  <div mat-dialog-content class="mt-4">
    <div class="row">
      <div *ngIf="action != 'Delete'" class="col-12 mt-2">
        <mat-form-field class="w-100">
          <mat-label>Status</mat-label>
          <input matInput status="text" [(ngModel)]="status.name" name="status" maxlength="255" required />
        </mat-form-field>
      </div>
      <div class="col-12 mt-12" *ngIf="action == 'Delete'">
        <h4>Are you sure you want to delete {{ status.name }}?</h4>
      </div>
    </div>
    <div mat-dialog-actions>
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button
        status="submit"
        mat-stroked-button
        class="ml-2"
        [class]="action == 'Delete' ? 'danger' : 'success'"
        [disabled]="loading"
      >
        <span>{{ action }} Status</span>
      </button>
    </div>
  </div>
</form>
