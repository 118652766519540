<div class="row">
  <div class="col-12" *ngFor="let release of releaseNotes">
    <div class="wrapper py-5 mx-5 border-bottom">
      <div class="d-inline-flex align-items-baseline">
        <h3 class="mb-0">v.{{ release.version }} - {{ release.releaseDate | date : "longDate" }}</h3>
        <h4 class="mb-0 ml-2">Updates</h4>
      </div>
      <div class="pl-3">
        <ng-template #recursiveList let-list>
          <ul class="pl-3" *ngFor="let feature of list">
            <li class="my-3">{{ feature.featureDescription }}</li>
            <ul class="pl-3" *ngIf="feature.children.length">
              <li class="my-3" *ngFor="let child of feature.children">
                {{ child.featureDescription }}
                <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: child.children }"></ng-container>
              </li>
            </ul>
          </ul>
        </ng-template>
        <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: release.releaseFeatures }"></ng-container>
      </div>
    </div>
  </div>
  <div *ngIf="!releaseNotes?.length" class="bg-white text-center p-4 my-auto">
    <span *ngIf="loaded">Gathering data ...</span>
    <span *ngIf="!loaded">No data returned</span>
  </div>
  <div class="col-12">
    <div class="mx-5">
      <app-table-footer
        [dataSource]="dataSource"
        [maxResults]="maxResults"
        [nextButton]="nextButton"
        [nextButtonDis]="nextButtonDis"
        [offset]="offset"
        [page]="page"
        [total]="totalCount"
        (pager)="setPager($event)"
      ></app-table-footer>
    </div>
  </div>
</div>
