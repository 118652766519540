import { Component, OnInit } from "@angular/core";
import { EmailService } from "src/app/services/ticket-plus/email/email.service";

@Component({
  selector: "app-test-mailbox",
  templateUrl: "./test-mailbox.component.html",
  styleUrls: ["./test-mailbox.component.css"],
})
export class TestMailboxComponent implements OnInit {
  loading = true;
  failed = false;
  private timestamp = new Date().getTime();

  tests = [
    {
      type: "SEND",
      name: "Testing ability to send emails",
      state: "PENDING",
    },
    {
      type: "READ",
      name: "Testing ability to read emails",
      state: "PENDING",
    },
    {
      type: "DELETE",
      name: "Testing ability to delete emails",
      state: "PENDING",
    },
  ];

  constructor(private emailService: EmailService) {}

  ngOnInit(): void {
    this.runTests();
  }

  retry(): void {
    this.tests.map((test) => (test.state = "PENDING"));
    this.runTests();
  }

  async runTests() {
    for await (const test of this.tests) {
      test.state = "CHECKING";
      let result = null;
      switch (test.type) {
        case "SEND":
          result = await this.sendEmail();
          break;
        case "READ":
          result = await this.readEmails();
          break;
        case "DELETE":
          result = await this.deleteEmails();
          break;
      }

      if (!result) this.failed = true;
      test.state = result ? "VERIFIED" : "ERRORED";
    }
  }

  async sendEmail() {
    const payload = await this.emailService.sendEmail();
    return !!payload?.count;
  }

  async readEmails() {
    const payload = await this.emailService.readEmails();
    return !!payload?.count;
  }

  async deleteEmails() {
    const payload = await this.emailService.deleteEmails();
    return !!payload?.count;
  }
}
