<h2 mat-dialog-title>Set Organizational Unit</h2>
<div class="mt-3" mat-dialog-content>
  <i
    style="font-size: 19px"
    class="material-icons material-icon-align mr-2"
    [ngClass]="isDomainClicked ? 'clickedDomain' : 'clickDomainClose'"
    type="button"
    (click)="rotateLeftNavIcon()"
    >keyboard_arrow_right</i
  >
  <a
    *ngIf="loginService.user.isAdmin"
    (click)="[selectOU(ouService.parentOU)]"
    [ngClass]="{ 'active-ou': selectedOU == '/' }"
  >
    {{ domain }}
  </a>
  <span *ngIf="!loginService.user.isAdmin">{{ domain }}</span>
  <ul
    *ngIf="isDomainClicked"
    [ngClass]="isDomainClicked ? 'transition-open' : 'transition-close'"
  >
    <ng-container
      *ngTemplateOutlet="recursiveListTmpl; context: { list: list }"
    ></ng-container>
  </ul>
  <ng-template #recursiveListTmpl let-list="list">
    <li class="subOU" *ngFor="let ou of list">
      <a
        class="change-ou-a cursor-pointer"
        *ngIf="ou.children.length > 0"
        (click)="[toggle(ou)]"
        data-id="{{ ou.orgUnitId }}"
      >
        <i
          style="font-size: 19px"
          class="material-icons material-icon-align mr-2"
          [ngClass]="
            ou.isOpen || selectedOU == ou.orgUnitPath ? 'clickOUOpen' : ''
          "
          type="button"
          >keyboard_arrow_right</i
        >
      </a>
      <a
        class="change-ou-a"
        (click)="[selectOU(ou)]"
        [ngClass]="{ 'active-ou': selectedOU == ou.orgUnitPath }"
      >
        {{ ou.name }}
      </a>
      <ul *ngIf="ou.children.length > 0 && ou.isOpen">
        <ng-container
          *ngTemplateOutlet="
            recursiveListTmpl;
            context: {
              list: ou.children
            }
          "
        >
        </ng-container>
      </ul>
    </li>
  </ng-template>
  <div mat-dialog-actions class="px-0">
    <button class="" type="button" mat-stroked-button mat-dialog-close>
      Cancel
    </button>
  </div>
</div>
