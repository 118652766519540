<ul class="navbar-nav w-100 d-flex ml-auto my-auto">
  <li class="nav-item mr-3 ml-md-0 d-none d-md-inline-block">
    <button
      mat-mini-fab
      target="_blank"
      matTooltip="Get Support"
      aria-label="Get Support"
      class="nav-link"
      (click)="toggleHelper()"
      color="info"
    >
      <mat-icon class="text-secondary"> help </mat-icon>
    </button>
  </li>
  <li *ngIf="loginService.user" class="nav-item mr-3 ml-md-0 d-none d-md-inline-block">
    <app-jobs-menu></app-jobs-menu>
  </li>
  <li class="nav-item">
    <div class="d-inline-block">
      <button
        color="info"
        mat-mini-fab
        aria-label="Account"
        class="nav-link p-0 m-0 text-secondary"
        [matMenuTriggerFor]="navMenu"
        aria-label="Logout"
      >
        <mat-icon *ngIf="!loginService.user?.photoUrl">account_circle</mat-icon>
        <img
          *ngIf="loginService.user?.photoUrl"
          src="{{ loginService.user.photoUrl }}"
          class="img-fluid rounded-circle"
          width="40"
        />
      </button>
      <mat-menu #navMenu="matMenu">
        <app-menu></app-menu>
      </mat-menu>
    </div>
  </li>
</ul>
