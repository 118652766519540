import { Injectable } from "@angular/core";
import { Ticket } from "src/app/models/ticket-plus/ticket";
import { Payload } from "../../utilities/utility.service";
import { TicketPlusService } from "../ticket-plus.service";

@Injectable({
  providedIn: "root",
})
export class TicketService extends TicketPlusService {
  async addTicket(ticket: Ticket): Promise<Ticket> {
    return await new Promise<Ticket>((resolve) => {
      this.utilityService.sendRequest("POST", `/ticket-plus/tickets`, {}, ticket).subscribe((payload) => {
        if (payload?.data?.length) {
          resolve(payload.data[0]);
        } else {
          resolve(null);
        }
      });
    });
  }

  async getTicket(ticketId: string): Promise<Ticket> {
    return await new Promise<Ticket>((resolve) => {
      this.utilityService.sendRequest("GET", "/ticket-plus/tickets/" + ticketId).subscribe((payload) => {
        if (payload?.data?.length) {
          resolve(payload.data[0]);
        } else {
          resolve(null);
        }
      });
    });
  }

  async getTickets(params = {}): Promise<Payload> {
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("GET", `/ticket-plus/tickets`, params).subscribe((payload) => {
        resolve(payload);
      });
    });
  }

  async getAnalytics(params = {}): Promise<any> {
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("GET", `/ticket-plus/analytics`, params).subscribe((payload) => {
        resolve(payload);
      });
    });
  }

  async editTicket(ticket: Ticket): Promise<Ticket> {
    return await new Promise<Ticket>((resolve, reject) => {
      this.utilityService.sendRequest("POST", `/ticket-plus/tickets/` + ticket.id, {}, ticket).subscribe((payload) => {
        if (payload?.data?.length) {
          resolve(payload.data[0]);
        } else {
          reject("Unable to update ticket");
        }
      });
    });
  }

  async deleteTickets(tickets: Ticket[]): Promise<Payload> {
    return await new Promise<Payload>((resolve) => {
      const method = tickets.length > 1 ? "POST" : "DELETE";
      const path = `/ticket-plus/tickets/` + (tickets.length > 1 ? "batch" : tickets[0].id);
      const body = tickets.length > 1 ? tickets.map((ticket) => ({ id: ticket.id, action: "delete" })) : {};

      this.utilityService.sendRequest(method, path, {}, body).subscribe((payload) => {
        resolve(payload);
      });
    });
  }
}
export enum TICKET_STATUS {
  OPEN = 1,
  PENDING = 2,
  CLOSED = 3,
}
