import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { Building } from "src/app/models/admin-plus/building";
import { Room } from "src/app/models/admin-plus/room";
import { BuildingsService } from "src/app/services/admin-plus/buildings.service";
import { RoomsService } from "src/app/services/admin-plus/rooms.service";
import { UtilityService } from "src/app/services/utilities/utility.service";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-building-room-dialog",
  templateUrl: "./building-room-dialog.component.html",
  styleUrls: ["./building-room-dialog.component.css"],
})
export class BuildingRoomDialogComponent implements OnInit {
  action = "Add";
  loading = false;
  buildingId = 0;

  @Input() object: Building | Room;

  actions = new FormControl();

  constructor(
    private dialogRef: MatDialogRef<BuildingRoomDialogComponent>,
    private buildingService: BuildingsService,
    private roomService: RoomsService,
    @Inject(MAT_DIALOG_DATA) public data,
    private notifyService: NotificationService,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.action = this.utilityService.capitalizeFirstLetter(this.data.action);
    if (this.data.object) {
      this.object = Object.assign({}, this.data.object);
      if (this.data.object.building) {
        this.buildingId = this.data.object.building.id;
        if (this.buildingId) {
          this.actions.setValue(this.buildingId);
        }
      }
    }
  }

  async processForm() {
    this.loading = true;
    this.buildingId = this.actions.value;
    if (!this.buildingId && this.data.type == "Room") {
      this.loading = false;
      this.notifyService.notify(`Building is required`);
      return;
    }
    if (!this.object.name?.trim()) {
      this.loading = false;
      this.notifyService.notify(`${this.data.type == "Room" ? "Label" : "Name"} is required`);
      return;
    }
    if (this.action !== "Delete" && this.buildingExists()) {
      this.loading = false;
      this.notifyService.notify("Building already exists");
      return;
    }
    if (this.action == "Add" || this.action == "Edit") {
      if (this.data.type == "Room") {
        const newRoom = new Room({
          id: this.object.id,
          name: this.object.name,
          building: { id: this.buildingId },
        });
        this.addEditRoom(newRoom);
      } else {
        const newBuilding = new Building({
          id: this.object.id,
          name: this.object.name,
        });
        this.addEditBuilding(newBuilding);
      }
    } else if (this.action == "Delete") {
      if ("building" in this.object) {
        this.deleteRoom(this.object.building.id, this.object.id);
      } else {
        this.deleteBuilding(this.object);
      }
    }
  }

  buildingExists() {
    const existingBuilding = this.data?.buildings?.find((building) => building?.name == this.object?.name);
    return existingBuilding ? true : false;
  }

  async addEditBuilding(building: Building) {
    const payload = await this.buildingService.addBuilding(building);
    this.dialogRef.close(payload);
    this.loading = false;
  }

  async addEditRoom(room: Room) {
    await this.roomService
      .addRoom(room.building.id, room)
      .catch((error) => {
        console.error(error);
      })
      .then((data) => {
        this.dialogRef.close(data);
      });
    this.loading = false;
  }

  async deleteBuilding(building: Building) {
    const payload = await this.buildingService.deleteBuilding(building);
    this.dialogRef.close(payload);
    this.loading = false;
  }

  async deleteRoom(buildingId, roomId) {
    await this.roomService
      .deleteRoom(buildingId, roomId)
      .catch((error) => {
        console.error(error);
      })
      .then((data) => {
        this.loading = false;
        this.dialogRef.close(data);
      });
    this.loading = false;
  }
}
