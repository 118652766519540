import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { Settings } from "./settings";
import { AuthService } from "src/app/services/admin-plus/auth.service";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  private api_url: string = "https://www.googleapis.com/groups/v1/groups/";
  private user: any;

  constructor(private http: HttpClient, private loginService: LoginService, private authService: AuthService) {}

  async getSettings(groupEmail: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise((resolve) => {
      this.getSettingsData(groupEmail).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getSettingsData(groupEmail: string) {
    return this.http
      .get(this.api_url + encodeURI(groupEmail), {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
        params: new HttpParams().set("alt", "json"),
      })
      .pipe(catchError(this.authService.handleAPIError<any>("getSettingsData")));
  }

  async saveSettings(groupEmail: string, settings: Settings) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise((resolve) => {
      this.saveSettingsData(groupEmail, settings).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private saveSettingsData(groupEmail: string, settings: Settings) {
    return this.http
      .put(this.api_url + encodeURI(groupEmail), settings, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<any>("saveSettingsData")));
  }
}
