<ul *ngIf="payload?.data?.length" class="list-group">
  <li *ngFor="let recentUser of payload.data" class="list-group-item">
    <mat-chip-set class="asset__subtitle">
      <mat-chip disableRipple>
        <img
          referrerpolicy="no-referrer"
          class="rounded-circle m-0"
          height="24"
          width="24"
          matChipAvatar
          src="{{
            !(recentUser.email | getProfilePicturePipe | async)
              ? 'https://lh3.googleusercontent.com/a/default-user=s64'
              : (recentUser.email | getProfilePicturePipe | async)
          }}"
          alt="Avatar"
        />
        {{ recentUser.email }}
      </mat-chip>
    </mat-chip-set>
  </li>
</ul>
