import { Component, Input } from "@angular/core";
import { Device } from "src/app/models/asset-plus/device";

@Component({
  selector: "app-device-info",
  templateUrl: "./device-info.component.html",
  styleUrls: ["./device-info.component.scss"],
})
export class DeviceInfoComponent {
  @Input() device: Device;
  @Input() loading = true;
}
