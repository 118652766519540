import { DatePipe } from "@angular/common";
import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-dialog-details",
  templateUrl: "./dialog-details.component.html",
  styleUrls: ["./dialog-details.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DialogDetailsComponent {
  html = "";
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private datePipe: DatePipe) {
    if (data) this.transformData(data);
  }

  transformData(data) {
    data = JSON.parse(data);
    this.html += `
				<table border="1" class="dynamic-table">
				 <thead>
				  <tr>
				   <th colspan="2" class="struct">struct</th>
				 </tr>
				</thead>
				<tbody>
				${this.iterate(data)}
				</tbody>
				</table>
			`;
  }

  iterate(obj: any) {
    let htmlToReturn = ``;
    if (!obj) return null;
    if (typeof obj === "string")
      return (htmlToReturn += `<tr>
				<td colspan='2'>${obj}</td>
				</tr>`);

    Object.keys(obj).forEach((x) => {
      if (typeof obj[x] == "object" && obj[x] !== null) {
        if (Array.isArray(obj[x])) {
          htmlToReturn += `
				<tr>
				<td>${x}</td>
				<td>
				<table border="1" class="dynamic-table">
				<thead>
				<tr>
				<th colspan="2">array</th>
				</tr>
				</thead>
				<tbody>
				<tr>
				${this.iterate(obj[x])}
				</tr>
				</tbody>
				</table></td>
				</tr>`;
        } else {
          htmlToReturn += `
				<tr>
				<td>${x}</td>
				<td>
				<table border="1" class="dynamic-table">
				<thead>
				<tr>
				<th colspan="2" class="struct">struct</th>
				</tr>
				</thead>
				<tbody>
				<tr>
				${this.iterate(obj[x])}
				</tr>
				</tbody>
				</table></td>
				</tr>`;
        }
      } else {
        const value = obj[x] === "" ? "(empty string)" : obj[x];
        htmlToReturn += `<tr><td>${x}</td><td>${value}</td></tr>`;
      }
    });
    return htmlToReturn;
  }
}
