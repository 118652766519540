import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LoginService } from "src/app/services/admin-plus/login.service";

@Component({
  selector: "app-edit-service-account",
  templateUrl: "./edit-service-account.component.html",
  styleUrls: ["./edit-service-account.component.scss"],
})
export class EditServiceAccountComponent {
  automatic = false;

  constructor(
    public dialogRef: MatDialogRef<EditServiceAccountComponent>,
    public dialog: MatDialog,
    public loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  setAutomatic(result = true) {
    this.automatic = result;
  }
}
