<h2 mat-dialog-title>Transfer Drive Content Ownership to Another User</h2>
<form *ngIf="users" (ngSubmit)="processForm()" (keyup.enter)="processForm()" autocomplete="off">
  <div mat-dialog-content>
    <mat-form-field class="w-100">
      <mat-chip-grid #userList>
        <mat-chip-row
          *ngFor="let selected of selected_user"
          [removable]="removable"
          (removed)="remove(selected)"
        >
          {{ selected.primaryEmail }}
          <mat-icon [selectable]="selectable" matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip-row>
        <input
          placeholder="Search user email / name ..."
          #userInput
          [formControl]="userCtrl"
          [matAutocomplete]="auto"
          [matChipInputFor]="userList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)"
          matInput
          (keyup)="search($event)"
        />
      </mat-chip-grid>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let user of filteredOptions | async" [value]="user">
          {{ user.name.fullName }}&nbsp;|&nbsp;<small class="text-muted">{{ user.primaryEmail }}</small>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <small class="text-muted d-block mb-2">You can only transfer ownership to one user at a time.</small>
    <app-dialog-actions-container></app-dialog-actions-container>
  </div>
</form>
