import { Injectable } from "@angular/core";
import { AdminPlusService } from "./admin-plus.service";
import { Signature } from "src/app/models/admin-plus/signature";
import { Payload } from "../utilities/utility.service";

@Injectable({
  providedIn: "root",
})
export class SignatureService extends AdminPlusService {
  public fonts: Font[] = [
    {
      name: "Sans Serif",
      css: "Sans-Serif",
    },
    {
      name: "Serif",
      css: "Serif",
    },
    {
      name: "Fixed Width",
      css: "monospace",
    },
    {
      name: "Wide",
      css: "'arial black', sans-serif",
    },
    {
      name: "Narrow",
      css: "'arial narrow', sans-serif",
    },
    {
      name: "Comic Sans MS",
      css: "'comic sans ms', sans-serif",
    },
    {
      name: "Garamond",
      css: "garamond, 'times new roman', serif",
    },
    {
      name: "Georgia",
      css: "georgia, serif",
    },
    {
      name: "Tahoma",
      css: "tahoma, sans-serif",
    },
    {
      name: "Trebuchet MS",
      css: "'trebuchet ms', sans-serif",
    },
    {
      name: "Verdana",
      css: "verdana, sans-serif",
    },
  ];
  async createSignature(signature: Signature): Promise<Signature> {
    return new Promise<Signature>((resolve) => {
      const method = "POST";
      const path = `/admin-plus/signatures`;
      const body = signature;

      this.utilityService.sendRequest(method, path, null, body).subscribe((payload) => {
        if (payload?.data?.length) {
          resolve(payload.data[0]);
        } else {
          resolve(null);
        }
      });
    });
  }

  async getSignatures(params = {}) {
    return new Promise<Payload>((resolve) => {
      const method = "GET";
      const path = `/admin-plus/signatures`;

      this.utilityService.sendRequest(method, path, params).subscribe((payload) => {
        resolve(payload);
      });
    });
  }

  async getSignature(signatureId: number): Promise<Signature> {
    return new Promise<Signature>((resolve) => {
      const method = "GET";
      const path = `/admin-plus/signatures/${signatureId}`;

      this.utilityService.sendRequest(method, path).subscribe((payload) => {
        if (payload?.data?.length) {
          resolve(payload.data[0]);
        } else {
          resolve(null);
        }
      });
    });
  }

  async updateSignature(signature: Signature): Promise<Signature> {
    return new Promise<Signature>((resolve) => {
      const method = "POST";
      const path = `/admin-plus/signatures/${signature.id}`;
      const body = signature;

      this.utilityService.sendRequest(method, path, null, body).subscribe((payload) => {
        if (payload?.data?.length) {
          resolve(payload.data[0]);
        } else {
          resolve(null);
        }
      });
    });
  }

  /* Will be depriciated as soon as the back-end is updated to track workflow actions using the signature */
  async useSignature(signature: Signature): Promise<Signature> {
    return new Promise<Signature>((resolve) => {
      const method = "POST";
      const path = `/admin-plus/signatures/${signature.id}/use`;

      this.utilityService.sendRequest(method, path).subscribe((payload) => {
        if (payload?.data?.length) {
          resolve(payload.data[0]);
        } else {
          resolve(null);
        }
      });
    });
  }

  async createSignaturePreview(email: string, signature: Signature): Promise<string> {
    return new Promise<any>((resolve) => {
      const method = "POST";
      const path = `/admin-plus/signatures/-/preview`;
      const body = {
        email: email,
        signature: signature,
      };

      this.utilityService.sendRequest(method, path, null, body).subscribe((payload) => {
        resolve(payload);
      });
    });
  }
}

export interface Font {
  name: string;
  css: string;
}
