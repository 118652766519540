import { Component, Input } from "@angular/core";
import { ChartType, ChartSelectionChangedEvent } from "angular-google-charts";
import { StorageReport, ReportsService } from "src/app/services/google/reports.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-storage-report-ou-bar-chart",
  templateUrl: "./storage-report-ou-bar-chart.component.html",
  styleUrls: ["./storage-report-ou-bar-chart.component.scss"],
})
export class StorageReportOuBarChartComponent {
  @Input() users: StorageReport[] = [];
  type: ChartType = ChartType.BarChart;
  public unit = "";
  public chartColumns = [
    "Organizational Unit",
    "Usage",
    { role: "annotation" },
    { role: "annotation" },
    { role: "annotation" },
  ];
  data: unknown[];
  options = {
    colors: ["#114c71"],
    annotations: {
      alwaysOutside: true,
      textStyle: {
        fontSize: 12,
        auraColor: "none",
        color: "#555",
      },
      boxStyle: {
        stroke: "#ccc",
        strokeWidth: 1,
        gradient: {
          color1: "#f3e5f5",
          color2: "#f3e5f5",
          x1: "0%",
          y1: "0%",
          x2: "100%",
          y2: "100%",
        },
      },
    },
    hAxis: {
      title: `Usage ${this.unit}`,
      minValue: 0,
    },
    vAxis: {
      title: "Organizational Units",
    },
  };

  constructor(private router: Router, private reportsService: ReportsService) {
    this.data = [];
  }

  onBarSelect(event: ChartSelectionChangedEvent) {
    for (let i = 0; i < this.users.length; i++) {
      if (event.selection[0].row === i) {
        this.router.navigate(["/reports/storage/users/" + this.users[i].id]);
      }
    }
  }

  ngOnChanges() {
    if (this.users && this.users.length) {
      this.unit = this.reportsService.calculateUnit(this.users[0].totalStorage);
      this.options.hAxis.title = `Usage ${this.unit}`;
    }
    this.data = this.users.map((item) => [
      item.name,
      this.reportsService.calculateValue(item.totalStorage, this.unit),
      item.firstName + " " + item.lastName,
      "",
      item.OU,
    ]);
  }
}
