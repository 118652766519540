<div
  *ngIf="!dismissed && serviceAccountService.hasFailedScopes"
  class="message bg-danger text-light d-flex align-content-center justify-content-between py-1"
>
  <div class="my-auto ml-4">
    <mat-icon>warning</mat-icon>
  </div>
  <div class="text-center my-auto">
    <h3 class="mb-0">Service Account Requires Attention</h3>
    <h4 *ngIf="!hideLink">
      <a class="text-light" routerLink="/settings/product/service-account">Click here to view and update permissions</a>
    </h4>
  </div>
  <button mat-icon-button class="mr-3 my-auto" (click)="dismiss()">
    <mat-icon class="mb-1">close</mat-icon>
  </button>
</div>
