import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { AuthService } from "src/app/services/admin-plus/auth.service";
import { Observable } from "rxjs";
import { LoginService } from "./login.service";
import { UtilityService } from "../utilities/utility.service";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  api_url: string;
  jwt_token: string;
  api_url_service: string;
  private user: any;
  private timezones: Array<string>;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private loginService: LoginService,
    private utilityService: UtilityService
  ) {}

  async getAccount() {
    return new Promise<any>((resolve) => {
      this.utilityService.sendRequest("GET", `/account`).subscribe((payload) => {
        resolve(payload);
      });
    });
  }

  public async getTimezones() {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    if (this.timezones) {
      return this.timezones;
    }

    return new Promise<Array<string>>((resolve) => {
      this.getTimezonesRequest().subscribe((results) => {
        if (results) {
          this.timezones = results;
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getTimezonesRequest(): Observable<Array<string>> {
    return this.http
      .get<Array<string>>(environment.apiUrl + `/customer/automation/timezones`, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Array<string>>("getTimezonesRequest")));
  }
}
