import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { User } from "src/app/web/login/user";
import { catchError } from "rxjs/operators";
import { AuthService } from "src/app/services/admin-plus/auth.service";

@Injectable({
  providedIn: "root",
})
export class ReleaseNotesService {
  private user: User;
  constructor(private http: HttpClient, private loginService: LoginService, private authService: AuthService) {}

  public async getReleaseNotes(limit: number, offset: number): Promise<ReleaseNote[]> {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<ReleaseNote[]>((resolve) => {
      this.getReleaseNotesRequest(limit, offset).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getReleaseNotesRequest(limit, offset): Observable<ReleaseNote[]> {
    return this.http
      .get<ReleaseNote[]>(environment.apiUrl + "/releases/features", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams().set("limit", limit.toString()).set("offset", offset.toString()),
      })
      .pipe(catchError(this.authService.handleAPIError<ReleaseNote[]>("getReleaseNotesRequest")));
  }
}

export interface ReleaseNote {
  id: number;
  version: string;
  releaseDate: string;
  releaseFeatures: [];
}
