import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { NavService } from "src/app/services/admin-plus/nav.service";

@Component({
  selector: "app-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.scss"],
})
export class NavComponent {
  loading = false;
  loadingEvents = 0;
  @Output() sideNavToggle = new EventEmitter();
  @Input() animating;
  constructor(private navService: NavService, public loginService: LoginService) {
    this.navService.loading.subscribe((loading) => {
      if (loading) {
        this.loadingEvents++;
      } else {
        if (this.loadingEvents > 0) {
          this.loadingEvents--;
        }
      }
      this.loading = loading;
    });
  }
}
