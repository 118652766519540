import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UsersService } from "src/app/services/admin-plus/users.service";
import { User } from "src/app/users/user";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { OUService } from "src/app/services/google/ou.service";
import { OuSelectionComponent } from "src/app/users/dialogs/ou-selection/ou-selection.component";
import { NavService } from "src/app/services/admin-plus/nav.service";

@Component({
  selector: "app-users-new-user",
  templateUrl: "./users-new-user.component.html",
  styleUrls: ["./users-new-user.component.scss"],
})
export class UsersNewUserComponent implements OnInit {
  public user;
  public selected_ou;
  public error: string;
  public firstname: string;
  public lastname: string;
  public primaryemail: string;
  public password: string;
  public domain: string;
  public org_unit: string;

  constructor(
    public matDialog: MatDialog,
    private usersService: UsersService,
    private notifyService: NotificationService,
    private navService: NavService,
    private ouService: OUService,
    private dialogRef: MatDialogRef<UsersNewUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    const user: User = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.user = user;
      this.domain = this.user.email.replace(/.*@/, "");
    }
  }

  async ngOnInit() {
    let OU = {};
    if (!this.user.isAdmin) {
      OU = await this.ouService.getUserOUs();
    }
    this.org_unit = this.user.isAdmin ? "/" : OU[0]["orgUnitPath"];
  }

  async processForm() {
    this.navService.loading.next(true);
    if (this.firstname === undefined) {
      this.notifyService.notify("First name is required");
    } else if (this.lastname === undefined) {
      this.notifyService.notify("Last name is required");
    } else if (this.primaryemail === undefined) {
      this.notifyService.notify("Primary email is required");
    } else if (this.password === undefined) {
      this.notifyService.notify("Password is required");
    } else {
      const user = {
        name: {
          familyName: this.lastname,
          givenName: this.firstname,
        },
        primaryEmail: this.primaryemail + "@" + this.domain,
        password: this.password,
        orgUnitPath: this.org_unit,
      };
      const response = await this.usersService.addUser(user);
      if (response) {
        this.dialogRef.close(response);
        this.notifyService.notify("Successfully added " + this.firstname + " " + this.lastname);
      }
    }
    this.navService.loading.next(false);
  }

  // public async openChangeOUDialog() {
  // 	let OU = {};
  // 	if (!this.user.isAdmin) {
  // 		OU = await this.ouService.getUserOUs();
  // 	}
  // 	const dialogRef = this.matDialog.open(OuSelectionComponent, {
  // 		width: "500px",
  // 		data: {
  // 			domain: this.user.email.replace(/.*@/, ""),
  // 			user: this.user,
  // 			orgPath: this.user.isAdmin ? "/" : OU[0]["orgUnitPath"],
  // 			selectedOU: this.org_unit,
  // 		},
  // 	});

  // 	dialogRef.afterClosed().subscribe((result) => {
  // 		if (result) {
  // 			this.org_unit = result;
  // 		}
  // 	});
  // }
}
