<div class="row pt-3">
  <div class="col-12 mt-2">
    <mat-form-field class="w-100">
      <mat-label>Search for User</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Email" #input />
    </mat-form-field>
  </div>
</div>
<div class="row">
  <div class="col-12 mat-mdc-card">
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="changeSort($event)">
        <ng-container matColumnDef="emailAddress">
          <th class="px-3" mat-header-cell mat-sort-header *matHeaderCellDef>User</th>
          <td class="p-3" mat-cell *matCellDef="let row">
            <div class="d-flex align-items-center" style="gap: 8px">
              <img
                referrerpolicy="no-referrer"
                width="28"
                height="28"
                matChipAvatar
                src="{{
                  !(row.emailAddress | getProfilePicturePipe | async)
                    ? 'https://lh3.googleusercontent.com/a/default-user=s64'
                    : (row.emailAddress | getProfilePicturePipe | async)
                }}"
                alt="Avatar"
              />
              <span>
                {{ row.emailAddress }}
              </span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="created">
          <th class="px-3" mat-header-cell mat-sort-header *matHeaderCellDef>Date</th>
          <td class="p-3" mat-cell *matCellDef="let row">
            {{ row.created | date : "short" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th class="px-3" mat-header-cell mat-sort-header *matHeaderCellDef>Type</th>
          <td class="p-3" mat-cell *matCellDef="let row">
            <!--a href="#" (click)="routeToEmail(row.typeId)"-->
            {{ row.type }}
            <!--/a-->
          </td>
        </ng-container>
        <tr class="mat-row" app-empty-dataset-alert [loading]="loading" [filterCount]="search.length" *matNoDataRow></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <app-table-footer
        [dataSource]="dataSource"
        [maxResults]="maxResults"
        [nextButton]="nextButton"
        [nextButtonDis]="nextButtonDis"
        [offset]="offset"
        [page]="page"
        [total]="totalEmailCount"
        (pager)="setPager($event)"
      ></app-table-footer>
    </div>
  </div>
</div>
