import { Component } from "@angular/core";
import { CustomerService, Job } from "src/app/services/admin-plus/customer.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-jobs-menu",
  templateUrl: "./jobs-menu.component.html",
  styleUrls: ["./jobs-menu.component.scss"],
})
export class JobsMenuComponent {
  jobs: Job[] = [];
  loading = false;

  constructor(public customerService: CustomerService, private router: Router) {}

  async loadData() {
    this.loading = true;
    this.customerService.running = false;
    const today = this.formatDate(new Date());
    const jobs = await this.customerService.getJobs("", "", "", "", "", "", "", null, null, false, today);
    if (jobs) {
      this.jobs = jobs["data"];
    }
    for (let i = 0; i < this.jobs.length; i++) {
      if (this.jobs[i]["status"]["id"] === 3) {
        this.customerService.running = true;
      }
    }
    this.loading = false;
  }

  //having a whole method for this... eh
  formatDate(date) {
    const d = new Date(date),
      year = d.getFullYear();

    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  goToJobs() {
    this.router.navigate(["reports/storage/jobs"]);
  }
}
