import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Status } from "src/app/models/asset-plus/status";
import { NotificationService } from "src/app/services/utilities/notification.service";

@Component({
  selector: "app-status-dialog",
  templateUrl: "./status-dialog.component.html",
  styleUrls: ["./status-dialog.component.css"],
})
export class StatusDialogComponent implements OnInit {
  action = "Add";
  loading = false;
  originalStatusName = "";

  @Input() status = new Status({});

  constructor(
    private dialogRef: MatDialogRef<StatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private notifyService: NotificationService
  ) {}

  ngOnInit(): void {
    this.action = this.capitalizeFirstLetter(this.data.action);
    if (this.data.status) {
      this.status = Object.assign({}, this.data.status);
      this.originalStatusName = this.status.name;
    }
  }

  async processForm() {
    this.loading = true;
    if (this.originalStatusName == this.status.name && this.action !== "Delete") {
      this.notifyService.notify("Status has not been changed");
      this.loading = false;
      return;
    }
    if (this.status.name?.trim()) {
      let duplicate = false;
      if (this.action !== "Delete") {
        for (const status of this.data.statuses) {
          if (this.status.name === status.name) {
            duplicate = true;
          }
        }
      }
      if (!duplicate || this.action == "Delete") {
        this.dialogRef.close(this.status);
      } else {
        this.notifyService.notify("The status already exists");
      }
    } else {
      this.notifyService.notify("Status is required");
    }
    this.loading = false;
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
