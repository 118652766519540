import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UsersService, resetPassword } from "src/app/services/admin-plus/users.service";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { NavService } from "src/app/services/admin-plus/nav.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  public password: string = "";

  constructor(
    public matDialog: MatDialog,
    private usersService: UsersService,
    private notifyService: NotificationService,
    private navService: NavService,
    private dialogRef: MatDialogRef<ResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  async processForm() {
    this.navService.loading.next(true);
    let resetPassword: resetPassword = {
      changePasswordAtNextLogin: true,
      password: this.password,
    };
    let response = await this.usersService.resetPassword(this.data.userKey, resetPassword);
    if (response !== null) {
      this.notifyService.notify("Successfully set a temporary password for " + this.data.userKey);
      this.dialogRef.close();
    }
    this.navService.loading.next(false);
  }
}
