import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthService } from "src/app/services/admin-plus/auth.service";

@Component({
  selector: "app-renew-token-dialog",
  templateUrl: "./renew-token-dialog.component.html",
  styleUrls: ["./renew-token-dialog.component.css"],
})
export class RenewTokenDialogComponent {
  constructor(public authService: AuthService, @Inject(MAT_DIALOG_DATA) public data: any) {}
}
