<div class="d-flex justify-content-between px-3 pt-2 my-4 flex-wrap">
  <div class="table-footer-item-count">
    {{ offset + (dataSource.data.length > 0 ? 1 : 0) | number }} -
    {{ !total || dataSource.data.length + offset < total ? dataSource.data.length + offset : (total | number) }}
    <span *ngIf="total" class="ml-1">of {{ total | number }}</span>
    <span class="ml-1">results</span>
  </div>

  <div class="d-flex align-items-center flex-wrap">
    <mat-form-field class="mr-3">
      <mat-label>Items per page: {{ maxResults }}</mat-label>
      <mat-select [formControl]="actionsMaxResult" (ngModelChange)="maxResultChange($event)">
        <mat-option value="10" selected>10</mat-option>
        <mat-option [disabled]="total && total <= 10" value="20">20</mat-option>
        <mat-option [disabled]="total && total <= 20" value="50">50</mat-option>
        <mat-option [disabled]="total && total <= 50" value="100">100</mat-option>
      </mat-select>
    </mat-form-field>

    <button type="button" [disabled]="page == 1 || disabled" class="mr-3" (click)="prevPage()" mat-stroked-button>
      <span class="material-icons">keyboard_arrow_left</span>
    </button>
    <button
      type="button"
      [disabled]="(!nextPageToken && dataSource.data.length < maxResults) || nextButtonDis || nextButton || disabled"
      class=""
      (click)="nextPage()"
      mat-stroked-button
    >
      <span class="material-icons">keyboard_arrow_right</span>
    </button>
    <span class="ml-3" style="font-size: 13px; color: rgba(0, 0, 0, 0.54)">Page {{ page }}</span>
  </div>
</div>
