import { Component, EventEmitter, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-auto-add-error-dialog",
  templateUrl: "./auto-add-error-dialog.component.html",
})
export class AutoAddErrorDialogComponent {
  retryAdd = new EventEmitter();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AutoAddErrorDialogComponent>,
    public dialog: MatDialog
  ) {}

  retry() {
    this.dialogRef.close();
    this.retryAdd.emit();
  }
}
