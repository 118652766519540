<nav class="navbar navbar-expand bg-white border-bottom">
  <app-loader *ngIf="loadingEvents"></app-loader>
  <app-service-account-notification
    *ngIf="loginService.getUser()?.authToken && loginService.user?.isAdmin"
  ></app-service-account-notification>
  <div class="w-100 d-flex justify-content-between p-2">
    <app-content-left (sideNavToggle)="sideNavToggle.emit()" [animating]="animating"></app-content-left>
    <app-content-right></app-content-right>
  </div>
</nav>
