import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Observable } from "rxjs";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { User } from "./user.service";
import { File } from "src/app/services/google/reports.service";
import { AuthService } from "src/app/services/admin-plus/auth.service";
import { StoragePlusAction } from "src/enums/storagePlusAction";

@Injectable({
  providedIn: "root",
})
export class FilesService {
  private user;
  private apiUrl = environment.apiUrl + "/files";
  private rootAPI = environment.apiUrl;

  types: string[] = ["application", "image", "video", "audio"];
  defaultFileTypes: FileType[] = [
    {
      prefix: "application/pdf",
      icon: "picture_as_pdf",
    },
    {
      prefix: "image/",
      icon: "image_file",
    },
    {
      prefix: "application/vnd.google-apps.presentation",
      icon: "slideshow",
    },
    {
      prefix: "video/",
      icon: "video_file",
    },
    {
      prefix: "audio/",
      icon: "audio_file",
    },
    {
      prefix: "application/vnd.google-apps.spreadsheet",
      icon: "border_all",
    },
    {
      prefix: "application/zip",
      icon: "folder_zip",
    },
    {
      prefix: "application/x-zip",
      icon: "folder_zip",
    },
  ];

  constructor(private http: HttpClient, private loginService: LoginService, private authService: AuthService) {}

  public async fixDuplicateFiles(md5Checksum: string, action: string, options: Options) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<boolean>((resolve) => {
      this.fixDuplicateFilesData(md5Checksum, action, options).subscribe((fixed) => {
        if (fixed) {
          resolve(fixed);
        }
      });
    });
  }

  private fixDuplicateFilesData(md5Checksum: string, action: string, options: Options): Observable<boolean> {
    const body = {
      action: action,
      options: options,
    };
    return this.http
      .post<boolean>("/storage_plus/files/duplicates/" + md5Checksum + "/fix/" + action, body, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<boolean>("fixDuplicateFilesData")));
  }

  public async getMD5ChecksumOwners(md5Checksum: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<File[]>((resolve) => {
      this.getMD5ChecksumOwnersData(md5Checksum).subscribe((files) => {
        if (files) {
          resolve(files);
        }
      });
    });
  }

  private getMD5ChecksumOwnersData(md5Checksum: string): Observable<File[]> {
    return this.http
      .get<File[]>(this.apiUrl + "/file-owners/" + md5Checksum, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<File[]>("getMD5ChecksumOwnersData")));
  }

  public async getBusyFiles(files: File[]) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<File[]>((resolve) => {
      this.getBusyFilesData(files).subscribe((files) => {
        if (files) {
          resolve(files);
        }
      });
    });
  }

  private getBusyFilesData(files: File[]): Observable<File[]> {
    return this.http
      .post<File[]>(this.apiUrl + "/get-busy-files", files, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<File[]>("getBusyFilesData")));
  }

  public async getBusyDuplicateFiles() {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<File[]>((resolve) => {
      this.getBusyDuplicateFilesData().subscribe((files) => {
        if (files) {
          resolve(files);
        }
      });
    });
  }

  public getBusyDuplicateFilesData(): Observable<File[]> {
    return this.http
      .get<File[]>(this.apiUrl + "/get-busy-duplicate-files", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<File[]>("getBusyDuplicateFilesData")));
  }

  public async getFile(id: string, driveId: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<File>((resolve) => {
      this.getFileData(id, driveId).subscribe((file) => {
        if (file) {
          resolve(file);
        }
      });
    });
  }

  private getFileData(id: string, driveId: string): Observable<File> {
    return this.http
      .get<File>(this.apiUrl + `/${id}/get-file`, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams().set("driveId", driveId === null ? "0" : driveId.toString()),
      })
      .pipe(catchError(this.authService.handleAPIError<File>("getFileData")));
  }

  public async resyncFiles(userId: number) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<number>((resolve) => {
      this.resyncFilesData(userId).subscribe((count) => {
        if (count) {
          resolve(count);
        }
      });
    });
  }

  private resyncFilesData(userId: number): Observable<number> {
    return this.http
      .get<number>(this.rootAPI + "/reports/storage/users/" + userId + "/files/resync", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<number>("resyncFilesData")));
  }

  public async addScheduleToTrash(files: File[]) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<File[]>((resolve) => {
      this.addScheduleToTrashData(files).subscribe((files) => {
        if (files) {
          resolve(files);
        }
      });
    });
  }

  private addScheduleToTrashData(files: File[]): Observable<File[]> {
    const body = {
      files: files,
    };
    return this.http
      .post<File[]>(`${this.rootAPI}/storage-plus/files/null/${StoragePlusAction.SCHEDULE}`, body, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<File[]>("addScheduleToTrashData")));
  }

  public async getTrashedFile(fileId: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<string>((resolve) => {
      this.getTrashedFileData(fileId).subscribe((job) => {
        if (job) {
          resolve(job);
        }
      });
    });
  }

  private getTrashedFileData(fileId: string): Observable<string> {
    return this.http
      .get<string>(this.apiUrl + "/" + fileId, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<string>("getTrashedFileData")));
  }

  public async getDeletedFile(fileId: string, reportId: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<string>((resolve) => {
      this.getDeletedFileData(fileId, reportId).subscribe((deletedFile) => {
        if (deletedFile) {
          resolve(deletedFile);
        }
      });
    });
  }

  private getDeletedFileData(fileId: string, reportId: string): Observable<string> {
    return this.http
      .get<string>(this.apiUrl + "/" + fileId + "/report/" + reportId, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<string>("getDeletedFileData")));
  }

  public async getDeletedFiles(
    reportId: string,
    orderBy = "",
    order = "",
    limit = 10,
    offset = "",
    fileName = "",
    email = ""
  ) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<File>((resolve) => {
      this.getDeletedFilesData(reportId, orderBy, order, limit, offset, fileName, email).subscribe((deletedFiles) => {
        if (deletedFiles) {
          resolve(deletedFiles);
        }
      });
    });
  }

  private getDeletedFilesData(
    reportId,
    orderBy = "",
    order = "",
    limit = 10,
    offset = "",
    fileName = "",
    email = ""
  ): Observable<File> {
    return this.http
      .get<File>(this.apiUrl + "/filesDeleted/" + reportId, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams()
          .set("orderBy", orderBy)
          .set("order", order)
          .set("limit", limit.toString())
          .set("offset", offset)
          .set("fileName", fileName)
          .set("email", email),
      })
      .pipe(catchError(this.authService.handleAPIError<File>("getDeletedFilesData")));
  }

  public async transferFileOwnership(files: File[], newOwner: User) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<File[]>((resolve) => {
      this.transferFileOwnershipData(files, newOwner).subscribe((undo) => {
        if (undo) {
          resolve(undo);
        }
      });
    });
  }

  private transferFileOwnershipData(files: File[], newOwner: User): Observable<File[]> {
    const body = {
      files: files,
      newOwner: newOwner,
    };
    return this.http
      .post<File[]>(this.apiUrl + "/transfer-file-ownership", body, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<File[]>("transferFileOwnershipData")));
  }

  public async undoTrash(files: File[]) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<File[]>((resolve) => {
      this.undoTrashData(files).subscribe((undo) => {
        if (undo) {
          resolve(undo);
        }
      });
    });
  }

  private undoTrashData(files: File[]): Observable<File[]> {
    const body = {
      files: files,
    };
    return this.http
      .post<File[]>(`${this.rootAPI}/storage-plus/files/null/${StoragePlusAction.UNTRASH}`, body, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<File[]>("undoTrashData")));
  }

  public async undoScheduledTrash(files: File[]) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<string>((resolve) => {
      this.undoScheduledTrashData(files).subscribe((undo) => {
        if (undo) {
          resolve(undo);
        }
      });
    });
  }

  private undoScheduledTrashData(files: File[]): Observable<string> {
    const body = {
      files: files,
    };
    return this.http
      .post<string>(`${this.rootAPI}/storage-plus/files/null/${StoragePlusAction.UNSCHEDULE}`, body, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<string>("undoScheduledTrashData")));
  }

  public async deleteFile(files: File[], reason: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<File[]>((resolve) => {
      this.deleteFileData(files, reason).subscribe((file) => {
        if (file) {
          resolve(file);
        }
      });
    });
  }

  private deleteFileData(files: File[], reason: string): Observable<File[]> {
    const body = {
      reason: reason,
      files: files,
    };
    return this.http
      .post<File[]>(`${this.rootAPI}/storage-plus/files/null/${StoragePlusAction.DELETE}`, body, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<File[]>("deleteFileData")));
  }

  public async trashNow(files: File[], reason: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<File[]>((resolve) => {
      this.trashNowData(files, reason).subscribe((file) => {
        if (file) {
          resolve(file);
        } else {
          resolve(null);
        }
      });
    });
  }

  private trashNowData(files: File[], reason: string): Observable<File[]> {
    const body = {
      reason: reason,
      files: files,
    };
    return this.http
      .post<File[]>(`${this.rootAPI}/storage-plus/files/null/${StoragePlusAction.TRASH}`, body, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<File[]>("trashNowData")));
  }

  public async getStorageReportFilesTrashed(
    storageReportId: string,
    search = "",
    name = "",
    limit = "10",
    orderBy: string,
    order: string,
    offset: any = ""
  ) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<File[]>((resolve) => {
      this.getStorageReportFilesTrashedData(storageReportId, search, name, limit, orderBy, order, offset).subscribe(
        (files) => {
          if (files) {
            resolve(files);
          } else {
            resolve(null);
          }
        }
      );
    });
  }

  private getStorageReportFilesTrashedData(
    storageReportId: string,
    search = "",
    name = "",
    limit = "10",
    orderBy: string,
    order: string,
    offset: any = ""
  ): Observable<File[]> {
    return this.http
      .get<File[]>(this.apiUrl + "/filesDeleted/" + storageReportId, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams()
          .set("fileName", search)
          .set("email", name)
          .set("limit", limit)
          .set("orderBy", orderBy)
          .set("order", order)
          .set("offset", offset),
      })
      .pipe(catchError(this.authService.handleAPIError<File[]>("getStorageReportFilesTrashedData")));
  }

  public async transferFilesToGcp(params) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<string>((resolve) => {
      this.transferFilesToGcpRequest(params).subscribe((result) => {
        if (result) {
          resolve(result);
        }
      });
    });
  }

  private transferFilesToGcpRequest(params): Observable<string> {
    return this.http
      .post<string>(this.rootAPI + "/reports/storage/files/transfer-to-gcp", params, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<string>("transferFilesToGcpRequest")));
  }

  public async getBuckets() {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Bucket[]>((resolve) => {
      this.getBucketsRequest().subscribe((buckets) => {
        if (buckets) {
          resolve(buckets);
        } else {
          resolve([]);
        }
      });
    });
  }

  private getBucketsRequest(): Observable<Bucket[]> {
    return this.http
      .get<Bucket[]>(this.rootAPI + "/reports/storage/buckets", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Bucket[]>("getBucketsRequest")));
  }

  public async getBucketFolders(bucketName: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<BucketFolder[]>((resolve) => {
      this.getBucketFoldersRequest(bucketName).subscribe((bucketFolders) => {
        if (bucketFolders) {
          resolve(bucketFolders);
        } else {
          resolve([]);
        }
      });
    });
  }

  private getBucketFoldersRequest(bucketName: string): Observable<BucketFolder[]> {
    return this.http
      .get<Bucket[]>(this.rootAPI + "/reports/storage/files/bucket-folders" + "/" + bucketName, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<BucketFolder[]>("getBucketFoldersRequest")));
  }

  public async getFilePaths(fileId: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<FilePath[]>((resolve) => {
      this.getFilePathsRequest(fileId).subscribe((filePaths) => {
        if (filePaths) {
          resolve(filePaths);
        } else {
          resolve([]);
        }
      });
    });
  }

  private getFilePathsRequest(fileId: string): Observable<FilePath[]> {
    return this.http
      .get<FilePath[]>(`${this.rootAPI}/storage-plus/files/${fileId}/details`, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<FilePath[]>("getFilePathsRequest")));
  }
}

export interface Bucket {
  id: string;
  name: string;
}

export interface BucketFolder {
  name: string;
}

export interface TransferFile {
  id: string;
  fileId: string;
  name: string;
  googleId: string;
}

export interface Transfer {
  files: TransferFile[];
  drive: Drive;
  isDrive: boolean;
  isZip: boolean;
  zipFile: string;
  sendEmail: boolean;
  bucketName: string;
  folderPath: string;
  isMove: boolean;
}

export interface Drive {
  googleId: string;
  name: string;
  type: string;
}

export interface Options {
  fileName: string;
  googleId: string;
}

export interface FilePath {
  filePath: string;
  parts: FilePathPart[];
}

export interface FilePathPart {
  id: string;
  name: string;
  webViewLink: string;
}

export interface FileType {
  prefix: string;
  icon: string;
}
