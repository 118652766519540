<app-page-header [options]="options" (optionSelected)="optionSelected($event)"></app-page-header>
<div class="row" id="entireScreen">
  <div class="col-12">
    <app-storage-report-files-table
      *ngIf="this.storageReport.status.name != ''"
      (filesEvent)="receiveFilesEvent($event)"
      [storageReport]="storageReport"
      [options]="options"
    ></app-storage-report-files-table>
  </div>
</div>
