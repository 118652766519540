import { Component, OnInit, Inject, ElementRef, ViewChild } from "@angular/core";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { UsersService } from "src/app/services/admin-plus/users.service";
import { Group } from "src/app/groups/group";
import { NavService } from "src/app/services/admin-plus/nav.service";

@Component({
  selector: "app-group-memberships-dialog",
  templateUrl: "./group-memberships-dialog.component.html",
  styleUrls: ["./group-memberships-dialog.component.scss"],
})
export class GroupMembershipsDialogComponent implements OnInit {
  public groups: Group[] = [];
  public selected_groups: string[];
  public display: boolean;
  public removable: boolean = true;
  public email: string;
  public filteredOptions: Observable<any[]>;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public myControl = new UntypedFormControl();
  private groups_attempted = 0;
  public groups_failed_to_add = [];
  private groups_added = [];

  @ViewChild("userInput") userInput: ElementRef;

  constructor(
    private usersService: UsersService,
    private notifyService: NotificationService,
    private navService: NavService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<GroupMembershipsDialogComponent>
  ) {}

  ngOnInit(): void {
    this.selected_groups = [];
    this.getGroups();
  }

  public add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || "").trim()) {
      this.selected_groups.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }

    this.myControl.setValue(null);
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    this.selected_groups.push(event.option.value);
    this.userInput.nativeElement.value = "";
    this.myControl.setValue(null);
  }

  public remove(id: string): void {
    const index = this.selected_groups.indexOf(id);
    if (index >= 0) {
      this.selected_groups.splice(index, 1);
    }
  }

  async getGroups(pageToken: string = "") {
    this.navService.loading.next(true);
    let response = await this.usersService.getGroups(pageToken);
    if (response) {
      var groups = [];
      if (response["groups"]) {
        groups = response["groups"];
      }

      this.groups.push.apply(this.groups, groups);

      for (var i = this.groups.length - 1; i >= 0; i--) {
        this.groups[i]["visible"] = true;
        for (var j = 0; j < this.data.groups.length; j++) {
          if (this.data.id !== undefined) {
            if (this.data.id === this.groups[i].id) {
              this.groups[i]["visible"] = false;
            }
          }
          if (this.groups[i].id === this.data.groups[j].id) {
            this.groups[i]["visible"] = false;
          }
        }
      }

      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value))
      );

      if (!response["nextPageToken"]) {
      } else {
        this.getGroups(response["nextPageToken"]);
      }
    }
    this.navService.loading.next(false);
  }

  public async processForm() {
    this.navService.loading.next(true);
    for (var i = 0; i < this.selected_groups.length; i++) {
      await this.saveGroupMembership(this.selected_groups[i]);
    }
    this.navService.loading.next(false);
    this.dialogRef.close(this.groups_added);
  }

  public async saveGroupMembership(group: any) {
    var newMember: any = {
      email: this.usersService.viewingUser.primaryEmail,
      role: "MEMBER",
    };
    var response = await this.usersService.addGroupMembership(group.id, newMember);
    return new Promise((resolve) => {
      this.groups_attempted++;
      if (response) {
        group.directMembersCount++;
        this.groups_added.push(group);
        this.notifyService.notify("Successfully added the membership " + group.name);
        resolve(response);
      } else {
        this.groups_failed_to_add.push(group);
        this.notifyService.notify("The membership " + group.name + " could not be added");
        resolve(null);
      }
    });
  }

  private _filter(value: any): any[] {
    if (typeof value !== "object" && value !== null) {
      const filterValue = value.toLowerCase();

      return this.groups.filter((group) => group.email.indexOf(filterValue) === 0 && group.visible);
    }
  }
}
