<h2 mat-dialog-title>
    Reset Password
</h2>
<form (ngSubmit)="processForm()" autocomplete="off">
    <div mat-dialog-content>
        <div class="alert alert-info">
            {{ data.userKey }}  will have to set a new password after logging in with the temporary password.
        </div>
        <div class="row">
            <div class="col-12 mt-2">
                <mat-form-field class="w-100">
                    <mat-label>
                        Temporary Password
                    </mat-label>
                    <input 
                        matInput 
                        [(ngModel)]="password" 
                        type="text" 
                        name="password" 
                        id="password" 
                        placeholder="Temporary Password *" 
                        autocomplete="off" 
                        required>
                </mat-form-field>
            </div>
        </div>
        <app-dialog-actions-container></app-dialog-actions-container>
    </div>
</form>
