import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { AuthService } from "src/app/services/admin-plus/auth.service";
import { User } from "src/app/web/login/user";
import { DatePipe } from "@angular/common";
import { NotificationService } from "src/app/services/utilities/notification.service";

@Injectable({
  providedIn: "root",
})
export class ExportsheetsService {
  public user: User;
  public api_url_sheets = "https://sheets.googleapis.com/v4/spreadsheets";

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private notifyService: NotificationService
  ) {}

  async createSpreadsheet(name): Promise<string> {
    this.notifyService.notify("Please wait while this data is being exported. This could take a few moments.");
    const rawDate = new Date();
    const formatted = this.datePipe.transform(rawDate, "MMM d, y, h:mm:ss a");
    const userSpreadsheet = {
      sheets: [
        {
          properties: {
            gridProperties: {
              frozenRowCount: 1,
            },
          },
        },
      ],
      properties: {
        title: `Export ${name} ` + formatted,
      },
    };
    const response = await this.createNewGoogleSheet(userSpreadsheet);
    return new Promise((resolve) => {
      if (response) {
        resolve(response["spreadsheetId"]);
      }
    });
  }

  async addData(spreadsheetID: string, columns, array) {
    const newObject = await this.createSpreadsheetArray(columns, array);
    if (newObject) {
      await this.addExportData(newObject, spreadsheetID, "Sheet1!A1:G" + newObject["values"].length);
    }
  }

  async addExportData(dataObject, spreadsheetID: string, range: string): Promise<unknown> {
    this.user = await this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise((resolve) => {
      this.addExportDataSpreadsheet(dataObject, spreadsheetID, range).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private addExportDataSpreadsheet(dataObject, spreadsheetID: string, range: string): Observable<unknown> {
    return this.http
      .post<unknown>(this.api_url_sheets + "/" + spreadsheetID + "/values/" + range + ":append", dataObject, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
        params: new HttpParams().set("valueInputOption", "USER_ENTERED"),
      })
      .pipe(catchError(this.authService.handleAPIError<unknown>("addExportDataSpreadsheet")));
  }

  async createNewGoogleSheet(newGoogleSheet) {
    this.user = await this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise((resolve) => {
      this.createNewGoogleSheetData(newGoogleSheet).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private createNewGoogleSheetData(newGoogleSheet): Observable<unknown> {
    return this.http
      .post(this.api_url_sheets, newGoogleSheet, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<unknown>("createNewGoogleSheetData")));
  }

  public createSpreadsheetArray(keyNames, dataSource) {
    return new Promise((resolve) => {
      const values = [];
      let rows = [];
      const headers = [];
      for (let i = 0; i < keyNames.length; i++) {
        keyNames = keyNames.filter((column) => column !== "select");
        keyNames = keyNames.filter((column) => column !== "options");
        keyNames = keyNames.filter((column) => column !== "pictureUrl");
        keyNames = keyNames.filter((column) => column !== "pullAllFiles");
        keyNames = keyNames.filter((column) => column !== "user");
        keyNames = keyNames.filter((column) => column !== "Image");
      }
      for (let i = 0; i < keyNames.length; i++) {
        const result = keyNames[i]
          .replace(/([A-Z]+)/g, " $1")
          .replace(/([A-Z][a-z])/g, "$1")
          .replace(/([([])\s+|\s+([)\]])/g, "$1$2");
        let finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        if (
          finalResult.includes("Storage") ||
          finalResult.includes("Total") ||
          finalResult.includes("Trashed") ||
          finalResult.includes("Mb")
        ) {
          finalResult = finalResult.replace(" Mb", "") + " (GB)";
        }
        if (finalResult === "Group Key.id") {
          finalResult = "Email";
        }
        if (finalResult === "Preferred Member Key.id") {
          finalResult = "Email";
        }
        headers.push(finalResult);
      }

      values.push(headers);
      for (let i = 0; i < dataSource.length; i++) {
        for (let j = 0; j < keyNames.length; j++) {
          if (keyNames[j] === "groupKey.id") {
            rows.push(dataSource[i]["groupKey"]["id"]);
          } else if (keyNames[j] === "preferredMemberKey.id") {
            rows.push(dataSource[i]["preferredMemberKey"]["id"]);
          } else if (keyNames[j] === "courseGrades") {
            rows.push(this.average(dataSource[i][keyNames[j]]));
          } else if (keyNames[j] === "fullName") {
            const fullName = dataSource[i]["fullName"] ?? dataSource[i]["firstName"] + " " + dataSource[i]["lastName"];
            rows.push(fullName);
          } else if (keyNames[j].toLowerCase().includes("date") || keyNames[j].toLowerCase().includes("time")) {
            rows.push(this.datePipe.transform(dataSource[i][keyNames[j]], "MMM d, y, h:mm:ss a"));
          } else {
            if (
              keyNames[j].toLowerCase().includes("storage") ||
              keyNames[j].toLowerCase().includes("total") ||
              keyNames[j].toLowerCase().includes("trashed") ||
              keyNames[j].includes("Mb")
            ) {
              rows.push(parseFloat((dataSource[i][keyNames[j]] / 1024).toFixed(2)));
            } else {
              rows.push(dataSource[i][keyNames[j]]);
            }
          }
        }
        values.push(rows);
        rows = [];
      }

      const objectSheet = {
        values: values,
      };

      resolve(objectSheet);
    });
  }

  average(array: []) {
    if (!array) {
      return 0;
    }

    let sum = 0;
    for (let i = 0; i < array.length; i++) {
      sum += array[i];
    }

    return array.length > 0 ? sum / array.length : 0;
  }
}

@Injectable({
  providedIn: "root",
})
export class ImportSheetsService {
  public user: User;
  public api_url_sheets = "https://sheets.googleapis.com/v4/spreadsheets";

  constructor(
    private loginService: LoginService,
    private http: HttpClient,
    private authService: AuthService,
    private notifyService: NotificationService,
    private datePipe: DatePipe,
    private exportSheetsService: ExportsheetsService
  ) {}

  async createSpreadsheet(name, sheets): Promise<string> {
    this.notifyService.notify("Please wait while the spreadsheet is created. This could take a few moments.");
    const rawDate = new Date();
    const formatted = this.datePipe.transform(rawDate, "MMM d, y, h:mm:ss a");
    const userSpreadsheet = {
      sheets,
      properties: {
        title: `Import ${name}s ` + formatted,
      },
    };
    const response = await this.exportSheetsService.createNewGoogleSheet(userSpreadsheet);
    return new Promise((resolve) => {
      if (response) {
        resolve(response["spreadsheetId"]);
      }
    });
  }

  async importFromSheets(spreadsheetId) {
    this.user = await this.loginService.getUser();
    if (!this.user) {
      return;
    }
    return this.http
      .get(`${this.api_url_sheets}/${spreadsheetId}?includeGridData=true`, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<unknown>("importGoogleSheetData")))
      .toPromise();
  }
}
