import { Injectable } from "@angular/core";
import { User } from "src/app/web/login/user";
import { FileSelectorMimeType } from "src/enums/fileSelectorMimeType";

declare const gapi;
declare const google;

@Injectable({
	providedIn: "root",
})
export class GooglePickerService {
	private pickerCallback = null;
	private apiKey = "AIzaSyDFAi9Z3NSLt17VBMLFGX9XlqhuL2lzm-A";
	private projectNumber = "926417889780";
	private oauthAccessToken = null;
	private pickerApiLoaded = false;
	private picker = null;

	constructor(
		private user: User,
		private mimeType: FileSelectorMimeType = FileSelectorMimeType.AllFiles,
		private multiSelect: boolean = true
	) {}

	public async open(callback) {
		this.pickerCallback = callback;
		gapi.load("picker", {
			callback: await this.onPickerApiLoad.bind(this),
		});
	}

	public async dispose() {
		this.picker.dispose();
	}

	private async onPickerApiLoad() {
		this.pickerApiLoaded = true;
		this.oauthAccessToken = this.user.authToken;
		await this.createPicker();
	}

	private async createPicker() {
		if (this.pickerApiLoaded && this.oauthAccessToken) {
			if (this.picker == null) {
				// mapping between google picker mimeType and FileSelectorMimeType
				if (this.mimeType == FileSelectorMimeType.Folders) {
					// Folders
					const DisplayView = new google.picker.DocsView()
						.setIncludeFolders(true)
						.setMimeTypes("application/vnd.google-apps.folder")
						.setSelectFolderEnabled(true);
					if (this.multiSelect) {
						this.picker = new google.picker.PickerBuilder()
							.hideTitleBar()
							.addView(DisplayView)
							.setCallback(this.pickerCallback)
							.setAppId(this.projectNumber)
							.setOAuthToken(this.oauthAccessToken)
							.setDeveloperKey(this.apiKey)
							.enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
							.build();
					} else {
						this.picker = new google.picker.PickerBuilder()
							.hideTitleBar()
							.addView(DisplayView)
							.setCallback(this.pickerCallback)
							.setAppId(this.projectNumber)
							.setOAuthToken(this.oauthAccessToken)
							.setDeveloperKey(this.apiKey)
							.disableFeature(google.picker.Feature.MULTISELECT_ENABLED)
							.build();
					}
				} else if (this.mimeType == FileSelectorMimeType.SharedDrives) {
					// Shared Drives
					const DisplayView = new google.picker.DocsView().setEnableDrives(true);
					if (this.multiSelect) {
						this.picker = new google.picker.PickerBuilder()
							.hideTitleBar()
							.addView(DisplayView)
							.setCallback(this.pickerCallback)
							.setAppId(this.projectNumber)
							.setOAuthToken(this.oauthAccessToken)
							.setDeveloperKey(this.apiKey)
							.enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
							.enableFeature(google.picker.Feature.SUPPORT_DRIVE)
							.build();
					} else {
						this.picker = new google.picker.PickerBuilder()
							.hideTitleBar()
							.addView(DisplayView)
							.setCallback(this.pickerCallback)
							.setAppId(this.projectNumber)
							.setOAuthToken(this.oauthAccessToken)
							.setDeveloperKey(this.apiKey)
							.disableFeature(google.picker.Feature.MULTISELECT_ENABLED)
							.enableFeature(google.picker.Feature.SUPPORT_DRIVE)
							.build();
					}
				} else {
					// default, All Files
					if (this.multiSelect) {
						// multi select
						this.picker = new google.picker.PickerBuilder()
							.hideTitleBar()
							.addView(google.picker.ViewId.DOCS)
							.setCallback(this.pickerCallback)
							.setAppId(this.projectNumber)
							.setOAuthToken(this.oauthAccessToken)
							.setDeveloperKey(this.apiKey)
							.enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
							.build();
					} else {
						// single select
						this.picker = new google.picker.PickerBuilder()
							.hideTitleBar()
							.addView(google.picker.ViewId.DOCS)
							.setCallback(this.pickerCallback)
							.setAppId(this.projectNumber)
							.setOAuthToken(this.oauthAccessToken)
							.setDeveloperKey(this.apiKey)
							.disableFeature(google.picker.Feature.MULTISELECT_ENABLED)
							.build();
					}
				}
			}
			this.picker.setVisible(true);
		}
	}
}
