<h2 mat-dialog-title>
    Please enter your Organization's Name
</h2>
<form (ngSubmit)="submit()" autocomplete="off">
    <div mat-dialog-content>
        <mat-form-field class="w-100">
            <mat-label>Organization Name</mat-label>
            <input
              matInput
              placeholder="Organization Name"
              [(ngModel)]="customer.name"
              #input
              name="customer.name"
              type="text"
              required
            />
        </mat-form-field>
        <app-dialog-actions-container></app-dialog-actions-container>
    </div>
</form>
