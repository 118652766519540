import { Injectable } from "@angular/core";
import { Payload } from "src/app/services/utilities/utility.service";
import { TicketPlusService } from "src/app/services/ticket-plus/ticket-plus.service";

@Injectable({
  providedIn: "root",
})
export class EmailService extends TicketPlusService {
  async sendEmail(params = {}): Promise<Payload> {
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("GET", `/ticket-plus/emails/test/send`, params, null, true).subscribe((payload) => {
        resolve(payload);
      });
    });
  }

  async readEmails(params = {}): Promise<Payload> {
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("GET", `/ticket-plus/emails/test/read`, params, null, true).subscribe((payload) => {
        resolve(payload);
      });
    });
  }

  async deleteEmails(params = {}): Promise<Payload> {
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("GET", `/ticket-plus/emails/test/delete`, params, null, true).subscribe((payload) => {
        resolve(payload);
      });
    });
  }
  async checkTicketMailbox(): Promise<Payload> {
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("POST", `/ticket-plus/emails/checkTicketMailbox`, {}, {}).subscribe((payload) => {
        resolve(payload);
      });
    });
  }
}
