<h2 mat-dialog-title class="d-flex align-items-center" style="gap: 8px">
  <mat-icon>warning</mat-icon>
  {{ title }}
</h2>
<div mat-dialog-content>
  {{ message }}
  <div mat-dialog-actions class="px-0">
    <button *ngIf="btnCancelText" mat-stroked-button mat-dialog-close class="">
      {{ btnCancelText }}
    </button>
    <button mat-stroked-button class="success ml-2" (click)="onConfirm()">
      {{ btnOkText }}
    </button>
  </div>
</div>
