import { Component, OnInit, Input } from "@angular/core";
import { AuthService } from "src/app/services/admin-plus/auth.service";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { UntypedFormControl } from "@angular/forms";
import { CustomerService, CustomerModule } from "src/app/services/admin-plus/customer.service";
import { ServiceAccountService, Project, ServiceAccount } from "src/app/services/admin-plus/service-account.service";
import { environment } from "src/environments/environment";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { EmailsService } from "src/app/services/storage-plus/emails.service";
import { AutoAddErrorDialogComponent } from "../../auto-add-error-dialog/auto-add-error-dialog.component";
import { Subscription } from "rxjs";

@Component({
  selector: "app-automatic-add-sa",
  templateUrl: "./automatic-add-sa.component.html",
  styleUrls: ["./automatic-add-sa.component.scss"],
})
export class AutomaticAddSaComponent implements OnInit {
  @Input() data = null;
  @Input() dialogRef: MatDialogRef<unknown> = null;
  cloudScope = "https://www.googleapis.com/auth/cloud-platform";
  advanced = false;
  saving = false;

  modules: CustomerModule[] = [];
  selectedModules: CustomerModule[] = [];
  linkModules = "";

  projects = new UntypedFormControl("[]");
  projectsList: Project[] = null;
  newProject = "";
  newProjectInput = true;

  serviceAccounts = new UntypedFormControl("[]");
  serviceAccountsList: Project[] = null;
  newServiceAccount = "";
  newServiceAccountInput = true;

  newProjectName = "";
  newServiceAccountName = "";

  autoSA: ServiceAccount = {
    id: null,
    projectId: null,
    projectName: null,
    clientId: null,
    name: null,
    delegateEmail: null,
    mailbox: {
      email: null,
      created: null,
      autoConfigure: null,
      lastCheck: null,
    },
  };

  refreshListener: Subscription;

  constructor(
    public authService: AuthService,
    public loginService: LoginService,
    private serviceAccountService: ServiceAccountService,
    private customerService: CustomerService,
    private navService: NavService,
    private notificationService: NotificationService,
    private emailService: EmailsService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.modules = this.data.modules.filter((m: CustomerModule) => m.requiresServiceAccount && !m.serviceAccountId);
    for (const module of this.modules) {
      module["selected"] = true;
      this.moduleSelected(module);
    }

    this.loadData();
  }

  async loadData() {
    if (this.hasCloudAccess()) {
      this.projectsList = await this.serviceAccountService.listProjects();
      const d = new Date();
      const timestampString = d.getHours() + +"" + d.getMinutes() + "" + d.getSeconds();
      this.autoSA.projectName = (!environment.production ? "dev-" : "") + "kc-caching-p-" + timestampString;
      this.autoSA.projectId = this.autoSA.projectName;
      this.newProject = this.autoSA.projectName;
      //this.autoSA.projectId = 'dev-kc-caching-p-4042';
      this.autoSA.name = (!environment.production ? "dev-" : "") + "kc-caching-sa-" + timestampString;
      this.newServiceAccount = this.autoSA.name;
      //this.autoSA.serviceAccountClientId = '107127074634919540254';
      this.autoSA.delegateEmail = this.loginService.user.email;
      this.autoSA.mailbox.email = "tickets@" + this.loginService.user.email.split("@")[1];
    }
  }

  elevate() {
    const scopes = [this.cloudScope];
    window.location.href = this.authService.elevateAccessToken(this.loginService.user.googleID, scopes, "service-account");
  }

  hasCloudAccess(): boolean {
    const scopes = this.authService.getScopes(this.loginService.user.permissions, "google", true, null);
    for (const scope of scopes) {
      if (scope == this.cloudScope) {
        return true;
      }
    }
    return false;
  }

  async projectSelect(value: string) {
    this.newProjectInput = value == "[]";

    if (value !== "[]") {
      this.autoSA.projectId = value;
      this.serviceAccountsList = await this.serviceAccountService.listServiceAccounts(value);
    } else {
      this.autoSA.projectId = this.autoSA.projectName;
      this.newProject = this.newProject || this.autoSA.projectName;
      this.serviceAccountsList = null;
      this.serviceAccounts.setValue("[]");
      this.serviceAccountSelect(value);
    }
  }

  async serviceAccountSelect(value: string) {
    this.newServiceAccountInput = value == "[]";

    if (value !== "[]") {
      this.autoSA.clientId = value;
    } else {
      this.autoSA.clientId = "";
      this.newServiceAccount = this.newServiceAccount || this.autoSA.name;
    }
  }

  moduleSelected(module: CustomerModule, event = null) {
    if (!this.selectedModules.includes(module)) {
      this.selectedModules.push(module);
    } else {
      this.selectedModules = this.selectedModules.filter((m) => m != module);
    }
    if (event) this.modules = this.modules.map((m) => (m.name == module.name ? { ...m, ["selected"]: event.checked } : m));

    this.linkModules = this.modules
      .filter((m) => m["selected"])
      .map((m: CustomerModule) => m.name)
      .join(", ");
  }

  async setupService() {
    if (!this.autoSA.delegateEmail || !this.emailService.isValidEmail(this.autoSA.delegateEmail)) {
      const delegateError = !this.autoSA.delegateEmail ? "Default Email is required" : "Default Email is invalid";
      this.notificationService.notify(delegateError);
      return;
    }
    this.saving = true;
    this.navService.loading.next(true);
    const result = await this.serviceAccountService.autoServiceAccount(this.autoSA);
    if (result && !result["error"]) {
      if (this.selectedModules.length > 0) {
        for (const selectedModule of this.selectedModules) {
          await this.customerService.linkCustomerModule("link", selectedModule.subscriptionModuleId, result.id);
        }

        this.customerService.loadCustomerModules();
      }
      this.dialogRef.close("test");
    } else {
      this.openAutomaticErrorDialog(result["error"]["message"]);
    }

    this.saving = false;
    this.navService.loading.next(false);
  }

  openAutomaticErrorDialog(message: string) {
    const dialogRef = this.dialog.open(AutoAddErrorDialogComponent, {
      width: "500px",
      data: {
        message: message,
      },
    });
    dialogRef.componentInstance.retryAdd.subscribe(() => {
      this.setupService();
    });
    dialogRef.afterClosed().subscribe(() => {
      dialogRef.componentInstance.retryAdd.unsubscribe();
      // unsubscribe onAdd
    });
  }

  toggleAdvanced() {
    this.advanced = !this.advanced;
  }
}
