import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { environment } from "src/environments/environment";
import { AuthService } from "src/app/services/admin-plus/auth.service";

@Injectable({
  providedIn: "root",
})
export class ReportsService {
  private apiUrl = "https://www.googleapis.com/admin/reports/v1/";
  private reportsApi: string = environment.apiUrl + "/reports";
  private usageApiUrl = "https://admin.googleapis.com/admin/reports/v1/usage/dates/";
  private user;
  reportHistoricalData: any[] = null;
  userHistoricalData: any[][] = []; //needs to be initialized empty

  constructor(private http: HttpClient, private loginService: LoginService, private authService: AuthService) {}

  async getScheduledReports(): Promise<ScheduledReport[]> {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise((resolve) => {
      this.getScheduledReportsData().subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getScheduledReportsData(): Observable<ScheduledReport[]> {
    return this.http
      .get<ScheduledReport[]>(this.reportsApi + "/storage/schedule-reports", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<ScheduledReport[]>("getScheduledReportsData")));
  }

  public async scheduleRunReport(week: Week, emailAddresses: Email[]) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Week>((resolve) => {
      this.scheduleRunReportData(week, emailAddresses).subscribe((scheduleReport) => {
        if (scheduleReport) {
          resolve(scheduleReport);
        } else {
          resolve(null);
        }
      });
    });
  }

  private scheduleRunReportData(week: Week, emailAddresses: Email[]): Observable<Week> {
    const postedObject = {
      week: week.days,
      emailAddresses: JSON.stringify(emailAddresses),
    };
    return this.http
      .post<Week>(this.reportsApi + "/storage/schedule-report", postedObject, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Week>("scheduleRunReportData")));
  }

  public async getActivities(
    userId = "all",
    applicationName = "user_accounts",
    startDate: any = "",
    endDate: any = "",
    eventName,
    filters
  ) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Reports>((resolve) => {
      this.getActivityData(userId, applicationName, startDate, endDate, eventName, filters).subscribe((activities) => {
        if (activities) {
          resolve(activities);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getActivityData(
    userId: string,
    applicationName: string,
    startDate: any,
    endDate: any,
    eventName: any,
    filters: any
  ): Observable<Reports> {
    if (eventName !== "" && filters !== "") {
      return this.http
        .get<Reports>(this.apiUrl + "activity/users/" + userId + "/applications/" + applicationName, {
          headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
          params: new HttpParams()
            .set("startTime", startDate)
            .set("endTime", endDate)
            .set("eventName", eventName)
            .set("filters", filters),
        })
        .pipe(catchError(this.authService.handleAPIError<Reports>("getActivityData")));
    }
    if (eventName !== "") {
      return this.http
        .get<Reports>(this.apiUrl + "activity/users/" + userId + "/applications/" + applicationName, {
          headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
          params: new HttpParams().set("startTime", startDate).set("endTime", endDate).set("eventName", eventName),
        })
        .pipe(catchError(this.authService.handleAPIError<Reports>("getActivityData")));
    }
    if (filters !== "") {
      return this.http
        .get<Reports>(this.apiUrl + "activity/users/" + userId + "/applications/" + applicationName, {
          headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
          params: new HttpParams().set("startTime", startDate).set("endTime", endDate).set("filters", filters),
        })
        .pipe(catchError(this.authService.handleAPIError<Reports>("getActivityData")));
    }
    if (eventName === "" && filters === "") {
      return this.http
        .get<Reports>(this.apiUrl + "activity/users/" + userId + "/applications/" + applicationName, {
          headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
          params: new HttpParams().set("startTime", startDate).set("endTime", endDate),
        })
        .pipe(catchError(this.authService.handleAPIError<Reports>("getActivityData")));
    }
  }

  public async getUsage(date: string, applicationName = "classroom", report = "num_14day_active_courses") {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Reports>((resolve) => {
      this.getUsageReport(date, applicationName, report).subscribe((activities) => {
        if (activities) {
          resolve(activities);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getUsageReport(date: string, applicationName: string, report: string): Observable<Reports> {
    return this.http
      .get<Reports>(this.usageApiUrl + "/dates/" + date, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
        params: new HttpParams().set("parameters", applicationName + ":" + report),
      })
      .pipe(catchError(this.authService.handleAPIError<Reports>("getUsageReport")));
  }

  public async getUserUsage(userKey = "all", date: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Reports>((resolve) => {
      this.getUserUsageReport(userKey, date).subscribe((usage) => {
        if (usage) {
          resolve(usage);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getUserUsageReport(userKey: string, date: string): Observable<Reports> {
    return this.http
      .get<Reports>(this.usageApiUrl + "/users/" + userKey + "/dates/" + date, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Reports>("getUserUsageReport")));
  }

  /* Reports Module... */
  public async getStorageReport() {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<StorageReport>((resolve) => {
      this.getStorageReportData().subscribe((storageReport) => {
        if (storageReport) {
          resolve(storageReport);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getStorageReportData(): Observable<StorageReport> {
    return this.http
      .get<StorageReport>(this.reportsApi + "/storage", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<StorageReport>("getStorageReportData")));
  }

  public async createStorageReport(storageReport: StorageReport) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<StorageReport>((resolve) => {
      this.createStorageReportData(storageReport).subscribe((storageReport) => {
        if (storageReport) {
          resolve(storageReport);
        } else {
          resolve(null);
        }
      });
    });
  }

  private createStorageReportData(storageReport: StorageReport): Observable<StorageReport> {
    return this.http
      .post<StorageReport>(this.reportsApi + "/storage", storageReport, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<StorageReport>("createStorageReportData")));
  }

  public async updateStorageReport(storageReport: StorageReport) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<StorageReport>((resolve) => {
      this.updateStorageReportData(storageReport).subscribe((storageReport) => {
        if (storageReport) {
          resolve(storageReport);
        } else {
          resolve(null);
        }
      });
    });
  }

  private updateStorageReportData(storageReport: StorageReport): Observable<StorageReport> {
    return this.http
      .patch<StorageReport>(this.reportsApi + "/storage", storageReport, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<StorageReport>("updateStorageReportData")));
  }

  public async getAuditLogs(
    limit = 10,
    offset = 0,
    search = "",
    startDate = "",
    endDate = "",
    activeOrderBy = "",
    direction = "",
    adminSearch = "",
    typeSearch = "",
    userSearch = "",
    fileSearch = "",
    noteSearch = "",
    getCount = true
  ) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<AuditLog[]>((resolve) => {
      this.getAuditLogData(
        limit,
        offset,
        search,
        startDate,
        endDate,
        activeOrderBy,
        direction,
        adminSearch,
        typeSearch,
        userSearch,
        fileSearch,
        noteSearch,
        getCount
      ).subscribe((auditLogs) => {
        if (auditLogs) {
          resolve(auditLogs);
        }
      });
    });
  }

  private getAuditLogData(
    limit = 10,
    offset = 0,
    search = "",
    startDate = "",
    endDate = "",
    activeOrderBy = "",
    direction = "",
    adminSearch = "",
    typeSearch = "",
    userSearch = "",
    fileSearch = "",
    noteSearch = "",
    getCount = true
  ): Observable<AuditLog[]> {
    return this.http
      .get<AuditLog[]>(this.reportsApi + "/storage/audit-logs", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams()
          .set("limit", limit.toString())
          .set("offset", offset.toString())
          .set("search", search)
          .set("startDate", startDate.toString())
          .set("endDate", endDate.toString())
          .set("orderBy", activeOrderBy)
          .set("order", direction)
          .set("admin", adminSearch)
          .set("type", typeSearch)
          .set("user", userSearch)
          .set("file", fileSearch)
          .set("note", noteSearch)
          .set("getCount", getCount.toString()),
      })
      .pipe(catchError(this.authService.handleAPIError<AuditLog[]>("getAuditLogData")));
  }

  async getStorageReportUsers(
    limit = 10,
    orderBy: string,
    order: string,
    firstNameSearch: string,
    lastNameSearch: string,
    emailSearch: string,
    ouSearch: string,
    offset: number,
    flagged = "all",
    usageSize = "",
    getCount = true,
    ouId?: number,
    daysFlagged = "",
    flaggedDateBefore = ""
  ) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<UserData>((resolve) => {
      this.getStorageReportUsersData(
        limit,
        orderBy,
        order,
        firstNameSearch,
        lastNameSearch,
        emailSearch,
        ouSearch,
        offset,
        flagged,
        usageSize,
        getCount,
        ouId,
        daysFlagged,
        flaggedDateBefore
      ).subscribe((users) => {
        if (users) {
          resolve(users);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getStorageReportUsersData(
    limit = 10,
    orderBy: string,
    order: string,
    firstNameSearch: string,
    lastNameSearch: string,
    emailSearch: string,
    ouSearch: string,
    offset: number,
    flagged = "all",
    usageSize = "",
    getCount = true,
    ouId?: number,
    daysFlagged = "",
    flaggedDateBefore = ""
  ): Observable<UserData> {
    return this.http
      .get<UserData>(this.reportsApi + "/storage/users", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams()
          .set("limit", limit.toString())
          .set("orderBy", orderBy)
          .set("order", order)
          .set("firstNameSearch", firstNameSearch)
          .set("lastNameSearch", lastNameSearch)
          .set("emailSearch", emailSearch)
          .set("ouSearch", ouSearch)
          .set("offset", offset.toString())
          .set("flagged", flagged)
          .set("usageSize", usageSize)
          .set("getCount", getCount.toString())
          .set("ouId", ouId.toString())
          .set("daysFlagged", daysFlagged)
          .set("flaggedDateBefore", flaggedDateBefore),
      })
      .pipe(catchError(this.authService.handleAPIError<UserData>("getStorageReportUsersData")));
  }

  async getStorageReportUser(userId: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<User>((resolve) => {
      this.getStorageReportUserData(userId).subscribe((user) => {
        if (user) {
          resolve(user);
        } else {
          resolve({});
        }
      });
    });
  }

  private getStorageReportUserData(userId: string): Observable<User> {
    return this.http
      .get<User>(this.reportsApi + "/storage/users/" + userId, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<User>("getStorageReportUserData")));
  }

  async getDuplicateFiles(
    limit = 10,
    orderBy: string,
    order: string,
    offset = 0,
    md5ChecksumSearch = "",
    usageSizeSearch = "",
    getCount = true
  ) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<FileData>((resolve) => {
      this.getDuplicateFilesData(limit, orderBy, order, offset, md5ChecksumSearch, usageSizeSearch, getCount).subscribe(
        (files) => {
          if (files) {
            resolve(files);
          } else {
            resolve(null);
          }
        }
      );
    });
  }

  private getDuplicateFilesData(
    limit = 10,
    orderBy: string,
    order: string,
    offset = 0,
    md5ChecksumSearch = "",
    usageSizeSearch = "",
    getCount = true
  ): Observable<FileData> {
    return this.http
      .get<FileData>(this.reportsApi + "/storage/files/duplicates", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams()
          .set("md5ChecksumSearch", md5ChecksumSearch)
          .set("usageSize", usageSizeSearch)
          .set("limit", limit.toString())
          .set("orderBy", orderBy)
          .set("order", order)
          .set("offset", offset.toString())
          .set("getCount", getCount.toString()),
      })
      .pipe(catchError(this.authService.handleAPIError<FileData>("getDuplicateFilesData")));
  }

  async getStorageReportFiles(
    limit = 10,
    orderBy: string,
    order: string,
    firstNameSearch = "",
    lastNameSearch = "",
    emailSearch = "",
    mimeTypeSearch = "",
    name = "",
    offset = 0,
    trashStatus: any,
    userId = 0,
    usageSize = "",
    driveId = 0,
    sharedDriveSearch = "",
    ouSearch = "",
    fileNameSearch = "",
    getCount = true,
    lastViewedBefore = "",
    lastViewedAfter = "",
    md5Checksum = "",
    daysFlagged = "",
    flaggedDateBefore = "",
    ownerIsSuspended: any = null
  ) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<FileData>((resolve) => {
      this.getStorageReportFileData(
        limit,
        orderBy,
        order,
        firstNameSearch,
        lastNameSearch,
        emailSearch,
        mimeTypeSearch,
        name,
        offset,
        trashStatus,
        userId,
        usageSize,
        driveId,
        sharedDriveSearch,
        ouSearch,
        fileNameSearch,
        getCount,
        lastViewedBefore,
        lastViewedAfter,
        md5Checksum,
        daysFlagged,
        flaggedDateBefore,
        ownerIsSuspended
      ).subscribe((files) => {
        if (files) {
          resolve(files);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getStorageReportFileData(
    limit = 10,
    orderBy: string,
    order: string,
    firstNameSearch = "",
    lastNameSearch = "",
    emailSearch = "",
    mimeTypeSearch = "",
    name = "",
    offset = 0,
    trashStatus: any,
    userId = 0,
    usageSize = "",
    driveId = 0,
    sharedDriveSearch = "",
    ouSearch = "",
    fileNameSearch = "",
    getCount = true,
    lastViewedBefore = "",
    lastViewedAfter = "",
    md5Checksum = "",
    daysFlagged = "",
    flaggedDateBefore = "",
    ownerIsSuspended: any
  ): Observable<FileData> {
    return this.http
      .get<FileData>(this.reportsApi + "/storage/files", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams()
          .set("firstNameSearch", firstNameSearch)
          .set("lastNameSearch", lastNameSearch)
          .set("emailSearch", emailSearch)
          .set("fileNameSearch", fileNameSearch)
          .set("mimeType", mimeTypeSearch)
          .set("user", name)
          .set("limit", limit.toString())
          .set("orderBy", orderBy)
          .set("order", order)
          .set("offset", offset.toString())
          .set("hideTrashed", trashStatus)
          .set("userId", userId ? userId.toString() : "")
          .set("usageSize", usageSize)
          .set("driveId", driveId.toString())
          .set("sharedDriveSearch", sharedDriveSearch)
          .set("ouSearch", ouSearch)
          .set("getCount", getCount.toString())
          .set("lastViewedBefore", lastViewedBefore)
          .set("lastViewedAfter", lastViewedAfter)
          .set("md5Checksum", md5Checksum)
          .set("daysFlagged", daysFlagged)
          .set("flaggedDateBefore", flaggedDateBefore)
          .set("ownerIsSuspended", ownerIsSuspended),
      })
      .pipe(catchError(this.authService.handleAPIError<FileData>("getStorageReportFileData")));
  }

  public async getHistorical(storageReportId: string, id = "") {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Historical[]>((resolve) => {
      this.getHistoricalData(storageReportId, id).subscribe((historical) => {
        if (historical) {
          resolve(historical);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getHistoricalData(storageReportId: string, id = ""): Observable<Historical[]> {
    return this.http
      .get<Historical[]>(this.reportsApi + "/storage/" + storageReportId + "/historical", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams().set("id", id),
      })
      .pipe(catchError(this.authService.handleAPIError<Historical[]>("getHistoricalData", true)));
  }
  public async getOUStorage() {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<OrganizationalUnit[]>((resolve) => {
      this.getOUStorageData().subscribe((data) => {
        if (data) {
          resolve(data);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getOUStorageData(): Observable<OrganizationalUnit[]> {
    return this.http
      .get<OrganizationalUnit[]>(this.reportsApi + "/storage/ous", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<OrganizationalUnit[]>("getOUStorageData")));
  }

  public async getMimeTypes(
    firstName = "",
    lastName = "",
    email = "",
    usageSize = "",
    lastViewedBefore = "",
    lastViewedAfter = "",
    fileName = "",
    fileType = "",
    trashType: any,
    sharedDrive = "",
    driveId = "",
    ouSearch = "",
    md5ChecksumSearch = "",
    filterType = ""
  ) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<any>((resolve) => {
      this.getMimeTypesData(
        firstName,
        lastName,
        email,
        usageSize,
        lastViewedBefore,
        lastViewedAfter,
        fileName,
        fileType,
        trashType,
        sharedDrive,
        driveId,
        ouSearch,
        md5ChecksumSearch,
        filterType
      ).subscribe((mimeTypes) => {
        if (mimeTypes) {
          resolve(mimeTypes);
        }
      });
    });
  }

  private getMimeTypesData(
    firstName = "",
    lastName = "",
    email = "",
    usageSize = "",
    lastViewedBefore = "",
    lastViewedAfter = "",
    fileName = "",
    fileType = "",
    trashType: any,
    sharedDrive = "",
    driveId = "",
    ouSearch = "",
    md5ChecksumSearch = "",
    filterType = ""
  ): Observable<any> {
    return this.http
      .get<any>(this.reportsApi + "/storage/mimeTypes", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams()
          .set("firstName", firstName)
          .set("lastName", lastName)
          .set("email", email)
          .set("usageSize", usageSize)
          .set("lastViewedBefore", lastViewedBefore)
          .set("lastViewedAfter", lastViewedAfter)
          .set("fileName", fileName)
          .set("fileType", fileType)
          .set("trashType", trashType)
          .set("sharedDrive", sharedDrive)
          .set("driveId", driveId)
          .set("ouSearch", ouSearch)
          .set("md5ChecksumSearch", md5ChecksumSearch)
          .set("filterType", filterType),
      })
      .pipe(catchError(this.authService.handleAPIError<any>("getMimeTypesData")));
  }

  public buildDates(startDate: Date, range: number, length: number): string[] {
    const dates: string[] = [];
    let lastDate: Date;
    for (let i = 0; i < length; i++) {
      const newDate = !lastDate
        ? startDate
        : new Date(lastDate.getFullYear(), lastDate.getMonth(), lastDate.getDate() - range);

      dates.push(this.makeDateString(newDate));
      lastDate = newDate;
    }

    return dates;
  }

  public async buildReport(reportId: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise<any>((resolve) => {
      this.buildReportData(reportId).subscribe((job) => {
        if (job) {
          resolve(job);
        }
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private buildReportData(reportId: string): Observable<any> {
    return (
      this.http
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .get<any>(this.reportsApi + "/storage/" + reportId + "/buildReport", {
          //please don't pass email addresses in URLs - JRB 2021-04-30
          headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .pipe(catchError(this.authService.handleAPIError<any>("buildReportData")))
    );
  }

  private makeDateString(date: Date): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
  }

  public async getTopOUs() {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<StorageReport[]>((resolve) => {
      this.getTopOUsRequest().subscribe((ous: StorageReport[]) => {
        if (ous) {
          resolve(ous);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getTopOUsRequest(): Observable<StorageReport[]> {
    return this.http
      .get<StorageReport[]>(this.reportsApi + "/storage/get-top-ous", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<StorageReport[]>("getTopOUsRequest")));
  }

  public async getTopUserOu() {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<StorageReport[]>((resolve) => {
      this.getTopUserOuRequest().subscribe((ous: StorageReport[]) => {
        if (ous) {
          resolve(ous);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getTopUserOuRequest(): Observable<StorageReport[]> {
    return this.http
      .get<StorageReport[]>(this.reportsApi + "/storage/get-top-user-ou", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<StorageReport[]>("getTopUserOuRequest")));
  }

  public async getOuById(ouId: number) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<OU>((resolve) => {
      this.getOuByIdRequest(ouId).subscribe((ou: OU) => {
        if (ou) {
          resolve(ou);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getOuByIdRequest(ouId: number): Observable<OU> {
    return this.http
      .get<OU>(this.reportsApi + "/storage/ou/" + ouId, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<OU>("getOuByIdRequest")));
  }

  public async getFilteredOus(
    limit = 10,
    orderBy: string,
    order: string,
    nameSearch: string,
    usageSizeSearch = "",
    offset: number,
    getCount = true,
    topOUs = false
  ) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<OrganizationalUnitData>((resolve) => {
      this.getFilteredOusRequest(limit, orderBy, order, nameSearch, usageSizeSearch, offset, getCount, topOUs).subscribe(
        (ous) => {
          if (ous) {
            resolve(ous);
          } else {
            resolve(null);
          }
        }
      );
    });
  }

  private getFilteredOusRequest(
    limit: number,
    orderBy: string,
    order: string,
    nameSearch: string,
    usageSizeSearch: string,
    offset = 0,
    getCount = true,
    topOUs = false
  ): Observable<OrganizationalUnitData> {
    return this.http
      .get<OrganizationalUnitData>(this.reportsApi + "/storage/filtered-ous", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams()
          .set("limit", limit.toString())
          .set("orderBy", orderBy)
          .set("order", order)
          .set("nameSearch", nameSearch)
          .set("usageSizeSearch", usageSizeSearch)
          .set("offset", offset.toString())
          .set("getCount", getCount.toString())
          .set("topOUs", topOUs.toString()),
      })
      .pipe(catchError(this.authService.handleAPIError<OrganizationalUnitData>("getFilteredOusRequest")));
  }

  calculateUnit(totalStorage): string {
    let unit = " MB";
    if (Math.floor(totalStorage / 1024) > 0) {
      unit = "GB";
      if (Math.floor(totalStorage / 1048576) > 0) {
        unit = "TB";
        if (Math.floor(totalStorage / 1073741824) > 0) {
          unit = "PB";
        }
      }
    }
    return unit;
  }

  calculateValue(totalStorage, unit): number {
    let convertedTotalStorage = 0;
    if (unit === "GB") {
      convertedTotalStorage = totalStorage / 1024;
    } else if (unit === "TB") {
      convertedTotalStorage = totalStorage / 1048576;
    } else if (unit === "PB") {
      convertedTotalStorage = totalStorage / 1073741824;
    } else {
      convertedTotalStorage = totalStorage;
    }
    return convertedTotalStorage;
  }
}

export interface Reports {
  etag: string;
  items: Item[];
  usageReports: UsageReport[];
  kind: string;
}

export interface OU {
  id: number;
  name: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Item {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UsageReport {}

export interface ServiceAccounts {
  serviceAccounts: ServiceAccount[];
}

export interface ServiceAccount {
  id?: string;
  created?: string;
  customerId?: string;
  accessKey?: string;
  serviceEmail?: string;
  delegateEmail?: string;
  type?: ServiceAccountType;
  testedDate?: string;
}

export interface ServiceAccountType {
  id?: string;
  name: string;
}

export interface StorageReport {
  id?: string;
  created?: string;
  customerId?: number;
  status: Status;
  name?: string;
  description?: string;
  lastRun?: string;
  syncError?: string;
  totalUsers: number;
  totalStorage: number;
  totalDrive: number;
  totalSharedDrive?: number;
  totalGmail: number;
  totalPhotos: number;
  previousTotal?: number;
  enableScheduled?: boolean;
  allowDelete?: boolean;
  enableTenant?: boolean;
  usageLimit?: number;
  filesSyncing?: string;
  filesSynced?: string;
  drivesSynced?: string;
  driveUsersSynced?: string;
  driveFilesSynced?: string;
  email?: string;
  buildId?: string;
  firstName?: string;
  lastName?: string;
  OU?: string;
}

export interface Jobs {
  users: Job;
  historical: Job;
  storage: Job;
  files: Job;
}

export interface Job {
  start: string;
  end: string;
  status: string;
  elapsed: number;
}

export interface Status {
  id: number;
  name: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UserData {}

export interface Users {
  users: User[];
}

export interface UserFiles {
  files: File;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FileData {}

export interface Files {
  file: File[];
}

export interface File {
  id?: string;
  created?: string;
  createdDate?: string;
  viewedByMeTime?: string;
  movedToTrashDate?: string;
  scheduledDateTime?: string;
  modifiedDate?: string;
  googleId?: string;
  user?: string;
  mimeType?: string;
  fileId?: string;
  name?: string;
  driveName?: string;
  driveId?: string;
  reportId?: number;
  storageMb: number;
  userId?: number;
  fileCount?: number;
  busy?: number;
  error?: number;
  days?: string;
  reason?: string;
}

export interface User {
  id?: number;
  created?: string;
  googleId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  fullName?: string;
  totalDrive?: number;
  totalGmail?: number;
  totalPhotos?: number;
  totalStorage?: number;
  suspended?: boolean;
  deleted?: boolean;
  driveTotal?: number;
  gmailTotal?: number;
  photosTotal?: number;
  flagId?: number;
  filesSyncing?: string;
  filesSynced?: string;
  userCount?: number;
  nonTrashedStorage?: string;
  trashedStorage?: number;
  previousTotal?: number;
  previousTrashed?: number;
  OU?: string;
  fileTransfer?: number;
  partialFiles?: number;
  pictureUrl?: string;
}

export interface Historical {
  reportDate: string;
  totalStorage: number;
  totalDrive: number;
  totalGmail: number;
  totalPhotos: number;
}
export interface OrganizationalUnit {
  name: string;
  totalStorage: number;
}

export interface AuditLog {
  id: number;
  created: Date;
  reportId: number;
  typeId: number;
  userId: number;
  googleId: string;
  fileId: string;
  note: string;
  userEmail: string;
  fileName: string;
  auditEmail: string;
}
export interface OrganizationalUnitData {
  count: number;
  data: OrganizationalUnit[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AuditLogData {}

export interface AuditLogType {
  id: number;
  name: string;
}

export interface Week {
  days: Day[];
}

export interface Day {
  name: string;
  dayOfWeek: number;
  offsetDayOfWeek: number;
  time: string;
  offsetTime: string;
  checked: boolean;
  changeDayOfWeek: boolean;
}

export interface ScheduledReport {
  id: number;
  dayOfWeek: number;
  reportId: number;
  time: string;
}

export interface Email {
  name: string;
}

export interface MimeTypes {
  mimeTypes: MimeType[];
}

export interface MimeType {
  mimeType: string;
}
