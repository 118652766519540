import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'attachment',
	templateUrl: "attachment.component.html",
	styleUrls: ["attachment.component.scss"]
})
export class AttachmentComponent {

	constructor(
		public matDialog: MatDialog,
		private dialogRef: MatDialogRef<AttachmentComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) { }
}