import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "app-table-footer",
  templateUrl: "./table-footer.component.html",
  styleUrls: ["./table-footer.component.scss"],
})
export class TableFooterComponent {
  @Input() dataSource;
  @Input() maxResults: number;
  @Input() nextButton = false;
  @Input() nextButtonDis = false;
  @Input() offset: number;
  @Input() page: number;
  @Input() total: number;
  @Input() nextPageToken: string;
  @Input() prevPageToken: string;
  @Output("pager") pager: EventEmitter<unknown> = new EventEmitter();
  public actionsMaxResult = new UntypedFormControl();
  private pagerData = {
    arrow: "",
    page: 1,
    offset: 0,
    maxResults: 0,
  };
  @Input() disabled: boolean;
  public numPages: number;

  constructor() {
    this.disabled = false;
  }

  ngOnChanges(changes) {
    if (changes.total) {
      if (this.actionsMaxResult.value && this.total <= 10) {
        //TODO make this work with all totals following logic from front-end
        this.actionsMaxResult.setValue("10");
      }
      if (this.total <= 10 || (this.dataSource.data.length < 10 && this.page === 1)) {
        this.actionsMaxResult.disable();
      } else {
        this.actionsMaxResult.enable();
      }
    }

    const numPages = Math.ceil(this.total / this.maxResults);
    this.numPages = !isNaN(numPages) ? numPages : null;
  }

  maxResultChange(value: number) {
    this.pagerData.arrow = "max";
    this.pagerData.page = 1;
    this.pagerData.offset = 0;
    this.pagerData.maxResults = parseInt(value + "");
    this.pager.emit(this.pagerData);
  }

  resetToFirstPage() {
    this.pagerData.arrow = "";
    this.pagerData.page = 1;
    this.pagerData.offset = 0;
    this.pager.emit(this.pagerData);
  }

  resetToLastPage() {
    this.pagerData.arrow = "";
    this.pagerData.page = this.numPages;
    this.pagerData.offset = this.maxResults * (this.numPages - 1);
    this.pager.emit(this.pagerData);
  }

  nextPage() {
    this.pagerData.arrow = "next";
    this.pagerData.page = ++this.page;
    this.pagerData.offset = this.offset + this.maxResults;
    this.pager.emit(this.pagerData);
  }

  prevPage() {
    this.pagerData.arrow = "prev";
    this.pagerData.page = --this.page;
    this.pagerData.offset = this.offset - this.maxResults;
    this.pager.emit(this.pagerData);
  }
}
