import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ReportsService, OU } from "src/app/services/google/reports.service";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { AdminPlusService } from "src/app/services/admin-plus/admin-plus.service";

@Component({
  selector: "app-storage-report-organizational-unit-users",
  templateUrl: "./storage-report-organizational-unit-users.component.html",
})
export class StorageReportOrganizationalUnitUsersComponent implements OnInit {
  OU: OU;
  ouId: string;
  destroy = false;
  private init = false;

  constructor(
    private reportsService: ReportsService,
    private activatedRoute: ActivatedRoute,
    private navService: NavService,
    private adminPlusService: AdminPlusService
  ) {
    this.adminPlusService.breadCrumbLinks.push({
      link: "/reports/storage/organizational-units",
      text: "Organizational Units",
      alt: "Organizational Units",
    });
  }

  async ngOnInit() {
    this.navService.loading.next(true);
    this.ouId = this.activatedRoute.snapshot.paramMap.get("ouId") || "";
    this.OU = await this.reportsService.getOuById(parseInt(this.ouId));

    if (this.OU && !this.init && !this.destroy) {
      this.adminPlusService.breadCrumbLinks.push({
        link: null,
        text: '"' + this.OU.name + '"',
        alt: this.OU.name,
      });
      this.init = true;
    }
    this.navService.loading.next(false);
  }

  ngOnDestroy() {
    this.destroy = true;
  }
}
