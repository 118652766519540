import { Injectable } from "@angular/core";
import { Delegate } from "src/app/models/ticket-plus/delegate";
import { Payload } from "../utilities/utility.service";
import { TicketPlusService } from "./ticket-plus.service";

@Injectable({
  providedIn: "root",
})
export class DelegateService extends TicketPlusService {
  private url = `/ticket-plus/delegates`;

  async getDelegates(params = {}): Promise<Payload> {
    return this.cacheService.getPayloadAndCache(this.url, params, this.utilityService);
  }

  async createUpdateDelegate(delegate: Delegate): Promise<Delegate> {
    this.cacheService.clearCache(this.url);
    return new Promise<Delegate>((resolve) => {
      const method = "POST";
      const path = `${this.url}/` + (delegate.id ? delegate.id : "");
      const body = delegate;
      this.utilityService.sendRequest(method, path, {}, body).subscribe((payload) => {
        if (payload?.data?.length) {
          resolve(payload.data[0]);
        } else {
          resolve(null);
        }
      });
    });
  }

  async deleteDelegate(delegate: Delegate): Promise<Payload> {
    this.cacheService.clearCache(this.url);
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("DELETE", `${this.url}/${delegate.id}`).subscribe((payload) => {
        resolve(payload);
      });
    });
  }
}
