import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { EmailsService, Email } from "src/app/services/storage-plus/emails.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { Sort } from "@angular/material/sort";
import { UntypedFormControl } from "@angular/forms";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { merge, of as observableOf } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";

@Component({
  selector: "app-email-history-table",
  templateUrl: "./email-history-table.component.html",
  styleUrls: ["./email-history-table.component.scss"],
})
export class EmailHistoryTableComponent implements OnInit {
  public dataSource = new MatTableDataSource<Email>();
  public actions = new UntypedFormControl();
  public actionsMaxResult = new UntypedFormControl();

  public displayedColumns: string[] = ["emailAddress", "created", "type"];

  public emails: Email[] = [];

  public maxResults = 10;
  public page = 1;
  public offset = 0;
  private getCount = true;
  public totalEmailCount = 0;

  public nextButtonDis = false;
  public nextPageDisabled = false;
  public nextButton = false;
  public loading = false;

  public search = "";
  public activeOrderBy = "created";
  public direction = "desc";
  public customerId = "";
  public storageReportId = "";

  @ViewChild(MatSort) sort!: MatSort;

  constructor(private emailsService: EmailsService, private router: Router, private navService: NavService) {}

  async ngOnInit() {
    this.loadData();
  }

  private async loadData(arrow = "") {
    this.loading = true;
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.navService.loading.next(true);
          this.nextButton = true;
          this.getCount = !arrow;
          return this.emailsService.getEmails(
            this.activeOrderBy,
            this.direction,
            this.maxResults,
            this.offset,
            this.search,
            this.getCount
          );
        }),
        map((emailLogs) => {
          this.loading = false;
          if (emailLogs) {
            this.emails = emailLogs["data"];
            this.dataSource = new MatTableDataSource(this.emails);
            this.dataSource.sort = this.sort;
            this.pagination(arrow, emailLogs);
          }
          this.dataSource.sortingDataAccessor = (data, sortHeaderId: string) => {
            if (typeof data[sortHeaderId] === "string") {
              return data[sortHeaderId].toLowerCase();
            }
            return data[sortHeaderId];
          };
          this.nextButton = false;
          this.navService.loading.next(false);
          return emailLogs;
        }),
        catchError(() => {
          this.loading = false;
          return observableOf([]);
        })
      )
      .subscribe((emailLogs) => {
        if (emailLogs) {
          return emailLogs;
        }
      });
  }

  pagination(arrow, emailLogs) {
    this.nextPageDisabled = this.dataSource.data.length < this.maxResults ? true : false;
    this.totalEmailCount = this.getCount ? (emailLogs["count"] ? emailLogs["count"] : 0) : this.totalEmailCount;
    if (this.dataSource["filteredData"].length < 10) {
      this.nextButtonDis = true;
    }
    if (this.dataSource["filteredData"].length > 10) {
      this.nextButtonDis = false;
    }
    if (arrow === "next" && this.dataSource["filteredData"].length + this.offset === this.totalEmailCount) {
      this.nextButtonDis = true;
    }
    if (arrow === "next" && this.dataSource["filteredData"].length + this.offset < this.totalEmailCount) {
      this.nextButtonDis = false;
    }
    if (this.dataSource["filteredData"].length + this.offset < this.totalEmailCount) {
      this.nextButtonDis = false;
    }
    if (this.dataSource["filteredData"].length + this.offset === this.totalEmailCount) {
      this.nextButtonDis = true;
    }
    if (arrow === "prev" && this.totalEmailCount > 0) {
      this.nextButtonDis = false;
    }
    if (this.totalEmailCount === this.maxResults) {
      this.nextButtonDis = true;
    }
    this.nextButton = false;
  }

  changeSort(sort: Sort) {
    if (sort.direction === "") {
      this.direction = "desc";
    } else {
      this.activeOrderBy = sort.active;
      this.direction = sort.direction;
    }
    this.loadData();
  }

  maxResultChange(value) {
    this.page = 1;
    this.offset = 0;
    this.maxResults = parseInt(value + "");
    this.loadData("max");
  }

  resetToFirstPage() {
    this.page = 1;
    this.offset = 0;
    this.loadData("");
  }

  applyFilter(event: Event) {
    this.search = (event.target as HTMLInputElement).value;
    this.loadData();
  }

  public setPager(data) {
    this.page = data.page;
    this.offset = data.offset;
    this.maxResults = data.maxResults ? data.maxResults : this.maxResults;
    this.loadData(data.arrow);
  }

  routeToEmail(index: number) {
    this.router.navigate(["/reports/storage/emails"], {
      fragment: index.toString(),
    });
  }
}
