<div *ngIf="!limit" class="row">
  <div class="col-12 mt-2">
    <app-filters [filterType]="filterTypeValue" (dataEvent)="receiveData($event)"></app-filters>
  </div>
  <div class="col-12">
    <div class="table-responsive has-active">
      <table mat-table [dataSource]="dataSource" matSort matSortActive="totalStorage" matSortDirection="desc">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
            >Organizational Unit</th
          >
          <td mat-cell
            (click)="routeToOUUsers(row.id)"
            *matCellDef="let row"
          >
            {{ row.name }}
        </td>
        </ng-container>
        <ng-container matColumnDef="totalStorage">
          <th mat-header-cell mat-sort-header *matHeaderCellDef
            >Total Storage</th
          >
          <td mat-cell
            *matCellDef="let row"
            (click)="routeToOUUsers(row.id)"
          >
            {{ row.totalStorage / 1024 | number : "1.1-2" }}
        </td>
        </ng-container>
        <tr class="mat-row" app-empty-dataset-alert [loading]="loading" [filterCount]="data.filtersCount" *matNoDataRow></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <app-table-footer
      *ngIf="!limit"
      [dataSource]="dataSource"
      [maxResults]="maxResults"
      [nextButton]="nextButton"
      [nextButtonDis]="nextButtonDis"
      [offset]="offset"
      [page]="page"
      [total]="totalCount"
      (pager)="setPager($event)"
    ></app-table-footer>
  </div>
</div>