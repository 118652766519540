import { Injectable } from "@angular/core";
import { User } from "src/app/web/login/user";
import { UtilityService } from "./utilities/utility.service";

@Injectable({
  providedIn: "root",
})
export class ModulesService {
  constructor(private utilityService: UtilityService) {}

  async getModules() {
    return new Promise<any>((resolve) => {
      this.utilityService.sendRequest("GET", `/login`).subscribe((payload) => {
        resolve(payload);
      });
    });
  }

  async approveTos(signature: string) {
    return new Promise<any>((resolve) => {
      this.utilityService.sendRequest("GET", `/login/approve`, { signature: signature }).subscribe((payload) => {
        resolve(payload);
      });
    });
  }

  async approveEua() {
    return new Promise<any>((resolve) => {
      this.utilityService.sendRequest("GET", `/login/eua/approve`).subscribe((payload) => {
        resolve(payload);
      });
    });
  }
}

export interface Modules {
  customerID: string;
  exp: number;
  message: string;
  modules: string[];
  result: number;
  tos: string;
  user: User;
}

export interface Customer {
  subscription: Subscription;
}

export interface Subscription {
  name?: string;
  expired?: boolean;
  free?: boolean;
}
