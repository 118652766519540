import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { ReleaseNote, ReleaseNotesService } from "../services/admin-plus/release-notes";

@Component({
  selector: "app-product-updates",
  templateUrl: "./product-updates.component.html",
  styleUrls: ["./product-updates.component.scss"],
})
export class ProductUpdatesComponent implements OnInit {
  releaseNotes: ReleaseNote[];
  pages: number;
  dataSource = new MatTableDataSource<ReleaseNote>();
  // pagination
  maxResults = 10;
  page = 1;
  offset = 0;
  loaded = false;
  orderBy = "";
  nextButton = false;
  nextButtonDis = false;
  nextPageDisabled = false;
  totalCount = 0;

  constructor(private releaseNotesService: ReleaseNotesService, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(() => {
      this.getReleases();
    });
  }

  async getReleases(arrow = "") {
    this.nextButton = true;
    this.loaded = true;
    const releaseInfo = await this.releaseNotesService.getReleaseNotes(this.maxResults, this.offset);
    if (releaseInfo) {
      this.releaseNotes = releaseInfo["releases"];
      this.totalCount = Number(releaseInfo["totalReleases"]);
      this.dataSource = new MatTableDataSource(this.releaseNotes);
      this.loaded = false;
      this.pagination(arrow);
    } else {
      this.loaded = false;
    }
  }

  pagination(arrow) {
    this.nextPageDisabled = this.dataSource.data.length < this.maxResults ? true : false;
    if (this.dataSource.filteredData.length < 10) {
      this.nextButtonDis = true;
    }
    if (this.dataSource.filteredData.length > 10) {
      this.nextButtonDis = false;
    }
    if (arrow === "next" && this.dataSource.filteredData.length + this.offset === this.totalCount) {
      this.nextButtonDis = true;
    }
    if (arrow === "next" && this.dataSource.filteredData.length + this.offset < this.totalCount) {
      this.nextButtonDis = false;
    }
    if (this.dataSource.filteredData.length + this.offset < this.totalCount) {
      this.nextButtonDis = false;
    }
    if (this.dataSource.filteredData.length + this.offset === this.totalCount) {
      this.nextButtonDis = true;
    }
    if (arrow === "prev" && this.totalCount > 0) {
      this.nextButtonDis = false;
    }
    if (this.totalCount === this.maxResults) {
      this.nextButtonDis = true;
    }
    this.nextButton = false;
  }

  public setPager(data) {
    this.page = data.page;
    this.offset = data.offset;
    this.maxResults = data.maxResults ? data.maxResults : this.maxResults;
    this.getReleases(data.arrow);
  }
}
