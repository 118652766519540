<div class="h-100 d-flex align-items-center ml-2">
  <button mat-button class="dropdown" [matMenuTriggerFor]="menu" [ngStyle]="{ 'font-family': selectedFont.css }">
    {{ selectedFont.name }}
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let font of signatureService.fonts" (click)="onChange(font)">
      <span [ngStyle]="{ 'font-family': font.css }">
        {{ font.name }}
      </span>
    </button>
  </mat-menu>
</div>
