<h2 mat-dialog-title>
	Are you sure you want to remove membership to the following groups?
</h2>
<div mat-dialog-content>
	<ul>
		<li *ngFor="let group of data.groups">
			{{ group.name }}
		</li>
	</ul>
	<div mat-dialog-actions class='px-0'>
		<button mat-dialog-close mat-stroked-button >Cancel</button>
		<button class="" mat-stroked-button (click)="delete()">Confirm</button>
	</div>
</div>
