import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { environment } from "src/environments/environment";
import { TenantDatabase } from "src/app/services/asset-plus/tenant-database.service";
import { ServiceAccount } from "./service-account.service";
import { AuthService } from "src/app/services/admin-plus/auth.service";
import { User } from "src/app/web/login/user";
import { UtilityService } from "../utilities/utility.service";
import { ModuleService } from "../admin-plus/module.service";
import { LeftNavService } from "./leftnav.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  private baseURL: string = environment.apiUrl + "/customer";
  private user: User;
  jobs: Job[] = [];
  running = false;
  private refreshSubject = new Subject<unknown>();
  customerModules: CustomerModule[] = [];
  customerModulesError: boolean;
  customer: Customer;
  private isExpired: boolean;

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private authService: AuthService,
    private utilityService: UtilityService,
    private moduleService: ModuleService,
    private leftNavService: LeftNavService,
    private router: Router
  ) {
    const localCustomer = localStorage.getItem("customer") || null;
    if (localCustomer) this.customer = JSON.parse(localCustomer);

    this.setSubscriptionExpired();

    if (!this.isExpired) this.loadCustomerModules();
  }

  public setSubscriptionExpired() {
    //if (this.isExpired != null || this.customer?.subscription?.expired === this.isExpired) return;

    this.isExpired = this.customer?.subscription?.expired;

    for (const link of this.leftNavService.leftNavLinks) {
      link.disabled = this.isExpired;
      link.loading = false;
      link.errorMessage = this.isExpired ? "Subscription Expired!" : link.description;
    }

    this.sendRefresh("refresh");
  }

  async loadCustomerModules() {
    const user = this.loginService.getUser();
    if (user) {
      const googlePermission = this.loginService.getGooglePermission(user);
      if (googlePermission) {
        const authToken = googlePermission.accessToken;
        if (authToken) {
          this.customerModules = await this.getCustomerModules();
          this.customerModulesError = !this.customerModules;
          this.sendRefresh("refresh");
        }
      }
    }
  }

  async getCustomer() {
    return new Promise<Customer>((resolve) => {
      this.utilityService.sendRequest("GET", `/admin-plus`).subscribe((payload) => {
        if (payload?.data?.length) {
          this.customer = payload.data[0];
          this.setSubscriptionExpired();
          resolve(payload.data[0]);
        } else {
          resolve(null);
        }
      });
    });
  }

  async getCustomerModules(): Promise<CustomerModule[]> {
    return new Promise<any>((resolve) => {
      this.utilityService.sendRequest("GET", `/account/get-customer-modules`).subscribe((payload) => {
        resolve(payload);
      });
    });
  }

  async getCustomerSchemas() {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<any[]>((resolve) => {
      this.getCustomerSchemasData().subscribe((customerSchemas) => {
        if (customerSchemas) {
          resolve(customerSchemas);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getCustomerSchemasData(): Observable<any[]> {
    return this.http
      .get<any[]>(this.baseURL + "/automation/custom-schemas", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<any[]>("getCustomerModulesData")));
  }

  async getCustomerSettings() {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<CustomerSettings>((resolve) => {
      this.getCustomerSettingsData().subscribe((customer) => {
        if (customer) {
          resolve(customer);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getCustomerSettingsData(): Observable<CustomerSettings> {
    return this.http
      .get<CustomerSettings>(environment.apiUrl + "/admin-plus/customer/settings", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<CustomerSettings>("getCustomerSettingsData")));
  }

  async setCustomerSettings(settings) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<CustomerSettings>((resolve) => {
      this.setCustomerSettingsData(settings).subscribe((customer) => {
        if (customer) {
          resolve(customer);
        } else {
          resolve(null);
        }
      });
    });
  }

  private setCustomerSettingsData(settings): Observable<CustomerSettings> {
    return this.http
      .post<CustomerSettings>(environment.apiUrl + "/admin-plus/customer/settings", settings, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<CustomerSettings>("setCustomerSettingsData")));
  }

  public async updateCustomer(customer: Customer) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Customer>((resolve) => {
      this.updateCustomerData(customer).subscribe((customer) => {
        if (customer) {
          resolve(customer);
        }
      });
    });
  }

  private updateCustomerData(customer: Customer): Observable<Customer> {
    return this.http
      .put<Customer>(this.baseURL + "/" + customer.id, customer, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Customer>("updateCustomerData")));
  }

  public async linkCustomerModule(action: string, subscriptionModuleId: number, serviceAccountId: number) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<CustomerModule>((resolve) => {
      this.linkCustomerModuleData(action, subscriptionModuleId, serviceAccountId).subscribe((customerModule) => {
        if (customerModule) {
          this.sendRefresh("refresh");
          resolve(customerModule);
        } else {
          resolve(null);
        }
      });
    });
  }

  private linkCustomerModuleData(
    action: string,
    subscriptionModuleId: number,
    serviceAccountId: number
  ): Observable<CustomerModule> {
    if (action === "link") {
      return this.http
        .post<CustomerModule>(
          this.baseURL + "/customer-module",
          { subscriptionModuleId, serviceAccountId },
          {
            headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
          }
        )
        .pipe(catchError(this.authService.handleAPIError<CustomerModule>("linkCustomerModuleData")));
    } else if (action === "change") {
      return this.http
        .patch<CustomerModule>(
          this.baseURL + "/customer-module",
          { subscriptionModuleId, serviceAccountId },
          {
            headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
          }
        )
        .pipe(catchError(this.authService.handleAPIError<CustomerModule>("linkCustomerModuleData")));
    }
  }

  public async unlinkCustomerModule(subscriptionModuleId: number) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<boolean>((resolve) => {
      this.unlinkCustomerModuleData(subscriptionModuleId).subscribe((unlinked) => {
        if (unlinked) {
          this.sendRefresh("refresh");
          resolve(unlinked);
        } else {
          resolve(false);
        }
      });
    });
  }

  private unlinkCustomerModuleData(subscriptionModuleId: number): Observable<boolean> {
    return this.http
      .delete<boolean>(this.baseURL + "/customer-module/" + subscriptionModuleId, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<boolean>("unlinkCustomerModuleData")));
  }

  public async enableGoogleCloudStorage(bucketName: string, enable: boolean) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<string>((resolve) => {
      this.enableGoogleCloudStorageData(bucketName, enable).subscribe((bucketName) => {
        if (bucketName) {
          resolve(bucketName);
        } else {
          resolve(null);
        }
      });
    });
  }

  private enableGoogleCloudStorageData(bucketName: string, enable: boolean): Observable<string> {
    return this.http
      .post<string>(
        this.baseURL + "/google-cloud-storage/bucket",
        {
          bucketName: bucketName,
          enable: enable,
        },
        {
          headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        }
      )
      .pipe(catchError(this.authService.handleAPIError<string>("enableGoogleCloudStorageData")));
  }

  async getJobStatuses() {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<JobStatuses[]>((resolve) => {
      this.getJobStatusesData().subscribe((jobStatuses) => {
        if (jobStatuses) {
          resolve(jobStatuses);
        }
      });
    });
  }

  private getJobStatusesData(): Observable<JobStatuses[]> {
    return this.http
      .get<JobStatuses[]>(this.baseURL + "/job-statuses", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<JobStatuses[]>("getJobStatusesData")));
  }

  async getJobTypes() {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<JobTypes[]>((resolve) => {
      this.getJobTypesData().subscribe((jobStatuses) => {
        if (jobStatuses) {
          resolve(jobStatuses);
        }
      });
    });
  }

  private getJobTypesData(): Observable<JobTypes[]> {
    return this.http
      .get<JobTypes[]>(this.baseURL + "/job-types", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<JobTypes[]>("getJobTypesData")));
  }

  async getJob(jobId: number) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Job>((resolve) => {
      this.getJobData(jobId).subscribe((job) => {
        if (job) {
          resolve(job);
        }
      });
    });
  }

  private getJobData(jobId: number): Observable<Job> {
    return this.http
      .get<Job>(this.baseURL + "/job", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams().set("jobId", jobId.toString()),
      })
      .pipe(catchError(this.authService.handleAPIError<Job>("getJobData")));
  }

  async getJobs(
    firstName = "",
    lastName = "",
    email = "",
    createdAfter = "",
    createdBefore = "",
    status = "",
    type = "",
    limit: number = null,
    offset: number = null,
    count = false,
    startDate: string = null,
    endDate: string = null
  ) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Job[]>((resolve) => {
      this.getJobsRequest(
        firstName,
        lastName,
        email,
        createdAfter,
        createdBefore,
        status,
        type,
        limit,
        offset,
        count,
        startDate,
        endDate
      ).subscribe((jobs) => {
        if (jobs) {
          resolve(jobs);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getJobsRequest(
    firstName = "",
    lastName = "",
    email = "",
    createdAfter = "",
    createdBefore = "",
    status = "",
    type = "",
    limit: number = null,
    offset: number = null,
    count = false,
    startDate: string = null,
    endDate: string = null
  ): Observable<Job[]> {
    //Build parameter list
    let params = new HttpParams()
      .set("count", count.toString())
      .set("firstName", firstName)
      .set("lastName", lastName)
      .set("email", email)
      .set("createdAfter", createdAfter)
      .set("createdBefore", createdBefore);

    if (limit) params = params.append("limit", limit.toString());
    if (offset) params = params.append("offset", offset.toString());
    if (startDate) params = params.append("startDate", startDate);
    if (endDate) params = params.append("endDate", endDate);
    if (status && status !== "") params = params.append("status", status);
    if (type && type !== "") params = params.append("type", type);

    //make/return request observable
    return this.http
      .get<Job[]>(this.baseURL + "/jobs", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: params,
      })
      .pipe(catchError(this.authService.handleAPIError<Job[]>("getJobsRequest")));
  }

  sendRefresh(action: string) {
    this.refreshSubject.next(action);
  }

  refreshModule(): Observable<unknown> {
    return this.refreshSubject.asObservable();
  }

  validModuleRoute(url: string) {
    //turn the url into a single module string (it's always the first part of the url)
    const moduleName = url.substring(1).split("/")[0].split("?")[0].split("#")[0];

    if (!this.moduleService.isValidModule(moduleName)) return false;

    for (const module of this.customerModules) {
      if (module.name == moduleName) {
        if (module.requiresServiceAccount && !module.serviceAccountId) {
          this.router.navigateByUrl("/settings/product/service-account");
        }
      }
    }

    return true;
  }

  matTabIndexToCommonString(index: number, tabs = []): string {
    return tabs[index] ? tabs[index] : "";
  }

  commonStringToMatTabIndex(string: string, tabs = []): number {
    return tabs.indexOf(string) != -1 ? tabs.indexOf(string) : 0;
  }

  getStripeLink(eligibleForFreeTrial: boolean) {
    return new Promise<any>((resolve) => {
      this.utilityService
        .sendRequest("GET", `/customer/stripe/link` + (eligibleForFreeTrial ? `/free-trial` : ``))
        .subscribe((payload) => {
          resolve(payload);
        });
    });
  }

  updateStripeSubscriptionExpiration() {
    return new Promise<any>((resolve) => {
      this.utilityService.sendRequest("GET", `/customer/stripe/subscription-expiration`).subscribe((payload) => {
        resolve(payload);
      });
    });
  }
}

export interface Customer {
  id?: number;
  created?: string;
  name?: string;
  tenantEnabled?: boolean;
  gcsEnabled?: boolean;
  gcsBucket?: string;
  tenantDatabase: TenantDatabase;
  serviceAccount: ServiceAccount;
  subscription: Subscription;
  timezone?: string;
  limit?: number;
  billingType?: string;
}

export interface Subscription {
  id?: number;
  name?: string;
  expires?: string;
  free?: boolean;
  expired?: boolean;
  plus?: number;
  billing?: string;
  stripe?: unknown;
}

export interface Job {
  id: number;
}

export interface JobTypes {
  jobType: JobType[];
}

export interface JobType {
  name: string;
}

export interface JobStatuses {
  jobStatus: JobStatus[];
}

export interface JobStatus {
  name: string;
}

export interface CustomerModule {
  id: number;
  name: string;
  description: string;
  requiresServiceAccount: boolean;
  serviceAccountId?: number;
  subscriptionModuleId?: number;
  delegateEmail?: string;
}

export interface CustomerSettings {
  attachmentId?: string;
  brand?: string;
  disablePortalLogin?: string;
  messageDelayTime?: string;
  ticketPlusEmailDelayTime?: string;
  ticketPlusRequireReasonForClosing?: string;
}
