import { Injectable } from "@angular/core";
import { Payload } from "../utilities/utility.service";
import { AdminPlusService } from "./admin-plus.service";

@Injectable({
	providedIn: "root",
})
export class AuditLogsService extends AdminPlusService {
	objects = [];
	auditTypes = [];
	selectedDataType = "asset";

	async getAuditLogs(params = {}): Promise<Payload> {
		return new Promise<Payload>((resolve) => {
			this.utilityService.sendRequest("GET", `/admin-plus/audit-logs`, params).subscribe((payload) => {
				if (payload) {
					resolve(payload);
				} else {
					resolve(null);
				}
			});
		});
	}

	async getFilters(params = {}): Promise<Payload> {
		return new Promise<Payload>((resolve) => {
			this.utilityService.sendRequest("GET", `/admin-plus/audit-logs/filters`, params).subscribe((payload) => {
				if (payload) {
					this.objects = payload["objects"];
					this.auditTypes = payload["auditTypes"];
					resolve(payload);
				} else {
					resolve(null);
				}
			});
		});
	}
}
