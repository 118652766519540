<h2 mat-dialog-title>More Information on Totals</h2>
<div mat-dialog-content>
  <div class="row">
    <div class="col-12 mt-2">
      <p>
        {{ message }}
      </p>
    </div>
  </div>
  <div mat-dialog-actions class="px-0">
    <button mat-stroked-button mat-dialog-close>Close</button>
  </div>
</div>
