import { Component, OnInit } from "@angular/core";
import { EmailsService } from "src/app/services/storage-plus/emails.service";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-email-action-required",
  templateUrl: "./email-action-required.component.html",
  styleUrls: ["./email-action-required.component.scss"],
})
export class EmailActionRequiredComponent implements OnInit {
  email: string = "";
  emailName: string = "actionRequired.html";
  refreshListener: Subscription;

  constructor(private emailsService: EmailsService, private navService: NavService) {
    this.refreshListener = this.emailsService.refreshEmailTemplate().subscribe((emailName) => {
      if (emailName == this.emailName) this.loadData();
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  private async loadData() {
    this.navService.loading.next(true);
    this.email = await this.emailsService.getEmail(this.emailName);
    this.navService.loading.next(false);
  }
}
