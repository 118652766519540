import { Component, OnInit } from "@angular/core";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { ReportsService, StorageReport } from "src/app/services/google/reports.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AdminPlusService } from "src/app/services/admin-plus/admin-plus.service";

@Component({
  selector: "app-storage-organizational-units-dashboard",
  templateUrl: "./storage-organizational-units-dashboard.component.html",
})
export class StorageOrganizationalUnitsDashboardComponent implements OnInit {
  pieChartData = [];
  loading = true;
  topOus: StorageReport[] = [];
  topUsers: StorageReport[] = [];

  constructor(
    private navService: NavService,
    private reportsService: ReportsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private adminPlusService: AdminPlusService
  ) {
    this.adminPlusService.breadCrumbLinks.push({
      link: null,
      text: "Organizational Units",
      alt: "Organizational Units",
    });
  }

  async ngOnInit() {
    this.loading = true;
    this.navService.loading.next(true);
    const storageReport = await this.reportsService.getStorageReport();
    this.loading = false;
    this.navService.loading.next(false);
    if (!storageReport || storageReport.status.id != 4) {
      //complete
      this.router.navigate(["/reports/storage"]);
      return;
    }
    this.loadTopOus();
    this.loadTopUsers();
  }

  private async loadTopOus() {
    this.navService.loading.next(true);
    this.loading = true;
    const ous = await this.reportsService.getTopOUs();
    if (ous) {
      this.topOus = ous;
    }
    this.loading = false;
    this.navService.loading.next(false);
  }

  private async loadTopUsers() {
    this.navService.loading.next(true);
    this.loading = true;
    const users = await this.reportsService.getTopUserOu();
    if (users) {
      this.topUsers = users;
    }
    this.loading = false;
    this.navService.loading.next(false);
  }

  viewAllOUs() {
    this.router.navigate(["ous"], { relativeTo: this.activatedRoute });
  }
}
