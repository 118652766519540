import { Injectable } from "@angular/core";
import { TicketTag } from "src/app/models/ticket-plus/tag";
import { Tag } from "src/app/models/ticket-plus/tag";
import { Payload } from "../../utilities/utility.service";
import { TicketService } from "./ticket.service";

@Injectable({
  providedIn: "root",
})
export class TagService extends TicketService {
  async getTags(params = {}) {
    const url = `/ticket-plus/tags`;
    return this.cacheService.getPayloadAndCache(url, params, this.utilityService);
  }

  async createUpdateTag(tag: Tag): Promise<TicketTag> {
    this.cacheService.clearCache(`/ticket-plus/tags`);
    return new Promise<TicketTag>((resolve) => {
      this.utilityService.sendRequest("POST", `/ticket-plus/tags`, {}, tag).subscribe((payload) => {
        if (payload?.data?.length) {
          resolve(payload.data[0]);
        } else {
          resolve(null);
        }
      });
    });
  }

  async deleteTag(tagId: number): Promise<Payload> {
    this.cacheService.clearCache(`/ticket-plus/tags/${tagId}`);
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("DELETE", `/ticket-plus/tags/${tagId}`).subscribe((payload) => {
        resolve(payload);
      });
    });
  }

  async getTicketTags(ticketId: number, params = {}) {
    const url = `/ticket-plus/tickets/${ticketId}/tags`;
    return this.cacheService.getPayloadAndCache(url, params, this.utilityService);
  }

  async createTicketTag(ticketId: number, tag: TicketTag): Promise<TicketTag> {
    this.cacheService.clearCache(`/ticket-plus/tickets/${ticketId}/tags`);
    return new Promise<TicketTag>((resolve) => {
      this.utilityService.sendRequest("POST", `/ticket-plus/tickets/${ticketId}/tags`, {}, tag).subscribe((payload) => {
        if (payload?.data?.length) {
          resolve(payload.data[0]);
        } else {
          resolve(null);
        }
      });
    });
  }

  async deleteTicketTag(ticketId: number, tagId: number): Promise<Payload> {
    this.cacheService.clearCache(`/ticket-plus/tickets/${ticketId}/tags/${tagId}`);
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("DELETE", `/ticket-plus/tickets/${ticketId}/tags/${tagId}`).subscribe((payload) => {
        resolve(payload);
      });
    });
  }
}
