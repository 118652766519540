import { Component, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ServiceAccount } from "src/app/services/admin-plus/service-account.service";
import { Clipboard } from "@angular/cdk/clipboard";
import { AuthService } from "src/app/services/admin-plus/auth.service";
import { LoginService } from "src/app/services/admin-plus/login.service";

@Component({
  selector: "app-api-controls",
  templateUrl: "./api-controls.component.html",
})
export class ApiControlsComponent {
  displayScopes = null;
  tooltipDisplayScopes = null;
  clientIdCopied = false;
  scopesCopied = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ServiceAccount,
    private dialogRef: MatDialogRef<ApiControlsComponent>,
    private dialog: MatDialog,
    private clipboard: Clipboard,
    public authService: AuthService,
    private loginService: LoginService
  ) {
    const scopeCount = data.scopes ? data.scopes.split(",").length : 0;
    this.displayScopes = scopeCount
      ? `${data.scopes.split(",")[0]} ${scopeCount > 1 ? " +" + (scopeCount - 1) + " More" : ""}`
      : null;
    this.tooltipDisplayScopes = scopeCount ? data.scopes.replace(/,/g, "\n") : null;
  }

  public copyToClipboard(text: string, type: string) {
    this.clipboard.copy(text);
    if (type == "clientId") {
      this.clientIdCopied = true;
      this.scopesCopied = false;
    } else {
      this.clientIdCopied = false;
      this.scopesCopied = true;
    }
  }

  openServiceAccountTestDialog() {
    this.dialogRef.close("test");
  }

  autoAddScopes() {
    let url = `https://admin.google.com/ac/owl/domainwidedelegation?clientScopeToAdd=${this.data.scopes}`;
    url += `&clientIdToAdd=${this.data.clientId}`;
    url += `&overwriteClientId=true`;
    url += `&dn=${this.loginService.user.email.split("@")[1]}`;
    url += `&authuser=${this.loginService.user.email}`;

    window.open(url, "_blank");
  }
}
