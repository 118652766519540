import { Injectable } from "@angular/core";
import { UtilityService } from "../utilities/utility.service";
import { CacheService } from "src/app/services/utilities/cache.service";

@Injectable({
  providedIn: "root",
})
export class TicketPlusService {
  constructor(public utilityService: UtilityService, public cacheService: CacheService) {}
}
