import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CustomerService, Customer } from "src/app/services/admin-plus/customer.service";
import { NotificationService } from "../../services/utilities/notification.service";

@Component({
  selector: "app-organization-dialog",
  templateUrl: "./organization-dialog.component.html",
  styleUrls: ["./organization-dialog.component.scss"],
})
export class OrganizationDialogComponent implements OnInit {
  public customer: Customer;
  public organizationName: string = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<OrganizationDialogComponent>,
    private customerService: CustomerService,
    private notifyService: NotificationService
  ) {}

  async submit() {
    if (this.customer.name.trim()) {
      const response = await this.customerService.updateCustomer(this.customer);
      if (response) {
        this.notifyService.notify("Successfully updated the organization name");
        this.dialogRef.close(response);
      }
    } else {
      this.notifyService.notify("The organization name is required");
    }
  }

  ngOnInit(): void {
    this.customer = this.data.customer;
  }
}
