<mat-form-field class="w-100">
  <mat-label>{{ title }}</mat-label>
  <mat-select
    [(ngModel)]="ticket.status"
    [compareWith]="compareObjects"
    name="status"
    placeholder="Status"
    [disabled]="ticketLoading || loading || ticket.deleted"
    (selectionChange)="onSelectionChange.emit($event)"
  >
    <div *ngFor="let parentStatus of statuses">
      <mat-option class="optionGroup" *ngIf="!parentStatus.parentId" [value]="parentStatus">
        {{ parentStatus.name }}
      </mat-option>
      <mat-option class="optionChild" *ngFor="let status of parentStatus.children" [value]="status">
        {{ status.name }}
      </mat-option>
    </div>
  </mat-select>
  <mat-hint *ngIf="loading">
    <mat-icon class="faa-spin mx-1"> filter_tilt_shift </mat-icon>
    Gathering ticket statuses...
  </mat-hint>
  <mat-error *ngIf="error && !loading"> Unable to retrieve status data </mat-error>
</mat-form-field>
