import { Component, OnInit, ElementRef, ViewChild, Inject } from "@angular/core";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { OUService } from "src/app/services/google/ou.service";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UntypedFormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { UsersService } from "src/app/services/admin-plus/users.service";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { NotificationService } from "../../services/utilities/notification.service";
import { User } from "src/app/users/users";

@Component({
  selector: "app-transfer-file-ownership",
  templateUrl: "./transfer-file-ownership.component.html",
  styleUrls: ["./transfer-file-ownership.component.scss"],
})
export class TransferFileOwnershipComponent implements OnInit {
  public users: User[] = [];
  public selected_user: string[];
  public removable: boolean = true;
  public userCtrl = new UntypedFormControl();
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public filteredOptions: Observable<any[]>;
  public loggedInUser: any;
  public orderBy: string = "givenName";
  public sortOrder: string = "SORT_ORDER_UNDEFINED";
  public user: User;

  @ViewChild("userInput") userInput: ElementRef;

  constructor(
    private usersService: UsersService,
    private notificationService: NotificationService,
    private navService: NavService,
    private ouService: OUService,
    private dialogRef: MatDialogRef<TransferFileOwnershipComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    let tUser: string = localStorage.getItem("user") || "";
    this.user = tUser && tUser != "" ? JSON.parse(tUser) : null;
  }

  ngOnInit(): void {
    this.selected_user = [];
  }

  public add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || "").trim()) {
      let user: any = {
        primaryEmail: value,
      };

      this.selected_user.push(user);
      // Reset the input value
      if (input) {
        input.value = "";
      }

      this.userCtrl.setValue(null);
    }
  }

  remove(email: string): void {
    const index = this.selected_user.indexOf(email);

    if (index >= 0) {
      this.selected_user.splice(index, 1);
      this.users = [];
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.users = [];
    if (this.selected_user.length < 1) {
      this.selected_user.push(event.option.value);
    }
    this.userInput.nativeElement.value = "";
    this.userCtrl.setValue(null);
  }

  public processForm() {
    if (!this.selected_user.length) {
      this.notificationService.notify("A user must be selected to transfer ownership");
    } else {
      this.dialogRef.close(this.selected_user);
    }
  }

  async getUsers(searchEmail: string = "") {
    if (this.selected_user.length < 1) {
      this.navService.loading.next(true);
      let OU = {};
      if (!this.user.isAdmin) {
        OU = await this.ouService.getUserOUs();
      }
      let response = await this.usersService.getUsers(
        25,
        "",
        this.orderBy,
        this.sortOrder,
        searchEmail,
        "",
        "",
        "",
        this.user.isAdmin ? "/" : OU[0]["orgUnitPath"]
      );
      if (response) {
        if (response.users) {
          this.users = [];
          if (typeof response.users !== "undefined") {
            this.users = response.users;
          }
        }
      }

      this.filteredOptions = this.userCtrl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value))
      );
      this.navService.loading.next(false);
    }
  }

  private _filter(value: any): any[] {
    if (typeof value !== "object" && value !== null) {
      const filterValue = "";
      if (value.primaryEmail) {
        const filterValue = value.primaryEmail.toLowerCase();
      }

      return this.users.filter((user) => user.primaryEmail.toLowerCase().indexOf(filterValue) === 0);
    }
  }

  search(event: any) {
    let search = event.target.value;
    if (search.length > 2) {
      this.getUsers(search);
    }
  }
}
