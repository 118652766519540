<h2 mat-dialog-title>{{ action }} {{ data.type }}</h2>
<form (ngSubmit)="processForm()" autocomplete="off">
  <div mat-dialog-content>
    <app-loader *ngIf="loading"></app-loader>
    <div class="row">
      <div class="col-12 mt-2" *ngIf="action != 'Delete'">
        <mat-form-field class="w-100">
          <mat-label>Name</mat-label>
          <input matInput type="text" [(ngModel)]="object.name" name="room" required />
        </mat-form-field>
      </div>
      <div class="col-12 mt-2" *ngIf="action == 'Delete'">
        <h4>Are you sure you want to delete {{ object.name }}?</h4>
      </div>
    </div>
    <div mat-dialog-actions>
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button
        type="submit"
        mat-stroked-button
        class="ml-2"
        [class]="action == 'Delete' ? 'danger' : 'success'"
        [disabled]="loading">
        <span>{{ action }} {{ data.type }}</span>
      </button>
    </div>
  </div>
</form>
