import { Component, OnInit } from "@angular/core";
import { ReportsService, StorageReport } from "src/app/services/google/reports.service";
import { CustomerService } from "src/app/services/admin-plus/customer.service";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { EnableGoogleCloudStorageComponent } from "./dialogs/enable-google-cloud-storage/enable-google-cloud-storage.component";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import {
  ConfirmDialogModel,
  ConfirmationDialogComponent,
} from "src/app/layout/dialogs/confirmation-dialog/confirmation-dialog.component";
import { User as localUser } from "src/app/web/login/user";

@Component({
  selector: "app-storage-report-settings",
  templateUrl: "./storage-report-settings.component.html",
  styleUrls: ["./storage-report-settings.component.scss"],
})
export class StorageReportSettingsComponent implements OnInit {
  public user: localUser = {};
  public storageReport: StorageReport = {
    status: {
      name: "",
      id: 0,
    },
    totalUsers: 0,
    totalStorage: 0,
    totalDrive: 0,
    totalGmail: 0,
    totalPhotos: 0,
    allowDelete: false,
    usageLimit: 0,
  };
  public projectName = "";
  loading = true;

  constructor(
    private reportsService: ReportsService,
    public customerService: CustomerService,
    private navService: NavService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private router: Router
  ) {
    const user: localUser = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.user = user;
    }
  }

  async ngOnInit() {
    if (!this.user.isAdmin) {
      //bounce non-admins...
      this.router.navigate(["/reports/storage"]);
    } else {
      this.loadData();
    }
  }

  private async loadData() {
    this.loading = true;
    this.navService.loading.next(true);
    const storageReport = await this.reportsService.getStorageReport();
    if (storageReport) {
      this.storageReport = storageReport;
    }
    this.navService.loading.next(false);
    this.loading = false;
  }

  async saveStorageReport() {
    if (this.storageReport.name && this.storageReport.name.trim() != "") {
      this.navService.loading.next(true);
      const createStorageReport = this.storageReport.id == undefined;
      const storageReport = createStorageReport
        ? await this.reportsService.createStorageReport(this.storageReport)
        : await this.reportsService.updateStorageReport(this.storageReport);
      if (storageReport) {
        this.notificationService.notify(
          `Your Storage report config has been ${createStorageReport ? "created" : "updated"}`
        );
        this.storageReport = storageReport;
      }
      this.navService.loading.next(false);
    } else {
      this.notificationService.notify("A Storage report name required");
    }
  }

  async confirmBuildReport() {
    const message =
      "Are you sure you want to build this this report? It may take some time to complete this task and the system will email you when it's finished";
    const btnOkText = `Confirm`;
    const btnCancelText = `Cancel`;
    const dialogData = new ConfirmDialogModel("Build Report", message, btnOkText, btnCancelText);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      width: "500px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) this.buildReport();
    });
  }

  async buildReport() {
    this.loading = true;
    this.navService.loading.next(true);
    this.storageReport.totalUsers = 0;
    const response = await this.reportsService.buildReport(this.storageReport.id);
    if (response) {
      this.notificationService.notify("Your Storage report is currently rebuilding");
    }
    this.navService.loading.next(false);
    this.loading = false;
  }

  allowDeletionConfirmation(event: Event) {
    if (event["checked"] === true) {
      const message =
        "Deleted files cannot be restored. Are you sure that you want to allow all admins to permanently delete files?";
      const btnOkText = `Confirm`;
      const dialogData = new ConfirmDialogModel("Allow Permanent Deletion of Files", message, btnOkText);

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: true,
        width: "500px",
        data: dialogData,
      });

      dialogRef.afterClosed().subscribe((dialogResult) => {
        if (!dialogResult) {
          this.storageReport.allowDelete = false;
        }
      });
    }
  }

  async enableCloudStorage(event: Event) {
    if (event["checked"] === true) {
      this.dialog.open(EnableGoogleCloudStorageComponent, {
        disableClose: true,
        width: "500px",
      });
    } else {
      const response = await this.customerService.enableGoogleCloudStorage("", false);
      if (response) {
        await this.updateLocalStorageCustomerBucket(response);
        this.customerService.customer.gcsBucket = response["gcsBucket"];
        this.customerService.customer.gcsEnabled = response["gcsEnabled"];
        this.notificationService.notify("You disabled Google Cloud Storage for Storage");
      }
    }
  }

  async updateLocalStorageCustomerBucket(response) {
    this.customerService.customer["gcsBucket"] = response["gcsBucket"];
    this.customerService.customer["gcsEnabled"] = response["gcsEnabled"];
    localStorage.setItem("customer", JSON.stringify(this.customerService.customer));
  }
}
