import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Status } from "src/app/models/ticket-plus/status";
import { Ticket } from "src/app/models/ticket-plus/ticket";
import { StatusService } from "src/app/services/ticket-plus/status.service";

@Component({
  selector: "app-status",
  templateUrl: "./status.component.html",
  styleUrls: ["./status.component.scss"],
})
export class StatusComponent implements OnInit {
	@Input() defaultStatuses = false;
	@Input() ticket: Ticket;
	@Input() ticketLoading = true;
	@Input() title = "Status";
	@Output() onSelectionChange = new EventEmitter<string[]>();
	loading = true;
	error = true;
	statuses: Status[] = [];

	constructor(private statusService: StatusService) {}

	async ngOnInit() {
		const payload = await this.statusService.getGroupedStatuses();
		if (payload.length) {
			this.statuses = payload;
			if (this.defaultStatuses) {
				const statuses = this.statuses.filter(function (status) {
					return !status.customer.id;
				});
				this.statuses = statuses;
			}
			this.error = false;
		}
		this.loading = false;
	}

	compareObjects(o1: Status, o2: Status) {
		return o1 && o2 ? o1.id == o2.id : false;
	}
}
