import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LoginService } from "src/app/services/admin-plus/login.service";

@Component({
  selector: "app-content-left",
  templateUrl: "./content-left.component.html",
  styleUrls: ["./content-left.component.scss"],
})
export class ContentLeftComponent {
  @Output() sideNavToggle = new EventEmitter();
  @Input() animating;
  constructor(public loginService: LoginService) {}

  toggleLeftNav() {
    this.sideNavToggle.emit();
  }
}
