import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Drives } from "src/app/services/storage-plus/drives.service";
import { User } from "src/app/services/storage-plus/user.service";
import { MatTableDataSource } from "@angular/material/table";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { SelectionModel } from "@angular/cdk/collections";
import { DrivesService, Drive } from "src/app/services/storage-plus/drives.service";
import { MatSort } from "@angular/material/sort";
import { UntypedFormControl } from "@angular/forms";
import { merge, of as observableOf } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import {
  ConfirmDialogModel,
  ConfirmationDialogComponent,
} from "src/app/layout/dialogs/confirmation-dialog/confirmation-dialog.component";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { StorageReport } from "src/app/services/google/reports.service";
import { FiltersService, FilterType } from "src/app/services/utilities/filters.service";

@Component({
  selector: "app-storage-report-drives-table",
  templateUrl: "./storage-report-drives-table.component.html",
  styleUrls: ["./storage-report-drives-table.component.scss"],
})
export class StorageReportDrivesTableComponent implements OnInit {
  filterTypeValue: FilterType = FilterType.DRIVE;
  @Input() options = [];
  @Input() storageReport: StorageReport = {
    status: {
      name: "",
      id: 4,
    },
    totalUsers: 0,
    totalStorage: 0,
    totalDrive: 0,
    totalGmail: 0,
    totalPhotos: 0,
    allowDelete: false,
  };

  dataSource = new MatTableDataSource<Drives>();
  maxResultsActions = new UntypedFormControl();
  actions = new UntypedFormControl();
  extraColumns = new UntypedFormControl();
  selection = new SelectionModel(true, []);

  columnsList: { name: string; label: string }[] = [
    { name: "contentManagers", label: "Content Manager" },
    { name: "commenters", label: "Commenter" },
    { name: "contributors", label: "Contributor" },
    { name: "viewers", label: "Viewer" },
  ];
  displayedColumns: string[] = ["select", "name", "totalUsage", "totalUsers", "totalFiles", "managers"];
  search = "";
  searchUser = "";
  direction = "desc";
  activeOrderBy = "totalUsage";

  driveId = 0;
  maxResults = 10;
  private getCount = true;
  totalDriveCount = 0;
  page = 1;
  offset = 0;

  drives: Drives[] = [];

  user: User;

  nextButtonDis = false;
  nextButton = false;
  hideHeader = false;
  loading = false;

  /* filtered search stuff */
  private usageSizeSearch = "0";
  private sharedDriveSearch = "";
  private totalUsers = "";
  private externalUsers = "";
  private organizerSearch = "";
  private readerSearch = "";
  private commenterSearch = "";
  private writerSearch = "";
  private fileOrganizerSearch = "";

  @ViewChild(MatSort) sort!: MatSort;

  @Output() drivesEvent = new EventEmitter<Drives[]>();

  data = {
    usageSizeSearch: this.usageSizeSearch,
    sharedDriveSearch: this.sharedDriveSearch,
    totalUsers: this.totalUsers,
    externalUsers: this.externalUsers,
    page: this.page,
    offset: this.offset,
    organizerSearch: this.organizerSearch,
    readerSearch: this.readerSearch,
    commenterSearch: this.commenterSearch,
    writerSearch: this.writerSearch,
    fileOrganizerSearch: this.fileOrganizerSearch,
    filtersCount: 0,
  };

  constructor(
    private navService: NavService,
    private router: Router,
    private drivesService: DrivesService,
    private dialog: MatDialog,
    private notifyService: NotificationService,
    private filtersService: FiltersService
  ) {
    const tUser: string = localStorage.getItem("user") || "";
    this.user = tUser && tUser != "" ? JSON.parse(tUser) : null;
  }

  async ngOnInit() {
    const hasNavFilters = await this.filtersService.checkNavFilters(this.filterTypeValue);
    if (!hasNavFilters) this.loadData();
    this.actions.valueChanges.subscribe((value) => {
      if (value == "wipeSharedDrive") {
        const drives: Drive[] = [];
        for (let i = 0; i < this.selection.selected.length; i++) {
          drives.push(this.selection.selected[i]);
        }
        this.openConfirmationWipeDrive(drives);
      }

      if (value == "pullAllFiles") {
        this.navService.loading.next(true);
        const drives: Drive[] = [];
        for (let i = 0; i < this.selection.selected.length; i++) {
          drives.push(this.selection.selected[i]);
        }
        this.pullAllFiles(drives);
      }

      return;
    });
  }

  receiveData($event) {
    this.data = $event;
    this.usageSizeSearch = this.data.usageSizeSearch;
    this.sharedDriveSearch = this.data.sharedDriveSearch;
    this.organizerSearch = this.data.organizerSearch;
    this.readerSearch = this.data.readerSearch;
    this.commenterSearch = this.data.commenterSearch;
    this.writerSearch = this.data.writerSearch;
    this.fileOrganizerSearch = this.data.fileOrganizerSearch;
    this.totalUsers = this.data.totalUsers;
    this.externalUsers = this.data.externalUsers;
    this.page = this.data.page;
    this.offset = this.data.offset;
    this.loadData();
  }

  async loadData() {
    this.navService.loading.next(true);
    await this.getDrives();
    this.navService.loading.next(false);
  }

  private async getDrives(arrow = "") {
    this.updateLoader(true);
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.nextButton = true;
          this.getCount = !arrow;
          return this.drivesService.getDrives(
            this.usageSizeSearch,
            this.sharedDriveSearch,
            this.maxResults,
            this.activeOrderBy,
            this.direction,
            this.offset,
            this.getCount,
            this.totalUsers,
            this.externalUsers,
            this.organizerSearch,
            this.readerSearch,
            this.commenterSearch,
            this.writerSearch,
            this.fileOrganizerSearch
          );
        }),
        map((drives) => {
          if (drives) {
            this.drives = drives["data"];
            this.dataSource = new MatTableDataSource(this.drives);
            this.dataSource.sort = this.sort;
            //pagination
            this.totalDriveCount = this.getCount ? (drives["count"] ? drives["count"] : 0) : this.totalDriveCount;
            if (this.dataSource.filteredData.length < 10) {
              this.nextButtonDis = true;
            }
            if (this.dataSource.filteredData.length > 10) {
              this.nextButtonDis = false;
            }
            if (arrow === "next" && this.dataSource.filteredData.length + this.offset === this.totalDriveCount) {
              this.nextButtonDis = true;
            }
            if (arrow === "next" && this.dataSource.filteredData.length + this.offset < this.totalDriveCount) {
              this.nextButtonDis = false;
            }
            if (this.dataSource.filteredData.length + this.offset < this.totalDriveCount) {
              this.nextButtonDis = false;
            }
            if (this.dataSource.filteredData.length + this.offset === this.totalDriveCount) {
              this.nextButtonDis = true;
            }
            if (arrow === "prev" && this.totalDriveCount > 0) {
              this.nextButtonDis = false;
            }
            if (this.totalDriveCount === this.maxResults) {
              this.nextButtonDis = true;
            }
          }
          this.nextButton = false;
          this.updateLoader(false);
          return this.drives;
        }),
        catchError(() => {
          this.updateLoader(false);
          return observableOf([]);
        })
      )
      .subscribe((drives) => {
        if (drives) {
          this.updateLoader(false);
          return this.drives;
        }
      });
  }

  openConfirmationWipeDrive(drives: Drive[]) {
    let message =
      `Are you sure you want to wipe all drive contents and delete the shared drive` + (drives.length > 1 ? "s" : "");
    for (let i = 0; i < drives.length; i++) {
      if (i == drives.length - 1) {
        message += ` ${drives[i].name}`;
      } else {
        message += ` ${drives[i].name}, and`;
      }
    }
    message += `? This could take some time if there are lots of files in the shared drive.`;
    const btnOkText = `Confirm`;
    const dialogData = new ConfirmDialogModel("Delete Shared Drive and Contents", message, btnOkText);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        for (let i = 0; i < drives.length; i++) {
          await this.wipeSharedDrive(drives[i]);
        }
      }
      this.selection.clear();
      this.actions.setValue("");
    });
  }

  checkDrive() {
    let counter = 0;
    for (let i = 0; i < this.selection.selected.length; i++) {
      if (this.checkDeletedDrive(this.selection.selected[i])) {
        counter++;
      }
    }

    if (counter === 0) {
      return true;
    } else {
      return false;
    }
  }

  checkSynced() {
    let counter = 0;
    for (let i = 0; i < this.selection.selected.length; i++) {
      if (this.checkFilesSynced(this.selection.selected[i])) {
        counter++;
      }
    }

    if (counter === 0) {
      return true;
    } else {
      return false;
    }
  }

  private checkFilesSynced(selection: Drive) {
    if (selection["filesSynced"]) {
      return true;
    } else {
      return false;
    }
  }

  private checkDeletedDrive(selection: Drive) {
    if (selection["isDeleting"] === 0) {
      return true;
    } else {
      return false;
    }
  }

  async wipeSharedDrive(drive: Drive) {
    this.updateLoader(true);
    const response = await this.drivesService.wipeSharedDrive(drive["id"]);
    if (response) {
      await this.getDrives();
    }
    this.updateLoader(false);
  }

  async pullAllFiles(drives: Drive[]) {
    const resyncDrives = drives.map((drive) => {
      return this.drivesService.resyncDrivesFiles(drive["id"]);
    });

    await Promise.all(resyncDrives).then((response) => {
      if (response) {
        this.getDrives();
      }
    });

    this.notifyService.notify(`The drive files are being re-synced for the selected drives`);
  }

  async sendDrivesEmitter() {
    const drives = [];
    let response = {};
    let offset = 0;
    do {
      response = await this.drivesService.getDrives(
        this.usageSizeSearch,
        this.sharedDriveSearch,
        100,
        this.activeOrderBy,
        this.direction,
        offset,
        this.getCount,
        this.totalUsers,
        this.externalUsers,
        this.organizerSearch,
        this.readerSearch,
        this.commenterSearch,
        this.writerSearch,
        this.fileOrganizerSearch
      );
      offset = offset + 100;
      drives.push(...response["data"]);
    } while (response["data"].length > 0);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const emitObject: any = {
      drives: drives,
      columns: this.displayedColumns,
    };
    this.drivesEvent.emit(emitObject);
  }

  maxResultChange(value: number) {
    this.page = 1;
    this.offset = 0;
    this.maxResults = parseInt(value + "");
    this.getDrives("max");
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  resetToFirstPage() {
    this.page = 1;
    this.offset = 0;
    this.getDrives("");
  }

  setPager(data) {
    this.page = data.page;
    this.offset = data.offset;
    this.maxResults = data.maxResults ? data.maxResults : this.maxResults;
    this.loadData();
  }

  goToDrive(driveId: string) {
    this.router.navigate(["reports/storage/drives/" + driveId]);
  }

  getPageData() {
    return this.dataSource._pageData(this.dataSource._orderData(this.dataSource.filteredData));
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  masterToggle() {
    this.isEntirePageSelected()
      ? this.selection.deselect(...this.getPageData())
      : this.selection.select(...this.getPageData());
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.getPageData() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1}`;
  }

  tableColumnChange(value) {
    let add = true;
    for (let i = 0; i < this.displayedColumns.length; i++) {
      if (this.displayedColumns[i] == value.name) {
        if (i !== -1) {
          this.displayedColumns.splice(i, 1);
          add = false;
        }
      }
    }

    if (add) {
      this.displayedColumns.push(value.name);
    }
  }

  updateLoader(value: boolean) {
    for (const option of this.options) {
      if (option["name"] == "Export to Sheets" || option["name"] == "Export to PDF") {
        option["disabled"] = value;
      }
    }
    this.loading = value;
    this.navService.loading.next(value);
  }
}
