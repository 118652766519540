import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
	selector: "app-storage-report-more-info",
	templateUrl: "./storage-report-more-info.component.html",
	styleUrls: ["./storage-report-more-info.component.scss"],
})
export class StorageReportMoreInfoComponent implements OnInit {
	message: string;
	constructor(@Inject(MAT_DIALOG_DATA) public data) {
		this.message = `Shared Drive totals may be less than the organization’s totals as they are calculated based on the top 100 largest files of each drive.`;
	}

	ngOnInit(): void {
		if (this.data?.message) this.message = this.data.message;
	}
}
