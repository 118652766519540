import { Component, OnInit } from "@angular/core";
import { ReportsService, StorageReport } from "src/app/services/google/reports.service";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { NotificationService } from "src/app/services/utilities/notification.service";

@Component({
  selector: "app-storage-report-option",
  templateUrl: "./storage-report-option.component.html",
})
export class StorageReportOptionComponent implements OnInit {
  storageReport: StorageReport = {
    status: {
      name: "",
      id: 0,
    },
    totalUsers: 0,
    totalStorage: 0,
    totalDrive: 0,
    totalGmail: 0,
    totalPhotos: 0,
    allowDelete: false,
    usageLimit: 0,
  };

  constructor(
    private reportsService: ReportsService,
    private navService: NavService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  private async loadData() {
    this.navService.loading.next(true);

    const storageReport = await this.reportsService.getStorageReport();
    if (storageReport) {
      this.storageReport = storageReport;
    }
    this.navService.loading.next(false);
  }

  async saveStorageReport() {
    this.navService.loading.next(true);
    const storageReport = await this.reportsService.updateStorageReport(this.storageReport);
    if (storageReport) {
      this.notificationService.notify("Your Storage report config has been updated");
      this.storageReport = storageReport;
    }

    this.navService.loading.next(false);
  }
}
