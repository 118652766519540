<mat-list>
  <mat-list-item *ngIf="loginService.user" class="mb-2">
    <mat-icon matListItemIcon *ngIf="!loginService.user?.photoUrl">account_circle</mat-icon>
    <img
      matListItemIcon
      *ngIf="loginService.user?.photoUrl"
      src="{{ loginService.user.photoUrl }}"
      class="img-fluid rounded-circle mr-2"
      width="40"
      style="margin-top: -8px"
    />
    <div class="my-auto mb-2">
      <span>{{ loginService.user.firstName }} {{ loginService.user.lastName }}</span>
      <small class="text-muted d-block" style="font-size: 12px">{{ loginService.user.email }}</small>
    </div>
  </mat-list-item>
  <ng-container *ngFor="let option of options">
    <mat-list-item *ngIf="(loginService.user && option.isAuthenticated) || (!loginService.user && !option.isAuthenticated)">
      <a mat-button (click)="menuOption(option.text)" class="my-auto w-100 d-flex justify-content-start">
        <mat-icon matListItemIcon> {{ option.icon }} </mat-icon>
        {{ option.text }}
      </a>
    </mat-list-item>
  </ng-container>
</mat-list>
