<div class="container mt-5 mb-5">
  <h1 class="text-center">TERMS OF SERVICE FOR ADMIN+ AND RELATED SERVICES</h1>
  <p>
    <strong>1. Scope/Services</strong>
	</p>
	<p>
    These Terms of Service for Admin+ and Related Services (“Terms”) are effective by and between Trafera, LLC (“Trafera”) 
		and the customer (“Customer”) ordering Admin+ and related services (collectively, the “Services”).
  </p>
	<p>
		Subject to Customer’s compliance with all of the terms and conditions of these Terms, Trafera will use commercially 
		reasonable efforts to provide the Services specified on the quote (“Quote). The Services will be delivered using 
		Trafera’s proprietary software, processes, user interfaces, know-how, techniques, designs, ideas, concepts, and other 
		tangible or intangible technical material or information (“Trafera Technology”). Trafera may use third party 
		subcontractors to provide any of the Services provided that such subcontractors are contractually obligated to protect 
		the Confidential Information of Customer. Acceptance of the Services will be deemed to occur on the date the Services 
		are implemented (“Implementation Date”).
	</p>
  <p>
		Trafera will provide reasonable standard remote assistance to Customer in order to implement the Trafera software 
		applications listed on the order (the “Software Applications”). Trafera will attempt to provide Customer with access to 
		email technical support for questions 7 days per week. Trafera will use reasonable efforts to respond to requests within 
		24 hours. Support outside of normal business hours will be subject to availability and an additional fee may apply. 
		Support includes updates to the Software Applications that Trafera makes available from time to time (“Updates”) to its 
		customer base. Updates include bug fixes, error corrections, and minor improvements to the software Services. Trafera 
		may issue a full upgrade to the software Services (“Upgrade”). Upgrades provide significant additional functionality and 
		are outside the scope of this Agreement. Upgrades are made available to Trafera customers for an additional fee described 
		in a new order for such Upgrade.
	</p>
	<p>
		Trafera will endeavor to make the Software Applications available to Customer 99% of the time during normal business hours 
		of 8AM to 6PM, Central Time, Monday through Friday (the “Availability Standard”), excluding scheduled and emergency 
		maintenance hours. The Availability Standard excludes planned outages and any downtime caused by third party service 
		providers. The Availability Standard does not apply to Software Applications provided under a trial period or proof of 
		concept or designated as a “beta” version.
	</p>
	<p>
		<strong>2. Payment of Fees</strong>
	</p>
	<p>
		Customer will pay Trafera the applicable fees as set forth on the Quote. Set up and data transfer fees must be paid in 
		advance of Services being implemented. Monthly fees and time and materials fees are due on the first day of each calendar 
		month. Annual fees are due in advance of performance of Services. To the extent applicable and utilized, Customer will pay 
		Trafera for consulting, integration or other professional services. Customer will make all payments through the identified 
		payment method and in accordance with the terms on the Quote and these Terms. If not otherwise specified, payments will be 
		due within thirty (30) days of invoice. Fees paid for the Services are non-refundable.
	</p>
	<p>
		Trafera may suspend Customer’s access to the Services if Customer is more than ten (10) business days late on a payment. 
		Customer will pay a late fee on any amount that is not paid when due that will be calculated at an interest rate of one and 
		one-half percent (1.5%) per month on any such outstanding balance, or the maximum permitted by law, whichever is less, from 
		the date due, plus all expenses of collection.
	</p>
	<p>
		Customer will be billed, and payments will be made, in U.S. dollars. All fees are exclusive of all taxes, levies, or duties 
		imposed by taxing authorities. Customer will be responsible for payment of all such taxes, levies, or duties, excluding only 
		taxes based solely on Trafera’s net income.
	</p>
	<p>
		<strong>3. Term and Termination</strong>
	</p>
	<p>
		These Terms become effective upon acceptance of the first Quote. Unless earlier terminated as provided below, these Terms 
		will automatically expire when no Quote is effective. If a trial period or proof of concept is indicated under “Additional 
		Terms” in the Quote, the term of such trial period will be 30 days or such other period as is indicated on the Quote and the 
		initial one-year term will immediately commence after such trial period unless Customer gives notice of its intent to 
		terminate prior to the end of the trial period.
	</p>
	<p>
		Either party may terminate these Terms upon thirty (30) days’ written notice if the other party breaches any material term 
		of these Terms and fails to cure such breach within thirty (30) days after receipt of notice detailing such breach; provided, 
		however, such breach is capable of being cured within the thirty (30) day cure period. Material breach by the Customer 
		includes any breach of Customer’s payment obligations or unauthorized use by Customer of the Trafera Technology or Services.
	</p>
	<p>
		On termination of these Terms for any reason, any amounts owed to Trafera under these Terms before such termination or 
		expiration will be immediately due and payable, all licensed rights granted will immediately cease to exist, and Customer must 
		promptly discontinue all use of the Trafera Technology and erase all copies of the Trafera Technology. Customer will pay a 
		termination fee equal to the amount of months remaining in the term multiplied by the applicable monthly service fees.
	</p>
	<p>
		<strong>4. Permitted Uses, Restrictions, and Ownership</strong>
	</p>
	<p>
		Customer is solely responsible for (a) providing and maintaining the hardware and software necessary to access and use the 
		Services including the rights to access any Google API’s and (b) making available such personnel and information as may be 
		reasonably required, and taking such other actions as Trafera may reasonably request to provide the Services.
	</p>
	<p>
		Customer will not (and will not permit others to) (a) modify or interfere with the Software Applications or the Trafera 
		Technology; (b) reverse engineer, decompile, or attempt to discover the source code of the Software Applications, or the Trafera 
		Technology; or (c) resell or otherwise use the Software Applications for timesharing or service bureau purposes or for any 
		purpose other than its own internal non-commercial purposes unless expressly agreed in advance in writing. Non-production and 
		proof of concept/evaluation services may not be used in a production environment. Customer is fully responsible for providing 
		its own backups.
	</p>
	<p>
		As between the parties, Trafera (and its licensors, where applicable) own all right, title, and interest, in and to the Software 
		Applications, Trafera Technology, and any suggestions, ideas, enhancement requests, recommendations, feedback, or other 
		information provided by Customer which Customer hereby assigns to Trafera. Customer represents and warrants that: (a) Customer 
		owns or has the right to use all customer data accessed by Trafera in connection with the Services (“Customer Data”); (b) use of 
		Customer Data in connection with the Services does not violate any third-party rights; and (c) Customer has the right to provide 
		Trafera with access to Customer’s G Suite for Education Services.
	</p>
	<p>
		Trafera acknowledges that as between the parties, Customer owns all right, title and interest in and to the Customer Data, 
		provided, however, that Customer grants Trafera the right to use any and all Customer Data in blinded or aggregated form for the 
		purpose of data analysis, compilation, interpretation, study, reporting, publishing, improvement of the Software Service, product 
		and service development, and other such purposes.
	</p>
	<p>
		Except as expressly set forth herein, Trafera alone (and its licensors, where applicable) will retain all intellectual property 
		rights relating to the Services and Trafera Technology and any suggestions, ideas, enhancement requests, feedback, recommendations 
		or other information provided by Customer or any other party relating to the Services, which are hereby assigned to Trafera. 
		Customer will not copy, distribute, reproduce, or use any of the foregoing except as expressly permitted under these Terms. All 
		rights not expressly granted to Customer are reserved by Trafera and its licensors.
	</p>
	<p>
		If Customer is a school district (“District”), no change order or other form of order of directive will be issued by the District 
		requiring additional compensable Services to be performed by Trafera, which work causes the aggregate amount payable under these 
		Terms to Trafera to exceed the amount appropriated for the original agreement, unless the Trafera is given written assurance by the 
		District that lawful appropriations to cover the costs of the additional work have been made or unless such work is covered under a 
		remedy-granting provision in the agreement.
	</p>
	<p>
		Software Applications designated as a “beta” version may not be used in a production environment and are for evaluation and testing 
		purposes only.
	</p>
	<p>
		<strong>5. Confidentiality</strong>
	</p>
	<p>
		The parties to these Terms understand that the other party may disclose information relating to the its technology or business. The 
		parties want to ensure that when one party (“Disclosing Party”) discloses such information, the other party (“Receiving Party”) will 
		use and keep it in a restricted manner.
	</p>
	<p>
		“Confidential Information” means any proprietary information that one party discloses to the other in contemplation or fulfillment of 
		their business relationship; provided, that (a) the information is marked with a restrictive legend by Disclosing Party or (b) if 
		disclosed orally or in another non-tangible manner, such disclosure is identified at the time as confidential or followed reasonably 
		promptly by a written communication identifying the disclosure as confidential.  If Receiving Party is unsure of whether information 
		it receives is Confidential Information, Receiving Party must seek clarification from Disclosing Party before doing anything with 
		information that would be a breach of this Agreement if the information were in fact Confidential Information.  The manners in which a 
		party may disclose Confidential Information include, but are not limited to, in writing; by delivery of items; by initiation of access 
		to such information, for example via provision of access to a computer, computer system or database; or by oral communication or visual 
		presentation.  Confidential Information also includes (i) the fact of communication thereof; (ii) analysis or notes containing 
		Confidential Information; and (iii) the existence, success, or failure of any resulting business relationship.
	</p>
	<p>
		Receiving Party shall use Disclosing Party’s Confidential Information only in connection with the business relationship with Disclosing 
		Party and as may be necessary for the performance of obligations under any subsequent definitive agreement.  Receiving Party shall not 
		disclose any of Disclosing Party’s Confidential Information to any person except those employees, agents, consultants or subcontractors 
		of Receiving Party and its affiliates (“Representative(s)”) who need to know such information in order to achieve the Purpose.  Prior to 
		any such disclosure by Receiving Party of Disclosing Party’s Confidential Information to a Representative, Receiving Party shall inform 
		such Representative of Receiving Party’s obligations under this Agreement with respect to such information.  Representatives must be 
		subject to confidentiality obligations with regard to their receipt of and use of confidential information from Receiving Party, which 
		obligations must not be substantially less than those created hereby with regard to Receiving Party.  Receiving Party will be in breach 
		of this Agreement if any Representative does anything with Confidential Information that if done by Receiving Party would constitute a 
		breach of this Agreement.  Receiving Party shall not make any copies of Disclosing Party’s Confidential Information except as is necessary 
		to achieve the Purpose, nor shall Receiving Party reverse engineer, reverse compile or otherwise seek to acquire proprietary information or 
		intellectual property of Disclosing Party via disclosed Confidential Information.
	</p>
	<p>
		These terms impose no obligation upon Receiving Party with respect to information that (a) is or becomes part of the public domain without 
		breach of these Terms; (b) was in the lawful possession of Receiving Party or any of its Representatives before receipt from Disclosing 
		Party; (c) is received in good faith by Receiving Party or one of its Representatives from a third party in lawful possession of such 
		information and without a duty of confidentiality or fiduciary duty to Disclosing Party; or (d) is independently developed by or for 
		Receiving Party or any of its Representatives without use of Disclosing Party’s Confidential Information or direction from any individual 
		who has knowledge of that Confidential Information.
	</p>
	<p>
		Receiving Party shall protect Disclosing Party’s Confidential Information and prevent the unauthorized use, dissemination, or publication 
		of the Confidential Information by using the same standard of care as Receiving Party uses to protect its own confidential and proprietary 
		information of like nature, provided that such standard of care may not be less than a reasonable standard of care.
	</p>
	<p>
		Immediately upon becoming aware of an unauthorized use or disclosure of Disclosing Party’s Confidential Information, Receiving Party shall 
		notify Disclosing Party of the unauthorized use or disclosure.  Each party acknowledges that any breach of the terms contained herein may 
		result in irreparable and continuing damage to the Disclosing Party for which there may be no adequate remedy at law, and, in the event 
		thereof, money damages alone may not be a sufficient remedy for Disclosing Party.  Consequently, Disclosing Party shall be entitled to seek 
		any and all relief including, but not limited to, equitable relief and/or injunctive relief from any court of competent jurisdiction, without 
		any requirement to post bond.
	</p>
	<p>
		The Receiving Party may disclose Confidential Information if it is ordered to do so by a court or other competent governmental authority; 
		provided that Receiving Party, if not expressly prohibited by such court or governmental authority, provides Disclosing Party advanced 
		written notice of the order so that Disclosing Party may have an opportunity to seek a protective order.
	</p>
	<p>
		Trafera acknowledges that, subject to the exceptions listed in this Section 5, Customer Data is Customer’s Confidential Information. 
		Trafera will also protect Customer’s G Suite login credentials as Confidential Information. Customer acknowledges that all training materials 
		and Software Applications and any related documentation are Trafera’s  Confidential Information.
	</p>
	<p>
		<strong>6. Indemnification</strong>
	</p>
	<p>
		Trafera will indemnify and hold Customer and its officers, directors, employees, attorneys, and agents harmless from and against any and all 
		costs, damages, losses, liabilities and expenses (including reasonable attorneys’ fees and costs) each to the extent paid to an unaffiliated 
		third party to the extent arising out of or in connection with such third-party’s claim alleging that the Trafera Technology directly infringes 
		a U.S. copyright. Notwithstanding the foregoing, Trafera will have no obligation with respect to any infringement claim based upon any 
		modification of the Trafera Technology by Customer or any use of the Trafera Technology (a) not in accordance with the applicable documentation; 
		or (b) in combination with other products, equipment, software, or data not supplied by Trafera.
	</p>
	<p>
		To the extent permitted by applicable law, Customer will indemnify and hold Trafera and its officers, directors, employees, attorneys, and agents 
		harmless from and against any and all costs, damages, losses, liabilities and expenses (including reasonable attorneys’ fees and costs) each to 
		the extent paid to an unaffiliated third party to the extent arising out of or in connection with such third-party’s claim relating to the use of 
		the software by Customer, excluding any claim for which Trafera is obligated to indemnify Customer pursuant to this section.
	</p>
	<p>
		<strong>7. Warranties and Warranty Disclaimer</strong>
	</p>
	<p>
		Trafera represents and warrants that the Services will be provided in a professional and workmanlike manner. If Trafera breaches this warranty, 
		Customer may, as its sole remedy, request that Trafera promptly correct any such failure of to provide the applicable Service at no additional 
		charge. Except for the above warranty, Trafera provides all services to Customer without warranties, express or implied. Trafera and its licensors 
		do not represent or warrant that (a) the use of the Services will be secure, timely, uninterrupted or error-free or operate in combination with any 
		other hardware, software, system, or data, (b) the Services will meet requirements or expectations, (c) any results or stored Customer Data will be 
		accurate or reliable, (d) errors or defects will be corrected, (e) the Services or the server(s) that make the Software Applications available are 
		free of viruses or other harmful components; (f) the Services or results will meet any regulatory approvals or requirements. All conditions, 
		representations and warranties, whether express, implied, statutory or otherwise, including, without limitation, any implied warranty of 
		merchantability, fitness for a particular purpose, or non-infringement of third-party rights, are hereby disclaimed to the maximum extent permitted 
		by applicable law by Trafera and its licensors. Trafera makes no warranties, express or implied, as to Software Applications provided under a trial 
		period or proof of concept or designated as a “beta” version.
	</p>
	<p>
		<strong>8. Limitation of Liability</strong>
	</p>
	<p>
		In no event will Trafera’s aggregate liability arising from or relating to these Terms exceed the amounts actually paid by Customer in the twelve (12) 
		month period immediately preceding the event giving rise to such liability. In no event will either party and/or its licensors be liable to anyone for 
		any indirect, punitive, special, exemplary, incidental, consequential or other damages of any type or kind (including direct or indirect damages for 
		loss of data, revenue, profits, use or other economic advantage) arising out of, or in any way connected with this agreement, including but not 
		limited to the use or inability to use the Services, or for any content obtained from or through the Services, any interruption, inaccuracy, error or 
		omission in the content, even if the party from which damages are being sought or such party’s licensors have been previously advised of the 
		possibility of such damages. In no event will Trafera be liable for the acts or omissions of Customer in its use of the Services. Software Applications 
		provided under a trial period or proof of concept or designated as a “beta” version are provided “as is” and you assume all risks arising out of your 
		use of such Software Applications.
	</p>
	<p>
		<strong>9. Force Majeure</strong>
	</p>
	<p>
		Neither party will be liable to the other party for any default (other than failure to pay money) hereunder, for so long as such default is caused by an 
		event beyond such parties control, including, without limitation, acts or failures to act of the other party; strikes, labor or civil disputes; 
		component shortages; unavailability of transportation; fires, war, governmental requirements; epidemics or other health emergencies; and acts of God. In 
		the event of threatened or actual nonperformance as a result of any of the above causes, the non-performing party will exercise commercially reasonable 
		efforts to avoid and cure such nonperformance.
	</p>
	<p>
		<strong>10. Miscellaneous</strong>
	</p>
	<p>
		Trafera may assign or transfer these Terms in connection with a sale of substantially all of the business to which these Terms relate without Customer’s 
		prior written consent. Except as expressly provided for in this section, neither party may assign these Terms. These Terms will be governed by Delaware 
		law. No text or information set forth on any other purchase order, preprinted form or document (other than a Quote) will add to or vary the terms and 
		conditions of this Agreement, unless otherwise agreed to by the parties in writing. If any provision of these Terms is held by a court of competent 
		jurisdiction to be invalid or unenforceable, then such provision(s) will be construed, as nearly as possible, to reflect the intentions of the invalid or 
		unenforceable provision(s), with all other provisions remaining in full force and effect. During the software service term, Customer authorizes Trafera 
		to identify Customer as a Trafera customer on Trafera’s website and in Trafera’s marketing materials. The failure of either party to enforce any right or 
		provision in these Terms will not constitute a waiver of such right or provision unless acknowledged and agreed to by such party in writing. Any 
		modification or amendment of this Agreement will be in writing signed by the parties. The Software Applications and any accompanying documentation are 
		deemed to be “commercial computer software” and “commercial computer software documentation,” respectively, pursuant to DFAR Section 227.7202 and FAR 
		Section 12.212(b), as applicable, and are commercial products, licensed on the open market at market prices, and were developed entirely at private 
		expense and without the use of any government funds. Any use modification, reproduction, release, performance, display, or disclosure of the Services by 
		any government will be governed solely by these Terms and will be prohibited except to the extent expressly permitted by these Terms or other mutually 
		executed agreement. No license to the Software Applications is granted to any government requiring different terms. Customer will not use the Software 
		Applications to provide services to any public sector or government end user where such would affect Trafera’s rights in the Software Applications or 
		require any affirmative action to be taken by Trafera due to governmental mandates or flow down regulation. These Terms, together with any applicable 
		Quote or exhibits, comprises the entire agreement between Customer and Trafera regarding the subject matter contained herein and supersedes all prior or 
		contemporaneous negotiations, discussions or agreements, whether written or oral, between the parties regarding such subject matter. The following 
		Sections will survive any expiration or termination of these Terms: Section 2 “Payment of Fees”, Section 4 “Permitted Uses, Restrictions, and Ownership”, 
		Section 5 “Confidentiality, Section 8 “Limitation of Liability” and Section 10 “Miscellaneous.”
	</p>
	<p>
		Trafera reserves the right to update these Terms in the future. Any updates will be posted on the Admin+ platform.
	</p>
	<p>
		Updated: June 7, 2024
	</p>
</div>
