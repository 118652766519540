<h2 mat-dialog-title>API Controls to Google Workspace</h2>
<div mat-dialog-content>
  <app-warnings
    message="Domain wide delegation scopes (permissions) usually apply within minutes of creation. However, depending on the size of your organization, this process can take up to 24 hours."
  ></app-warnings>
  <p class="mx-3">
    {{ authService.appName }} cannot automatically add domain wide delegation scopes (permissions) for service accounts
    within a customer's Google Workspace. Click the "Add Scopes to Domain Wide Delegation" button below to get started.
  </p>
  <div class="text-center mb-2">
    <button mat-stroked-button class="create mt-2 mb-3" (click)="autoAddScopes()">
      Add Scopes to Domain Wide Delegation
    </button>
  </div>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-description class="my-auto">
          <mat-icon class="mr-2">help</mat-icon>
          Need additional help?
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <p>
          Sign into Google Workspace and add THIS service account to the
          <a href="https://admin.google.com/u/1/ac/owl/domainwidedelegation" target="_blank">domain wide delegation list</a>
          within your API Controls.
        </p>
        <ol>
          <li>Security</li>
          <li>Access and data control</li>
          <li>API Controls</li>
          <li>
            MANAGE DOMAIN WIDE DELEGATION<br />
            <small class="text-muted">Very bottom of page</small>
          </li>
          <hr />
          <li>Add new</li>
          <li>Copy and paste in the Client ID and Scopes below</li>
        </ol>
        <div class="alert alert-info my-auto">
          Client ID: <br />
          <a *ngIf="data.clientId" (click)="copyToClipboard(data.clientId, 'clientId')">
            <b>{{ data.clientId }}</b>
            <mat-icon matTooltip="Copy to clipboard" *ngIf="!clientIdCopied" class="ml-2">content_copy</mat-icon>
            <mat-icon matTooltip="Copied" *ngIf="clientIdCopied" class="ml-2">file_copy</mat-icon>
          </a>
          <i *ngIf="!data.clientId">Client ID not set - this is not required for the service account to work.</i>
          <br /><br />
          Scopes: <br />
          <a *ngIf="displayScopes" (click)="copyToClipboard(data.scopes, 'scopes')">
            <b [matTooltip]="tooltipDisplayScopes">{{ displayScopes }}</b>
            <mat-icon matTooltip="Copy to clipboard" *ngIf="!scopesCopied" class="ml-2">content_copy</mat-icon>
            <mat-icon matTooltip="Copied" *ngIf="scopesCopied" class="ml-2">file_copy</mat-icon>
          </a>
          <i *ngIf="!displayScopes">No scopes for this service account - you may need to link to a module.</i>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>

  <div mat-dialog-actions>
    <span matTooltip="Scopes required for testing" [matTooltipDisabled]="displayScopes">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button class="btn-success" (click)="openServiceAccountTestDialog()" [disabled]="!displayScopes">
        Test
      </button>
    </span>
  </div>
</div>
