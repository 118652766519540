<h2 mat-dialog-title>
  <span *ngIf="data.action == 'add'">Add Service Account</span>
  <span *ngIf="data.action == 'edit'">Edit Service Account</span>
</h2>

<div mat-dialog-content>
  <mat-tab-group mat-stretch-tabs [selectedIndex]="data.action == 'edit' ? 1 : 0">
    <mat-tab [disabled]="data.action == 'edit'" label="Automatic">
      <app-automatic-add-sa *ngIf="data.action != 'edit'" [data]="data" [dialogRef]="dialogRef"></app-automatic-add-sa>
    </mat-tab>
    <mat-tab label="Manual">
      <app-manual-add-edit-sa [data]="data" [dialogRef]="dialogRef"></app-manual-add-edit-sa>
    </mat-tab>
  </mat-tab-group>
</div>
