import { Component, OnInit } from "@angular/core";
import { LoginService } from "../../services/admin-plus/login.service";

@Component({
  template: "",
})
export class LogoutComponent implements OnInit {
  constructor(private loginService: LoginService) {}

  ngOnInit(): void {
    this.loginService.logout();
  }
}
