import { FlatTreeControl } from "@angular/cdk/tree";
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";

export interface TreeNode {
  id: string;
  name: string;
  parent?: string;
  children?: TreeNode[];
}

interface FlatNode {
  expandable: boolean;
  id: string;
  name: string;
  level: number;
}

@Component({
  selector: "app-tree-selector",
  templateUrl: "./tree-selector.component.html",
})
export class TreeSelectorComponent {
  private transformer = (node: TreeNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      id: node.id,
      name: node.name,
      parent: node.parent,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hideTitle: boolean;
  title = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialogRef: MatDialogRef<TreeSelectorComponent>) {
    this.dataSource.data = data.source;
    this.title = data.title ? data.title : "Tree Selector";
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  select(value: any) {
    this.dialogRef.close(value);
  }
}
