import { Injectable } from "@angular/core";
import { Manufacturer } from "src/app/models/asset-plus/manufacturer";
import { UtilityService, Payload } from "../utilities/utility.service";

@Injectable({
  providedIn: "root",
})
export class ManufacturerService {
  constructor(private utilityService: UtilityService) {}

  async createUpdateDevice(manufacturer: Manufacturer) {
    return new Promise<Payload>((resolve) => {
      const method = "POST";
      const path = `/devices/manufacturers/` + (manufacturer.id ? manufacturer.id : "");
      const body = manufacturer;

      this.utilityService.sendRequest(method, path, {}, body).subscribe((results) => {
        resolve(results);
      });
    });
  }

  async getManufacturers(params?) {
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("GET", `/devices/manufacturers`, params, null).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  async deleteManufacturer(manufacturer: Manufacturer) {
    return new Promise<Payload>((resolve) => {
      const path = `/devices/manufacturers/${manufacturer.id}`;

      this.utilityService.sendRequest("DELETE", path, {}, {}).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }
}
