import { Customer } from "src/app/services/admin-plus/customer.service";

export interface IStatus {
  id: number;
  customer?: Customer;
  name: string;
  edit?: boolean;
  parent?: {
    id: number;
    name: string
  }
}

export class Status implements IStatus {
  id: number;
  customer?: Customer;
  name: string;
  edit?: boolean;
  parent?: {
    id: number;
    name: string
  }

  constructor(properties) {
    Object.assign(this, properties);
  }
}
