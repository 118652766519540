<h2 mat-dialog-title>{{ title }}</h2>
<div class="mt-3" mat-dialog-content>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
      <button mat-button (click)="select(node)">{{ node.name }}</button>
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodeToggle>
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
        <mat-icon>
          {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
        </mat-icon>
      </button>
      {{ node.name }}
    </mat-tree-node>
  </mat-tree>
  <div mat-dialog-actions class="px-0">
    <button class="" type="button" mat-stroked-button mat-dialog-close>Cancel</button>
  </div>
</div>
