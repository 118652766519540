<app-page-header [options]="options" (optionSelected)="optionSelected($event)"></app-page-header>

<div class="row mt-2 pb-1 mb-3" *ngIf="drive && driveId !== '0' && drive.isDeleting === 1">
  <div class="col-12">
    <div class="alert alert-info">
      <div class="d-flex align-items-center justify-content-center mb-3">
        <strong>This shared drive is currently being deleted along with all of the shared drive contents.</strong>
      </div>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
</div>

<div class="row mt-2 pb-1 mb-3" *ngIf="drive && drive.filesSyncing && !drive.filesSynced">
  <div class="col-12">
    <div class="alert alert-info">
      <div class="d-flex align-items-center justify-content-center mb-3">
        <strong
          >All of the files for this shared drive are currently being re-synced and pulled into the storage report.
        </strong>
      </div>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
</div>

<div *ngIf="driveId !== '0'" class="row mt-2 pb-1 mb-3" id="entireScreen">
  <div class="col-12 col-md-4 mt-2 mt-md-0">
    <mat-card class="p-3">
      <div class="card-header d-flex align-items-center justify-content-between">
        <h2 class="mb-0">
          Total Usage
          <span
            *ngIf="drive && drive.totalFiles === 100"
            matTooltip="Partial data - run ‘Pull All Drive Files’ from the options menu in the top right to get the totals"
            class="text-danger"
            class="material-icons mat-icon mr-2"
            >info</span
          >
        </h2>
        <span *ngIf="drive">
          <p *ngIf="drive.totalUsage" style="font-size: 30px" class="text-center mb-0">
            {{ drive.totalUsage ? (drive.totalUsage / 1024 | number : "1.1-2") : 0 }}
            <small class="text-muted">GB</small>
          </p>
        </span>
      </div>
    </mat-card>
  </div>

  <div class="col-12 col-md-4 mt-2 mt-md-0">
    <mat-card class="p-3">
      <div class="card-header d-flex align-items-center justify-content-between">
        <h2 class="mb-0">Total Users</h2>
        <div class="d-flex align-items-center" *ngIf="drive">
          <p *ngIf="drive.totalUsers" style="font-size: 30px" class="text-center mb-0">
            {{ drive.totalUsers }}
          </p>
          <p *ngIf="!drive.totalUsers" style="font-size: 30px" class="text-center mb-0">0</p>
        </div>
      </div>
    </mat-card>
  </div>

  <div class="col-12 col-md-4 mt-2 mt-md-0">
    <mat-card class="card p-3">
      <div class="card-header d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <h2 class="mb-0">
            Total Files
            <span
              *ngIf="drive && drive.totalFiles === 100"
              matTooltip="Partial data - run ‘Pull All Drive Files’ from the options menu in the top right to get the totals"
              class="text-danger"
              class="material-icons mat-icon mr-2"
              >info
            </span>
          </h2>
        </div>

        <div class="d-flex align-items-center" *ngIf="drive">
          <p *ngIf="drive.totalFiles" class="mb-0" style="font-size: 30px">
            {{ drive.totalFiles }}
          </p>
          <p *ngIf="!drive.totalFiles" class="mb-0" style="font-size: 30px">0</p>
        </div>
      </div>
    </mat-card>
  </div>

  <div class="col-12 my-2 my-md-4">
    <mat-card class="p-3">
      <div class="card-header d-flex align-items-center justify-content-between w-100">
        <h2 class="mb-0">Drive Files</h2>

        <button
          mat-stroked-button
          class="success"
          (click)="filesSpreadsheet()"
          matTooltip="Moves table data below into Google Sheet"
        >
          <mat-icon> description </mat-icon>
          <span> Export to Sheets </span>
        </button>
      </div>

      <app-storage-report-files-table
        *ngIf="drive"
        (filesEvent)="receiveFilesEvent($event)"
        [viewOnly]="viewOnly"
        [storageReport]="storageReport"
      >
      </app-storage-report-files-table>
    </mat-card>
  </div>

  <div class="col-12 mt-2 mt-md-0">
    <mat-card class="p-3">
      <app-storage-report-drive-users [storageReport]="storageReport" *ngIf="this.storageReport.status.name != ''">
      </app-storage-report-drive-users>
    </mat-card>
  </div>
  <div *ngIf="driveId === '0'" class="alert alert-warning text-center">
    <span class="material-icons mat-icon mr-3"> warning </span>
    Failed to load drive data.<br />
    <small class="text-muted">
      Please try <a routerLink="../" title="All drives">searching</a> for the intended drive again.
    </small>
  </div>
</div>
