import { Component } from "@angular/core";
import { FormControl, FormGroupDirective, NgForm, Validators } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatDialogRef } from "@angular/material/dialog";

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: "app-user-prompt",
  templateUrl: "./user-prompt.component.html",
})
export class UserPromptComponent {
  usersFormControl = new FormControl(null, [Validators.required, Validators.min(1)]);

  matcher = new MyErrorStateMatcher();

  constructor(private dialogRef: MatDialogRef<UserPromptComponent>) {}

  public onSubmit() {
    if (!this.usersFormControl.invalid) this.dialogRef.close(this.usersFormControl.value);
  }
}
