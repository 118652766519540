import { AfterViewInit, Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { AutomationService, Workflow } from "src/app/services/workflow-plus/automation.service";

@Component({
  selector: "app-dialog-workflow-additional-information",
  templateUrl: "./dialog-workflow-additional-information.component.html",
  styleUrls: ["./dialog-workflow-additional-information.component.scss"],
})
export class DialogWorkflowAdditionalInformationComponent implements OnInit {
  workflowId: string;
  workflow: Workflow;
  loading: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private automationService: AutomationService,
    private navService: NavService,
    private router: Router
  ) {
    this.loading = false;
    this.workflowId = this.data.workflowId;
  }

  ngOnInit() {
    this.navService.loading.next(true);
    this.loadData();
    this.navService.loading.next(false);
  }

  async loadData() {
    try {
      this.loading = true;
      this.workflow = await this.automationService.getWorkflowTemplate(Number(this.workflowId));
    } finally {
      this.loading = false;
    }
  }

  async copy() {
    const newWorkflowId = await this.automationService.copyWorkflow(Number(this.workflowId), true);
    this.router.navigate(["/automation/workflows/" + newWorkflowId["newId"]]);
  }
}
