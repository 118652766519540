import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { UntypedFormControl } from "@angular/forms";
import { SelectionModel } from "@angular/cdk/collections";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { CustomerService, Job } from "src/app/services/admin-plus/customer.service";
import { merge, of as observableOf } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { Sort } from "@angular/material/sort";
import { FiltersService, FilterType } from "../../services/utilities/filters.service";

@Component({
  selector: "app-jobs",
  templateUrl: "./jobs.component.html",
  styleUrls: ["./jobs.component.scss"],
})
export class JobsComponent implements OnInit {
  public displayedColumns: string[] = ["status", "type", "user", "start", "end", "details", "executed"];

  public dataSource = new MatTableDataSource<Job>();
  public actions = new UntypedFormControl();
  public mimeTypeActions = new UntypedFormControl();
  public maxResultsActions = new UntypedFormControl();
  public usageSize = new UntypedFormControl();
  public userCtrl = new UntypedFormControl();
  public selection = new SelectionModel<File>(true, []);

  public maxResults = 10;
  private getCount = true;
  public totalJobCount = 0;
  public page = 1;
  public offset = 0;

  public jobs: Job[] = [];

  public nextButtonDis = false;
  public nextButton = false;
  public nextPageDisabled = false;
  public loading = false;

  /* filtered search stuff */
  private firstNameSearch = "";
  private lastNameSearch = "";
  private emailSearch = "";
  private createdAfterSearch = "";
  private createdBeforeSearch = "";
  private statusSearch = "";
  private typeSearch = "";

  public filterTypeValue: FilterType = FilterType.JOB;

  public data = {
    firstNameSearch: this.firstNameSearch,
    lastNameSearch: this.lastNameSearch,
    emailSearch: this.emailSearch,
    createdAfterSearch: this.createdAfterSearch,
    createdBeforeSearch: this.createdBeforeSearch,
    statusSearch: this.statusSearch,
    typeSearch: this.typeSearch,
    page: this.page,
    offset: this.offset,
    filtersCount: 0,
  };

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private customerService: CustomerService,
    private navService: NavService,
    private filtersService: FiltersService
  ) {}

  async ngOnInit() {
    const hasNavFilters = await this.filtersService.checkNavFilters(this.filterTypeValue);
    if (!hasNavFilters) this.loadData();
  }

  receiveData($event) {
    this.data = $event;
    this.firstNameSearch = this.data.firstNameSearch;
    this.lastNameSearch = this.data.lastNameSearch;
    this.emailSearch = this.data.emailSearch;
    this.createdAfterSearch = this.data.createdAfterSearch;
    this.createdBeforeSearch = this.data.createdBeforeSearch;
    this.statusSearch = this.data.statusSearch;
    this.typeSearch = this.data.typeSearch;
    this.page = this.data.page;
    this.offset = this.data.offset;
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  private async loadData(arrow = "") {
    this.navService.loading.next(true);
    await this.getJobs(arrow);
    this.navService.loading.next(false);
  }

  private async getJobs(arrow = "") {
    this.loading = true;
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.navService.loading.next(true);
          this.selection.clear();
          this.nextButton = true;
          this.getCount = !arrow;
          return this.customerService.getJobs(
            this.firstNameSearch,
            this.lastNameSearch,
            this.emailSearch,
            this.createdAfterSearch,
            this.createdBeforeSearch,
            this.statusSearch.toUpperCase(),
            this.typeSearch.toUpperCase(),
            this.maxResults,
            this.offset,
            true
          );
        }),
        map((jobs) => {
          this.loading = false;
          if (jobs) {
            this.jobs = jobs["data"];
            this.dataSource = new MatTableDataSource(this.jobs);
            this.dataSource.sort = this.sort;
            this.nextPageDisabled = this.dataSource.data.length < this.maxResults ? true : false;
            //pagination
            this.totalJobCount = this.getCount ? (jobs["count"] ? jobs["count"] : 0) : this.totalJobCount;
            if (this.dataSource["filteredData"].length < 10) {
              this.nextButtonDis = true;
            }
            if (this.dataSource["filteredData"].length > 10) {
              this.nextButtonDis = false;
            }
            if (arrow === "next" && this.dataSource["filteredData"].length + this.offset === this.totalJobCount) {
              this.nextButtonDis = true;
            }
            if (arrow === "next" && this.dataSource["filteredData"].length + this.offset < this.totalJobCount) {
              this.nextButtonDis = false;
            }
            if (this.dataSource["filteredData"].length + this.offset < this.totalJobCount) {
              this.nextButtonDis = false;
            }
            if (this.dataSource["filteredData"].length + this.offset === this.totalJobCount) {
              this.nextButtonDis = true;
            }
            if (arrow === "prev" && this.totalJobCount > 0) {
              this.nextButtonDis = false;
            }
            if (this.totalJobCount === this.maxResults) {
              this.nextButtonDis = true;
            }
          }
          this.dataSource.sortingDataAccessor = (data: Job, sortHeaderId: string) => {
            if (typeof data[sortHeaderId] === "string") {
              return data[sortHeaderId].toLowerCase();
            }
            return data[sortHeaderId];
          };
          this.nextButton = false;
          this.navService.loading.next(false);
          return this.jobs;
        }),
        catchError(() => {
          this.loading = false;
          return observableOf([]);
        })
      )
      .subscribe((files) => {
        if (files) {
          //This block required for page functionality.
        }
      });
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === "") {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "status":
          return compare(a["status"]["name"], b["status"]["name"], isAsc);
        case "type":
          return compare(a["type"]["name"], b["type"]["name"], isAsc);
        case "user":
          return compare(a["user"]["name"], b["user"]["name"], isAsc);
        case "start":
          return compare(a["start"], b["start"], isAsc);
        case "end":
          return compare(a["end"], b["end"], isAsc);
        case "executed":
          return compare(a["executed"], b["executed"], isAsc);
        default:
          return 0;
      }
    });
  }

  parseJSON(string) {
    return JSON.parse(string);
  }

  onSubmit() {
    this.loadData("");
  }

  public setPager(data) {
    this.page = data.page;
    this.offset = data.offset;
    this.maxResults = data.maxResults ? data.maxResults : this.maxResults;
    this.loadData(data.arrow);
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
