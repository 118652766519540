import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { FilesService } from "src/app/services/storage-plus/files.service";
import { DrivesService } from "src/app/services/storage-plus/drives.service";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { TransferOwnershipComponent } from "src/app/layout/transfer-ownership/transfer-ownership.component";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ConfirmDialogModel,
  ConfirmationDialogComponent,
} from "src/app/layout/dialogs/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { ReportsService, StorageReport, User, Historical, File } from "src/app/services/google/reports.service";
import { DialogTransferComponent } from "src/app/layout/dialogs/dialog-transfer/dialog-transfer.component";
import { StorageReportMoreInfoComponent } from "../dialogs/storage-report-more-info/storage-report-more-info.component";
import { FilterType } from "src/app/services/utilities/filters.service";
import { CustomerService } from "src/app/services/admin-plus/customer.service";
import { AdminPlusService } from "src/app/services/admin-plus/admin-plus.service";
import { UtilityService } from "src/app/services/utilities/utility.service";

@Component({
  selector: "app-storage-report-user",
  templateUrl: "./storage-report-user.component.html",
  styleUrls: ["./storage-report-user.component.scss"],
})
export class StorageReportUserComponent implements OnInit, OnDestroy {
  options = [];
  private transferInterval;
  private pullAllFilesInterval;
  user: User = {
    firstName: "",
    lastName: "",
    email: "",
    fileTransfer: 0,
    partialFiles: 0,
  };

  loggedInUser;
  storageReport: StorageReport = {
    status: {
      name: "",
      id: 0,
    },
    totalUsers: 0,
    totalStorage: 0,
    totalDrive: 0,
    totalGmail: 0,
    totalPhotos: 0,
    previousTotal: 0,
  };
  storageByType = [];
  historicalCached: Historical[] = [];
  historical: Historical[] = [];
  userId: string;
  viewOnly: boolean;
  files: File[] = [];
  unit = "MB";
  divide = 1;
  finishedLoading = false;
  clickedSyncFiles: boolean;
  historicalLoaded: boolean;
  userHistoricalData: Historical[] = [];
  destroy = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private reportsService: ReportsService,
    private filesService: FilesService,
    private drivesService: DrivesService,
    private notifyService: NotificationService,
    private navService: NavService,
    private dialog: MatDialog,
    private customerService: CustomerService,
    private adminPlusService: AdminPlusService,
    private utilityService: UtilityService
  ) {
    const tUser: string = localStorage.getItem("user") || "";
    this.loggedInUser = tUser && tUser != "" ? JSON.parse(tUser) : null;

    this.options.push({
      icon: "picture_as_pdf",
      name: "Export to PDF",
      tooltip: "Saves current page to PDF",
      class: "export",
    });

    this.adminPlusService.breadCrumbLinks.push({
      link: "/reports/storage/users",
      text: "Users",
      alt: "Users",
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  optionSelected(option) {
    switch (option.name) {
      case "Export to PDF":
        this.utilityService.downloadAsPDF();
        break;
      case "Pull All Files":
        this.resyncFiles();
        break;
      case "Transfer to Google Cloud":
        this.openTransferDialog();
        break;
      case "Transfer Ownership of Drive Contents":
        this.transferOwnership();
        break;
      default:
        break;
    }
  }

  private async loadData() {
    this.navService.loading.next(true);
    this.options.forEach((option) => {
      option.disabled = true;
    });
    this.storageReport = await this.reportsService.getStorageReport();
    if (!this.storageReport) {
      this.navService.loading.next(false);
      this.router.navigate(["/reports/storage"]);
    }
    if (this.storageReport.id) {
      this.userId = this.activatedRoute.snapshot.paramMap.get("userId") || "";
      this.user = await this.reportsService.getStorageReportUser(this.userId);
      if (this.user.id) {
        const fullName = this.user.firstName + " " + this.user.lastName;
        if (!this.destroy) {
          this.adminPlusService.breadCrumbLinks.push({
            link: null,
            text: fullName,
            alt: fullName,
          });
          const fileData = await this.reportsService.getStorageReportFiles(
            10,
            "storageMB",
            "desc",
            "",
            "",
            "",
            "",
            "",
            0,
            "",
            this.user.id,
            "",
            0,
            "",
            "",
            "",
            false
          );
          this.files = fileData["data"];
          this.storageByType = [
            ["Drive", this.user.driveTotal / 1024],
            ["Gmail", this.user.gmailTotal / 1024],
            ["Photos", this.user.photosTotal / 1024],
          ];

          //Load historical data
          this.loadHistorical();
          if (this.user.fileTransfer != 0) {
            if (!this.destroy) this.checkTransfer();
            this.toggleButtons();
          }

          if (this.user.filesSyncing && !this.user.filesSynced) {
            if (!this.destroy) this.checkPullAllFiles();
            this.toggleButtons();
          }

          if (this.user?.fileTransfer == 0 && this.user?.filesSynced != null) {
            this.options.push({
              icon: "file_download",
              name: "Pull All Files",
              tooltip: "Cache all file data for this user",
              class: "primary",
            });

            if (this.user?.fileTransfer == 0 && this.user?.filesSynced != null) {
              this.options.push({
                icon: "manage_accounts",
                name: "Transfer Ownership of Drive Contents",
                tooltip: "Transfers the ownership of this drive's contents to another user in your organization",
                class: "primary",
              });
            }
            if (this.customerService.customer.gcsEnabled) {
              this.options.push({
                icon: "cloud_upload",
                name: "Transfer to Google Cloud",
                tooltip: "Transfers this user drive content to Google Cloud",
                class: "primary",
              });
            }
          }
        }
      }
    }
    this.options.forEach((option) => {
      option.disabled = false;
    });
    this.navService.loading.next(false);
    this.finishedLoading = true;
  }

  async loadHistorical() {
    if (this.reportsService.userHistoricalData[this.user.id]) {
      this.historical = this.reportsService.userHistoricalData[this.user.id];
    } else {
      const reportData = [];
      const data = await this.reportsService.getHistorical(this.storageReport.id, this.user.googleId);
      for (let i = 0; i < data.length; i++) {
        reportData.push([
          data[i].reportDate,
          data[i].totalStorage / 1024,
          data[i].totalDrive / 1024,
          data[i].totalGmail / 1024,
          data[i].totalPhotos / 1024,
        ]);
      }
      this.historical = reportData;
      this.reportsService.userHistoricalData[this.user.id] = reportData;
    }
    this.historicalLoaded = true;
  }

  private transferOwnershipConfirmation(user: User) {
    const message = `
      Are you sure you want to transfer ownership of all of the drive contents to 
      the user ${user[0]["name"]["fullName"]}?  
    `;
    const btnOkText = `Transfer Ownership`;
    const dialogData = new ConfirmDialogModel("Transfer Ownership of Drive Contents", message, btnOkText);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      disableClose: true,
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((afterClosedResult) => {
      if (afterClosedResult) {
        this.toggleButtons();
        this.transferOwnershipRequest(user[0]);
      }
    });
  }

  private transferOwnership() {
    const dialogRef = this.dialog.open(TransferOwnershipComponent, {
      width: "500px",
      disableClose: true,
      data: {
        user: this.user,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.transferOwnershipConfirmation(result);
      }
    });
  }

  private async transferOwnershipRequest(user: User) {
    this.navService.loading.next(true);
    const response = await this.drivesService.transferOwnership(this.user, user);
    if (!response["error"]) {
      this.user.fileTransfer = 1;
      this.notifyService.notify(`
        The user's ${this.user["firstName"]} ${this.user["lastName"]} drive contents are currently being transferred. 
        The new folder will be called ${this.user["email"]}
      `);
      if (!this.destroy) this.checkTransfer();
    } else {
      this.notifyService.notify(response["error"]["message"]);
    }
    this.navService.loading.next(false);
  }

  async viewFiles() {
    const tableId = `_${FilterType.FILE.toString()}`;
    const paramKey = encodeURI("Email") + tableId;
    this.router.navigate(["../../files"], {
      relativeTo: this.activatedRoute,
      queryParams: { [paramKey]: this.user.email },
      state: {
        addChip: true,
      },
    });
  }

  private resyncFiles() {
    const message = `Are you sure you want to pull all files for this user?  This could take a few minutes`;
    const btnOkText = `Pull files`;
    const dialogData = new ConfirmDialogModel("Pull all files", message, btnOkText);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      width: "500px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.resyncFilesConfirmed();
      }
    });
  }

  async resyncFilesConfirmed() {
    this.navService.loading.next(true);
    this.toggleButtons();
    const response = await this.filesService.resyncFiles(this.user.id);
    if (response) {
      this.notifyService.notify("The files for this user are being re-synced");
      this.user.filesSynced = null;
      this.viewOnly = true;
      if (!this.destroy) this.checkPullAllFiles();
    }
    this.navService.loading.next(false);
  }

  private toggleButtons(disabled = true) {
    for (let i = 0; i < this.options.length; i++) {
      if (i != 0) this.options[i].disabled = disabled;
    }
  }

  openTransferDialog() {
    const dialogRef = this.dialog.open(DialogTransferComponent, {
      disableClose: true,
      width: "500px",
      data: {
        files: [],
        isDrive: true,
        isZip: true,
        zipFile: this.user.email,
        sendEmail: false,
        drive: {
          googleId: this.user.googleId,
          type: "user",
          name: this.user.email,
        },
        isMove: false,
        folderPath: "",
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.toggleButtons();
      }
    });
  }

  checkTransfer() {
    this.transferInterval = setInterval(async () => {
      const user = await this.reportsService.getStorageReportUser(this.userId);
      if (!Number.isNaN(user.fileTransfer) && user.fileTransfer === 0) {
        this.user.fileTransfer = 0;
        clearInterval(this.transferInterval);
        this.toggleButtons(false);
      }
    }, 10000);
  }

  checkPullAllFiles() {
    this.pullAllFilesInterval = setInterval(async () => {
      const user = await this.reportsService.getStorageReportUser(this.userId);
      user.filesSyncing && !user.filesSynced;
      if (user.filesSynced) {
        this.user.filesSyncing = user.filesSyncing;
        this.user.filesSynced = user.filesSynced;
        clearInterval(this.pullAllFilesInterval);
        this.toggleButtons(false);
      }
    }, 10000);
  }

  ngOnDestroy(): void {
    this.destroy = true;
    clearInterval(this.transferInterval);
    clearInterval(this.pullAllFilesInterval);
  }

  openMoreInfoDialog() {
    this.dialog.open(StorageReportMoreInfoComponent, {
      data: {
        message:
          "User usage totals may differ slightly from file totals because of differences in the calculations between Google Admin Usage and Google Drive Usage",
      },
    });
  }
}
