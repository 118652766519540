<h2 mat-dialog-title class="d-flex align-items-center" style="gap: 8px">
  <mat-icon>group</mat-icon>
  Total Organization Users
</h2>
<div mat-dialog-content>
  Please enter the total user count (including suspended) within your organization.
  <form>
    <mat-form-field class="w-100 mt-4">
      <mat-label>Total Users</mat-label>
      <input type="number" matInput [formControl]="usersFormControl" min="1" required />
      <mat-hint><sup>*</sup>User count is used for billing and reporting</mat-hint>
      <mat-error *ngIf="usersFormControl.hasError('min') && !usersFormControl.hasError('required')">
        Please enter a valid user count
      </mat-error>
      <mat-error *ngIf="usersFormControl.hasError('required')"> Total users is <strong>required</strong> </mat-error>
    </mat-form-field>
  </form>

  <div mat-dialog-actions class="px-0">
    <button mat-stroked-button class="success ml-2" (click)="onSubmit()">Submit</button>
  </div>
</div>
