import { Component, OnInit, Inject } from "@angular/core";
import { DatePipe } from "@angular/common";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UntypedFormControl } from "@angular/forms";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { GroupsService } from "src/app/services/admin-plus/groups.service";
import { NavService } from "src/app/services/admin-plus/nav.service";

export interface Role {
  name: string;
  selected: boolean;
  expires: string;
  time: string;
}

const ROLE_DATA: Role[] = [
  { name: "OWNER", selected: false, expires: "", time: "" },
  { name: "MANAGER", selected: false, expires: "", time: "" },
  { name: "MEMBER", selected: false, expires: "", time: "" },
];

@Component({
  selector: "app-edit-member-roles",
  templateUrl: "./edit-member-roles.component.html",
  styleUrls: ["./edit-member-roles.component.scss"],
})
export class EditMemberRolesComponent implements OnInit {
  public displayedColumns: string[] = ["selected", "name", "expires"];
  public dataSource = ROLE_DATA;
  public selected_member: any;
  private selected_member_id: string;
  public error: string;
  public memberExpiration: boolean = false;
  public newTime: string = "00:00";
  public newTimeChange: boolean = false;
  public time = { hour: 0, minute: 0 };
  public meridian = true;
  public datePipeString: string;
  public memberEmail: string;
  public timeString: string;
  public isChecked: boolean = false;
  public disabled: boolean = true;
  public todayDate: Date = new Date();
  public enterprise: boolean = false;

  public expirationdate;
  public localDateTime;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: NotificationService,
    public groupsService: GroupsService,
    private dialogRef: MatDialogRef<EditMemberRolesComponent>,
    private datePipe: DatePipe,
    private navService: NavService
  ) {}

  ngOnInit(): void {
    this.enterprise = this.data.enterprise;
    var nameArray = this.data.member.name.split("/");
    this.selected_member_id = nameArray[3];
    this.getMember(this.data.id, this.selected_member_id);
  }

  async getMember(groupid, memberid) {
    //this.navService.loading.next(true);
    this.error = null;
    var response = await this.groupsService.getMember(groupid, memberid);
    if (response) {
      this.selected_member = response;
      this.memberEmail = this.selected_member.preferredMemberKey.id.substring(
        0,
        this.selected_member.preferredMemberKey.id.lastIndexOf("@")
      );
      this.memberEmail = this.memberEmail.replace(/.*@/, "");
      this.setMembershipRoles(this.selected_member);
      if (this.selected_member.roles[0].expiryDetail !== undefined) {
        //Needs to check for "MEMBER"
        this.memberExpiration = true;
        this.isChecked = true;
        this.localDateTime = new Date(this.selected_member.roles[0].expiryDetail.expireTime);
        this.datePipeString = this.datePipe.transform(this.localDateTime, "MM/dd/yyyy");
        this.expirationdate = new UntypedFormControl(new Date(this.datePipeString));
        this.time = {
          hour: this.localDateTime.getHours(),
          minute: this.localDateTime.getMinutes(),
        };
      } else {
        this.expirationdate = new UntypedFormControl();
      }
      this.enableSlider();
    }
    //this.navService.loading.next(false);
  }

  private setMembershipRoles(member: any) {
    for (var j = 0; j < this.dataSource.length; j++) {
      this.dataSource[j]["selected"] = false;
    }
    var roles = member.roles;
    for (var i = 0; i < roles.length; i++) {
      for (var j = 0; j < this.dataSource.length; j++) {
        if (roles[i]["name"] == this.dataSource[j]["name"]) {
          this.dataSource[j]["selected"] = true;
        }
      }
    }
  }

  setExpiration() {
    this.memberExpiration = !this.memberExpiration;
  }

  onTimeChange(value: { hour: string; minute: string }) {
    this.newTimeChange = true;
    this.newTime = `${value.hour}:${value.minute}`;
  }

  processForm() {
    this.saveRoles();
  }

  private async saveRoles() {
    this.navService.loading.next(true);
    var roles = this.data.member.roles;

    for (var i = 0; i < roles.length; i++) {
      for (var j = 0; j < this.dataSource.length; j++) {
        if (roles[i]["name"] == this.dataSource[j]["name"]) {
          if (roles[i]["name"] == "MEMBER" && this.isChecked) {
            this.dataSource[j]["expires"] = this.expirationdate.value;
            if (this.newTimeChange === true) {
              this.dataSource[j]["time"] = this.newTime;
            } else {
              if (this.localDateTime === undefined) {
                this.timeString = "00:00";
                this.dataSource[j]["time"] = this.timeString;
              } else {
                if (this.localDateTime.getHours() === 0 && this.localDateTime.getMinutes() === 0) {
                  this.timeString = "00:00";
                  this.dataSource[j]["time"] = this.timeString;
                } else {
                  this.timeString = this.localDateTime.getHours() + ":" + this.localDateTime.getMinutes();
                  this.dataSource[j]["time"] = this.timeString;
                }
              }
            }
          } else {
            this.dataSource[j]["expires"] = "";
            this.dataSource[j]["time"] = "";
          }
        }
      }
    }

    //I have no idea what's up above exactly
    var newRoles: any[] = [];
    for (var i = 0; i < this.dataSource.length; i++) {
      if (this.dataSource[i]["selected"]) {
        var newRole = {
          name: this.dataSource[i]["name"],
        };
        newRoles.push(newRole);
      }
    }

    if (newRoles.length == 1) {
      //must be member
      if (this.expirationdate.value) {
        var datetime = this.expirationdate.value;
        var hourMin = this.newTime.split(":");
        datetime.setHours(datetime.getHours() + hourMin[0]);
        datetime.setMinutes(datetime.getMinutes() + hourMin[1]);
        newRoles[0]["expiryDetail"] = {
          expireTime: datetime.toISOString(),
        };
      }
    }

    var newMember = {
      preferredMemberKey: {
        id: this.selected_member.preferredMemberKey.id,
      },
      roles: newRoles,
    };
    var response = await this.groupsService.deleteMember(this.selected_member);
    if (response) {
      var memResponse = await this.groupsService.addMember(this.data.id, newMember);
      if (memResponse) {
        var updatedRole = {
          email: this.selected_member.preferredMemberKey.id,
          newRole: newRoles[0].name,
        };
        this.notificationService.notify("Member roles updated");
        this.dialogRef.close(updatedRole);
      } else {
        var member = {
          preferredMemberKey: {
            id: this.selected_member.preferredMemberKey.id,
          },
          roles: this.selected_member["roles"],
        };
        var addMemberResponse = await this.groupsService.addMember(this.data.id, member);
        if (addMemberResponse) {
          //The system will show the error
          //this.notificationService.notify("Make sure the expiration date and time is not in the past");
        }
      }
      this.navService.loading.next(false);
    }
  }

  toggleMembership(element: any) {
    for (var i = 0; i < this.dataSource.length; i++) {
      if (this.dataSource[i]["name"] == element["name"]) {
        this.dataSource[i]["selected"] = !this.dataSource[i]["selected"];
      }
    }

    this.enableSlider();
  }

  enableSlider() {
    var count = 0;
    for (var i = 0; i < this.dataSource.length; i++) {
      if (this.dataSource[i]["selected"]) {
        count++;
      }
    }
    this.disabled = count > 1;
  }
}
