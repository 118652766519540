<h2 mat-dialog-title>Unable To Create Service Account</h2>
<div mat-dialog-content>
  <div class="alert alert-info d-flex align-items-center">
    <div class="pr-3">
      <mat-icon>help</mat-icon>
    </div>
    <div>
      The automated tool was unable to create the service account. One possible solution is to verify you can log into
      <a href="https://console.cloud.google.com/">https://console.cloud.google.com/</a>
      and have accepted Google's Terms of Service.
    </div>
  </div>
  <div class="px-3" *ngIf="data.message">
    <h4>Error:</h4>
    &nbsp;&nbsp;<small>{{ data.message }}</small>
  </div>
  <div mat-dialog-actions class="px-0">
    <button mat-stroked-button mat-dialog-close>Close</button>
    <button mat-stroked-button create (click)="retry()">Retry</button>
  </div>
</div>
