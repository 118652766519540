<h2 mat-dialog-title>
    Info - {{ data.title }}
</h2>
<div mat-dialog-content>
    <div *ngIf="!data.list">
        {{ data.message }}
    </div>
    <div *ngIf="data.list">
        <ul *ngFor="let item of listArray">
            <li>{{ item }}</li>
        </ul>
    </div>
    <div mat-dialog-actions class='px-0'>
        <button (click)="close()" class="success" mat-stroked-button >Close</button>
    </div>
</div>