import { Component, Input } from "@angular/core";
import { Ticket } from "src/app/models/ticket-plus/ticket";
import { Payload } from "src/app/services/utilities/utility.service";

@Component({
  selector: "app-icon-badge-list",
  templateUrl: "./icon-badge-list.component.html",
  styleUrls: ["./icon-badge-list.component.scss"],
})
export class IconBadgeListComponent {
  @Input() iconName: string;
  @Input() matTooltipDisplay: string;
  @Input() ariaLabelDisplay: string;
  @Input() matSubheaderDisplay: string;
  @Input() matButtonDisplay: string;
  @Input() ticket: Ticket = new Ticket({});
  @Input() badgeClass: string;
  @Input() popupClass: string;
  @Input() displayButton: boolean;
  @Input() payload: Payload;

  openTicket(ticketId: string) {
    window.open(`/ticketing/tickets/${ticketId}`, "_blank");
  }

  matBadge() {
    return this.payload ? this.payload.count : "-";
  }
}
