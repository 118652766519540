<h2 mat-dialog-title>
  {{ data.filter.name }}
</h2>
<form (ngSubmit)="applyFilter()" autocomplete="off">
  <div class="datepicker__dialog" mat-dialog-content>
    <mat-calendar
      *ngIf="data.filter.inputType == 'date'"
      [selected]="selectedDate"
      (selectedChange)="onSelectDate($event)"
    ></mat-calendar>
    <mat-form-field *ngIf="data.filter.inputType == 'text' || data.filter.inputType == 'number'" class="w-100">
      <mat-label> {{ data.filter.additionalText }} {{ data.filter.unit }}</mat-label>
      <input
        matInput
        placeholder="{{ data.filter.additionalText }} {{ data.filter.unit }}"
        [(ngModel)]="search"
        #input
        maxlength="255"
        min="0"
        step="{{ data.filter.step }}"
        oninput="validity.valid||(value='');"
        name="search"
        type="{{ data.filter.inputType }}"
      />
      <mat-hint *ngIf="data.filter.hint">{{ data.filter.hint }}</mat-hint>
    </mat-form-field>
    <div *ngIf="data.filter.inputType == 'duration'">
      <mat-form-field class="w-100">
        <mat-label> {{ data.filter.additionalText }}</mat-label>
        <input
          matInput
          [(ngModel)]="search"
          #input
          (keyup)="updateDurationFormatDisplay($event.target.value)"
          (change)="updateDurationFormatDisplay($event.target.value)"
          name="search"
          type="number"
          oninput="validity.valid||(value='');"
          placeholder="0"
          min="0"
          autocomplete="off"
        />
        <mat-hint *ngIf="data.filter.hint">{{ data.filter.hint }}</mat-hint>
      </mat-form-field>
      <div class="pt-2 text-center">
        Displays as
        {{ this.formattedDurationDisplay ? this.formattedDurationDisplay : "00:00:00" }}
      </div>
    </div>
    <div *ngIf="data.filter.inputType == 'select'">
      <table *ngIf="loading" class="table table-borderless text-left">
        <tr>
          <td>
            <span> Gathering data... </span>
          </td>
          <td>
            <span class="material-icons faa-spin"> filter_tilt_shift </span>
          </td>
        </tr>
      </table>
      <mat-form-field *ngIf="!loading && data.filter.name !== 'Organizational Unit'" class="w-100">
        <mat-label>{{ data.filter.name }}</mat-label>
        <mat-select *ngIf="!data.filter.parentChild" name="search" [(ngModel)]="search">
          <input
            class="input-filter"
            matInput
            (keyup)="onKey($event.target.value)"
            (keydown)="$event.stopPropagation()"
            placeholder="Type here to filter"
            #input
            autocomplete="off"
          />
          <mat-option *ngIf="data.filter.types[0] == FilterType.ADMIN_USER && loginService.user.isAdmin" value="/"
            >All organizational units</mat-option
          >
          <mat-option
            matTooltip="{{ value.name }}"
            [matTooltipPosition]="position.value"
            *ngFor="let value of selectFilteredValues"
            [value]="data.filter.name == 'Status' ? value : value.id"
          >
            {{ value.name | titlecase }}</mat-option
          >
        </mat-select>
        <mat-select *ngIf="data.filter.parentChild" name="search" [(ngModel)]="search">
          <input
            class="input-filter"
            matInput
            (keyup)="onKey($event.target.value)"
            (keydown)="$event.stopPropagation()"
            placeholder="Type here to filter"
            #input
            autocomplete="off"
          />
          <div *ngFor="let parent of selectFilteredValues">
            <mat-option class="optionGroup" [value]="capitalizeFirstLetter(parent.name)">
              {{ capitalizeFirstLetter(parent.name) }}
            </mat-option>
            <mat-option
              class="optionChild"
              *ngFor="let subItem of parent.children"
              [value]="subItem.mimeType ? subItem.mimeType : subItem.name"
            >
              {{ subItem.mimeType ? subItem.mimeType : subItem.name }}
            </mat-option>
          </div>
        </mat-select>
      </mat-form-field>
      <app-organizational-unit
        *ngIf="!loading && data.filter.name === 'Organizational Unit'"
        [label]="data.filter.name"
        [placeholder]="'Type here to filter'"
        [(ngModel)]="search"
        #ouInput
        name="search"
        id="search" 
      ></app-organizational-unit>
    </div>
    <div *ngIf="data.filter.inputType == 'logData'">
      <table *ngIf="loading" class="table table-borderless text-left">
        <tr>
          <td>
            <span> Gathering data... </span>
          </td>
          <td>
            <span class="material-icons faa-spin"> filter_tilt_shift </span>
          </td>
        </tr>
      </table>
      <mat-form-field *ngIf="!loading" class="w-100">
        <mat-label>Audit Type</mat-label>
        <mat-select name="auditType" [(ngModel)]="auditType">
          <mat-option
            matTooltip="{{ value.name }}"
            [matTooltipPosition]="position.value"
            *ngFor="let value of dataKeys | keyvalue"
            [value]="value.key"
          >
            {{ value.key | titlecase }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="!loading && auditType != 'tickets'" class="w-100">
        <mat-label>{{ auditType | titlecase }} Data</mat-label>
        <mat-select name="dataKey" [(ngModel)]="dataKey" [disabled]="!auditType">
          <mat-option [matTooltipPosition]="position.value" *ngFor="let value of dataKeys[auditType]" [value]="value">
            {{ value }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="!loading && auditType == 'tickets'" class="w-100">
        <mat-label>{{ auditType | titlecase }} Data</mat-label>
        <mat-select
          name="ticketDataKey"
          (selectionChange)="buildDataSearch($event.value)"
          [(ngModel)]="ticketDataKey"
          [disabled]="!auditType"
        >
          <mat-optgroup *ngFor="let objectKey of Object.keys(dataKeys[auditType])" label="{{ objectKey | titlecase }}">
            <mat-option
              [matTooltipPosition]="position.value"
              *ngFor="let value of dataKeys[auditType][objectKey]"
              [value]="objectKey + '.' + value"
            >
              {{ value }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="!loading" class="w-100">
        <input
          [(ngModel)]="auditTypeSearch"
          matInput
          placeholder="{{ data.filter.additionalText }} {{ data.filter.unit }}"
          (keyup)="buildDataSearch($event.target.value)"
          (keydown)="$event.stopPropagation()"
          #input
          maxlength="255"
          min="0"
          step="{{ data.filter.step }}"
          oninput="validity.valid||(value='');"
          name="search"
          type="{{ data.filter.inputType }}"
          [disabled]="!dataKey && !ticketDataKey"
        />
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button class="blue-border" mat-stroked-button mat-dialog-close (click)="onDismiss()">Cancel</button>
      <button create mat-stroked-button>Apply</button>
    </div>
  </div>
</form>
