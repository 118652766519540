import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from "@angular/core";
import {
  ConfirmDialogTrashModel,
  ConfirmationDialogTrashComponent,
} from "src/app/layout/dialogs/confirmation-dialog-trash/confirmation-dialog-trash.component";
import {
  ConfirmDialogModel,
  ConfirmationDialogComponent,
} from "src/app/layout/dialogs/confirmation-dialog/confirmation-dialog.component";
import { FixDuplicatesDialogComponent } from "src/app/layout/dialogs/fix-duplicates-dialog/fix-duplicates-dialog.component";
import { TransferFileOwnershipComponent } from "src/app/layout/transfer-file-ownership/transfer-file-ownership.component";
import { DialogTransferComponent } from "src/app/layout/dialogs/dialog-transfer/dialog-transfer.component";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSort } from "@angular/material/sort";
import { ReportsService, StorageReport, File } from "src/app/services/google/reports.service";
import { User } from "src/app/services/storage-plus/user.service";
import { MatTableDataSource } from "@angular/material/table";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { MatDialog } from "@angular/material/dialog";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { FilePath, FileType, FilesService } from "src/app/services/storage-plus/files.service";
import { SelectionModel } from "@angular/cdk/collections";
import { UntypedFormControl } from "@angular/forms";
import { merge, of as observableOf } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { FiltersService, Filter, FilterType } from "src/app/services/utilities/filters.service";
import { TreeNode, TreeSelectorComponent } from "src/app/layout/dialogs/tree-selector/tree-selector.component";
import { Clipboard } from "@angular/cdk/clipboard";
import { CustomerService } from "src/app/services/admin-plus/customer.service";

@Component({
  selector: "app-storage-report-files-table",
  templateUrl: "./storage-report-files-table.component.html",
  styleUrls: ["./storage-report-files-table.component.scss"],
})
export class StorageReportFilesTableComponent implements OnInit {
  @Input() options = [];
  @Input() viewOnly = false;
  @Input() storageReport: StorageReport = {
    status: {
      name: "",
      id: 4,
    },
    totalUsers: 0,
    totalStorage: 0,
    totalDrive: 0,
    totalGmail: 0,
    totalPhotos: 0,
    allowDelete: false,
  };

  displayedColumns: string[] = [
    "details",
    "storageMb",
    "owner",
    "lastModifiedBy",
    /*"mimeType",*/

    "createdDate",
    /*"viewedByMeTime",*/
    "driveName",
    "movedToTrashDate",
    "scheduledDateTime",
    "filePath",
  ];

  usageSizeList: string[] = ["1", "5", "10", "15", "20", "25", "30", "35", "40", "45", "50", "75", "100", "150"];
  dataSource = new MatTableDataSource<File>();
  actions = new UntypedFormControl();
  maxResultsActions = new UntypedFormControl();
  usageSize = new UntypedFormControl();
  menuSelect = new UntypedFormControl();
  userCtrl = new UntypedFormControl();
  selection = new SelectionModel<File>(true, []);

  trashStatusList: string[] = [];
  trashedActions: string[] = ["regular", "trashed", "movedToTrashDate", "scheduledDateTime"];
  columnsList: string[] = ["hideTrashed"];

  driveId = 0;
  maxResults = 10;
  private getCount = true;
  totalFileCount = 0;
  page = 1;
  offset = 0;
  userId: number = null;

  search = "";
  searchMimeType = "";
  name = "";
  trashStatus = "";
  direction = "desc";
  activeOrderBy = "storageMb";

  files: File[] = [];

  user: User;
  nextButtonDis = false;
  nextButton = false;
  nextPageDisabled = false;
  loading = false;

  filterTypeValue: FilterType = FilterType.FILE;

  @ViewChild(MatSort) sort!: MatSort;

  @Output() filesEvent = new EventEmitter();

  /* filtered search stuff */
  private usageSizeSearch = "0";
  private firstNameSearch = "";
  private fileNameSearch = "";
  private lastNameSearch = "";
  private emailSearch = "";
  private sharedDriveSearch = "";
  private trashType = false;
  private fileType = "";
  private ouSearch = "";
  private lastViewedBefore = "";
  private lastViewedAfter = "";
  private lastModifiedBy = "";
  private md5ChecksumValue = "";
  private daysFlagged = "";
  private flaggedDateBefore = "";
  private ownerSuspendedType = "";

  // Filter code
  selectedFilters: Filter[] = [];
  filterFromUser = false;

  data = {
    usageSizeSearch: this.usageSizeSearch,
    firstNameSearch: this.firstNameSearch,
    fileNameSearch: this.fileNameSearch,
    lastNameSearch: this.lastNameSearch,
    emailSearch: this.emailSearch,
    sharedDriveSearch: this.sharedDriveSearch,
    trashType: this.trashType,
    fileType: this.fileType,
    ouSearch: this.ouSearch,
    lastViewedBefore: this.lastViewedBefore,
    lastViewedAfter: this.lastViewedAfter,
    lastModifiedBy: this.lastModifiedBy,
    userId: this.userId,
    md5ChecksumValue: this.md5ChecksumValue,
    page: this.page,
    offset: this.offset,
    daysFlagged: this.daysFlagged,
    flaggedDateBefore: this.flaggedDateBefore,
    filtersCount: 0,
    ownerSuspendedType: this.ownerSuspendedType,
  };

  constructor(
    private navService: NavService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notifyService: NotificationService,
    private reportsService: ReportsService,
    private filesService: FilesService,
    private dialog: MatDialog,
    private filtersService: FiltersService,
    public matDialog: MatDialog,
    private fileService: FilesService,
    private clipboard: Clipboard,
    public customerService: CustomerService
  ) {
    const tUser = localStorage.getItem("user") || "";
    this.user = tUser && tUser != "" ? JSON.parse(tUser) : null;
  }

  async ngOnInit() {
    if (history?.state.addChip) {
      this.activatedRoute.queryParams.subscribe(async (params) => {
        if (params) {
          this.selectedFilters = await this.filtersService.addChipFromLink(params, this.selectedFilters);
        }
      });
    }
    const driveId = this.activatedRoute.snapshot.paramMap.get("driveId") || "";
    if (driveId) {
      this.driveId = parseInt(driveId);
    }
    this.usageSize.setValue("1");
    this.usageSize.valueChanges.subscribe((value) => {
      this.usageSizeSearch = value;
      this.loadData();
    });
    const hasNavFilters = await this.filtersService.checkNavFilters(this.filterTypeValue);
    if (!hasNavFilters) this.loadData();
    this.actions.valueChanges.subscribe((value) => {
      if (value == "schedule") {
        const files: File[] = [];
        for (let i = 0; i < this.selection.selected.length; i++) {
          if (this.checkIndividual(this.selection.selected[i])) {
            files.push(this.selection.selected[i]);
          }
        }
        this.openConfirmationDialog(files);
      } else if (value == "now") {
        const files: File[] = [];
        for (let i = 0; i < this.selection.selected.length; i++) {
          if (this.checkIndividual(this.selection.selected[i])) {
            files.push(this.selection.selected[i]);
          }
        }
        this.openTrashDialog(files);
      } else if (value == "delete") {
        const files = this.selection.selected;
        this.openDeleteDialog(files);
      } else if (value == "transfer") {
        const files = this.selection.selected;
        this.openTransferDialog(files);
      } else if (value == "transferOwnership") {
        const files = this.selection.selected;
        this.openTransferOwnershipDialog(files);
      } else if (value == "fixDuplicates") {
        const file: File = this.selection.selected[0];
        this.duplicateFilesDialog(file);
      } else if (value == "restoreFromTrash") {
        const files = this.selection.selected;
        if (files.length > 0) this.undoTrash(files);
      } else if (value == "restoreFromSchedule") {
        const files = this.selection.selected;
        if (files.length > 0) this.undoSchedule(files);
      } else {
        return;
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    if (!this.viewOnly) {
      this.displayedColumns.unshift("select");
    }
  }

  receiveData($event) {
    this.data = $event;
    this.firstNameSearch = this.data.firstNameSearch;
    this.lastNameSearch = this.data.lastNameSearch;
    this.emailSearch = this.data.emailSearch;
    this.fileNameSearch = this.data.fileNameSearch;
    this.fileType = this.data.fileType;
    this.ouSearch = this.data.ouSearch;
    this.sharedDriveSearch = this.data.sharedDriveSearch;
    this.lastModifiedBy = this.data.lastModifiedBy;
    this.usageSizeSearch = this.data.usageSizeSearch;
    this.trashType = this.data.trashType;
    this.lastViewedAfter = this.data.lastViewedAfter;
    this.lastViewedBefore = this.data.lastViewedBefore;
    this.userId = this.data.userId;
    this.md5ChecksumValue = this.data.md5ChecksumValue;
    this.page = this.data.page;
    this.offset = this.data.offset;
    this.daysFlagged = this.data.daysFlagged;
    this.flaggedDateBefore = this.data.flaggedDateBefore;
    this.ownerSuspendedType = this.data.ownerSuspendedType;
    this.loadData();
  }

  copyToClipboard(text: string) {
    this.clipboard.copy(text);
    this.notifyService.notify(`The file id ${text} was successfully added to your clipboard`);
  }

  duplicateFilesDialog(file: File) {
    const dialogRef = this.dialog.open(FixDuplicatesDialogComponent, {
      width: "500px",
      disableClose: true,
      data: {
        file: file,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.getFiles();
      }
      this.selection.clear();
      this.actions.setValue("");
    });
  }

  openTransferOwnershipDialog(files: File[]) {
    const dialogRef = this.dialog.open(TransferFileOwnershipComponent, {
      width: "500px",
      disableClose: true,
      data: {
        files: files,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.transferOwnershipConfirmation(files, result);
      }
      this.selection.clear();
      this.actions.setValue("");
    });
  }

  transferOwnershipConfirmation(files: File[], result: User) {
    let message =
      ` Are you sure you want to transfer ownership of the selected file` +
      (files.length > 1 ? "s" : "") +
      ` to the user ${result[0]["name"]["fullName"]}: `;
    for (let i = 0; i < files.length; i++) {
      if (i == files.length - 1) {
        message += `${files[i]["name"]}`;
      } else {
        message += `${files[i]["name"]}, `;
      }
    }
    message += "?";
    const btnOkText = `Transfer Ownership`;
    const dialogData = new ConfirmDialogModel("Transfer Ownership of Files", message, btnOkText);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      width: "500px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe(async (afterClosedResult) => {
      if (afterClosedResult) {
        this.actions.disable();
        files.map((file) => (file.busy = 2));
        files = await this.transferOwnershipRequest(files, result);
        for (let i = 0; i < files.length; i++) {
          this.checkBusy(files[i], this.dataSource.data, files.length);
        }
      } else {
        this.selection.clear();
        this.actions.setValue("");
      }
      this.actions.enable();
    });
  }

  async transferOwnershipRequest(files: File[], result: User) {
    this.updateLoader(true);
    const response = await this.filesService.transferFileOwnership(files, result);
    let data = this.dataSource.data;
    if (response) {
      // All files are succeeded
      if (response["success"] && response["success"].length === files.length) {
        this.notifyService.notify(
          `${response["success"].length} ${response["success"].length > 1 ? "files are" : "file is"} being transferred`
        );
        for (let i = 0; i < response["success"].length; i++) {
          for (let j = 0; j < data.length; j++) {
            if (data[j]["googleId"] === response["success"][i]["googleId"]) {
              data[j]["busy"] = 2;
            }
          }
        }
      }
      // All files failed
      else if (response["error"] && response["error"].length === files.length) {
        let message = "";
        for (let i = 0; i < response["error"].length; i++) {
          message += `${response["error"][i].name} - ${
            response["error"][i].error ? response["error"][i].error + " " : "Unknown error to transfer. "
          }`;
          data = data.filter((file) => file.googleId !== response["error"][i]["googleId"]);
          files = files.filter((file: File) => file.googleId !== response["error"][i]["googleId"]);
        }
        this.notifyService.notify(message);
      }
      // Some file failed and some success
      else if (response["error"] && response["error"].length > 0 && response["success"] && response["success"].length > 0) {
        let message = "";
        for (let i = 0; i < response["success"].length; i++) {
          message += `${response["success"][i].name} - ${"Successfully transferred. "}`;
          for (let j = 0; j < data.length; j++) {
            if (data[j]["googleId"] === response["success"][i]["googleId"]) {
              data[j]["busy"] = 2;
            }
          }
        }
        for (let i = 0; i < response["error"].length; i++) {
          message += `${response["error"][i].name} - ${
            response["error"][i].error ? response["error"][i].error + " " : "Unknown error to transfer. "
          }`;
          data = data.filter((file) => file.googleId !== response["error"][i]["googleId"]);
          files = files.filter((file: File) => file.googleId !== response["error"][i]["googleId"]);
        }
        this.notifyService.notify(message);
      }
    }
    this.dataSource.data = data;
    this.selection.clear();
    this.actions.setValue("");
    this.updateLoader(false);
    return files;
  }

  openTransferDialog(files: File[]) {
    const dialogRef = this.dialog.open(DialogTransferComponent, {
      width: "500px",
      disableClose: true,
      data: {
        files: files,
        isDrive: false,
        isZip: false,
        zipFile: "",
        sendEmail: false,
        isMove: false,
        folderPath: "",
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const done = await this.delay(5000);
        if (done === undefined) {
          this.selection.clear();
          this.actions.setValue("");
          for (let i = 0; i < files.length; i++) {
            this.checkBusy(files[i], this.dataSource.data, files.length);
          }
        }
      }
    });
  }

  openTrashDialog(files: File[]) {
    let message = `Are you sure you want to move the following file` + (files.length > 1 ? "s" : "");
    message += ` to the user's trash now`;
    const btnOkText = `Confirm`;
    const dialogData = new ConfirmDialogTrashModel(files, "Move to Trash", message, btnOkText, `?`);
    const dialogRef = this.dialog.open(ConfirmationDialogTrashComponent, {
      disableClose: true,
      width: "500px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        files.map((file) => (file.busy = 1));
        files = await this.trashFiles(result, files);
        for (let i = 0; i < files.length; i++) {
          this.checkBusy(files[i], this.dataSource.data, files.length);
        }
      }
      this.selection.clear();
      this.actions.setValue("");
    });
  }

  async trashFiles(result, files: File[]) {
    let reason = "";
    if (result.event !== null && result.event !== "Other") {
      reason = result.event;
    }
    if (result.other !== undefined) {
      reason = result.other;
    }
    this.updateLoader(true);
    const response = await this.filesService.trashNow(files, reason);
    let data = this.dataSource.data;
    if (response) {
      // All files are succeeded
      if (response["success"] && response["success"].length === files.length) {
        this.notifyService.notify(
          `${response["success"].length} ${response["success"].length > 1 ? "files are" : "file is"} being trashed`
        );
        for (let i = 0; i < response["success"].length; i++) {
          for (let j = 0; j < data.length; j++) {
            if (data[j]["googleId"] === response["success"][i]["googleId"]) {
              data[j]["busy"] = 1;
            }
          }
        }
      }
      // All files failed
      else if (response["error"] && response["error"].length === files.length) {
        let message = "";
        for (let i = 0; i < response["error"].length; i++) {
          message += `${response["error"][i].name} - ${
            response["error"][i].error ? response["error"][i].error + " " : "Unknown error to trash. "
          }`;
          data = data.filter((file) => file.googleId !== response["error"][i]["googleId"]);
          files = files.filter((file: File) => file.googleId !== response["error"][i]["googleId"]);
        }
        this.notifyService.notify(message);
      }
      // Some file failed and some success
      else if (response["error"] && response["error"].length > 0 && response["success"] && response["success"].length > 0) {
        let message = "";
        for (let i = 0; i < response["success"].length; i++) {
          message += `${response["success"][i].name} - ${"Successfully trashed. "}`;
          for (let j = 0; j < data.length; j++) {
            if (data[j]["googleId"] === response["success"][i]["googleId"]) {
              data[j]["busy"] = 1;
            }
          }
        }
        for (let i = 0; i < response["error"].length; i++) {
          message += `${response["error"][i].name} - ${
            response["error"][i].error ? response["error"][i].error + " " : "Unknown error to trash. "
          }`;
          data = data.filter((file) => file.googleId !== response["error"][i]["googleId"]);
          files = files.filter((file: File) => file.googleId !== response["error"][i]["googleId"]);
        }
        this.notifyService.notify(message);
      }
      this.dataSource.data = data;
      this.updateLoader(false);
      return files;
    }
  }

  openDeleteDialog(files: File[]) {
    const message =
      `Are you sure you want to delete the following file` + (files.length > 1 ? "s permanently" : " permanently");
    const btnOkText = `Confirm`;
    const dialogData = new ConfirmDialogTrashModel(
      files,
      "Delete Files",
      message,
      btnOkText,
      `? You will be unable to restore the following file` + (files.length > 1 ? "s." : "."),
      undefined,
      true
    );
    const dialogRef = this.dialog.open(ConfirmationDialogTrashComponent, {
      disableClose: true,
      width: "500px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        files.map((file) => (file.busy = 1));
        files = await this.deleteFiles(result, files);
        if (files.length > 0) this.busyDeleteFiles(files);
      }
      this.selection.clear();
      this.actions.setValue("");
    });
  }

  async busyDeleteFiles(files: File[]) {
    let seconds = files.length + "000";
    seconds = (parseInt(seconds) + 2000).toString();
    if (files.length === 1) {
      seconds = "2000";
    }
    if (files.length === 2) {
      seconds = "5000";
    }
    if (files.length >= 30) {
      seconds = "35000";
    }
    await sleep(seconds);
    const interval = setInterval(async () => {
      const response = await this.filesService.getBusyFiles(files);
      if (response.length === 0) {
        clearInterval(interval);
        this.getFiles();
      }
    }, parseInt(seconds));
  }

  async deleteFiles(result, files: File[]) {
    let reason = "";
    if (result.event !== null && result.event !== "Other") {
      reason = result.event;
    }
    if (result.other !== undefined) {
      reason = result.other;
    }
    this.updateLoader(true);
    const response = await this.filesService.deleteFile(files, reason);
    let data = this.dataSource.data;
    if (response) {
      // All files are succeeded
      if (response["success"] && response["success"].length === files.length) {
        this.notifyService.notify(
          `${response["success"].length} ${response["success"].length > 1 ? "files are" : "file is"} being deleted`
        );
        for (let i = 0; i < response["success"].length; i++) {
          for (let j = 0; j < data.length; j++) {
            if (data[j]["googleId"] === response["success"][i]["googleId"]) {
              data[j]["busy"] = 1;
            }
          }
        }
      }
      // All files failed
      else if (response["error"] && response["error"].length === files.length) {
        let message = "";
        for (let i = 0; i < response["error"].length; i++) {
          message += `${response["error"][i].name} - ${
            response["error"][i].error ? response["error"][i].error + " " : "Unknown error to delete. "
          }`;
          data = data.filter((file) => file.googleId !== response["error"][i]["googleId"]);
          files = files.filter((file: File) => file.googleId !== response["error"][i]["googleId"]);
        }
        this.notifyService.notify(message);
      }
      // Some file failed and some success
      else if (response["error"] && response["error"].length > 0 && response["success"] && response["success"].length > 0) {
        let message = "";
        for (let i = 0; i < response["success"].length; i++) {
          message += `${response["success"][i].name} - ${"Successfully deleted. "}`;
          for (let j = 0; j < data.length; j++) {
            if (data[j]["googleId"] === response["success"][i]["googleId"]) {
              data[j]["busy"] = 1;
            }
          }
        }
        for (let i = 0; i < response["error"].length; i++) {
          message += `${response["error"][i].name} - ${
            response["error"][i].error ? response["error"][i].error + " " : "Unknown error to delete. "
          }`;
          data = data.filter((file) => file.googleId !== response["error"][i]["googleId"]);
          files = files.filter((file: File) => file.googleId !== response["error"][i]["googleId"]);
        }
        this.notifyService.notify(message);
      }
    }
    this.dataSource.data = data;
    this.updateLoader(false);
    return files;
  }

  async openConfirmationDialog(files: File[]) {
    let message = `Are you sure you want to schedule the following file` + (files.length > 1 ? "s" : "");
    message += ` to move to the user's trash in `;
    const bold = `day(s)`;
    const btnOkText = `Confirm`;
    const dialogData = new ConfirmDialogTrashModel(files, "Schedule for Trash", message, btnOkText, `?`, bold);
    const dialogRef = this.dialog.open(ConfirmationDialogTrashComponent, {
      disableClose: true,
      width: "500px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        files.map((file) => (file.busy = 1));
        files = await this.scheduleForTrash(result, files);
        for (let i = 0; i < files.length; i++) {
          this.checkBusy(files[i], this.dataSource.data, files.length);
        }
      }
      this.selection.clear();
      this.actions.setValue("");
    });
  }

  async scheduleForTrash(result, files: File[]) {
    let reason = "";
    let days = "7";
    if (result.event !== null && result.event !== "Other") {
      reason = result.event;
    }
    if (result.other !== undefined) {
      reason = result.other;
    }
    if (result.days !== undefined) {
      days = result.days;
    }
    files.map((file) => (file.days = days));
    files.map((file) => (file.reason = reason));
    this.updateLoader(true);
    const response = await this.filesService.addScheduleToTrash(files);
    let data = this.dataSource.data;
    if (response) {
      // All files are succeeded
      if (response["success"] && response["success"].length === files.length) {
        this.notifyService.notify(
          `${response["success"].length} ${response["success"].length > 1 ? "files are" : "file is"} being scheduled`
        );
        for (let i = 0; i < response["success"].length; i++) {
          for (let j = 0; j < data.length; j++) {
            if (data[j]["googleId"] === response["success"][i]["googleId"]) {
              data[j]["busy"] = 1;
            }
          }
        }
      }
      // All files failed
      else if (response["error"] && response["error"].length === files.length) {
        let message = "";
        for (let i = 0; i < response["error"].length; i++) {
          message += `${response["error"][i].name} - ${
            response["error"][i].error ? response["error"][i].error + " " : "Unknown error to schedule. "
          }`;
          data = data.filter((file) => file.googleId !== response["error"][i]["googleId"]);
          files = files.filter((file: File) => file.googleId !== response["error"][i]["googleId"]);
        }
        this.notifyService.notify(message);
      }
      // Some file failed and some success
      else if (response["error"] && response["error"].length > 0 && response["success"] && response["success"].length > 0) {
        let message = "";
        for (let i = 0; i < response["success"].length; i++) {
          message += `${response["success"][i].name} - ${"Successfully scheduled. "}`;
          for (let j = 0; j < data.length; j++) {
            if (data[j]["googleId"] === response["success"][i]["googleId"]) {
              data[j]["busy"] = 1;
            }
          }
        }
        for (let i = 0; i < response["error"].length; i++) {
          message += `${response["error"][i].name} - ${
            response["error"][i].error ? response["error"][i].error + " " : "Unknown error to schedule. "
          }`;
          data = data.filter((file) => file.googleId !== response["error"][i]["googleId"]);
          files = files.filter((file: File) => file.googleId !== response["error"][i]["googleId"]);
        }
        this.notifyService.notify(message);
      }
    }
    this.dataSource.data = data;
    this.updateLoader(false);
    return files;
  }

  async getScheduledTrash(googleId: string) {
    const response = await this.filesService.getTrashedFile(googleId);
    return new Promise((resolve) => {
      if (response) {
        resolve(response);
      }
    });
  }

  private async loadData(arrow = "") {
    this.navService.loading.next(true);
    await this.getFiles(arrow);
    this.navService.loading.next(false);
  }

  checkMoving() {
    for (let i = 0; i < this.selection.selected.length; i++) {
      if (this.selection.selected[i]["fileTransfer"] === 1) {
        return true;
      }
    }
  }

  checkTrashed() {
    let counter = 0;
    for (let i = 0; i < this.selection.selected.length; i++) {
      if (this.checkIndividual(this.selection.selected[i])) {
        counter++;
      }
    }

    if (counter === 0) {
      return true;
    } else {
      return false;
    }
  }

  checkDrive() {
    for (let i = 0; i < this.selection.selected.length; i++) {
      if (this.selection.selected[i]["driveId"]) {
        return true;
      }
    }
  }

  checkMd5Checksum() {
    if (this.md5ChecksumValue === "") {
      return true;
    }
  }

  private checkIndividual(selection) {
    if (selection["scheduledDateTime"] === null && selection["trashed"] !== 1) {
      return true;
    } else {
      return false;
    }
  }

  private async getFiles(arrow = "") {
    this.updateLoader(true);
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.selection.clear();
          this.nextButton = true;
          this.getCount = !arrow;
          return this.reportsService.getStorageReportFiles(
            this.maxResults,
            this.activeOrderBy,
            this.direction,
            this.firstNameSearch,
            this.lastNameSearch,
            this.emailSearch,
            this.fileType,
            this.userId ? "" : this.name,
            this.offset,
            this.trashType,
            this.userId,
            this.usageSizeSearch,
            this.driveId,
            this.sharedDriveSearch,
            this.ouSearch,
            this.fileNameSearch,
            this.getCount,
            this.lastViewedBefore,
            this.lastViewedAfter,
            this.md5ChecksumValue,
            this.daysFlagged,
            this.flaggedDateBefore,
            this.ownerSuspendedType
          );
        }),
        map((files) => {
          if (files) {
            this.files = files["data"];
            this.dataSource = new MatTableDataSource(this.files);
            this.dataSource.sort = this.sort;
            for (let i = 0; i < this.files.length; i++) {
              if (this.files[i]["busy"] !== 0) {
                this.checkBusy(this.files[i], this.dataSource.data, this.files.length);
              }
            }
            this.nextPageDisabled = this.dataSource.data.length < this.maxResults ? true : false;
            //pagination
            this.totalFileCount = this.getCount ? (files["count"] ? files["count"] : 0) : this.totalFileCount;
            if (this.dataSource.filteredData.length < 10) {
              this.nextButtonDis = true;
            }
            if (this.dataSource.filteredData.length > 10) {
              this.nextButtonDis = false;
            }
            if (arrow === "next" && this.dataSource.filteredData.length + this.offset === this.totalFileCount) {
              this.nextButtonDis = true;
            }
            if (arrow === "next" && this.dataSource.filteredData.length + this.offset < this.totalFileCount) {
              this.nextButtonDis = false;
            }
            if (this.dataSource.filteredData.length + this.offset < this.totalFileCount) {
              this.nextButtonDis = false;
            }
            if (this.dataSource.filteredData.length + this.offset === this.totalFileCount) {
              this.nextButtonDis = true;
            }
            if (arrow === "prev" && this.totalFileCount > 0) {
              this.nextButtonDis = false;
            }
            if (this.totalFileCount === this.maxResults) {
              this.nextButtonDis = true;
            }
          }
          this.nextButton = false;
          this.updateLoader(false);
          return this.files;
        }),
        catchError(() => {
          this.updateLoader(false);
          return observableOf([]);
        })
      )
      .subscribe();
  }

  async sendFilesEvent() {
    const files = [];
    let response = {};
    let offset = 0;
    do {
      response = await this.reportsService.getStorageReportFiles(
        100,
        this.activeOrderBy,
        this.direction,
        this.firstNameSearch,
        this.lastNameSearch,
        this.emailSearch,
        this.fileType,
        this.userId ? "" : this.name,
        offset,
        this.trashType,
        this.userId,
        this.usageSizeSearch,
        this.driveId,
        this.sharedDriveSearch,
        this.ouSearch,
        this.fileNameSearch,
        this.getCount,
        this.lastViewedBefore,
        this.lastViewedAfter,
        this.md5ChecksumValue,
        this.daysFlagged,
        this.flaggedDateBefore,
        this.ownerSuspendedType
      );
      offset = offset + 100;
      files.push(...response["data"]);
    } while (response["data"].length > 0);
    const emitObject = {
      files: files,
      columns: this.displayedColumns,
    };
    this.filesEvent.emit(emitObject);
  }

  trashChange(value: string) {
    this.page = 1;
    this.offset = 0;
    let add = true;
    for (let i = 0; i < this.trashStatusList.length; i++) {
      if (this.trashStatusList[i] === value) {
        if (i !== -1) {
          this.trashStatusList.splice(i, 1);
          add = false;
        }
      }
    }
    if (add) {
      this.trashStatusList.push(value);
    }
    this.loadData();
  }

  maxResultChange(value: number) {
    this.page = 1;
    this.offset = 0;
    this.maxResults = parseInt(value + "");
    this.loadData("max");
  }

  resetToFirstPage() {
    this.page = 1;
    this.offset = 0;
    this.loadData("");
  }

  onSubmit() {
    this.loadData("");
  }

  setPager(data) {
    this.page = data.page;
    this.offset = data.offset;
    this.maxResults = data.maxResults ? data.maxResults : this.maxResults;
    this.loadData(data.arrow);
  }

  routeToUser(userId: number) {
    this.router.navigate(["reports/storage/users/" + userId]);
  }

  getPageData() {
    return this.dataSource._pageData(this.dataSource._orderData(this.dataSource.filteredData));
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  masterToggle() {
    this.isEntirePageSelected()
      ? this.selection.deselect(...this.getPageData())
      : this.selection.select(...this.getPageData());
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.getPageData() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1}`;
  }

  async undoSchedule(files: File[]) {
    this.updateLoader(true);
    const response = await this.filesService.undoScheduledTrash(files);
    const data = this.dataSource.data;
    if (response && response["success"]) {
      if (response["success"].length > 0) {
        this.notifyService.notify(
          `${response["success"].length} ${
            response["success"].length > 1 ? "files are" : "file is"
          } being unscheduled for trash`
        );
        for (let i = 0; i < response["success"].length; i++) {
          for (let j = 0; j < data.length; j++) {
            if (data[j]["googleId"] === response["success"][i]["googleId"]) {
              data[j]["busy"] = 1;
              this.checkBusy(response["success"][i], data, files.length);
            }
          }
        }
      }
    }
    this.dataSource.data = data;
    this.selection.clear();
    this.actions.setValue("");
    this.updateLoader(false);
  }

  async undoTrash(files: File[]) {
    this.updateLoader(true);
    const response = await this.filesService.undoTrash(files);
    const data = this.dataSource.data;
    if (response && response["success"]) {
      if (response["success"].length > 0) {
        this.notifyService.notify(
          `${response["success"].length} ${response["success"].length > 1 ? "files are" : "file is"} being restored`
        );
        for (let i = 0; i < response["success"].length; i++) {
          for (let j = 0; j < data.length; j++) {
            if (data[j]["googleId"] === response["success"][i]["googleId"]) {
              data[j]["busy"] = 1;
              this.checkBusy(response["success"][i], data, files.length);
            }
          }
        }
      }
    }
    this.dataSource.data = data;
    this.selection.clear();
    this.actions.setValue("");
    this.updateLoader(false);
  }

  viewEmails(googleId: string) {
    this.router.navigate(["../emails"], {
      relativeTo: this.activatedRoute,
      queryParams: { googleId: googleId },
    });
  }

  async resyncFilesConfirmed() {
    this.updateLoader(true);
    const response = await this.filesService.resyncFiles(this.userId);
    if (response) {
      this.notifyService.notify("The files for the user have been re-synced");
      this.loadData();
    }
    this.updateLoader(false);
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async checkBusy(file: File, data, totalFile, action?) {
    let seconds = totalFile + "000";
    if (totalFile <= 3) {
      seconds = "3000";
    }
    if (totalFile >= 30) {
      seconds = "30000";
    }
    const interval = setInterval(async () => {
      const getFile = await this.filesService.getFile(file.id, file.driveId);
      if (typeof getFile.busy === "number" && getFile.busy === 0) {
        for (let i = 0; i < data.length; i++) {
          if (data[i]["id"] === getFile["id"]) {
            data[i] = getFile;
            clearInterval(interval);
            this.dataSource.data = data;
          }
        }
      }
      if (typeof getFile.busy !== "number") {
        let done = false;
        for (let i = 0; i < data.length; i++) {
          if (data[i]["id"] === file.id) {
            data.splice(i, 1);
            clearInterval(interval);
            done = true;
            this.dataSource.data = data;
            break;
          }
        }
        if (done && action !== "delete") {
          this.getFiles();
        }
      }
    }, parseInt(seconds));
  }

  /** map the tree data source using recursion from file paths */
  recursion(parent: TreeNode, child: TreeNode) {
    if (parent.children) this.recursion(parent.children[0], child);
    else parent.children = [child];
  }

  /** opens the file path dialog */
  async openFilePathDialog(fileId: string, fileName: string) {
    let paths = await this.getFilePaths(fileId);
    // transforming file paths data to use with mat tree
    paths = paths.map((path: FilePath) => ({ ...path, parts: path.parts ? path.parts.reverse() : [] }));
    const source: TreeNode[] = [];
    for (let i = 0; i < paths.length; i++) {
      for (let j = 0; j < paths[i].parts.length; j++) {
        if (j == 0) {
          source.push({ id: paths[i].parts[j].id, name: paths[i].parts[j].name, children: null });
        } else {
          this.recursion(source[i], { id: paths[i].parts[j].id, name: paths[i].parts[j].name, children: null });
        }
      }
    }
    this.matDialog.open(TreeSelectorComponent, {
      width: "600px",
      data: {
        fileId,
        title: fileName,
        source: source,
      },
    });
  }

  /** Load the File Paths by Calling API */
  async getFilePaths(fileId: string): Promise<FilePath[]> {
    this.navService.loading.next(true);
    try {
      return await this.fileService.getFilePaths(fileId);
    } catch (error) {
      //
    } finally {
      this.navService.loading.next(false);
    }
  }

  //** A method to check whether all selected files can be undo trash */
  canUndoTrash() {
    let canUndo = false;
    const BreakException = {};
    try {
      this.selection.selected.forEach((selected: File) => {
        if (selected["trashed"] !== 1) {
          throw BreakException;
        }
        canUndo = true;
      });
    } catch (e) {
      if (e !== BreakException) throw e;
      else canUndo = false;
    }
    return canUndo;
  }

  canUndoSchedule() {
    let canUndo = false;
    const BreakException = {};
    try {
      this.selection.selected.forEach((selected: File) => {
        if (!selected["scheduledDateTime"]) {
          throw BreakException;
        }
        canUndo = true;
      });
    } catch (e) {
      if (e !== BreakException) throw e;
      else canUndo = false;
    }
    return canUndo;
  }

  updateLoader(value: boolean) {
    for (const option of this.options) {
      if (option["name"] == "Export to Sheets" || option["name"] == "Export to PDF") {
        option["disabled"] = value;
      }
    }
    this.loading = value;
    this.navService.loading.next(value);
  }

  public mimeTypeToIcon(mimeType: string) {
    const fileType: FileType = this.filesService.defaultFileTypes.find((type) => mimeType.startsWith(type.prefix));

    return fileType ? fileType.icon : "description";
  }
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
