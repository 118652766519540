import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { EmailsService, CustomizedEmail } from "src/app/services/storage-plus/emails.service";

@Component({
  selector: "app-email-customization",
  templateUrl: "./email-customization.component.html",
  styleUrls: ["./email-customization.component.scss"],
})
export class EmailCustomizationComponent implements OnInit {
  public emailId: number;
  public emailName: string;
  public headerUrl: string = "";
  public footerLine: string = "";
  private footerLines: string[];
  public paragraph: string = "";
  private paragraphs: string[];
  constructor(
    private dialogRef: MatDialogRef<EmailCustomizationComponent>,
    private emailsService: EmailsService,
    private notifyService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.emailName = this.data.emailName;
    if (this.data.customizedEmail) {
      this.emailId = this.data.customizedEmail.id;
      this.headerUrl = this.data.customizedEmail.headerUrl;
      this.paragraphs = JSON.parse(this.data.customizedEmail.paragraphs) ?? "";
      this.footerLines = JSON.parse(this.data.customizedEmail.footerLines) ?? "";
      for (const footer of this.footerLines) {
        this.footerLine += footer + "\n";
      }
      this.footerLine = this.footerLine.trim();
      for (const para of this.paragraphs) {
        this.paragraph += para + "\n";
      }
      this.paragraph = this.paragraph.trim();
    }
  }

  async processForm() {
    const customizedEmail: CustomizedEmail = this.createCustomizedEmailObj();
    if (!customizedEmail.headerUrl && !customizedEmail.paragraphs && !customizedEmail.footerLines) {
      this.notifyService.notify("Nothing to save, cancel to exit");
    } else {
      const response = await this.emailsService.saveEmailCustomization(customizedEmail);
      if (response) {
        this.dialogRef.close(response);
        this.notifyService.notify("Email customization saved");
      } else {
        this.notifyService.notify("An error occurred");
      }
    }
  }

  createCustomizedEmailObj() {
    return {
      id: this.emailId ? this.emailId : 0,
      name: this.emailName,
      headerUrl: this.headerUrl ? this.headerUrl.trim() : null,
      paragraphs: this.paragraph.trim() ? this.paragraph.trim().split("\n") : null,
      footerLines: this.footerLine.trim() ? this.footerLine.trim().split("\n") : null,
    };
  }
}
