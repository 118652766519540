import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { CustomerService } from "src/app/services/admin-plus/customer.service";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { ServiceAccountService } from "src/app/services/admin-plus/service-account.service";

@Component({
  selector: "app-service-account-notification",
  templateUrl: "./service-account-notification.component.html",
  styleUrls: ["./service-account-notification.component.scss"],
})
export class ServiceAccountNotificationComponent implements OnInit {
  dismissed = false;
  hideLink = false;

  constructor(
    public serviceAccountService: ServiceAccountService,
    private customerService: CustomerService,
    private router: Router,
    private loginService: LoginService
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.hideLink = this.router.url === "/settings/product/service-account";
      }
    });
  }

  async ngOnInit() {
    if (
      this.customerService.customer?.serviceAccount?.id &&
      this.loginService.user.isAdmin &&
      !this.customerService.customer?.subscription.expired
    )
      await this.serviceAccountService.failedScopeStatus(this.customerService.customer.serviceAccount.id);
  }

  dismiss() {
    this.dismissed = true;
  }
}
