import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { AuthService } from "src/app/services/admin-plus/auth.service";

@Injectable({
  providedIn: "root",
})
export class EmailsService {
  private user: any;
  private apiUrl = environment.apiUrl + "/emails";
  private refreshSubject = new Subject<any>();

  constructor(private http: HttpClient, private loginService: LoginService, private authService: AuthService) {}

  public async addFlag(emailList: EmailList) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Flag[]>((resolve) => {
      this.addFlagData(emailList).subscribe((emailList) => {
        if (emailList) {
          resolve(emailList);
        }
      });
    });
  }

  private addFlagData(emailList: EmailList): Observable<Flag[]> {
    return this.http
      .post<Flag[]>(environment.apiUrl + "/reports/storage/users/create-flags", emailList, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Flag[]>("addFlagData")));
  }

  public async getTotalHistoryEmails(reportId: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<string>((resolve) => {
      this.getTotalHistoryEmailsData(reportId).subscribe((count) => {
        if (count) {
          resolve(count);
        }
      });
    });
  }

  private getTotalHistoryEmailsData(reportId: string): Observable<string> {
    return this.http
      .get<string>(this.apiUrl + "/historyEmailsTotal/" + reportId, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<string>("getTotalHistoryEmailsData")));
  }

  public async deleteFlag(emailList: EmailList) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<Flag[]>((resolve) => {
      this.deleteFlagData(emailList).subscribe((emailList) => {
        if (emailList) {
          resolve(emailList);
        }
      });
    });
  }

  private deleteFlagData(emailList: EmailList): Observable<Flag[]> {
    return this.http
      .post<Flag[]>(environment.apiUrl + "/reports/storage/users/delete-flags", emailList, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<Flag[]>("deleteFlagData")));
  }

  public async getEmails(orderBy = "", order = "", limit = 10, offset = 0, search = "", getCount = true) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<EmailData>((resolve) => {
      this.getEmailsData(orderBy, order, limit, offset, search, getCount).subscribe((emails) => {
        if (emails) {
          resolve(emails);
        }
      });
    });
  }

  private getEmailsData(
    orderBy = "",
    order = "",
    limit = 10,
    offset = 0,
    search = "",
    getCount = true
  ): Observable<EmailData> {
    return this.http
      .get<EmailData>(this.apiUrl + "/emails", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams()
          .set("orderBy", orderBy)
          .set("order", order)
          .set("limit", limit.toString())
          .set("offset", offset.toString())
          .set("search", search)
          .set("getCount", getCount.toString()),
      })
      .pipe(catchError(this.authService.handleAPIError<EmailData>("getEmailsData")));
  }

  public async getEmail(emailType: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<string>((resolve) => {
      this.getEmailData(emailType).subscribe((email) => {
        if (email) {
          resolve(email);
        }
      });
    });
  }

  private getEmailData(emailType: string): Observable<string> {
    return this.http
      .get(this.apiUrl + "/emailType", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        responseType: "text",
        params: new HttpParams().set("emailType", emailType),
      })
      .pipe(catchError(this.authService.handleAPIError<string>("getEmailData")));
  }

  public async sendScheduleEmail(files: any) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<boolean>((resolve) => {
      this.sendScheduleEmailData(files).subscribe((sent) => {
        if (sent) {
          resolve(sent);
        }
      });
    });
  }

  private sendScheduleEmailData(files: any): Observable<boolean> {
    return this.http
      .post<boolean>(
        this.apiUrl + "/scheduled-trash-email",
        { files },
        {
          headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
          params: new HttpParams().set("emailType", "pendingDeletion.html"),
        }
      )
      .pipe(catchError(this.authService.handleAPIError<boolean>("sendScheduleEmailData")));
  }

  public async sendTest(emailType: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<boolean>((resolve) => {
      this.sendTestData(emailType).subscribe((sent) => {
        if (sent) {
          resolve(sent);
        }
      });
    });
  }

  private sendTestData(emailType: string): Observable<boolean> {
    return this.http
      .post<boolean>(
        this.apiUrl + "/send-test",
        {},
        {
          headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
          params: new HttpParams().set("emailType", emailType),
        }
      )
      .pipe(catchError(this.authService.handleAPIError<boolean>("sendTestData")));
  }

  public async getEmailCustomization(emailName: string) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<CustomizedEmail>((resolve) => {
      this.getEmailCustomizationData(emailName).subscribe((emailCustomization) => {
        if (emailCustomization) {
          resolve(emailCustomization);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getEmailCustomizationData(emailName: string): Observable<CustomizedEmail> {
    return this.http
      .get<CustomizedEmail>(this.apiUrl + "/customize-email", {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
        params: new HttpParams().set("emailName", emailName),
      })
      .pipe(catchError(this.authService.handleAPIError<CustomizedEmail>("getEmailCustomizationData")));
  }

  public async saveEmailCustomization(customizedEmail: CustomizedEmail) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<CustomizedEmail>((resolve) => {
      this.saveEmailCustomizationData(customizedEmail).subscribe((customizedEmail) => {
        if (customizedEmail) {
          resolve(customizedEmail);
        } else {
          resolve(null);
        }
      });
    });
  }

  private saveEmailCustomizationData(customizedEmail: CustomizedEmail): Observable<CustomizedEmail> {
    return this.http
      .post<CustomizedEmail>(this.apiUrl + "/customize-email", customizedEmail, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<CustomizedEmail>("saveEmailCustomizationData")));
  }

  public async deleteEmailCustomization(emailId: number) {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise((resolve) => {
      this.deleteEmailCustomizationData(emailId).subscribe((customizationDeleted) => {
        if (customizationDeleted) {
          resolve(customizationDeleted);
        } else {
          resolve(null);
        }
      });
    });
  }

  private deleteEmailCustomizationData(emailId: number): Observable<string> {
    return this.http
      .delete<string>(this.apiUrl + "/customize-email/" + emailId, {
        headers: new HttpHeaders().set("idToken", this.user.idToken).set("accessToken", this.user.authToken),
      })
      .pipe(catchError(this.authService.handleAPIError<string>("deleteEmailCustomizationData")));
  }

  public sendRefresh(emailName: string) {
    this.refreshSubject.next(emailName);
  }

  public refreshEmailTemplate(): Observable<any> {
    return this.refreshSubject.asObservable();
  }

  public isValidEmail(email: string) {
    const regExEmail = /\S+@\S+\.\S+/;
    return regExEmail.test(String(email).toLowerCase());
  }

  public isValidDomain(domain: string) {
    const regExDomain = /\S+\.\S+/;
    return regExDomain.test(String(domain).toLowerCase());
  }
}

export interface EmailData {
  email: string;
}

export interface Email {
  id: number;
  created: string;
  type: EmailType;
  reportId: number;
  emailAddress: string;
  emailCount?: number;
}

export interface EmailType {
  id: number;
  name: string;
}

export interface CustomizedEmail {
  id: number;
  headerUrl?: string;
  paragraphs?: any;
  footerLines?: any;
}

export interface EmailList {
  emails: string[];
}

export interface Flag {
  googleId: string;
  usageStorage: number;
  driveTrashed: number;
  email: string;
  firstName: string;
  lastName: string;
}
