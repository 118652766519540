import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { OUService } from "src/app/services/google/ou.service";
import { HelpDialogService } from "src/app/services/utilities/help-dialog.service";
import { OuSelectionComponent } from "src/app/users/dialogs/ou-selection/ou-selection.component";
import { User } from "src/app/web/login/user";
import { NavService } from "../../../services/admin-plus/nav.service";

@Component({
  selector: "app-organizational-unit",
  templateUrl: "./organizational-unit.component.html",
  styleUrls: ["./organizational-unit.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: OrganizationalUnitComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: OrganizationalUnitComponent,
      multi: true,
    },
  ],
})
export class OrganizationalUnitComponent implements ControlValueAccessor, Validator {
  @Input("label") label = "Organizational Unit";
  @Input("placeholder") placeholder = "Default '/'";
  @Input("disabled") isDisabled = false;
  @Input("required") isRequired = false;
  @Input("hideLabel") hideLabel = false;
  @Input("hideDialogControl") hideDialogControl = false;
  @Input("showHint") showHint = false;
  @Input("hintTitle") hintTitle = "";
  @Input("hintDescription") hintDescription = "";
  @Input() path = "/";

  @Output() onPathChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onIdChanged: EventEmitter<string> = new EventEmitter<string>();

  user: User;
  touched = false;
  loading = false;

  constructor(
    public router: Router,
    private dialog: MatDialog,
    private ouService: OUService,
    public helpDialogService: HelpDialogService,
    private navService: NavService
  ) {
    const user: User = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.user = user;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  onChange = (path: string) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setPath(path) {
    if (path !== undefined) this.path = path;

    this.markAsTouched();
    if (!this.isDisabled) {
      this.onChange(this.path);
    }
  }

  writeValue(path: string) {
    this.setPath(path);
  }

  registerOnChange(onChange) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.isDisabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const path = control.value;
    if (path === "") {
      return {
        mustRequired: { path },
      };
    }
  }

  async openChangeOUDialog() {
    this.navService.loading.next(true);
    this.loading = true;
    const ou = await this.ouService.getUserOUs();
    const dialogRef = this.dialog.open(OuSelectionComponent, {
      width: "500px",
      data: {
        domain: this.user.email.replace(/.*@/, ""),
        user: this.user,
        orgPath: this.user.isAdmin ? "/" : ou[0]["orgUnitPath"],
        selectedOU: this.path,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.path = result.orgUnitPath;
        this.setPath(result.orgUnitPath);
        this.onPathChange.emit(this.path);
        this.onIdChanged.emit(result.orgUnitId);
      }
    });
    this.loading = false;
    this.navService.loading.next(false);
  }
}
