import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { Observable } from "rxjs";
import { MatChipInputEvent, MatChipListbox } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { map, startWith } from "rxjs/operators";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { LicensesService, Product, SKU } from "src/app/services/admin-plus/licenses.service";
import { AutomationService, WorkflowAction } from "src/app/services/workflow-plus/automation.service";

@Component({
  selector: "app-single-selection",
  templateUrl: "./single-selection.component.html",
  styleUrls: ["./single-selection.component.scss"],
})
export class SingleSelectionComponent implements OnInit {
  @Output() selectedItem = new EventEmitter();
  @Input() selector = "";
  @Input() item = null;
  @Input() workflowAction: WorkflowAction;
  @Input() fieldLabel = "";
  @Input() placeholder = "";
  @Input() parameterId: number;
  @ViewChild("itemsInput") itemsInput: ElementRef;
  @ViewChild("itemsList") itemsList: MatChipListbox;
  public readonly separatorKeysCodes = [ENTER, COMMA] as const;
  public filteredOptions: Observable<unknown>;
  public itemsCtrl = new UntypedFormControl(null, Validators.required);
  public items = [];
  public selected_items = [];
  public SKUs: SKU[] = [];
  public productId = "";

  constructor(
    private licensesService: LicensesService,
    private navService: NavService,
    public automationService: AutomationService
  ) {}

  async ngOnInit() {
    if (this.item) {
      if (this.selector === "product") {
        const product = await this.licensesService.getProduct(this.item);
        if (product && product.productName) this.item = product.productName;
      }
      if (this.selector === "sku") {
        const sku = await this.licensesService.getSKU(this.item);
        if (sku && sku.skuName) this.item = sku.skuName;
      }
      this.selected_items.push(this.item);
    }
    this.loadData();
  }

  loadData() {
    if (this.selector === "product") this.getProducts();
    if (this.selector === "sku" && this.workflowAction.action.parameters[0].parameterValue.value) this.getSKUs();
  }

  public async add(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value.trim();
    if ((value || "").trim()) {
      if (!this.item) {
        if (this.parameterId === 9) {
          for (const product of this.licensesService.licenses) {
            if (product.productName.toLowerCase() === value.toLowerCase()) {
              const item = {
                itemId: product.productId,
                itemName: product.productName,
              };
              this.item = item;
              this.selected_items.push(this.item);
              const selectedObject = {
                selected_item: this.item.itemId,
                parameterId: this.parameterId,
              };
              this.selectedItem.emit(selectedObject);
              this.items = [];
            }
          }
        }
        if (this.parameterId === 10) {
          for (const product of this.licensesService.licenses) {
            if (product.productId === this.productId) {
              for (const sku of product.SKUs) {
                if (sku.skuName.toLowerCase() === value.toLowerCase()) {
                  const item = {
                    itemId: sku.skuId,
                    itemName: sku.skuName,
                  };
                  this.item = item;
                  this.selected_items.push(this.item);
                  const selectedObject = {
                    selected_item: this.item.itemId,
                    parameterId: this.parameterId,
                  };
                  this.selectedItem.emit(selectedObject);
                }
              }
            }
          }
        }
      }
      // Reset the input value
      if (input) {
        input.value = "";
      }
    }
    this.itemsInput.nativeElement.value = "";
    this.itemsCtrl.setValue(null);
  }

  remove(): void {
    this.item = "";
    this.selected_items = [];
    const selectedObject = {
      selected_item: null,
      parameterId: this.parameterId,
    };
    this.selectedItem.emit(selectedObject);
  }

  async selected(event: MatAutocompleteSelectedEvent) {
    if (!this.item) {
      this.item = event.option.value;
      if (this.parameterId) {
        this.selected_items.push(this.item);
        const selectedObject = {
          selected_item: this.item.itemId,
          parameterId: this.parameterId,
        };
        this.selectedItem.emit(selectedObject);
      }
      this.itemsInput.nativeElement.value = "";
      this.itemsCtrl.setValue(null);
    }
  }

  async getProducts(search = "") {
    if (!this.item) {
      let products: Product[] = [];
      if (this.licensesService.licenses.length > 0) {
        products = this.licensesService.licenses;
        if (search !== "") {
          products = products.filter(function (product) {
            return product.productName.toLowerCase().indexOf(search.toLowerCase()) !== -1;
          });
        }
      } else {
        this.navService.loading.next(true);
        products = await this.licensesService.getLicenses(search);
        this.navService.loading.next(false);
      }
      if (products) {
        this.items = [];
        for (const product of products) {
          const objectProduct = {
            itemName: product.productName,
            itemId: product.productId,
          };
          this.items.push(objectProduct);
        }
      }
      this.filteredOptions = this.itemsCtrl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value))
      );
    }
  }

  async getSKUs(search = "") {
    if (!this.item) {
      if (this.productId === "" || this.productId !== this.workflowAction.action.parameters[0].parameterValue.value) {
        this.SKUs = [];
        this.productId = this.workflowAction.action.parameters[0].parameterValue.value;
      }
      if (this.SKUs.length === 0) {
        this.navService.loading.next(true);
        if (this.licensesService.licenses.length === 0) {
          await this.licensesService.getLicenses(search);
        }
        for (const product of this.licensesService.licenses) {
          if (product.productId === this.productId) {
            for (const sku of product.SKUs) {
              if (!this.SKUs.some((s) => s.skuName === sku.skuName)) {
                this.SKUs.push(sku);
              }
            }
          }
        }
        this.navService.loading.next(false);
      } else {
        this.SKUs = this.SKUs.filter(function (sku) {
          if (search !== "") {
            return sku.skuName.toLowerCase().indexOf(search.toLowerCase()) !== -1;
          }
        });
      }
      if (this.SKUs) {
        this.items = [];
        for (const sku of this.SKUs) {
          const objectSku = {
            itemName: sku.skuName,
            itemId: sku.skuId,
          };
          this.items.push(objectSku);
        }
      }
      this.filteredOptions = this.itemsCtrl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value))
      );
    }
  }

  private _filter(value) {
    if (typeof value !== "object" && value !== null) {
      let filterValue = "";
      if (value) {
        filterValue = value.toLowerCase();
      }
      return this.items.filter((item) => item.itemName.toLowerCase().indexOf(filterValue) === 0);
    }
  }

  removeExtraText() {
    if (this.selected_items.length) {
      this.itemsInput.nativeElement.value = "";
    }
  }

  search(event) {
    const search = event.target.value;
    if (!this.item) {
      if (this.selector === "product") this.getProducts(search);
      if (this.selector === "sku" && this.workflowAction.action.parameters[0].parameterValue.value) this.getSKUs(search);
    }
  }
}
