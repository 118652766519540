import { Component, Input, Output, EventEmitter } from "@angular/core";
import { AppSettingLink } from "src/app/models/admin-plus/appSettings";
import { AdminPlusService } from "src/app/services/admin-plus/admin-plus.service";
import { trigger, state, style, animate, transition } from "@angular/animations";

@Component({
  selector: "app-breadcrumbs",
  templateUrl: "./breadcrumbs.component.html",
  styleUrls: ["./breadcrumbs.component.scss"],
  animations: [
    trigger("fadeInOut", [
      state("in", style({ opacity: 1, transform: "translateY(0) translateX(-15px)" })),
      transition(":enter", [
        style({ opacity: 0, transform: "translateY(-150px)" }),
        animate("300ms ease", style({ opacity: 1, transform: "translateY(0) translateX(-15px)" })),
      ]),
    ]),
  ],
})
export class BreadcrumbsComponent {
  @Input() breadCrumbLinks: AppSettingLink[] = [];
  @Input() buttons = [];
  @Output() buttonSelected: EventEmitter<void> = new EventEmitter();

  constructor(public adminPlusService: AdminPlusService) {}
}
