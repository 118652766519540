import { Component } from "@angular/core";
import { LeftNavService } from "../../services/admin-plus/leftnav.service";
import { CustomerService } from "../../services/admin-plus/customer.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { LeftNavLink } from "src/app/models/admin-plus/base";
import { ModuleService } from "src/app/services/admin-plus/module.service";
import { FilterType } from "src/app/services/utilities/filters.service";
import { User } from "src/app/users/users";

export interface OUStructure {
  name: string;
  children: OUStructure[];
}

@Component({
  selector: "app-leftnav",
  templateUrl: "./leftnav.component.html",
  styleUrls: ["./leftnav.component.scss"],
})
export class LeftnavComponent {
  hasQueryParams = false;
  refreshListener: Subscription;
  topLinks: LeftNavLink[];
  bottomLinks: LeftNavLink[];
  filterTypeValue: FilterType = FilterType.TICKET;
  current_user: User;
  public currentYear = 0;

  constructor(
    public leftNavService: LeftNavService,
    public customerService: CustomerService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public moduleService: ModuleService
  ) {
    const d = new Date();
    this.currentYear = d.getFullYear();

    const current_user: User = JSON.parse(localStorage.getItem("user"));
    if (current_user) {
      this.current_user = current_user;
    }
    const modules: string = localStorage.getItem("modules");
    try {
      this.leftNavService.modules = JSON.parse(modules);
    } catch {
      this.leftNavService.modules = [];
    }

    this.router.events.subscribe((event) => {
      const foundLink = this.leftNavService.leftNavLinks.find((link) => this.router.url.startsWith("/" + link.module.name));

      if (foundLink) foundLink.showChildren = true;

      this.leftNavService.leftNavLinks.forEach((link) => {
        if (link.showChildren && link != foundLink) {
          link.showChildren = false;
        }
      });
      this.hasQueryParams = Object.entries(this.activatedRoute.snapshot.queryParams).length == 0;
      if (event instanceof NavigationEnd) {
        this.customerService.sendRefresh("refresh");
      }
    });

    this.topLinks = leftNavService.leftNavLinks.filter((link) => link.location == "top");
    this.bottomLinks = leftNavService.leftNavLinks.filter((link) => link.location == "bottom");

    this.refreshListener = this.customerService.refreshModule().subscribe((module) => {
      if (module == "refresh") {
        this.updateModuleLinks();
      }
    });
  }

  updateModuleLinks() {
    if (this.customerService.customer?.subscription?.expired) return;
    this.leftNavService.leftNavLinks.forEach((link) => {
      if (this.customerService.customerModules?.some((csm) => csm.id == link.module.id)) {
        const loading = !this.customerService.customerModules.some((m) => m.id == link.module.id && m.serviceAccountId);
        link.loading = loading;
        link.disabled = loading;
        link.errored = this.customerService.customerModules.some(
          (m) => m.id == link.module.id && m.serviceAccountId === null
        );
      } else if (link.module.id) {
        link.errored = this.customerService.customerModulesError;
      }

      if (link.errored) {
        link.loading = false;
        link.disabled = false;
      }
    });
  }
}
