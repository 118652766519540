<div class="login">
  <h1 class="mb-4">Signing in to {{ authService.appName }}</h1>
  <table class="table table-borderless text-left">
    <tr>
      <td>
        <span *ngIf="validatingToken"> Validating login token... </span>
      </td>
      <td>
        <span *ngIf="validatingToken && tokenValidated">
          <span class="material-icons text-success mb-0"> done_outline </span>
        </span>
        <span *ngIf="validatingToken && !tokenValidated">
          <span class="material-icons faa-spin"> filter_tilt_shift </span>
        </span>
      </td>
    </tr>
    <tr>
      <td>
        <span *ngIf="gettingModules"> Retrieving user modules... </span>
      </td>
      <td>
        <span *ngIf="gettingModules && modulesRetrieved">
          <span class="material-icons text-success mb-0"> done_outline </span>
        </span>
        <span *ngIf="gettingModules && !modulesRetrieved">
          <span class="material-icons faa-spin"> filter_tilt_shift </span>
        </span>
      </td>
    </tr>
    <tr>
      <td>
        <span *ngIf="checkingTosAccepted"> Checking for accepted TOS... </span>
      </td>
      <td>
        <span *ngIf="checkingTosAccepted && tosAccepted">
          <span class="material-icons text-success mb-0"> done_outline </span>
        </span>
        <span *ngIf="checkingTosAccepted && !tosAccepted">
          <span class="material-icons faa-spin"> filter_tilt_shift </span>
        </span>
      </td>
    </tr>
    <tr>
      <td>
        <span *ngIf="gettingAccessToken"> Retrieving access token... </span>
      </td>
      <td>
        <span *ngIf="gettingAccessToken && accessTokenRetrieved">
          <span class="material-icons text-success mb-0"> done_outline </span>
        </span>
        <span *ngIf="gettingAccessToken && !accessTokenRetrieved">
          <span class="material-icons faa-spin"> filter_tilt_shift </span>
        </span>
      </td>
    </tr>
    <tr>
      <td>
        <span *ngIf="customizingApplication">
          Customizing application for user...
        </span>
      </td>
      <td>
        <span *ngIf="customizingApplication && applicationCustomized">
          <span class="material-icons text-success mb-0"> done_outline </span>
        </span>
        <span *ngIf="customizingApplication && !applicationCustomized">
          <span class="material-icons faa-spin"> filter_tilt_shift </span>
        </span>
      </td>
    </tr>
  </table>
</div>
