import { Component, OnInit, ViewChild } from "@angular/core";
import { StorageReport, ReportsService } from "src/app/services/google/reports.service";
import { Drives } from "src/app/services/storage-plus/drives.service";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { jsPDF } from "jspdf";
import { Router } from "@angular/router";
import html2canvas from "html2canvas";
import { ExportsheetsService } from "src/app/services/google/sheets.service";
import { StorageReportDrivesTableComponent } from "src/app/storage-plus/storage/storage-report-drives/storage-report-drives-table/storage-report-drives-table.component";
import { AdminPlusService } from "src/app/services/admin-plus/admin-plus.service";

@Component({
  selector: "app-storage-report-drives",
  templateUrl: "./storage-report-drives.component.html",
  styleUrls: ["./storage-report-drives.component.scss"],
})
export class StorageReportDrivesComponent implements OnInit {
  options = [];
  @ViewChild(StorageReportDrivesTableComponent)
  drivesTableComponent: StorageReportDrivesTableComponent;

  storageReport: StorageReport = {
    status: {
      name: "",
      id: 4,
    },
    totalUsers: 0,
    totalStorage: 0,
    totalDrive: 0,
    totalGmail: 0,
    totalPhotos: 0,
    driveFilesSynced: new Date().toString(),
  };

  drives: Drives[] = [];
  columns = [];

  constructor(
    private navService: NavService,
    private router: Router,
    private exportSheetsService: ExportsheetsService,
    private reportsService: ReportsService,
    private adminPlusService: AdminPlusService
  ) {
    this.options.push({
      icon: "picture_as_pdf",
      name: "Export to PDF",
      disabled: true,
      tooltip: "Exports the current page to a PDF document for sharing",
      class: "export",
    });
    this.options.push({
      icon: "description",
      name: "Export to Sheets",
      disabled: true,
      tooltip: "Exports the current data set to Google Sheets",
      class: "success",
    });

    this.adminPlusService.breadCrumbLinks.push({
      link: null,
      text: "Shared Drives",
      alt: "Shared Drives",
    });
  }

  async ngOnInit() {
    this.storageReport = await this.reportsService.getStorageReport();
    if (!this.storageReport || this.storageReport.status.id != 4) {
      //complete
      this.router.navigate(["/reports/storage"]);
    }
  }

  optionSelected(option) {
    switch (option.name) {
      case "Export to PDF":
        this.downloadAsPDF();
        break;
      case "Export to Sheets":
        this.exportToSheets();
        break;
      default:
        break;
    }
  }

  receiveDrives($event) {
    this.drives = $event["drives"];
    this.columns = $event["columns"];
  }

  async exportToSheets() {
    this.navService.loading.next(true);
    const spreadsheetID = await this.exportSheetsService.createSpreadsheet("Shared Drives");
    await this.drivesTableComponent.sendDrivesEmitter();
    await this.exportSheetsService.addData(spreadsheetID, this.columns, this.drives);
    window.open("https://docs.google.com/spreadsheets/d/" + spreadsheetID);
    this.navService.loading.next(false);
  }

  async downloadAsPDF() {
    this.navService.loading.next(true);
    const canvas = await this.canvas();
    if (canvas) {
      this.navService.loading.next(false);
    }
  }

  async canvas() {
    return new Promise((resolve) => {
      html2canvas(document.getElementById("entireScreen")).then(function (canvas) {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 210;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        const doc = new jsPDF("p", "mm");
        let position = 0;
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save("storage-report-drives.pdf");
        resolve(true);
      });
    });
  }
}
