import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { AuthService } from "src/app/services/admin-plus/auth.service";
import { environment } from "src/environments/environment";
import { User } from "src/app/web/login/user";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  private user: User;
  public api_url = "https://www.googleapis.com/admin/directory/v1/users";
  private kcApiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient, private loginService: LoginService, private authService: AuthService) {}

  async getUser(userKey: string, mute = false): Promise<User> {
    this.user = this.loginService.getUser();
    if (!this.user) {
      return;
    }

    return new Promise<User>((resolve) => {
      this.getUserData(userKey, mute).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getUserData(userKey: string, mute = false): Observable<User> {
    return this.http
      .get<User>(this.api_url + "/" + userKey, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + this.user.authToken),
        params: new HttpParams().set("projection", "full"),
      })
      .pipe(catchError(this.authService.handleAPIError<User>("getUserData", mute)));
  }

  public async getAdminUser(userKey: string, accessToken: string) {
    return new Promise<User>((resolve) => {
      this.getAdminUserData(userKey, accessToken).subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getAdminUserData(userKey: string, accessToken: string) {
    return this.http
      .get<User>(this.api_url + "/" + userKey, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + accessToken),
      })
      .pipe(catchError(this.authService.handleAPIError<any>("getAdminUserData")));
  }
}

export interface Users {
  kind: string;
  trigger_event: string;
  etag: string;
  users: User[];
  nextPageToken: string;
}
