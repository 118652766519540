<div class="col-12 mt-2">
  <mat-accordion>
    <mat-expansion-panel
      [expanded]="panelOpenState"
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
      [ngClass]="{ 'h-100': panelOpenState }"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h4>{{ title }}</h4>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row">
        <div class="col-12">
          <div class="" *ngIf="loading">
            <div class="alert alert-info d-flex align-items-center m-3" *ngIf="loading">
              <span class="material-icons mat-icon faa-spin mr-3"> filter_tilt_shift </span>
              <p class="mb-0">    Gathering {{ title }}...</p>
            </div>
            </div>
        </div>
      </div>
      <div class="row">
        <div *ngFor="let chart of charts" class="chart-container col-12 col-md-4 p-4 text-center">
          <google-chart
            [options]="chart.options"
            [type]="chart.type"
            [data]="chart.data"
            [dynamicResize]="true"
            *ngIf="chart.data.length > 0"
          ></google-chart>
          <span class="text-center">{{ chart?.title }}</span>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
