import { Injectable } from "@angular/core";
import { UtilityService, Payload } from "../utilities/utility.service";
import { Type } from "src/app/models/asset-plus/type";

@Injectable({
  providedIn: "root",
})
export class TypeService {
  constructor(public utilityService: UtilityService) {}

  async createUpdateType(type: Type) {
    return new Promise<Payload>((resolve) => {
      const method = "POST";
      const path = `/devices/types/` + (type.id ? type.id : "");
      const body = type;

      this.utilityService.sendRequest(method, path, {}, body).subscribe((results) => {
        resolve(results);
      });
    });
  }

  async getTypes(params?) {
    return new Promise<Payload>((resolve) => {
      this.utilityService.sendRequest("GET", `/devices/types`, params, null).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  async deleteType(type: Type) {
    return new Promise<Payload>((resolve) => {
      const path = `/devices/types/${type.id}`;

      this.utilityService.sendRequest("DELETE", path, {}, {}).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }
}
