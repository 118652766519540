<h2 mat-dialog-title>{{ action }} Type</h2>
<form (ngSubmit)="processForm()" autocomplete="off">
    <div mat-dialog-content>
        <div class="row">
            <div *ngIf="action != 'Delete'" class="col-12 mt-2">
                <mat-form-field class="w-100">
                    <mat-label>Type</mat-label>
                    <input 
                        matInput
                        type="text"
                        [(ngModel)]="type.name"
                        name="type"
                        maxlength="255"
                        required
                    />
                </mat-form-field>
            </div>
            <div class="col-12 mt-12" *ngIf="action == 'Delete'">
                <h4>Are you sure you want to delete {{ type.name }}?</h4>
            </div>
        </div>
        <div mat-dialog-actions>
            <button mat-stroked-button mat-dialog-close>Cancel</button>
            <button
                type="submit"
                mat-stroked-button
                class="ml-2"
                [class]="action == 'Delete' ? 'danger' : 'success'"
                [disabled]="loading"
            >
                <span>{{ action }} Type</span>
            </button>
         </div>
    </div>
</form>