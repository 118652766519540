<form (ngSubmit)="onSubmit()">
  <div class="row">
    <div *ngIf="!viewOnly" class="col-12 col-md-4 mt-2">
      <mat-form-field class="w-100 h-100">
        <mat-label>Actions</mat-label>
        <mat-select [formControl]="actions">
          <mat-option value="" selected></mat-option>
          <mat-option value="now" [disabled]="selection.selected.length == 0 || checkTrashed() || checkMoving()"
            >Move to Trash Now ...</mat-option
          >
          <mat-option
            value="schedule"
            [disabled]="selection.selected.length == 0 || checkTrashed() || checkDrive() || checkMoving()"
            >Schedule for Trash ...</mat-option
          >
          <mat-option
            *ngIf="storageReport.allowDelete"
            value="delete"
            [disabled]="selection.selected.length == 0 || checkMoving()"
            >Delete File ...</mat-option
          >
          <mat-option
            *ngIf="user.isAdmin && customerService.customer.gcsEnabled"
            value="transfer"
            [disabled]="selection.selected.length == 0 || checkTrashed() || checkDrive() || checkMoving()"
            >Transfer to Google Cloud Storage ...</mat-option
          >
          <mat-option value="transferOwnership" [disabled]="selection.selected.length == 0 || checkDrive() || checkMoving()"
            >Transfer Ownership ...</mat-option
          >
          <mat-option value="restoreFromTrash" [disabled]="selection.selected.length == 0 || !canUndoTrash()"
            >Restore from Trash</mat-option
          >
          <mat-option value="restoreFromSchedule" [disabled]="selection.selected.length == 0 || !canUndoSchedule()">
            Undo Schedule for Trash
          </mat-option>
          <!--
          <mat-option
            value="fixDuplicates"
            [disabled]="selection.selected.length !== 1 || checkMd5Checksum()"
            >Find & Fix Duplicate Files ...</mat-option
          >-->
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 col-md-8 mt-2">
      <app-filters
        [inputSelectedFilters]="selectedFilters"
        [filterType]="filterTypeValue"
        [driveId]="driveId"
        [userId]="userId"
        (dataEvent)="receiveData($event)"
      >
      </app-filters>
    </div>
  </div>
</form>
<div class="row">
  <div class="col-12">
    <small
      class="text-muted d-flex align-items-center mb-1"
      *ngIf="storageReport.status.id == 4 && !storageReport.filesSynced"
      style="gap: 4px"
    >
      <mat-icon class="faa-spin" style="transform: scale(0.75)"> filter_tilt_shift </mat-icon>
      Files are still syncing...
    </small>
    <div id="pdfTable" #pdfTable class="table-responsive">
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              color="primary"
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() ? true : false && getPageData()"
              [indeterminate]="selection.hasValue() && !getPageData()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td *matCellDef="let row">
            <mat-checkbox
              color="primary"
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [disabled]="row.busy !== 0"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="details">
          <th mat-header-cell *matHeaderCellDef class="justify-content-end">
            Name<br /><small class="text-muted">File Id</small>
          </th>
          <td [ngClass]="{ grayout: row.trashed === 1 || row.busy !== 0 }" mat-cell *matCellDef="let row">
            <div class="d-flex align-items-center">
              <span *ngIf="row.busy !== 0" class="icon-spinner mr-2 mat-icon material-icons mb-2"> filter_tilt_shift </span>
              <div class="d-flex align-items-center" style="gap: 12px">
                <mat-icon class="text-muted" [matTooltip]="row.mimeType">{{ mimeTypeToIcon(row.mimeType) }}</mat-icon>
                <div>
                  <div class="d-flex align-items-center">
                    <span class="fw-bold">{{ row.name }}</span>
                    <mat-icon
                      *ngIf="row.error"
                      class="text-danger ml-1"
                      style="transform: scale(0.75)"
                      matTooltip="{{ row.error }}"
                      >error</mat-icon
                    >
                    <mat-icon
                      *ngIf="row.scheduledDateTime && !row.trashed"
                      class="ml-1"
                      style="transform: scale(0.75)"
                      matTooltip="This file is scheduled to be moved to the user's trash."
                      >schedule</mat-icon
                    >
                    <mat-icon
                      *ngIf="row.trashed === 1"
                      class="ml-1"
                      style="transform: scale(0.75)"
                      matTooltip="This file is in the user's trash."
                      >delete</mat-icon
                    >
                  </div>
                  <small
                    class="cursor-pointer text-muted"
                    (click)="copyToClipboard(row.googleId)"
                    matTooltip="Copy id to clipboard"
                    >{{ row.googleId }}</small
                  >
                </div>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="owner">
          <th mat-header-cell *matHeaderCellDef>
            Owner<br /><small class="text-muted" matTooltip="Date the file was last viewed by the owner">Last Viewed</small>
          </th>
          <td *matCellDef="let row">
            <a class="text-nowrap" (click)="routeToUser(row.userId)">
              {{ row.user | emptyValueDisplay }}<br />
              <small class="text-muted"
                ><span class="text-nowrap">{{ row.viewedByMeTime | date : "short" }}</span></small
              >
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="lastModifiedBy">
          <th mat-header-cell *matHeaderCellDef>Last Modified</th>
          <td *matCellDef="let row">
            {{ row.lastModifiedBy | emptyValueDisplay }}<br />
            <small class="text-muted"
              ><span class="text-nowrap">{{ row.modifiedDate | date : "short" }}</span></small
            >
          </td>
        </ng-container>
        <!--ng-container matColumnDef="mimeType">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Mime Type</th>
          <td [ngClass]="{ grayout: row.trashed === 1 || row.busy !== 0 }" class="" *matCellDef="let row">
            <span class="text-nowrap">{{ row.mimeType }}</span>
          </td>
        </ng-container-->
        <ng-container matColumnDef="storageMb">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Storage (GB)</th>
          <td [ngClass]="{ grayout: row.trashed === 1 || row.busy !== 0 }" mat-cell *matCellDef="let row">
            <span class="text-nowrap">{{ row.storageMb / 1024 | number : "1.1-2" }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="createdDate">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Added to Drive</th>
          <td [ngClass]="{ grayout: row.trashed === 1 || row.busy !== 0 }" *matCellDef="let row">
            <span class="text-nowrap">{{ row.createdDate | date : "short" }}</span>
          </td>
        </ng-container>
        <!--ng-container matColumnDef="viewedByMeTime">
          <th mat-header-cell mat-header-cell mat-sort-header *matHeaderCellDef>Viewed By Owner</th>
          <td [ngClass]="{ grayout: row.trashed === 1 || row.busy !== 0 }" *matCellDef="let row">
            <span class="text-nowrap">{{ row.viewedByMeTime | date : "short" }}</span>
          </td>
        </ng-container-->
        <ng-container matColumnDef="driveName">
          <th mat-header-cell *matHeaderCellDef>Drive Name</th>
          <td [ngClass]="{ grayout: row.trashed === 1 || row.busy !== 0 }" *matCellDef="let row">
            <span class="text-nowrap">{{ row.driveName }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="movedToTrashDate">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td [ngClass]="{ grayout: row.trashed === 1 || row.busy !== 0 }" *matCellDef="let row">
            <span class="text-nowrap" style="margin: 0px; display: inline-block">
              <span
                matTooltip="It appears the user has restored this file from trash."
                *ngIf="row.trashed === 0 && row.reasonDeleted !== null && !row.scheduledDateTime"
                class="text-danger"
                class="mat-icon material-icons mr-2"
              >
                feedback
              </span>
              <span *ngIf="row.trashed === 0 && row.reasonDeleted !== null && !row.scheduledDateTime"
                ><i>Moved to Trash</i></span
              >
            </span>
            <span *ngIf="row.fileTransfer === 1">
              <span style="display: inline-block; margin: 0px" class="material-icons mat-icon mr-2"> swap_horiz </span>
              <span style="display: inline-block; margin: 0px">Transferring</span>
            </span>
            <span class="text-nowrap" *ngIf="row.trashed && row.movedToTrashDate !== null" class="text-nowrap"
              >Moved to Trash</span
            >
            <span class="text-nowrap" *ngIf="row.trashed && row.movedToTrashDate === null" class="text-nowrap">Trashed</span>
            <span class="text-nowrap" *ngIf="row.scheduledDateTime && !row.trashed" class="text-nowrap"
              >Scheduled for Trash</span
            >
            <br />
            <span
              class="text-nowrap cutoffText"
              matTooltip="{{ row.reasonDeleted }}"
              *ngIf="row.trashed && row.reasonDeleted !== '' && row.reasonDeleted !== null"
              style="font-size: 10px"
              >({{ row.reasonDeleted }})</span
            >
            <span
              class="text-nowrap cutoffText"
              matTooltip="{{ row.reasonScheduled }}"
              *ngIf="row.scheduledDateTime && !row.trashed && row.reasonScheduled !== '' && row.reasonScheduled !== null"
              style="font-size: 10px"
              >({{ row.reasonScheduled }})</span
            >
          </td>
        </ng-container>
        <ng-container matColumnDef="scheduledDateTime">
          <th mat-header-cell *matHeaderCellDef>Trashed/Scheduled</th>
          <td [ngClass]="{ grayout: row.trashed === 1 || row.busy !== 0 }" *matCellDef="let row">
            <span *ngIf="row.scheduledDateTime" class="text-nowrap">{{ row.scheduledDateTime | date : "short" }}</span>
            <span *ngIf="row.trashed" class="text-nowrap">
              {{ row.movedToTrashDate ? (row.movedToTrashDate | date : "short") : "" }}</span
            >
          </td>
        </ng-container>
        <ng-container matColumnDef="filePath">
          <th mat-header-cell *matHeaderCellDef>File Path</th>
          <td *matCellDef="let row">
            <div matTooltip="Disabled" aria-haspopup="true">
              <button
                mat-icon-button
                class="text-gray"
                (click)="openFilePathDialog(row.id, row.name)"
                #tooltip="matTooltip"
                matTooltip="View file path"
                aria-label="Button that displays file paths"
              >
                <mat-icon class="text-gray-700">settings</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <tr class="mat-row" app-empty-dataset-alert [loading]="loading" [filterCount]="data.filtersCount" *matNoDataRow></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <app-table-footer
      [dataSource]="dataSource"
      [maxResults]="maxResults"
      [nextButton]="nextButton"
      [nextButtonDis]="nextButtonDis"
      [offset]="offset"
      [page]="page"
      [total]="totalFileCount"
      (pager)="setPager($event)"
    ></app-table-footer>
  </div>
</div>
