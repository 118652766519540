<h2 mat-dialog-title>
  <i style="font-size: 20px" class="faa-shake material-icons material-icon-align mr-2">warning</i>
  Admin+ by Trafera Terms of Service
</h2>
<div mat-dialog-content class="text-center">
  <p class="pb-3">
    By signing and clicking <strong>I Agree</strong> below, you are accepting the
    <strong>Admin+ Terms of Service</strong> and signing that agreement electronically. The Admin+ Terms of Service must be
    agreed to before users from your organization can access Admin+. You represent that you have the authority to bind your
    organization to these terms.
  </p>
  <a class="text-center" target="_blank" href="https://admin-plus.trafera.app/tos" target="_blank">
    <h3>Read Terms of Service</h3>
  </a>
  <br />
  <div class="border border-dark">
    <div class="col-12 mt-2">
      <mat-form-field class="w-100">
        <mat-label> Signature </mat-label>
        <input
          matInput
          [(ngModel)]="signature"
          (ngModelChange)="onChangeSignature($event)"
          type="text"
          name="signature"
          id="signature"
          placeholder="Enter your full legal name *"
          autocomplete="off"
          required
        />
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="showError" class="alert alert-danger">
    Unable to Confirm Terms of Service - please contact google.support&#64;trafera.com for further assistance.
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close class="btn" [disabled]="lockButtons" (click)="onDismiss()">Cancel</button>
    <button mat-stroked-button class="btn success ml-2" (click)="onConfirm()" [disabled]="lockButtons" cdkFocusInitial>
      <span>I Agree</span>
    </button>
  </div>
</div>
