import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { ServiceAccountService } from "src/app/services/admin-plus/service-account.service";

@Component({
  selector: "app-test-service-account",
  templateUrl: "./test-service-account.component.html",
})
export class TestServiceAccountComponent implements OnInit {
  serviceAccountId: number;
  loading: boolean;
  failedScopeStatus: boolean;
  reload: boolean;

  constructor(
    private dialogRef: MatDialogRef<TestServiceAccountComponent>,
    private notificationService: NotificationService,
    private serviceAccountService: ServiceAccountService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  async loadData() {
    this.serviceAccountId = this.data?.serviceAccount?.id;

    if (!this.serviceAccountId) return this.notificationService.notify("Invalid service account data - unable to test");
    this.loading = true;
    await this.serviceAccountService.checkScopes(this.serviceAccountId);

    this.failedScopeStatus = await this.serviceAccountService.failedScopeStatus(this.serviceAccountId);

    this.loading = false;
  }

  close() {
    this.dialogRef.close(this.failedScopeStatus);
  }

  retry() {
    this.loadData();
  }
}
