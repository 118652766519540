import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Building } from "src/app/models/admin-plus/building";
import { Room } from "src/app/models/admin-plus/room";
import { RoomsService } from "src/app/services/admin-plus/rooms.service";

@Component({
	selector: "app-rooms",
	templateUrl: "./rooms.component.html",
})
export class RoomsInputComponent {
	@Input() parentLoading = false;
	@Input() selectedBuilding: Building;
	@Input() selectedRoom: Room;
	@Output() roomChange = new EventEmitter();

	loading = false;
	loadingRooms = false;
	rooms: Room[];
	lastBuildingId: number;

	constructor(private roomsService: RoomsService) {}

	ngDoCheck() {
		if (!this.selectedBuilding?.id) {
			this.selectedRoom = null;
		}
		if (this.selectedBuilding?.id && this.selectedBuilding?.id !== this.lastBuildingId) {
			this.loading = true;
			this.lastBuildingId = this.selectedBuilding.id;
			this.populateRooms();
			this.loading = false;
		}
	}

	async ngOnInit(): Promise<void> {
		this.loading = true;
		if (this.selectedBuilding?.id) {
			this.lastBuildingId = this.selectedBuilding.id;
			this.populateRooms();
		}
		this.loading = false;
	}

	async onRoomChange() {
		this.selectedBuilding.room = this.selectedRoom;
		this.roomChange.emit(this.selectedBuilding);
	}

	async populateRooms() {
		this.loadingRooms = true;
		const rooms = await this.roomsService.getRooms(this.selectedBuilding.id);
		this.rooms = rooms?.count ? rooms.data : [];
		this.loadingRooms = false;
	}

	compare(o1, o2) {
		return o1 && o2 ? o1.id == o2.id : false;
	}
}
