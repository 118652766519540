import { Component, OnInit, Inject, ElementRef, ViewChild } from "@angular/core";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UntypedFormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { UsersService } from "src/app/services/admin-plus/users.service";
import { GroupsService } from "src/app/services/admin-plus/groups.service";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { User } from "src/app/users/users";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { OUService } from "src/app/services/google/ou.service";

@Component({
  selector: "app-members-dialog",
  templateUrl: "./members-dialog.component.html",
  styleUrls: ["./members-dialog.component.scss"],
})
export class MembersDialogComponent implements OnInit {
  private user: any;
  public users: User[] = [];
  public selected_users: string[];
  public users_attempted = 0;
  public users_failed_to_add = 0;
  public users_added = [];
  public removable: boolean = true;
  public userCtrl = new UntypedFormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  public filteredOptions: Observable<any[]>;
  public orderBy: string = "givenName";
  public sortOrder: string = "SORT_ORDER_UNDEFINED";

  @ViewChild("userInput") userInput: ElementRef;

  constructor(
    private usersService: UsersService,
    private groupsService: GroupsService,
    private notifyService: NotificationService,
    private dialogRef: MatDialogRef<MembersDialogComponent>,
    private ouService: OUService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private navService: NavService
  ) {
    const user: any = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.user = user;
    }
  }

  public add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || "").trim()) {
      var user: any = {
        primaryEmail: value,
      };
      this.selected_users.push(user);
      // Reset the input value
      if (input) {
        input.value = "";
      }

      this.userCtrl.setValue(null);
    }
  }

  remove(email: string): void {
    const index = this.selected_users.indexOf(email);

    if (index >= 0) {
      this.selected_users.splice(index, 1);
      this.users = [];
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.users = [];
    this.selected_users.push(event.option.value);
    this.userInput.nativeElement.value = "";
    this.userCtrl.setValue(null);
  }

  async getUsers(emailSearch: string) {
    this.navService.loading.next(true);
    let OU = {};
    if (!this.user.isAdmin) {
      OU = await this.ouService.getUserOUs();
    }
    let response = await this.usersService.getUsers(
      25,
      "",
      this.orderBy,
      this.sortOrder,
      emailSearch,
      "",
      "",
      "",
      this.user.isAdmin ? "/" : OU[0]["orgUnitPath"]
    );
    if (response) {
      if (response.users) {
        this.users = [];
        if (typeof response.users !== "undefined") {
          this.users = response.users;
        }
      }
      //Remove any users who are already in the group
      for (let user of this.users) {
        user.visible = true;
        for (let member of this.data.members) {
          const memberId = member.name.substring(member.name.lastIndexOf("/") + 1);
          if (user.id == memberId) {
            user.visible = false;
          }
        }
        //Remove any user in the selection
        for (let selectedUser of this.selected_users) {
          let selected: Object = selectedUser;
          if (user.id == selected["id"]) {
            user.visible = false;
          }
        }
      }

      this.filteredOptions = this.userCtrl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value))
      );
    }
    this.navService.loading.next(false);
  }

  public processForm() {
    if (this.selected_users.length == 0) {
      this.makeList();
    }
    for (var i = 0; i < this.selected_users.length; i++) {
      this.saveMember(this.selected_users[i]);
    }
  }

  private makeList() {
    var list = this.userInput.nativeElement.value.split(/[ ,]+/);
    for (var i = 0; i < list.length; i++) {
      var member: any = {};
      member["primaryEmail"] = list[i];
      this.selected_users.push(member);
    }
  }

  private async saveMember(member: any) {
    this.navService.loading.next(true);
    if (member.primaryEmail === undefined) {
      var newMember: any = {
        preferredMemberKey: {
          id: member,
        },
        roles: [
          {
            name: "MEMBER",
          },
        ],
      };
    } else {
      var newMember: any = {
        preferredMemberKey: {
          id: member.primaryEmail,
        },
        roles: [
          {
            name: "MEMBER",
          },
        ],
      };
    }
    var response = await this.groupsService.addMember(this.data.id, newMember);
    if (response) {
      this.users_attempted++;
      if (response) {
        this.users_added.push(response["response"]);
      } else {
        this.users_failed_to_add++;
      }

      if (this.users_attempted == this.selected_users.length) {
        if (this.users_added.length == this.selected_users.length) {
          this.notifyService.notify(
            "Successfully added " + this.users_added.length + " membership" + (this.users_added.length > 1 ? "s" : "")
          );
        } else {
          this.notifyService.notify(
            "Failed to add " + this.users_failed_to_add + " member" + (this.users_failed_to_add > 1 ? "s" : "")
          );
        }
        this.dialogRef.close(this.users_added);
      }
    }
    this.navService.loading.next(false);
  }

  private _filter(value: any): any[] {
    if (typeof value !== "object" && value !== null) {
      let filterValue = "";
      if (value.primaryEmail) {
        filterValue = value.primaryEmail.toLowerCase();
      }

      return this.users.filter((user) => user.primaryEmail.toLowerCase().indexOf(filterValue) === 0 && user.visible);
    }
  }

  search(event: any) {
    let search = event.target.value;
    if (this.user.isAdmin) {
      //Won't work otherwise anyway.
      this.getUsers(search);
    }
  }

  ngOnInit(): void {
    this.selected_users = [];
  }
}
