import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { UsersService } from "src/app/services/admin-plus/users.service";
import { NavService } from "src/app/services/admin-plus/nav.service";

@Component({
  selector: "app-delete-group-memberships-dialog",
  templateUrl: "./delete-group-memberships-dialog.component.html",
  styleUrls: ["./delete-group-memberships-dialog.component.scss"],
})
export class DeleteGroupMembershipsDialogComponent {
  public groups: [];
  public groups_attempted_to_delete = 0;
  private groups_deleted = [];

  constructor(
    private dialogRef: MatDialogRef<DeleteGroupMembershipsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: NotificationService,
    private usersService: UsersService,
    private navService: NavService
  ) {}

  async delete() {
    this.navService.loading.next(true);
    for (var i = 0; i < this.data.groups.length; i++) {
      await this.deleteMember(this.data.groups[i], this.usersService.viewingUser.primaryEmail);
    }
    this.navService.loading.next(false);
    this.dialogRef.close(this.groups_deleted);
  }

  private async deleteMember(group: any, userKey: string) {
    var response = await this.usersService.deleteGroupMembership(group.id, userKey);
    return new Promise((resolve) => {
      this.groups_attempted_to_delete++;
      if (response !== undefined) {
        this.notificationService.notify("Removed membership in " + group.name);
        this.groups_deleted.push(group);
        resolve(group);
      } else {
        this.notificationService.notify("There was an error trying to remove the membership " + group.name);
        resolve(null);
      }
    });
  }
}
