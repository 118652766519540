<h2 mat-dialog-title>Are you sure you want to remove the following members?</h2>
<div mat-dialog-content>
	<ul>
		<li *ngFor="let member of members">
			{{ member.preferredMemberKey.id }}
		</li>
	</ul>
	<div mat-dialog-actions class='px-0'>
		<button mat-dialog-close mat-stroked-button class=''>Cancel</button>
		<button create mat-stroked-button (click)="delete()">Confirm</button>
	</div>
</div>
