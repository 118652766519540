<h2 mat-dialog-title>
  <span>{{ tag && tag.id ? "Edit" : "Add" }} Tag</span>
</h2>
<form autocomplete="off">
  <div mat-dialog-content>
    <div class="row mt-3">
      <div class="col pt-2">
        <mat-form-field class="w-100">
          <mat-label>Tag</mat-label>
          <input
            maxlength="45"
            type="text"
            min="5"
            matInput
            id="tagField"
            [(ngModel)]="tag.tag"
            name="tag"
            (focus)="updatePosition()"
            (keyup)="tagFormat()"
            placeholder="Ex. &lt;&lt;HELLO&gt;&gt;"
            required
            (input)="automationService.dirty()"
          />
        </mat-form-field>
        <div class="text-danger m-0 py-2" *ngIf="error && !loading">
          Tags may only contain capital letters and underscores.
        </div>
        <div class="text-danger m-0 py-2" *ngIf="isDuplicate()">You can not have duplicate tags</div>
      </div>
      <div class="col pt-2">
        <mat-form-field class="w-100">
          <mat-label>Parameter</mat-label>
          <mat-select
            [(ngModel)]="tag.parameter"
            name="parameter"
            [compareWith]="compareObjects"
            (selectionChange)="checkProperty(); automationService.dirty(); tag.value = null; tag.property = null"
            required
          >
            <mat-option value="--- Static Value ---">--- Static Value ---</mat-option>
            <mat-option *ngFor="let parameter of parameters" [value]="parameter">
              {{ parameter.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="tag.parameter && tag.parameter.id" class="col pt-2">
        <mat-form-field class="w-100">
          <mat-label>Property</mat-label>
          <mat-select
            [(ngModel)]="tag.property"
            name="property"
            [compareWith]="compareObjects"
            required
            (selectionChange)="checkProperty(tag.property); automationService.dirty()"
          >
            <mat-option *ngFor="let property of tag.parameter?.object?.properties" [value]="property">
              {{ property.name }}
            </mat-option>
          </mat-select>
          <div class="text-danger m-0 py-2" *ngIf="nested && !tag.parameter.object.properties.length">
            Report Properties / Columns selection required
          </div>
        </mat-form-field>
      </div>
      <div *ngIf="hasSubProperties && useTreeSelector" class="col" style="margin-top: 9px">
        <mat-form-field class="w-100">
          <mat-label>Sub Property</mat-label>
          <input
            maxlength="255"
            type="text"
            matInput
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tag.subProperty"
            matTooltip="{{ tag.subProperty ? tag.subProperty : '' }}"
            [disabled]="loading"
            readonly
            placeholder="E.g. Abc123"
            (input)="automationService.dirty()"
          />
          <span matSuffix *ngIf="hasSubProperties">
            <span style="font-size: 13px; white-space: nowrap; cursor: pointer" (click)="openTreeSelector(tag.property)">
              <i *ngIf="hasSubProperties" style="font-size: 16px" class="material-icons">create</i>
            </span>
          </span>
          <mat-hint
            class="icon-button"
            (click)="
              helpDialogService.openHelpDialogService(
                'Sub Properties',
                'The sub properties further determine what column of data to apply the tag to.',
                false
              )
            "
          >
            <mat-icon matSuffix>help_outline</mat-icon>
          </mat-hint>
        </mat-form-field>
      </div>
      <div *ngIf="hasSubProperties && useDropDownSelector" class="col" style="margin-top: 9px">
        <mat-form-field class="w-100">
          <mat-label>Sub Property</mat-label>
          <mat-select
            [compareWith]="compareCustomObjects"
            [(ngModel)]="tag.subProperty"
            [disabled]="loading"
            matTooltip="{{ tag.subPropertyDisplay ? tag.subPropertyDisplay : '' }}"
            required
            (selectionChange)="setSubProperty($event.value); automationService.dirty()"
            name="subPropSelect"
          >
            <mat-option *ngFor="let property of customGoogleObject?.subProperties" [value]="property">
              {{ property.display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="!tag.parameter || !tag.parameter.id" class="col pt-2">
        <mat-form-field class="w-100">
          <mat-label>Value</mat-label>
          <input
            maxlength="255"
            type="text"
            matInput
            [(ngModel)]="tag.value"
            name="value"
            placeholder="Ex. Welcome Home!"
            (input)="automationService.dirty()"
            required
          />
        </mat-form-field>
      </div>
    </div>
    <div mat-dialog-actions class="px-0">
      <button class="" mat-stroked-button mat-dialog-close>Cancel</button>
      <button
        [disabled]="
          tag.tag.length < 5 ||
          (!tag.parameter?.name && !tag.value) ||
          (tag.parameter?.name && !tag.property) ||
          loading ||
          isDuplicate() ||
          error ||
          (hasSubProperties && !tag.subProperty)
        "
        (click)="processForm()"
        create
        mat-stroked-button
      >
        Save
      </button>
    </div>
  </div>
</form>
