import { Component, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { UntypedFormControl } from "@angular/forms";

@Component({
	selector: "app-confirmation-dialog-trash",
	templateUrl: "./confirmation-dialog-trash.component.html",
	styleUrls: ["./confirmation-dialog-trash.component.scss"],
})
export class ConfirmationDialogTrashComponent {
	public dialogData: unknown[];
	public title: string;
	public message: string;
	public btnOkText: string;
	public reasonText: string;
	public bold: string;
	public questionMark: string;
	public deleteFile = false;
	public textArea = false;
	public actions = new UntypedFormControl();
	public selectedDays = "7";
	public hideReason = true;

	constructor(
		public dialogRef: MatDialogRef<ConfirmationDialogTrashComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogTrashModel
	) {
		// Update view with given values
		this.title = data.title;
		this.message = data.message;
		this.btnOkText = data.btnOkText;
		this.dialogData = data.dialogData;
		this.bold = data.bold;
		this.questionMark = data.questionMark;
		this.deleteFile = data.deleteFile;
		this.hideReason = data.hideReason;
	}

	@ViewChild("autosize") autosize: CdkTextareaAutosize;

	reason(value) {
		if (value === "Other") {
			this.textArea = true;
		} else {
			this.textArea = false;
		}
	}

	public onConfirm(): void {
		// Close the dialog, return true
		this.dialogRef.close({
			event: this.actions.value,
			days: this.selectedDays,
			other: this.reasonText,
		});
	}

	public onDismiss(): void {
		// Close the dialog, return false
		this.dialogRef.close(false);
	}
}

export class ConfirmDialogTrashModel {
	constructor(
		public dialogData: unknown[],
		public title: string,
		public message: string,
		public btnOkText: string,
		public questionMark: string,
		public bold?: string,
		public deleteFile?: boolean,
		public hideReason?: boolean
	) {}
}
