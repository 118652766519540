import { Component, Input } from "@angular/core";
import { Device } from "src/app/models/asset-plus/device";

@Component({
  selector: "app-notes",
  templateUrl: "./notes.component.html",
  styleUrls: ["./notes.component.scss"],
})
export class NotesComponent {
  @Input() payload: Device;
}
