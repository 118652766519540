import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Observable, Subscription, of } from "rxjs";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { Permission } from "src/app/web/login/user";
import { environment } from "src/environments/environment";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { NavService } from "src/app/services/admin-plus/nav.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public appName = "Admin+";
  private apiUrl = "https://accounts.google.com/o/oauth2/v2/auth";
  private userApiUrl = environment.apiUrl + "/login";
  private redirectUrl = environment.baseUrl + "/login";
  private clientId = environment.clientId;
  refreshAccessTokenListener: Subscription;

  defaultPermissions: Permission[] = [
    {
      name: "google",
      expires: 0,
      accessToken: "",
      idToken: "",
      scopes: [
        {
          name: "email",
          required: true,
          accepted: false,
        },
        {
          name: "profile",
          required: true,
          accepted: false,
        },
        {
          name: "https://www.googleapis.com/auth/admin.directory.user",
          required: true,
          accepted: false,
        },
        {
          name: "https://www.googleapis.com/auth/admin.directory.group",
          required: true,
          accepted: false,
        },
        {
          name: "https://www.googleapis.com/auth/admin.directory.orgunit.readonly",
          required: true,
          accepted: false,
        },
        {
          name: "https://www.googleapis.com/auth/cloud-platform",
          required: true,
          accepted: false,
        },
        {
          name: "https://www.googleapis.com/auth/cloud-identity.groups",
          required: true,
          accepted: false,
        },
        {
          name: "https://www.googleapis.com/auth/drive.file",
          required: true,
          accepted: false,
        },
        {
          name: "https://www.googleapis.com/auth/apps.groups.settings",
          required: true,
          accepted: false,
        },
      ],
    },
  ];

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private notificationService: NotificationService,
    private navService: NavService
  ) {
    this.setRefreshAccessTokenListener();
  }

  async setRefreshAccessTokenListener() {
    this.refreshAccessTokenListener = this.loginService.refreshAccessToken().subscribe(async (response) => {
      const userAccessToken = await this.refreshUserAuthCode(response.id_token, response.jwt_token);
      if (!userAccessToken?.accessToken) {
        this.loginService.logout();
        return;
      }
      const user = this.loginService.user;
      const googlePermission = user.permissions?.find((p) => p.name == "google");
      googlePermission.idToken = userAccessToken.idToken;
      googlePermission.accessToken = userAccessToken.accessToken;
      googlePermission.expires = userAccessToken.expires;
      localStorage.setItem("user", JSON.stringify(user));
      this.loginService.refreshing = false;
    });
  }

  signInWithGoogle(): void {
    const signIn = window.location.href;
    window.location.href = this.createURL();
    history.replaceState(null, "", signIn);
  }

  createURL(): string {
    const scopes = this.getScopes(this.defaultPermissions, "google", null, true);
    const nonce = this.randomString(24);
    return (
      this.apiUrl +
      "?scope=" +
      encodeURI(scopes.join(" ")) +
      "&redirect_uri=" +
      this.redirectUrl +
      "&client_id=" +
      this.clientId +
      "&response_type=id_token code" +
      "&prompt=select_account" +
      "&access_type=offline" +
      "&nonce=" +
      nonce
    );
  }

  createAccessTokenURL(scopes: string[], googleId: string): string {
    let additionalParams = "&response_type=token";
    if (scopes.length) {
      additionalParams += " code&access_type=offline";
    }
    const defaultScopes = this.getScopes(this.defaultPermissions, "google", null, true);
    scopes = defaultScopes.concat(scopes);
    localStorage.setItem("state", "login");
    return (
      this.apiUrl +
      "?scope=" +
      encodeURI(scopes.join(" ")) +
      "&redirect_uri=" +
      this.redirectUrl +
      "&client_id=" +
      this.clientId +
      "&include_granted_scopes=true" +
      additionalParams +
      "&login_hint=" +
      googleId +
      "&state=login"
    );
  }

  createFreeTrialURL(): string {
    const nonce = this.randomString(24);
    const scopes = this.getScopes(this.defaultPermissions, "google", null, true);
    scopes.push("https://www.googleapis.com/auth/admin.reports.usage.readonly"); //required for usage information
    scopes.push("https://www.googleapis.com/auth/admin.directory.customer.readonly"); //Required for customer name

    return (
      this.apiUrl +
      "?scope=" +
      encodeURI(scopes.join(" ")) +
      "&redirect_uri=" +
      this.redirectUrl +
      "&client_id=" +
      this.clientId +
      "&response_type=id_token token code" +
      "&access_type=offline" +
      "&include_granted_scopes=true" +
      "&prompt=select_account" +
      "&nonce=" +
      nonce +
      "&state=free-trial"
    );
  }

  elevateAccessToken(googleId, scopes = [], state) {
    localStorage.setItem("state", state);
    return (
      this.apiUrl +
      "?scope=" +
      encodeURI(scopes.join(" ")) +
      "&redirect_uri=" +
      this.redirectUrl +
      "&client_id=" +
      this.clientId +
      "&response_type=token code" +
      "&access_type=offline" +
      "&include_granted_scopes=true" +
      "&prompt=consent" +
      "&login_hint=" +
      googleId +
      "&state=" +
      state
    );
  }

  randomString = function (length: number) {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  };

  public getUserInfo(idToken: string) {
    return this.http
      .get(environment.apiUrl + "/login/get-user-info?id_token=" + idToken)
      .pipe(catchError(this.handleAPIError("getUserInfo")));
  }

  getScopes(permissions: Permission[], provider = "google", accepted: boolean, required: boolean): string[] {
    const scopes = [];

    for (const p of permissions) {
      if (p.name == provider) {
        for (const s of p.scopes) {
          if (accepted !== null) {
            if (s.accepted == accepted) {
              scopes.push(s.name);
            }
          } else if (required !== null) {
            if (s.required == required) {
              scopes.push(s.name);
            }
          } else {
            scopes.push(s.name);
          }
        }
      }
    }

    return scopes;
  }

  public handleAPIError<T>(operation = "operation", mute?: boolean, customMessage?: string, result?: T) {
    return (error): Observable<T> => {
      if (
        ((error["error"] && error["error"]["code"] == 401) ||
          error["status"] == 401 ||
          (error["error"] && error["error"]["error"] && error["error"]["error"]["code"] == 401)) &&
        !mute
      ) {
        //auth failed
        this.loginService.logout();
      }
      const errorMessage = true;
      if (!mute) {
        if (customMessage) {
          this.notificationService.notify(customMessage, errorMessage);
        } else if (error["error"] && error["error"]["message"]) {
          this.notificationService.notify(error["error"]["message"], errorMessage);
        } else if (error["error"] && error["error"]["error"] && error["error"]["error"]["message"]) {
          this.notificationService.notify(error["error"]["error"]["message"], errorMessage);
        } else if (error["error"] && typeof error["error"] == "string") {
          this.notificationService.notify(error["error"], errorMessage);
        } else if (error["message"]) {
          this.notificationService.notify(error["message"], errorMessage);
        } else {
          this.notificationService.notify("Unknown error - HAE", errorMessage);
        }
      }
      this.navService.loading.next(false);
      return of(result as T);
    };
  }

  public async createUserAuthCode(code: string) {
    return new Promise<UserAccessToken>((resolve) => {
      this.createUserAuthCodeRequest(code).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private createUserAuthCodeRequest(code: string): Observable<UserAccessToken> {
    const userAuthCode: UserAuthCode = {
      code: code,
      redirectUrl: this.redirectUrl,
    };
    return this.http
      .post<UserAccessToken>(this.userApiUrl + `/user-auth-code`, userAuthCode, {
        headers: new HttpHeaders().set("flow", "code"),
      })
      .pipe(catchError(this.handleAPIError<UserAccessToken>("createUserAuthCodeRequest")));
  }

  public async refreshUserAuthCode(id_token: string, jwt_token: string) {
    return new Promise<UserAccessToken>((resolve) => {
      this.refreshUserAuthCodeRequest(id_token, jwt_token).subscribe((results) => {
        if (results) {
          resolve(results);
        } else {
          resolve(null);
        }
      });
    });
  }

  private refreshUserAuthCodeRequest(id_token: string, jwt_token: string): Observable<UserAccessToken> {
    return this.http
      .get<UserAccessToken>(this.userApiUrl + `/user-auth-code/refresh`, {
        headers: new HttpHeaders().set("flow", "code").set("id_token", id_token),
        params: new HttpParams().set("jwt", jwt_token),
      })
      .pipe(catchError(this.handleAPIError<UserAccessToken>("refreshUserAuthCodeRequest")));
  }
}

export interface UserAuthCode {
  code: string;
  redirectUrl: string;
}

export interface UserAccessToken {
  idToken: string;
  accessToken: string;
  expires: number;
  error: string;
}
