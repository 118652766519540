import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Tag } from "src/app/models/ticket-plus/tag";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { UtilityService } from "src/app/services/utilities/utility.service";
import { TagService } from "src/app/services/ticket-plus/ticket/tag.service";
import { NavService } from "src/app/services/admin-plus/nav.service";

@Component({
  selector: "app-tag-dialog",
  templateUrl: "./tag-dialog.component.html",
  styleUrls: ["./tag-dialog.component.css"],
})
export class TagDialogComponent implements OnInit {
  action = "Add";
  tag = new Tag({});
  originalTag = "";
  loading = false;

  constructor(
    private dialogRef: MatDialogRef<TagDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private notifyService: NotificationService,
    private utilityService: UtilityService,
    private tagsService: TagService,
    private navService: NavService
  ) {}

  ngOnInit(): void {
    this.action = this.utilityService.capitalizeFirstLetter(this.data.action);
    if (this.data.tag) {
      this.tag = Object.assign({}, this.data.tag);
      this.originalTag = this.tag.name;
    }
  }

  async processForm() {
    this.loading = true;
    this.navService.loading.next(true);
    if (this.data.action == "delete") {
      const deleteTag = await this.tagsService.deleteTag(this.tag.id);
      if (deleteTag) {
        this.notifyService.notify("Tag Deleted");
        this.dialogRef.close(this.tag);
        this.navService.loading.next(false);
        this.loading = false;
        return;
      }
    }
    if (this.originalTag === this.tag.name) {
      this.notifyService.notify("Tag name has not changed");
      this.navService.loading.next(false);
      this.loading = false;
      return;
    }
    if (this.tag.name?.trim()) {
      const existingTag = await this.tagsService.getTags({ nameExact: this.tag.name });
      if (existingTag.count) {
        this.notifyService.notify(`Tag ${this.tag.name} already exists`);
      } else {
        const createUpdateTag = await this.tagsService.createUpdateTag(this.tag);
        if (createUpdateTag) {
          this.notifyService.notify(`Tag ${this.tag.id ? "Updated" : "Added"}`);
          this.dialogRef.close(this.tag);
        }
      }
    } else {
      this.notifyService.notify("Tag name is required");
    }
    this.navService.loading.next(false);
    this.loading = false;
  }
}
