import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ModulesService } from "src/app/services/modules.service";

@Component({
  selector: "app-eua-prompt",
  templateUrl: "./eua-prompt.component.html",
})
export class EuaPromptComponent {
  agree = false;
  lockButtons = false;
  showError = false;

  constructor(private modulesService: ModulesService, public dialogRef: MatDialogRef<EuaPromptComponent>) {}

  async onConfirm() {
    const result: boolean = await this.modulesService.approveEua();

    this.lockButtons = true;
    if (result) {
      this.dialogRef.close(true);
    } else {
      this.showError = true;
    }
  }

  onDismiss() {
    this.dialogRef.close(false);
  }
}
