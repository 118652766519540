<div class="col-12 mt-2">
  <div *ngIf="!dismissed && message?.length" class="alert alert-info px-3 mb-2">
    <div class="d-flex align-items-center">
      <div class="mr-3">
        <mat-icon>help</mat-icon>
      </div>
      <span class="" [innerHTML]="message"></span>
      <button class="ml-auto" mat-icon-button aria-label="Dismiss" (click)="dismissed = !dismissed">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>
