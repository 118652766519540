import { TicketService } from "src/app/services/ticket-plus/ticket/ticket.service";
import { Customer } from "src/app/services/admin-plus/customer.service";
import { Agent } from "./agent";
import { Team } from "./team";
import { Category } from "./category";
import { Status } from "./status";
import { Priority } from "./priority";
import { Channel } from "./channel";
import { TicketAttachment } from "./attachment";
import { UserAction } from "../admin-plus/base";
import { Tag } from "./tag";

export interface ITicket {
  subject: string;
  description: string;
  id?: number;
  customer?: Customer;
  createdBy?: UserAction;
  requestedBy?: UserAction;
  updatedBy?: UserAction;
  status: Status;
  priority?: Priority;
  category?: Category;
  assignedTo?: AssignedTo;
  channel: Channel;
  attachments: TicketAttachment[];
  comments: Comment[];
  tags: Tag[];
  deleted: number;
  updateTicket: () => Promise<Ticket>;
  createTicket: () => Promise<Ticket>;
}

export class Ticket implements ITicket {
  private ticketService: TicketService;
  subject: string;
  description: string;
  id?: number;
  customer?: Customer;
  createdBy?: UserAction;
  requestedBy?: UserAction;
  updatedBy?: UserAction;
  status: Status;
  priority?: Priority;
  category?: Category;
  assignedTo?: AssignedTo;
  channel: Channel;
  attachments: TicketAttachment[];
  comments: Comment[];
  tags: Tag[];
  deleted: number;

  constructor(properties) {
    Object.assign(this, properties);
  }

  public async updateTicket(): Promise<Ticket> {
    const ticket = await this.ticketService.editTicket(this);
    this.updatedBy.date = ticket.updatedBy.date;
    this.updatedBy.email = ticket.updatedBy.email;
    return this;
  }

  public async createTicket(): Promise<Ticket> {
    const ticket = await this.ticketService.addTicket(this);
    this.id = ticket.id;
    this.createdBy.date = ticket.createdBy.date;
    this.createdBy.email = ticket.createdBy.email;
    return this;
  }
}

export interface AssignedTo {
  agent?: Agent;
  team?: Team;
  edit?: boolean;
}
