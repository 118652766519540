<mat-list>
  <mat-list-item>
    <div class="d-flex align-items-center justify-content-between">
      <span>Add Tag</span>
      <button mat-icon-button aria-label="Add tag" (click)="addTag()" [disabled]="!loaded">
        <mat-icon> add_circle </mat-icon>
      </button>
    </div>
  </mat-list-item>
  <mat-list-item *ngFor="let tag of tags">
    <div class="d-flex align-items-center justify-content-between">
      <span>{{ tag.tag }}</span>
      <div>
        <button mat-icon-button aria-label="Copy tag" (click)="copy(tag)" [disabled]="!loaded">
          <mat-icon>content_copy</mat-icon>
        </button>
        <button mat-icon-button aria-label="Edit tag" (click)="edit(tag)" [disabled]="!loaded">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button aria-label="Delete tag" (click)="delete(tag)" [disabled]="!loaded">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </mat-list-item>
</mat-list>
