<form (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-12 col-md-7 mt-2">
      <app-filters [filterType]="filterTypeValue" (dataEvent)="receiveData($event)"></app-filters>
    </div>
  </div>
</form>
<div class="row">
  <div class="col-12">
    <div id="pdfTable" #pdfTable>
      <div class="table-responsive has-active">
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th class="px-3" mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                color="primary"
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() ? true : false && getPageData()"
                [indeterminate]="selection.hasValue() && !getPageData()"
                [aria-label]="checkboxLabel()"
              >
              </mat-checkbox>
            </th>
            <td class="p-3" mat-cell *matCellDef="let row">
              <mat-checkbox
                color="primary"
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
                [aria-label]="checkboxLabel(row)"
              >
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th class="px-3 text-nowrap" mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
            <td class="p-3" mat-cell *matCellDef="let row">
              <span class="text-nowrap">{{ row.status.name }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th class="px-3 text-nowrap" mat-header-cell mat-sort-header *matHeaderCellDef>Type</th>
            <td class="p-3" mat-cell *matCellDef="let row">
              <span class="text-nowrap">{{ row.type.name }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="user">
            <th class="px-3 text-nowrap" mat-header-cell mat-sort-header *matHeaderCellDef>User</th>
            <td class="p-3" mat-cell *matCellDef="let row">
              <span class="text-nowrap">{{ row.user.name }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="start">
            <th class="px-3 text-nowrap" mat-header-cell mat-sort-header *matHeaderCellDef>Start</th>
            <td class="p-3" mat-cell *matCellDef="let row">
              <span class="text-nowrap">{{ row.start | date : "short" }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="end">
            <th class="px-3 text-nowrap" mat-header-cell mat-sort-header *matHeaderCellDef>End</th>
            <td class="p-3" mat-cell *matCellDef="let row">
              <span class="text-nowrap">{{ row.end | date : "short" }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="details">
            <th class="px-3 text-nowrap bg-light" mat-header-cell mat-sort-header *matHeaderCellDef>Details</th>
            <td class="p-3 py-1 bg-light" mat-cell *matCellDef="let row">
              <span class="text-nowrap">
                <div class="my-auto">
                  <div>
                    <span>
                      Bucket: <b>{{ parseJSON(row.params).bucketName }}</b>
                    </span>
                    <span *ngIf="!!parseJSON(row.params).folderPath">/{{ parseJSON(row.params).folderPath }} </span>
                  </div>
                  <div *ngIf="parseJSON(row.params).files.length">
                    Files: <b>{{ parseJSON(row.params).files.length }}</b>
                  </div>
                  <div *ngIf="parseJSON(row.params).isDrive">
                    Drive: <b>{{ parseJSON(row.params).drive.name }}</b>
                  </div>
                  <div *ngIf="parseJSON(row.params).isZip">
                    Zip File: <b>{{ parseJSON(row.params).zipFile }}.zip</b>
                  </div>
                  <div>
                    Delete: <b>{{ parseJSON(row.params).isMove }}</b>
                  </div>
                  <div *ngIf="!!row.note">
                    Note: <b>{{ row.note }}</b>
                  </div>
                </div>
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="executed">
            <th class="px-3 text-nowrap" mat-header-cell mat-sort-header *matHeaderCellDef>Executed</th>
            <td class="p-3" mat-cell *matCellDef="let row">
              <span class="text-nowrap">
                {{ row.executed }}
                <small class="text-muted" matTooltip="Maximum number of times this job will be attempted"
                  >({{ row.maxExecutions }})</small
                >
              </span>
            </td>
          </ng-container>
          <tr class="mat-row" app-empty-dataset-alert [loading]="loading" [filterCount]="data.filtersCount" *matNoDataRow></tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <app-table-footer
      [dataSource]="dataSource"
      [maxResults]="maxResults"
      *
      [nextButton]="nextButton"
      [nextButtonDis]="nextButtonDis"
      [offset]="offset"
      [page]="page"
      (pager)="setPager($event)"
    ></app-table-footer>
    </div>
  </div>
</div>
