<h2 mat-dialog-title>Transfer to Google Cloud Storage</h2>
<div mat-dialog-content>
	<div class="row">
		<div class="col-12 mt-2">
			<p [innerHTML]="topText"></p>
		</div>
		<div *ngIf="fileCount > 25" class="col-12 mt-2">
			<p>
				The job (Transfer to GCP) processes up to 25 files at a time,
				{{ totalJobs }} jobs will be created.
			</p>
		</div>
		<div class="col-12 mt-2">
			<mat-label
				>Transfer file{{ fileCount == 1 ? "" : "s" }} to the following
				folder:</mat-label
			>
			<table
				*ngIf="!loadedBucketFolders"
				class="table table-borderless text-left"
			>
				<tr>
					<td>
						<span> Retrieving bucket folders... </span>
					</td>
					<td>
						<span class="material-icons faa-spin"> filter_tilt_shift </span>
					</td>
				</tr>
			</table>
			<mat-form-field *ngIf="loadedBucketFolders" class="w-100">
				<mat-select
					[formControl]="actions"
					(ngModelChange)="folderSelect($event)"
				>
					<mat-option value="[]">---New folder---</mat-option>
					<mat-option value="">---Root---</mat-option>
					<mat-option
						#folder
						*ngFor="let folder of bucketFolders"
						[value]="folder"
						>{{ folder }}</mat-option
					>
				</mat-select>
				<mat-hint *ngIf="actions.value?.length > 765"
					>Folder name exceeds allowable length - select or create a different
					folder.</mat-hint
				>
			</mat-form-field>
		</div>
		<div *ngIf="newFolderInput" class="col-12 mt-2">
			<mat-form-field class="w-100">
				<mat-label> New folder name: </mat-label>
				<input
					matInput
					[(ngModel)]="newFolder"
					type="string"
					name="newFolder"
					id="newFolder"
					autocomplete="off"
					maxlength="765"
					#newFolderName
				/>
				<mat-hint>
					See
					<a
						href="https://cloud.google.com/storage/docs/naming-objects"
						target="_blank"
						>GCS Documentation</a
					>
					for object naming guidelines. {{ newFolderName.value?.length }} of 765
					characters.
				</mat-hint>
			</mat-form-field>
		</div>
	</div>
	<div class="col-12 mt-2">
		<mat-checkbox
    color="primary"
			
			[(ngModel)]="transfer.isMove"
			name="isMove"
		>
			Delete transferred files from drive?
		</mat-checkbox>
	</div>
	<div class="col-12 mt-2">
		<mat-checkbox
    color="primary"
			
			[disabled]="transfer.isDrive"
			[(ngModel)]="transfer.isZip"
			name="transfer.isZip"
		>
			Compress (zip) files?
		</mat-checkbox>
	</div>
	<div *ngIf="transfer.isZip" class="col-12 mt-2">
		<mat-form-field class="w-100">
			<mat-label> Zipped File Name </mat-label>
			<input
				matInput
				type="text"
				[(ngModel)]="transfer.zipFile"
				placeholder="transferred_from_google_drive"
				autocomplete="off"
				name="zipFile"
				maxLength="150"
			/>
			<mat-hint>
				<sup>*</sup>Name will be appended with a timestamp for uniqueness
			</mat-hint>
		</mat-form-field>
	</div>
	<!--div class="col-12 mt-2">
    <mat-checkbox color="primary"  [(ngModel)]="transfer.sendEmail" name="transfer.sendEmail">
      Email me when complete
    </mat-checkbox>
  </div-->
	<div mat-dialog-actions class='px-0'>
		<button class="" mat-stroked-button mat-dialog-close>Cancel</button>
		<button
			mat-stroked-button 
			class="success ml-2"
			(click)="onConfirm()"
			[disabled]="disabled"
		>
			Confirm
		</button>
	</div>
</div>
